import classes from "./Scrolable.module.less";
import React from "react";
import { Pagination } from "antd";

export default ({ children, pagination, onPaginationChange }) => (
  <>
    <div className={classes.container}>
      <div className={classes.content}>{children}</div>
    </div>
    {pagination ? (
      <div className={classes.pagination}>
        <Pagination
          pageSize={10}
          {...pagination}
          onChange={onPaginationChange || (() => {})}
        />
      </div>
    ) : null}
  </>
);
