import React, { useState } from "react";
import classes from "../CloudStore.module.less";
import { Tabs } from "antd";
import ECS from "../Shop/ECS/ECS";
import Domains from "../Shop/Domains/Domains";
import Database from "../Shop/Database/Database";
const { TabPane } = Tabs;

const Products = ({ appId, ...props }) => {
  let [tab, setTab] = useState("ecs");
  const onChange = key => {
    setTab(key);
  };
  return (
    <div className={classes.container}>
      <Tabs activeKey={tab} onChange={onChange}>
        <TabPane tab="Elastic Compute Service" key="ecs">
          <ECS appId={appId} {...props} />
        </TabPane>
        <TabPane tab="Dedicated Database" key="ecs2">
          <Database appId={appId} {...props} />
        </TabPane>
        <TabPane tab="Domains and ICP Recordal Registration" key="ecs3">
          <Domains appId={appId} {...props} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Products;
