import React from "react";
import { Button, Modal, Card } from "antd";
import classes from "./StopFakeSession.module.less";
import Draggable from "react-draggable";
const confirm = Modal.confirm;
const StopFakeSession = ({ email_to_impersonate }) => {
  const removeSession = () => {
    localStorage.removeItem("email_to_impersonate");
    if (localStorage.original_id_token) {
      if (localStorage.original_id_token === "undefined") {
        localStorage.clear();
      } else {
        localStorage.id_token = localStorage.original_id_token;
        localStorage.removeItem("original_id_token");
      }
    }
    window.close();
  };
  const showConfirm = () => {
    confirm({
      title: "Are you sure you want to close this view as client session?",
      onOk() {
        removeSession();
      },
      onCancel() {}
    });
  };
  return (
    <div className={classes.container}>
      <Draggable>
        <Card>
          <div>
            <p>
              Viewing as: <b> {email_to_impersonate}</b>
              {"     "}
            </p>
            <Button onClick={showConfirm} size="small" icon="stop">
              Stop Now
            </Button>
          </div>
        </Card>
      </Draggable>
    </div>
  );
};
export default StopFakeSession;
