import React, { useState } from "react";
import { Row, Col, Modal, Input, message, Button, Card } from "antd";
import { badgeGp, badgeIos, badgeEn, badgeCn } from "../../../../images";
import classes from "./AppInChinaBadge.module.less";
import badgesZip from "../../../../assets/appinchina-badges-print.zip";
import copy from "clipboard-copy";
import { QRCode } from "react-qr-svg";
import { Link } from "react-router-dom";
import { saveSvgAsPng } from "save-svg-as-png";
const { TextArea } = Input;

export default ({ packageName, app, style, ...props }) => {
  let [modalOpen, setModalOpen] = useState(false);
  let customUrl = `https://apps.appinchina.com.cn/details/${packageName}`;
  let chineseHtml = `<script>(()=>{if(void 0!==typeof window){window.trackBadgeEvent=(async t=>{var e=await new Promise(t=>{fetch("https://www.cloudflare.com/cdn-cgi/trace").then(t=>t.text()).then(e=>t(e))});fetch("https://appinchina.space/misc/tracking/${app.id}",{method:"POST",body:JSON.stringify({x:e,e:t,r:window.location.href}),headers:{"Content-Type":"application/json"}})})}})();</script>
  <a href="${customUrl}" target="_blank" onclick="window.trackBadgeEvent('click')"><img onload="window.trackBadgeEvent('loaded')" src="https://www.appinchina.co/images/badge-cn.png" alt="Get it on AppInChina"/></a>`;
  let englishHtml = `<script>(()=>{if(void 0!==typeof window){window.trackBadgeEvent=(async t=>{var e=await new Promise(t=>{fetch("https://www.cloudflare.com/cdn-cgi/trace").then(t=>t.text()).then(e=>t(e))});fetch("https://appinchina.space/misc/tracking/${app.id}",{method:"POST",body:JSON.stringify({x:e,e:t,r:window.location.href}),headers:{"Content-Type":"application/json"}})})}})();</script>
  <a href="${customUrl}" target="_blank"h onclick="window.trackBadgeEvent('click')"><img onload="window.trackBadgeEvent('loaded')" src="https://www.appinchina.co/images/badge-en.png" alt="Get it on AppInChina"/></a>`;
  let data = { customUrl, englishHtml, chineseHtml };

  const copyText = (variable) => {
    let dataToCopy = data[variable];
    copy(dataToCopy);
    message.success("Text copied to clipboard!");
    document.getElementById(`${variable}Element`).select();
  };

  const downloadZip = () => {
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(badgesZip));
    link.setAttribute("download", "appinchina-badges.zip");
    document.body.appendChild(link);
    link.click();
  };

  return packageName ? (
    <div className={classes.container} style={style}>
      <Card size="small" title="AppInChina Badge" className={classes.intro}>
        <div className={classes.flex}>
          <img src={badgeGp} alt="Google Play Badge" />
          <img src={badgeIos} alt="IOS Badge" />
          <img src={badgeEn} alt="AppInChina Badge" />
        </div>
        <p>
          Use the AppInChina badge in your marketing and website to promote your
          content to Chinese users.
        </p>
        {/*
        NOTE: add this button back then the tracking page is ready to be used
        <Row type="flex" align="end">
          {/*<Link to={`/badge-tracking/${app.id}`}>
            <Button icon="tag">View Statistics</Button>
          </Link>
          </Row>*/}
        <Button onClick={() => setModalOpen(true)}>More Details</Button>
      </Card>
      <Modal
        title="AppInChina Badge"
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={[
          <Button
            onClick={downloadZip}
            width="170px"
            style={{ marginRight: 10 }}
            icon="download"
          >
            Print Versions (EPS)
          </Button>,
          <Link to={`/badge-analytics/${app.id}`}>
            <Button
              onClick={() => setModalOpen(false)}
              type="primary"
              width="100%"
              icon="tag"
            >
              View Details
            </Button>
          </Link>,
        ]}
      >
        <Row gutter={16} className={classes.modalBadges}>
          <Col span={12}>
            <Col span={24}>
              <img src={badgeCn} alt="Chinese Badge" />
              <a
                href={badgeCn}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                Download Chinese Version
              </a>
            </Col>
            <Col span={24}>
              <img src={badgeEn} alt="English Badge" />
              <a
                href={badgeEn}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                Download English Version
              </a>
            </Col>
          </Col>

          <Col span={12}>
            <div className={classes.qrCode}>
              <QRCode
                id="qrcode"
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                style={{ width: 170 }}
                value={`https://apps.appinchina.com.cn/details/${packageName}`}
              />
            </div>
            <a
              onClick={() => {
                saveSvgAsPng(
                  document.getElementById("qrcode"),
                  `${packageName}_qrcode.png`,
                  { scale: 4.5 }
                );
              }}
            >
              Download QR Code
            </a>
          </Col>
          <Col span={24} className={classes.customUrl}>
            <p>
              <b>Your custom URL:</b>{" "}
              <Input
                value={customUrl}
                onClick={() => copyText("customUrl")}
                id="customUrlElement"
              />
            </p>
          </Col>
          <Col span={24} className={classes.thirdColumn}>
            <p>
              <b>Or copy/paste this HTML (Chinese Version)</b>
            </p>
            <TextArea
              value={chineseHtml}
              rows={5}
              onClick={() => copyText("chineseHtml")}
              id="chineseHtmlElement"
            />
            <p>
              <b>Or copy/paste this HTML (English Version)</b>
            </p>
            <TextArea
              value={englishHtml}
              rows={5}
              onClick={() => copyText("englishHtml")}
              id="englishHtmlElement"
            />
          </Col>
        </Row>
      </Modal>
    </div>
  ) : null;
};
