import axios from "../axios";

export const getTrademarksStatus = async (values) => {
  let response = null;
  try {
    response = await axios.get(`/trademarks-status?values=${values}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const searchTrademarks = async (page, search) => {
  let response = null;
  try {
    response = await axios.get(`/trademarks`, {
      params: {
        search,
        page,
      },
    });
  } catch (e) {
    console.log("error", e);
  } finally {
    return response.data;
  }
};
