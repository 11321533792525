import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import classes from "./PayPalButton.module.less";
import { notification } from "antd";
import history from "../../../../../history";
const PayPal = ({ amount, addBalance, appId }) => {
  return (
    <div className={classes.container}>
      <PayPalButton
        amount="0.01"
        // amount={`${amount}`}
        style={{
          color: "blue"
        }}
        // shippingPreference="USD" // default is "GET_FROM_FILE"
        options={{
          clientId:
            "ASgF7wh67NOiYkU2zItpCgc5AhsyzI2imgvHPzBRvWTsREOFLCdnR_4BeeWIXsfrmaXsXVaC3oA20ORW"
        }}
        onError={error => {
          console.log("error", error);

          notification.error({
            message: `An error has ocurred ${error}`,
            placement: "bottomLeft"
          });
        }}
        onSuccess={(details, data) => {
          try {
            // let paypal_amount = details.purchase_units[0].amount.value;
            // addBalance(+paypal_amount, data.orderID, JSON.stringify(details));
            addBalance(amount, data.orderID, JSON.stringify(details));
            history.push(`/cloud/account-balance/${appId}`);
          } catch (error) {
            console.log("error", error);
          }
        }}
      />
    </div>
  );
};

export default PayPal;
