import moment from "moment";
let table = [];
let chart = [];
const beginDay = new Date().getTime();
for (let i = 0; i < 20; i += 1) {
  table.push({
    page: "www.demo_app.com",
    name: `Demo App`,
    app: "1234-8475-9439-9494",
    views: Math.floor(Math.random() * 10000) + 100,
    clicks: Math.floor(Math.random() * 10000) + 100
  });
}
for (let i = 0; i < 20; i += 1) {
  chart.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    event: "clicks",
    event_count: Math.floor(Math.random() * 100) + 10
  });
}
for (let i = 0; i < 20; i += 1) {
  chart.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    event: "views",
    event_count: Math.floor(Math.random() * 100) + 10
  });
}
const badgeFake = { chart: chart, table: table };

export { badgeFake };
