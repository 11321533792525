import axios from "../axios";

export const getBadgeSettings = async id => {
  let response = null;
  try {
    response = await axios.get(`/badge-settings/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const updateBadgeSettings = async values => {
  let response = null;
  try {
    response = await axios.put(`/badge-settings`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
