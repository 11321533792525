import React from "react";
import { Alert, notification } from "antd";
import classes from "./UncontractedAnalyticsMessage.module.less";
import { newAnalyticsRequest } from "../../../services/analyticsService";

export default ({ app, useDemoData }) => {
  let sendAnalyticsRequest = async () => {
    notification.info({
      message: "Sending request...",
      placement: "bottomLeft",
      duration: 2
    });
    let email = localStorage.getItem("email");
    let { data } = await newAnalyticsRequest(
      "ANALYTICS",
      email,
      app.id,
      app.name
    );
    if (data && data === "success") {
      notification.info({
        message:
          "Thank you for your request. An operations manager will email you soon.",
        placement: "bottomLeft",
        duration: 8
      });
      if (window.mixpanel) {
        window.mixpanel.track("Analytics access requested");
      }
    } else {
      notification.warning({
        message: "An error has ocurred.",
        placement: "bottomLeft"
      });
    }
  };
  return useDemoData ? (
    <div className={classes.alert}>
      <Alert
        message={
          <p>
            This page demonstrates how the analytics platform looks using
            example data. If you would like to integrate the AppInChina
            Analytics SDK then please{" "}
            <a onClick={sendAnalyticsRequest}>click here</a>.
          </p>
        }
        type="warning"
        showIcon
      />
    </div>
  ) : null;
};
