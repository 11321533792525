import React, { useState, useEffect } from "react";
import {
  Row,
  Button,
  Input,
  Form,
  Col,
  Avatar,
  Spin,
  notification
} from "antd";
import classes from "../Settings.module.less";
import ChangePassword from "../../Dialogs/ChangePassword/ChangePassword";
import { getUserInfo, updateInfo } from "../../../services/acccountService";
const AccountSettings = ({ form }) => {
  const { getFieldDecorator } = form;
  const [display, setDisplay] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    fetch();
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    setUploading(true);
    form.validateFields(async (err, values) => {
      if (!err) {
        const { data } = await updateInfo(values);
        if (data.message === "success") {
          notification.success({
            message: "Information updated!"
          });
          setUploading(false);
        }
      }
    });
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const { data } = await getUserInfo();
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const avatarStyle = { width: 120, height: 120, lineHeight: 64, fontSize: 32 };

  return (
    <div className={classes.container}>
      {loading ? (
        <Spin />
      ) : (
        <Row gutter={16}>
          <Col span={16}>
            <Form onSubmit={handleSubmit}>
              <Form.Item label="First Name">
                {getFieldDecorator(`first_name`, {
                  rules: [{ required: true, message: "Required" }],
                  initialValue: data.first_name
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Last Name">
                {getFieldDecorator(`last_name`, {
                  rules: [{ required: true, message: "Required" }],
                  initialValue: data.last_name
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Email">
                {getFieldDecorator(`email`, {
                  rules: [{ required: true, message: "Required" }],
                  initialValue: data.email
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item label="Company">
                {getFieldDecorator(`client_company`, {
                  rules: [{ required: true, message: "Required" }],
                  initialValue: data.client_company
                })(<Input />)}
              </Form.Item>
              <Row style={{ paddingTop: 16 }} type="flex" justify="end">
                <Button
                  style={{ width: 150 }}
                  icon="save"
                  loading={uploading}
                  disable={uploading}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
                <Button
                  style={{ marginLeft: 20 }}
                  onClick={() => setDisplay(true)}
                  icon="edit"
                >
                  Change Password
                </Button>
              </Row>
            </Form>
          </Col>
          <Col span={8} style={{ marginTop: 60 }}>
            {" "}
            <p>Profile Image</p>
            {localStorage.imageLocation ? (
              <Avatar src={localStorage.imageLocation} style={avatarStyle} />
            ) : (
              <Avatar style={avatarStyle} icon="user" />
            )}
          </Col>
        </Row>
      )}
      <ChangePassword display={display} close={() => setDisplay(false)} />
    </div>
  );
};

export default Form.create()(AccountSettings);
