import React, { useState, useEffect } from "react";
import history from "../../history";
import classes from "./AntMenu.module.less";
import { companyLogoWhite, logoGreen } from "../../images";
import { Menu, Icon, notification, Drawer } from "antd";
import NewFeature from "../UI/NewFeature/NewFeature";
const AntMenu = ({ menuOption, section, ...props }) => {
  const { SubMenu } = Menu;
  const [openKeys, setOpenKeys] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  let app =
    props && props.apps ? props.apps.find((it) => it.id === props.appId) : null;
  let app_stores_account = app && app.main_information.app_store_accounts;
  let role = !!app && app.role;
  const corporate_client =
    app_stores_account === "CLIENT_CC" || app_stores_account === "CLIENT_OC";
  const display_authorization =
    app_stores_account === "AIC" || app_stores_account === "ZHENCHUANG";

  console.log("rendering menu", app, role)

  let monetizationOptions = {
    title: "Monetization",
    key: "monetization",
    icon: "money-collect",
    submenus: [
      { key: "summary", title: "Summary" },
      { key: "transactions", title: "Transactions" },
      { key: "users", title: "Users" },
      { key: "refunds", title: "Refunds" },
    ],
  };

  const menuData = role === "PAYMENTS" ? [monetizationOptions] : [
    { title: "Home", key: "home", icon: "home" },
    {
      title: "Localization",
      key: "localization",
      icon: "appstore",
      submenus: [
        { key: "integrations", title: "Integrations" },
        { key: "translation", title: "Translation" },
        { key: "trademark-search", title: "Trademark Search" },
      ],
    },
    {
      title: "Distribution",
      key: "distribution",
      icon: "form",
      submenus: [
        { key: "distribution-status", title: "Distribution Status" },
        { key: "app-information", title: "App Submission" },
        {
          key: "company-form",
          title: "Business Information",
          visible: !corporate_client,
        },
        { key: "copyright-certificate", title: "Copyright Certificate" },
        { key: "security-assesment", title: "Security Assessment" },
        {
          key: "authorization-letter",
          title: "Authorization Letter",
          visible: !display_authorization,
        },
      ],
    },
    {
      title: "Monetization",
      key: "monetization",
      icon: "money-collect",
      submenus: [
        { key: "summary", title: "Summary" },
        { key: "transactions", title: "Transactions" },
        { key: "renewals", title: "Renewals" },
        { key: "users", title: "Users" },
        { key: "refunds", title: "Refunds" },
        { key: "statement-reports", title: "Statements & Reports" },
        { key: "transfer-history", title: "Transfer History" },
        { key: "account-details", title: "Account Details" },
      ],
    },
    {
      title: "Analytics",
      key: "app-promotion",
      icon: "fund",
      submenus: [
        { key: "summary", title: "Summary" },
        {
          key: "overall-data",
          title: "Overall Data",
        },
        {
          key: "data-segmentation",
          title: "App Store Promotion Data",
        },
        {
          key: "promotion-expenses",
          title: "Promotion Expenses",
        },
        {
          key: "app-versions",
          title: "App Versions",
        },
        {
          key: "retained-users",
          title: "Retained Users",
        },
        {
          key: "active-users",
          title: "Active Users",
        },
        {
          key: "paying-users",
          title: "Paying Users",
        },
      ],
    },
    /*{
      title: "Push Notifications",
      key: "push-notifications",
      icon: "notification",
      submenus: [
        {
          key: "new",
          title: "New Notification",
        },
        {
          key: "history",
          title: "History",
        },
        {
          key: "configuration",
          title: "Configuration",
        },
      ],
    },*/
    {
      title: "Badge Page",
      key: "aic-badge",
      icon: "tag",
    },
    {
      title: "SDK & API Management",
      key: "sdk-management",
      icon: "android",
    },
    /*{
      title: "Help",
      key: "help",
      icon: "question-circle",
    },*/
  ];

  const handleChange = (e) => {
    let { keyPath } = e;
    let { appId } = props;
    if (!appId) {
      if (!props.apps || !props.apps.length) {
        notification.info({
          placement: "bottomLeft",
          message: "You need to add an app first.",
          description:
            "You can also switch to the Demo App on the top menu if you just want to explore the platform.",
          duration: 10,
        });
        history.push("/new");
      } else {
        appId = props.apps[0].id;
        redirectToPage(keyPath, appId);
      }
    } else {
      redirectToPage(keyPath, appId);
    }
  };

  const redirectToPage = (keyPath, appId) => {
    setDrawerOpen(false);
    if (keyPath.length === 2 && keyPath[1] === "cloud") {
      history.push({
        pathname: `/cloud/${keyPath[0].replace("cloud#", "")}/${appId}`,
      });
    } else if (keyPath.length === 2 && keyPath[1] === "shop") {
      history.push({
        pathname: `/shop/${keyPath[0].replace("#shop", "")}/${appId}`,
      });
    } else if (keyPath && keyPath.length > 1) {
      history.push({
        pathname: `/${keyPath[1]}/${appId}/${keyPath[0].split("#").pop()}`,
      });
    } else if (keyPath[0] === "new") {
      history.push("/new");
    } else {
      history.push({
        pathname: `/${keyPath[0]}/${appId}`,
      });
    }
  };

  const onOpenChange = (newOpenKeys) => {
    openKeys.forEach((key) => {
      let indexToRemove = newOpenKeys.findIndex((newKey) => {
        return newKey === key;
      });
      if (indexToRemove >= 0) {
        newOpenKeys.splice(indexToRemove, 1);
      }
    });
    setOpenKeys(newOpenKeys);
  };

  useEffect(() => {
    setOpenKeys(menuOption ? [menuOption] : []);
  }, []);

  let MenuContent = () => (
    <Menu
      mode="inline"
      onClick={handleChange}
      selectedKeys={[selectedKeys]}
      onOpenChange={onOpenChange}
      openKeys={openKeys}
    >
      {menuData.map((it) =>
        it.submenus && !it.visible ? (
          <SubMenu
            key={it.key}
            title={
              <span>
                <Icon type={it.icon} />
                <span>{it.title}</span>
              </span>
            }
          >
            {it.submenus.map((subOption) =>
              !subOption.visible ? (
                subOption.key === "app-information" ? (
                  <Menu.Item key={`${it.key}#${subOption.key}`}>
                    <NewFeature name="new.app.form.menu">
                      {subOption.title}
                    </NewFeature>
                  </Menu.Item>
                ) : (
                  <Menu.Item key={`${it.key}#${subOption.key}`}>
                    {subOption.title}
                  </Menu.Item>
                )
              ) : null
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={it.key}>
            <Icon type={it.icon} />
            <span>{it.title}</span>
          </Menu.Item>
        )
      )}
      {role === "PAYMENTS" ? null : <Menu.Item key="new" className={classes.addApp}>
        <Icon type="plus-square" />
        <span>Add New App</span>
      </Menu.Item>}
    </Menu>
  );

  let selectedKeys =
    menuOption !== section ? `${menuOption}#${section}` : section;

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img
          className={classes.Logo}
          src={companyLogoWhite}
          alt="Company Logo"
        />
        <img
          className={classes.AltLogo}
          src={companyLogoWhite}
          alt="Company Logo"
        />
        <Icon
          type="menu"
          onClick={() => setDrawerOpen(true)}
          className={classes.menuIcon}
        />
      </div>
      <div className={classes.sideMenu}>
        <MenuContent />
      </div>
      <Drawer
        placement="left"
        closable={true}
        title={<img src={logoGreen} className={classes.drawerLogo} />}
        onClose={() => setDrawerOpen(false)}
        visible={drawerOpen}
      >
        <div className={classes.drawerMenu}>
          <MenuContent />
        </div>
      </Drawer>
    </div>
  );
};

export default AntMenu;
