import React, { useState, useEffect } from "react";
import classes from "../PaymentsPlatform.module.less";
import {
  Button,
  Table,
  DatePicker,
  Input,
  Row,
  Col,
  Alert,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { getRefunds } from "../../../services/aicPay";
import functions from "../../../functions";
import { refunds_fake } from "../FakeData";
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { Option } = Select;
export default ({ app, useDemoData, ...props }) => {
  const [start_date, setStartDate] = useState(null)
  const [end_date, setEndDate] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [pagination, setPagination] = useState({});
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [useAsync, setUseAsync] = useState(true);
  const [searchFilter, setSearch] = useState({
    filter: undefined,
    value: null,
  });
  const searchOptions = [
    { value: "transaction", label: "Transaction ID" },
    { value: "customer_name", label: "User Name" },
  ];
  const [disableFilters, setDisabledFilters] = useState({
    user: false,
    date: false,
    transaction: false,
  });
  const [balance, setBalance] = useState(null);
  const tableColumns = [
    {
      title: "Phone Number",
      dataIndex: "user",
      key: "user",
      fixed: "left",
      width: 120,
      render: (user) =>
        user ? (
          <Link to={`/monetization/${app ? app.id : props.appId}/user/${user}`}>
            {user}
          </Link>
        ) : null,
    },
    {
      title: "Refund Date",
      dataIndex: "refund_date",
      key: "refund_date",
      sorter: true,
    },
    {
      title: "User Name",
      dataIndex: "customer_name",
      key: "customerName",
      render: (customer_name) =>
        customer_name ? (
          <Link
            to={`/monetization/${app ? app.id : props.appId
              }/user-unregistered/${customer_name}`}
          >
            {customer_name}
          </Link>
        ) : (
          ""
        ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Amount",
      dataIndex: "refund_amount",
      className: classes.alignRight,
      key: "refund_amount",
      sorter: true,
      render: (total) => <span>¥{total / 100}</span>,
    },
    {
      title: "Transaction ID",
      dataIndex: "pay_order_id",
      key: "pay_order_id",
      align: "center",
    },
  ];

  useEffect(() => {
    fetch({
      results: 10,
      page: 1,
      sortField: "gmt_refund",
      sortOrder: "descend",      
    });
  }, [app.id, useDemoData]);

  const fetch = async (params = {}) => {
    if (!useDemoData)
      try {
        if (!!app) {
          setLoading(true);
          const paymentsId = [
            app.alipay_id || "",
            app.wechat_id || "",
            app.seccond_alipay_id || "",
            app.seccond_wechat_id || "",
          ].join(",");
          const response = await getRefunds({
            ...params,
            app,
            id: paymentsId,
          });
          setPagination({
            total: response.total_results,
          });
          setValues(response.refunds);
          setBalance(response.balance || 0);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const field = {
      refund_date: "gmt_refund",
      refund_amount: "refund_amount",
    };

    const date =
      start_date != "" && end_date != ""
        ? { rangeDate: { start: start_date, end: end_date } }
        : {};

    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field ? field[sorter.field] : "gmt_refund",
      sortOrder: sorter.order || "descend",
      ...date,
    });
  };

  const onChangeDate = (_, [start, end]) => {
    setDisabled(false);
    setLoading(true);
    setStartDate(start);
    setEndDate(end);
    fetch({
      results: 10,
      page: 1,
      rangeDate: { start, end },
      sortField: "gmt_refund",
      sortOrder: "descend",
    });
  };

  const resetFilter = () => {
    setSearch({ filter: undefined, value: null });
    setUseAsync(true);
    setDisabled(true);
    setEndDate(null);
    setStartDate(null);
    setDisabledFilters({
      user: false,
      date: false,
      transaction: false,
    });
    fetch({
      results: 10,
      page: 1,
      sortField: "gmt_refund",
      sortOrder: "descend",
      rangeDate: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
    });
  };

  const getDate = () => {
    return `Total refunded from ${start_date ||
      moment()
        .startOf("month")
        .format("YYYY-MM-DD HH:mm")} until ${end_date ||
        moment().format("YYYY-MM-DD HH:mm")} : `;
  };
  const handleRequest = () => {
    const { filter, value } = searchFilter;
    setUseAsync(false);
    setDisabled(false);
    fetch({
      results: 10,
      page: 1,
      sortField: "gmt_refund",
      sortOrder: "descend",
      [filter]: value,
    });
  };
  return (
    <div className={classes.tableContainer}>
      <Row gutter={16}>
        <Col span={24}>
          {balance != null &&  start_date && end_date ? (
            <Alert
              style={{ marginBottom: 20 }}
              message={
                <p style={{ marginBottom: 0, textAlign: "left" }}>
                  {getDate()}{" "}
                  <strong>¥ {functions.styleAsNumber(balance)}</strong>
                </p>
              }
              type="info"
              showIcon
            />
          ) : null}
        </Col>
        <Col style={{ marginTop: 12 }} xs={24} sm={24} md={18} lg={12} xl={10}>
          <Row gutter={8}>
            {" "}
            <Col span={8}>
              <Select
                disabled={loading}
                loading={loading}
                value={searchFilter.filter}
                placeholder="Search By"
                style={{ width: "100%" }}
                onChange={(val) => setSearch({ ...searchFilter, filter: val })}
              >
                {searchOptions.map(({ label, value }) => (
                  <Option value={value}>{label}</Option>
                ))}
              </Select>
            </Col>
            <Col span={16}>
              <Search
                disabled={loading || !searchFilter.filter}
                onChange={(e) =>
                  setSearch({ ...searchFilter, value: e.target.value })
                }
                onSearch={handleRequest}
                value={searchFilter.value}
                style={{ width: "100%" }}
                placeholder="Type here..."
              />
            </Col>
          </Row>
        </Col>

        <Col style={{ marginTop: 12 }} xs={17} sm={17} md={17} lg={9} xl={7}>
          <RangePicker
            disabled={loading || searchFilter.filter}
            allowClear={false}
            style={{ width: "100%" }}
            value={start_date && end_date ? [moment(start_date), moment(end_date)] : null}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
          />
        </Col>
        <Col style={{ marginTop: 12 }} xs={7} sm={7} md={7} lg={4} xl={3}>
          <Button
            type="primary"
            onClick={resetFilter}
            disabled={disabled}
            style={{ width: "100%" }}
            loading={loading}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <div>
        <Table
          dataSource={useDemoData ? refunds_fake : values}
          rowKey={(record) => record.pay_order_id}
          columns={tableColumns}
          size="small"
          pagination={pagination}
          onChange={useAsync ? handleTableChange : () => { }}
          loading={loading}
          scroll={{ x: 700 }}
        />
      </div>
    </div>
  );
};
