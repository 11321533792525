import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import history from "../../history";
import AccountSettings from "./AccountSettings/AccountSettings";
import RolesAndPermissions from "./RolesAndPermissions/RolesAndPermissions";
import classes from "./Settings.module.less";
const { TabPane } = Tabs;

const Settings = ({ ...props }) => {
  let [tab, setTab] = useState(0);

  useEffect(() => {
    let { section } = props;
    setTab(section);
  }, []);

  const updateTab = section => {
    setTab(section);
    history.push(`/settings/${section}/${props.appId}`);
  };
  return (
    <Tabs
      activeKey={`${tab}`}
      onChange={e => updateTab(e)}
      className={classes.settings}
    >
      <TabPane tab="Profile" key="profile">
        <AccountSettings />
      </TabPane>
      <TabPane tab="Roles and Permissions" key="roles">
        <RolesAndPermissions {...props} />
      </TabPane>
    </Tabs>
  );
};

export default Settings;
