import axios from "../axios";

export const getAutoRenewals = async (appId) => {
  let response = null;
  try {
    response = await axios.get(`/payments/renewals/${appId}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const addAutoRenewal = async (itemId, values) => {
  let response = null;
  try {
    response = await axios.post(`/payments/renewals/${itemId}`, {
      ...values,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const editAutoRenewal = async (itemId, values) => {
  let response = null;
  try {
    response = await axios.put(`/payments/renewals/${itemId}`, {
      ...values,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const deleteAutoRenewal = async (itemId) => {
  let response = null;
  try {
    response = await axios.delete(`/payments/renewals/${itemId}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
