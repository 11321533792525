import React, { useState, useEffect } from "react";
import classes from "../PaymentsPlatform.module.less";
import { Button, Form, Input, notification, Row, Col, Spin } from "antd";
import {
  getAccountDetails,
  updateAccountDetails
} from "../../../services/paymentsService";
import { account_details_fake } from "../FakeData";
const AccountDetails = ({ app, form, useDemoData }) => {
  const [accountDetails, setAccountDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { getFieldDecorator } = form;

  useEffect(() => {
    getData();
  }, [app.id, useDemoData]);

  const getData = async (params = {}) => {
    if (!useDemoData) {
      try {
        if (!!app) {
          setLoading(true);
          let response = await getAccountDetails(app.id);
          setLoading(false);
          if (response.statusText === "OK") {
            if (response.data.account) {
              const { accData } = response.data.account;
              setAccountDetails(accData || {});
              setDisabled(!!accData);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setDisabled(true);
    }
  };

  const openNotificationWithIcon = type => {
    notification[type]({
      message: "Information saved.",
      placement: "bottomLeft"
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setUploading(true);
          await updateAccountDetails({
            account: {
              ...values,
              FK_App: app.id
            }
          });
          setUploading(false);
          setDisabled(true);
          openNotificationWithIcon("success");
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  return (
    <div className={classes.tableContainer}>
      <div style={{ marginTop: "20px" }}>
        {loading ? (
          <Spin />
        ) : (
          <Form onSubmit={handleSubmit}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Company Name">
                  {getFieldDecorator("company_name", {
                    initialValue: useDemoData
                      ? account_details_fake.company_name
                      : accountDetails.company_name,
                    rules: [{ required: true, message: "Required" }]
                  })(<Input placeholder="Company Name" disabled={disabled} />)}
                </Form.Item>
                <Form.Item label="Company Address">
                  {getFieldDecorator("company_address", {
                    initialValue: useDemoData
                      ? account_details_fake.company_address
                      : accountDetails.company_address,
                    rules: [{ required: true, message: "Required" }]
                  })(
                    <Input placeholder="Company Address" disabled={disabled} />
                  )}
                </Form.Item>
                <Form.Item label="Bank Name">
                  {getFieldDecorator("bank_name", {
                    initialValue: useDemoData
                      ? account_details_fake.bank_name
                      : accountDetails.bank_name,
                    rules: [{ required: true, message: "Required" }]
                  })(<Input placeholder="Bank Name" disabled={disabled} />)}
                </Form.Item>
                <Form.Item label="Bank Address">
                  {getFieldDecorator("bank_address", {
                    initialValue: useDemoData
                      ? account_details_fake.bank_address
                      : accountDetails.bank_address,
                    rules: [{ required: true, message: "Required" }]
                  })(<Input placeholder="Bank Address" disabled={disabled} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Account Number">
                  {getFieldDecorator("account_number", {
                    initialValue: useDemoData
                      ? account_details_fake.account_number
                      : accountDetails.account_number,
                    rules: [{ required: true, message: "Required" }]
                  })(
                    <Input placeholder="Account Number" disabled={disabled} />
                  )}
                </Form.Item>
                <Form.Item label="SWIFT Code">
                  {getFieldDecorator("swift_code", {
                    initialValue: useDemoData
                      ? account_details_fake.swift_code
                      : accountDetails.swift_code,
                    rules: [{ required: true, message: "Required" }]
                  })(<Input placeholder="SWIFT Code" disabled={disabled} />)}
                </Form.Item>
                <Form.Item label="Preferred Currency">
                  {getFieldDecorator("preferred_currency", {
                    initialValue: useDemoData
                      ? account_details_fake.preferred_currency
                      : accountDetails.preferred_currency,
                    rules: [{ required: true, message: "Required" }]
                  })(
                    <Input
                      placeholder="Preferred Currency"
                      disabled={disabled}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={disabled}
                loading={uploading}
                style={{ marginTop: "16px", width: "200px" }}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};
export default Form.create()(AccountDetails);
