import React, { useState, useEffect } from "react";
import { Modal, Input, Form, notification } from "antd";
import classes from "./PurchaseItemModal.module.less";
import {
  addAutoRenewal,
  editAutoRenewal,
} from "../../../../services/AutoRenewalItemsService";

const SubscriptionModal = ({
  form,
  visible,
  itemName,
  amount,
  id,
  itemId,
  onAdd,
  onEdit,
  onClose,
}) => {
  let [loading, setLoading] = useState(false);

  const { getFieldDecorator } = form;

  useEffect(() => {
    form.setFields({
      itemName: {
        value: itemName,
      },
      amount: {
        value: amount,
      },
    });
  }, [visible]);

  let handleResponse = (data, itemId, callback) => {
    if (!!data && !data.error) {
      notification.success({
        message: "Data Saved.",
        placement: "bottomLeft",
      });
      callback(data, itemId);
    } else {
      notification.error({
        message: "Unable to save the data.",
        placement: "bottomLeft",
      });
    }
  };

  let saveData = (event) => {
    event.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        console.log("ITEM ID", itemId);
        if (!itemId || itemId === "undefined") {
          let { data } = await addAutoRenewal(id, values);
          handleResponse(data.item, itemId, onAdd);
        } else {
          let newValues = {
            ...values,
            id: itemId,
          };
          await editAutoRenewal(itemId, newValues);
          handleResponse(newValues, itemId, onEdit);
        }
        setLoading(false);
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft",
        });
      }
    });
  };

  return (
    <div className={classes.container}>
      <Modal
        visible={visible}
        confirmLoading={loading}
        onOk={saveData}
        onCancel={onClose}
      >
        <Form.Item label="Agreement Title">
          {getFieldDecorator("itemName", {
            rules: [
              {
                required: true,
                message: "Required",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Period Amount">
          {getFieldDecorator("amount", {
            rules: [
              {
                required: true,
                message: "Required",
              },
            ],
          })(<Input type="number" />)}
        </Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(SubscriptionModal);
