import moment from "moment";
const sdks_fake = [
  {
    FK_App: "1234-8475-9439-9495",
    PK_Apk: "1",
    app_name: "Tap_unsign",
    createdAt: moment(),
    file_url: "1559552660485/Tencent_tap_unsign.apk",
    pkg_name: "tap.claim.nosig",
    report: null,
    sdk_list: "test sdk",
    sdks: [],
    updatedAt: moment(),
    version: "3.0",
    version_code: null,
  },
  {
    FK_App: "1234-8475-9439-9495",
    PK_Apk: "2",
    app_name: "Demo 1",
    createdAt: moment().subtract(1, "days"),
    file_url: "1559552660485/Tencent_tap_unsign.apk",
    pkg_name: "tap.claim.nosig",
    report: null,
    sdk_list: "test sdk",
    sdks: [],
    updatedAt: moment().subtract(1, "days"),
    version: "2.0",
    version_code: null,
  },
  {
    FK_App: "1234-8475-9439-9495",
    PK_Apk: "3",
    app_name: "Demo 2",
    createdAt: moment().subtract(2, "days"),
    file_url: "1559552660485/Tencent_tap_unsign.apk",
    pkg_name: "tap.claim.nosig",
    report: null,
    sdk_list: "test sdk",
    sdks: [],
    updatedAt: moment().subtract(2, "days"),
    version: "1.0",
    version_code: null,
  },
];
let translations_fake = [
  {
    FK_App: "1234-8475-9439-9495",
    PK_Translation: "1",
    createdAt: moment(),
    file_url: "1599085678283/test.txt",
    original_language: "English",
    report: null,
    text: null,
    updatedAt: moment(),
  },
  {
    FK_App: "1234-8475-9439-9495",
    PK_Translation: "2",
    createdAt: moment(),
    file_url: null,
    original_language: "English",
    report: null,
    text: "This is a demo text",
    updatedAt: moment(),
  },
];
export { sdks_fake, translations_fake };
