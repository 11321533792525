import React from "react";
import DataSet from "@antv/data-set";
import { Chart, Geom, Tooltip, Legend } from "bizcharts";
import { area } from "../../../fakeData";
const { AMapUI } = window;

export default class extends React.Component {
  constructGeoJSON = features => {
    if (!features) return false;

    if (Array.isArray(features)) {
      return {
        type: "FeatureCollection",
        features: [...features]
      };
    }
    return features;
  }; // 传入adcode获取geojson，部分数据需要处理一下

  getGeojsonByCode = (adcode = 100000, withSub = true) => {
    if (!AMapUI) {
      return Promise.reject();
    } // 文档：https://lbs.amap.com/api/javascript-api/reference-amap-ui/geo/district-explorer

    return new Promise((resolve, reject) => {
      AMapUI.load("ui/geo/DistrictExplorer", DistrictExplorer => {
        const districtExplorer = new DistrictExplorer();
        districtExplorer.loadAreaNode(adcode, (error, areaNode) => {
          if (error) {
            reject();
          }

          let res = null;

          if (withSub) {
            res = areaNode.getSubFeatures();
          } else {
            res = areaNode.getParentFeature();
          }

          resolve(this.constructGeoJSON(res));
        });
      });
    });
  }; // 绘制思路
  // 前提：页面加载高德地图及其UI的SDK，参考html页面
  // 1、通过高德的数据接口获取geojson数据
  // 2、通过Dataset进行数据处理
  // 3、绘制class

  constructor(props) {
    super(props);
    this.state = {
      chinaGeo: null
    };
  }

  componentDidMount() {
    this.getGeojsonByCode(100000, true).then(res => {
      this.setState({
        chinaGeo: res
      });
    });
  }

  processGeoData = (geoData, dataValue) => {
    const { features } = geoData;
    features.forEach(one => {
      const name = one && one.properties && one.properties.name;
      dataValue.forEach(item => {
        if (name.includes(item.name)) {
          one.value = item.value;
        }
      });
    });
    const geoDv = new DataSet.View().source(geoData, {
      type: "GeoJSON"
    });
    return geoDv;
  };

  render() {
    const { chinaGeo } = this.state;

    if (!chinaGeo) {
      return "Loading map...";
    }
    let mapData = this.props.useDemoData ? area : this.props.data;
    const data = this.processGeoData(chinaGeo, mapData);

    const scale = {
      latitude: {
        sync: true,
        nice: false
      },
      longitude: {
        sync: true,
        nice: false
      }
      /* value: {
        formatter: val => (val || 0).format("0.0%")
      }*/
    };
    return [
      <div
        key="1"
        style={{
          position: "relative"
        }}
      >
        <Chart
          height={500}
          width={645}
          scale={scale}
          data={data}
          padding={[0, 0, 0, 90]}
        >
          <Geom
            type="polygon"
            position="longitude*latitude"
            style={{
              lineWidth: 1,
              stroke: "#505050"
            }} // color={['value', ['#31c5f8', '#61d3f8', '#89dcfd', '#b0e8f8', '#d8f3ff']]}
            color={["value", ["#d9f4ff", "#33c5f6"]]}
            tooltip={[
              "name*value",
              (name, value) => ({
                name,
                value
                //value: (value || 0).format("0.0%")
              })
            ]}
          >
            <Tooltip showTitle={false} />
            <Legend
              position="bottom-left"
              offsetY={-130}
              offsetX={-60}
              slidable={false}
              width={320}
            />
          </Geom>
        </Chart>
      </div>
    ];
  }
}
