import moment from "moment";

const fakeData = [];
const beginDay = new Date().getTime();
for (let i = 0; i < 20; i += 1) {
  fakeData.push({
    x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    y: Math.floor(Math.random() * 100) + 10
  });
}
const downloadsFake = [];
for (let i = 0; i < 20; i += 1) {
  downloadsFake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    downloads: Math.floor(Math.random() * 1000000) + 100000
  });
}
const usersArrayFake = [];
for (let i = 0; i < 20; i += 1) {
  usersArrayFake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    count: Math.floor(Math.random() * 1000000) + 100000
  });
}
const transactionsArrayFake = [];
for (let i = 0; i < 20; i += 1) {
  transactionsArrayFake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    transactions_by_day: Math.floor(Math.random() * 10000000) + 1000000
  });
}
const forms = {
  business_form_status: "NOT_RECEIVED",
  app_form_status: "NOT_RECEIVED",
  copyright_form_status: "NOT_RECEIVED",
  security_form_status: "NOT_RECEIVED",
  corporate_client: false
};
let actualStores = [
  {
    name: "Tencent My App",
    market_coverage: 26.26,
    downloads: 260069000,
    link: "http://android.myapp.com/"
  },
  {
    name: "Huawei App Market",
    market_coverage: 14.7,
    downloads: 151361000,
    link: "http://app.hicloud.com/"
  },
  {
    name: "Oppo Software Store",
    market_coverage: 12.56,
    downloads: 129361000,
    link: "https://store.oppomobile.com/"
  },
  {
    name: "360 Mobile Assistant",
    market_coverage: 10.23,
    downloads: 105307000,
    link: "http://zhushou.360.cn/"
  },
  {
    name: "Baidu Mobile Assistant",
    market_coverage: 8.48,
    downloads: 87308000,
    link: "http://as.baidu.com/"
  },
  {
    name: "Vivo App Store",
    market_coverage: 8.29,
    downloads: 85350000,
    link: "https://developer.vivo.com.cn/doc/products/pc/index.html#firstPage"
  },
  {
    name: "MIUI App Store",
    market_coverage: 7.55,
    downloads: 77738000,
    link: "http://app.xiaomi.com/"
  },
  {
    name: "PP Assistant",
    market_coverage: 2.46,
    downloads: 23337000,
    link: "https://www.25pp.com/android/"
  },
  {
    name: "Anzhi Market",
    market_coverage: 2.24,
    downloads: 23090000,
    link: "http://www.anzhi.com/applist.html"
  },
  {
    name: "China Mobile MM Store",
    market_coverage: 1.76,
    downloads: 18170000,
    link: "http://mm.10086.cn/store"
  },
  {
    name: "Sogou Mobile Assistant",
    market_coverage: 1.45,
    downloads: 14891000,
    link: "http://zhushou.sogou.com/"
  },
  {
    name: "Wandoujia",
    market_coverage: 1.21,
    downloads: 12482000,
    link: "http://www.wandoujia.com/apps"
  },
  {
    name: "HiMarket",
    market_coverage: 0.98,
    downloads: 10074000,
    link: "http://apk.hiapk.com/"
  },
  {
    name: "Lenovo Le Store",
    market_coverage: 0.5,
    downloads: 5150000,
    link: "http://www.lenovomm.com/"
  },
  {
    name: "Samsung App Store",
    market_coverage: 0.49,
    downloads: 5025000,
    link: "https://www.samsung.com/my/apps/galaxy-apps/"
  },
  {
    name: "Coolpad",
    market_coverage: 0.38,
    downloads: 3888000,
    link: "http://www.coolmart.net.cn/"
  },
  {
    name: "China Unicom Wo Store",
    market_coverage: 0.38,
    downloads: 3888000,
    link: "http://store.wo.com.cn/"
  },
  {
    name: "ZTE App Store",
    market_coverage: 0.34,
    downloads: 3466000,
    link: "http://apps.ztems.com/"
  },
  {
    name: "Safe Market",
    downloads: 1463000,
    market_coverage: 0.14,
    link: null
  },
  {
    name: "China Telecom 189 Store",
    market_coverage: 0.13,
    downloads: 1463000,
    link: "https://www.189store.com/"
  },
  {
    name: "LeTV Store",
    market_coverage: 0.11,
    downloads: 1136000,
    link: "http://leplay.cn/"
  },
  {
    name: "AppChina",
    market_coverage: 0.11,
    downloads: 1097000,
    link: "http://www.appchina.com/"
  },
  {
    name: "Google Play",
    market_coverage: 0.04,
    downloads: 451000,
    link: "http://play.google.com/store"
  },
  {
    name: "91 Assistant",
    market_coverage: 0.04,
    downloads: 403000,
    link: "http://www.91.com/"
  },
  {
    name: "Cool Market",
    market_coverage: 0.03,
    downloads: 272000,
    link: "https://coolapk.com/apk/"
  },
  {
    name: "Meizu Flyme",
    market_coverage: 0.02,
    downloads: 180000,
    link: "http://app.meizu.com/"
  },
  {
    name: "Shouji",
    market_coverage: 0.02,
    downloads: 171000,
    link: "https://soft.shouji.com.cn/android.html"
  },
  {
    name: "ivvi App Store",
    market_coverage: 0.02,
    downloads: 156000,
    link: null
  },
  {
    name: "Dian Ming Market",
    market_coverage: 0.01,
    downloads: 115000,
    link: "http://www.dmrjkj.com/"
  },
  {
    name: "Daily Application Market",
    market_coverage: 0.01,
    downloads: 102000,
    link: null
  }
];
const storesPublished = [];
actualStores.forEach(store => {
  storesPublished.push({
    store: { name: store.name },
    distribution_status: "PUBLISHED",
    link: store.link
  });
});

const percentFake = 50;
const reviewsFake = Math.floor(Math.random() * 1000) + 100;
const transactionsFake = Math.floor(Math.random() * 1000) + 100;
const transactionsBalanceFake = Math.floor(Math.random() * 10000000) + 1000000;
const totalDownloadsFake = Math.floor(Math.random() * 10000000) + 1000000;
const usersFake = Math.floor(Math.random() * 1000) + 100;
const storesPaidFake = 10;
const storesUploadFake = 5;
export {
  fakeData,
  downloadsFake,
  forms,
  storesPublished,
  percentFake,
  reviewsFake,
  transactionsBalanceFake,
  transactionsFake,
  totalDownloadsFake,
  usersFake,
  storesPaidFake,
  storesUploadFake,
  usersArrayFake,
  transactionsArrayFake
};
