import React, { useState, useEffect } from "react";
import { Card, Table, Row, Col, DatePicker, Select, Button } from "antd";
import { getPaidUsersTable } from "../../../services/promotionService";
import { paidUsersTable } from "../PromotionFakeData";
import functions from "../../../functions";
import { getDate } from "../../../functions";
const { Option } = Select;
const { RangePicker } = DatePicker;
export default ({ app, useDemoData }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let [store, setStore] = useState(null);
  let [allStores, setAllStores] = useState([]);
  let [date, setDate] = useState(getDate());

  useEffect(() => {
    validateRequest();
  }, [app, store, date]);

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getPaidUsersTable({
      promotionId: app.analitycs_id,
      store,
      startDate: date[0].format("YYYY-MM-DD"),
      endDate: date[1].format("YYYY-MM-DD"),
      app: app.id,
    });
    setData(response.data);
    setAllStores(response.stores);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData(paidUsersTable);
        setLoading(false);
        setAllStores([
          {
            value: null,
            label: "All Stores",
          },
        ]);
      }, 500);
    }
  };

  const tableColumns = [
    {
      title: "App Store",
      dataIndex: "marketName",
      key: "marketName",
    },
    {
      title: "Paying Users",
      dataIndex: "paidUsers",
      key: "paidUsers",
      render: (value) => `${functions.styleAsNumber(value)}`,
    },
    {
      title: "Transactions",
      dataIndex: "noOrders",
      key: "noOrders",
      render: (value) => `${functions.styleAsNumber(value)}`,
    },
    {
      title: "Total Paid",
      dataIndex: "totalRevenue",
      key: "totalRevenue",
      render: (value) => `${functions.styleAsNumber(value)}`,
    },
  ];

  const exportToCsv = () => {
    const headers = [
      "App Store",
      "Paying Users",
      "Transactions",
      "Total Paid",
    ].join(",");
    const rows = data.map((it) => [...Object.values(it)].join(",")).join("\n");
    let csvContent = "data:text/csv;charset=utf-8," + `${headers}\n` + rows;
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Card style={{ marginTop: 15 }}>
      <Row gutter={16} style={{ marginTop: 15 }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          lg={{ span: 10 }}
          style={{ marginBottom: 15 }}
        >
          <RangePicker
            value={date}
            style={{ width: "100%" }}
            onChange={setDate}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 8 }}
          lg={{ span: 4 }}
          style={{ marginBottom: 15 }}
        >
          <Select
            defaultValue={null}
            style={{ width: "100%" }}
            onChange={setStore}
          >
            {allStores.map(({ value, label }) => (
              <Option value={value}>{label}</Option>
            ))}
          </Select>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 8 }}
          lg={{ offset: 6, span: 4 }}
          style={{ marginBottom: 15 }}
        >
          <Button
            type="primary"
            icon="file"
            style={{ width: "100%" }}
            onClick={exportToCsv}
          >
            Export
          </Button>
        </Col>
      </Row>
      <Table
        size="small"
        columns={tableColumns}
        loading={loading}
        dataSource={data}
        style={{ marginTop: 15 }}
        scroll={{ x: 500 }}
      />
    </Card>
  );
};
