import React, { useState, useEffect } from "react";
import { DatePicker, Row, Col, Spin, Button, Select } from "antd";
import moment from "moment";
import classes from "../AnalyticsHome.module.css";
import LineCard from "../../UI/LineCard/LineCard";
import PaymentRate from "./Graphics/PaymentRate";
import PaymentOrigin from "./Graphics/PaymentOrigin";
import { getPaymentsInformationService } from "../../../services/analyticsService";
import UncontractedAnalyticsMessage from "../UncontractedAnalyticsMessage/UncontractedAnalyticsMessage";
import { versions } from "../fakeData";
const { RangePicker } = DatePicker;
const { Option } = Select;
export default ({ app, useDemoData }) => {
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [versionSelected, setVersionSelected] = useState("");
  const [chartData, setChartData] = useState({
    paymentOrigin: [],
    paymentRate: [],
  });
  const defaultFilters = {
    start: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    version: "all",
  };

  useEffect(() => {
    setStart(defaultFilters.start);
    setEnd(defaultFilters.end);
    setVersionSelected(defaultFilters.version);
    fetch(defaultFilters.start, defaultFilters.end, defaultFilters.version);
  }, [app.id, useDemoData]);

  const onChangeDate = (_, [start, end]) => {
    fetch(start, end, versionSelected);
    setStart(start);
    setEnd(end);
  };
  const onChangeVersion = (value) => {
    setVersionSelected(value);
    fetch(start, end, value);
  };
  const resetFilter = () => {
    setStart(defaultFilters.start);
    setEnd(defaultFilters.end);
    setVersionSelected(defaultFilters.version);
    fetch(defaultFilters.start, defaultFilters.end, defaultFilters.version);
  };
  const fetch = async (start, end, version) => {
    if (!useDemoData && app)
      try {
        const accountId = [
          app.alipay_id || "",
          app.wechat_id || "",
          app.seccond_wechat_id || "",
          app.seccond_alipay_id || "",
        ].join(",");
        setLoading(true);
        const { data } = await getPaymentsInformationService(
          start,
          end,
          version,
          accountId
        );
        if (!data.error) {
          const { paymentOrigin, paymentRate } = data;
          setChartData({
            paymentOrigin: paymentOrigin || [],
            paymentRate: paymentRate || [],
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
  };

  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  ) : (
    <div className={classes.Container}>
      <UncontractedAnalyticsMessage app={app} useDemoData={useDemoData} />
      <Row gutter={16}>
        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
          <RangePicker
            value={[moment(start), moment(end)]}
            style={{ width: "100%", marginBottom: 10 }}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
          />
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
          <Select
            onChange={onChangeVersion}
            value={versionSelected}
            style={{ width: "100%", marginBottom: 10 }}
            disabled={!useDemoData}
          >
            {versions.map((it) => (
              <Option key={it.value} value={it.value} label={it.label}>
                {it.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
          <Button
            style={{ width: "100%", marginBottom: 10 }}
            type="primary"
            onClick={resetFilter}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12}>
          <LineCard
            title="Payment Origin By Store"
            style={{ textAlign: "center" }}
          >
            <PaymentOrigin
              data={chartData.paymentOrigin}
              useDemoData={useDemoData}
            />
          </LineCard>
        </Col>
        <Col xl={12}>
          <LineCard
            title="Payment Rate By Store"
            style={{ textAlign: "center" }}
          >
            <PaymentRate
              data={chartData.paymentRate}
              useDemoData={useDemoData}
            />
          </LineCard>
        </Col>
      </Row>
    </div>
  );
};
