import React from "react";
import { Card, List, Row, Statistic, Icon } from "antd";
import functions from "../../../../functions";
import classes from "./CardOverview.module.less";
export default ({ balance = {}, title }) => {
    const getValue = (value) => {
        return functions.styleAsNumber(
            value || 0
        )
    };
    const data = [
        {
            title: "Closed Orders:",
            content: <span>{getValue(balance.closedOrders)}</span>,
        },
        {
            title: "Paid Transactions:",
            content: <span>{getValue(balance.paidTransactions)}</span>,
        },
        {
            title: "Refunds:",
            content: <span>{getValue(balance.numberOfRefunds)}</span>,
        },
        {
            title: "Original Revenue:",
            content: <Statistic
                value={getValue(balance.originalRevenue)}
                precision={2}
                valueStyle={balance.originalRevenue && balance.originalRevenue > 0 ? { color: '#3f8600' } : {}}
                prefix={balance.originalRevenue && balance.originalRevenue > 0 ? <Icon type="arrow-up" /> : null}
                suffix="RMB"
            />,
        },
        {
            title: "Total Refunded:",
            content: <Statistic
                value={getValue(balance.totalRefunded)}
                precision={2}
                valueStyle={balance.totalRefunded && balance.totalRefunded > 0 ? { color: '#cf1322' } : {}}
                prefix={balance.totalRefunded && balance.totalRefunded > 0 ? <Icon type="arrow-down" /> : null}
                suffix="RMB"
            />,
        },
        {
            title: "Final Revenue:",
            content: <Statistic
                value={getValue(balance.finalRevenue)}
                precision={2}
                suffix="RMB"
            />,
        },
    ];
    return (
        <Card className={classes.container} size="small" title={title}>
            <List
                size="small"
                dataSource={data}
                renderItem={(item) => (
                    <List.Item key={item.title}>
                        <Row type="flex" justify="space-between" style={{ width: "100%" }}>
                            <List.Item.Meta
                                style={{ textAlign: "left" }}
                                title={item.title}
                            />
                            <div>{item.content}</div>
                        </Row>
                    </List.Item>
                )}
            />
        </Card>
    );
};
