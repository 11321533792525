import React, { useEffect, useState } from "react";
import classes from "./PushDetails.module.less";
import {
  Form,
  Row,
  Col,
  Input,
  Radio,
  Select,
  notification,
  DatePicker,
  Button,
  Skeleton,
  Tag,
  Modal,
  Alert,
} from "antd";
import LineCard from "../../UI/LineCard/LineCard";
import {
  updateNewPushNotification,
  getAllTags,
  getPushDetails,
  deletePushNotification,
} from "../../../services/pushNotificationsService";
import PushNotificationDemo from "../PushNotificationDemo/PushNotificationDemo";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import history from "../../../history";
const { Item } = Form;

const NewPushNotification = ({ form, apps, appId, pushId }) => {
  let [loading, setLoading] = useState(false);
  let [preLoading, setPreloading] = useState(true);
  let [editting, setEditting] = useState(false);
  let [tags, setTags] = useState([]);
  let [details, setDetails] = useState({});
  let app = apps.find((app) => app.id === appId);

  const { getFieldDecorator } = form;

  let getTags = async () => {
    let response = await getAllTags(app.id);
    if (response && response.data && response.data.tags) {
      setTags(response.data.tags);
    }
  };

  let getDetails = async () => {
    let response = await getPushDetails(app.analitycs_id, app.id, pushId);
    if (response && response.data && response.data.details) {
      setDetails(response.data.details);
    }
    setPreloading(false);
  };

  useEffect(() => {
    getTags();
    getDetails();
  }, []);

  const showNotification = (type, message) => {
    notification[type]({
      message,
    });
  };

  let handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      let send_time =
        values.send_option === "NOW"
          ? moment()
              .tz("Asia/Shanghai")
              .format("YYYY-MM-DDTHH:mm")
          : moment(values.send_time).format("YYYY-MM-DDTHH:mm");
      if (!err && !loading) {
        setLoading(true);
        let result = await updateNewPushNotification(details.pushId, app.id, {
          ...values,
          send_time,
        });
        if (result && result.data && result.data.message === "success") {
          showNotification("success", "Information saved!");
          setEditting(false);
        } else {
          showNotification("error", "An error happened");
        }
        setLoading(false);
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft",
        });
      }
    });
  };

  let getAppIcon = () => {
    let imageData = app.image_data ? app.image_data : "";
    return (imageData = /uploads|static|googleusercontent/.test(imageData)
      ? imageData
      : `data:image/png;base64,${imageData}`);
  };

  let createStatusTag = (status) => {
    switch (status) {
      case "SENT":
        return <Tag color="green">Sent</Tag>;
      case "ERROR":
        return <Tag color="red">Error</Tag>;
      case "PENDING":
        return <Tag color="blue">Pending</Tag>;
      default:
        return null;
    }
  };

  let promptDeletion = () => {
    Modal.confirm({
      title: "Do you want to delete the push notification?",
      content:
        "The scheduled push notification won't be sent to the users anymore.",
      async onOk() {
        let response = await deletePushNotification(details.pushId);
        if (response && response.data && response.data.message === "success") {
          notification.success({
            message: "Push notification deleted",
          });
          history.push(`/push-notifications/${appId}/history`);
        } else {
          notification.error({
            message: "Error while deleting",
          });
        }
      },
      onCancel() {},
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.intro}>
        <LineCard shadow>
          <div className={classes.buttons}>
            <Link to={`/push-notifications/${appId}/history`}>
              <Button icon="left">Back</Button>
            </Link>
            {details.status !== "PENDING" || preLoading ? null : (
              <div>
                {editting ? (
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    icon="save"
                    disabled={loading}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => setEditting(true)}
                    icon="edit"
                  >
                    Modify
                  </Button>
                )}
                <Button
                  style={{ marginLeft: "16px" }}
                  type="danger"
                  ghost
                  onClick={promptDeletion}
                  icon="delete"
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        </LineCard>
      </div>
      <div className={classes.content}>
        <Form colon={false} layout="vertical" className={classes.form}>
          <div>
            <LineCard title="Push Notification Details" shadow>
              <Skeleton loading={preLoading} active={true}>
                <Row gutter={16} type="flex" justify="space-around">
                  <Col span={24}>
                    <p>
                      <b>Status:</b> {createStatusTag(details.status)}
                      <br />
                    </p>
                  </Col>
                </Row>
                <Row gutter={16} type="flex" justify="space-around">
                  <Col span={24}>
                    <Item label="Title">
                      {getFieldDecorator(`title`, {
                        rules: [
                          {
                            required: true,
                            message: "Required",
                          },
                        ],
                        initialValue: details.title,
                      })(
                        <Input
                          disabled={!editting || details.status === "SENT"}
                        />
                      )}
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Item label="Body">
                      {getFieldDecorator(`body`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: details.body,
                      })(
                        <Input.TextArea
                          disabled={!editting || details.status === "SENT"}
                        />
                      )}
                    </Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Item label="Target Audience">
                      {getFieldDecorator("pushTarget", {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: details.pushTarget,
                      })(
                        <Radio.Group
                          disabled={!editting || details.status === "SENT"}
                        >
                          <Radio value="ALL">All Users</Radio>
                          <Radio value="TAG">By Tag</Radio>
                        </Radio.Group>
                      )}
                    </Item>
                  </Col>
                </Row>
                {form.getFieldValue("pushTarget") === "TAG" ? (
                  <Row>
                    <Col span={24}>
                      <Item label="Select Tag">
                        {getFieldDecorator("targetValue", {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: details.targetValue,
                        })(
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            disabled={!editting || details.status === "SENT"}
                          >
                            {tags.map((it) => (
                              <Select.Option value={it.title}>
                                {it.title}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Item>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col span={24}>
                    <Item label="Time to be sent">
                      {getFieldDecorator("send_option", {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: details.send_option,
                      })(
                        <Radio.Group
                          disabled={!editting || details.status === "SENT"}
                        >
                          <Radio value="NOW">Now</Radio>
                          <Radio value="SCHEDULED">Scheduled</Radio>
                        </Radio.Group>
                      )}
                    </Item>
                  </Col>
                </Row>
                {form.getFieldValue("send_option") === "SCHEDULED" ? (
                  <Row>
                    <Col span={24}>
                      <Alert
                        message="Warning"
                        description="The time you select must be in China Standard Time (GMT+8)."
                        type="warning"
                        showIcon
                      />
                      <p></p>
                      <Item label="Select Time">
                        {getFieldDecorator("send_time", {
                          rules: [
                            {
                              required: true,
                              validator: (rule, value, callback) => {
                                if (
                                  moment(value).isAfter(
                                    moment(
                                      moment()
                                        .tz("Asia/Shanghai")
                                        .format("YYYY-MM-DDTHH:mm:00")
                                    )
                                  )
                                ) {
                                  callback();
                                } else {
                                  callback(
                                    "The time to be sent must be higher than right now in China"
                                  );
                                }
                              },
                            },
                          ],
                          initialValue: moment(details.send_time),
                        })(
                          <DatePicker
                            disabled={!editting || details.status === "SENT"}
                            showTime={{ format: "HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                            placeholder="Select Time"
                          />
                        )}
                      </Item>
                    </Col>
                  </Row>
                ) : null}
              </Skeleton>
            </LineCard>
          </div>
        </Form>
        <PushNotificationDemo
          appName={app ? app.name : ""}
          title={form.getFieldValue("title")}
          body={form.getFieldValue("body")}
          send_option={form.getFieldValue("send_option")}
          time={form.getFieldValue("send_time")}
          icon={getAppIcon()}
        />
      </div>
      {details.stats && details.stats.pushModel ? (
        <LineCard shadow title="Statistics" className={classes.stats}>
          <Row gutter={16}>
            <Col span={8}>
              <b>Send Count: </b>
              {details.stats.sendCnt}
            </Col>
            <Col span={8}>
              <b>Received Count: </b>
              {details.stats.receiveCnt}
            </Col>
            <Col span={8}>
              <b>Accept Count: </b>
              {details.stats.acceptCnt}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <b>Open Count: </b>
              {details.stats.openCnt}
            </Col>
            <Col span={8}>
              <b>Delete Count: </b>
              {details.stats.deleteCnt}
            </Col>
          </Row>
        </LineCard>
      ) : null}
    </div>
  );
};

export default Form.create()(NewPushNotification);
