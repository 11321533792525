import React from "react";
import TrendPerVersion from "../DataSegmentation/TrendPerVersion/TrendPerVersion";
import UncontractedMessage from "../UncontractedAppPromotion/UncontractedMessage";
import AppVersionsTable from "../AppVersions/AppVersionsTable";

export default ({ app, useDemoData }) => {
  return (
    <>
      <UncontractedMessage app={app} useDemoData={useDemoData} />
      <TrendPerVersion app={app} useDemoData={useDemoData} />
      <AppVersionsTable app={app} useDemoData={useDemoData} />
    </>
  );
};
