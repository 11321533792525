import React from "react";
import { Form } from "antd";
import UploadApk from "../UploadApk/UploadApk";
import AppInformation from "../AppInformation/AppInformation";
import Screenshots from "../Screenshots/Screenshots";
import DistributionDetails from "../DistributionDetails/DistributionDetails";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
const Preview = ({ data, form, app }) => {
  const { getFieldDecorator } = form;
  const apkMode =
    data.multi_apks && data.multi_apks.length > 0 ? "multiple" : "single";
  return (
    <div>
      {data.is_apk_update ? (
        <UploadApk
          data={data}
          disabled={true}
          mode={apkMode}
          getFieldDecorator={getFieldDecorator}
        />
      ) : null}
      <AppInformation
        data={data}
        disabled={true}
        getFieldDecorator={getFieldDecorator}
      />
      <DistributionDetails
        data={data}
        disabled={true}
        getFieldDecorator={getFieldDecorator}
      />
      <Screenshots
        data={data}
        disabled={true}
        getFieldDecorator={getFieldDecorator}
      />
      <PrivacyPolicy
        app={app}
        data={data}
        disabled={true}
        getFieldDecorator={getFieldDecorator}
      />
    </div>
  );
};
export default Form.create()(Preview);
