import React, { Component } from "react";
import classes from "./Tooltip.module.css";

class Tooltip extends Component {
  state = {
    offsetLeft: 0
  };
  elementWidth = 200;

  render() {
    //this gets calculated to adjust the tooltip position if it's overflowing the window (causing a scrollbar)
    let divStyle = {
      left: "calc(50% + " + this.state.offsetLeft + "px)",
      width: this.props.width ? this.props.width : this.elementWidth
    };
    let iStyle = { left: "calc(50% - " + (this.state.offsetLeft + 10) + "px)" };

    return (
      <span
        className={classes.Container}
        style={this.props.align ? { textAlign: this.props.align } : null}
      >
        <span
          className={classes.Tooltip}
          ref={divElement => (this.divElement = divElement)}
          style={divStyle}
        >
          <i style={iStyle} />
          {this.props.content}
        </span>
        {this.props.children}
      </span>
    );
  }
  componentDidMount() {
    this.updatePosition();
  }
  updatePosition = () => {
    let xPos = this.divElement.getBoundingClientRect().x;
    if (xPos < 0 && window.innerWidth <= 770) {
      //mobile version
      this.setState({ offsetLeft: -1 * xPos });
    } else if (xPos < 340 && window.innerWidth > 770) {
      //desktop version and overflowing on the left
      this.setState({ offsetLeft: -1 * xPos + 340 });
    }
    if (xPos < 0) {
      this.setState({ offsetLeft: -1 * xPos }); //any version and overflowing on the right
    } else {
      let offsetLeft =
        window.innerWidth -
        xPos -
        (this.props.width ? this.props.width : this.elementWidth) -
        30;
      if (offsetLeft < 30) this.setState({ offsetLeft: offsetLeft });
    }
  };
}

export default Tooltip;
