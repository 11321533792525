import moment from "moment";
let transactions_fake = [];

const beginDay = new Date().getTime();
for (let i = 0; i < 20; i += 1) {
  transactions_fake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    store: `Store 1`,
    source: "2019030863464735",
    revenue_by_day: Math.floor(Math.random() * 4000) + 500,
    transactions_by_day: Math.floor(Math.random() * 4000) + 500
  });
}
for (let i = 0; i < 20; i += 1) {
  transactions_fake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    store: `Store2`,
    source: "wx69032c955650db5c",
    revenue_by_day: Math.floor(Math.random() * 4000) + 500,
    transactions_by_day: Math.floor(Math.random() * 4000) + 500
  });
}
const pie_chart = {
  alipay: Math.floor(Math.random() * 4000) + 500,
  wechat: Math.floor(Math.random() * 4000) + 500
};
const revenueInfo = {
  activeAutoRenewals: Math.floor(Math.random() * 4000) + 500,
  newAutoRenewals: Math.floor(Math.random() * 4000) + 500,
  cancelledRenewals: Math.floor(Math.random() * 4000) + 500,
  autoRenewalsAmount: Math.floor(Math.random() * 4000) + 500,
  generalOverview: {
    closedOrders: Math.floor(Math.random() * 4000) + 500,
    paidTransactions: Math.floor(Math.random() * 4000) + 500,
    numberOfRefunds: Math.floor(Math.random() * 4000) + 500,
    originalRevenue: Math.floor(Math.random() * 4000) + 500,
    totalRefunded: Math.floor(Math.random() * 4000) + 500,
    finalRenevue: Math.floor(Math.random() * 4000) + 500,
  },
  weChatOverview: {
    closedOrders: Math.floor(Math.random() * 4000) + 500,
    paidTransactions: Math.floor(Math.random() * 4000) + 500,
    numberOfRefunds: Math.floor(Math.random() * 4000) + 500,
    originalRevenue: Math.floor(Math.random() * 4000) + 500,
    totalRefunded: Math.floor(Math.random() * 4000) + 500,
    finalRenevue: Math.floor(Math.random() * 4000) + 500,
  },
  alipayOverview: {
    closedOrders: Math.floor(Math.random() * 4000) + 500,
    paidTransactions: Math.floor(Math.random() * 4000) + 500,
    numberOfRefunds: Math.floor(Math.random() * 4000) + 500,
    originalRevenue: Math.floor(Math.random() * 4000) + 500,
    totalRefunded: Math.floor(Math.random() * 4000) + 500,
    finalRenevue: Math.floor(Math.random() * 4000) + 500,
  },
};

const users_fake = [];
for (let i = 0; i < 20; i += 1) {
  users_fake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    count: Math.floor(Math.random() * 4000) + 500,
    type: 'Phone Number'
  });
  users_fake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    count: Math.floor(Math.random() * 4000) + 500,
    type: 'User Name'
  });
}

const transactions_table_fake = [];
for (let i = 0; i < 20; i += 1) {
  let val = Math.floor(Math.random() * 2) + 1;
  transactions_table_fake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    amount: Math.floor(Math.random() * 4000) + 500,
    biz_no: "981289316477218478127498184Demo",
    goods_title: "Demo",
    pay_channel: Math.floor(Math.random() * 1) + 0 === 0 ? "01" : "11",
    platform: val === 1 ? "wx69032c955650db5c" : "2019030863464735",
    refund: null,
    customer_name: `Customer ${i + 1}`,
    store: `Store Demo ${i + 1}`,
    status: val === 1 ? "11" : "21",
    transaction_id: (
      Math.floor(Math.random() * 130000000000) + 100000000000
    ).toString(),
    renewal_agreement: (
      Math.floor(Math.random() * 160000000000) + 140000000000
    ).toString(),
    user: Math.floor(Math.random() * 130000000) + 100000000
  });
}

const users_table_fake = [];
for (let i = 0; i < 20; i += 1) {
  users_table_fake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    mobile_no: (Math.floor(Math.random() * 130000000) + 100000000).toString(),
    customer_name: (
      Math.floor(Math.random() * 130000000000) + 100000000000
    ).toString(),
  });
}
const user_details_fake = {
  transactions: [],
  refunds: [],
  renewals: []
};
for (let i = 0; i < 20; i += 1) {
  let val = Math.floor(Math.random() * 2) + 1;
  user_details_fake.transactions.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    transaction_id: (
      Math.floor(Math.random() * 900000000000) + 200000000000
    ).toString(),
    amount: Math.floor(Math.random() * 4000) + 500,
    goods_title: "Demo",
    pay_channel: Math.floor(Math.random() * 1) + 0 === 0 ? "01" : "11",
    platform: val === 1 ? "wx69032c955650db5c" : "2019030863464735",
    refund: null,
    status: val === 1 ? "11" : "21"
  });
}
for (let i = 0; i < 20; i += 1) {
  user_details_fake.refunds.push({
    refund_date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    pay_order_id: (
      Math.floor(Math.random() * 130000000000) + 100000000000
    ).toString(),
    refund_amount: Math.floor(Math.random() * 4000) + 500,
    details: "Demo",
    renewal_agreement: (
      Math.floor(Math.random() * 130000000000) + 100000000000
    ).toString(),
  });
}
for (let i = 0; i < 20; i += 1) {
  let val = Math.floor(Math.random() * 3) + 1;
  user_details_fake.renewals.push({
    cancelled_date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    agreement_sign_date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    renewal_date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    renewal_period: Math.floor(Math.random() * 31) + 7,
    renewal_id: (
      Math.floor(Math.random() * 130000000000) + 100000000000
    ).toString(),
    amount: Math.floor(Math.random() * 4000) + 500,
    status: val === 1 ? "error" : val === 2 ? "active" : "cancelled"
  });
}
const refunds_fake = [];

for (let i = 0; i < 20; i += 1) {
  refunds_fake.push({
    refund_date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    transaction_id: (
      Math.floor(Math.random() * 130000000000) + 100000000000
    ).toString(),
    pay_order_id: (
      Math.floor(Math.random() * 130000000000) + 100000000000
    ).toString(),
    user: (Math.floor(Math.random() * 130000000) + 100000000).toString(),
    refund_amount: Math.floor(Math.random() * 4000) + 500,
    customer_name: `Customer ${i + 1}`,
    details: "Demo",
    renewal__id: (
      Math.floor(Math.random() * 130000000000) + 100000000000
    ).toString()
  });
}
const statement_reports_fake = [];
for (let i = 0; i < 4; i += 1) {
  statement_reports_fake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    details: "Demo report."
  });
}
const transfer_history_fake = [];
for (let i = 0; i < 4; i += 1) {
  transfer_history_fake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    details: "Demo transfer.",
    amount: `USD $${Math.floor(Math.random() * 4000)}.00`
  });
}
const account_details_fake = {
  company_name: "Company Demo",
  company_address: "Company Address Demo",
  bank_name: "Bank Demo",
  bank_address: "Bank Address Demo",
  swift_code: "DEMODEMO123",
  preferred_currency: "EUR",
  account_number: Math.floor(Math.random() * 900000000) + 100000000
};
const renewal_info_fake = {
  user: "12345074385395",
  status: "active",
  renewal_period: "7",
  renewal_date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * 1)).format(
    "YYYY-MM-DD"
  ),
  cancelled_date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * 1)).format(
    "YYYY-MM-DD"
  ),
  comments: "Comments..."
};
const renewals_table_fake = [];
for (let i = 0; i < 20; i += 1) {
  let val = Math.floor(Math.random() * 2) + 1;
  renewals_table_fake.push({
    renewal_date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    cancelled_date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format(
      "YYYY-MM-DD"
    ),
    comments: "Demo",
    renewal_period: Math.floor(Math.random() * 31) + 7,
    status: val === 1 ? "error" : val === 2 ? "active" : "cancelled",
    renewal_id: (
      Math.floor(Math.random() * 130000000000) + 100000000000
    ).toString(),
    user: (
      Math.floor(Math.random() * 160000000000) + 140000000000
    ).toString(),
  });
}
export {
  revenueInfo,
  transactions_fake,
  renewal_info_fake,
  pie_chart,
  users_fake,
  transactions_table_fake,
  users_table_fake,
  user_details_fake,
  refunds_fake,
  statement_reports_fake,
  transfer_history_fake,
  account_details_fake,
  renewals_table_fake
};
