import React, { useState } from "react";
import { Form, Row, Col, Input, Modal, Alert } from "antd";
import LineCard from "../../../../../UI/LineCard/LineCard";
import File from "../../../../../UI/File/File";
import privacyScreenshot1 from "../../../../../../assets/privacy_image_1.png";
import privacyScreenshot2 from "../../../../../../assets/privacy_image_2.png";
import classes from "./PrivacyPolicy.module.less";
import long_screenshot from "../../../../../../assets/long_screenshot.jpeg";
import vivo_screenshot from "../../../../../../assets/privacy_vivo_tencent.jpeg";
import vivo_tencent_pdf from "../../../../../../assets/privacy_vivo_tencent.pdf";
import short_screenshot from "../../../../../../assets/short_screenshot.jpeg";

const { Item } = Form;

export default ({
  getFieldDecorator,
  autoSave,
  isRequired,
  disabled = false,
  data = {},
  submitFileHandler,
  deleteFile,
  app,
}) => {
  let [modalVisible, setModalVisible] = useState(false);
  let [modalVivoVisible, setModalVivoVisible] = useState(false);
  return (
    <>
      <LineCard
        title="Privacy Policy (General)"
        className={classes.container}
        shadow
      >
        <Row>
          <Col span={24}>
            <Item label="Please provide a link to your privacy policy. Must be in Chinese.">
              {getFieldDecorator(`privacy_policy`, {
                initialValue: data.privacy_policy,
                rules: [{ required: true, message: "Required" }],
                onChange: (event) =>
                  autoSave({ privacy_policy: event.target.value }),
              })(<Input disabled={disabled} />)}
            </Item>
          </Col>
          <p>
            All App Stores require proof that your app complies with data
            privacy laws. Please submit two screenshots for confirmation,
            following these examples:
          </p>
          <Row gutter={36} type="flex" justify="space-between">
            <Col xs={24} sm={12}>
              <Row gutter={16}>
                <p className={classes.intro}>
                  1. This screenshot must show the pop-up message for user
                  confirmation of the privacy policy. In JPG or PNG format. When
                  clicking on the links in the pop up window, the user must stay
                  in the app. The links cannot bring the user to a browser
                  window. This window should also not be close-able. The only
                  way for user to exit out of the screen is to hit accept or
                  decline. If the user selects decline, the app should close
                  out. If the user selects accept they can continue using the
                  app.
                </p>
                <Col span={12}>
                  <div className={classes.privacyExample}>
                    <img
                      src={privacyScreenshot2}
                      title="Document"
                      alt="Document"
                    />
                    <label>Example</label>
                  </div>
                </Col>
                <Col span={12}>
                  <Item>
                    {getFieldDecorator(`privacy_screenshot_1`, {
                      initialValue: data.privacy_screenshot_1,
                      rules: [{ required: true, message: "Required" }],
                    })(
                      <File
                        name="privacy_screenshot_1"
                        type="Image"
                        title="Screenshot 1 *"
                        validation={{
                          fileRequired: isRequired,
                          size: 1000000,
                        }}
                        disabled={disabled}
                        onDelete={() => deleteFile("privacy_screenshot_1")}
                        format={["png", "jpg", "jpeg"]}
                        submitFile={submitFileHandler}
                        previewWidth={480}
                        previewHeight={955}
                      />
                    )}
                  </Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={12}>
              <Row gutter={16}>
                <p className={classes.intro}>
                  2. This screenshot must show the permanent links to your
                  privacy policy and terms of service within the app itself.
                </p>
                <Col span={12}>
                  <div className={classes.privacyExample}>
                    <img
                      src={privacyScreenshot1}
                      title="Document"
                      alt="Document"
                    />
                    <label>Example</label>
                  </div>
                </Col>
                <Col span={12}>
                  <Item>
                    {getFieldDecorator(`privacy_screenshot_2`, {
                      initialValue: data.privacy_screenshot_2,
                      rules: [{ required: true, message: "Required" }],
                    })(
                      <File
                        name="privacy_screenshot_2"
                        type="Image"
                        title="Screenshot 2 *"
                        validation={{
                          fileRequired: isRequired,
                          size: 1000000,
                        }}
                        disabled={disabled}
                        onDelete={() => deleteFile("privacy_screenshot_2")}
                        format={["png", "jpg", "jpeg"]}
                        submitFile={submitFileHandler}
                        showPreview
                        previewWidth={480}
                        previewHeight={955}
                      />
                    )}
                  </Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </LineCard>
      <LineCard
        title="Privacy Policy (Detailed)"
        className={classes.container}
        shadow
      >
        <p>
          Tencent requires a file with the full text of the privacy policy which
          should cover the entire privacy policy of your app. If this file is
          not provided the app won't be accepted by the store.
        </p>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Item>
              {getFieldDecorator(`long_privacy_screenshot`, {
                rules: [
                  {
                    required: app.main_information.publish_on_tencent,
                    message: "Required",
                  },
                ],
                initialValue: data.long_privacy_screenshot,
              })(
                <File
                  type="File"
                  name="long_privacy_screenshot"
                  validation={{
                    fileRequired: app.main_information.publish_on_tencent,
                    size: 20971520,
                  }}
                  title="File *"
                  disabled={disabled}
                  onDelete={() => deleteFile("long_privacy_screenshot")}
                  submitFile={submitFileHandler}
                />
              )}
            </Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className={classes.requirements}>
              <b>Requirements:</b>
              <ol>
                <li>
                  The content of the privacy policy must be consistent with the
                  content of the privacy policy agreement in the APP.
                </li>
                <li>
                  If the privacy policy agreement contains a link, please make
                  sure you provide it to us.
                </li>
              </ol>
              <Modal
                title="Example File"
                visible={modalVisible}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
              >
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <label>Where it will be displayed on the store.</label>
                    <div className={classes.scrollable}>
                      <img src={short_screenshot} alt="" />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <label>
                      What you should submit.
                      <br />
                      <a
                        href={long_screenshot}
                        download
                        target="_blank"
                        className={classes.download}
                      >
                        Download Example File
                      </a>
                    </label>
                    <div className={classes.scrollable}>
                      <img src={long_screenshot} alt="" />
                    </div>
                  </Col>
                </Row>
              </Modal>
            </div>
          </Col>
        </Row>
      </LineCard>
      <LineCard
        title="Extra Requirements for Vivo and Tencent"
        className={classes.container}
        shadow
      >
        <ol>
          <li>
            In your app's in-app privacy policy page, please list out the
            permissions your app is requesting from the user. Along with the
            permissions you're requesting, please also list out what function
            these permissions serve.
          </li>
          <li>
            In your webpage privacy policy, please list out the permissions your
            app is requesting from the user. Along with the permissions you're
            requesting, please also list out what function these permissions
            serve.
          </li>
        </ol>
        <Alert
          type="warning"
          showIcon
          message="Important: This requirement MUST be in Chinese. This is currently required for submission only on Tencent and Vivo."
        ></Alert>
        <br />
        <p>
          Please{" "}
          <a
            style={{ textDecoration: "underline" }}
            onClick={() => setModalVivoVisible(true)}
          >
            click here
          </a>{" "}
          to see example implementation in Chinese.
        </p>
        <p>
          Please{" "}
          <a
            style={{ textDecoration: "underline" }}
            target="_blank"
            href={vivo_tencent_pdf}
          >
            click here
          </a>{" "}
          to see example implementation in English.
        </p>
        <Modal
          title="Example File"
          visible={modalVivoVisible}
          onOk={() => setModalVivoVisible(false)}
          onCancel={() => setModalVivoVisible(false)}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <img
                src={vivo_screenshot}
                alt=""
                style={{
                  display: "block",
                  width: "100%",
                }}
              />
            </Col>
          </Row>
        </Modal>
      </LineCard>
    </>
  );
};
