import React, { useState, useEffect, Fragment } from "react";
import {
  Form,
  Input,
  Modal,
  notification,
  Button,
  Checkbox,
  Row,
  Col,
  Popover,
  Icon,
} from "antd";
import classes from "./AICBadge.module.less";
import { ChromePicker } from "react-color";
import File from "../../UI/File/File";
import {
  getBadgeSettings,
  updateBadgeSettings,
} from "../../../services/badgeSettingsService";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";
import LineCard from "../../UI/LineCard/LineCard";
const { Item } = Form;
const { confirm } = Modal;
const AICBadge = ({ form, appId }) => {
  let [loadingSaving, setLoadingSaving] = useState(false);
  let [settings, setSettings] = useState({});
  let [packageName, setPackageName] = useState("");
  let [loading, setLoading] = useState(false);
  let [useCustomColor, setUseCustomColor] = useState(false);
  let [customColor, setCustomColor] = useState(false);
  let [defaultLogo, setDefaultLogo] = useState(false);
  let [stores, setStores] = useState([]);
  const { getFieldDecorator } = form;
  const handleSubmit = (event) => {
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let storesHidden = [];
        Object.keys(values).forEach((key) => {
          if (key.includes("stores_hidden")) {
            if (values[key] === false) {
              storesHidden.push(key.replace("stores_hidden_", ""));
            }
          }
        });
        let stores_hidden =
          storesHidden.length > 0 ? storesHidden.join(",") : "";
        let data = {
          ...values,
          stores_hidden: stores_hidden,
          custom_logo_url: values.custom_logo_url ? values.custom_logo_url : "",
        };
        confirm({
          title: "Are you sure you want to save all new information?",
          onOk() {
            updateSettings(data);
          },
          onCancel() {},
        });
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft",
        });
      }
    });
  };
  const updateSettings = async (values) => {
    setLoadingSaving(true);
    let { data } = await updateBadgeSettings({ appId, values });
    setLoadingSaving(false);
    if (data && data.message === "success") {
      notification.info({
        message: "Information received!",
      });
    }
  };
  const getData = async () => {
    setLoading(true);
    let { data } = await getBadgeSettings(appId);
    setLoading(false);
    if (data && data.settings) {
      data.settings.badge_setting.stores_hidden = data.settings.badge_setting
        .stores_hidden
        ? data.settings.badge_setting.stores_hidden.split(",")
        : [];

      setSettings(data.settings.badge_setting);
      setPackageName(data.settings.main_information.package_name);
      setUseCustomColor(data.settings.badge_setting.use_custom_color);
      setCustomColor(data.settings.badge_setting.custom_color);
      setDefaultLogo(data.settings.badge_setting.use_custom_logo);
      setStores(data.settings.information);
    }
  };
  const handleFile = ({ name, value }) => {
    form.setFieldsValue({ [name]: value });
  };
  const deleteFile = () => {
    form.setFieldsValue({ custom_logo_url: "" });
  };
  const handleStores = (name, val) => {
    form.setFieldsValue({ [name]: val });
  };
  const handleChangeComplete = (color) => {
    console.log("color", color);
    form.setFieldsValue({ custom_color: color ? color.hex : "" });
  };
  useEffect(() => {
    getData();
  }, []);
  return loading && !settings ? (
    <LoadingSpin style={{ height: 500 }} />
  ) : (
    <div className={classes.container}>
      <Form onSubmit={handleSubmit}>
        <Item label="App Name Displayed">
          {getFieldDecorator(`app_public_name`, {
            initialValue:
              settings && settings.app_public_name
                ? settings.app_public_name
                : "",
          })(<Input disabled={loadingSaving} />)}
        </Item>
        <Item label="App Description">
          {getFieldDecorator(`app_description`, {
            initialValue:
              settings && settings.app_description
                ? settings.app_description
                : "",
          })(<Input.TextArea rows={7} disabled={loadingSaving} />)}
        </Item>
        <Item
          label={
            <Popover
              placement="bottomLeft"
              content="Users with Apple mobile devices will be redirected to this link when opening the AIC Badge page"
            >
              Apple App Store China <Icon type="question-circle-o" />
            </Popover>
          }
        >
          {getFieldDecorator(`apple_link`, {
            initialValue:
              settings && settings.apple_link ? settings.apple_link : "",
          })(<Input disabled={loadingSaving} />)}
        </Item>
        <Row>
          <Col>
            <Item>
              {getFieldDecorator(`hide_company_name`, {
                initialValue:
                  settings && settings.hide_company_name
                    ? settings.hide_company_name
                    : false,
                valuePropName: "checked",
              })(
                <Checkbox disabled={loadingSaving}>Hide Company Name</Checkbox>
              )}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row type="flex" align="middle" justify="start">
              <Item>
                {getFieldDecorator(`use_custom_logo`, {
                  initialValue:
                    settings && settings.use_custom_logo
                      ? settings.use_custom_logo
                      : false,
                  valuePropName: "checked",
                })(
                  <Checkbox
                    disabled={loadingSaving}
                    onChange={(e) => setDefaultLogo(e.target.checked)}
                  >
                    Hide AppInChina logo
                  </Checkbox>
                )}
              </Item>
            </Row>
            <Row type="flex" align="middle" justify="center">
              <Col span={24}>
                {defaultLogo ? (
                  <Fragment>
                    <Item>
                      {getFieldDecorator(`custom_logo_url`, {
                        rules: [
                          {
                            required: false,
                            message: "Required",
                          },
                        ],
                        initialValue:
                          settings && settings.custom_logo_url
                            ? settings.custom_logo_url
                            : "",
                      })(
                        <File
                          name="custom_logo_url"
                          type="Image"
                          title="Custom Logo *"
                          validation={{
                            fileRequired: !defaultLogo ? true : false,
                          }}
                          disabled={loadingSaving}
                          onDelete={deleteFile}
                          submitFile={handleFile}
                          format={["png", "svg"]}
                        />
                      )}
                    </Item>
                    <p>* Must be PNG or SVG.</p>
                  </Fragment>
                ) : null}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row type="flex" align="middle" justify="start">
              <Item>
                {getFieldDecorator(`use_custom_color`, {
                  initialValue:
                    settings && settings.use_custom_color
                      ? settings.use_custom_color
                      : false,
                  valuePropName: "checked",
                })(
                  <Checkbox
                    disabled={loadingSaving}
                    onChange={(e) => setUseCustomColor(e.target.checked)}
                  >
                    Use custom theme color
                  </Checkbox>
                )}
              </Item>
            </Row>
            {useCustomColor ? (
              <div className={classes.colorPicker}>
                <Item>
                  {getFieldDecorator(`custom_color`, {
                    rules: [
                      {
                        required: !useCustomColor ? true : false,
                        message: "Required",
                      },
                    ],
                  })(
                    <ChromePicker
                      disableAlpha
                      disabled={loadingSaving}
                      color={{
                        hex: form.getFieldValue("custom_color") || customColor,
                      }}
                      onChangeComplete={handleChangeComplete}
                    />
                  )}
                </Item>
              </div>
            ) : null}
          </Col>
        </Row>
        {stores.length > 0 ? (
          <LineCard title="Stores Displayed">
            <Row gutter={16}>
              {stores.map(({ store }) => (
                <Col sm={12} xl={8} xxl={6}>
                  <Item>
                    {getFieldDecorator(`stores_hidden_${store.short_name}`, {
                      initialValue:
                        settings &&
                        settings.stores_hidden.includes(store.short_name)
                          ? false
                          : true,
                      valuePropName: "checked",
                    })(
                      <Checkbox
                        disabled={loadingSaving}
                        onChange={(e) =>
                          handleStores(
                            `stores_hidden_${store.short_name}`,
                            e.target.checked
                          )
                        }
                      >
                        {store.fullname}
                      </Checkbox>
                    )}
                  </Item>
                </Col>
              ))}
            </Row>
          </LineCard>
        ) : null}
        <Form.Item>
          <a
            href={`https://apps.appinchina.com.cn/details/${packageName}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button style={{ marginRight: "12px" }}>View current page</Button>
          </a>
          <Button
            loading={loadingSaving}
            width="200px"
            icon="save"
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Save Information
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create()(AICBadge);
