import React, { useEffect, useState } from "react";
import {
  getOrderDetails,
  renewOrder
} from "../../../services/cloudStoreService";
import {
  Form,
  notification,
  Select,
  List,
  Card,
  Row,
  Alert,
  Button,
  Radio,
  Icon,
  Result
} from "antd";
import classes from "./RenewOrder.module.less";
import functions from "../../../functions";
import AccountBalanceRenew from "./AccountBalanceRenew/AccountBalanceRenew";
import BankTransferRenew from "./BankTransferRenew/BankTransferRenew";
import PayPalRenew from "./PayPalRenew/PayPalRenew";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";
const { Item } = Form;
const { Option } = Select;
const RenewOrder = ({ form, orderId, ...props }) => {
  const { getFieldDecorator, getFieldValue } = form;
  let [months, setMonths] = useState([3, 6, 9, 12]);
  let [orderDetails, setOrderDetails] = useState([]);
  let [total, setTotal] = useState(0);
  let [price, setPrice] = useState(0);
  let [appId, setAppId] = useState("");
  let [loading, setLoading] = useState(false);
  let [loadingRenew, setLoadingRenovate] = useState(false);
  let [paymentMethod, setPaymentMethod] = useState("");
  let [disablePaypal, setDisablePaypal] = useState(false);
  let [success, setSuccess] = useState(false);
  const getDetails = async () => {
    setLoading(true);
    let { data } = await getOrderDetails(orderId);
    if (data && data.product_type) {
      setPrice(data.monthly_price);
      setOrderDetails([{ ...data }]);
      setAppId(data.appId);
      calculatePrice(data.monthly_price, 3);
      if (data.product_type === "domain") {
        setMonths([12, 24, 36]);
      }
    }
    setLoading(false);
  };

  const renewOrderRequest = async values => {
    setLoadingRenovate(true);
    let { data } = await renewOrder({
      appId,
      orderId,
      payment_method: paymentMethod,
      months_paid: getFieldValue("months_paid"),
      monthly_price: price,
      ...values
    });
    setLoadingRenovate(false);
    if (data && data.message === "success") {
      setSuccess(true);
    }
  };

  useEffect(() => {
    getDetails();
    if (orderId.includes("1234-8475-9439-9494")) {
      setDisablePaypal(true);
    }
    if (props.history.location.state && props.history.location.state.appId) {
      setAppId(props.history.location.state.appId);
    }
  }, [orderId]);
  useEffect(() => {
    getDetails();
  }, []);
  const calculatePrice = (price, months) => {
    let res = months * price;
    setTotal(res);
  };
  return loading ? (
    <LoadingSpin />
  ) : !success ? (
    <div>
      {" "}
      <Row type="flex" justify="start">
        <Button
          style={{ marginBottom: 10 }}
          type="ghost"
          onClick={() => window.history.back()}
        >
          <Icon type="left" />
          Go back
        </Button>
      </Row>
      <Alert
        className={classes.alertText}
        message={
          <div>
            <p>Total:</p>
            <b>USD ${functions.styleAsNumber(total)}</b>
          </div>
        }
        type="success"
        style={{ marginBottom: 20 }}
      />
      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={orderDetails}
        renderItem={({ title, id, monthly_price, product_specifications }) => (
          <List.Item>
            <Card
              key={id}
              title={
                <Row type="flex" justify="space-between">
                  <span>{title}</span>
                  <b>USD ${functions.styleAsNumber(monthly_price)}</b>
                </Row>
              }
              bodyStyle={{ height: 250 }}
              actions={[]}
            >
              <div>
                {Object.entries(JSON.parse(product_specifications)).map(
                  ([key, value]) => {
                    return (
                      <p>
                        <b>{key}</b> : {value}
                      </p>
                    );
                  }
                )}
              </div>
            </Card>
          </List.Item>
        )}
      />
      <Form colon={false} layout="vertical" style={{ width: "100%" }}>
        <Item label="Select a monthly plan:" style={{ margin: 0 }}>
          {getFieldDecorator(`months_paid`, {
            initialValue: months[0]
          })(
            <Select onChange={val => calculatePrice(price, val)}>
              {months.map(it => (
                <Option key={it} value={it}>
                  {it} Months
                </Option>
              ))}
            </Select>
          )}
        </Item>
      </Form>
      {total > 0 ? (
        <Radio.Group
          style={{ marginTop: 13 }}
          disabled={loadingRenew}
          onChange={e => setPaymentMethod(e.target.value)}
        >
          <Radio.Button value="account_balance">Account Balance</Radio.Button>
          <Radio.Button value="bank_transfer">Bank Transfer</Radio.Button>
          {!disablePaypal ? (
            <Radio.Button value="paypal">Paypal</Radio.Button>
          ) : null}
        </Radio.Group>
      ) : null}
      <div className={classes.payment}>
        {paymentMethod === "account_balance" ? (
          <AccountBalanceRenew
            total={total}
            orderId={orderId}
            appId={appId}
            loadingRenew={loadingRenew}
            renewOrder={renewOrderRequest}
            orderType={orderDetails.product_type}
          />
        ) : null}
        {paymentMethod === "bank_transfer" ? (
          <BankTransferRenew
            total={total}
            loadingRenew={loadingRenew}
            renewOrder={renewOrderRequest}
          />
        ) : null}
        {paymentMethod === "paypal" ? (
          <PayPalRenew total={total} renewOrder={renewOrderRequest} />
        ) : null}
      </div>
    </div>
  ) : (
    <Result
      status="success"
      title="Successfully Purchased!"
      extra={[
        <Button
          type="link"
          icon="view"
          onClick={() => props.history.push(`/cloud/summary/${appId}`)}
        >
          View My Orders
        </Button>
      ]}
    />
  );
};

export default Form.create()(RenewOrder);
