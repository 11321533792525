import React from "react";
import LineCard from "../../UI/LineCard/LineCard";

export default ({ countries, loading }) => {
  if (countries && countries.length) {
    let data = countries.map(({ countryName, visitors }) => [
      countryName,
      +visitors
    ]);
    let mapData = [["Country", "Popularity"], ...data];

    window.google.charts.load("current", {
      packages: ["geochart"],
      mapsApiKey: "AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY"
    });

    window.google.charts.setOnLoadCallback(() => {
      var data = window.google.visualization.arrayToDataTable(mapData);
      var options = {
        colorAxis: { colors: ["rgb(219, 236, 171)", "rgb(97, 132, 0)"] }
      };
      var chart = new window.google.visualization.GeoChart(
        document.getElementById("test")
      );
      chart.draw(data, options);
    });
  }

  return (
    <LineCard title="Location Statistics" loading={loading}>
      <div id="test" />
    </LineCard>
  );
};
