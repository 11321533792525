import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import history from "../../history";
import BadgeAnalytics from "./BadgeAnalytics/BadgeAnalytics";
import classes from "./BadgeHome.module.less";
import AICBadge from "./AICBadge/AICBadge";
const { TabPane } = Tabs;

const Settings = ({ ...props }) => {
  let [tab, setTab] = useState(0);

  useEffect(() => {
    let { section } = props;
    setTab(section);
  }, []);

  const updateTab = section => {
    setTab(section);
    history.push(`/aic-badge/${props.appId}/${section}`);
  };
  return (
    <Tabs
      activeKey={`${tab}`}
      onChange={e => updateTab(e)}
      className={classes.settings}
    >
      <TabPane tab="Analytics" key="analytics">
        <BadgeAnalytics {...props} />
      </TabPane>
      <TabPane tab="Customization" key="customization">
        <AICBadge {...props} />
      </TabPane>
    </Tabs>
  );
};

export default Settings;
