import React, { useState, useEffect } from "react";
import classes from "./DataPerStore.module.less";
import { Card, DatePicker, Row, Col, Table } from "antd";
import { Link } from "react-router-dom";
import functions, { getLast30Days } from "../../../../functions";
import { dataPerStoreTable } from "../../PromotionFakeData";
import { getDataPerStore } from "../../../../services/promotionService";

export default ({ app, useDemoData }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(getLast30Days());
  const [usersDataTable, setUsersDataTable] = useState([]);
  const makeRequest = async () => {
    setLoading(true);

    let { data: response } = await getDataPerStore({
      promotionId: app.analitycs_id,
      app: app.id,
      start: date[0].format("YYYY-MM-DD"),
      end: date[1].format("YYYY-MM-DD"),
    });
    setData(response.result.uaDashboardTable);
    setUsersDataTable(response.result.usersDataTable);
    setLoading(false);
  };

  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData([...dataPerStoreTable]);
        setUsersDataTable([...dataPerStoreTable]);
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    validateRequest();
  }, [app]);

  useEffect(() => {
    validateRequest();
  }, [date]);

  const columns = [
    {
      fixed: "left",
      width: "100px",
      title: "App Store",
      dataIndex: "marketName",
      key: "marketName",
      align: "right",
      render: (store) => (
        <>
          {store == null
            ? "Not Set"
            : `${store}`.charAt(0).toUpperCase() + `${store}`.slice(1)}
        </>
      ),
    },
    {
      title: "Exposure",
      dataIndex: "exposure",
      key: "exposure",
      align: "right",
      render: (val) => (val ? functions.styleAsNumber(val) : 0),
    },
    {
      title: "Downloads",
      dataIndex: "downloads",
      key: "downloads",
      align: "right",
      render: (val) => (val ? functions.styleAsNumber(val) : 0),
    },
    {
      title: "Amount Spent",
      dataIndex: "spend",
      key: "spend",
      align: "right",
      render: (val) => (val ? functions.styleAsNumber(val) : 0),
    },
  ];

  const usersDataColumns = [
    {
      fixed: "left",
      width: "100px",
      title: "App Store",
      dataIndex: "marketName",
      key: "marketName",
      align: "right",
      render: (store) => (
        <Link
          to={`/promotion/${app.id}/data-per-store/${store}`}
          state={{ store }}
        >
          {store == null
            ? "Not Set"
            : `${store}`.charAt(0).toUpperCase() + `${store}`.slice(1)}
        </Link>
      ),
    },
    {
      title: "Recurring Users",
      dataIndex: "activations",
      key: "activations",
      align: "right",
      render: (val) => (val ? functions.styleAsNumber(val) : 0),
    },
    {
      title: "Activations",
      dataIndex: "newRegistrations",
      key: "newRegistrations",
      align: "right",
      render: (val) => (val ? functions.styleAsNumber(val) : 0),
    },
  ];

  return (
    <div className={classes.container}>
      <Card title="Data Per Store" style={{ marginTop: 15 }}>
        <Row gutter={16}>
          <Col xs={{ span: 12 }} lg={{ span: 10 }} style={{ marginBottom: 15 }}>
            <DatePicker.RangePicker
              disabled={loading}
              value={date}
              onChange={(moment) => {
                setDate(moment);
              }}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Table
          scroll={{ x: 800 }}
          loading={loading}
          size="small"
          columns={columns}
          dataSource={data}
        />
        <Table
          scroll={{ x: 800 }}
          loading={loading}
          size="small"
          columns={usersDataColumns}
          dataSource={usersDataTable}
        />
      </Card>
    </div>
  );
};
