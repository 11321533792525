import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Form, Avatar, Alert, Checkbox } from "antd";
import LineCard from "../../../../../../components/UI/LineCard/LineCard";
import File from "../../../../../../components/UI/File/File";
import classes from "./Screenshots.module.less";
import { ROLES } from "../../../../../../constants";
import HorizontalScreenshots from "./HorizontalScreenshots/HorizontalScreenshots";
const { Item } = Form;
export default ({
  getFieldDecorator,
  disabled = false,
  data = {},
  submitFileHandler,
  //firstFormSubmition,
  lastSubmition,
  deleteFile,
  autoSave,
}) => {
  const [
    displayHorizontalScreenshots,
    setdisplayHorizontalScreenshots,
  ] = useState(false);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const comprare = async (fieldToUpdate, event, file) => {
    try {
      submitFileHandler(fieldToUpdate);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (data && data.include_horizontal_screenshots) {
      console.log(data);
      setdisplayHorizontalScreenshots(
        data.include_horizontal_screenshots || false
      );
    }
  }, [data]);
  return (
    <div className={classes.container}>
      <Row gutter={16} type="flex" justify="space-around">
        <Col xs={24} md={14}>
          <LineCard title="App Icons." shadow>
            <Row type="flex" justify="space-between">
              <div>
                <b>Icon Requirements:</b>
                <ol>
                  <li>Must match the icon in the APK.</li>
                  <li>Must be in PNG format.</li>
                  <li>The background behind the icon must be transparent.</li>
                  <li>50KB or less.</li>
                </ol>
              </div>
              {data.apk_icon ? (
                <Alert
                  message={
                    <div>
                      <Avatar
                        size="large"
                        shape="square"
                        src={data.apk_icon}
                        title="App Icon"
                      />{" "}
                      <b>Current APK Icon</b>
                    </div>
                  }
                  type="info"
                />
              ) : null}
            </Row>

            <Row gutter={16}>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <Item>
                  {getFieldDecorator(`icon_2`, {
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                    initialValue: data.icon_2,
                  })(
                    <File
                      type="File"
                      name="icon_2"
                      validation={{
                        fileRequired: true,
                        resolution: { width: "16", height: "16" },
                      }}
                      format={["png", "jpg", "jpeg"]}
                      title="App Icon (16x16px) *"
                      disabled={disabled}
                      onDelete={() => deleteFile("icon_2")}
                      submitFile={(fieldToUpdate, event, file) =>
                        comprare(fieldToUpdate, event, file)
                      }
                      previewWidth={16}
                      previewHeight={16}
                    />
                  )}
                </Item>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <Item>
                  {getFieldDecorator(`icon_3`, {
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                    initialValue: data.icon_3,
                  })(
                    <File
                      type="File"
                      name="icon_3"
                      validation={{
                        fileRequired: true,
                        resolution: { width: "216", height: "216" },
                      }}
                      format={["png", "jpg", "jpeg"]}
                      title="App Icon (216x216px) *"
                      disabled={disabled}
                      onDelete={() => deleteFile("icon_3")}
                      submitFile={(fieldToUpdate, event, file) =>
                        comprare(fieldToUpdate, event, file)
                      }
                      previewWidth={16}
                      previewHeight={16}
                    />
                  )}
                </Item>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <Item>
                  {getFieldDecorator(`icon`, {
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                    initialValue: data.icon,
                  })(
                    <File
                      type="File"
                      name="icon"
                      validation={{
                        fileRequired: true,
                        resolution: { width: "512", height: "512" },
                      }}
                      format={["png", "jpg", "jpeg"]}
                      disabled={disabled}
                      title="App Icon (512x512px) *"
                      onDelete={() => deleteFile("icon")}
                      submitFile={(fieldToUpdate, event, file) =>
                        comprare(fieldToUpdate, event, file)
                      }
                      previewWidth={16}
                      previewHeight={16}
                    />
                  )}
                </Item>
              </Col>
            </Row>
          </LineCard>
        </Col>
        <Col xs={24} md={10}>
          <LineCard
            className={classes.preview}
            title="App Preview Video."
            description="Please ensure that your video is between 15 seconds and 2
                    minutes in duration, less than 500MB in size, in the .mov or
                    .mp4 format, and has a pixel resolution of 1080x1920 (aspect
                    ratio: 9:16)."
            shadow
          >
            <Item>
              {getFieldDecorator(`preview_video`, {
                initialValue: data.preview_video,
              })(
                <File
                  name="preview_video"
                  type="Video"
                  title="App Preview Video"
                  validation={{ fileRequired: false }}
                  disabled={disabled}
                  onDelete={() => deleteFile("preview_video")}
                  submitFile={submitFileHandler}
                />
              )}
            </Item>
          </LineCard>
        </Col>
      </Row>
      <LineCard
        title="1080x1920 PIxel Screenshots. 2MB or less."
        style={{ marginTop: 15 }}
        shadow
      >
        <Row className={classes.description} style={{ marginBottom: 15 }}>
          <b>
            All screenshots must be in JPG or PNG format; taken on an Android
            device but not showing any recognizable phone brand; contents are in
            Chinese and match the APK that was submitted.
          </b>
        </Row>
        <Row gutter={16} type="flex" justify="space-around">
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot1_1`, {
                rules: [{ required: true, message: "Required" }],
                initialValue: data.screenshot1_1,
              })(
                <File
                  name="screenshot1_1"
                  type="Image"
                  title="Screenshot 1 *"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "1080", height: "1920" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot1_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1080}
                  previewHeight={1920}
                />
              )}
            </Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot2_1`, {
                rules: [{ required: true, message: "Required" }],
                initialValue: data.screenshot2_1,
              })(
                <File
                  name="screenshot2_1"
                  type="Image"
                  title="Screenshot 2 *"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "1080", height: "1920" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot2_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1080}
                  previewHeight={1920}
                />
              )}
            </Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot3_1`, {
                rules: [{ required: true, message: "Required" }],
                initialValue: data.screenshot3_1,
              })(
                <File
                  name="screenshot3_1"
                  title="Screenshot 3 *"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "1080", height: "1920" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  type="Image"
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot3_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1080}
                  previewHeight={1920}
                />
              )}
            </Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot4_1`, {
                rules: [{ required: true, message: "Required" }],
                initialValue: data.screenshot4_1,
              })(
                <File
                  name="screenshot4_1"
                  title="Screenshot 4 *"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "1080", height: "1920" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  type="Image"
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot4_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1080}
                  previewHeight={1920}
                />
              )}
            </Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot5_1`, {
                rules: [{ required: true, message: "Required" }],
                initialValue: data.screenshot5_1,
              })(
                <File
                  name="screenshot5_1"
                  title="Screenshot 5 *"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "1080", height: "1920" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  type="Image"
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot5_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1080}
                  previewHeight={1920}
                />
              )}
            </Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot6_1`, {
                initialValue: data.screenshot6_1,
              })(
                <File
                  name="screenshot6_1"
                  title="Screenshot 6"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "1080", height: "1920" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  type="Image"
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot6_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1080}
                  previewHeight={1920}
                />
              )}
            </Item>
          </Col>
        </Row>
      </LineCard>
      <LineCard title="480x800 Pixel Screenshots." shadow>
        <Row gutter={16} type="flex" justify="space-around">
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot1`, {
                rules: [{ required: true, message: "Required" }],
                initialValue: data.screenshot1,
              })(
                <File
                  name="screenshot1"
                  type="Image"
                  title="Screenshot 1 *"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "480", height: "800" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot1")}
                  submitFile={submitFileHandler}
                  previewWidth={480}
                  previewHeight={800}
                />
              )}
            </Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot2`, {
                rules: [{ required: true, message: "Required" }],
                initialValue: data.screenshot2,
              })(
                <File
                  type="Image"
                  name="screenshot2"
                  title="Screenshot 2 *"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "480", height: "800" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot2")}
                  submitFile={submitFileHandler}
                  previewWidth={480}
                  previewHeight={800}
                />
              )}
            </Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot3`, {
                rules: [{ required: true, message: "Required" }],
                initialValue: data.screenshot3,
              })(
                <File
                  type="Image"
                  name="screenshot3"
                  title="Screenshot 3 *"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "480", height: "800" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot3")}
                  submitFile={submitFileHandler}
                  previewWidth={480}
                  previewHeight={800}
                />
              )}
            </Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot4`, {
                rules: [{ required: true, message: "Required" }],
                initialValue: data.screenshot4,
              })(
                <File
                  type="Image"
                  name="screenshot4"
                  title="Screenshot 4 *"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "480", height: "800" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot4")}
                  submitFile={submitFileHandler}
                  previewWidth={480}
                  previewHeight={800}
                />
              )}
            </Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot5`, {
                rules: [{ required: true, message: "Required" }],
                initialValue: data.screenshot5,
              })(
                <File
                  type="Image"
                  name="screenshot5"
                  title="Screenshot 5 *"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "480", height: "800" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot5")}
                  submitFile={submitFileHandler}
                  previewWidth={480}
                  previewHeight={800}
                />
              )}
            </Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={4} xl={4}>
            <Item>
              {getFieldDecorator(`screenshot6`, {
                initialValue: data.screenshot6,
              })(
                <File
                  name="screenshot6"
                  type="Image"
                  title="Screenshot 6"
                  validation={{
                    fileRequired: true,
                    resolution: { width: "480", height: "800" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("screenshot6")}
                  submitFile={submitFileHandler}
                  previewWidth={480}
                  previewHeight={800}
                />
              )}
            </Item>
          </Col>
          <Col span={24}>
            <Item>
              {getFieldDecorator(`include_horizontal_screenshots`, {
                initialValue: data.include_horizontal_screenshots || false,
                onChange: (event) => {
                  setdisplayHorizontalScreenshots(event.target.checked);
                  autoSave({
                    include_horizontal_screenshots: event.target.checked,
                  });
                },
                valuePropName: "checked",
              })(
                <Checkbox disabled={disabled}>
                  Include horizontal screenshots
                </Checkbox>
              )}
            </Item>
          </Col>
        </Row>
      </LineCard>

      {displayHorizontalScreenshots ? (
        <HorizontalScreenshots
          data={data}
          getFieldDecorator={getFieldDecorator}
          deleteFile={deleteFile}
          submitFileHandler={submitFileHandler}
          disabled={disabled}
        />
      ) : null}
    </div>
  );
};
