import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import classes from "./PayPalRenew.module.less";
import { notification } from "antd";

const PayPalRenew = ({ total, renewOrder }) => {
  return (
    <div className={classes.container}>
      <PayPalButton
        amount="0.01"
        // amount={`${total}`}
        style={{
          color: "blue"
        }}
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        options={{
          clientId:
            "ASgF7wh67NOiYkU2zItpCgc5AhsyzI2imgvHPzBRvWTsREOFLCdnR_4BeeWIXsfrmaXsXVaC3oA20ORW"
        }}
        onError={error => {
          console.log("error", error);

          notification.error({
            message: `An error has ocurred ${error}`,
            placement: "bottomLeft"
          });
        }}
        onSuccess={(details, data) => {
          renewOrder({
            total: total,
            paypal_id: data.orderID,
            paypal_callback_string: JSON.stringify(details)
          });
        }}
      />
    </div>
  );
};

export default PayPalRenew;
