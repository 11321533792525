import React, { useState, useEffect } from "react";
import classes from "../PaymentsPlatform.module.less";
import { Button, Table, Tag, DatePicker, Input, Row, Col, Select, List, Popover } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { getRenewals } from "../../../services/aicPay";
import { renewals_table_fake } from "../FakeData";
import Scrollable from "../../UI/Scrollable/Scrollable";
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { Option } = Select;
const getRenewalTag = {
  error: <Tag color="purple">ERROR</Tag>,
  active: <Tag color="green">ACTIVE</Tag>,
  cancelled: <Tag color="volcano">CANCELLED</Tag>,
};
export default ({ app, useDemoData, ...props }) => {
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [previousSorter, setPreviousSorter] = useState({});
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [useAsync, setUseAsync] = useState(true);
  const [filters, setFilters] = useState("21,31,41,11,51");
  const [searchFilter, setSearch] = useState({ filter: undefined, value: null })
  const [downloading, setDownloading] = useState(false);
  const searchOptions = [{ value: "user", label: "User" },
  { value: "renewal_id", label: "Renewal #" }];
  const tableColumns = [
    {
      title: "Renewal #",
      dataIndex: "renewal_id",
      key: "renewal_id",
      align: "center",
      render: (renewal_id) =>
        renewal_id ? (
          <Link to={`/monetization/${app ? app.id : props.appId}/renewals/${renewal_id}`}>
            {renewal_id}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
        { text: "ERROR", value: "error" },
        { text: "ACTIVE", value: "active" },
        { text: "CANCELLED", value: "cancelled" },
      ],
      render: (status) => getRenewalTag[status],
    },
    {
      title: "Renewal Period (Days)",
      dataIndex: "renewal_period",
      key: "renewal_period",
      align: "center",
    },
    {
      title: "User Name",
      dataIndex: "user",
      key: "user",
      align: "center",
      render: (user) =>
        user ? (
          <Link to={`/monetization/${app ? app.id : props.appId}/user-unregistered/${user}`}>
            {user}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      title: "Renewal Signed Date",
      dataIndex: "renewal_date",
      key: "renewal_date",
    },
    {
      title: "Cancelled Date",
      dataIndex: "cancelled_date",
      key: "cancelled_date",
    }
  ];

  useEffect(() => {
    fetch({
      results: 10,
      page: 1,
      sortField: "gmt_create",
      filters: "21,31,41,11,51",
      sortOrder: "descend",      
    });
  }, [app.id, useDemoData]);
  const handleSearch = () => {
    if (searchFilter.filter && searchFilter.value) {
      const { filter: filterName, value } = searchFilter;
      fetch({
        results: 10,
        page: 1,
        sortField: "gmt_create",
        filters: "21,31,41,11,51",
        sortOrder: "descend",
        [filterName]: value,
        rangeDate: {
          start: moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
      });

    } else {

    }

  }
  const fetch = async (params = {}) => {
    if (!useDemoData)
      try {
        if (!!app) {
          setLoading(true);
          const paymentsId = [
            app.alipay_id || "",
            app.wechat_id || "",
            app.seccond_alipay_id || "",
            app.seccond_wechat_id || "",
          ].join(",");
          let response = await getRenewals({
            rangeDate: { start: start_date, end: end_date },
            ...params,
            id: paymentsId,
            app,
          });
          setLoading(false);
          if (response) {
            setTotal(response.total_results);
            setValues(response.renewals);
          }
        }
      } catch (error) {
        console.log(error);
      }
  };

  const handlePaginationChange = (newCurrent) => {
    const field = {
      date: "gmt_create",
      amount: "amount",
    };
    const date =
      start_date != "" && end_date != ""
        ? { rangeDate: { start: start_date, end: end_date } }
        : {};
    setCurrent(newCurrent);
    fetch({
      results: 10,
      page: newCurrent,
      sortField: previousSorter.field
        ? field[previousSorter.field]
        : "gmt_create",
      filters,
      sortOrder: previousSorter.order || "descend",
      ...date,
    });
  };

  const handleTableChange = (_, filters, sorter) => {
    const field = {
      date: "gmt_create",
      amount: "amount",
    };
    const date =
      start_date != "" && end_date != ""
        ? { rangeDate: { start: start_date, end: end_date } }
        : {};
    const filtros =
      filters && filters.status ? filters.status.join(",") : "21,31,41,11,51";
    setFilters(filtros);
    setPreviousSorter(sorter);
    setCurrent(1);
    fetch({
      results: 10,
      page: 1,
      sortField: sorter.field ? field[sorter.field] : "gmt_create",
      filters: filtros,
      sortOrder: sorter.order || "descend",
      ...date,
    });
  };

  const onChangeDate = (_, [start, end]) => {
    fetch({
      results: 10,
      page: 1,
      rangeDate: { start, end },
      sortField: "gmt_create",
      sortOrder: "descend",
      filters: filters,
    });
    setDisabled(false);
    setStartDate(start);
    setEndDate(end);
    setCurrent(1);

  };


  const resetFilter = () => {
    setSearch({ filter: undefined, value: null });
    setUseAsync(true);
    setFilters("21,31,41,11,51");
    setStartDate(null);
    setEndDate(null);
    setCurrent(1);
    fetch({
      results: 10,
      page: 1,
      sortField: "gmt_create",
      sortOrder: "descend",
      filters: "21,31,41,11,51",
      rangeDate: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
    });
  };


  const downloadCsv = async () => {
    setDownloading(true)
    const paymentsId = [
      app.alipay_id || "",
      app.wechat_id || "",
      app.seccond_alipay_id || "",
      app.seccond_wechat_id || "",
    ].join(",");
    let response = await getRenewals({
      results: 1000,
      page: 1,
      rangeDate: { start: start_date, end: end_date },
      sortField: "gmt_create",
      sortOrder: "descend",
      id: paymentsId,
      app,
      filters: filters,
    });
    if (response && response.renewals) {
      const labelFilters = {
        "error": "ERROR",
        "active": "ACTIVE",
        "cancelled": "CANCELLED",
      };
      const data = response.renewals.map((it) => ({
        renewal_date: it.renewal_date,
        cancelled_date: it.cancelled_date,
        renewal_period: it.renewal_period,
        status: labelFilters[it.status],
        user: it.user,
        id: it.renewal_id,
      }));
      const rows = [
        ["Renewal Signed Date", "Cancelled Date", "Renewal Period", "Status", "Comments", "User ID", "Renewal ID"],
        ...data.map((it) => [...Object.values(it)]),
      ];
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const link = document.createElement("a");
      link.setAttribute("href", encodeURI(csvContent));
      link.setAttribute("download", "renewals.csv");
      document.body.appendChild(link);
      link.click();
    }
    setDownloading(false)
  };




  return (
    <div className={classes.tableContainer}>
      <Row justify="start" gutter={8}>
        <Col style={{ marginTop: 12 }} xs={24} sm={24} md={18} lg={12} xl={10}>
          <Row gutter={8}> <Col span={8}>
            <Select disabled={loading} loading={loading} value={searchFilter.filter} placeholder="Search By" style={{ width: "100%" }} onChange={val => setSearch({ ...searchFilter, filter: val })}>
              {searchOptions.map(({ label, value }) => (
                <Option key={value} value={value}>{label}</Option>
              ))}</Select></Col>
            <Col span={16}>
              <Search
                style={{ width: "100%" }}
                disabled={loading || !searchFilter.filter}
                onChange={e => setSearch({ ...searchFilter, value: e.target.value })}
                onSearch={handleSearch}
                placeholder="Type here..."
                value={searchFilter.value}
              />
            </Col>
          </Row>
        </Col>
        <Col style={{ marginTop: 12 }} xs={24} sm={12} md={12} lg={12} xl={6}>
          <RangePicker
            style={{ width: "100%" }}
            disabled={loading || searchFilter.filter}
            value={start_date && end_date ? [moment(start_date), moment(end_date)] : null}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
            allowClear={false}
          />
        </Col>
        <Col style={{ marginTop: 12 }} xs={12} sm={4} md={4} lg={4} xl={2}>
          <Button
            type="primary"
            onClick={resetFilter}
            disabled={disabled}
            style={{ width: "100%", padding: "0px" }}
            loading={loading}
          >
            Reset
          </Button>
        </Col>
        <Col style={{ marginTop: 12 }} xs={12} sm={6} md={6} lg={6} xl={4}>
          <Popover content="A maximum amount of 1000 items will be included in the CSV file.">
            <Button
              icon="download"
              onClick={downloadCsv}
              type="primary"
              loading={downloading}
              disabled={loading}
              style={{ width: "100%", padding: "0px" }}
            >
              Download as CSV
          </Button>
          </Popover>
        </Col>
      </Row>
      <div>
        {values && values.length && values.length > 0 || useDemoData ?
          <>
            <Scrollable
              pagination={{ current, total }}
              onPaginationChange={useAsync ? handlePaginationChange : () => { }}
            >
              <Table
                dataSource={useDemoData ? renewals_table_fake : values}
                rowKey={(record) => record.renewal_id}
                columns={tableColumns}
                size="small"
                loading={loading}
                scroll={{ x: 1700 }}
                pagination={false}
                onChange={useAsync ? handleTableChange : () => { }}
              />
            </Scrollable></> : <List loading={loading} dataSource={[]} />}
      </div>
    </div>
  );
};
