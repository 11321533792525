import React, { useState, useEffect } from "react";
import LineCard from "../../UI/LineCard/LineCard";
import classes from "../ClientForms.module.less";
import DemoAppAlert from "../../DemoAppAlert/DemoAppAlert";
import { withRouter } from "react-router-dom";
import { Button, Form, Input, Popover, Icon, notification } from "antd";
import axios from "../../../axios";
import { getForm } from "../../../services/distributionService";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";

const { Item } = Form;

const SecurityAssessmentForm = ({ form, role = null, match }) => {
  const disabled = role === "VIEWER";
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { app_id } = match.params;

  useEffect(() => {
    getSecurityForm();
  }, [app_id]);

  const getSecurityForm = async () => {
    try {
      setLoading(true);
      const { data } = await getForm("security-assessment-form", app_id);
      setFormData(data);
      setLoading(false);
    } catch (error) {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setSaving(true);
        let result = await axios.post("security-assessment-form", {
          appId: app_id,
          form_data: values,
        });
        result && result.data
          ? showNotification("success", "Information Sent!")
          : showNotification("error", "An error happened");
        if (window.mixpanel) {
          window.mixpanel.track("Security form submitted");
        }
        setSaving(false);
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft",
        });
      }
    });
  };
  const handleSubmitDraft = async () => {
    let values = form.getFieldsValue();
    setSaving(true);
    let result = await axios.put("security-assessment-form", {
      appId: app_id,
      form_data: values,
    });
    result && result.data
      ? showNotification("success", "Information Saved!")
      : showNotification("error", "An error happened");

    setSaving(false);
  };

  const showNotification = (type, message) => {
    notification[type]({
      message,
      duration: 0,
    });
  };

  const { getFieldDecorator } = form;

  return loading ? (
    <LoadingSpin />
  ) : (
    <>
      <DemoAppAlert appId={app_id} />
      <div className={classes.Form}>
        <LineCard shadow>
          <p>
            Please answer the questions below in order to apply for a security
            assessment certificate for your app. As of September 1st 2019, this
            certificate is legally required in order to publish your app in the
            Chinese market.{" "}
            <b style={{ fontWeight: "500" }}>
              You can hover over the question mark symbol of each question to
              find sample answers.
            </b>
          </p>
        </LineCard>
        <Form
          onSubmit={handleSubmit}
          colon={false}
          layout="vertical"
          className={classes.securityForm}
        >
          <LineCard shadow title="Questions">
            <Item
              label={
                <Popover
                  placement="bottomLeft"
                  content={
                    <p>
                      Please describe the structure of your app’s internal
                      management team that handles internet safety (the
                      employees who are responsible for reviewing user posted
                      content and how this process is reviewed and approved by
                      your internal team).
                      <br /> <br />
                      Example answer for a Twitter-like app: “Our company has a
                      content operations department that deals specifically with
                      the review of content within our app. This department is
                      in charge of content editing, reviews, approvals and
                      operations. The department reviews all daily posted
                      contents including news and user posted content, and comes
                      to decisions to approve or reject posts. The information
                      and paperwork related to content review is kept in their
                      own department.”
                    </p>
                  }
                >
                  1. Have you established a safety management superintendent, an
                  information reviewer, and a process for safety management
                  administration? Please explain in detail. {" "}
                  <Icon type="question-circle" theme="filled" />
                </Popover>
              }
            >
              {getFieldDecorator(`safety_management`, {
                initialValue: formData.safety_management,
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea disabled={disabled} rows={6} />)}
            </Item>
            <br />
            <Item
              label={
                <Popover
                  placement="bottomLeft"
                  content={
                    <p>
                      Example answer: During registration, users need to provide
                      a cellphone number. We are able to identify users based on
                      their phone number. User information is stored on a server
                      which is used to validate the user’s identity when they
                      log in to the app again. We save IP addresses, user device
                      ID, MAC address, and all accounts that the user linked
                      inside the app.
                    </p>
                  }
                >
                  2. How do you verify users’ real identity, and what are your
                  retention measures for the registration information? {" "}
                  <Icon type="question-circle" theme="filled" />
                </Popover>
              }
            >
              {getFieldDecorator(`user_identity_verification`, {
                initialValue: formData.user_identity_verification,
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea disabled={disabled} rows={6} />)}
            </Item>
            <br />
            <Item
              label={
                <Popover
                  placement="bottomLeft"
                  content={
                    <p>
                      Please describe in detail how you collect daily user
                      information, including user account information, user
                      sessions, user IP addresses (TCP/IP configurations), port
                      settings, user hardware information, and other information
                      sent by the user’s device.
                      <br /> <br />
                      Example answer: We collect daily information regarding the
                      user’s account, user’s activation time (beginning session
                      time), type of action, and IP address, including the
                      source as well as the destination. We also gather the port
                      information for the session. We collect and save this
                      information to quickly find a user’s device, as well as to
                      identify the correct user.
                    </p>
                  }
                >
                  3. Please detail your record retention measures for the user’s
                  account, operation time, operation type, addresses of network
                  source and destination, port of network source,
                  characteristics of client hardware and other logging
                  information, as well as the record of the information posted
                  by the users. <Icon type="question-circle" theme="filled" />
                </Popover>
              }
            >
              {getFieldDecorator(`users_record_retention`, {
                initialValue: formData.users_record_retention,
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea disabled={disabled} rows={6} />)}
            </Item>
            <br />
            <Item
              label={
                <Popover
                  placement="bottomLeft"
                  content={
                    <p>
                      Example answer:
                      <br />
                      An administrator has access to the database of user
                      submitted information.
                      <br />
                      We have the ability to blacklist users who break the terms
                      of service.
                      <br />
                      We have the ability to block certain keywords and website
                      links.
                      <br />
                      We have a notification function for intercepted content:
                      When a certain post or link cannot be shown, it will
                      display a notification to users and send them a warning
                      email.
                    </p>
                  }
                >
                  4. What are your measures for the prevention, handling, and
                  related record retention of illegal and harmful information in
                  the name, nickname, introduction, remarks, identification,
                  information posts, re-posts, comments of the user account and
                  distribution group, as well as the service functions of the
                  distribution group?{" "}
                  <Icon type="question-circle" theme="filled" />
                </Popover>
              }
            >
              {getFieldDecorator(`users_retention_prevention`, {
                initialValue: formData.users_retention_prevention,
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea disabled={disabled} rows={6} />)}
            </Item>
            <br />
            <Item
              label={
                <Popover
                  placement="bottomLeft"
                  content={
                    <p>
                      Example answer:
                      <br />
                      An administrator has access to the database of user
                      submitted information.
                      <br />
                      We have the ability to blacklist users who break the terms
                      of service.
                      <br />
                      We have the ability to block certain keywords and website
                      links.
                      <br />
                      We have a notification function for intercepted content:
                      When a certain post or link cannot be shown, it will
                      display a notification to users and send them a warning
                      email.
                      <br />
                      We encrypt all user information on a secure database. The
                      passwords are automatically changed on a monthly basis.
                    </p>
                  }
                >
                  5. What are your technical measures for protecting personal
                  information and preventing the transmission & dissemination of
                  illegal & harmful information, as well as the risk of losing
                  control over social mobilisation capabilities?{" "}
                  <Icon type="question-circle" theme="filled" />
                </Popover>
              }
            >
              {getFieldDecorator(`personal_information_protection`, {
                initialValue: formData.personal_information_protection,
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea disabled={disabled} rows={6} />)}
            </Item>
            <br />
            <Item
              label={
                <Popover
                  placement="bottomLeft"
                  content={
                    <p>
                      Example answer: The app has a reporting system. Once a
                      report has been received, it is transferred to the content
                      review department for review. An operations employee will
                      determine the outcome of the report.
                    </p>
                  }
                >
                  6. Have you established a complaint and offence reporting
                  system, and published information about the complaint and
                  offence reporting methods? Please explain. Also, how do you
                  ensure you can accept and handle the relevant complaint and
                  reporting situations in a timely manner?{" "}
                  <Icon type="question-circle" theme="filled" />
                </Popover>
              }
            >
              {getFieldDecorator(`complaint_report_system`, {
                initialValue: formData.complaint_report_system,
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea disabled={disabled} rows={6} />)}
            </Item>
            <br />
            <Item
              label={
                <Popover
                  placement="bottomLeft"
                  content={
                    <p>
                      Example answer:
                      <br />
                      Our servers store user information for the past 100 days.
                      <br />
                      This includes any conversations within our app, as well as
                      user posted content.
                      <br />
                      We are able to locate the correct user based on username,
                      device ID, and IP address.
                      <br />
                      We are able to intercept content and delete it or
                      quarantine it as necessary.
                    </p>
                  }
                >
                  7. What is your working mechanism to provide technical & data
                  support and assistance to support the legally required duties
                  of the supervision department and enforcement department?{" "}
                  <Icon type="question-circle" theme="filled" />
                </Popover>
              }
            >
              {getFieldDecorator(`mechanism_legal_supervision`, {
                initialValue: formData.mechanism_legal_supervision,
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea disabled={disabled} rows={6} />)}
            </Item>
            <br />
            <Item
              label={
                <Popover
                  placement="bottomLeft"
                  content={
                    <p>
                      If you have any additional details or questions that you’d
                      like to add then please write them here.
                    </p>
                  }
                >
                  Additional Notes{" "}
                  <Icon type="question-circle" theme="filled" />
                </Popover>
              }
            >
              {getFieldDecorator(`special_notes`, {
                initialValue: formData.special_notes,
              })(<Input.TextArea disabled={disabled} rows={6} />)}
            </Item>
          </LineCard>
          <div className={classes.Buttons}>
            <Button
              type="primary"
              size="large"
              ghost
              onClick={() => handleSubmitDraft()}
              disabled={disabled || saving}
              icon={saving ? "loading" : null}
            >
              Save as Draft
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={disabled || saving}
              icon={saving ? "loading" : null}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Form.create()(withRouter(SecurityAssessmentForm));
