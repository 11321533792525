import { Avatar } from "antd";
import React, { useState, useEffect } from "react";
import classes from "./PushNotificationDemo.module.less";
import phone from "../../../assets/phone.png";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import moment from "moment-timezone";

export default ({ title, body, icon, appName, send_option, time }) => {
  let [chinaTime, setChinaTime] = useState(moment().tz("Asia/Shanghai"));
  useEffect(() => {
    setInterval(() => {
      setChinaTime(moment().tz("Asia/Shanghai"));
    }, 5000);
  }, []);
  return (
    <div className={classes.container}>
      <img src={phone} />
      <div className={classes.datetime}>
        <span className={classes.time}>
          {(send_option === "SCHEDULED" && time ? time : chinaTime).format(
            "HH:mm"
          )}
        </span>
        <span className={classes.date}>
          {(send_option === "SCHEDULED" && time ? time : chinaTime).format(
            "ddd, MMM DD"
          )}
        </span>
      </div>
      <div className={classes.content}>
        <div>
          <label>
            <Ellipsis length={25}>{appName}</Ellipsis> - now
          </label>
          <h4>
            <Ellipsis length={25}>{title || "Title..."}</Ellipsis>
          </h4>
          <p>
            <Ellipsis length={60}>{body || "Body..."}</Ellipsis>
          </p>
        </div>
        <Avatar
          shape="square"
          src={icon || null}
          size="large"
          icon={icon ? null : "android"}
        />
      </div>
    </div>
  );
};
