import React from "react";
import classes from "./Summary.module.less";
import DataComparissonPerHour from "./DataComparisson/DataComparissonPerHour";
import SummaryCard from "./SummaryCard/SummaryCard";
import UncontractedMessage from "../UncontractedAppPromotion/UncontractedMessage";

export default ({ app, useDemoData }) => {
  return (
    <div className={classes.container}>
      <UncontractedMessage app={app} useDemoData={useDemoData} />
      <SummaryCard app={app} useDemoData={useDemoData} />
      <DataComparissonPerHour app={app} useDemoData={useDemoData} />
    </div>
  );
};
