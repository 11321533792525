import React, { useState } from "react";
import classes from "./SdkManagementApp.module.less";
import { Button, Card, Table, Popover, Icon } from "antd";
import history from "../../../history";
import { getSubscriptionsServer } from "../../../services/paymentsSubscriptionsService";
import SubscriptionModal from "./SubscriptionModal/SubscriptionModal";

const SdkManagementService = ({ apps, appId, serviceNo, ...props }) => {
  let [app, setApp] = useState(apps.find((it) => it.id === appId));
  let [mainSubscription, setMainSubscription] = useState({});
  let [subscription, setSubscription] = useState({});
  let [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false);
  let [subscriptions, setSubscriptions] = useState([]);
  let [loadingSubscriptions, setLoadingSubscriptions] = useState(false);

  let editSubscription = async () => {
    if (subscriptions.length) {
      setSubscription({
        ...mainSubscription,
      });
      setSubscriptionModalVisible(true);
    }
  };

  let getSubscriptions = async () => {
    setLoadingSubscriptions(true);
    let { data } = await getSubscriptionsServer(app.analitycs_id, app.id);
    if (data && data.length) {
      setSubscriptions(data);
      let item = data.find((it) => it.serviceNo === serviceNo);
      if (item) {
        let { id, ...details } = item;
        setMainSubscription({
          subscriptionId: id,
          ...details,
        });
      }
    }
    setLoadingSubscriptions(false);
  };

  let handleSubscriptionEdited = (values, id) => {
    getSubscriptions();
    setSubscriptionModalVisible(false);
  };

  useState(() => {
    if (app.analitycs_id) getSubscriptions();
  }, [appId]);

  let subscriptionsColumns = [
    {
      title: (
        <Popover
          placement="bottomLeft"
          content={
            <p style={{ maxWidth: 400, textAlign: "left", margin: 0 }}>
              The <b>serviceTypeNo</b> you create here will be the serviceTypeNo
              you input into the API. Each serviceTypeNo will have information
              regarding the length of time this particular renewal will have
            </p>
          }
        >
          serviceTypeNo <Icon type="question-circle-o" />
        </Popover>
      ),
      dataIndex: "serviceTypeNo",
    },
    {
      title: (
        <Popover
          placement="bottomLeft"
          content={
            <p style={{ maxWidth: 400, textAlign: "left", margin: 0 }}>
              A descriptor name for your <b>serviceTypeNo</b>
            </p>
          }
        >
          serviceTypeName <Icon type="question-circle-o" />
        </Popover>
      ),
      dataIndex: "serviceTypeName",
    },
    {
      title: "periodType (DAY/MONTH)",
      dataIndex: "periodType",
    },
    {
      title: "periodValue",
      dataIndex: "periodValue",
    },
  ];

  return (
    <div className={classes.container}>
      <SubscriptionModal
        visible={subscriptionModalVisible}
        id={app.analitycs_id}
        appId={app.id}
        {...subscription}
        onEdit={handleSubscriptionEdited}
        onClose={() => setSubscriptionModalVisible(false)}
      />
      <div className={classes.backButton}>
        <Button
          icon="left"
          onClick={() => history.push(`/sdk-management/details/${appId}/`)}
        >
          Back
        </Button>
      </div>
      <Card
        title="Details"
        className={classes.details}
        extra={
          <Button
            icon="edit"
            onClick={() => editSubscription()}
            disabled={!subscriptions.length}
          >
            Edit{" "}
          </Button>
        }
      >
        <ul>
          <li>
            <span>App Name:</span> {app.main_information.aicpay_appname}
          </li>
          <li>
            <span>serviceName:</span> {mainSubscription.serviceName}
          </li>
          <li>
            <span>serviceNo:</span> {mainSubscription.serviceNo}
          </li>
        </ul>
      </Card>
      <br />

      <Table
        loading={loadingSubscriptions}
        className={classes.shortcuts}
        bordered
        title={() => (
          <div>
            <b>Service Type List</b>
          </div>
        )}
        columns={subscriptionsColumns}
        dataSource={mainSubscription.accountServiceTypeList}
        rowKey="name"
      />
    </div>
  );
};

export default SdkManagementService;
