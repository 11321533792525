import React from "react";
import { Alert } from "antd";
import history from "../../history";
import classes from "./DemoAppAlert.module.less";

const DemoAppAlert = ({ appId }) => {
  return appId === "1234-8475-9439-9494" || appId === "1234-8475-9439-9495" ? (
    <div className={classes.container}>
      {" "}
      <Alert
        message={
          <p>
            The data displayed here is for demonstration purposes only, if you
            want to add your app please{" "}
            <a onClick={() => history.push("/new")}>click here</a>.
          </p>
        }
        type="warning"
        showIcon
      />
    </div>
  ) : null;
};
export default DemoAppAlert;
