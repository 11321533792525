import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Select,
  Form,
  notification,
  Modal,
  Spin,
  Table,
  Input,
} from "antd";
import File from "../../../../../../UI/File/File";
import { getStores } from "../../../../../../../services/storesService";
import {
  newMultiApk,
  deleteMultiApk,
} from "../../../../../../../services/clientFormsService";
import moment from "moment";
import SemverCompare from "compare-versions";
import { QRCode } from "react-qr-svg";
const AppInfoParser = require("app-info-parser");

const Option = Select.Option;
const { Item } = Form;
const confirm = Modal.confirm;
const MultiApk = ({
  updateApkValues,
  form,
  data,
  disabled,
  attachMultiApks,
  lastSubmition,
  getData,
  loadingInfo,
  compareIcons,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [display, setDisplay] = useState(false);
  const [stores, setStores] = useState([]);
  const [multiApks, setMultiApks] = useState([]);
  const [appIcon, setAppIcon] = useState(null);

  const getStoresRequest = async () => {
    setLoadingData(true);
    let { data: stores } = await getStores();
    if (stores) {
      setStores(stores);
      setMultiApks(data.multi_apks);
    }
    setLoadingData(false);
  };

  const newApk = async (values) => {
    setLoading(true);
    let { data } = await newMultiApk({ ...values });
    if (data && data.PK_Multi_Apk) {
      setMultiApks([...multiApks, data]);
      notification.success({
        message: "APK saved",
        placement: "bottomLeft",
      });
    } else {
      notification.error({
        message: "An error has ocurred.",
        placement: "bottomLeft",
      });
    }
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    getStoresRequest();
  }, []);

  const parseAPKs = () => {
    return multiApks
      .map((it) => ({
        ...it,
        store:
          it.store_id === "other" ? it.store_id : getStoreName(it.store_id),
        ranking: it.store_id === "other" ? 0 : getStoreRanking(it.store_id),
      }))
      .sort((a, b) => (a.ranking < b.ranking ? -1 : 1));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    form.validateFields(
      ["store_id", "url_apk", "custom_text"],
      (err, values) => {
        if (!err) {
          console.log("Saving Form", values);
          newApk({ ...values, FK_App_Form: data.Form_id });
          attachMultiApks([
            ...multiApks,
            { ...values, FK_App_Form: data.Form_id },
          ]);
        } else {
          notification.error({
            message: "Fill all the required fields.",
            placement: "bottomLeft",
          });
        }
      }
    );
  };

  const validateApk = async (pkgInfo) => {
    const isHigherVersion = isHigherCodeVersion(
      pkgInfo.versionName,
      lastSubmition.version,
      `The version number must be higher than the previous one in order to continue.`
    );
    const isHigherVersionCode = isHigherCodeVersion(
      pkgInfo.versionCode,
      lastSubmition.version_code,
      `The version code must be higher than the previous one in order to continue.`
    );
    const isSameApk = isSamePackageName(pkgInfo.package);
    return isHigherVersion && isSameApk && isHigherVersionCode;
  };

  const isHigherCodeVersion = (newVersion, oldVersion, title) => {
    if (oldVersion) {
      try {
        const [newVersionNormalized, lastVersionNormalized] = normalizeVersions(
          `${newVersion}`,
          `${oldVersion}`
        );
        const compareVersions = SemverCompare(
          newVersionNormalized,
          lastVersionNormalized
        );
        if (compareVersions !== 1) {
          Modal.error({
            title,
            centered: true,
          });
          return false;
        }
      } catch (error) {
        return false;
      }
    }
    return true;
  };

  const normalizeVersions = (newVersion, lastVersion) => {
    try {
      const newVersionLength = newVersion.split(".").length || 1;
      const lastVersionLength = lastVersion.split(".").length || 1;
      const zerosToAdd = Math.abs(newVersionLength - lastVersionLength);
      const zerosFill = Array(zerosToAdd)
        .fill(".0")
        .join("");
      const versionsNormalized =
        newVersionLength > lastVersionLength
          ? [newVersion, `${lastVersion}${zerosFill}`]
          : [`${newVersion}${zerosFill}`, lastVersion];

      return versionsNormalized;
    } catch (error) {
      console.log(error);
    }
  };

  const isSamePackageName = (packageName) => {
    const lastSubmitionPackage = lastSubmition.package_name;
    if (lastSubmitionPackage) {
      if (lastSubmitionPackage != packageName) {
        Modal.error({
          title: `The package name of the APK you uploaded must be the same as your previous form submissions`,
          centered: true,
        });
        return false;
      }
    }
    return true;
  };

  const submitApk = (fieldToUpdate, event, file) => {
    const parser = new AppInfoParser(file);
    parser.parse().then(async (pkgInfo) => {
      if (pkgInfo) {
        let apkValid = await validateApk(pkgInfo);
        if (apkValid) {
          let appNameIcon = null;
          if (pkgInfo.application && pkgInfo.application.label) {
            appNameIcon =
              pkgInfo.application.label instanceof Array
                ? pkgInfo.application.label[0]
                : pkgInfo.application.label;
          }
          /*await compareIcons(
            pkgInfo.icon,
            lastSubmition.apk_icon || lastSubmition.icon,
            {
              version: pkgInfo.versionName,
              version_code: pkgInfo.versionCode,
              package_name: pkgInfo.package,
              appname_en: appNameIcon,
              apk_icon: pkgInfo.icon,
            }
          );*/
          await updateApkValues(pkgInfo, false);
          form.setFieldsValue({ url_apk: fieldToUpdate.value });
        }
      }
    });
  };
  const closeModal = () => {
    form.resetFields();
    setDisplay(false);
  };

  const getStoreName = (id) => {
    let value = stores.find((store) => store.id === id);
    return value.fullname;
  };

  const getStoreRanking = (id) => {
    let value = stores.find((store) => store.id === id);
    return value.real_ranking;
  };

  const deleteApkRequest = async (id) => {
    setLoading(true);
    let { data } = await deleteMultiApk(id);
    if (data && data.message === "success") {
      let multiApksCopy = multiApks.map((it) => ({ ...it }));
      let indexToDelete = multiApksCopy.findIndex(
        (it) => it.PK_Multi_Apk === id
      );
      multiApksCopy.splice(indexToDelete, 1);
      setMultiApks(multiApksCopy);
      setLoading(false);
      notification.success({
        message: "APK deleted",
        placement: "bottomLeft",
      });
    }
  };

  const deleteApk = (id) => {
    showConfirmDeleteApk(id);
  };

  const showQrCode = (item) => {
    Modal.info({
      title: "The file can be downloaded with the following QR code",
      content: (
        <QRCode
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="Q"
          style={{ width: 276, margin: "20px 0px 0px", display: "block" }}
          value={`https://appinchina.space/uploads/${item.url_apk}`}
        />
      ),
      onOk() {},
    });
  };

  const showConfirmDeleteApk = (id) => {
    confirm({
      title: "Are you sure you want to delete this APK?",
      onOk() {
        deleteApkRequest(id);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "left",
      render: (createdAt) => moment(createdAt).format("YYYY-MM-DD"),
    },
    {
      title: "Store Name",
      dataIndex: "store",
      key: "store",
      align: "left",
      render: (store, it) => (it.store_id === "other" ? it.custom_text : store),
    },
    {
      title: "File Name",
      dataIndex: "url_apk",
      key: "url_apk",
      align: "left",
      render: (url) =>
        url && url.split("/") && url.split("/").length
          ? url.split("/").pop()
          : "N/A",
    },

    {
      title: "",
      dataIndex: "PK_Multi_Apk",
      key: "PK_Multi_Apk",
      render: (PK_Multi_Apk, item) => (
        <div>
          {item.url_apk ? (
            <a
              rel="noopener noreferrer"
              href={`https://appinchina.space/download/${item.url_apk}`}
              target="_blank"
            >
              <Button icon="cloud-download"></Button>
            </a>
          ) : null}
          <Button
            onClick={() => showQrCode(item)}
            icon="qrcode"
            style={{ marginRight: "8px" }}
          />
          {disabled ? null : (
            <Button
              type="danger"
              ghost
              onClick={() => deleteApk(PK_Multi_Apk)}
              icon="delete"
            />
          )}
        </div>
      ),
    },
  ];
  const { getFieldDecorator } = form;

  return (
    <Fragment>
      <Table
        dataSource={parseAPKs(multiApks)}
        columns={columns}
        size="small"
        loading={loadingData || loadingInfo}
        rowKey={(record) => record.PK_Multi_Apk}
        pagination={multiApks.length > 5 ? { pageSize: 5 } : false}
      />
      {disabled ? null : (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={disabled || loadingData || loadingInfo}
            style={{ marginTop: 20 }}
            type="primary"
            icon="plus"
            onClick={() => setDisplay(true)}
          >
            Add APK
          </Button>
        </div>
      )}
      <Modal
        title="New APK"
        visible={display}
        onCancel={() => closeModal()}
        footer={null}
      >
        <div>
          <Form hideRequiredMark={true} colon={false} layout="vertical">
            <div>
              <Item label="Store">
                {getFieldDecorator(`store_id`, {
                  rules: [
                    {
                      required: display,
                      message: "Required",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    placeholder="Please select a store"
                    onChange={(value) =>
                      form.setFieldsValue({ store_id: value })
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option
                      key="other"
                      value="other"
                      label="Custom Store Name (Add Details)"
                    >
                      Custom Store Name (Add Details)
                    </Option>
                    {stores.map((it) => (
                      <Option
                        key={it.fullname}
                        value={it.id}
                        label={it.fullname}
                      >
                        {it.fullname}
                      </Option>
                    ))}
                  </Select>
                )}
              </Item>
              {form.getFieldValue("store_id") === "other" ? (
                <Item label="Custom Store Name">
                  {getFieldDecorator(`custom_text`, {
                    rules: [
                      {
                        required: form.getFieldValue("store_id") === "other",
                        max: 100,
                        message: "Required. Maximum 100 characters.",
                      },
                    ],
                  })(<Input disabled={disabled} />)}
                </Item>
              ) : null}
              <Item label="">
                {getFieldDecorator(`url_apk`, {
                  rules: [
                    {
                      required: display,
                      message: "Required",
                    },
                  ],
                })(
                  <File
                    name="url_apk"
                    type="File"
                    customIcon={appIcon}
                    validation={{
                      fileRequired: display,
                    }}
                    title="App APK *"
                    onDelete={() => form.setFieldsValue({ url_apk: null })}
                    submitFile={submitApk}
                    format={["apk"]}
                  />
                )}
              </Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="primary"
                  size="small"
                  onClick={handleSubmit}
                  disabled={loading}
                  icon={loading ? "loading" : null}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Form.create()(MultiApk);
