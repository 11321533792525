import React, { useState, useEffect } from "react";
import Histogram from "../../../UI/Charts/Histogram";
import { Tabs, Card, DatePicker, Row, Col } from "antd";
import classes from "./UsersSource.module.less";
import { getDate } from "../../../../functions";
import { usersSourceFake, usersSourceTable } from "../../PromotionFakeData";
import { getUsersSource } from "../../../../services/promotionService";
const { TabPane } = Tabs;

export default ({ app, useDemoData, store }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [date, setDate] = useState(getDate());

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getUsersSource({
      promotionId: app.analitycs_id,
      app: app.id,
      start: date[0].format("YYYY-MM-DD"),
      end: date[1].format("YYYY-MM-DD"),
      store,
    });
    setData(response);
    setLoading(false);
  };

  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData({ ...usersSourceFake, usersSourceTable });
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    validateRequest();
  }, [app]);

  useEffect(() => {
    validateRequest();
  }, [date]);

  return (
    <div className={classes.container}>
      <Card title="Users Source" style={{ marginTop: 15 }}>
        <Row gutter={16}>
          <Col span={10} style={{ marginBottom: 15 }}>
            <DatePicker.RangePicker
              loading={loading}
              disabled={loading}
              value={date}
              onChange={(moment) => {
                setDate(moment);
              }}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Tabs
          type="card"
          defaultActiveKey="1"
          style={{ width: "100%", marginTop: 15 }}
        >
          <TabPane disabled={loading} tab="Phone" key="1">
            <Histogram
              loading={loading}
              data={data.phone}
              x="phone"
              y="Total"
              position="phone*Total"
            />
          </TabPane>
          <TabPane disabled={loading} tab="Country" key="2">
            <Histogram
              loading={loading}
              data={data.country}
              x="country"
              y="Total"
              position="country*Total"
            />
          </TabPane>
          <TabPane disabled={loading} tab="Region" key="3">
            <Histogram
              loading={loading}
              data={data.region}
              x="province"
              y="Total"
              position="province*Total"
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};
