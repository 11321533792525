import React, { Fragment, useState } from "react";
import classes from "./PreviewButton.module.less";
import ToolTip from "../Tooltip/Tooltip";
import { Modal, Button } from "antd";
import LoadingSpin from "../LoadingSpin/LoadingSpin";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";

export default ({ fileName }) => {
  let [dialogPreview, setDialogPreview] = useState(false);
  let [pages, setPages] = useState(0);

  const getPreviewAbleContent = () => {
    let fileExtensions = /(\.pdf|\.PDF)$/i;
    let imageExtensions = /(\.jpg|\.png|\.jpeg|\.svg|\.JPG|\.JPEG|\.gif|\.webp)$/i;
    let previewAbleContent = null;
    if (fileExtensions.exec(fileName)) {
      previewAbleContent = (
        <Fragment>
          <Button
            onClick={() => {
              setDialogPreview(true);
            }}
            shape="circle"
            icon="eye"
          />
          <Modal
            visible={dialogPreview}
            onCancel={() => setDialogPreview(false)}
            footer={null}
            width={700}
          >
            <div className={classes.document}>
              <Document
                file={`https://appinchina.space/uploads/${fileName}`}
                noData="No PDF file specified."
                loading={<LoadingSpin style={{ height: 400 }} />}
                onLoadSuccess={({ numPages }) => {
                  setPages(numPages);
                }}
                options={{
                  cMapUrl: "cmaps/",
                  cMapPacked: true,
                }}
              >
                {Array.from(new Array(pages), (el, index) => (
                  <Page
                    loading=""
                    width={600}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ))}
              </Document>
            </div>
          </Modal>
        </Fragment>
      );
    } else if (imageExtensions.exec(fileName)) {
      previewAbleContent = fileName ? (
        <ToolTip
          content={<img src={`https://appinchina.space/uploads/${fileName}`} />}
        >
          <Button shape="circle" icon="eye" />
        </ToolTip>
      ) : null;
    } else if (
      !imageExtensions.exec(fileName) &&
      !fileExtensions.exec(fileName)
    ) {
      previewAbleContent = <Button shape="circle" disabled icon="eye" />;
    }
    return previewAbleContent;
  };
  return (
    <div>
      {
        <div className={[classes.Container].join(" ")}>
          <div>{getPreviewAbleContent()}</div>
        </div>
      }
    </div>
  );
};
