import React, { useEffect, useState } from "react";
import classes from "./ECSDetails.module.less";
import functions from "../../../../../functions";
import { Form, Radio, Slider, Row, Col, InputNumber, Select } from "antd";
const { Item } = Form;
const { Option } = Select;
const ECSDetails = ({
  getFieldDecorator,
  calculatePrice,
  getFieldValue,
  resetFields,
  total,
  price,
  itemToEdit,
  ...props
}) => {
  let [initial, setInitial] = useState(null);
  useEffect(() => {
    calculatePrice(
      getFieldValue("type_storage"),
      getFieldValue("speed"),
      getFieldValue("extra_storage"),
      getFieldValue("months_paid")
    );
  }, []);
  useEffect(() => {
    if (itemToEdit) {
      setInitial(itemToEdit);
    }
  }, [itemToEdit]);
  let months = [3, 6, 9, 12];

  return (
    <div className={classes.container}>
      <Row type="flex" justify="space-between">
        <h3>{props.cpu_ram}</h3>
        <h1 className={classes.price}>
          USD ${functions.styleAsNumber(price)}/Month
        </h1>
      </Row>
      <p className={classes.info}>{props.chip}</p>
      <p className={classes.info}>
        Alibaba Cloud Intranet Speed {props.intranet_speed} Gbps
      </p>
      <br />
      <p className={classes.title}>Operating System</p>
      <Item style={{ margin: 0 }}>
        {getFieldDecorator(`operating_systems`, {
          initialValue:
            initial && initial.operating_system ? initial.operating_system : "",
          rules: [
            {
              required: true,
              message: "Operating System Required!",
              whitespace: true
            }
          ]
        })(
          <Radio.Group>
            {props.operating_systems.map(it => (
              <Radio key={it} value={it}>
                {it}
              </Radio>
            ))}
          </Radio.Group>
        )}
      </Item>
      <p className={classes.title}>Storage</p>
      <Item style={{ margin: 0 }}>
        {getFieldDecorator(`type_storage`, {
          initialValue:
            initial && initial.storage_type ? initial.storage_type : "HDD",
          rules: [
            {
              required: true,
              message: "Required!",
              whitespace: true
            }
          ]
        })(
          <Radio.Group
            onChange={e => {
              resetFields(["extra_storage"]);
              calculatePrice(
                e.target.value,
                getFieldValue("speed"),
                getFieldValue("extra_storage"),
                getFieldValue("months_paid")
              );
            }}
          >
            <Radio value="SSD">SSD</Radio>
            <Radio value="HDD">HDD</Radio>
          </Radio.Group>
        )}
      </Item>
      <Item style={{ marginBottom: 16 }}>
        {getFieldDecorator(`extra_storage`, {
          initialValue: initial && initial.storage_gb ? initial.storage_gb : 40
        })(
          <InputNumber
            onChange={extra =>
              calculatePrice(
                getFieldValue("type_storage"),
                getFieldValue("speed"),
                extra,
                getFieldValue("months_paid")
              )
            }
            min={40}
            max={80}
            // step={getFieldValue("type_storage") === "HDD" ? 0.35 : 1}
            step={1}
            formatter={value => `${value}GB`}
            parser={value => value.replace("GB", "")}
          />
        )}
      </Item>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={24}>
          <p className={classes.title}>Public IPV4 Address Speed (Mpbs)</p>
        </Col>
        <Col span={20}>
          <Item style={{ margin: 0 }}>
            {getFieldDecorator(`speed`, {
              initialValue:
                initial && initial.public_ipv4_address_speed_mpbs_
                  ? initial.public_ipv4_address_speed_mpbs_
                  : 1
            })(
              <Slider
                onChange={speed =>
                  calculatePrice(
                    getFieldValue("type_storage"),
                    speed,
                    getFieldValue("extra_storage"),
                    getFieldValue("months_paid")
                  )
                }
                min={1}
                max={30}
                step={1}
              />
            )}
          </Item>
        </Col>
        <Col span={4}>
          <h3 style={{ textAlign: "right" }}>{getFieldValue("speed")} Mbps</h3>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {" "}
          <Item label="Select a monthly plan:" style={{ margin: 0 }}>
            {getFieldDecorator(`months_paid`, {
              initialValue:
                initial && initial.months_paid ? initial.months_paid : 3
            })(
              <Select
                onChange={val =>
                  calculatePrice(
                    getFieldValue("type_storage"),
                    getFieldValue("speed"),
                    getFieldValue("extra_storage"),
                    val
                  )
                }
              >
                {months.map(it => (
                  <Option key={it} value={it}>
                    {it} Months
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
        <Col span={12}>
          <h1 className={classes.total}>
            Total: USD ${functions.styleAsNumber(total)}
          </h1>
        </Col>
      </Row>
    </div>
  );
};
export default ECSDetails;
