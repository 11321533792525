import React from "react";
import UncontractedMessage from "../UncontractedAppPromotion/UncontractedMessage";
import Summary from "./Summary";
import MarketTable from "./MarketTable";
import Charts from "./Chart";
import classes from "../UserActivity/UserBehavior.module.less";

export default ({ app, useDemoData }) => {
  return (
    <div className={classes.container}>
      <UncontractedMessage app={app} useDemoData={useDemoData} />
      <Summary app={app} useDemoData={useDemoData} />
      <MarketTable app={app} useDemoData={useDemoData} />
      <Charts app={app} useDemoData={useDemoData} />
    </div>
  );
};
