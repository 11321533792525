import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import Summary from "./Summary/Summary";
import DataSegmentation from "./DataSegmentation/DataSegmentation";
import UsersBehavior from "./UserActivity/UserBehavior";
import PromotionExpenses from "./PromotionExpenses/PromotionExpenses";
import PaidUsers from "./PaidUsers/PaidUsers";
import ActiveUsers from "./ActiveUsers/ActiveUsers";
import RetainedUsers from "./RetainedUsers/RetainedUsers";
import AppVersions from "./AppVersions/AppVersions";
import OverralData from "./OverralData/OverralData/OverralData";

export default props => {
  const [loading, setLoading] = useState(true);
  let [useDemoData, setUseDemoData] = useState(true);
  let { apps, appId } = props;
  useEffect(() => {
    let app = apps.find(app => app.id === appId);
    let usingDemoApp =
      appId === "1234-8475-9439-9494" || appId === "1234-8475-9439-9495";
    if (app || usingDemoApp) {
      setUseDemoData((!!app && !app.analitycs_id) || usingDemoApp);
      setLoading(false);
    }
  }, [appId]);

  const getContent = props => {
    let section = props.section || "summary";
    let app = apps.find(app => app.id === appId);
    switch (section) {
      case "summary":
        return <Summary app={app} useDemoData={useDemoData} />;
      case "overall-data":
        return <OverralData app={app} useDemoData={useDemoData} />;
      case "data-segmentation":
        return <DataSegmentation app={app} useDemoData={useDemoData} />;
      case "users-behavior":
        return <UsersBehavior app={app} useDemoData={useDemoData} />;
      case "promotion-expenses":
        return <PromotionExpenses app={app} useDemoData={useDemoData} />;
      case "app-versions":
        return <AppVersions app={app} useDemoData={useDemoData} />;
      case "retained-users":
        return <RetainedUsers app={app} useDemoData={useDemoData} />;
      case "active-users":
        return <ActiveUsers app={app} useDemoData={useDemoData} />;
      case "paying-users":
        return <PaidUsers app={app} useDemoData={useDemoData} />;
      default:
        return <Summary app={app} useDemoData={useDemoData} />;
    }
  };

  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    />
  ) : (
    <div style={{ textAlign: "left" }}>{getContent(props)}</div>
  );
};
