import React from "react";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import DataSet from "@antv/data-set";
import { appStoresDownloads } from "../../fakeData";
export default class extends React.Component {
  render() {
    let data = this.props.useDemoData ? appStoresDownloads : this.props.data;
    const scale = {
      store_count: {
        min: 0
      }
    };
    const ds = new DataSet();
    const dv = ds.createView().source(data);
    dv.source(data).transform({
      type: "sort",
      callback(a, b) {
        return a.store_count - b.store_count;
      }
    });
    return (
      <div>
        <Chart
          height={500}
          data={data}
          padding={[60, 20, 40, 60]}
          scale={scale}
          forceFit
        >
          <Axis
            name="store_count"
            labels={null}
            title={null}
            line={null}
            tickLine={null}
            label={{ formatter: val => `${val}` }}
          />
          <Tooltip
            crosshairs={{
              type: "y"
            }}
          />
          <Geom
            type="interval"
            position="app_store*store_count"
            color={"app_store"}
            opacity={0.4}
            tooltip={[
              "app_store*store_count",
              (name, vote) => {
                vote = `${vote}`;
                return {
                  name: vote
                };
              }
            ]}
          />
        </Chart>
      </div>
    );
  }
}
