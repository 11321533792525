import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Redirect } from "react-router-dom";
import FormStepper from "../FormStepper/FormStepper";
import classes from "./SubmitNewVersion.module.less";
import { getOrCreateDraft } from "../../../../services/distributionService";
import LoadingSpin from "../../../UI/LoadingSpin/LoadingSpin";

const SubmitNewVersion = ({ app }) => {
  let [draftInfo, setDraftInfo] = useState(null);
  let [loading, setLoading] = useState(false);
  let [lastVersion, setLastVersion] = useState({});
  let [creatingDraft, setCreatingDraft] = useState(false);
  let [step, setStep] = useState(0);

  const isOwner = app.role === "OWNER" || app.role === "ADMIN";

  useEffect(() => {
    getData();
  }, [app]);

  const getData = async (defaultStep = null) => {
    setLoading(true);
    try {
      setCreatingDraft(true);
      const { data } = await getOrCreateDraft(app.id, true);
      if (data.response) {
        setDraftInfo(data.response.draft);
        setLastVersion(data.response.latest_version);
        if (defaultStep !== null) {
          setStep(defaultStep);
        } else {
          setStep(getHighestCompletedStep(data.response.draft));
        }
      }
      setCreatingDraft(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getHighestCompletedStep = (data) => {
    for (let i = 6; i >= 0; i--) {
      if (data[`step${i}_finished`]) return i + 1;
    }
    return 0;
  };

  return isOwner ? (
    <div className={classes.container}>
      {loading ? (
        <LoadingSpin />
      ) : draftInfo && lastVersion ? (
        <FormStepper
          app={app}
          loadingInfo={creatingDraft}
          lastSubmition={lastVersion}
          draft={draftInfo}
          initialStep={step}
          is_apk_update={true}
          getData={getData}
          loadingInfo={creatingDraft}
        />
      ) : null}
    </div>
  ) : (
    <Redirect to={`/distribution/${app.id}/app-information`} />
  );
};

export default SubmitNewVersion;
