import React from "react";
import { Card, List, Row } from "antd";
export default ({ storesPublished = [], loading, app = {} }) => {
  const getValue = (value) => {
    return value ? value : "N/A";
  };
  const data = [
    {
      title: "Target Number Of Stores:",
      content: <span>{getValue(app.main_information.stores_to_upload)}</span>,
    },
    {
      title: "Stores Currently Published:",
      content: <span>{storesPublished.length}</span>,
    },
    {
      title: "Annual Updates:",
      content: <span>{getValue(app.main_information.amount_updates)}</span>,
    },
    {
      title: "Annual Updates Left:",
      content: <span>{getValue(app.main_information.updates_left)}</span>,
    },
  ];
  return (
    <Card loading={loading} size="small" title="Contract Details">
      <List
        size="small"
        dataSource={data}
        renderItem={(item) => (
          <List.Item key={item.title}>
            <Row type="flex" justify="space-between" style={{ width: "100%" }}>
              <List.Item.Meta
                style={{ textAlign: "left" }}
                title={item.title}
              />
              <div>{item.content}</div>
            </Row>
          </List.Item>
        )}
      />
    </Card>
  );
};
