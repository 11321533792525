import React, { useState, useEffect } from "react";
import classes from "../PaymentsPlatform.module.less";
import { Button, Table, Tag, DatePicker, Input, Alert, Row, Col, Select, List } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import RefundDialog from "../../PaymentsPlatform/RefundsHistory/RefundDialog/RefundDialog";
import functions from "../../../functions";
import {
  getUserDetails,
  getTransactionById,
  getTransactions,
  getBizNo,
  getTransactionsByCustomerName
} from "../../../services/aicPay";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import { transactions_table_fake } from "../FakeData";
import Scrollable from "../../UI/Scrollable/Scrollable";
import ExportCsvModal from "./ExportCsvModal/ExportCsvModal";
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { Option } = Select;
const getTag = {
  "11": <Tag color="volcano">PENDING</Tag>,
  "21": <Tag color="green">PAID</Tag>,
  "31": <Tag color="red">PAYMENT FAILED</Tag>,
  "41": <Tag color="blue">ORDER CLOSED</Tag>,
  "51": <Tag color="purple">REFUNDED</Tag>,
};
export default ({ app, useDemoData, ...props }) => {
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [userInfo, setInfo] = useState([]);
  const [showRefund, setShowRefund] = useState(false);
  const [visible, setVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [previousSorter, setPreviousSorter] = useState({});
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [useAsync, setUseAsync] = useState(true);
  const [filters, setFilters] = useState("21,31,41,11,51");
  const [searchFilter, setSearch] = useState({ filter: undefined, value: null })
  const [balance, setBalance] = useState(null);
  const [csvModal, setCsvModal] = useState(false);
  const [detailsList, setDetailsList] = useState([]);
  const [detailsFilters, setDetailsFilters] = useState('');
  const [platformFilters, setPlatformFilters] = useState('');
  const searchOptions = [{ value: "user", label: "Phone Number" },
  { value: "customer_name", label: "User Name" },
  { value: "transaction_id", label: "Transaction ID" },
  { value: "biz_no", label: "Biz No" }];
  const paymentsId = [
    app.alipay_id || "",
    app.wechat_id || "",
    app.seccond_alipay_id || "",
    app.seccond_wechat_id || "",
  ].join(",");
  const tableColumns = [
    {
      title: "Phone Number",
      dataIndex: "user",
      key: "user",
      align: "center",
      render: (user) =>
        user ? (
          <Link to={`/monetization/${app ? app.id : props.appId}/user/${user}`}>
            {user}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 180,
      sorter: true,
      align: "center",
    },
    {
      title: "User Name",
      dataIndex: "customer_name",
      key: "customerName",
      render: (customer_name) =>
        customer_name ? (
          <Link
            to={`/monetization/${app ? app.id : props.appId
              }/user-unregistered/${customer_name}`}
          >
            {customer_name}
          </Link>
        ) : (
          ""
        ),
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
    },
    {
      title: "Details",
      dataIndex: "goods_title",
      key: "goods_title",
      filters: detailsList.map(e => ({ text: e, value: e })) || []
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      align: "center",
      render: (amount) => <span>¥{amount / 100}</span>,
    },
    {
      title: "Platform",
      dataIndex: "pay_channel",
      key: "pay_channel",
      align: "center",
      filters: [{ text: "Alipay", value: "AliPay" }, { text: "WeChat", value: "WeChat" },],
      render: (platform) => {
        let tag = "Not Set";
        if (
          platform === "WeChat"
        ) {
          tag = <Tag color="orange">WeChat</Tag>;
        } else if (platform == "AliPay") {
          tag = <Tag color="geekblue">Alipay</Tag>;
        }
        return tag;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
        { text: "PAID", value: "21" },
        { text: "PAYMENT FAILED", value: "31" },
        { text: "ORDER CLOSED", value: "41" },
        { text: "PENDING", value: "11" },
        { text: "REFUNDED", value: "51" },
      ],
      render: (status) => getTag[status],
    },
    {
      title: "Renewal Agreement #",
      dataIndex: "renewal_agreement",
      key: "renewal_agreement",
      align: "center",
      render: (renewal_agreement) =>
        renewal_agreement ? (
          <Link to={`/monetization/${app ? app.id : props.appId}/renewals/${renewal_agreement}`}>
            {renewal_agreement}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      align: "center",
      render: (transaction_id) => (
        <Ellipsis length={15} tooltip>
          {transaction_id}
        </Ellipsis>
      ),
    },
    {
      title: "Platform ID",
      dataIndex: "refund_no",
      key: "refund_no",
      align: "center",
      render: (refund_no) => (refund_no ? refund_no : "-"),
    },
    {
      title: "Biz No",
      dataIndex: "biz_no",
      key: "biz_no",
      align: "center",
      render: (biz_no) =>
        biz_no ? (
          <Ellipsis length={15} tooltip>
            {biz_no}
          </Ellipsis>
        ) : (
          "-"
        ),
    },
    {
      title: "Attach Data",
      dataIndex: "attach_data",
      key: "attach_data",
      align: "center",
      render: (attach_data) =>
        attach_data ? (
          <Ellipsis length={15} tooltip>
            {attach_data}
          </Ellipsis>
        ) : (
          "-"
        ),
    },
    {
      title: "",
      dataIndex: "refund",
      key: "refund",
      align: "center",
      render: (refund, item) => {
        let content =
          item.status == "21" ? (
            <a onClick={() => showRefundInfo(item, updateAfterRefund)}>
              Refund
            </a>
          ) : (
            <Tag color="grey">Not refundable</Tag>
          );
        return content;
      },
    },
  ];

  useEffect(() => {
    fetch({
      results: 10,
      page: 1,
      sortField: "gmt_create",
      filters: "21,31,41,11,51",
      sortOrder: "descend",
      platform: "WeChat,AliPay",
      detailsOptions: detailsFilters
    });
  }, [app.id, useDemoData]);

  const fetch = async (params = {}) => {
    if (!useDemoData)
      try {
        if (!!app) {
          setLoading(true);
          let response = await getTransactions({
            rangeDate: { start: start_date, end: end_date },
            ...params,
            platform: params.platform !== "" ? params.platform : "WeChat,AliPay",
            id: paymentsId,
            app,
          });
          setLoading(false);
          if (response) {
            setTotal(response.total_results);
            setValues(response.transactions);
            setBalance(response.balance || 0);
            setDetailsList(response.detailsOptions || [])
            setDetailsFilters(response.detailsOptions ? encodeURIComponent(response.detailsOptions.join(",")) : "");
          }
        }
      } catch (error) {
        console.log(error);
      }
  };

  const handlePaginationChange = (newCurrent) => {
    const field = {
      date: "gmt_create",
      amount: "amount",
    };
    const date =
      start_date != "" && end_date != ""
        ? { rangeDate: { start: start_date, end: end_date } }
        : {};
    setCurrent(newCurrent);
    fetch({
      results: 10,
      page: newCurrent,
      sortField: previousSorter.field
        ? field[previousSorter.field]
        : "gmt_create",
      filters,
      platform: platformFilters,
      detailsOptions: detailsFilters,
      sortOrder: previousSorter.order || "descend",
      ...date,
    });
  };

  const handleTableChange = (_, filters, sorter) => {
    const field = {
      date: "gmt_create",
      amount: "amount",
    };
    const date =
      start_date != "" && end_date != ""
        ? { rangeDate: { start: start_date, end: end_date } }
        : {};
    const filtros =
      filters && filters.status ? filters.status.join(",") : "21,31,41,11,51";
    const filtersPlatform = filters && filters.pay_channel ? filters.pay_channel.join(",") : "";
    const detailsFilters = filters && filters.goods_title ? filters.goods_title.join(",") : "";
    setFilters(filtros);
    setPreviousSorter(sorter);
    setPlatformFilters(filtersPlatform)
    setCurrent(1);
    fetch({
      results: 10,
      page: 1,
      sortField: sorter.field ? field[sorter.field] : "gmt_create",
      filters: filtros,
      detailsOptions: detailsFilters,
      sortOrder: sorter.order || "descend",
      platform: filtersPlatform,
      ...date,
    });
  };

  const onChangeDate = (_, [start, end]) => {
    fetch({
      results: 10,
      page: 1,
      rangeDate: { start, end },
      sortField: "gmt_create",
      sortOrder: "descend",
      filters: filters,
      platform: platformFilters,
      detailsOptions: detailsFilters
    });
    setDisabled(false);
    setStartDate(start);
    setEndDate(end);
    setCurrent(1);
  };

  const showRefundInfo = (info) => {
    setVisible(true);
    setInfo(info);
    setShowRefund(true);
  };

  const resetFilter = () => {
    setSearch({ filter: undefined, value: null })
    setUseAsync(true);
    setFilters("21,31,41,11,51");
    setStartDate(null);
    setEndDate(null);
    setCurrent(1);
    setDetailsList([]);
    fetch({
      results: 10,
      page: 1,
      sortField: "gmt_create",
      sortOrder: "descend",
      filters: "21,31,41,11,51",
      platform: "WeChat,AliPay",
      detailsOptions: ""
    });
  };

  const handleRequest = () => {
    const { filter, value } = searchFilter;
    if (filter) {
      if (filter === "transaction_id") {
        handleSearchTransaction(value)
      }
      else if (filter === "user") {
        handleSearchUser(value)
      } else if (filter === "customer_name") {
        handleSearchCustomerName(value)
      } else if (filter === "biz_no") {
        handleSearchBizNo(value)
      }
    }
  }

  const handleSearchTransaction = async (value) => {
    setUseAsync(false);
    setDisabled(false);
    setLoading(true);
    let response = await getTransactionById(value, paymentsId, app);
    setLoading(false);
    if (response) {
      setCurrent(1);
      setTotal(response.transactions.length);
      setValues(response.transactions);
    }
  };

  const handleSearchUser = async (value) => {
    setUseAsync(false);
    setDisabled(false);
    setLoading(true);
    let response = await getUserDetails(value, paymentsId, app);
    setLoading(false);
    if (response) {
      setCurrent(1);
      setTotal(response.total_results);
      setValues(response.user.transactions);
    }
  };

  const handleSearchBizNo = async (value) => {
    setUseAsync(false);
    setDisabled(false);
    setLoading(true);
    let response = await getBizNo(value, paymentsId, app);
    setLoading(false);
    if (response) {
      setCurrent(1);
      setTotal(response.transactions.length);
      setValues(response.transactions);
    }
  };
  const handleSearchCustomerName = async (value) => {
    setUseAsync(false);
    setDisabled(false);
    setLoading(true);
    let response = await getTransactionsByCustomerName(value, paymentsId, app);
    setLoading(false);
    if (response) {
      setCurrent(1);
      setTotal(response.transactions.length);
      setValues(response.transactions);
    }
  };

  const getDate = () => {
    return `The balance from ${start_date ||
      moment()
        .startOf("month")
        .format("YYYY-MM-DD HH:mm")} until ${end_date ||
        moment().format("YYYY-MM-DD HH:mm")} : `;
  };

  const updateAfterRefund = (id) => {
    const newTransactions = values.map(
      ({ transaction_id, status, ...rest }) => ({
        ...rest,
        transaction_id,
        status: transaction_id == id ? "51" : status,
      })
    );
    setValues(newTransactions);
  };

  return (
    <div className={classes.tableContainer}>
      {balance != null && start_date && end_date ? (
        <div>
          <Alert
            message={
              <p style={{ marginBottom: 0, textAlign: "left" }}>
                {getDate()}{" "}
                <strong>¥ {functions.styleAsNumber(balance)}</strong>
              </p>
            }
            type="info"
            showIcon
          />
        </div>
      ) : null}
      <Row justify="start" gutter={8}>
        <Col style={{ marginTop: 12 }} xs={24} sm={24} md={18} lg={12} xl={10}>
          <Row gutter={8}> <Col span={8}>
            <Select disabled={loading} loading={loading} value={searchFilter.filter} placeholder="Search By" style={{ width: "100%" }} onChange={val => setSearch({ ...searchFilter, filter: val })}>
              {searchOptions.map(({ label, value }) => (
                <Option key={value} value={value}>{label}</Option>
              ))}</Select></Col>
            <Col span={16}>
              <Search
                style={{ width: "100%" }}
                disabled={loading || !searchFilter.filter}
                onChange={e => setSearch({ ...searchFilter, value: e.target.value })}
                onSearch={handleRequest}
                placeholder="Type here..."
                value={searchFilter.value}
              />
            </Col>
          </Row>
        </Col>
        <Col style={{ marginTop: 12 }} xs={24} sm={12} md={12} lg={12} xl={6}>
          <RangePicker
            style={{ width: "100%" }}
            disabled={loading || searchFilter.filter}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
            allowClear={false}
            value={start_date && end_date ? [moment(start_date), moment(end_date)] : null}
          />
        </Col>
        <Col style={{ marginTop: 12 }} xs={12} sm={4} md={4} lg={4} xl={2}>
          <Button
            type="primary"
            onClick={resetFilter}
            disabled={disabled}
            style={{ width: "100%", padding: "0px" }}
            loading={loading}
          >
            Reset
          </Button>
        </Col>
        <Col style={{ marginTop: 12 }} xs={12} sm={6} md={6} lg={6} xl={4}>
          <Button
            icon="download"
            onClick={() => setCsvModal(true)}
            type="primary"
            // loading={downloading}
            disabled={loading}
            style={{ width: "100%", padding: "0px" }}
          >
            Download as CSV
          </Button>
        </Col>
      </Row>
      <div>
        {values && values.length && values.length > 0 || useDemoData ?

          <>
            <ExportCsvModal detailsList={detailsList} app={app} paymentsId={paymentsId} display={csvModal} close={() => setCsvModal(false)} />
            <Scrollable
              pagination={{ current, total }}
              onPaginationChange={useAsync ? handlePaginationChange : () => { }}
            >
              <Table
                dataSource={useDemoData ? transactions_table_fake : values}
                rowKey={(record) => record.transaction_id}
                columns={tableColumns}
                size="small"
                loading={loading}
                scroll={{ x: 1700 }}
                pagination={false}
                onChange={useAsync ? handleTableChange : () => { }}
              />

            </Scrollable></> : <List loading={loading} dataSource={[]} />}
      </div>
      {showRefund ? (
        <RefundDialog
          useDemoData={useDemoData}
          info={userInfo}
          show={visible}
          setVisible={setVisible}
          updateAfterRefund={updateAfterRefund}
          app={app}
        />
      ) : null}
    </div>
  );
};
