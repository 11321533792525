import React, { useEffect, useState, Fragment } from "react";
import {
  Alert,
  Row,
  Button,
  Form,
  List,
  Card,
  Icon,
  Modal,
  Radio,
  Result,
  Checkbox,
  notification
} from "antd";
import functions from "../../../functions";
import classes from "./Cart.module.less";
import { Link } from "react-router-dom";
import history from "../../../history";
import PayPalButton from "./PaypalButton/PayPalButton";
import AccountDetails from "./AccountDetails/AccountDetails";
import AccountBalancePay from "./AccountBalancePay/AccountBalancePay";
import {
  newCloudOrder,
  getOrdersByApp
} from "../../../services/cloudStoreService";
const { confirm } = Modal;
let uuid = require("uuid-random");
export default Form.create()(({ form, appId, ...props }) => {
  let [orders, setOrders] = useState([]);
  let [total, setTotal] = useState(0);
  let [success, setSuccess] = useState(false);
  let [loadingPay, setLoadingPay] = useState(false);
  let [paymentMethod, setPaymentMethod] = useState("");
  let [disablePaypal, setDisablePaypal] = useState(false);
  let [disabled, setDisabled] = useState(false);
  let [freeDomain, setFreeDomain] = useState(false);
  let [appName, setAppName] = useState("");
  let [loading, setLoading] = useState(false);

  const getOrders = () => {
    let data = localStorage.cloud_cart
      ? JSON.parse(localStorage.cloud_cart)
      : [];
    let ordersValues = data && data.orders ? data.orders : [];
    const index = ordersValues.findIndex(e => e.product_type === "domain");
    const indexECS = ordersValues.findIndex(e => e.product_type === "ecs");
    if (ordersValues.length > 0 && index === -1 && indexECS !== -1) {
      setDisabled(true);
      getOrdersFromDatabase();
    } else {
      setDisabled(false);
    }
    let item = ordersValues.filter(e =>
      e.product_subtype.includes("appinchina.com.cn")
    );
    if (item.length > 0) {
    } else {
      let app = props.apps.filter(e => e.id === appId)[0];
      setAppName(
        app.name
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "")
          .replace(/\s/g, "")
      );
    }
    setOrders(ordersValues);
    setSuccess(false);
    setPaymentMethod("");
  };

  const getOrdersFromDatabase = async () => {
    setLoading(true);
    let { data } = await getOrdersByApp(appId);
    if (data && data.orders) {
      let servers = data.orders.filter(e => e.product_type === "ecs");
      if (servers.length === 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }

    setLoading(false);
  };

  const confirmDelete = id => {
    confirm({
      title: "Are you sure you want to remove from cart?",
      onOk() {
        deleteFromCart(id);
      },
      onCancel() {}
    });
  };
  const createOrder = async values => {
    setLoadingPay(true);
    let format = { appId, orders: values, paymentMethod };
    let { data } = await newCloudOrder(format);
    setLoadingPay(false);
    if (data && data.message === "success") {
      setSuccess(true);
      localStorage.removeItem("cloud_cart");
    } else {
      notification.error({
        message: `An error has ocurred.`
      });
    }
  };

  const addSubdomain = () => {
    let localStorageCopy = localStorage.cloud_cart
      ? JSON.parse(localStorage.cloud_cart)
      : [];
    let localStorageOrders =
      localStorageCopy && localStorageCopy.orders
        ? localStorageCopy.orders
        : [];

    localStorageOrders.push({
      id: uuid(),
      title: `Domain: ${appName}.appinchina.com.cn`,
      product_type: "domain",
      product_subtype: `${appName}.appinchina.com.cn`,
      months_paid: 12,
      total: 1000,
      price: 0,
      onetime_fee: 0,
      monthly_price: 0,
      description: [
        { key: "Domain", value: `${appName}.appinchina.com.cn` },
        { key: "Available", value: "Available" }
      ]
    });
    const appFilterSettings = {
      orders: [...localStorageOrders]
    };
    window.localStorage.setItem(
      "cloud_cart",
      JSON.stringify(appFilterSettings)
    );
  };
  const deleteSubdomain = () => {
    let localStorageCopy = localStorage.cloud_cart
      ? JSON.parse(localStorage.cloud_cart)
      : [];
    let localStorageOrders =
      localStorageCopy && localStorageCopy.orders
        ? localStorageCopy.orders
        : [];

    localStorageOrders = localStorageOrders.filter(
      e => e.product_subtype !== `${appName}.appinchina.com.cn`
    );
    const appFilterSettings = {
      orders: [...localStorageOrders]
    };
    window.localStorage.setItem(
      "cloud_cart",
      JSON.stringify(appFilterSettings)
    );
  };

  const deleteFromCart = id => {
    let item = orders.filter(e => e.id === id);
    if (item[0].product_subtype === `${appName}.appinchina.com.cn`) {
      deleteSubdomain();
      setFreeDomain(false);
    } else {
      let filter = orders.filter(e => e.id !== id);
      const appFilterSettings = {
        orders: filter
      };
      window.localStorage.setItem(
        "cloud_cart",
        JSON.stringify(appFilterSettings)
      );
      setOrders(filter);
      getOrders();
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    getOrders();
    if (appId === "1234-8475-9439-9495" || appId === "1234-8475-9439-9494") {
      setDisablePaypal(true);
    } else {
      setDisablePaypal(false);
    }
  }, [appId]);

  useEffect(() => {
    if (freeDomain) {
      addSubdomain();
    } else {
      deleteSubdomain();
    }
    getOrders();
  }, [freeDomain]);

  useEffect(() => {
    let sum = orders.reduce((acc, curr) => (acc += curr.total), 0);
    setTotal(sum);
  }, [orders]);

  return (
    <div className={classes.container}>
      {!success ? (
        <Fragment>
          <Alert
            className={classes.alertText}
            message={
              <div>
                <p>Total:</p>
                <b>USD ${functions.styleAsNumber(total)}</b>
              </div>
            }
            type="success"
          />
          {disabled || freeDomain ? (
            <Alert
              showIcon
              style={{ marginTop: 20 }}
              className={classes.alertInfo}
              message={
                <div>
                  <b>Server purchases also require a domain purchase.</b>
                  <p>
                    <Checkbox
                      checked={freeDomain}
                      onChange={e => {
                        setDisabled(!e.target.checked);
                        setFreeDomain(e.target.checked);
                      }}
                    >
                      Include subdomain of {appName}.appinchina.com.cn with a
                      cost of USD $1,000
                    </Checkbox>
                  </p>
                </div>
              }
              type="warning"
            />
          ) : null}
          <div className={classes.shopButton}>
            <Link to={`/cloud/products/${appId}`}>
              <Button icon="shopping-cart" onClick={() => {}}>
                Continue Shopping
              </Button>
            </Link>
          </div>
          <List
            grid={{ gutter: 16, column: orders.length > 1 ? 2 : 1 }}
            dataSource={orders}
            renderItem={({
              title,
              price,
              months_paid,
              total,
              description,
              id,
              product_type
            }) => (
              <List.Item>
                <Card
                  key={id}
                  title={
                    <Row type="flex" justify="space-between">
                      <span>{title}</span>
                      <b>USD ${functions.styleAsNumber(total)}</b>
                      <b>
                        {months_paid} {months_paid > 1 ? "Months" : "Month"}
                      </b>
                    </Row>
                  }
                  bodyStyle={{ height: 250 }}
                  actions={[
                    product_type !== "domain" ? (
                      <Button
                        type="link"
                        onClick={() =>
                          history.push({
                            pathname: `/cloud/shop/${product_type}/${appId}`,
                            state: {
                              id: id
                            }
                          })
                        }
                      >
                        <Icon type="edit" key="edit" />
                      </Button>
                    ) : null,
                    <Button onClick={() => confirmDelete(id)} type="link">
                      <Icon type="delete" key="delete" />
                    </Button>
                  ]}
                >
                  <div className={classes.description}>
                    {description.map((desc, index) => (
                      <p key={index}>
                        <b>{desc.key}</b>:{" "}
                        {desc.key === "Type" && desc.value === "mysql"
                          ? "MySQL"
                          : desc.key === "Type" && desc.value === "postgres_sql"
                          ? "PostgresSQL"
                          : desc.value}
                      </p>
                    ))}
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </Fragment>
      ) : null}

      {total > 0 && !disabled ? (
        !success ? (
          <div className={classes.payment}>
            <p className={classes.paymentMethod}>Select a payment method:</p>
            <Radio.Group
              disabled={loadingPay}
              onChange={e => setPaymentMethod(e.target.value)}
            >
              <Radio.Button value="account_balance">
                Account Balance
              </Radio.Button>
              <Radio.Button value="bank_transfer">Bank Transfer</Radio.Button>
              {!disablePaypal ? (
                <Radio.Button value="paypal">Paypal</Radio.Button>
              ) : null}
            </Radio.Group>
            <Row style={{ marginTop: 20 }}>
              {paymentMethod === "paypal" ? (
                <PayPalButton
                  appId={appId}
                  total={total}
                  createOrder={createOrder}
                />
              ) : null}
              {paymentMethod === "bank_transfer" ? (
                <AccountDetails
                  appId={appId}
                  total={total}
                  loadingPay={loadingPay}
                  createOrder={createOrder}
                />
              ) : null}
              {paymentMethod === "account_balance" ? (
                <AccountBalancePay
                  appId={appId}
                  total={total}
                  loadingPay={loadingPay}
                  createOrder={createOrder}
                />
              ) : null}
            </Row>
          </div>
        ) : (
          <Result
            status="success"
            title="Successfully Purchased!"
            extra={[
              <Button
                type="link"
                icon="view"
                onClick={() => history.push(`/cloud/summary/${appId}`)}
              >
                View My Orders
              </Button>
            ]}
          />
        )
      ) : null}
    </div>
  );
});
