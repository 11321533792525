import React, { useState, useEffect } from "react";
import { DatePicker, Row, Col, Spin, Button, Select, notification } from "antd";
import HistogramByPage from "./Graphics/HistogramByPage";
import HistogramByTime from "./Graphics/HistogramByTime";
import AppVisits from "./Graphics/AppVisits";
import UserVisits from "./Graphics/UserVisits";
import AppPages from "./Tables/AppPages";
import moment from "moment";
import classes from "../AnalyticsHome.module.css";
import LineCard from "../../UI/LineCard/LineCard";
import { versions } from "../fakeData";
import { getUsersAnalytics } from "../../../services/analyticsService";
import UncontractedAnalyticsMessage from "../UncontractedAnalyticsMessage/UncontractedAnalyticsMessage";
const { RangePicker } = DatePicker;
const { Option } = Select;
export default ({ app, useDemoData }) => {
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [versionSelected, setVersionSelected] = useState("");
  const [data, setData] = useState({
    appVisits: [],
    pageAnalitycs: [],
    pagesTable: [],
    timeSpentInApp: [],
    userVisits: []
  });
  const defaultFilters = {
    start: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    version: "all"
  };

  useEffect(() => {
    setStart(defaultFilters.start);
    setEnd(defaultFilters.end);
    setVersionSelected(defaultFilters.version);
    fetch(defaultFilters.start, defaultFilters.end, defaultFilters.version);
  }, [app.id, useDemoData]);

  const onChangeDate = (_, [start, end]) => {
    fetch(start, end, versionSelected);
    setStart(start);
    setEnd(end);
  };
  const onChangeVersion = value => {
    setVersionSelected(value);
    fetch(start, end, value);
  };
  const resetFilter = () => {
    setStart(defaultFilters.start);
    setEnd(defaultFilters.end);
    setVersionSelected(defaultFilters.version);
    fetch(defaultFilters.start, defaultFilters.end, defaultFilters.version);
  };
  const fetch = async (start, end, version) => {
    if (!useDemoData && app)
      try {
        setLoading(true);
        let { data } = await getUsersAnalytics(
          start,
          end,
          version,
          app.analitycs_id
        );
        setLoading(false);
        if (data && data.userAnalytics) {
          setData({
            appVisits: data.userAnalytics.appVisits || [],
            pageAnalitycs: data.userAnalytics.userBehavior.pageAnalytics || [],
            pagesTable: data.userAnalytics.userBehavior.pagesTable || [],
            timeSpentInApp: data.userAnalytics.userBehavior.pageAnalytics || [],
            userVisits: data.userAnalytics.userVisits || []
          });
        } else {
          notification.warning({
            message: "There was an error while connecting to the server.",
            placement: "bottomLeft"
          });
        }
      } catch (error) {}
  };

  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    />
  ) : (
    <div className={classes.Container}>
      <UncontractedAnalyticsMessage app={app} useDemoData={useDemoData} />
      <div className={classes.Buttons}>
        <Row gutter={16}>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <RangePicker
              value={[moment(start), moment(end)]}
              style={{ width: "100%", marginBottom: 10 }}
              onChange={onChangeDate}
              format={"YYYY-MM-DD"}
            />
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <Select
              onChange={onChangeVersion}
              value={versionSelected}
              style={{ width: "100%", marginBottom: 10 }}
              disabled={!useDemoData}
            >
              {versions.map(it => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <Button
              style={{ width: "100%", marginBottom: 10 }}
              type="primary"
              onClick={resetFilter}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </div>
      <Row gutter={16}>
        <Col xxl={12}>
          <LineCard title="App Visits" style={{ textAlign: "center" }}>
            <AppVisits data={data.appVisits} useDemoData={useDemoData} />
          </LineCard>
        </Col>
        <Col xxl={12}>
          <LineCard title="User Visits" style={{ textAlign: "center" }}>
            <UserVisits data={data.userVisits} useDemoData={useDemoData} />
          </LineCard>
        </Col>
      </Row>

      <div>
        <Row gutter={16}>
          <Col xl={24}>
            <LineCard
              title="User Behavior By Page"
              style={{ textAlign: "center" }}
            >
              <HistogramByPage
                data={data.pageAnalitycs}
                useDemoData={useDemoData}
              />
            </LineCard>
          </Col>
          {/*<Col xl={12}>
            <LineCard
              title="Break down of user time spent in App"
              style={{ margin: "20px 0", textAlign: "center" }}
            >
              <HistogramByTime
                data={data.timeSpentInApp}
                useDemoData={useDemoData}
              />
            </LineCard>
              </Col>*/}
        </Row>
      </div>
      <div>
        <h3 className={classes.subtitle}>App Pages</h3>
        <AppPages data={data.pagesTable} useDemoData={useDemoData} />
      </div>
    </div>
  );
};
