import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, List, Row } from "antd";
import functions from "../../../../functions";
import { getDistributionResults } from "../../../../services/distributionService";

export default ({ app = {} }) => {
  let [data, setData] = useState({});
  let [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      const { id } = app;
      if (id) {
        setLoading(true);
        const { data } = await getDistributionResults(id);
        if (data.marketCoverage) {
          setData({ ...data });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [app.name]);
  const information = [
    {
      title: "Market Coverage (Latest App Version)",
      content: <p>{data.marketCoverage || 0}%</p>,
    },
    {
      title: "Total Cumulative Downloads",
      content: (
        <div>
          <span style={{ marginRight: "6px" }}>
            ~{functions.styleAsNumber(data.totalDownloads)}{" "}
          </span>
          <Link to={`/distribution/${app.id}/downloads`}>
            <Button icon="line-chart">View Details</Button>
          </Link>
        </div>
      ),
    },
    {
      title: "App Detailed History",
      content: (
        <Link to={`/distribution/${app.id}/history`}>Open Timeline</Link>
      ),
    },
  ];

  return (
    <Card loading={loading} size="small" title="Distribution Results">
      <List
        size="small"
        dataSource={information}
        renderItem={(item) => (
          <List.Item key={item.title}>
            <Row type="flex" justify="space-between" style={{ width: "100%" }}>
              <List.Item.Meta
                style={{ textAlign: "left" }}
                title={item.title}
              />
              <div>{item.content}</div>
            </Row>
          </List.Item>
        )}
      />
    </Card>
  );
};
