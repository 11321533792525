import React, { Component, Fragment } from "react";
import classes from "./GoogleSearcher.module.css";
import { Button, notification } from "antd";
import Input from "../UI/Input/Input";
import GoogleAppsDialog from "../Dialogs/GoogleAppsDialog/GoogleAppsDialog";
import functions from "../../functions.js";
import _ from "underscore";
import { getGoogleResults, getAppDetails } from "../../services/aiconService";

class GoogleSearcher extends Component {
  state = {
    appname: {
      value: "",
      validation: {
        required: true
      },
      valid: false,
      validated: false
    },
    link: {
      value: "",
      validation: {
        required: true
      },
      valid: false,
      validated: false
    },
    googleApps: [],
    showDialog: false,
    loadingGoogle: false
  };

  render() {
    let { appname, link } = this.state;
    let {
      handleFieldChange,
      useGoogleLink,
      handleGoogleAppSelected,
      handleNoGoogleAppSelected
    } = this;
    return (
      <Fragment>
        <GoogleAppsDialog
          display={this.state.showDialog}
          apps={this.state.googleApps}
          select={handleGoogleAppSelected}
          cancel={handleNoGoogleAppSelected}
        />
        <p>
          Type the name of your app below and choose the correct app from the
          given options
          <br />
          (This may take a few seconds)
        </p>
        <div>
          <form className={classes.AppnameContainer} onSubmit={this.submitForm}>
            <Input
              {...appname}
              placeholder="App Name"
              onChange={event => handleFieldChange(event, "appname")}
              valid={this.state.appname.valid}
              validated={this.state.appname.validated}
              errormessage="Field required"
            />
            <Button onClick={this.submitForm} type="primary" icon="search">
              Search
            </Button>
          </form>
        </div>
        <p className={classes.Text}>
          Or add the link to your app on Google Play
        </p>

        <div>
          <form
            className={classes.AppnameContainer}
            onSubmit={this.useGoogleLink}
          >
            <Input
              {...link}
              placeholder="Link"
              onChange={event => handleFieldChange(event, "link")}
              errormessage="Field required"
            />
            <Button
              onClick={useGoogleLink}
              disabled={this.state.loadingGoogle}
              type="primary"
            >
              Continue
            </Button>
          </form>
          <Button onClick={this.props.cancel} icon="left">
            Go Back
          </Button>
        </div>
      </Fragment>
    );
  }
  handleFieldChange = (event, name) => {
    let field = {
      ...this.state[name],
      validated: false,
      value: event.target.value
    };
    this.setState({ [name]: field });
  };
  submitForm = event => {
    event.preventDefault();
    let appname = {
      ...this.state.appname,
      validated: true
    };
    appname.valid = functions.checkValidity(appname.value, appname.validation);
    this.setState({ appname: appname });
    if (appname.valid) {
      this.setState({ showDialog: true });
      this.searchGoogleApps();
    }
  };
  searchGoogleApps = async () => {
    this.setState({ googleApps: [] });
    let response = await getGoogleResults(this.state.appname.value);
    if (response.status === 200) {
      if (
        response.data &&
        response.data.length &&
        typeof response.data === "object"
      ) {
        this.setState({ googleApps: response.data });
      } else {
        this.props.error();
        throw response;
      }
    } else {
      console.log("error while scraping google");
    }
  };
  useGoogleLink = event => {
    event.preventDefault();
    let link = {
      ...this.state.link,
      validated: true
    };
    link.valid = functions.checkValidity(link.value, link.validation);
    this.setState({ link: link });
    if (link.valid) {
      this.getAppDetails();
    }
  };
  handleGoogleAppSelected = app => {
    this.setState({ showDialog: false });
    this.props.select(app, this.state.appname.value);
  };
  handleNoGoogleAppSelected = () => {
    this.setState({ showDialog: false });
  };
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft"
    });
  };
  getAppDetails = async () => {
    this.setState({
      showDialog: false,
      loadingGoogle: true
    });
    let params = { googleDetailsUrl: this.state.link.value };
    let headers = { "Content-Type": "text/plain" };
    let response = await getAppDetails({ params: params, headers: headers });
    this.setState({ loadingGoogle: false });
    if (response.data.error) {
      this.openNotificationWithIcon("warning", "Invalid URL");
    } else if (typeof response.data !== "object") {
      this.props.error();
    } else {
      this.props.select(
        {
          name: _.unescape(response.data.name),
          package: response.data.package
        },
        response.data.name
      );
    }
  };
}
export default GoogleSearcher;
