import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import functions from "../../../../../functions";
export default ({ data }) => {
  return (
    <div>
      <Chart height={400} data={data} padding={60} forceFit>
        <Legend />
        <Legend />
        <Axis name="date" />
        <Axis
          name="event_count"
          label={{
            formatter: val => `${functions.styleAsNumber(val)}`
          }}
        />
        <Tooltip
          crosshairs={{
            type: "y"
          }}
        />
        <Geom
          type="line"
          position="date*event_count"
          size={2}
          color={"event"}
          tooltip={[
            "date*event_count",
            (date, event_count) => {
              event_count = functions.styleAsNumber(event_count);
              return {
                name: date,
                value: event_count
              };
            }
          ]}
        />
        <Geom
          type="point"
          position="date*event_count"
          size={4}
          shape={"circle"}
          color={"event"}
          style={{
            stroke: "#fff",
            lineWidth: 1
          }}
        />
      </Chart>
    </div>
  );
};
