import axios from "../axios";

export const getRevenue = async ({ start, end, app, id }) => {
  let response = null;
  try {
    response = await axios.post(`/welcome-revenue/${id}`, { start, end, app });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const markBulletinAsRead = async (id) => {
  let response = null;
  try {
    response = await axios.put(`/bulletin-read/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getTotalDownloads = async (filters) => {
  let response = null;
  try {
    response = await axios.post("/welcome-downloads/", { ...filters });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getAppStoresDistribution = async (id) => {
  let response = null;
  try {
    response = await axios.get(`/welcome-app-stores/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getAppDistribution = async (id) => {
  let response = null;
  try {
    response = await axios.get(`/welcome-distribution/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getAllBulletins = async (id) => {
  let response = null;
  try {
    response = await axios.get(`/bulletin/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getBulletinContent = async (id) => {
  let response = null;
  try {
    response = await axios.get(`/bulletin-content/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
