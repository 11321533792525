import React from "react";
import { Card, List, Row } from "antd";
import functions from "../../../../functions";
import classes from "./AutoRenewalsStats.module.less";
export default ({ balance = {} }) => {
    const getValue = (value) => {
        return functions.styleAsNumber(
            value || 0
        )
    };
    const data = [
        {
            title: "Auto Renewals Stats:",
            content: <span>{getValue(balance.activeAutoRenewals)}</span>,
        },
        {
            title: "New Auto Renewals:",
            content: <span>{getValue(balance.newAutoRenewals)}</span>,
        },
        {
            title: "Cancelled Renewals:",
            content: <span>{getValue(balance.cancelledRenewals)}</span>,
        },
        {
            title: "Auto Renewals Amount:",
            content: <span>{getValue(balance.autoRenewalsAmount)}</span>,
        },

    ];
    return (
        <Card className={classes.container} size="small" title="Auto Renewals Stats">
            <List
                size="small"
                dataSource={data}
                renderItem={(item) => (
                    <List.Item key={item.title}>
                        <Row type="flex" justify="space-between" style={{ width: "100%" }}>
                            <List.Item.Meta
                                style={{ textAlign: "left" }}
                                title={item.title}
                            />
                            <div>{item.content}</div>
                        </Row>
                    </List.Item>
                )}
            />
        </Card>
    );
};
