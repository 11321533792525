import React, { useState, useEffect } from "react";
import { Card, Statistic, Tooltip, Icon } from "antd";
import { summaryCard } from "../../PromotionFakeData";
import { getSummary } from "../../../../services/promotionService";
import classes from "./SummaryCard.module.less";
const gridStyle = {
  width: "33.33%",
  textAlign: "center",
};

export default ({ app, useDemoData }) => {
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState({});

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getSummary({
      promotionId: app.analitycs_id,
      app: app.id,
    });
    setData(response.result);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData({ ...summaryCard });
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    validateRequest();
  }, [app]);

  return (
    <Card
      className={classes.container}
      loading={loading}
      title="Summary (TODAY)"
    >
      <Card.Grid style={gridStyle}>
        <Statistic
          title="Active Users"
          value={data.active_users ? data.activations : "No Data"}
        />
        <p>Change (Yesterday) {data.active_users_change}</p>
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Statistic
          title={
            <>
              Activations{" "}
              <Tooltip title="Activations means when a user first opens the app after installation">
                <Icon type="question-circle" theme="filled" />
              </Tooltip>
            </>
          }
          value={data.activations ? data.activations : "No Data"}
        />
        <p>Change (Yesterday) {data.activations_change}</p>
      </Card.Grid>

      <Card.Grid style={gridStyle}>
        <Statistic
          title={
            <>
              New Registrations{" "}
              <Tooltip title="The registration amount refers to the first time a user registers for the APP by using mobile phone number/email/WeChat/QQ.">
                <Icon type="question-circle" theme="filled" />
              </Tooltip>
            </>
          }
          value={data.new_registrations ? data.new_registrations : "No Data"}
        />
        <p>Change (Yesterday) {data.new_registrations_change}</p>
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Statistic
          title="Transactions"
          value={data.transactions ? data.transactions : "No Data"}
        />
        <p>Change (Yesterday) {data.transactions_change}</p>
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Statistic
          title="Paying Users"
          value={data.paying_users ? data.paying_users : "No Data"}
        />
        <p>Change (Yesterday) {data.paying_users_change}</p>
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <Statistic
          title="Total Paid (RMB)"
          value={data.total_paid ? data.total_paid : "No Data"}
        />
        <p>Change (Yesterday) {data.total_paid_change}</p>
      </Card.Grid>
    </Card>
  );
};
