import React from "react";
import UsersPerDay from "../UserActivity/UsersPerDay/UsersPerDay";
import UncontractedMessage from "../UncontractedAppPromotion/UncontractedMessage";

export default ({ app, useDemoData }) => {
  return (
    <>
      <UncontractedMessage app={app} useDemoData={useDemoData} />
      <UsersPerDay app={app} useDemoData={useDemoData} />
    </>
  );
};
