import React, { useState, useEffect } from "react";
import classes from "../PaymentsPlatform.module.less";
import { Button, Table, DatePicker, Row, Col } from "antd";
import { getStatementsReports } from "../../../services/paymentsService";
import moment from "moment";
import { statement_reports_fake } from "../FakeData";
const { RangePicker } = DatePicker;

export default ({ app, useDemoData }) => {
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [date_filter, setDateFilter] = useState(true);
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);

  const tableColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      fixed: "left",
      width: 120,
      render: date => (date ? moment(date).format("YYYY-MM-DD") : ""),
      sorter: (a, b) => {
        if (a.date > b.date) return -1;
        else if (a.date < b.date) return 1;
        return 0;
      }
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      sorter: true,
      sorter: (a, b) => {
        if (a.name > b.name) return -1;
        else if (a.name < b.name) return 1;
        return 0;
      }
    },
    {
      title: "",
      dataIndex: "link",
      key: "link",
      align: "center",
      render: link =>
        link ? (
          <a
            rel="noopener noreferrer"
            href={`https://appinchina.space/download/${link}`}
            target="_blank"
          >
            <Button icon="cloud-download">Download File</Button>
          </a>
        ) : null
    }
  ];

  useEffect(() => {
    fetch();
  }, [app.id, useDemoData]);

  const fetch = async () => {
    if (!useDemoData)
      try {
        if (!!app) {
          setLoading(true);
          let { data } = await getStatementsReports(app.id);
          setLoading(false);
          if (data.statusText === "OK") {
            setValues(data.data.statements);
          }
        }
      } catch (error) {
        console.log(error);
      }
  };

  const onChangeDate = (_, [start, end]) => {
    setStartDate(start);
    setEndDate(end);
    setDateFilter(false);
  };

  const resetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setDateFilter(true);
  };

  let payments_filter =
    start_date !== "" && end_date !== ""
      ? values.filter(it => it.date >= start_date && it.date <= end_date)
      : values;

  return (
    <div className={classes.tableContainer}>
      <div />
      <Row gutter={16}>
        <Col xs={17} sm={17} md={17} lg={9} xl={8} span={9}>
          <RangePicker
            style={{ width: "100%" }}
            value={start_date && end_date ? [moment(start_date), moment(end_date)] : null}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
          />
        </Col>
        <Col xs={7} sm={7} md={7} lg={7} xl={5} span={3}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={() => resetFilter()}
            disabled={date_filter}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <div>
        <Table
          dataSource={useDemoData ? statement_reports_fake : payments_filter}
          columns={tableColumns}
          size="small"
          pagination={{ pageSize: 10 }}
          loading={loading}
          scroll={{ x: 900 }}
        />
      </div>
    </div>
  );
};
