import React from "react";
import history from "../../../history";
import DataPerStore from "./DataPerStore/DataPerStore";
import TrendPerStore from "./TrendPerStore/TrendPerStore";
import UncontractedMessage from "../UncontractedAppPromotion/UncontractedMessage";

export default ({ app, useDemoData }) => {
  const goToSingleStoreView = store => {
    history.push({
      to: `/promotion/${app.id}/data-per-store`,
      state: {
        store
      }
    });
  };

  return (
    <>
      <UncontractedMessage app={app} useDemoData={useDemoData} />
      <DataPerStore
        app={app}
        useDemoData={useDemoData}
        goToSingleStoreView={goToSingleStoreView}
      />
      <TrendPerStore app={app} useDemoData={useDemoData} />
    </>
  );
};
