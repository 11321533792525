import React, { useState, useEffect } from "react";
import classes from "./NewFeature.module.css";
import { setAsViewed } from "../../../services/distributionService";
const NewFeature = ({ show, children, style, category = null, id = null }) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    if (show !== undefined) {
      setVisible(show);
    }
  }, [show]);

  const clickHandler = async () => {
    setVisible(false);
    try {
      if (category && id) {
        await setAsViewed({ category, id });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* return visible ? (
    <div onClick={clickHandler}>
      <div className={classes.container}>
        <span className={classes.animated} style={style} />
        {children}
      </div>
    </div>
  ) : (
    children
  );*/
  return children;
};
export default NewFeature;
