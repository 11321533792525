import React from "react";
import { Alert } from "antd";
import classes from "./UncontractedAnalyticsMessage.module.less";

export default ({ app, useDemoData }) => {
  return useDemoData ? (
    <div className={classes.alert}>
      <Alert
        message={
          <p>
            This page demonstrates how the App Promotion platform looks using
            example data.
          </p>
        }
        type="warning"
        showIcon
      />
    </div>
  ) : null;
};
