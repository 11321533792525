import React, { useState, useEffect } from "react";
import MultiLineChart from "../../../UI/Charts/MultiLineChart";
import { Card, Row, Col, DatePicker, Checkbox } from "antd";
import LoadingSpin from "../../../UI/LoadingSpin/LoadingSpin";
import { getDate } from "../../../../functions";
import { chartsFake, storesOptions } from "../../PromotionFakeData";
import { getExpensesPerDay } from "../../../../services/promotionService";
export default ({ app, useDemoData }) => {
  let [split, setSplit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [date, setDate] = useState(getDate());

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getExpensesPerDay({
      app: app.id,
      start: date[0].format("YYYY-MM-DD"),
      end: date[1].format("YYYY-MM-DD")
    });
    setData(response);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData({
          accumulatedAmount: chartsFake(["Total"]),
          amountByStore: chartsFake(storesOptions)
        });
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    validateRequest();
  }, [app]);

  useEffect(() => {
    validateRequest();
  }, [date]);

  return (
    <Card title="Expenses Per Day" style={{ marginTop: 15 }}>
      <Row gutter={16}>
        <Col xs={{ span: 12 }} lg={{ span: 10 }}>
          <DatePicker.RangePicker
            loading={loading}
            value={date}
            onChange={moment => {
              setDate(moment);
            }}
            style={{ width: "100%" }}
          />
        </Col>
        <Col xs={{ span: 12 }} lg={{ span: 4 }}>
          <Checkbox disabled={loading} onClick={() => setSplit(!split)}>
            Split By Store
          </Checkbox>
        </Col>
      </Row>
      {loading ? (
        <LoadingSpin />
      ) : !split ? (
        <MultiLineChart loading={loading} data={data.accumulatedAmount} />
      ) : (
        <MultiLineChart loading={loading} data={data.amountByStore} />
      )}
    </Card>
  );
};
