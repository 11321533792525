import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";

export default ({ version, id, handleSubmit, warning = false }) => {
  return (
    <Result
      status={warning ? "warning" : "success"}
      title={
        warning
          ? "Please finish all the steps before submitting the app information"
          : `Version ${version} Succesfully Submitted`
      }
      subTitle={
        warning ? "" : "Please check status via the Submission History Tab"
      }
      extra={[
        !warning ? (
          <Button>
            <Link to={`/distribution/${id}/app-information/submission_history`}>
              Submission History
            </Link>
          </Button>
        ) : null,
      ]}
    />
  );
};
