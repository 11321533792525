import React from "react";
import Summary from "./Summary/Summary";
import Chart from "./Chart/Chart";
import UncontractedMessage from "../../UncontractedAppPromotion/UncontractedMessage";

export default ({ app, useDemoData }) => {
  return (
    <>
      <UncontractedMessage app={app} useDemoData={useDemoData} />
      <Summary app={app} useDemoData={useDemoData} />
      <Chart app={app} useDemoData={useDemoData} />
    </>
  );
};
