import React from "react";
import { Table, Row, Button, Tag, Divider, Popconfirm } from "antd";
import moment from "moment";
const SubmissionStatusTable = ({
  data,
  app,
  setFormId,
  continueSubmition,
  deleteHandler,
  loading,
  isOwnerOrAdmin,
}) => {
  const getTag = (item) => {
    if (item.is_draft === false && item.is_apk_update) {
      return <Tag color="green">New Version</Tag>;
    } else if (item.is_apk_update === false) {
      return <Tag color="cyan">App Information</Tag>;
    } else if (item.submission_origin === "API") {
      return <Tag color="geekblue">API Submission</Tag>;
    } else if (item.source === "API" && item.is_test === true) {
      return <Tag color="blue">API Test</Tag>;
    } else if (item.is_draft === true) {
      return <Tag color="purple">Draft</Tag>;
    }
  };

  let columns = [
    {
      key: "submission_date",
      dataIndex: "submission_date",
      title: "Date",
      render: (date, item) => (date ? moment(date).format("YYYY-MM-DD") : ""),
      width: 120,
      fixed: "left",
    },
    {
      key: "version",
      dataIndex: "version",
      title: "Version",
    },
    {
      key: "appname_en",
      dataIndex: "appname_en",
      title: "App Name",
    },
    {
      key: "type",
      dataIndex: "type",
      title: "Type",
      render: (_, item) => getTag(item),
    },
    {
      key: "options",
      dataIndex: "options",
      title: "Options",
      render: (_, item) =>
        item.is_draft ? (
          isOwnerOrAdmin ? (
            <>
              <Button onClick={() => continueSubmition(item)} type="link">
                Continue
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                onConfirm={() => deleteHandler(item.Form_id)}
                title="Are you sure you want to delete this form submission? This can't be undone."
              >
                <Button
                  type="danger"
                  ghost
                  icon="delete"
                  shape="circle"
                  danger
                />
              </Popconfirm>
            </>
          ) : (
            <span style={{ paddingLeft: "16px" }}>N/A</span>
          )
        ) : (
          <>
            <Button onClick={() => setFormId({ ...item })} type="link">
              View
            </Button>
          </>
        ),
    },
  ];

  let orderedDate = data
    .filter((it) => it.is_draft)
    .concat(data.filter((it) => !it.is_draft));
  return (
    <div>
      <Row type="flex" justify="space-between">
        <h4>Submission History</h4>
        <h4>
          Remaining yearly updates:{" "}
          <Tag color="blue">{app.main_information.updates_left || 0}</Tag>
        </h4>
      </Row>
      <Table
        loading={loading}
        scroll={{ x: 900 }}
        dataSource={orderedDate}
        columns={columns}
        size="small"
      />
    </div>
  );
};

export default SubmissionStatusTable;
