import React, { Fragment } from "react";
import classes from "./StatisticCard.module.less";
import { Tooltip, Icon, Spin, Button } from "antd";
import functions from "../../../functions";
import { Link } from "react-router-dom";
const StatisticCard = ({
  title,
  total,
  percent,
  revenue,
  description,
  info,
  chart,
  loading,
  link,
  linkDescription
}) => {
  let LoadingSpin = (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    />
  );
  return (
    <div className={classes.Container}>
      {loading ? (
        LoadingSpin
      ) : (
        <Fragment>
          {" "}
          <div className={classes.Header}>
            <p className={classes.Title}>{title}</p>{" "}
            <div>
              {link && linkDescription ? (
                <Tooltip placement="bottomLeft" title={linkDescription}>
                  <Link to={`${link}`}>
                    <Button
                      icon="link"
                      style={{ border: "none", marginRight: 5 }}
                    />
                  </Link>
                </Tooltip>
              ) : null}
              {description ? (
                <Tooltip placement="bottomLeft" title={description}>
                  <Icon type="question-circle" />
                </Tooltip>
              ) : null}
            </div>
          </div>
          <p className={classes.total}>
            {percent
              ? `${total}%`
              : revenue
              ? `¥${functions.styleAsNumber(total)}`
              : total
              ? functions.styleAsNumber(total)
              : null}
          </p>
          <div className={classes.Chart}>{chart ? chart : null}</div>
          <div className={classes.Info}>
            <div className={classes.InfoText}>
              {info && info.length > 0
                ? info.map(it => (
                    <p>
                      {it.title}: <b>{functions.styleAsNumber(it.data)}</b>
                    </p>
                  ))
                : ""}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default StatisticCard;
