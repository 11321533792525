import axios from "../axios";

export const getUsersLinked = async email => {
  let response = null;
  try {
    response = await axios.get("/users-linked", { email });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const removeAccess = async values => {
  let response = null;
  try {
    response = await axios.delete("/roles", { data: { ...values } });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const updateRole = async values => {
  let response = null;
  try {
    response = await axios.put("/roles", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getAppsRoles = async () => {
  let response = null;
  try {
    response = await axios.get(`/roles`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
