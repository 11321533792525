import React, { useState } from "react";
import classes from "./AttachmentCard.module.less";
import { Table, Button, Row, Icon, Modal } from "antd";
import moment from "moment";
import NewAttachmentModal from "./NewAttachmentModal/NewAttachmentModal";
import { deleteReclaimingAttachment } from "../../../../../services/apkReclaimService";
import NewFeature from "../../../../UI/NewFeature/AsyncNewFeature";

const { confirm } = Modal;
export default ({ data, update, clientData, deleteHandler, isOwner }) => {
  let [display, setDisplay] = useState(false);
  let columns = [
    {
      dataIndex: "date",
      key: "date",
      title: "Date",
      render: (date, item) => moment(date).format("YYYY-MM-DD HH:mm")
    },
    { dataIndex: "file_name", key: "file_name", title: "File Name" },
    { dataIndex: "file_size", key: "file_size", title: "File Size (Bytes)" },
    {
      dataIndex: "file",
      key: "file",
      title: "",
      render: (file_url, item) =>
        file_url ? (
          <NewFeature
            show={item.viewed === false}
            category="apk_reclaim_attachments"
            id={item.id}
          >
            <a
              rel="noopener noreferrer"
              href={`https://appinchina.xyz/download/${file_url}`}
              target="_blank"
            >
              <Button icon="cloud-download">Download</Button>
            </a>
          </NewFeature>
        ) : (
          "No file attached"
        )
    }
  ];
  const handleDelete = id => {
    confirm({
      title: "Are you sure you want to delete this attachment?",
      async onOk() {
        await deleteReclaimingAttachment(id);
        deleteHandler(id);
      }
    });
  };

  return (
    <div className={classes.container}>
      <Row
        className={classes.Button}
        type="flex"
        justify="space-between"
        align="bottom"
      >
        <h3>My Attachments</h3>
        {isOwner ? (
          <Button onClick={() => setDisplay(true)}>
            <Icon type="upload" /> Click to Upload
          </Button>
        ) : null}
      </Row>
      <Table
        rowKey={(record) => record.id}
        columns={[
          ...columns,
          {
            dataIndex: "delete",
            key: "delete",
            title: "",
            render: (_, e) => (
              <Button
                disabled={!isOwner}
                onClick={() => handleDelete(e.id)}
                icon="delete"
                shape="circle"
                type="danger"
              />
            )
          }
        ]}
        size="small"
        pagination={clientData.length > 5 ? { pageSize: 5 } : false}
        dataSource={clientData}
      />
      <Row
        className={classes.Button}
        type="flex"
        justify="space-between"
        align="middle"
      >
        <h3>Account Manager Attachments</h3>
      </Row>
      <Table
        rowKey={(record) => record.id}
        size="small"
        columns={columns}
        pagination={data.length > 5 ? { pageSize: 5 } : false}
        dataSource={data}
      />
      <NewAttachmentModal
        updateData={update}
        visible={display}
        close={() => setDisplay(false)}
      />
    </div>
  );
};
