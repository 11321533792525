import React, { Fragment, useState, useEffect } from "react";
import classes from "./PaymentsPlatform.module.less";
import { Spin } from "antd";
import Transactions from "./Transactions/Transactions";
import Users from "./Users/Users";
import RefundsHistory from "./RefundsHistory/RefundsHistory";
import Summary from "./Summary/Summary";
import StatementReports from "./StatementReports/StatementReports";
import AccountDetails from "./AccountDetails/AccountDetails";
import TransferHistory from "./TransferHistory/TransferHistory";
import RequestAccess from "./RequestAccessPayments/RequestAccessPayments";
import Renewals from "./Renewals/Renewals";
export default props => {
  let [shouldRenderTabs, setShouldRenderTabs] = useState(false);
  let [loading, setLoading] = useState(true);
  let { apps, appId } = props;

  useEffect(() => {
    let appDemo =
      appId === "1234-8475-9439-9494" || appId === "1234-8475-9439-9495";
    let app = apps.find(app => app.id === appId) || null;
    if (app || appDemo) {
      setShouldRenderTabs(
        app
          ? app.alipay_id != null ||
          app.wechat_id != null ||
          app.seccond_alipay_id != null ||
          app.seccond_wechat_id != null
          : false
      );
      setLoading(false);
    }
    if (window.mixpanel) {
      window.mixpanel.track("Monetization loaded");
    }
  }, [appId]);

  let loader = () => (
    <div className={classes.largeFlex}>
      <Spin />
    </div>
  );

  let emptyContent = () => {
    let { apps, appId } = props;
    let app = apps.find(app => app.id === appId);

    return !shouldRenderTabs ? (
      <RequestAccess
        title={"MONETIZATION"}
        text={
          "You currently don't have AppInChina Pay integrated in your app. This page demonstrates how our monetization platform looks using example data."
        }
        buttonText={" Request Access Now"}
        app={app}
      />
    ) : null;
  };

  let getContentSection = () => {
    let section = props.section || "integrations";
    let { apps, appId } = props || "";
    let app = apps.find(app => app.id === appId) || {};
    switch (section) {
      case "summary":
        return <Summary app={app} useDemoData={!shouldRenderTabs} {...props} />;
      case "transactions":
        return (
          <Transactions app={app} useDemoData={!shouldRenderTabs} {...props} />
        );
      case "renewals":
        return (
          <Renewals app={app} useDemoData={!shouldRenderTabs} {...props} />
        );
      case "users":
        return <Users app={app} useDemoData={!shouldRenderTabs} {...props} />;
      case "refunds":
        return (
          <RefundsHistory
            app={app}
            useDemoData={!shouldRenderTabs}
            {...props}
          />
        );
      case "statement-reports":
        return (
          <StatementReports
            app={app}
            useDemoData={!shouldRenderTabs}
            {...props}
          />
        );
      case "account-details":
        return (
          <AccountDetails
            {...props}
            app={app}
            useDemoData={!shouldRenderTabs}
          />
        );
      case "transfer-history":
        return (
          <TransferHistory
            {...props}
            app={app}
            useDemoData={!shouldRenderTabs}
          />
        );

      default:
        return <Summary app={app} />;
    }
  };
  let getContent = () => {
    return (
      <Fragment>
        {emptyContent()}
        {getContentSection()}
      </Fragment>
    );
  };

  return <Fragment>{loading ? loader() : getContent()}</Fragment>;
};
