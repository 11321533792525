import React, { useEffect, useState } from "react";
import { Button } from "antd";
import UsersPerDay from "./UsersPerDay/UsersPerDay";
import UsersSource from "./UsersSource/UsersSource";
import UsersRetention from "../UsersRetention/UsersRetention";
import UncontractedMessage from "../UncontractedAppPromotion/UncontractedMessage";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";

export default ({ app, store }) => {
  let [useDemoData, setUseDemoData] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let usingDemoApp =
      app.id === "1234-8475-9439-9494" || app.id === "1234-8475-9439-9495";
    if (usingDemoApp) {
      setUseDemoData(true);
    } else {
      setUseDemoData(!!app.analitycs_id);
    }
    setLoading(false);
  }, []);

  return (
    <div style={{ textAlign: "left" }}>
      {loading ? (
        <LoadingSpin />
      ) : (
        <>
          <UncontractedMessage app={app} useDemoData={!useDemoData} />
          <Button icon="left" onClick={() => window.history.back()}>
            Back
          </Button>
          <UsersPerDay app={app} useDemoData={!useDemoData} store={store} />
          <UsersSource app={app} useDemoData={!useDemoData} store={store} />
          <UsersRetention app={app} useDemoData={!useDemoData} store={store} />
        </>
      )}
    </div>
  );
};
