import React, { useState } from "react";
import classes from "../CloudStore.module.less";
import { Steps, Button } from "antd";
import { Link } from "react-router-dom";
const { Step } = Steps;
const Shop = ({ ...props }) => {
  let [step] = useState(-1);
  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <h3>Cloud Services and Hosting</h3>
        <p>
          Cloud services purchased on AppInChina's dashboard are provided by
          Alibaba Cloud China. Servers will be hosted in Chinese cities for
          faster connection speeds. Services available for purchase include
          dedicated servers, databases and domain names/ICP registration.{" "}
        </p>
        <Steps
          style={{ marginTop: 50 }}
          size="small"
          current={step}
          direction="vertical"
        >
          <Step
            title="Product Selection"
            description="Select and configure the service you want to buy."
          />
          <Step
            title="Payment"
            description="Pay online via credit card or bank transfer."
          />
          <Step
            title="Confirmation"
            description="Pending orders are confirmed by AppInChina."
          />
          <Step
            title="Configuration Details"
            description="Check back on the dashboard for configuration details."
          />
        </Steps>
      </div>
      <div className={classes.center}>
        <Link to={`/cloud/products/${props.appId}`}>
          <Button type="primary">Browse Products</Button>
        </Link>
      </div>
    </div>
  );
};

export default Shop;
