import React, { useEffect, useState } from 'react'
import { Alert, Button, Spin } from 'antd'
import Axios from 'axios';

export default () => {
    const [message, setMessage] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const url = new URL(window.location.href)
        const channel = url.searchParams.get("channel");
        const user = url.searchParams.get("usr");
        if (channel && user) {
            unsuscribe(channel, user)
        } else {
            window.location.replace('/login')
        }
    }, [])

    const unsuscribe = async (channel, user) => {
        try {
            const { data } = await Axios.post('https://appinchina.space/misc/unsuscribe', {
                channel,
                user
            })
            if (!data.error) {
                setLoading(false)                    
                if (data.message == true) {
                    setMessage("success")    
                } else {
                    setMessage("info")
                }                
            }
            else {
                setLoading(false)
                setMessage("error")
            }
        } catch (error) {

        }
    }

    const styles = {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    }

    const text = {
        'success': "You are unsuscribed from this channel.",
        'info': "You are already unsuscribed to this channel.",
        'error': 'An error has ocurred.'
    }

    const header = {
        'success': "Success",
        'info': "Info",
        'error': 'Something wrong happen!'
    }

    return (
        <div style={styles}>
            {
                loading ? <Spin /> :
                    <Alert
                        style={{ width: "40%" }}
                        message={header[message]}
                        description={
                            <span>
                                {text[message]}
                                <Button type="ghost" onClick={() => window.location.replace('/login')}>
                                    Go To Login Page
                                </Button>
                            </span>
                        }
                        type={`${message}`}
                        showIcon
                    />
            }
        </div>
    )
}
