import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import FileInput from "../../UI/File/File";
import LineCard from "../../UI/LineCard/LineCard";
import classes from "../ClientForms.module.less";
import {
  documentHand1,
  documentHand2,
  bankPermit,
  documentHand,
} from "../../../images";
import DemoAppAlert from "../../DemoAppAlert/DemoAppAlert";
import { getForm } from "../../../services/distributionService";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Popover,
  Icon,
  Popconfirm,
  notification,
} from "antd";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";
import axios from "../../../axios";

const { Item } = Form;

const CompanyForm = ({ form, role = null, match }) => {
  const disabled = role === "VIEWER";
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { app_id } = match.params;

  useEffect(() => {
    getCompanyForm();
  }, []);

  const getCompanyForm = async () => {
    try {
      setLoading(true);
      const { data } = await getForm("company-form", app_id);
      setFormData(data);
      setLoading(false);
    } catch (error) {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setSaving(true);
        let result = await axios.post("company-form", {
          appId: app_id,
          form_data: values,
        });
        result && result.data
          ? showNotification("success", "Information Sent!")
          : showNotification("error", "An error happened");

        if (window.mixpanel) {
          window.mixpanel.track("Company form submitted");
        }
        setSaving(false);
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft",
        });
      }
    });
  };
  const handleSubmitDraft = async () => {
    let values = form.getFieldsValue();
    setSaving(true);
    let result = await axios.put("company-form", {
      appId: app_id,
      form_data: values,
    });
    result && result.data
      ? showNotification("success", "Information Saved!")
      : showNotification("error", "An error happened");

    setSaving(false);
  };

  const showNotification = (type, message) => {
    notification[type]({
      message,
      duration: 0,
    });
  };

  const deleteFile = (field) => {
    form.setFieldsValue({ [field]: null });
  };

  const submitFile = ({ name, value }) => {
    console.log("submitting file", name, value);
    form.setFieldsValue({ [name]: value });
  };

  const { getFieldDecorator } = form;

  return (
    <>
      {loading ? (
        <LoadingSpin />
      ) : (
        <>
          <DemoAppAlert appId={app_id} />
          <div className={classes.Form}>
            <LineCard shadow>
              <p>
                The information that you provide in this form will be used to
                create accounts for your company on each of the top 15 Chinese
                Android app stores.
              </p>
            </LineCard>
            <Form
              onSubmit={(e) => e.preventDefault()}
              colon={false}
              layout="vertical"
            >
              <LineCard title="App Store Account Information" shadow>
                <p>
                  This is the email address and password that will be used to
                  log into each of the app stores.
                </p>
                <Row gutter={16} type="flex" justify="space-around">
                  <Col xs={24} lg={8}>
                    <Fragment>
                      <Item
                        label={
                          <Popover
                            placement="bottomLeft"
                            content={
                              <p style={{ textAlign: "justify" }}>
                                Please set up a new email address which will be
                                used to register your account on each of the app
                                stores. <br /> It is preferred that you create
                                an email address using your company domain. We
                                will need access to this <br /> email account
                                for different steps throughout the app store
                                registration process.
                                <br /> Please also be sure to set up automatic
                                forwarding of all emails in this account to
                                <a href="mailto:joanne@appinchina.co">
                                  {" "}
                                  joanne@appinchina.co.
                                </a>
                              </p>
                            }
                          >
                            Registration Email Address{" "}
                            <Icon type="question-circle-o" />
                          </Popover>
                        }
                      >
                        {getFieldDecorator(`account_email`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.account_email,
                        })(<Input disabled={disabled} />)}
                      </Item>
                    </Fragment>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Fragment>
                      <Item
                        label={
                          <Popover
                            placement="bottomLeft"
                            content={
                              <p>
                                Please ensure that your password is between 6
                                and 12 characters, and that it includes both
                                letters and numbers. <br /> We will use the same
                                password to create an account on each of the app
                                stores.
                              </p>
                            }
                          >
                            Registration Email Password{" "}
                            <Icon type="question-circle-o" />
                          </Popover>
                        }
                      >
                        {getFieldDecorator(`account_password`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.account_password,
                        })(<Input disabled={disabled} />)}
                      </Item>
                    </Fragment>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Fragment>
                      <Item
                        label={
                          <Popover
                            placement="bottomLeft"
                            content={
                              <p>
                                Please send us the SIM card of a Chinese phone
                                number by post or courier. <br /> This SIM card
                                will be used to complete your registration and
                                verify your accounts. <br /> After we verify
                                your accounts, we will send the SIM card back to
                                you.
                              </p>
                            }
                          >
                            Mobile Phone Number for Registration{" "}
                            <Icon type="question-circle-o" />
                          </Popover>
                        }
                      >
                        {getFieldDecorator(`account_phone`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.account_phone,
                        })(<Input disabled={disabled} />)}
                      </Item>
                    </Fragment>
                  </Col>
                </Row>
              </LineCard>
              <LineCard title="Chinese Company Information" shadow>
                <p>
                  This must be the information of your Chinese company and must
                  exactly match the information listed on your Chinese Business
                  License (营业执照).
                </p>
                <Row gutter={16} type="flex" justify="space-around">
                  <Col xs={24} lg={12} xl={8}>
                    <Item label="Full Company Name (Chinese)">
                      {getFieldDecorator(`company_name`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: formData.company_name,
                      })(<Input disabled={disabled} />)}
                    </Item>
                  </Col>
                  <Col xs={24} lg={12} xl={8}>
                    <Item label="Company Address (in Chinese)">
                      {getFieldDecorator(`company_address`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: formData.company_address,
                      })(<Input disabled={disabled} />)}
                    </Item>
                  </Col>
                  <Col xs={24} lg={12} xl={8}>
                    <Item label="Chinese Business License (营业执照) Number">
                      {getFieldDecorator(`license_number`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: formData.license_number,
                      })(<Input disabled={disabled} />)}
                    </Item>
                  </Col>
                  <Col xs={24} lg={12} xl={8}>
                    <Item label="Official Chinese Company Website URL (Optional)">
                      {getFieldDecorator(`company_url`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: formData.company_url,
                      })(<Input disabled={disabled} />)}
                    </Item>
                  </Col>
                  <Col xs={24} lg={12} xl={8}>
                    <Item label="Chinese Company Bank Account Number">
                      {getFieldDecorator(`bank_account`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: formData.bank_account,
                      })(<Input disabled={disabled} />)}
                    </Item>
                  </Col>
                  <Col xs={24} lg={12} xl={8}>
                    <Item label="Chinese Company Bank Branch Name (Chinese)">
                      {getFieldDecorator(`company_branch`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: formData.company_branch,
                      })(<Input disabled={disabled} />)}
                    </Item>
                  </Col>
                </Row>
                <Row gutter={16} type="flex" justify="space-around">
                  <Col xs={24} lg={12} xl={8}>
                    <Fragment>
                      <Item>
                        {getFieldDecorator(`business_license`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.business_license,
                        })(
                          <FileInput
                            type="Image"
                            name="business_license"
                            validation={{ fileRequired: true }}
                            format={["png", "jpg"]}
                            title="Chinese Business License (营业执照) (In JPG or PNG format) *"
                            onDelete={() => deleteFile("business_license")}
                            submitFile={submitFile}
                            disabled={disabled}
                          />
                        )}
                      </Item>
                      <p> In JPG or PNG format. </p>
                    </Fragment>
                  </Col>
                  <Col xs={24} lg={12} xl={8}>
                    <Fragment>
                      <Item>
                        {getFieldDecorator(`bank_permit`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.bank_permit,
                        })(
                          <FileInput
                            type="Image"
                            name="bank_permit"
                            validation={{ fileRequired: true }}
                            format={["png", "jpg"]}
                            title="Chinese Bank Account Opening License (开户许可证) (In JPG or PNG format) *"
                            onDelete={() => deleteFile("bank_permit")}
                            submitFile={submitFile}
                            disabled={disabled}
                          />
                        )}
                      </Item>
                      <p>
                        In JPG or PNG format.
                        <Popover
                          placement="bottomLeft"
                          content={
                            <img
                              style={{ width: 300 }}
                              src={bankPermit}
                              title="Document"
                              alt="Document"
                            />
                          }
                        >
                          <b>&nbsp;Example.&nbsp;</b>
                        </Popover>
                      </p>
                    </Fragment>
                  </Col>
                  <Col xs={24} lg={12} xl={8}>
                    <Fragment>
                      <Item>
                        {getFieldDecorator(`passport_regular_front`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.passport_regular_front,
                        })(
                          <FileInput
                            type="Image"
                            name="passport_regular_front"
                            validation={{ fileRequired: true }}
                            format={["png", "jpg"]}
                            title="Photo/Scan of the Passport/ID Card of the Legal Representative of the Chinese Company (Front) *"
                            onDelete={() =>
                              deleteFile("passport_regular_front")
                            }
                            submitFile={submitFile}
                            disabled={disabled}
                          />
                        )}
                      </Item>
                      <p> In JPG or PNG format. </p>
                    </Fragment>
                  </Col>
                  <Col xs={24} lg={12} xl={8}>
                    <Fragment>
                      <Item>
                        {getFieldDecorator(`passport_regular_back`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.passport_regular_back,
                        })(
                          <FileInput
                            type="Image"
                            name="passport_regular_back"
                            validation={{ fileRequired: true }}
                            format={["png", "jpg"]}
                            title="Photo/Scan of the Passport/ID Card of the Legal Representative of the Chinese Company (Back) *"
                            onDelete={() => deleteFile("passport_regular_back")}
                            submitFile={submitFile}
                            disabled={disabled}
                          />
                        )}
                      </Item>
                      <p> In JPG or PNG format. </p>
                    </Fragment>
                  </Col>
                  <Col xs={24} lg={12} xl={8}>
                    <Fragment>
                      <Item>
                        {getFieldDecorator(`passport_hand_front`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.passport_hand_front,
                        })(
                          <FileInput
                            type="Image"
                            name="passport_hand_front"
                            validation={{ fileRequired: true }}
                            format={["png", "jpg"]}
                            title="Photo of the Passport/ID Card of the Legal Representative of the Chinese Company held in their hands (Front) *"
                            onDelete={() => deleteFile("passport_hand_front")}
                            submitFile={submitFile}
                            disabled={disabled}
                          />
                        )}
                      </Item>
                      <p> In JPG or PNG format. </p>
                    </Fragment>
                  </Col>
                  <Col xs={24} lg={12} xl={8}>
                    <Fragment>
                      <Item>
                        {getFieldDecorator(`passport_hand_back`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.passport_hand_back,
                        })(
                          <FileInput
                            type="Image"
                            name="passport_hand_back"
                            validation={{ fileRequired: true }}
                            format={["png", "jpg"]}
                            title="Photo of the Passport/ID Card of the Legal Representative of the Chinese Company held in their hands (Back) *"
                            onDelete={() => deleteFile("passport_hand_back")}
                            submitFile={submitFile}
                            disabled={disabled}
                          />
                        )}
                      </Item>
                      <p> In JPG or PNG format. </p>
                    </Fragment>
                  </Col>
                </Row>
              </LineCard>
              <LineCard title="Contact Information" shadow>
                <p>
                  The app stores require that there be a designated Chinese
                  citizen who is the contact person at your company. Please
                  provide this person’s information below. This contact person
                  can be changed in future if they leave your company.
                </p>
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <Item label="Contact Name (in Chinese) ">
                      {getFieldDecorator(`contact_name`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: formData.contact_name,
                      })(<Input disabled={disabled} />)}
                    </Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Item label="Chinese ID Card Number (身份证号)">
                      {getFieldDecorator(`id_number`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: formData.id_number,
                      })(<Input disabled={disabled} />)}
                    </Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Item label="Job Title of Contact Person">
                      {getFieldDecorator(`title`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: formData.title,
                      })(<Input disabled={disabled} />)}
                    </Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Item label="Mobile Phone Number of Contact Person">
                      {getFieldDecorator(`mobile`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: formData.mobile,
                      })(<Input disabled={disabled} />)}
                    </Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <Fragment>
                      <Item>
                        {getFieldDecorator(`id_regular_front`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.id_regular_front,
                        })(
                          <FileInput
                            type="Image"
                            validation={{ fileRequired: true }}
                            format={["png", "jpg"]}
                            name="id_regular_front"
                            title="Photo/Scan of Chinese ID Card (Front) *"
                            onDelete={() => deleteFile("id_regular_front")}
                            submitFile={submitFile}
                            disabled={disabled}
                          />
                        )}
                      </Item>
                      <p> In JPG or PNG format. </p>
                    </Fragment>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Fragment>
                      <Item>
                        {getFieldDecorator(`id_regular_back`, {
                          rules: [{ required: true, message: "Required" }],
                          initialValue: formData.id_regular_back,
                        })(
                          <FileInput
                            type="Image"
                            name="id_regular_back"
                            validation={{ fileRequired: true }}
                            format={["png", "jpg"]}
                            title="Photo/Scan of Chinese ID Card (Back) "
                            onDelete={() => deleteFile("id_regular_back")}
                            submitFile={submitFile}
                            disabled={disabled}
                          />
                        )}
                      </Item>
                      <p> In JPG or PNG format. </p>
                    </Fragment>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Fragment>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Item>
                            {getFieldDecorator(`id_hand_front`, {
                              rules: [{ required: true, message: "Required" }],
                              initialValue: formData.id_hand_front,
                            })(
                              <FileInput
                                type="Image"
                                name="id_hand_front"
                                validation={{ fileRequired: true }}
                                format={["png", "jpg"]}
                                title="Photo of the ID Card of the Contact Person held in their hands (Front) *"
                                onDelete={() => deleteFile("id_hand_front")}
                                submitFile={submitFile}
                                disabled={disabled}
                                previewWidth={400}
                                previewHeight={300}
                              />
                            )}
                          </Item>
                        </Col>
                        <Col span={12}>
                          <div className={classes.exampleImage}>
                            <FileInput
                              type="Image"
                              title="Example"
                              disabled
                              previewWidth={400}
                              previewHeight={300}
                              previewValue={documentHand2}
                            />
                            <label>Example</label>
                          </div>
                        </Col>
                      </Row>
                      <p>
                        In JPG or PNG format. <br />
                        Please try to match the example as closely as possible,
                        and ensure that the information on the passport/ID Card
                        is clearly visible.
                      </p>
                    </Fragment>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Fragment>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Item>
                            {getFieldDecorator(`id_hand_back`, {
                              rules: [{ required: true, message: "Required" }],
                              initialValue: formData.id_hand_back,
                            })(
                              <FileInput
                                type="Image"
                                name="id_hand_back"
                                validation={{ fileRequired: true }}
                                format={["png", "jpg"]}
                                title="Photo of the ID Card of the Contact Person held in their hands (Back) *"
                                onDelete={() => deleteFile("id_hand_back")}
                                submitFile={submitFile}
                                disabled={disabled}
                                previewWidth={400}
                                previewHeight={300}
                              />
                            )}
                          </Item>
                        </Col>
                        <Col span={12}>
                          <div className={classes.exampleImage}>
                            <FileInput
                              type="Image"
                              title="Example"
                              disabled
                              previewWidth={400}
                              previewHeight={300}
                              previewValue={documentHand1}
                            />
                            <label>Example</label>
                          </div>
                        </Col>
                      </Row>
                      <p>
                        In JPG or PNG format. <br />
                        Please try to match the example as closely as possible,
                        and ensure that the information on the passport/ID Card
                        is clearly visible.
                      </p>
                    </Fragment>
                  </Col>
                </Row>
              </LineCard>
              <LineCard shadow>
                <Item
                  label={
                    <Popover
                      placement="bottomLeft"
                      content={
                        <p>
                          If you have any additional details or questions that
                          you’d like
                          <br />
                          to add then please write them here.
                        </p>
                      }
                    >
                      Additional Notes <Icon type="question-circle-o" />
                    </Popover>
                  }
                >
                  {getFieldDecorator(`special_notes`, {
                    initialValue: formData.special_notes,
                  })(<Input.TextArea disabled={disabled} />)}
                </Item>
              </LineCard>
              <LineCard
                title="Extra License Requirements"
                className={classes.container}
                shadow
              >
                <p>
                  Tencent now requires the photocopy of your business license to
                  have the Chinese sentence
                  "用于腾讯移动开放平台开发者授权资质使用+Date" handwritten on
                  it, this requirement applies to all clients who wish to
                  publish their app on Tencent.
                </p>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Item>
                      {getFieldDecorator(`business_license_chinese`, {
                        rules: [
                          {
                            required: false,
                            message: "Required",
                          },
                        ],
                        initialValue: formData.business_license_chinese,
                      })(
                        <FileInput
                          type="File"
                          name="business_license_chinese"
                          validation={{
                            fileRequired: false,
                            size: 20971520,
                          }}
                          format={["png", "jpg", "jpeg"]}
                          title="File *"
                          disabled={disabled}
                          onDelete={() =>
                            deleteFile("business_license_chinese")
                          }
                          submitFile={submitFile}
                        />
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={classes.requirements}>
                      <b>Notes:</b>
                      <ol>
                        <li>
                          Please HANDWRITE the Chinese sentence{" "}
                          <b>"用于腾讯移动开放平台开发者授权资质使用+Date"</b>{" "}
                          on a copy of business license, stamp, scan, then
                          upload. The Business stamp must cover part of the
                          handwritten sentence
                        </li>
                        <li>
                          The Chinese sentence means{" "}
                          <b>
                            "It is used for Tencent mobile open platform
                            developer's authorization review purpose"
                          </b>
                        </li>
                        <li>
                          If you have difficult to write the Chinese sentence,
                          please contact your account manager for assistant
                        </li>
                      </ol>
                    </div>
                  </Col>
                </Row>
              </LineCard>
              <div className={classes.Buttons}>
                <Popconfirm
                  onConfirm={handleSubmitDraft}
                  disabled={disabled || saving}
                  title="Are you sure you want to save the current information as a draft?"
                >
                  <Button
                    disabled={disabled || saving}
                    icon={saving ? "loading" : null}
                  >
                    Save as Draft
                  </Button>
                </Popconfirm>
                <Popconfirm
                  onConfirm={handleSubmit}
                  disabled={disabled || saving}
                  title="Are you sure you want to submit the current information?"
                >
                  <Button
                    type="primary"
                    disabled={disabled || saving}
                    icon={saving ? "loading" : null}
                  >
                    Submit
                  </Button>
                </Popconfirm>
              </div>
            </Form>
          </div>
        </>
      )}
    </>
  );
};

export default Form.create()(withRouter(CompanyForm));
