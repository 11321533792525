import axios from "../axios";

export const createCredentials = async (appId, values) => {
  let response = null;
  try {
    response = await axios.post(`/payments/credentials/${appId}`, {
      ...values,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const editCredentials = async (appId, values) => {
  let response = null;
  try {
    response = await axios.put(`/payments/credentials/${appId}`, {
      ...values,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
