import React, { useState, useRef, useEffect } from "react";
import classes from "./AppFormIntro.module.less";
import { Carousel, Button, Row, Modal, Popconfirm } from "antd";
import img1 from "../../../../assets/screenshots_app_form/distribute1.png";
import img2 from "../../../../assets/screenshots_app_form/distribute2.png";
import img3 from "../../../../assets/screenshots_app_form/distribute3.png";
import img4 from "../../../../assets/screenshots_app_form/distribute4.png";
import img5 from "../../../../assets/screenshots_app_form/distribute5.png";
import img6 from "../../../../assets/screenshots_app_form/distribute6.png";
import img7 from "../../../../assets/screenshots_app_form/distribute7.png";
import img8 from "../../../../assets/screenshots_app_form/distribute8.png";
import img9 from "../../../../assets/screenshots_app_form/distribute9.png";
import img10 from "../../../../assets/screenshots_app_form/distribute10.png";
import { hideAppIntroduction } from "../../../../services/distributionService";

export default ({ visible, onOk, forceRender }) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(0);
  const slider = useRef();
  const [display, setDisplay] = useState(visible);

  const introElements = [
    {
      img: img1,
      text:
        "Your submission history lives here. You can view all your past submissions including app information, new version submissions, documents and apk reclaims.",
    },
    {
      img: img2,
      text: "You can continue or delete an unfinished submission.",
    },
    {
      img: img3,
      text: "View any past submission by pressing view.",
    },
    {
      img: img4,
      text: "View your app’s distribution under this page.",
    },
    {
      img: img5,
      text:
        "Click on each store’s name to get detailed distribution statuses as well as interact AppInChina’s reps.",
    },
    {
      img: img6,
      text: "To submit a new version of your app, click here.",
    },
    {
      img: img7,
      text:
        "If you need to update your app’s information but do not yet have a new apk, you can click on this page to edit.",
    },
    {
      img: img8,
      text:
        "If your app requires reclaiming you’ll find the stores that requires this under this page.",
    },
    {
      img: img9,
      text:
        "Download the blank apk, have your developer sign it then click the + icon to upload the signed apk.",
    },
    {
      img: img10,
      text:
        "Any documents required throughout distribution (if any) will be displayed in this page. You can download it, sign/ stamp the document and re-upload it.",
    },
  ];

  let handleOk = async () => {
    setLoading(true);
    try {
      let result = await hideAppIntroduction();
      console.log("result", result);
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
      setDisplay(false);
      onOk();
    }
  };

  useEffect(() => {
    !!slider && !!slider.current && slider.current.goTo(0);
    setStep(0);
  }, [visible]);

  return (
    <div className={classes.container}>
      <Modal
        closable={false}
        width="900px"
        title="Introduction to App Distribution"
        visible={forceRender ? visible : display}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setStep(step - 1);
              slider.current.goTo(step - 1);
            }}
            disabled={step <= 0 ? true : false}
          >
            Previous
          </Button>,
          step < introElements.length - 1 ? (
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                setStep(step + 1);
                slider.current.goTo(step + 1);
              }}
            >
              Next
            </Button>
          ) : (
            <Button type="primary" loading={loading} onClick={handleOk} ghost>
              Close
            </Button>
          ),
        ]}
      >
        <div className={classes.content}>
          <Carousel
            dots={false}
            ref={(ref) => {
              slider.current = ref;
            }}
          >
            {introElements.map((it, index) => (
              <div className={classes.carouselBlock} key={index}>
                <img src={it.img} alt="" />
                <p>{it.text}</p>
              </div>
            ))}
          </Carousel>
        </div>
      </Modal>
    </div>
  );
};
