import React, { Component } from 'react';
import classes from './Button.module.css';
import Preloader from '../Preloader/Preloader';

class Button extends Component {

	state = {}

	render() {
		let type = this.props.type ? classes[this.props.type] : null
		let loading = this.props.loading ? classes.Loading : null
		let content = this.props.loading ? <Preloader size={3} inverse /> : this.props.children
		return (
			<button
				onClick={this.click}
				className={[classes.Button, loading, type].join(' ')}
				style={{ width: this.props.width, margin: this.props.margin, background: this.props.background }}>
				{content}
			</button>
		)
	}
	click = (event) => {
		if (!this.props.loading) {
			if (this.props.click) this.props.click(event)
		}
		else {
			event.preventDefault()
		}
	}
}

export default Button;