import React, { useState, useEffect } from "react";
import { Modal, Popover, Icon, Input, Form, notification, Alert } from "antd";
import classes from "./SubscriptionModal.module.less";
import {
  addSubscriptionServer,
  editSubscriptionsServer,
} from "../../../../services/paymentsSubscriptionsService";

const SubscriptionModal = ({
  form,
  visible,
  serviceName,
  serviceNo,
  serviceDays,
  id,
  subscriptionId,
  onAdd,
  onEdit,
  onClose,
  accountId,
  appId,
}) => {
  let [loading, setLoading] = useState(false);

  const { getFieldDecorator } = form;

  useEffect(() => {
    form.setFields({
      serviceName: {
        value: serviceName,
      },
      serviceNo: {
        value: serviceNo,
      },
      serviceDays: {
        value: serviceDays,
      },
    });
  }, [visible]);

  let handleResponse = (data, subscriptionId, callback) => {
    if (!data.error) {
      notification.success({
        message: "Data Saved.",
        placement: "bottomLeft",
      });
      callback(data, subscriptionId);
    } else {
      notification.error({
        message: "An error happened",
        placement: "bottomLeft",
      });
    }
  };

  let saveData = (event) => {
    event.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        if (!subscriptionId || subscriptionId === "undefined") {
          let { data } = await addSubscriptionServer(id, values, appId);
          handleResponse(data, subscriptionId, onAdd);
        } else {
          let newValues = {
            ...values,
            accountId,
            id: subscriptionId,
          };
          let { data } = await editSubscriptionsServer(
            subscriptionId,
            newValues,
            appId
          );
          handleResponse(newValues, subscriptionId, onEdit);
        }
        setLoading(false);
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft",
        });
      }
    });
  };

  return (
    <div className={classes.container}>
      <Modal
        visible={visible}
        confirmLoading={loading}
        onOk={saveData}
        onCancel={onClose}
      >
        <Form.Item
          label={
            <Popover
              placement="bottomLeft"
              content={
                <p style={{ maxWidth: 400, textAlign: "left", margin: 0 }}>
                  A descriptor name for your serviceNo.
                </p>
              }
            >
              serviceName <Icon type="question-circle-o" />
            </Popover>
          }
        >
          {getFieldDecorator("serviceName", {
            rules: [
              {
                required: true,
                message: "Required",
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item
          label={
            <Popover
              placement="bottomLeft"
              content={
                <p style={{ maxWidth: 400, textAlign: "left", margin: 0 }}>
                  The serviceNo you create here will be the serviceNo you input
                  into the API. Each serviceNo also specifications you can
                  modify (serviceTypeNo, serviceTypeName, periodType,
                  periodValue), a serviceNo will can have multiple
                  serviceTypeNos. Think of the serviceNo as a model number for a
                  product. (i.e. iPhone 12, iPhone 12 pro, iPhone 12 mini etc),
                  each serviceNo (model) can have multiple types of
                  specifications, i.e. 128gb, 256gb etc. These different
                  specifications are the different serviceTypeNos. When you
                  create a serviceNo, the server will automatically create
                  several types of commonly used specifications for you. You can
                  modify or add new ones as needed. The information you use for
                  freeTrialServiceNo is the same as serviceNo.
                </p>
              }
            >
              serviceNo <Icon type="question-circle-o" />
            </Popover>
          }
        >
          {getFieldDecorator("serviceNo", {
            rules: [
              {
                required: true,
                message: "Required. 64 characters maximum.",
                max: 64
              },
            ],
          })(
            <Input
              disabled={subscriptionId && subscriptionId !== "undefined"}
            />
          )}
        </Form.Item>
        <Alert
          showIcon
          type="warning"
          message="Once this information is added it CANNOT be deleted. Please make sure all information is correct"
        />
      </Modal>
    </div>
  );
};

export default Form.create()(SubscriptionModal);
