import React, { useState, Fragment, useEffect } from "react";
import { Button, Form, Input, Modal, notification } from "antd";
import Axios from "../../../../axios";

const RefundDialog = ({
  info,
  form,
  show,
  setVisible,
  updateAfterRefund,
  useDemoData,
  app,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [amount, setAmount] = useState("");
  const [transaction, setTransaction] = useState(null);
  const [note, setNote] = useState("");
  useEffect(() => {
    setTransaction({
      transaction_id: info.transaction_id,
      biz_no: info.biz_no,
    });
    setAmount(info.amount);
    setNote("");
  }, [show]);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setShowConfirm(true);
        setAmount(values.amount);
        setNote(values.note);
      }
    });
  };

  const newRefund = async () => {
    try {
      setLoading(true);
      setDisabled(true);
      const { data } = await Axios.post(`/create-refunds/${app.id}`, {
        note,
        amount: amount * 100,
        ...transaction,
      });
      setLoading(false);
      setDisabled(false);
      setVisible(false);
      setShowConfirm(false);
      if (data.error) {
        openNotificationWithIcon("error", data.error);
      } else {
        updateAfterRefund(transaction.transaction_id);
        openNotificationWithIcon("success", data.message);
      }
      setAmount("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setAmount("");
    setTransaction(null);
    form.resetFields();
    setVisible(false);
    setShowConfirm(false);
  };

  const { getFieldDecorator } = form;

  return (
    <div>
      <Modal
        title="Are you sure you would like to perform this refund?"
        visible={show}
        onOk={newRefund}
        onCancel={handleCancel}
        footer={
          showConfirm
            ? [
                <Button
                  type="primary"
                  loading={loading}
                  disabled={disabled}
                  onClick={newRefund}
                >
                  Submit
                </Button>,
                <Button key="cancel" onClick={handleCancel}>
                  Cancel
                </Button>,
              ]
            : []
        }
      >
        {showConfirm ? (
          <Fragment>
            <p>Amount: {amount}</p>
            <p>Transaction ID: {transaction.transaction_id}</p>
            <p>Note: {note}</p>
          </Fragment>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Item label="Refund Amount:">
              {getFieldDecorator("amount", {
                rules: [
                  {
                    required: true,
                    message: "Please input the amount",
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (value <= 0) {
                        callback("Invalid amount.");
                      }
                      if (value > amount / 100) {
                        callback(
                          "Refund amount cannot be higher than amount paid."
                        );
                      }
                      callback();
                      return;
                    },
                  },
                ],
                initialValue: amount / 100,
              })(<Input disabled style={{ width: "100%" }} />)}
            </Form.Item>
            <Form.Item label="Note:">
              {getFieldDecorator("note", {
                rules: [
                  {
                    required: true,
                    message: "Please input a note",
                  },
                  {
                    max: 100,
                    message: "Note cannot be more than 100 characters",
                  },
                ],
              })(<Input.TextArea placeholder="Max 100 characters" />)}
            </Form.Item>
            <Form.Item>
              {/* disabled={enableRefund} */}
              <Button
                disabled={useDemoData}
                htmlType="submit"
                className="login-form-button"
              >
                Refund
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default Form.create()(RefundDialog);
