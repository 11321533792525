import React from "react";
import classes from "./LineCard.module.less";

const lineCard = ({
  fullWidthTable,
  style,
  title,
  description,
  children,
  shadow,
  className,
  disabled,
}) => {
  let styles = className ? className : "";
  return (
    <div
      className={[
        styles,
        classes.Card,
        fullWidthTable ? classes.FullWidthTable : "",
        shadow ? classes.Shadow : "",
        disabled ? classes.Disabled : "",
      ].join(" ")}
      style={style}
    >
      <h4>{title}</h4>
      {description ? (
        <p className={classes.description}>{description}</p>
      ) : null}
      {children}
    </div>
  );
};

export default lineCard;
