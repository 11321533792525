import React, { Component } from "react";
import functions from "../../../functions";
import classes from "./SetupApp.module.css";
import { Button, Icon } from "antd";
import Input from "../../UI/Input/Input";
import GoogleSearcher from "../../GoogleSearcher/GoogleSearcher";
import EventBus from "eventbusjs";
import Preloader from "../../UI/Preloader/Preloader";
import { newApp } from "../../../services/appsService";
import { notification } from "antd";
import { getGoogleDetails } from ".././../../services/aiconService";
class SetupApp extends Component {
  state = {
    inGooglePlay: null,
    appname: {
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      validated: false,
    },
    aiconError: false,
    loading: false,
  };

  render() {
    let notSelected =
      this.state.inGooglePlay === null ? classes.Displayed : null;
    let inGooglePlay =
      this.state.inGooglePlay === true ? classes.Displayed : null;
    let notInGooglePlay =
      this.state.inGooglePlay === false ? classes.Displayed : null;

    return (
      <div className={classes.Container}>
        <div className={classes.Content}>
          {this.state.loading ? (
            <div>
              <Preloader size={3} />
              <p className={classes.LoadingMessage}>Saving...</p>
            </div>
          ) : (
            <div>
              <div className={[classes.Step, notSelected].join(" ")}>
                <p className={classes.Text}>Is your app on Google Play?</p>
                <div className={classes.ButtonsContainer}>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => this.handleGoogleSelection(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    size="large"
                    onClick={() => this.handleGoogleSelection(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
              <div className={[classes.Step, inGooglePlay].join(" ")}>
                <GoogleSearcher
                  select={this.handleAppSelected}
                  cancel={this.cancel}
                  error={this.handleAiconError}
                />
              </div>
              <div className={[classes.Step, notInGooglePlay].join(" ")}>
                <p>Type the name of your app</p>
                <form
                  className={classes.AppnameContainer}
                  onSubmit={this.submitForm}
                >
                  <Input
                    {...this.state.appname}
                    placeholder="App Name"
                    onChange={this.handleAppnameChange}
                    errorMessage="Field required"
                  />
                  <Button onClick={this.submitForm} type="primary">
                    Continue
                  </Button>
                </form>
                {!this.state.aiconError ? (
                  <Button onClick={this.cancel}>
                    <Icon type="left" />
                    Go Back
                  </Button>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  cancel = () => {
    this.setState({
      inGooglePlay: null,
    });
  };
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };
  handleAiconError = () => {
    this.openNotificationWithIcon(
      "warning",
      "Error searching for your app in Google, please try again."
    );
    this.setState({
      inGooglePlay: false,
      aiconError: true,
    });
  };
  handleAppnameChange = (event) => {
    let appname = {
      ...this.state.appname,
      validated: false,
      value: event.target.value,
    };
    this.setState({ appname: appname });
  };
  submitForm = (event) => {
    event.preventDefault();
    let appname = {
      ...this.state.appname,
      validated: true,
    };
    appname.valid = functions.checkValidity(appname.value, appname.validation);
    if (appname.valid) {
      this.handleAppSelected({ name: appname.value });
    }
  };
  handleAppSelected = async (app) => {
    this.setState({ loading: true });
    if (app.package) {
      let packageValue = app.package.replace("&hl=es", "");
      let response = await getGoogleDetails(packageValue);
      if (response.status === 200) {
        if (response.data && typeof response.data === "object") {
          let newAppResponse = await newApp({
            image_data: app.icon,
            name: app.title || app.name,
            authorized: false,
            main_information: {
              company_name: app.developer,
              package_name: packageValue,
              industry: functions.splitAndCapitalize(response.data.category[0]),
            },
          });
          this.setState({ loading: false });
          if (newAppResponse.status === 200) {
            if (
              newAppResponse.data.app &&
              typeof newAppResponse.data.app === "object"
            ) {
              this.handleAppSubmited(newAppResponse.data.app);
            } else throw newAppResponse;
          }
        }
      } else {
        this.openNotificationWithIcon(
          "warning",
          "Connection error while scraping google details"
        );
      }
    } else {
      let newAppResponse = await newApp({
        image_data: app.icon,
        name: app.name,
        authorized: false,
        main_information: {
          company_name: app.developer,
          package_name: app.package,
        },
      });
      this.setState({ loading: false });
      if (newAppResponse.status === 200) {
        if (
          newAppResponse.data.app &&
          typeof newAppResponse.data.app === "object"
        ) {
          this.handleAppSubmited(newAppResponse.data.app);
        } else throw newAppResponse;
      } else {
        this.openNotificationWithIcon("warning", "Connection error");
      }
    }
  };
  handleAppSubmited = (app) => {
    this.props.apps.unshift({
      ...app,
      selected: true,
    });
    EventBus.dispatch("UPDATE_APPS", this, this.props.apps);
    if (window.mixpanel) {
      window.mixpanel.track("New app added");
    }
  };
  handleGoogleSelection = (value) => {
    this.setState({ inGooglePlay: value });
  };
}
export default SetupApp;
