const dataBadgeFake = {
  table: [
    { date: "2020-03-01", event: "Pv", event_count: 1570 },
    { date: "2020-03-01", event: "Uv", event_count: "1034" },
    { date: "2020-03-02", event: "Pv", event_count: 1790 },
    { date: "2020-03-02", event: "Uv", event_count: "1149" },
  ],
  summary: {
    page_views: 3269,
    visitors: 2395,
    avg_session_duration: 267329.2747,
  },
  traficByReferrer: [
    { referrer: "Not Set", sessions: "1047" },
    {
      referrer: "https://www.google.com",
      sessions: "2345",
    },
  ],
  visitorsByCountry: [
    { countryName: "China", visitors: 100000, page_views: 5400 },
    { countryName: "United States", visitors: 50000, page_views: 5070 },
    { countryName: "Mexico", visitors: 40400, page_views: 5000 },
    { countryName: "Canada", visitors: 40000, page_views: 4500 },
    { countryName: "Brazil", visitors: 35000, page_views: 4000 },
  ],
  visitorsByRegion: [
    { name: "北京", value: 10, key: "10101", regionName: "Beijing" },
    { name: "广东", value: 6, key: "10105", regionName: "Guangdong" },
    { name: "安徽", value: 8, key: "10102", regionName: "Anhui" },
    { name: "浙江", value: 30, key: "10130", regionName: "Zheijiang" },
    { name: "湖北", value: 32, key: "10112", regionName: "Hubei" },
    { name: "上海", value: 11, key: "10124", regionName: "Shanghai" },
    { name: "福建", value: 19, key: "10103", regionName: "Fujian" },
    { name: "重庆", value: 18, key: "10131", regionName: "Chongqing" },
    { name: "江苏", value: 6, key: "10115", regionName: "Jiangsu" },
    { name: "陕西", value: 22, key: "10123", regionName: "Shaanxi" },
    { name: "山东", value: 10, key: "10121", regionName: "Shandong" },
    { name: "河北", value: 6, key: "10109", regionName: "Hebei" },
    { name: "江西", value: 8, key: "10116", regionName: "Jiangxi" },
    { name: "湖南", value: 10, key: "10113", regionName: "Hunan" },
    { name: "云南", value: 20, key: "10129", regionName: "Yunnan" },
    { name: "四川", value: 20, key: "10125", regionName: "Sichuan" },
    { name: "甘肃", value: 33, key: "10104", regionName: "Gansu" },
    { name: "吉林", value: 15, key: "10114", regionName: "Jilin" },
    { name: "贵州", value: 7, key: "10107", regionName: "Guizhou" },
    { name: "广西", value: 5, key: "10106", regionName: "Guangxi" },
    { name: "河南", value: 6, key: "10110", regionName: "Henan" },
    { name: "辽宁", value: 9, key: "10117", regionName: "Liaoning" },
    { name: "内蒙", value: 5, key: "10118", regionName: "Inner Mongolia" },
    { name: "新疆", value: 10, key: "10128", regionName: "Xinjiang" },
    { name: "黑龙江", value: 6, key: "10111", regionName: "Heilongjiang" },
    { name: "天津", value: 4, key: "10126", regionName: "Tianjin" },
    { name: "山西", value: 9, key: "10122", regionName: "Shanxi" },
    { name: "海南", value: 7, key: "10108", regionName: "Hainan" },
    { name: "宁夏", value: 10, key: "10119", regionName: "Ningxia" },
    { name: "青海", value: 20, key: "10120", regionName: "Qinghai" },
    { name: "西藏", value: 10, key: "10127", regionName: "Tibet" },
  ],
  clicks: [
    { store: "Tencent My App", clicks: 30000 },
    { store: "Huawei App Market", clicks: 29600 },
    { store: "Oppo Software Store", clicks: 28300 },
    { store: "360 Mobile Assistant", clicks: 28000 },
    { store: "VIVO App Store", clicks: 27300 },
    { store: "MIUI App Store", clicks: 23050 },
    { store: "Baidu Mobile Assistant", clicks: 23050 },
    { store: "Anzhi Market", clicks: 22050 },
    { store: "PP Assistant", clicks: 22000 },
    { store: "China Mobile MM Store", clicks: 20300 },
  ],
  name: "",
  todayTraffic: 1000,
};

export { dataBadgeFake };
