import React from "react";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import DataSet from "@antv/data-set";
import { operating_system } from "../../fakeData";
export default class extends React.Component {
  render() {
    let data = this.props.useDemoData ? operating_system : this.props.data;

    const scale = {
      os_count: {
        min: 0
      }
    };
    const ds = new DataSet();
    const dv = ds.createView().source(data);
    dv.source(data).transform({
      type: "sort",
      callback(a, b) {
        return a.os_count - b.os_count;
      }
    });
    return (
      <div>
        <Chart
          height={500}
          data={data}
          padding={[60, 20, 40, 60]}
          scale={scale}
          forceFit
        >
          <Axis
            name="operating_system"
            label={{ formatter: val => `Android ${val}` }}
            labels={null}
            title={null}
            line={null}
            tickLine={null}
          />
          <Tooltip
            crosshairs={{
              type: "y"
            }}
          />
          <Geom
            type="interval"
            position="operating_system*os_count"
            color={"operating_system"}
            opacity={0.4}
            tooltip={[
              "operating_system*os_count",
              (name, vote) => {
                return {
                  title: `Android ${name}`,
                  name: `Android ${name} : ${vote}`
                };
              }
            ]}
          />
        </Chart>
      </div>
    );
  }
}
