import React, { useState, useEffect } from "react";
import LineCard from "../../UI/LineCard/LineCard";
import { Radio, Checkbox } from "antd";
import Chart from "./Chart";
const { Group, Button } = Radio;

export default ({ data }) => {
  const [chartData, setChartData] = useState([]);
  const [view, setView] = useState("PV");
  const [contrast, setContrast] = useState([
    "todayTraffic",
    "yesterdayTraffic"
  ]);

  useEffect(() => {
    if (data) {
      const filteredData = ["todayTraffic", "yesterdayTraffic"]
        .reduce((prev, key) => [...prev, ...data[key]], [])
        .filter(({ event }) => event.includes(view));
      setChartData(filteredData);
    }
  }, [data]);

  const handleChartView = (view, contrast) => {
    const filteredData = contrast
      .reduce((prev, key) => [...prev, ...data[key]], [])
      .filter(({ event }) => event.includes(view));
    setChartData(filteredData);
  };

  return data ? (
    <LineCard title="Trend" loading={!!!data} style={{ marginBottom: "24px" }}>
      <Group
        value={view}
        onChange={event => {
          setView(event.target.value);
          handleChartView(event.target.value, contrast);
        }}
      >
        <Button value="PV">Pageviews (PV)</Button>
        <Button value="UV">Visitors (UV)</Button>
      </Group>
      <strong style={{ margin: 15 }}>Contrast: </strong>
      <Checkbox.Group
        value={contrast}
        onChange={checkedValues => {
          setContrast(checkedValues);
          handleChartView(view, checkedValues);
        }}
      >
        <Checkbox value="todayTraffic">Today</Checkbox>
        <Checkbox value="lastweekTraffic">7 Days Ago</Checkbox>
        <Checkbox value="yesterdayTraffic">Yesterday</Checkbox>
      </Checkbox.Group>
      <Chart data={chartData} />
    </LineCard>
  ) : null;
};
