import { appInChinaPay } from "../axios";
import axios from '../axios';

export const getAppAnalytics = async ({ start, end, app, id }) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint}/${process.env.REACT_APP_PAYMENTS_API}/analytics?app=${id}&start=${start}&end=${end}`
    );
    return data;
  } catch (error) {
    return "error";
  }
};

export const getRefunds = async ({
  results,
  page,
  rangeDate = {},
  sortField,
  sortOrder,
  user,
  transaction,
  id,
  customer_name,
  app,
}) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint
      }/${process.env.REACT_APP_PAYMENTS_API}/refunds?page=${page}&results=${results}&start=${rangeDate.start ||
      undefined}&end=${rangeDate.end ||
      undefined}&sortField=${sortField}&sortOrder=${sortOrder}&user=${user}&transaction=${transaction}&app=${id}&customer_name=${customer_name}`
    );
    return data;
  } catch (error) { }
};

export const getTransactions = async ({
  results,
  page,
  rangeDate = {},
  filters,
  sortField,
  sortOrder,
  app,
  id,
  platform,
  detailsOptions
}) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint
      }/${process.env.REACT_APP_PAYMENTS_API}/transactions?page=${page}&results=${results}&start=${rangeDate.start ||
      undefined}&end=${rangeDate.end ||
      undefined}&sortField=${sortField}&filters=${filters ||
      "21,31,41,11"}&sortOrder=${sortOrder}&app=${id}&platform=${platform}&detailsOptions=${detailsOptions}`
    );
    return data;
  } catch (error) {
    return null;
  }
};
export const getCsvTransactions = async ({
  dateRange = {},
  status,
  app,
  id,
  platform,
  detailsOptions,
}) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint}/${process.env.REACT_APP_PAYMENTS_API}/csv/transactions?start=${dateRange.start || undefined}&end=${dateRange.end || undefined}&status=${status || "21,31,41,11"}&app=${id}&platform=${platform}&detailsOptions=${detailsOptions}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getTransactionById = async (transactionId, id, app) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint}/${process.env.REACT_APP_PAYMENTS_API}/transactions/${transactionId}/${id}`
    );
    return data;
  } catch (error) { }
};

export const getAllUsers = async ({
  results,
  page,
  rangeDate = {},
  id,
  app,
}) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint
      }/${process.env.REACT_APP_PAYMENTS_API}/users?page=${page}&results=${results}&start=${rangeDate.start ||
      undefined}&end=${rangeDate.end ||
      undefined}&sortField=gmt_create&app=${id}`
    );
    return data;
  } catch (error) { }
};

export const getAllUnregistered = async ({
  results,
  page,
  rangeDate = {},
  id,
  app,
  sortOrder
}) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint
      }/${process.env.REACT_APP_PAYMENTS_API}/unregistered?page=${page}&results=${results}&start=${rangeDate.start ||
      undefined}&end=${rangeDate.end ||
      undefined}&sortField=gmt_create&app=${id}&sortOrder=${sortOrder || 'descend'}`
    );
    return data;
  } catch (error) { }
};

export const getUser = async (userId, id, app) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint}/${process.env.REACT_APP_PAYMENTS_API}/users/${userId}/${id}`
    );
    return data;
  } catch (error) { }
};

export const getUserDetails = async (mobile, id, app) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint}/${process.env.REACT_APP_PAYMENTS_API}/user-details/${mobile}/${id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const getUnregisteredUserDetails = async (customer_name, id, app) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint}/${process.env.REACT_APP_PAYMENTS_API}/user-details-unregistered/${customer_name}/${id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getBizNo = async (id, paymentId, app) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint}/${process.env.REACT_APP_PAYMENTS_API}/biz_no/${id}/${paymentId}`
    );
    return data;
  } catch (error) { }
};

export const getTransactionsByCustomerName = async (username, paymentId, app) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint}/${process.env.REACT_APP_PAYMENTS_API}/transactions-username/${username}/${paymentId}`
    );
    return data;
  } catch (error) { }
};

export const getUsersByCustomerName = async (username, paymentId, app) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint}/${process.env.REACT_APP_PAYMENTS_API}/users-username/${username}/${paymentId}`
    );
    return data;
  } catch (error) { }
};

export const getRenewalDetails = async (renewalId, paymentsId, app) => {
  try {
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint}/${process.env.REACT_APP_PAYMENTS_API}/renewal-details/${renewalId}/${paymentsId}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getRenewals = async ({
  results,
  page,
  rangeDate = {},
  filters,
  sortField,
  sortOrder,
  app,
  id,
  ...params
}) => {
  try {
    const filterSearch = Object.entries(params)[0] || [];
    let searchBy = filterSearch.length && filterSearch.length > 0 ? `${filterSearch[0]}=${filterSearch[1]}` : null;
    const { data } = await appInChinaPay.get(
      `${app.server.data_endpoint
      }/${process.env.REACT_APP_PAYMENTS_API}/renewals?page=${page}&results=${results}&start=${rangeDate.start ||
      undefined}&end=${rangeDate.end ||
      undefined}&sortField=${sortField}&filters=${filters ||
      "error,active,cancelled"}&sortOrder=${sortOrder}&app=${id}&${searchBy ? searchBy : ""}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const cancelRenewal = async ({ customer_identity, service_no, app_id, app, comments }) => {
  try {
    const { data } = await axios.post("/renewals", {
      id: app.id,
      app_id,
      customer_identity,
      service_no,
      comments
    })
    return data.result || {};
  } catch (error) {
    return error;
  }
};
