import React, { useState } from "react";
import { List, Card, Alert, Button } from "antd";
import NewAppHistory from "../NewAppHIstory/NewAppHistory";
import classes from "./DistributionDetailsCard.module.less";
import HistoryTable from "../HistoryTable/HistoryTable";

const AppDistribution = ({
  data = [],
  corporate,
  updateHistory,
  disabled,
  updateStore,
}) => {
  const [visible, setVisible] = useState(false);
  const [infoId, setInfoId] = useState(null);

  const update = (values) => {
    updateHistory({
      ...values,
      store: infoId,
    });
    const user = localStorage.getItem("email");
    updateStore([
      {
        ...data[0],
        distribution_histories: [
          ...data[0].distribution_histories,
          { ...values, user },
        ],
      },
    ]);
    setInfoId(null);
  };

  return (
    <div className={classes.container}>
      <NewAppHistory
        infoId={infoId}
        visible={visible}
        setVisible={setVisible}
        updateHistory={update}
      />
      {data.length > 0 ? (
        <List
          itemLayout="vertical"
          size="small"
          dataSource={data}
          pagination={false}
          renderItem={(it) => (
            <Card
              className={classes.card}
              title={it.store.name}
              extra={
                <Button
                  icon="plus"
                  type="primary"
                  ghost
                  disabled={disabled}
                  onClick={() => {
                    setInfoId(it.id);
                    setVisible(true);
                  }}
                >
                  Add Comments
                </Button>
              }
            >
              <HistoryTable corporate={corporate} data={[{ ...it }]} />
            </Card>
          )}
        />
      ) : (
        <Alert
          type="info"
          message="The distribution of your app will begin soon. Come back later to see the status of your app."
          showIcon
        />
      )}
    </div>
  );
};

export default AppDistribution;
