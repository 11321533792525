import React, { useState, useEffect, Fragment } from "react";
import classes from "./ApkInspector.module.less";
import File from "../../../UI/File/File";
import moment from "moment";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Icon,
  notification,
  Select,
  Tag,
  Col,
  Row,
  Skeleton,
  Typography,
  Steps,
} from "antd";
import CustomInput from "../../../UI/Input/Input";
import { APP_TESTING_CATEGORIES } from "../../../../constants";
import {
  getApkFiles,
  newApkFile,
  newSdk,
  getMightySignalInfo,
} from "../../../../services/localizationService";
import LineCard from "../../../UI/LineCard/LineCard";
const AppInfoParser = require("app-info-parser");
const Option = Select.Option;
const { Paragraph } = Typography;
const { Step } = Steps;
const ApkInspector = ({ app, form, ...props }) => {
  const { getFieldDecorator } = form;
  const [sdkList, setSdkList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [apk_files, setData] = useState([]);
  const [apk_values, setApkValues] = useState({});
  const [apktitle, setApkTitle] = useState("APK *");
  const [currentSDK, setCurrentSDK] = useState([]);
  const [currentApk, setCurrentApk] = useState(null);
  const [sdkModalVisible, setSDKModalVisible] = useState(false);
  const [inputCategory, setInputCategory] = useState(false);
  const [packageName, setPackageName] = useState("");
  const [appIcon, setAppIcon] = useState(null);
  const [step, setStep] = useState(null);
  const [skdsResponse, setSdksResponse] = useState([]);

  const getData = async (id) => {
    let { data } = await getApkFiles(id);
    if (!data.error) {
      setData(data);
      if (data.length > 0) {
        setPackageName(data[0].pkg_name);
      }
      setLoadingInitial(false);
    } else {
      throw new Error(data.error);
    }
  };
  useEffect(() => {
    setLoadingInitial(true);
    let { appId } = props;
    getData(appId);
  }, [app.id]);
  useEffect(() => {
    setLoadingInitial(true);
    let { appId } = props;
    getData(appId);
  }, []);
  const showSDKContent = (element) => {
    setCurrentSDK(element);
    setSDKModalVisible(true);
  };
  const translationSDK = () => (
    <Modal
      width={600}
      title="SDKs"
      visible={sdkModalVisible}
      onOk={() => setSDKModalVisible(false)}
      onCancel={() => setSDKModalVisible(false)}
    >
      {
        <Table
          dataSource={currentSDK}
          columns={columns_sdk_list}
          size="small"
          scroll={{ x: 600 }}
          pagination={
            currentSDK && currentSDK.length > 11 ? { pageSize: 10 } : false
          }
        />
      }
    </Modal>
  );
  const columns = [
    {
      title: "Upload Date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (a.createdAt > b.createdAt) return -1;
        else if (a.createdAt < b.createdAt) return 1;
        return 0;
      },
      render: (createdAt) => moment(createdAt).format("YYYY-MM-DD"),
    },
    {
      title: "Package Name",
      dataIndex: "pkg_name",
      key: "pkg_name",
    },
    {
      title: "Version Number",
      dataIndex: "version",
      key: "version",
      align: "center",
    },
    // {
    //   title: "Version Code",
    //   dataIndex: "version_code",
    //   key: "version_code",
    //   align: "center"
    // },
    {
      title: "",
      dataIndex: "sdks",
      key: "sdks",
      align: "center",
      render: (sdks) =>
        sdks.length > 0 ? (
          <Button icon="eye" onClick={() => showSDKContent(sdks)}>
            View SDK List
          </Button>
        ) : null,
    },
  ];
  const parseCategory = (value, record) => {
    let result = APP_TESTING_CATEGORIES.filter((e) => e.value == value);
    let text = result.length > 0 ? result[0].label : value;
    return (
      <Paragraph
        editable={{ onChange: (text) => editRecord(text, "category", record) }}
      >
        {text}
      </Paragraph>
    );
  };
  const editRecord = (newText, key, record) => {
    let newValues = sdkList.map((it) =>
      it == record ? { ...it, [key]: newText } : { ...it }
    );
    setSdkList(newValues);
  };
  const columns_sdk = [
    {
      title: "SDK Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      // render: (category, record) => parseCategory(category, record)
    },
    {
      title: "Options",
      dataIndex: "options",
      key: "options",
      render: (options, element) =>
        element ? <a onClick={() => deleteSDK(element)}>Delete</a> : null,
    },
  ];
  const columns_sdk_list = [
    {
      title: "SDK Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: "140px",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      //  render: category => parseCategory(category)
    },
    {
      title: "Supported in China",
      dataIndex: "works_in_china",
      key: "works_in_china",
      align: "center",
      render: (works_in_china) => getTag(works_in_china),
    },
    {
      title: "Recommended Alternative",
      dataIndex: "recommended_alternative",
      key: "recommended_alternative",
      align: "center",

      render: (recommended_alternative, it) =>
        recommended_alternative &&
        it.recommended_alternative_name &&
        it.works_in_china == false ? (
          <a
            href={recommended_alternative}
            rel="noopener noreferrer"
            target="_blank"
          >
            {it.recommended_alternative_name}
          </a>
        ) : (
          it.recommended_alternative_name
        ),
    },
  ];

  const getTag = (value) => {
    switch (value) {
      case null:
        return <Tag color="blue">No data</Tag>;
      case true:
        return (
          <Tag color="green">
            <span>
              Accessible <Icon type="check-circle" />
            </span>
          </Tag>
        );
      case false:
        return (
          <Tag color="red">
            <span>
              Blocked <Icon type="close-circle" />
            </span>
          </Tag>
        );
      default:
        return null;
    }
  };
  const deleteSDK = (elem) => {
    let result = sdkList.filter((e) => e != elem);
    setSdkList(result);
  };

  const parseApk = (fieldToUpdate, event, apk) => {
    if (apk) {
      const parser = new AppInfoParser(apk);
      parser.parse().then((pkgInfo) => {
        if (pkgInfo) {
          let appIcon = pkgInfo.icon ? pkgInfo.icon : null;
          setAppIcon(appIcon);
          const formUpdate = {
            version: pkgInfo.versionName,
            pkg_name: pkgInfo.package,
            version_code: pkgInfo.versionCode,
            app_name: pkgInfo.application.label[0] || "",
            file_url: fieldToUpdate.value,
          };
          getInfoFromMightySignal(pkgInfo.package);
          setCurrentApk(fieldToUpdate.value);
          setApkTitle(pkgInfo.package);
          setApkValues({ ...formUpdate });
        }
      });
    }
  };

  const getInfoFromMightySignal = async (pkg) => {
    let response = await getMightySignalInfo(pkg);
    let data = !!response && response.data;
    if (data && data.sdks !== "error" && data.sdks.length > 0) {
      let newSdks = data.sdks.map((it) => ({
        name: it.name,
        category: it.categories.length > 0 ? it.categories[0] : "",
      }));
      setSdkList(newSdks);
      openNotificationWithIcon(
        "info",
        "We found this list of SDKs, please check if they are updated."
      );
    }
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      try {
        if (!err) {
          if (Object.keys(apk_values).length > 0) {
            let data = {
              sdk_list: values.sdk_list,
              ...apk_values,
            };
            setLoading(true);
            let apkFile = await newApkFile(props.appId, data);
            setLoading(false);

            if (!apkFile.data.error) {
              let { data } = await newSdk(apkFile.data.PK_Apk, sdkList);
             
              if (data.length > 0) {
                setSdksResponse(data);
                getData(props.appId);
                setStep(2);
                form.resetFields();
                openNotificationWithIcon(
                  "info",
                  "Information received. We are preparing an integration report and will email you when the report is ready."
                );
                setSdkList([]);
                setApkValues({});
                setApkTitle("APK *");
                setCurrentApk(null);
                if (window.mixpanel) {
                  window.mixpanel.track("Localization requested");
                }
              } else {
                throw new Error(data.error);
              }
            } else {
              throw new Error(apkFile.data.error);
            }
          } else {
            openNotificationWithIcon("warning", "Please submit an APK file.");
          }
        }
      } catch (error) {}
    });
  };

  const addSdk = () => {
    let sdkFormat = {
      name: form.getFieldsValue().name,
      category:
        form.getFieldsValue().category == "OTHER"
          ? form.getFieldsValue().category_input
          : form.getFieldsValue().category,
    };
    setSdkList([...sdkList, sdkFormat]);
    setInputCategory(false);
    form.resetFields();
  };
  const deleteApk = () => {
    setCurrentApk(null);
    setApkValues({});
    setApkTitle("APK *");
  };
  return (
    <div className={classes.Container}>
      {translationSDK()}
      {step !== null && step !== 2 ? (
        <Row type="flex" justify="start">
          {" "}
          <Button
            icon="left"
            onClick={() => setStep(step !== 0 ? step - 1 : null)}
          >
            Go Back
          </Button>
        </Row>
      ) : null}
      {step !== null ? (
        <Steps size="small" current={step}>
          <Step title="Add APK" />
          <Step title="Validate SDKs" />
          <Step title="Results" />
        </Steps>
      ) : null}

      <Form hideRequiredMark={true} layout="vertical" onSubmit={handleSubmit}>
        {step === 0 ? (
          <LineCard shadow>
            <p>
              <b>Step 1: </b>click the box below to upload your app’s APK.{" "}
            </p>
            <Row gutter={16} type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item>
                  <File
                    name="apk"
                    type="File"
                    customIcon={appIcon}
                    title={apktitle}
                    validation={{ fileRequired: true }}
                    onDelete={() => deleteApk()}
                    source={currentApk}
                    submitFile={parseApk}
                    format={["apk"]}
                    style={{ width: "100%" }}
                    package_name={{
                      value: packageName,
                      message: `This APK appears to be for a different app from the one you uploaded previously. Please click on "New App" on the left to add a new app and upload this APK there.`,
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <CustomInput
                      placeholder="App Name"
                      value={apk_values.app_name ? apk_values.app_name : ""}
                      disabled
                    />
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <CustomInput
                      placeholder="Package Name"
                      value={apk_values.pkg_name ? apk_values.pkg_name : ""}
                      disabled
                    />
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ marginTop: "8px" }}>
                      <CustomInput
                        placeholder="Version Number"
                        value={apk_values.version ? apk_values.version : ""}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ marginTop: "8px" }}>
                      <CustomInput
                        placeholder="Version Code"
                        value={
                          apk_values.version_code ? apk_values.version_code : ""
                        }
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className={classes.ApkButton}>
              <Button
                style={{ marginTop: "16px" }}
                disabled={apk_values.app_name ? false : true}
                onClick={() => setStep(1)}
                icon="right"
              >
                Next
              </Button>
            </div>
          </LineCard>
        ) : null}

        {step === 1 ? (
          <LineCard shadow>
            <p>
              <b>Step 2: </b>type the name of each SDK within your app and click
              "Add" after entering each one.{" "}
            </p>
            <div
              className={inputCategory ? classes.Grid : classes.GridContainer}
            >
              <Form.Item label="SDK name">
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: sdkList.length > 0 ? false : true,
                      message: "Please input SDK name.",
                    },
                  ],
                })(<Input placeholder="Enter SDK name" />)}
              </Form.Item>
              <Form.Item label="Category">
                {getFieldDecorator("category", {
                  rules: [
                    {
                      required: sdkList.length > 0 ? false : true,
                      message: "Please select an option",
                    },
                  ],
                  onChange: (value) =>
                    value == "OTHER"
                      ? setInputCategory(true)
                      : setInputCategory(false),
                })(
                  <Select placeholder="Select a category...">
                    {APP_TESTING_CATEGORIES.map((it) => (
                      <Option key={it.value} value={it.value} label={it.label}>
                        {it.label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              {inputCategory ? (
                <Form.Item label="Please input category:">
                  {getFieldDecorator("category_input", {
                    rules: [
                      {
                        required: inputCategory,
                        message: "Please input category.",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              ) : null}
              <Form.Item>
                <div className={classes.AddButton}>
                  <Button
                    disabled={
                      form.getFieldsValue().name &&
                      form.getFieldsValue().category
                        ? false
                        : true
                    }
                    type="primary"
                    onClick={() => addSdk()}
                  >
                    Add
                  </Button>
                </div>
              </Form.Item>
            </div>
            {sdkList.length > 0 ? (
              <div style={{ marginBottom: "20px" }}>
                <Table
                  dataSource={sdkList}
                  columns={columns_sdk}
                  size="small"
                  pagination={{ pageSize: 10 }}
                />
              </div>
            ) : null}
            <Form.Item>
              <div className={classes.ApkButton}>
                <Button
                  loading={loading}
                  icon="right"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  disabled={
                    sdkList.length > 0 && Object.keys(apk_values).length > 0
                      ? false
                      : true
                  }
                >
                  Next
                </Button>
              </div>
            </Form.Item>
          </LineCard>
        ) : null}
      </Form>
      {step === 3 ? (
        <LineCard shadow>
          <p>
            <b>Step 3: </b>when the list is complete click "Submit".{" "}
          </p>
        </LineCard>
      ) : null}
      {step === null ? (
        <div>
          <Table
            rowKey={(record) => record.PK_Apk}
            dataSource={apk_files}
            columns={columns}
            size="small"
            pagination={{ pageSize: 10 }}
            loading={loadingInitial}
          />
          <Row type="flex" justify="end">
            <Button
              style={{ marginTop: 20 }}
              icon="plus"
              type="primary"
              onClick={() => setStep(0)}
            >
              Test new APK
            </Button>
          </Row>
        </div>
      ) : null}
      {step === 2 ? (
        skdsResponse.length > 0 ? (
          <Fragment>
            <Table
              size="small"
              dataSource={skdsResponse}
              columns={columns_sdk_list}
              pagination={{ pageSize: 10 }}
            />
            <Row type="flex" justify="center">
              <Button type="primary" onClick={() => setStep(null)}>
                OK
              </Button>
            </Row>
          </Fragment>
        ) : null
      ) : null}
    </div>
  );
};
export default Form.create()(ApkInspector);
