import React from "react";
import { Chart, Geom, Axis, Tooltip, View, Legend } from "bizcharts";

export default class Lineofdashed extends React.Component {
  render() {
    const data = this.props.data;
    var scale = {
      date: {
        alias: "Date",
        mask: "YYYY-MM-DD",
        sync: true
      },
      count: {
        alias: "New Users",
        range: [0, 1]
      }
    };

    class SliderChart extends React.Component {
      render() {
        return (
          <div style={{ width: "100%" }}>
            <Chart
              height={500}
              padding={60}
              forceFit
              data={data}
              scale={scale}
              style={{ marginTop: "40px" }}
            >
              <Axis name="count" />
              <Axis name="date" />
              <Tooltip
                crosshairs={{
                  type: "y"
                }}
              />
              <Legend
                position="top"
                useHtml={true}
                g2-legend={{
                  marginLeft: "100px",
                  marginTop: "-107px"
                }}
                g2-legend-list={{
                  border: "none",
                  height: "auto",
                  marginBottom: "30px"
                }}
              />
              <Geom
                type="line"
                color={"type"}
                position="date*count"
                size={2}
              />
              <Geom
                type="point"
                position="date*count"
                size={4}
                color={"type"}
                shape={"circle"}
                style={{
                  stroke: "#fff",
                  lineWidth: 1
                }}
              />
            </Chart>
          </div>
        );
      }
    }
    return <SliderChart />;
  }
}
