import axios from "../axios";

export const getForm = async (form, app_id) => {
  let response = null;
  try {
    response = await axios.get(`/${form}/${app_id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getFilterDownloads = async (filters) => {
  let response = null;
  try {
    response = await axios.post("/downloads", { ...filters });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getUpdates = async (id) => {
  let response = null;
  try {
    response = await axios.get(`/updates/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const updateForm = async (form, values) => {
  let response = null;
  try {
    response = await axios.put(`/${form}`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const submitForm = async (form, values) => {
  let response = null;
  try {
    response = await axios.post(`/${form}`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const newReply = async (values) => {
  let response = null;
  try {
    response = await axios.post("/review-reply", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const searchReviews = async (id) => {
  let response = null;
  try {
    response = await axios.get(`/manual-reviews/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export async function getAppDocuments(id) {
  let response = null;
  try {
    response = await axios.get(`/app-documents/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function getDistributionDetails(id) {
  let response = null;
  try {
    response = await axios.get(`/distribution/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export async function getDistributionResults(id) {
  let response = null;
  try {
    response = await axios.get(`/distribution-results/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function createDistributionEvent(data) {
  let response = null;
  try {
    response = await axios.post(`/distribution`, {
      ...data,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}

export async function attachNewDocument(data) {
  let response = null;
  try {
    response = await axios.post(`/app-documents`, {
      ...data,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
}
export const getReviews = async (id) => {
  let response = null;
  try {
    response = await axios.get(`/reviews/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const deleteFormSubmission = async (id) => {
  let response = null;
  try {
    response = await axios.delete(`/app-form/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getOrCreateDraft = async (id, is_apk_update) => {
  let response = null;
  try {
    response = await axios.get(`/draft/${id}?is_update=${is_apk_update}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const hideAppIntroduction = async (id, is_apk_update) => {
  let response = null;
  try {
    response = await axios.put(`/app-intro-viewed`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getDotTabs = async (app) => {
  let response = null;
  try {
    response = await axios.get(`/viewed/${app}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const setAsViewed = async (data) => {
  let response = null;
  try {
    response = await axios.put(`/viewed`, {
      ...data,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const newAuthorizationLetter = async (values) => {
  let response = null;
  try {
    response = await axios.post(`/app-document`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getAuthorizationLetter = async (id) => {
  let response = null;
  try {
    response = await axios.get(`/app-document/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
