import React, { useEffect, useState } from "react";
import MultiLineChart from "../../../UI/Charts/MultiLineChart";
import {
  Tabs,
  Card,
  DatePicker,
  Row,
  Col,
  Select,
  Table,
  Tooltip,
  Icon,
} from "antd";
import classes from "./UsersPerDay.module.less";
import {
  chartsFake,
  usersPerDayTable,
  storesOptions,
  versionsFake,
} from "../../PromotionFakeData";
import functions, { getDate } from "../../../../functions";
import { getUsersPerDay } from "../../../../services/promotionService";
import LoadingSpin from "../../../UI/LoadingSpin/LoadingSpin";
const { TabPane } = Tabs;
const { Option } = Select;

export default ({ app, useDemoData, store }) => {
  const [loading, setLoading] = useState(false);
  const [chart, setChart] = useState({});
  const [table, setTable] = useState([]);
  const [date, setDate] = useState(getDate());
  const [stores, setStores] = useState(null);
  const [version, setVersion] = useState(null);
  const [allStores, setStoresOptions] = useState([]);
  const [versionsOptions, setVersionsOptions] = useState([]);
  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getUsersPerDay({
      promotionId: app.analitycs_id,
      app: app.id,
      start: date[0].format("YYYY-MM-DD"),
      end: date[1].format("YYYY-MM-DD"),
      version,
      store: stores,
    });
    setChart(response.charts);
    setTable(response.table);
    setStoresOptions(response.stores);
    setVersionsOptions(response.versions);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setStoresOptions([...storesOptions, "All Stores"]);
        setVersionsOptions([...versionsFake, "All Versions"]);
        setTable(usersPerDayTable);
        setChart({
          activeUsers: chartsFake(["Vivo", "Oppo", "Huawei", "Tencent My App"]),
          usersComposition: chartsFake([
            "Vivo",
            "Oppo",
            "Huawei",
            "Tencent My App",
          ]),
          usersViscosity: chartsFake([
            "Vivo",
            "Oppo",
            "Huawei",
            "Tencent My App",
          ]),
          payingUsers: chartsFake(["Vivo", "Oppo", "Huawei", "Tencent My App"]),
        });
        setVersionsOptions([
          {
            value: null,
            label: "All Versions",
          },
        ]);
        setStoresOptions([
          {
            value: null,
            label: "All Stores",
          },
        ]);

        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    validateRequest();
  }, [app]);

  useEffect(() => {
    validateRequest();
  }, [date, stores, version]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      fixed: "left",
      width: "100px",
    },
    {
      title: "Active Users",
      dataIndex: "activeUsers",
      key: "activeUsers",
      align: "right",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "Users Composition",
      dataIndex: "usersComposition",
      key: "usersComposition",
      align: "right",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "DAU 7 Days",
      dataIndex: "dau7Days",
      key: "dau7Days",
      align: "right",
      render: (val) => `${functions.styleAsNumber(val)} %`,
    },

    {
      title: "DAU 30 Days",
      dataIndex: "dau30Days",
      key: "dau30Days",
      align: "right",
      render: (val) => `${functions.styleAsNumber(val)} %`,
    },
  ];

  return (
    <div className={classes.container}>
      <Card
        title={`${
          store && store !== "undefined" && store !== "null" ? store : ""
        } Active User Analysis`}
        style={{ marginTop: 15 }}
      >
        <Row gutter={16}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 10 }}
            style={{ marginBottom: 15 }}
          >
            <DatePicker.RangePicker
              disabled={loading}
              value={date}
              onChange={(moment) => {
                setDate(moment);
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 4 }}
            style={{ marginBottom: 15 }}
          >
            <Select
              loading={loading}
              disabled={loading}
              value={version}
              style={{ width: "100%" }}
              placeholder="Version"
              onChange={(val) => setVersion(val)}
            >
              {versionsOptions.map((it) => (
                <Option value={it.value}>{it.label}</Option>
              ))}
            </Select>
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 4 }}
            style={{ marginBottom: 15 }}
          >
            <Select
              loading={loading}
              disabled={loading}
              value={stores}
              style={{ width: "100%" }}
              placeholder="App Store"
              onChange={(val) => setStores(val)}
            >
              {allStores.map((it) => (
                <Option value={it.value}>{it.label}</Option>
              ))}
            </Select>
          </Col>
        </Row>
        {loading ? (
          <LoadingSpin />
        ) : (
          <Tabs
            type="card"
            defaultActiveKey="1"
            style={{ width: "100%", marginTop: 15 }}
          >
            <TabPane disabled={loading} tab="Active Users" key="1">
              <MultiLineChart loading={loading} data={chart.activeUsers} />
            </TabPane>
            <TabPane disabled={loading} tab="Users Composition" key="2">
              <p>
                <b>Users Composition:</b> Percentage of new users with respects
                to active users
              </p>
              <MultiLineChart loading={loading} data={chart.usersComposition} />
            </TabPane>
            <TabPane disabled={loading} tab="Users Viscosity" key="3">
              <p>
                Users Viscosity: DAU/ past 7 day active users not including
                today. This is a way to measure how sticky your app is
              </p>
              <MultiLineChart loading={loading} data={chart.usersViscosity} />
            </TabPane>
            <TabPane disabled={loading} tab="Paying Users" key="4">
              <MultiLineChart loading={loading} data={chart.payingUsers} />
            </TabPane>
          </Tabs>
        )}
        <Table
          scroll={{ x: 500 }}
          columns={columns}
          dataSource={table}
          size="small"
          style={{ marginTop: 15 }}
          loading={loading}
        />
      </Card>
    </div>
  );
};
