import React, { useState, useEffect } from "react";
import { Button, DatePicker, Row, Col, Table, Alert, Select } from "antd";
import moment from "moment";
import { getFilterDownloads } from "../../../services/distributionService";
import history from "../../../history";
import classes from "./Downloads.module.less";
import DownloadsCard from "./DownloadsCard/DownloadsCard";
import functions from "../../../functions";
import MultiLineChart from "./DownloadsCard/MultilineChart";

const { RangePicker } = DatePicker;
const { Option } = Select;
export default (props) => {
  let { app_id } = props.match.params;

  let columns = [
    {
      dataIndex: "date",
      title: "Date",
      fixed: "left",
      width: "100px",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    },
    {
      dataIndex: "new",
      title: "New Downloads",
      render: (downloads) => functions.styleAsNumber(downloads),
    },
    {
      dataIndex: "update",
      title: "Update Downloads",
      render: (downloads) => functions.styleAsNumber(downloads),
    },
    {
      dataIndex: "total",
      title: "Total Downloads",
      render: (downloads) => functions.styleAsNumber(downloads),
    },
  ];

  const lastSyncColumns = [
    { dataIndex: "store", title: "Store" },
    { dataIndex: "last_sync", title: "Last Synchronization Date" },
  ];

  if (app_id) {
    const [dateRange, setDateRange] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cumulativeDow, setCumulative] = useState([]);
    const [table, setTableData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [sumary, setSumary] = useState({});
    const [stores, setStores] = useState(["All Stores"]);
    const [storeSelected, setStoreSelected] = useState("All Stores");
    const [lastSyncInfo, setLastSync] = useState(null);
    const getCumulative = (data) => {
      let array = [];
      data.map(({ date, data }) => {
        data.map(({ store, downloads }) => {
          array.push({
            date,
            downloads,
            store,
          });
        });
      });
      return array;
    };
    const tableData = (downloadsArray) => {
      return downloadsArray.map(({ date, data }) => {
        let downloads = data.reduce(
          (prev, curr) => ({
            ...prev,
            update: prev.update + curr.currentUpdateDown,
            total: prev.total + curr.currentDown,
          }),
          {
            total: 0,
            update: 0,
          }
        );
        downloads = {
          ...downloads,
          new: downloads.total - downloads.update,
        };
        return {
          date,
          ...downloads,
        };
      });
    };

    const sumaryData = (data, lastDayData, forcedStore) => {
      console.log("DATA NO FILTER", data, lastDayData, forcedStore);
      const sumary = data.reduce(
        (prev, curr) => ({
          total: prev.total + curr.total,
          update: prev.update + curr.update,
          new: prev.new + curr.new,
        }),
        {
          total: 0,
          update: 0,
          new: 0,
        }
      );
      var totalCumulative = 0;
      if (storeSelected === "All Stores" || forcedStore === "All Stores") {
        totalCumulative = lastDayData.data.reduce(
          (prev, { downloads }) => prev + downloads,
          0
        );
      } else {
        let lastDayRow = lastDayData.data.find(
          (row) => storeSelected === row.store.fullname
        );
        totalCumulative = lastDayRow && lastDayRow.downloads;
      }
      console.log("DATA AFTER FILTER", {
        ...sumary,
        totalCumulative,
      });
      return {
        ...sumary,
        totalCumulative,
      };
    };

    const chartData = (data) => {
      return data.reduce((prev, curr) => {
        const data = [
          { title: "New Downloads", key: "new" },
          { title: "Update Downloads", key: "update" },
          { title: "Total Downloads", key: "total" },
        ].map(({ title, key }) => ({
          title,
          date: curr.date,
          downloads: curr[key],
        }));
        return [...prev, ...data];
      }, []);
    };

    const searchDownloads = async () => {
      try {
        if (dateRange.length > 0) {
          setLoading(true);
          let [start, end] = dateRange;
          const { data } = await getFilterDownloads({
            start,
            end,
            app_id,
          });
          setLoading(false);
          if (data && data.length > 0) {
            let cumulative_dow = getCumulative(data);
            const lastRecord = data[data.length - 1];
            setCumulative(cumulative_dow);
            let table = tableData(data);
            let appStores = [];
            data.map(({ data }) => {
              data.map(({ store }) => {
                if (appStores.indexOf(store.fullname) === -1) {
                  appStores.push(store.fullname);
                }
              });
            });
            setStores(["All Stores", ...appStores]);
            await setStoreSelected("All Stores");
            setLastSync(lastSyncDate(lastRecord.data));
            setRawData(data);
            setTableData(table);
            setSumary(sumaryData(table, lastRecord, "All Stores"));
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    const filterByStore = () => {
      let info;
      let arr = [];
      if (storeSelected == "All Stores") {
        info = tableData(rawData);
      } else {
        rawData.map(({ data, date }) => {
          let storeInfo = data.filter(
            (e) => e.store.fullname === storeSelected
          );
          if (storeInfo && storeInfo[0]) {
            arr.push({
              date: date,
              total: storeInfo[0].currentDown,
              update: storeInfo[0].currentUpdateDown,
              new: storeInfo[0].currentDown - storeInfo[0].currentUpdateDown,
            });
          }
        });
        info = arr;
      }
      setTableData(info);
      if (info.length > 0 && rawData.length > 0) {
        setSumary(sumaryData(info, rawData[rawData.length - 1]));
      }
    };

    const lastSyncDate = (data) => {
      return data.map(({ store: { fullname, last_synchronization_date } }) => ({
        store: fullname,
        last_sync: last_synchronization_date,
      }));
    };

    useEffect(() => {
      let end = moment();
      let start = moment().subtract(30, "days");
      setDateRange([start, end]);
      searchDownloads();
    }, [app_id]);

    useEffect(() => {
      searchDownloads();
    }, [dateRange]);

    useEffect(() => {
      filterByStore();
    }, [storeSelected]);

    return (
      <div className={classes.container}>
        <Row type="flex" gutter={16} className={classes.filters}>
          <Col xs={24} sm={6} md={4}>
            <Button
              onClick={() =>
                history.push(`/distribution/${app_id}/distribution-status`)
              }
              icon="left"
              style={{ width: "100%" }}
            >
              Go back
            </Button>
          </Col>
          <Col xs={24} sm={18} md={10}>
            <RangePicker
              value={[dateRange[0], dateRange[1]]}
              onChange={(moment) => setDateRange(moment)}
              style={{ width: "100%" }}
              disabled={loading}
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <div className={classes.storesFilter}>
              <label>Filter By Store: </label>
              <Select
                value={storeSelected}
                style={{ width: "100%" }}
                onChange={(val) => setStoreSelected(val)}
                disabled={loading}
              >
                {stores.map((it) => (
                  <Option key={it} value={it} label={it}>
                    {it}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <div className={classes.alert}>
          <Alert
            message="The download numbers displayed here may be slightly innacurate since some stores may be unreliable on their calculations."
            type="warning"
            showIcon
            closeText="Dismiss"
          />
        </div>
        {storeSelected !== "All Stores" ? (
          <div className={classes.alert}>
            <Alert
              message={
                <p>
                  Data filtered. Showing information of <b>{storeSelected}</b> .
                </p>
              }
              type="info"
              showIcon
            />
          </div>
        ) : null}
        <Row gutter={24}>
          <Col>
            <table className={classes.summaryTable}>
              <tbody>
                <tr>
                  <td>New Downloads</td>
                  <td>{functions.styleAsNumber(sumary.new)}</td>
                </tr>
                <tr>
                  <td>Update Downloads</td>
                  <td>{functions.styleAsNumber(sumary.update)}</td>
                </tr>
                <tr>
                  <td>Total Downloads</td>
                  <td>{functions.styleAsNumber(sumary.total)}</td>
                </tr>
                {/*<tr>
                  <td>Total Cumulative Downloads</td>
                  <td>{functions.styleAsNumber(sumary.totalCumulative)}</td>
                </tr>*/}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col>
            <MultiLineChart
              data={chartData(table)}
              rawData={table}
              loading={loading}
              title="Downloads Per Day"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              style={{ marginTop: 12 }}
              size="small"
              dataSource={table}
              columns={columns}
              loading={loading}
              pagination={{ pageSize: 8 }}
              scroll={{ x: 500 }}
            />
          </Col>

          {/*<Col span={24}>
            <DownloadsCard
              title="Cumulative Downloads"
              loading={loading}
              data={cumulativeDow}
              rawData={rawData}
            />
            </Col>*/}
          <Col span={24}>
            <Table
              dataSource={lastSyncInfo}
              size="small"
              columns={lastSyncColumns}
              loading={!lastSyncInfo}
            />
          </Col>
        </Row>
      </div>
    );
  }
};
