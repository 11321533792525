import axios from "../axios";
export const newAnalyticsRequest = async (
  title,
  client_email,
  appId,
  appName
) => {
  let response = null;
  try {
    response = await axios.post("/analytics-request", {
      title,
      client_email,
      appId,
      appName
    });
  } catch (e) {
    console.log(e);
  }
  return response;
};
export const getUsersAnalytics = async (startDate, endDate, version, accountId) => {
  let response = null;
  try {
    response = await axios.post("/test-analytics-users", {
      startDate,
      endDate,
      version,
      accountId
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const getAnalyticsGeneralInformation = async (
  startDate,
  endDate,
  version,
  accountId
) => {
  let response = null;
  try {
    response = await axios.post("/analytics-general-information", {
      startDate,
      endDate,
      version,
      accountId
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getPaymentsInformationService = async (
  startDate,
  endDate,
  version,
  accountId
) => {
  let response = null;
  try {
    response = await axios.post("/payments-analytics", {
      startDate,
      endDate,
      version,
      accountId
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
