import axios from "../axios";

export const getApps = async () => {
  let response = null;
  try {
    response = await axios.get("/apps");
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const newApp = async values => {
  let response = null;
  try {
    response = await axios.post("/apps", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const deleteApps = async values => {
  let response = null;
  try {
    response = await axios.delete("/apps", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
