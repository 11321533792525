import React, { useState, useEffect } from "react";
import classes from "./DatabaseConfiguration.module.less";
import {
  Form,
  notification,
  Button,
  Row,
  Icon,
  InputNumber,
  Modal,
  Radio,
  Select,
  Col
} from "antd";
import functions from "../../../../functions";
import { getAllDatabases } from "../../../../services/cloudStoreService";
import history from "../../../../history";
import LoadingSpin from "../../../UI/LoadingSpin/LoadingSpin";
const { confirm } = Modal;
const { Option } = Select;
const { Item } = Form;
let uuid = require("uuid-random");
const DatabaseConfiguration = ({ form, appId, ...props }) => {
  const formItemLayout = {};
  const { getFieldDecorator } = form;
  let [selected, setSelected] = useState("mysql");
  let [options, setOptions] = useState([]);
  let [total, setTotal] = useState(0);
  let [price, setPrice] = useState(0);
  let [loading, setLoading] = useState(false);
  let [actual, setActual] = useState({});
  let [data, setData] = useState([]);
  let [initial, setInitial] = useState(null);
  const filterSpecifications = () => {
    if (data && data.length > 0) {
      let copy = data.filter(e => e.type === selected);
      let values = copy.map(({ cpu_ram }) => cpu_ram);
      calculatePrice(
        form.getFieldValue("type"),
        values[0],
        form.getFieldValue("storage_gb"),
        form.getFieldValue("months_paid")
      );
      setOptions(values);
      form.resetFields(["specification"]);
    }
  };
  const getData = async () => {
    setLoading(true);
    let { data } = await getAllDatabases();
    setLoading(false);
    if (data) {
      setData(data);
      editItem(data);
    }
  };
  const editItem = values => {
    if (props.history.location.state && props.history.location.state.id) {
      let idItem = props.history.location.state.id;
      let localStorageCopy = localStorage.cloud_cart
        ? JSON.parse(localStorage.cloud_cart)
        : [];
      let info = localStorageCopy.orders.filter(e => e.id === idItem)[0];
      let copyChecked = { ...values };
      Object.keys(copyChecked).map(it => (copyChecked[it] = false));
      copyChecked[info.product_subtype] = true;
      setSelected(info.product_subtype);
      setTotal(info.total);
      setPrice(info.price);
      let specifications = {};
      info.description.map(
        ({ key, value }) =>
          (specifications[functions.reverseSplitAndCapitalize(key)] = value)
      );

      specifications["item_id"] = info.id;
      specifications["months_paid"] = info.months_paid;
      setInitial(specifications);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    filterSpecifications();
  }, [selected]);
  useEffect(() => {
    filterSpecifications();
  }, [data]);
  const calculatePrice = (type, specification, storage, months) => {
    if (data.length > 0) {
      let info = data.filter(
        e => e.type === type && e.cpu_ram === specification
      )[0];
      setActual(info);
      let type_price = info.price;
      let extra_storage_price = storage * 5;
      let price = type_price + extra_storage_price;
      let total = price * months;
      setPrice(+price.toFixed(2));
      setTotal(+total.toFixed(2));
    }
  };
  const handleSubmit = event => {
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        confirm({
          title: "Are you sure you want request this service?",
          onOk() {
            let data = {
              id: initial && initial.item_id ? initial.item_id : uuid(),
              title: "Database",
              product_type: "database",
              product_subtype: values.type,
              months_paid: values.months_paid,
              monthly_price: price,
              onetime_fee: 0,
              price: price,
              total: total,
              description: [
                {
                  key: "Type",
                  value: values.type
                },
                {
                  key: "Specifications",
                  value: values.specification
                },
                {
                  key: "Storage GB",
                  value: actual.storage
                },
                {
                  key: "Extra Storage GB",
                  value: values.storage_gb
                }
              ]
            };
            history.push(`/cloud/cart/${appId}`);
            saveCart(data);
          },
          onCancel() {}
        });
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft"
        });
      }
    });
  };
  const saveCart = values => {
    let localStorageCopy = localStorage.cloud_cart
      ? JSON.parse(localStorage.cloud_cart)
      : [];
    let localStorageOrders =
      localStorageCopy && localStorageCopy.orders
        ? localStorageCopy.orders
        : [];
    const index = localStorageOrders.findIndex(e => e.id === values.id);
    if (index === -1) {
      localStorageOrders.push(values);
    } else {
      localStorageOrders[index] = values;
    }
    const appFilterSettings = {
      orders: [...localStorageOrders]
    };
    window.localStorage.setItem(
      "cloud_cart",
      JSON.stringify(appFilterSettings)
    );
    form.resetFields();
    setSelected("mysql");
  };
  let months = [3, 6, 9, 12];
  return loading ? (
    <LoadingSpin />
  ) : (
    <div className={classes.container}>
      <Row type="flex" justify="start">
        <Button
          onClick={() => {
            window.history.back();
          }}
          type="primary"
          width="100px"
          margin="auto"
          ghost
        >
          <Icon type="left" />
          Go back
        </Button>
      </Row>
      <Form
        {...formItemLayout}
        onSubmit={handleSubmit}
        colon={false}
        layout="vertical"
      >
        <div className={classes.form}>
          <Row type="flex" justify="space-between">
            <Item label="Type">
              {getFieldDecorator(`type`, {
                initialValue: initial && initial.type ? initial.type : selected,
                rules: [
                  {
                    required: true,
                    message: "Required!",
                    whitespace: true
                  }
                ]
              })(
                <Radio.Group
                  onChange={e => {
                    setSelected(e.target.value);
                  }}
                >
                  <Radio value="mysql">MySQL</Radio>
                  <Radio value="postgres_sql">PostgresSQL</Radio>
                </Radio.Group>
              )}
            </Item>
            <h1>USD ${functions.styleAsNumber(price)}/Month</h1>
          </Row>

          <Item label="Specifications">
            {getFieldDecorator(`specification`, {
              initialValue:
                initial && initial.specifications
                  ? initial.specifications
                  : options[0],
              rules: [
                {
                  required: true,
                  message: "Required!",
                  whitespace: true
                }
              ]
            })(
              <Select
                onChange={val =>
                  calculatePrice(
                    form.getFieldValue("type"),
                    val,
                    form.getFieldValue("storage_gb"),
                    form.getFieldValue("months_paid")
                  )
                }
                placeholder="Select..."
              >
                {options.map(it => (
                  <Option key={it} value={it} label={it}>
                    {it}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <p className={classes.info}>* Storage Starting at 100GB</p>
          <Item label="Extra Storage">
            {getFieldDecorator(`storage_gb`, {
              initialValue:
                initial && initial.extra_storage_gb
                  ? initial.extra_storage_gb
                  : 0
            })(
              <InputNumber
                onChange={storage =>
                  calculatePrice(
                    form.getFieldValue("type"),
                    form.getFieldValue("specification"),
                    storage,
                    form.getFieldValue("months_paid")
                  )
                }
                min={0}
                max={20}
                step={1}
                formatter={value => `${value}GB`}
                parser={value => value.replace("GB", "")}
              />
            )}
          </Item>

          <Row type="flex">
            <Col span={12}>
              <Item label="Select a monthly plan:" style={{ margin: 0 }}>
                {getFieldDecorator(`months_paid`, {
                  initialValue:
                    initial && initial.months_paid ? initial.months_paid : 3
                })(
                  <Select
                    onChange={val =>
                      calculatePrice(
                        form.getFieldValue("type"),
                        form.getFieldValue("specification"),
                        form.getFieldValue("storage_gb"),
                        val
                      )
                    }
                  >
                    {months.map(it => (
                      <Option key={it} value={it}>
                        {it} Months
                      </Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={12}>
              <h1 className={classes.total}>
                Total: USD ${functions.styleAsNumber(total)}
              </h1>
            </Col>
          </Row>
        </div>
        <Item>
          <div className={classes.button}>
            <Button
              width="200px"
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Add to Cart
            </Button>
          </div>
        </Item>
      </Form>
    </div>
  );
};
export default Form.create()(DatabaseConfiguration);
