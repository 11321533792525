import React, { Fragment } from "react";
import classes from "./AppDistributionCard.module.less";
import { Link } from "react-router-dom";
import {
  Table,
  Spin,
  Tag,
  Popover,
  Icon,
  Button,
  Row,
  Col,
  Progress,
  Divider,
} from "antd";
import functions from "../../../functions";
const AppDistributionCard = ({
  title,
  loading,
  data,
  appId,
  percent = 0,
  info,
  display_authorization,
  completedStores,
  ...props
}) => {
  let corporate_client =
    props.app &&
    (props.app.main_information.app_store_accounts === "CLIENT_CC" ||
      props.app.main_information.app_store_accounts === "CLIENT_OC");
  const getStyle = (val) => {
    let result;
    val && val !== "NOT_RECEIVED" && val !== "WAITING"
      ? (result = (
          <Button
            ghost
            style={{ marginRight: 10, marginBottom: 10 }}
            type="primary"
            size="small"
            shape="circle"
            icon="check"
          ></Button>
        ))
      : (result = (
          <Button
            ghost
            style={{ marginRight: 10, marginBottom: 10 }}
            type="danger"
            size="small"
            shape="circle"
            icon="close"
          ></Button>
        ));
    return result;
  };

  const STATUS = {
    NOT_SUBMITTED: <Tag color="gold">Not Submitted</Tag>,
    SUBMITTED_TO_STORE: <Tag color="cyan">Submitted to Store</Tag>,
    PUBLISHED: <Tag color="green">Published</Tag>,
    REJECTED: <Tag color="blue">Rejected</Tag>,
    RECLAIM: <Tag color="magenta">Reclaim</Tag>,
    PAUSED: <Tag color="purple">Paused</Tag>,
    SUSPENDED: <Tag color="volcano">Suspended</Tag>,
    REMOVED: <Tag color="red">Removed</Tag>,
  };

  const getLink = (link, distribution_status) => {
    return link ? (
      <a rel="noopener noreferrer" href={link} target="_blank">
        View In Store
      </a>
    ) : (
      <Popover
        placement="top"
        content={
          <span className={classes.problemText}>
            This store can only be accessed through the store's mobile app.
          </span>
        }
      >
        <Tag color="grey">
          Mobile Only <Icon type="info-circle" />
        </Tag>
      </Popover>
    );
  };
  let columns = [
    {
      title: "Store",
      dataIndex: "store",
      align: "left",
      key: "store",
      render: (store) => store.fullname,
    },
    {
      title: "Status",
      dataIndex: "distribution_status",
      align: "center",
      render: (distribution_status, it) => STATUS[distribution_status],
    },
    {
      title: "Link",
      dataIndex: "link",
      align: "center",
      key: "link",
      fixed: "right",
      width: "100px",
      render: (link, it) => getLink(link, it.distribution_status),
    },
  ];
  let LoadingSpin = (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );

  console.log("completedStores", completedStores);

  return (
    <div className={classes.Container}>
      {loading ? (
        LoadingSpin
      ) : (
        <Fragment>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <div className={classes.Info}>
                <div className={classes.InfoText}>
                  {info && info.length > 0
                    ? info.map((it) => (
                        <p>
                          {it.title}: <b>{functions.styleAsNumber(it.data)}</b>
                        </p>
                      ))
                    : ""}
                </div>
              </div>
            </Col>
            <Col span={16}>
              <p className={classes.total}>
                {percent ? functions.styleAsNumber(percent) : null}
              </p>
              <div className={classes.Chart}>
                <Progress
                  percent={percent}
                  showInfo={percent === 100 ? true : false}
                />
              </div>
            </Col>
          </Row>

          <Divider />
          <Row gutter={24} type="flex">
            <Col xs={24} sm={7} style={{ marginBottom: "16px" }}>
              <div className={classes.Forms}>
                <h4>Pre-distribution-actions:</h4>
                {data && data.main_information ? (
                  <div>
                    {corporate_client ? (
                      <div className={classes.buttonForms}>
                        {getStyle(data.main_information.business_form_status)}
                        <Link
                          to={`/distribution/${appId}/business-information`}
                        >
                          Business Form
                        </Link>
                      </div>
                    ) : null}
                    {display_authorization ? (
                      <div className={classes.buttonForms}>
                        {getStyle(
                          data.main_information.authorization_letter_status
                        )}
                        <Link
                          to={`/distribution/${appId}/authorization-letter`}
                        >
                          Authorization Letter
                        </Link>
                      </div>
                    ) : null}
                    <div className={classes.buttonForms}>
                      {getStyle(data.main_information.security_form_status)}
                      <Link to={`/distribution/${appId}/security-assesment`}>
                        Security Form
                      </Link>
                    </div>
                    <div className={classes.buttonForms}>
                      {getStyle(data.main_information.copyright_form_status)}
                      <Link to={`/distribution/${appId}/copyright-certificate`}>
                        Copyright Form
                      </Link>
                    </div>
                    <div className={classes.buttonForms}>
                      {getStyle(data.main_information.privacy_policy_status)}
                      <Link
                        to={`/distribution/${appId}/app-information/submit_new_version`}
                      >
                        Privacy Policy
                      </Link>
                    </div>
                    <div className={classes.buttonForms}>
                      {getStyle(data.main_information.apk_reclaim_status)}
                      <Link
                        to={`/distribution/${appId}/app-information/apk_reclaim`}
                      >
                        APK Reclaim
                      </Link>
                    </div>
                  </div>
                ) : null}
                <h4>Distribution-actions:</h4>
                {data && data.main_information ? (
                  <div className={classes.buttonForms}>
                    {getStyle(data.main_information.app_form_status)}
                    <Link
                      to={`/distribution/${appId}/app-information/submission_history`}
                    >
                      App Form
                    </Link>
                  </div>
                ) : null}
              </div>
            </Col>
            <Col xs={24} sm={17}>
              <div className={classes.Table}>
                {" "}
                <Table
                  dataSource={completedStores || []}
                  columns={columns}
                  size="small"
                  pagination={{ pageSize: 5 }}
                />
              </div>
            </Col>
          </Row>
        </Fragment>
      )}
    </div>
  );
};

export default AppDistributionCard;
