import React, { useState } from "react";
import classes from "./Register.module.less";
import LoginForm from "../Login/LoginForm/LoginForm";
import { Button, Input, Form } from "antd";
import { Link } from "react-router-dom";
import { registerEmail } from "../../services/acccountService";
import { notification } from "antd";
import Auth from "../../auth";
const auth = new Auth();

export default Form.create({ name: "login_form" })(({ form }) => {
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator } = form;
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        register(values);
      }
    });
  };

  const register = async (formValues) => {
    try {
      const { data } = await registerEmail(formValues);
      if (!data.error) {
        handleSuccessfulRegistration(formValues);
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon("warning", `${error.message}`);
    }
  };

  const handleSuccessfulRegistration = async ({ email, password }) => {
    openNotificationWithIcon("info", "Successful registration");
    if (window.mixpanel) window.mixpanel.track("User registered");
    let { err, result } = await auth.login(email, password);
    setLoading(false);
    auth.handleAuthentication(result, err);
  };

  return (
    <LoginForm>
      <div className={classes.Container}>
        <h2 className={classes.Title}>Welcome</h2>
        <Form onSubmit={handleSubmit} className={classes.form}>
          <Form.Item label="First Name">
            {getFieldDecorator("first_name", {
              rules: [{ required: true, message: "Required" }],
              validateTrigger: "onSubmit",
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Last Name">
            {getFieldDecorator("last_name", {
              rules: [{ required: true, message: "Required" }],
              validateTrigger: "onSubmit",
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Job Title">
            {getFieldDecorator("position", {
              rules: [{ required: true, message: "Required" }],
              validateTrigger: "onSubmit",
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Required" },
                {
                  pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                  message: "Input a valid email",
                },
              ],
              validateTrigger: "onSubmit",
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Required" },
                {
                  pattern: /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
                  message:
                    "Must be at least 10 characters long and contain at least one lower case letter, one upper case letter, a number and an special character",
                },
              ],
              validateTrigger: "onSubmit",
            })(<Input.Password />)}
          </Form.Item>
          <Button
            loading={loading}
            disable={loading}
            type="primary"
            htmlType="submit"
          >
            Register
          </Button>
        </Form>
      </div>
      <div className={classes.BottomOptions}>
        <Link to="/login">Already have an account?</Link>
      </div>
    </LoginForm>
  );
});
