import React, { useState, useEffect } from "react";
import { Card, Statistic } from "antd";
import { amontSpentFake } from "../../PromotionFakeData";
import { getAmountSpent } from "../../../../services/promotionService";
import classes from "./AmountSpent.module.less";
export default ({ app, useDemoData }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const gridStyle = {
    width: "25%",
    textAlign: "center",
  };

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getAmountSpent(app.id);
    setData(response);
    setLoading(false);
  };

  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData({
          ...amontSpentFake,
        });
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    validateRequest();
  }, [app]);

  return (
    <div className={classes.container}>
      <Card loading={loading} title="Amount Spent">
        <Card.Grid style={gridStyle}>
          <Statistic title="Overall" value={data.overall} />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic title="Yesterday" value={data.yesterday} />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic title="Recent 7 Days" value={data.recent_7_days} />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic title="Recent 30 Days" value={data.recent_30_days} />
        </Card.Grid>
      </Card>
    </div>
  );
};
