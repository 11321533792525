import React from "react";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import { app_visits } from "../../fakeData";
export default ({ useDemoData, data }) => {
  let chartData = useDemoData ? app_visits : data;
  const cols = {
    average_visits: {
      min: 0
    },
    date: {
      range: [0, 1]
    }
  };

  return (
    <div>
      <Chart height={500} data={chartData} scale={cols} forceFit>
        <Axis name="date" />
        <Axis name="average_visits" label={{ formatter: val => `${val}` }} />
        <Tooltip
          crosshairs={{
            type: "y"
          }}
        />
        <Geom
          type="line"
          position="date*average_visits"
          size={2}
          tooltip={[
            "date*average_visits",
            (date, average_visits) => {
              return {
                name: `Average visits : ${average_visits}`
              };
            }
          ]}
        />
        <Geom
          type="point"
          position="date*average_visits"
          shape="smooth"
          size={2}
        />
      </Chart>
    </div>
  );
};
