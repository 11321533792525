import React, { useState, Fragment, useEffect } from "react";
import classes from "./History.module.less";
import { Table, Row, Col, Button, Input, Tag, Alert } from "antd";
import { getAllNotifications } from "../../../services/pushNotificationsService";
import moment from "moment";
import { Link } from "react-router-dom";
import { DEMO_HISTORY } from "../FakeData";
import LineCard from "../../UI/LineCard/LineCard";

const Users = ({ app, useDemoData, ...props }) => {
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  let createStatusTag = (status) => {
    switch (status) {
      case "SENT":
        return <Tag color="green">Sent</Tag>;
      case "ERROR":
        return <Tag color="red">Error</Tag>;
      case "PENDING":
        return <Tag color="blue">Pending</Tag>;
      default:
        return null;
    }
  };

  const tableColumns = () => [
    {
      title: "Date Sent",
      dataIndex: "send_time",
      key: "send_time",
      align: "center",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => createStatusTag(status),
    },
    {
      title: "",
      dataIndex: "pushId",
      key: "pushId",
      render: (pushId) => (
        <Link
          to={`/push-notifications/${
            app ? app.id : props.appId
          }/details/${pushId}`}
        >
          View Details
        </Link>
      ),
    },
  ];

  useEffect(() => {
    fetch("");
  }, [app.id, useDemoData]);

  const fetch = async (text) => {
    if (!useDemoData) {
      try {
        if (!!app) {
          setLoading(true);
          let response = await getAllNotifications(app.id, text);
          setLoading(false);
          if (response && response.data && response.data.history) {
            setValues(response.data.history);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const searchResults = (value) => {
    fetch(value);
  };

  const resetFilter = () => {
    setSearch("");
    fetch("");
  };

  return (
    <Fragment>
      <div className={classes.intro}>
        {useDemoData ? (
          <>
            <Alert
              message="You need to have push notifications enabled to use this feature."
              description="Please contact your account manager for details."
              type="warning"
              showIcon
              style={{ textAlign: "left" }}
            />
            <p></p>
          </>
        ) : null}
        <LineCard shadow>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </LineCard>
      </div>
      <div className={classes.tableContainer}>
        <Row gutter={16}>
          <Col style={{ marginTop: 12 }} xs={24} sm={12} md={8} lg={8} xl={8}>
            <Input.Search
              placeholder="Push Notification Title"
              enterButton="Search"
              onSearch={searchResults}
              value={search}
              loading={loading}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col style={{ marginTop: 12 }} xs={6} sm={5} md={5} lg={4} xl={4}>
            <Button
              style={{ width: "100%" }}
              onClick={resetFilter}
              disabled={loading}
            >
              Reset Filter
            </Button>
          </Col>
        </Row>
        <div style={{ paddingTop: "16px" }}>
          <Table
            dataSource={useDemoData ? DEMO_HISTORY : values}
            rowKey="gmtCreate"
            columns={tableColumns()}
            size="small"
            loading={loading}
            scroll={{ x: 700 }}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default Users;
