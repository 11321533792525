import React, { useState, useEffect } from "react";
import classes from "./AppDocuments.module.less";
import { Row, Table, Spin, Button } from "antd";
import moment from "moment";
import AttachmentCard from "./AttachmentCard/AttachmentCard";
import NewClientAttachment from "./NewClientAttachment/NewClientAttachment";
import { getAppDocuments } from "../../../../services/distributionService";
import NewFeature from "../../../UI/NewFeature/AsyncNewFeature";
export default ({ appId, isOwner }) => {
  let [loading, setLoading] = useState(true);
  let [visible, setVisible] = useState(false);
  let [information, setInformation] = useState({});
  let [docs, setDocuments] = useState([]);

  const getData = async () => {
    if (appId) {
      let { data } = await getAppDocuments(appId);
      if (data && data.docs) {
        setDocuments(data.docs);
      }
      setLoading(false);
    }
  };
  let columns = [
    {
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (a.createdAt > b.createdAt) return -1;
        else if (a.createdAt < b.createdAt) return 1;
        return 0;
      },
      key: "createdAt",
      dataIndex: "createdAt",
      title: "Date",
      render: (date, item) => (
        <NewFeature
          show={item.viewed === false}
          category="app_documents"
          id={item.PK_App_Doc}
        >
          {moment(date).format("YYYY-MM-DD")}
        </NewFeature>
      ),
    },
    { key: "uploader", dataIndex: "uploader", title: "Uploader" },
    {
      key: "description",
      dataIndex: "description",
      title: "Description",
    },
    {
      key: "PK_App_Doc",
      dataIndex: "PK_App_Doc",
      title: "",
      render: (id, { type }) => (
        <Button
          icon="plus"
          type="primary"
          ghost
          disabled={!isOwner}
          onClick={() => {
            setInformation({ id, type });
            setVisible(true);
          }}
        >
          Add Attachment
        </Button>
      ),
    },
  ];
  useEffect(() => {
    getData();
  }, [appId]);

  const { AUTHORIZATION = [], ...otherDocs } = docs;

  const updateClientsAttachments = (newAttachments) => {
    const stateCopy = docs[information.type];
    const index = stateCopy.findIndex(
      (item) => item.PK_App_Doc === information.id
    );
    stateCopy[index] = {
      ...stateCopy[index],
      app_documents_attachments_clients: [
        ...stateCopy[index].app_documents_attachments_clients,
        ...newAttachments,
      ],
    };
    setDocuments({
      ...docs,
      [information.type]: stateCopy,
    });
  };

  return loading ? (
    <Spin />
  ) : (
    <div className={classes.container}>
      <NewClientAttachment
        visible={visible}
        setVisible={setVisible}
        update={updateClientsAttachments}
        information={information}
      />
      <Row
        style={{ marginTop: 10 }}
        className={classes.header}
        type="flex"
        justify="start"
      >
        <h3>Authorization</h3>
      </Row>
      <Table
        rowKey={(record) => record.PK_App_Doc}
        loading={loading}
        scroll={{ x: 700 }}
        pagination={AUTHORIZATION.length > 15 ? { pageSize: 15 } : false}
        columns={columns}
        expandedRowRender={(record) => (
          <div style={{ margin: 0 }}>
            <AttachmentCard
              data={{
                manager_attachments: record.app_documents_attachments,
                client_attachments: record.app_documents_attachments_clients,
              }}
            />
          </div>
        )}
        dataSource={AUTHORIZATION}
        size="small"
      />
      {otherDocs
        ? Object.entries(otherDocs).map(([title, categoryDocs]) => (
            <>
              <Row
                style={{ marginTop: 10 }}
                className={classes.header}
                type="flex"
                justify="start"
              >
                <h3>{title.toLowerCase()}</h3>
              </Row>
              <Table
                scroll={{ x: 700 }}
                loading={loading}
                rowKey={(record) => record.PK_App_Doc}
                pagination={categoryDocs.length > 15 ? { pageSize: 15 } : false}
                expandedRowRender={(record) => (
                  <div style={{ margin: 0 }}>
                    <AttachmentCard
                      data={{
                        manager_attachments: record.app_documents_attachments,
                        client_attachments:
                          record.app_documents_attachments_clients,
                      }}
                    />
                  </div>
                )}
                columns={columns}
                dataSource={categoryDocs}
                size="small"
              />
            </>
          ))
        : null}
    </div>
  );
};
