import React, { useState, Fragment } from "react";
import { Table, Tag, Button, Popover, Icon, Checkbox } from "antd";
import moment from "moment";
import functions from "../../../../../functions";
import NewFeature from "../../../../UI/NewFeature/AsyncNewFeature";
import classes from "./HistoryTable.module.less";
export default ({ data }) => {
  let columns = [
    {
      dataIndex: "ranking",
      title: "",
      key: "ranking",
      fixed: "left",
      width: "40px",
      render: (_, item) => item.store.real_ranking,
    },
    {
      dataIndex: "distribution_status",
      key: "distribution_status",
      title: "Distribution Status",
      render: (distribution_status) =>
        distribution_status ? STATUS[distribution_status] : "",
    },
    {
      dataIndex: "version",
      title: "Live Version",
      key: "version",
    },
    {
      dataIndex: "version_being_submitted",
      title: "Submitted Version",
      key: "version_being_submitted",
    },
    {
      dataIndex: "downloads",
      title: "Downloads",
      render: (downloads, item) => getbackDownloads(downloads),
    },
    {
      dataIndex: "link",
      key: "link",
      title: "Link",
      render: (link, item) => getLink(link, item.distribution_status),
    },
  ];
  let columnsHistory = [
    {
      key: "user",
      dataIndex: "user",
      title: "Uploader",
      render: (user, item) => (
        <NewFeature
          show={item.viewed === false}
          category="distribution_history"
          id={item.id}
        >
          {user}
        </NewFeature>
      ),
    },
    {
      key: "client_notes",
      dataIndex: "client_notes",
      title: "Description",
      render: (client_notes, item) =>
        client_notes ? client_notes : item.description,
    },
    {
      key: "status",
      dataIndex: "status",
      title: "Status",
      render: (distribution_status) =>
        distribution_status ? STATUS[distribution_status] : "",
    },
    {
      key: "date",
      dataIndex: "date",
      title: "Date",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm"),
    },
  ];

  let columnsAttachments = [
    {
      dataIndex: "file_name",
      key: "file_name",
      title: "File Name",
    },
    {
      dataIndex: "file_size",
      key: "file_size",
      title: "File Size (bytes)",
    },
    {
      dataIndex: "url",
      key: "url",
      title: "",
      render: (url, item) =>
        url ? (
          <NewFeature
            show={item.viewed === false}
            id={item.PK_History_Attachments}
            category="history_attachments"
          >
            <a
              rel="noopener noreferrer"
              href={`https://appinchina.xyz/download/${url}`}
              target="_blank"
            >
              <Button icon="cloud-download">Download</Button>
            </a>
          </NewFeature>
        ) : null,
    },
  ];
  const getbackDownloads = (downloads) => {
    let back =
      !!downloads &&
      downloads
        .filter((e) => e.source !== "AICON")
        .filter((e) => e.downloads !== null)
        .map((e) => {
          return e.downloads;
        });

    return back.length > 0
      ? functions.styleAsNumber(back[back.length - 1])
      : "-";
  };
  const getLink = (link, distribution_status) => {
    return link ? (
      <a rel="noopener noreferrer" href={link} target="_blank">
        View In Store
      </a>
    ) : distribution_status === "PUBLISHED" ? (
      <Popover
        placement="top"
        content={
          <span className={classes.problemText}>
            This store can only be accessed through the store's mobile app.
          </span>
        }
      >
        <Tag color="grey">
          Mobile Only <Icon type="info-circle" />
        </Tag>
      </Popover>
    ) : null;
  };
  const STATUS = {
    NOT_SUBMITTED: <Tag color="gold">Not Submitted</Tag>,
    SUBMITTED_TO_STORE: <Tag color="cyan">Submitted to Store</Tag>,
    PUBLISHED: <Tag color="green">Published</Tag>,
    REJECTED: <Tag color="blue">Rejected</Tag>,
    RECLAIM: <Tag color="magenta">Reclaim</Tag>,
    PAUSED: <Tag color="purple">Paused</Tag>,
    SUSPENDED: <Tag color="volcano">Suspended</Tag>,
    REMOVED: <Tag color="red">Removed</Tag>,
  };

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ x: 700 }}
      />
      <div>
        <p />
        <Fragment>
          <p>
            <b>Distribution History:</b>
          </p>
          <Table
            rowKey={(record) => record.id}
            dataSource={(data[0] && data[0].distribution_histories) || []}
            columns={columnsHistory}
            size="small"
            pagination={
              data[0].distribution_histories.length > 5
                ? { pageSize: 5 }
                : false
            }
            scroll={{ x: 700 }}
            expandedRowRender={(record) => {
              return (
                <div>
                  <h3>Attachments</h3>
                  <Table
                    size="small"
                    dataSource={record.history_attachments}
                    pagination={
                      record.history_attachments > 5 ? { pageSize: 5 } : false
                    }
                    columns={columnsAttachments}
                  />
                </div>
              );
            }}
          />
        </Fragment>
      </div>
    </div>
  );
};
