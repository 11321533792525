import React from "react";
import classes from "./BigLoader.module.css";
import { Spin, Icon } from "antd";

const bigLoader = props => {
  let displayed = props.loading ? classes.Displayed : null;

  return (
    <div className={[classes.Container, displayed].join(" ")}>
      <div>
        <div className={classes.Preloader}>
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 30 }} spin />}
          />
        </div>
        <h4>Retrieving Information...</h4>
      </div>
    </div>
  );
};

export default bigLoader;
