import React, { useEffect, useState } from "react";
import classes from "./RenewalsDetails.module.less";
import RefundDialog from "../../RefundsHistory/RefundDialog/RefundDialog";
import { Tag, Icon, PageHeader, Descriptions, Button, Table, Spin, List } from "antd";
import { transactions_table_fake, renewal_info_fake } from "../../FakeData";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import { getRenewalDetails } from "../../../../services/aicPay";
import CancelModal from "./RenewalModalCancel/RenewalModalCancel";

const getTag = {
    "11": <Tag color="volcano">PENDING</Tag>,
    "21": <Tag color="green">PAID</Tag>,
    "31": <Tag color="red">PAYMENT FAILED</Tag>,
    "41": <Tag color="blue">ORDER CLOSED</Tag>,
    "51": <Tag color="purple">REFUNDED</Tag>,
};
const getRenewalTag = {
    error: <Tag color="purple">ERROR</Tag>,
    active: <Tag color="green">ACTIVE</Tag>,
    cancelled: <Tag color="volcano">CANCELLED</Tag>,
};
const RenewalsDetails = ({ app, ...props }) => {
    const useDemoData = app
        ? app.alipay_id != null || app.wechat_id != null
        : props.appId === "1234-8475-9439-9494" ||
        props.appId === "1234-8475-9439-9495";
    const renewalId = props.match.params.id;
    const [transactions, setTransactions] = useState([]);
    const [renewalInfo, setRenewalInfo] = useState({
        user: "",
        status: "",
        comments: "",
        renewal_date: "",
        cancelled_date: "",
        renewal_period: ""
    });
    const [userInfo, setInfo] = useState([]);
    const [showRefund, setShowRefund] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    const [cancelDisabled, setCancelDisabled] = useState(false);
    const transactionsColumns = [
        {
            title: "Transaction ID",
            dataIndex: "transaction_id",
            key: "transaction_id",
            fixed: "left",
            width: 150,
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            sorter: (a, b) => {
                if (a.date > b.date) return -1;
                else if (a.date < b.date) return 1;
                return 0;
            },
        },
        {
            title: "Amount",
            dataIndex: "amount",
            className: classes.alignRight,
            key: "amount",
            sorter: true,
            render: (total) => <span>¥{total / 100}</span>,
        },
        {
            title: "Details",
            dataIndex: "goods_title",
            key: "goods_title",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => getTag[status],
        },
        {
            title: "Biz No",
            dataIndex: "biz_no",
            key: "biz_no",
            align: "center",
            render: (biz_no) =>
                biz_no ? (
                    <Ellipsis length={15} tooltip>
                        {biz_no}
                    </Ellipsis>
                ) : (
                    "-"
                ),
        },
        {
            title: "",
            dataIndex: "refund",
            key: "refund",
            render: (refund, item) =>
                item.status == "21" && item.biz_no ? (
                    <a onClick={() => showRefundInfo(item)}>Refund</a>
                ) : (
                    <Tag color="grey">Not refundable</Tag>
                ),
        },
    ];
    const updateAfterRefund = (id) => {
        const newTransactions = transactions.map(
            ({ transaction_id, status, ...rest }) => ({
                ...rest,
                transaction_id,
                status: transaction_id == id ? "51" : status,
            })
        );
        setTransactions(newTransactions);
        searchRenewalById();
    };
    const showRefundInfo = (info) => {
        setVisible(true);
        setInfo(info);
        setShowRefund(true);
    };
    const searchRenewalById = async () => {
        if (useDemoData) {
            setLoading(true);
            if (app) {
                const paymentsId = [
                    app.alipay_id || "",
                    app.wechat_id || "",
                    app.seccond_alipay_id || "",
                    app.seccond_wechat_id || "",
                ].join(",");
                let results = await getRenewalDetails(renewalId, paymentsId, app);
                if (results && results.transactions) {
                    setTransactions(results.transactions);
                    setRenewalInfo(results.renewalInfo);
                    const disabled = results.renewalInfo && results.renewalInfo.status && results.renewalInfo.status === "cancelled" ? true : false;
                    setDisplayCancel(disabled)
                }
            }
            setLoading(false);
        }
    };
    useEffect(() => {
        searchRenewalById();
    }, [app, app.id]);
    return (
        <div className={classes.container}>
            <Spin spinning={loading}>
                <PageHeader
                    loading={loading}
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                    }}
                    subTitle="Renewal ID"
                    tags={<Tag>{renewalId}</Tag>}
                    ghost={false}
                    backIcon={<Icon type="arrow-left" />}
                    onBack={() => window.history.go(-2)}
                    extra={[
                        <Button disabled={cancelDisabled} onClick={() => setDisplayCancel(true)} ghost type="danger">
                            Cancel Renew Agreement</Button>,
                    ]}
                >
                    <Descriptions>
                        <Descriptions.Item label="User Name">
                            {!useDemoData ? renewal_info_fake.user_name : renewalInfo.user_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Phone Number">
                            {!useDemoData ? renewal_info_fake.phone_number : renewalInfo.phone_number}
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                            {!useDemoData
                                ? getRenewalTag[renewal_info_fake.status]
                                : getRenewalTag[renewalInfo.status]}
                        </Descriptions.Item>
                        <Descriptions.Item label="Renewal Date">
                            {!useDemoData
                                ? renewal_info_fake.renewal_date
                                : renewalInfo.renewal_date}
                        </Descriptions.Item>
                        <Descriptions.Item label="Renewal Period">
                            {!useDemoData
                                ? renewal_info_fake.renewal_period
                                : renewalInfo.renewal_period}
                        </Descriptions.Item>
                        <Descriptions.Item label="Cancelled Date">
                            {!useDemoData
                                ? renewal_info_fake.cancelled_date
                                : renewalInfo.cancelled_date}
                        </Descriptions.Item>
                    </Descriptions>
                </PageHeader>{" "}
            </Spin>
            {transactions && transactions.length && transactions.length > 0 || !useDemoData ?
                <Table
                    title={() => "Transactions"}
                    dataSource={!useDemoData ? transactions_table_fake : transactions}
                    rowKey={(record) => record.transaction_id}
                    columns={transactionsColumns}
                    size="small"
                    loading={loading}
                    scroll={{ x: 800 }}
                /> : <List loading={loading} dataSource={[]} />}
            {!showRefund ? null : (
                <RefundDialog
                    info={userInfo}
                    show={visible}
                    setVisible={setVisible}
                    updateAfterRefund={updateAfterRefund}
                    app={app}
                    useDemoData={!useDemoData}
                />
            )}
            <CancelModal
                app={app}
                renewalId={renewalId}
                updateRenewal={(comments) => setRenewalInfo({
                    ...renewalInfo,
                    comments,
                    status: "cancelled"
                })}
                renewalInfo={!useDemoData ? renewal_info_fake : renewalInfo}
                useDemoData={!useDemoData}
                visible={displayCancel}
                close={() => setDisplayCancel(false)}
            />
        </div>
    );
};
export default RenewalsDetails;
