import React, { Component, Fragment } from "react";
import AppFormHome from "./AppFormHome/AppFormHome";
import SecurityAssessmentForm from "./SecurityAssessmentForm/SecurityAssessmentForm";
import CompanyForm from "./CompanyForm/CompanyForm";
import CopyRightFormRegular from "./CopyrightForm/CopyrightFormRegular";
import CopyrightFormCorporate from "./CopyrightForm/CopyrightFormCorporate";
import classes from "./Distribution.module.less";
import AuthorizationLetter from "./AuthorizationLetter/AuthorizationLeter";
import { getForm } from "../../services/distributionService";
import DistributionDetails from "./DistributionDetails/NewDistributionDetails";
import { Spin, Alert } from "antd";
import history from "../../history";

class Distribution extends Component {
  state = {
    app_form: null,
    loading: true,
    corporate_client: false,
  };

  componentDidUpdate(prevProps) {
    let { app_id } = this.props.match.params;
    let oldAppId = prevProps.match.params.app_id;
    if (
      (!prevProps.apps && this.props.apps && this.props.apps.length) ||
      app_id !== oldAppId
    ) {
      this.handleApps();
    }
  }

  handleApps = () => {
    let { app_id } = this.props.match.params;
    //let { redirect } = this.props;

    if (app_id) {
      let app =
        this.props.apps && this.props.apps.find((app) => app.id === app_id);
      this.setState(
        {
          app,
          loading: true,
        },
        app ? () => this.getData(app_id) : () => history.push("/new")
      );
    } else {
      history.push("/new");
    }
  };

  componentDidMount() {
    if (this.props.apps) this.handleApps();
    if (window.mixpanel) window.mixpanel.track("Distribution loaded");
  }

  getData = async (id) => {
    const { app_store_accounts } = this.state.app.main_information;
    const corporate_client =
      app_store_accounts === "CLIENT_CC" || app_store_accounts === "CLIENT_OC";
    this.setState({
      loading: false,
      corporate_client,
    });
    // Promise.all([getForm("app-form", id)]).then((values) => {
    //   let [app_form] = values;
    //   this.setState({
    //     app_form: !app_form.data.error ? app_form.data : [],
    //     loading: false,
    //     corporate_client,
    //   });
    // });
  };

  getContent = (section, props) => {
    let { app_id } = this.props.match.params;
    const tab = this.props.tab || this.props.match.params.tab;
    const { corporate_client, app } = this.state;
    const copyright = corporate_client
      ? CopyrightFormCorporate
      : CopyRightFormRegular;
    const Copyright = copyright;
    switch (section) {
      case "distribution-status":
        return <DistributionDetails role={app.role} app={app} />;
      case "company-form":
        return <CompanyForm role={app.role} />;
      case "app-information":
        return (
          <div className={classes.wrapper}>
            <AppFormHome appId={app_id} app={app} tab={tab} />
          </div>
        );
      case "copyright-certificate":
        return <Copyright role={app.role} />;
      case "security-assesment":
        return <SecurityAssessmentForm role={app.role} />;
      case "authorization-letter":
        return <AuthorizationLetter app={app} appId={app_id} />;
      default:
        return (
          <div className={classes.wrapper}>
            <AppFormHome appId={app_id} app={app} tab={tab} />{" "}
          </div>
        );
    }
  };

  render() {
    const { loading } = this.state;
    const section = this.props.section || this.props.match.params.section;
    return (
      <Fragment>
        {loading ? (
          <Spin
            tip="Loading..."
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <>
            {this.getNoPaymentWarning()}
            {this.getContent(section, this.props)}
          </>
        )}
      </Fragment>
    );
  }

  callback = (key) => {
    this.props.history.push(`/distribution/${this.state.app.id}/${key}`);
  };

  getNoPaymentWarning = () => {
    return !this.state.app.authorized ? (
      <div className={classes.errorMessage}>
        <Alert
          message="Please note that distribution of your app will not start until payment has been received."
          type="error"
        />
      </div>
    ) : null;
  };
}

export default Distribution;
