import React from "react";
import { Row, Button, Select, List, Avatar, Popover } from "antd";
import classes from "./UserCard.module.less";
import { ROLES } from "../../../constants";
const { Option } = Select;

const UserCard = ({
  isOwner,
  users,
  appname,
  appId,
  showConfirmUpdateRol,
  showConfirmDeleteAccess
}) => {
  let appIDs = isOwner.length > 0 ? isOwner.map(({ id }) => id) : [];
  let disabled = !appIDs.includes(appId);
  //console.log("disabled", appIDs, disabled, appId);
  return (
    <div className={classes.container}>
      <List
        dataSource={users}
        renderItem={item => (
          <List.Item key={item.id}>
            <List.Item.Meta
              avatar={
                localStorage.imageLocation &&
                localStorage.email &&
                localStorage.email === item.email ? (
                  <Avatar src={localStorage.imageLocation} />
                ) : (
                  <Avatar icon="user" />
                )
              }
              title={<a href="https://ant.design">{item.name}</a>}
              description={item.email}
            />

            <Row type="flex" justify="space-between" style={{ width: "50%" }}>
              <Select
                disabled={disabled}
                onChange={val =>
                  showConfirmUpdateRol(val, item, appname, appId)
                }
                value={item.role}
                style={{ width: 200 }}
              >
                {ROLES.map(({ value, label }) => (
                  <Option key={value} value={value} label={label}>
                    Role: {label}
                  </Option>
                ))}
              </Select>

              <Popover content="Remove Access">
                <Button
                  size="small"
                  disabled={disabled}
                  icon="delete"
                  type="danger"
                  ghost
                  onClick={() =>
                    showConfirmDeleteAccess(item.email, appname, {
                      user: item.id,
                      app: appId
                    })
                  }
                />
              </Popover>
            </Row>
          </List.Item>
        )}
      ></List>
    </div>
  );
};
export default UserCard;
