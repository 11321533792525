import React, { useEffect, useState, Fragment } from "react";
import classes from "./Summary.module.less";
import { Button, DatePicker, Row, Col, Spin } from "antd";
import PieChart from "./PieChart";
import TransactionsChart from "./TransactionsChart";
import CardOverview from "./CardOverview/CardOverview";
import AutoRenewalsStats from "./AutoRenewalsStats/AutoRenewalsStats";
import UsersChart from "./UsersChart";
import TransactionsByAppStore from "./TransactionsByAppStore";
import moment from "moment";
import LineCard from "../../UI/LineCard/LineCard";

import {
  revenueInfo,
  pie_chart,
  transactions_fake,
  users_fake,
} from "../FakeData";
import { getAppAnalytics } from "../../../services/aicPay";
const { RangePicker } = DatePicker;
export default ({ app, useDemoData }) => {
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [balance, setBalance] = useState(null);
  const [transactionsChart, setTransactionsChart] = useState(null);
  const [transactionsByStore, setTransactionsByStore] = useState(null);
  const [pieChart, setPieChart] = useState(null);
  const [usersChart, setUsersChart] = useState(null);
  const [loading, setLoading] = useState(true);
  const defaultDate = {
    start: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };

  useEffect(() => {
    fetch(defaultDate.start, defaultDate.end);
  }, [app.id, useDemoData]);

  const fetch = async (start, end) => {
    if (!useDemoData)
      try {
        if (app) {
          const paymentsId = [
            app.alipay_id || "",
            app.wechat_id || "",
            app.seccond_alipay_id || "",
            app.seccond_wechat_id || "",
          ].join(",");
          setLoading(true);
          const data = await getAppAnalytics({
            app,
            start: start,
            end: end,
            id: paymentsId,
          });
          if (data) {
            const {
              activeAutoRenewals,
              newAutoRenewals,
              cancelledRenewals,
              autoRenewalsAmount,
              transactions,
              users,
              usersByUserName,
              generalOverview,
              alipayOverview,
              wechatOverview
            } = data;
            setBalance({
              activeAutoRenewals,
              newAutoRenewals,
              cancelledRenewals,
              autoRenewalsAmount,
              generalOverview,
              alipayOverview,
              wechatOverview
            });
            const reducedData = transformData(transactions);
            const transactionsStore = getTransactionsByStore(transactions);
            const storesKeys = getStores(transactionsStore);
            const range = getRange(transactionsStore);
            let fill =
              storesKeys.length > 1
                ? fillTransactions(range, storesKeys, transactionsStore)
                : transactionsStore;
            const usersChart = mergeUsersChart(usersByUserName, users)
            setTransactionsByStore(fill);
            setPieChart(transformPieChartData(reducedData));
            setTransactionsChart(transformLinearChartData(reducedData));
            setUsersChart(usersChart);
            setLoading(false);
          }
        }

      } catch (error) {
        console.log(error);
      }
  };

  const mergeUsersChart = (userName, phoneNumber) => {
    const registered = phoneNumber.map((item) => ({
      ...item,
      type: 'Phone Number'
    }))
    const unregistered = userName.map((item) => ({
      ...item,
      type: 'User Name'
    }))
    return [
      ...registered,
      ...unregistered
    ]
  }

  const getStores = (data) => {
    let array = [];
    data.map(({ store }) => {
      if (array.indexOf(store) === -1) {
        array.push(store);
      }
    });
    return array;
  };
  const getRange = (data) => {
    let array = [];
    data.map(({ date }) => {
      if (array.indexOf(date) === -1) {
        array.push(date);
      }
    });
    return array;
  };
  const fillTransactions = (range, stores, data) => {
    let arr = [];
    range.map((it) => {
      stores.forEach((name) => {
        let val = data.filter(
          ({ store, date }) => name === store && it === date
        );
        val.length > 0
          ? arr.push(val[0])
          : arr.push({ date: it, store: name, transactions_by_day: 0 });
      });
    });
    return arr;
  };
  const transformData = (data) => {
    return data.map(({ source, revenue_by_day, ...item }) => ({
      source:
        source == app.alipay_id || source == app.seccond_alipay_id
          ? "Alipay"
          : "WeChat",
      revenue_by_day: revenue_by_day / 100 || 0,
      ...item,
    }));
  };
  const getTransactionsByStore = (data) => {
    let storeTransactions = data.map(
      ({ store, transactions_by_day, ...item }) => ({
        store: store !== null ? store : "Not Set",
        transactions_by_day,
        ...item,
      })
    );
    let reducedStoreTransactions = transformStoreLinearChartData(
      storeTransactions
    );
    return reducedStoreTransactions;
  };

  const onChangeDate = (_, [start, end]) => {
    fetch(start, end);
    setStartDate(start);
    setEndDate(end);
  };

  const transformPieChartData = (data) => {
    return data.reduce(
      (prev, curr) => {
        return {
          ...prev,
          wechat:
            curr.pay_channel == "01"
              ? prev.wechat + curr.transactions_by_day
              : prev.wechat,
          alipay:
            curr.pay_channel == "11"
              ? prev.alipay + curr.transactions_by_day
              : prev.alipay,
        };
      },
      {
        wechat: 0,
        alipay: 0,
      }
    );
  };

  const transformLinearChartData = (data) => {
    let result = [];
    data.forEach((it) => {
      let elementIndex = result.findIndex(
        (resultElement) => it.date === resultElement.date
      );
      if (elementIndex >= 0) {
        result[elementIndex].revenue_by_day =
          it.revenue_by_day + result[elementIndex].revenue_by_day;
        result[elementIndex].transactions_by_day =
          it.transactions_by_day + result[elementIndex].transactions_by_day;
      } else {
        result.push(it);
      }
    });
    return result;
  };

  const transformStoreLinearChartData = (data) => {
    let result = [];
    data.forEach((it) => {
      let elementIndex = result.findIndex(
        (resultElement) =>
          it.date === resultElement.date && it.store === resultElement.store
      );
      if (elementIndex >= 0) {
        result[elementIndex].revenue_by_day =
          it.revenue_by_day + result[elementIndex].revenue_by_day;
        result[elementIndex].transactions_by_day =
          it.transactions_by_day + result[elementIndex].transactions_by_day;
      } else {
        result.push(it);
      }
    });
    return result;
  };

  const resetFilter = () => {
    setStartDate(defaultDate.start);
    setEndDate(defaultDate.end);
    fetch(defaultDate.start, defaultDate.end);
  };

  return (
    <div className={classes.tableContainer}>
      <Row className={classes.rangePicker} gutter={[16, 16]} type="flex" align="top">
        <Col xs={24} sm={12} md={12} lg={12} xl={9}>
          <RangePicker
            value={
              start_date != "" && end_date != ""
                ? [moment(start_date), moment(end_date)]
                : [moment().startOf("month"), moment()]
            }
            style={{ width: "100%", marginBottom: 20 }}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
            disabled={loading && !useDemoData}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={3}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={resetFilter}
            loading={loading && !useDemoData}
          >
            Reset
          </Button>
        </Col>
      </Row>
      {loading && !useDemoData ? (
        <Spin />
      ) : (
        <Fragment>
          <div className={classes.gridTwo}>
              <CardOverview title="WeChat Overview" balance={useDemoData ? revenueInfo.wechatOverview : balance.wechatOverview} />
              <CardOverview title="Alipay Overview" balance={useDemoData ? revenueInfo.alipayOverview : balance.alipayOverview} />
          </div>
          <div className={classes.gridTwo}>
            <CardOverview title="General Overview" balance={useDemoData ? revenueInfo.generalOverview : balance.generalOverview} />
            <AutoRenewalsStats balance={useDemoData ? revenueInfo : balance} />
          </div>
          <div>
            <LineCard title="Transactions per day">
              <div className={classes.Charts}>
                {pieChart ? (
                  <PieChart
                    wechat={useDemoData ? pie_chart.wechat : pieChart.wechat}
                    alipay={useDemoData ? pie_chart.alipay : pieChart.alipay}
                  />
                ) : null}
                {transactionsChart || useDemoData ? (
                  <TransactionsChart
                    data={
                      useDemoData
                        ? transformLinearChartData(transactions_fake)
                        : transactionsChart
                    }
                  />
                ) : null}
              </div>
            </LineCard>
          </div>
          <div>
            <LineCard title="Transactions by App Store">
              {transactionsByStore || useDemoData ? (
                <TransactionsByAppStore
                  dataView={
                    useDemoData ? transactions_fake : transactionsByStore
                  }
                />
              ) : null}
            </LineCard>
          </div>
          <div>
            <LineCard title="New Users per day">
              {usersChart || useDemoData ? (
                <UsersChart data={useDemoData ? users_fake : usersChart} />
              ) : null}
            </LineCard>
          </div>
        </Fragment>
      )}
    </div>
  );
};
