import React, { useState } from "react";
import { Button, Table, Popover, Tag, Icon, Row, Tooltip } from "antd";
import classes from "./DistributionDetailsTable.module.less";
import LoadingSpin from "../../../UI/LoadingSpin/LoadingSpin";
import functions from "../../../../functions";
import DistributionDetailsCard from "./DistributionDetailsCard/DistributionDetailsCard";
import NewFeature from "../../../UI/NewFeature/AsyncNewFeature";
const AppDistribution = ({
  data = [],
  corporate,
  loadingApp,
  updateHistory,
  disabled,
}) => {
  const [display, setDisplay] = useState(false);
  const [store, setStore] = useState([]);

  const getLinkProps = () =>
    window.outerWidth > 600 ? { fixed: "right" } : {};

  let columns = [
    {
      dataIndex: "ranking",
      title: "",
      key: "ranking",
      fixed: "left",
      width: "40px",
      render: (_, item) =>
        item.store.real_ranking < 20 ? item.store.real_ranking : "-",
    },
    {
      dataIndex: "store_name",
      title: (
        <Tooltip
          placement="bottom"
          title="Click on store name to view detailed distribution status"
        >
          Store Name{" "}
          <Icon type="question-circle-o" style={{ fonSize: "12px" }} />
        </Tooltip>
      ),
      key: "store_name",
      width: "220px",
      render: (_, item) => (
        <div>
          <NewFeature
            show={item.viewed === false}
            id={item.id}
            category="information"
          >
            <Button
              type="link"
              onClick={() => {
                setStore([{ ...item }]);
                setDisplay(true);
              }}
              style={{ fontWeight: "500" }}
            >
              {item.store.name}
              <Icon type="link" style={{ color: "#aaaaaa", fonSize: "12px" }} />
            </Button>
          </NewFeature>
        </div>
      ),
    },
    {
      dataIndex: "distribution_status",
      key: "distribution_status",
      title: "Status",
      render: (distribution_status) =>
        distribution_status ? STATUS[distribution_status] : "",
    },
    {
      dataIndex: "version",
      title: "Live Version",
      key: "version",
    },
    {
      dataIndex: "version_being_submitted",
      title: "Submitted Version",
      key: "version_being_submitted",
    },
    {
      dataIndex: "downloads",
      title: "Downloads",
      align: "right",
      render: (downloads, item) =>
        downloads.length && downloads[0].totalDownloads
          ? functions.styleAsNumber(downloads[0].totalDownloads)
          : 0,
    },
    {
      dataIndex: "link",
      key: "link",
      title: "Link",
      ...getLinkProps(),
      // width: "100px",
      render: (link, item) => getLink(link, item.distribution_status),
    },
  ];
  const getLink = (link, distribution_status) => {
    return link ? (
      <a rel="noopener noreferrer" href={link} target="_blank">
        View In Store
      </a>
    ) : distribution_status === "PUBLISHED" ? (
      <Popover
        placement="top"
        content={
          <span className={classes.problemText}>
            This store can only be accessed through the store's mobile app.
          </span>
        }
      >
        <Tag color="grey">
          Mobile Only <Icon type="info-circle" />
        </Tag>
      </Popover>
    ) : null;
  };

  const STATUS = {
    NOT_SUBMITTED: <Tag color="gold">Not Submitted</Tag>,
    SUBMITTED_TO_STORE: <Tag color="cyan">Submitted to Store</Tag>,
    PUBLISHED: <Tag color="green">Published</Tag>,
    REJECTED: <Tag color="blue">Rejected</Tag>,
    RECLAIM: <Tag color="magenta">Reclaim</Tag>,
    PAUSED: <Tag color="purple">Paused</Tag>,
    SUSPENDED: <Tag color="volcano">Suspended</Tag>,
    REMOVED: <Tag color="red">Removed</Tag>,
  };

  let mainStores = data.slice(0, 20).map((it, index) => ({ ...it, index }));

  let otherStores = data
    .slice(20)
    .filter(
      (it) =>
        it.distribution_status && it.distribution_status !== "NOT_SUBMITTED"
    );

  return loadingApp ? (
    <LoadingSpin />
  ) : (
    <div className={classes.container}>
      {!display ? (
        <Table
          size="small"
          columns={columns}
          dataSource={[...mainStores, ...otherStores].filter(
            (it) => !it.hidden_clients
          )}
          pagination={false}
          scroll={{ x: 800 }}
        />
      ) : (
        <>
          <Row>
            <Button
              style={{ margin: "0px 10px 0 0", background: "white" }}
              type="ghost"
              onClick={() => setDisplay(false)}
            >
              <Icon type="left" />
              Go back
            </Button>
          </Row>
          <DistributionDetailsCard
            data={store}
            updateStore={setStore}
            corporate={corporate}
            disabled={disabled}
            updateHistory={updateHistory}
          />
        </>
      )}
    </div>
  );
};

export default AppDistribution;
