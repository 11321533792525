import React, { useState, useEffect } from "react";
import MultiLineChart from "../../../UI/Charts/MultiLineChart";
import { Tabs, Card, DatePicker, Row, Col, Select } from "antd";
import { getDailyDate } from "../../../../functions";
import { dataComparissonPerHourFake } from "../../PromotionFakeData";
import moment from "moment";
import { getSummaryPerHour } from "../../../../services/promotionService";
const { TabPane } = Tabs;
const { Option } = Select;

export default ({ app, useDemoData }) => {
  const [date, setDate] = useState(getDailyDate());
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState({});
  const [storeSelected, setStore] = useState(null);
  const [stores, setStores] = useState([]);
  const [tab, activeTab] = useState("1");

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getSummaryPerHour({
      promotionId: app.analitycs_id,
      app: app.id,
      first: date.start,
      second: date.end,
      store: storeSelected
    });
    setData(response);
    setStores(response.stores);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData(dataComparissonPerHourFake(date.start, date.end));
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    validateRequest();
  }, [app]);
  useEffect(() => {
    validateRequest();
  }, [date]);
  return (
    <Card title="Data comparison per hour" style={{ marginTop: 15 }}>
      <Row gutter={16}>
        <Col
          xs={{ span: 12 }}
          md={{ span: 6 }}
          xl={{ span: 5 }}
          style={{ marginBottom: 15 }}
        >
          <DatePicker
            format="YYYY-MM-DD"
            onChange={(_, string) => {
              setDate({
                start: moment(string).format("YYYY-MM-DD"),
                end: moment(string)
                  .add(1, "days")
                  .format("YYYY-MM-DD"),
              });
            }}
            value={moment(date.start, "YYYY-MM-DD")}
            style={{ width: "100%" }}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          md={{ span: 6 }}
          xl={{ span: 5 }}
          style={{ marginBottom: 15 }}
        >
          <DatePicker
            format="YYYY-MM-DD"
            onChange={(_, string) =>
              setDate({
                start: moment(string)
                  .subtract(1, "days")
                  .format("YYYY-MM-DD"),
                end: moment(string).format("YYYY-MM-DD"),
              })
            }
            value={moment(date.end, "YYYY-MM-DD")}
            style={{ width: "100%" }}
          />
        </Col>
        {tab == "1" || tab == "2" ? (
          <Col
            xs={{ span: 12 }}
            md={{ span: 6 }}
            xl={{ span: 5 }}
            style={{ marginBottom: 15 }}
          >
            <Select
              disabled={loading}
              loading={loading}
              value={storeSelected}
              onChange={(val) => setStore(val)}
              style={{ width: "100%" }}
              placeholder="App Store"
            >
              {stores.map((it) => (
                <Option value={it.value}>{it.label}</Option>
              ))}
            </Select>
          </Col>
        ) : null}
      </Row>
      <Tabs
        type="card"
        defaultActiveKey={tab}
        onChange={(tab) => activeTab(tab)}
        style={{ width: "100%", marginTop: 15 }}
      >
        <TabPane disabled={loading} tab="Active Users" key="1">
          <MultiLineChart loading={loading} data={data.recurringUsers} />
        </TabPane>
        <TabPane disabled={loading} tab="New Activations" key="2">
          <MultiLineChart loading={loading} data={data.activations} />
        </TabPane>
        <TabPane disabled={loading} tab="New Registrations" key="3">
          <MultiLineChart loading={loading} data={data.registrations} />
        </TabPane>
        <TabPane disabled={loading} tab="Total Paid" key="4">
          <MultiLineChart loading={loading} data={data.totalPaid} />
        </TabPane>
        <TabPane disabled={loading} tab="Paying Users" key="5">
          <MultiLineChart loading={loading} data={data.payingUsers} />
        </TabPane>
      </Tabs>
    </Card>
  );
};
