import React, { useState, Fragment } from "react";
import {
  Form,
  Row,
  Col,
  Divider,
  Modal,
  Radio,
  Avatar,
  Input,
  Tag,
} from "antd";
import SemverCompare from "compare-versions";
import LineCard from "../../../../../UI/LineCard/LineCard";
import File from "../../../../../UI/File/File";
import MultiApk from "./MultiApk/MultiApk";
import classes from "./UploadApk.module.less";
import { compareImages } from "../../../../../../functions";
const AppInfoParser = require("app-info-parser");
const { Item } = Form;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export default ({
  getFieldDecorator,
  disabled = false,
  data = {},
  submitFileHandler,
  compareIcons,
  setApkInformation,
  deleteFile,
  mode = "single",
  changeMode,
  lastSubmition = {},
  autoSave,
  loadingInfo,
  getData,
}) => {
  const [apkRoute, setApkRoute] = useState("");
  const [appIcon, setAppIcon] = useState(null);
  const [appName, setAppName] = useState(null);
  const [iconsMatch, setIconsMatch] = useState(true);
  const isHigherCodeVersion = (newVersion, oldVersion, title) => {
    if (oldVersion) {
      try {
        const [newVersionNormalized, lastVersionNormalized] = normalizeVersions(
          `${newVersion}`,
          `${oldVersion}`
        );
        const compareVersions = SemverCompare(
          newVersionNormalized,
          lastVersionNormalized
        );
        if (compareVersions !== 1) {
          Modal.error({
            title,
            centered: true,
          });
          return false;
        }
      } catch (error) {
        return false;
      }
    }
    return true;
  };

  const normalizeVersions = (newVersion, lastVersion) => {
    try {
      const newVersionLength = newVersion.split(".").length || 1;
      const lastVersionLength = lastVersion.split(".").length || 1;
      const zerosToAdd = Math.abs(newVersionLength - lastVersionLength);
      const zerosFill = Array(zerosToAdd)
        .fill(".0")
        .join("");
      const versionsNormalized =
        newVersionLength > lastVersionLength
          ? [newVersion, `${lastVersion}${zerosFill}`]
          : [`${newVersion}${zerosFill}`, lastVersion];

      return versionsNormalized;
    } catch (error) {
      console.log(error);
    }
  };

  const isSamePackageName = (packageName) => {
    const lastSubmitionPackage = lastSubmition.package_name;
    if (lastSubmitionPackage) {
      if (lastSubmitionPackage != packageName) {
        Modal.error({
          title: `The package name of the APK you uploaded must be the same as your previous form submissions`,
          centered: true,
        });
        return false;
      }
    }
    return true;
  };

  const parseApk = (event, apk, next) => {
    const parser = new AppInfoParser(apk);
    parser.parse().then(async (pkgInfo) => {
      if (pkgInfo) {
        let apkValid = await validateApk(pkgInfo);
        if (apkValid) {
          updateApkValues(pkgInfo);
          compareIcons(
            pkgInfo.icon,
            lastSubmition.apk_icon || lastSubmition.icon
          );
          next();
        }
      }
    });
  };

  const handleFileSubmitted = (fieldToUpdate, event, file) => {
    submitFileHandler(fieldToUpdate);
    setApkRoute(fieldToUpdate.value);
    autoSave({ apk: fieldToUpdate.value });
  };

  const validateApk = async (pkgInfo) => {
    const isHigherVersion = isHigherCodeVersion(
      pkgInfo.versionName,
      lastSubmition.version,
      `The version number must be higher than the previous one in order to continue.
      New: ${pkgInfo.versionName} Current: ${lastSubmition.version}
      `
    );
    const isHigherVersionCode = isHigherCodeVersion(
      pkgInfo.versionCode,
      lastSubmition.version_code,
      `The version code must be higher than the previous one in order to continue.
      New: ${pkgInfo.versionCode} Current: ${lastSubmition.version_code}`
    );
    const isSameApk = isSamePackageName(pkgInfo.package);
    return isHigherVersion && isSameApk && isHigherVersionCode;
  };

  const updateApkValues = (pkgInfo, autosave = true) => {
    setAppIcon(pkgInfo.icon);
    //compareCurrentIcons();
    let appNameIcon = null;
    if (pkgInfo.application && pkgInfo.application.label) {
      appNameIcon =
        pkgInfo.application.label instanceof Array
          ? pkgInfo.application.label[0]
          : pkgInfo.application.label;
      setAppName(appNameIcon);
    }
    setApkInformation({
      ...data,
      version: pkgInfo.versionName,
      version_code: pkgInfo.versionCode,
      package_name: pkgInfo.package,
      apk_icon: pkgInfo.icon,
      appname_en: appNameIcon,
    });
    if (appNameIcon) {
      submitFileHandler({ name: "appname_en", value: appNameIcon }, false);
    }
    submitFileHandler({ name: "version", value: pkgInfo.versionName }, false);
    submitFileHandler(
      {
        name: "version_code",
        value: pkgInfo.versionCode,
      },
      false
    );
    submitFileHandler(
      {
        name: "package_name",
        value: pkgInfo.package,
      },
      false
    );
    submitFileHandler({ name: "apk_icon", value: pkgInfo.icon }, false);
    if (autosave) {
      autoSave({
        version: pkgInfo.versionName,
        version_code: pkgInfo.versionCode,
        package_name: pkgInfo.package,
        appname_en: appNameIcon,
        apk_icon: pkgInfo.icon,
      });
    }
  };

  const attachMultiApks = (apks) => {
    setApkInformation({
      ...data,
      multi_apks: apks,
    });
  };

  const compareCurrentIcons = async () => {
    let result = await compareImages(
      appIcon || data.apk_icon,
      lastSubmition.apk_icon || lastSubmition.icon
    );
    setIconsMatch(result);
  };

  return (
    <LineCard style={{ marginTop: 15 }} shadow>
      {!disabled ? (
        <RadioGroup
          size="small"
          value={mode}
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <RadioButton
            disabled={disabled}
            value="single"
            onChange={(event) => changeMode(event.target.value)}
          >
            Single APK
          </RadioButton>
          <RadioButton
            value="multi"
            onChange={(event) => changeMode(event.target.value)}
          >
            Multi APK
          </RadioButton>
        </RadioGroup>
      ) : null}

      {mode === "single" ? (
        <Row gutter={16}>
          <Col span={10}>
            <Item>
              {getFieldDecorator("apk", {
                rules: [
                  {
                    required: true,
                    message: "Required",
                  },
                ],
                initialValue: data.apk,
              })(
                <File
                  name="apk"
                  type="File"
                  title="App APK *"
                  handleChange={parseApk}
                  disabled={disabled}
                  onDelete={() => {
                    deleteFile("apk");
                    setApkRoute("");
                  }}
                  validation={{
                    fileRequired: true,
                  }}
                  source={apkRoute || data.apk}
                  format={["apk"]}
                  submitFile={handleFileSubmitted}
                />
              )}
            </Item>
          </Col>
          <Col span={14}>
            <p>
              Please note the App Name within your APK must match exactly with
              the App Name or App Name (Alternative Version) on the approved
              Copyright Certificate. Users will also see this name on their
              phones as well as the information page on each store.
            </p>
            <p>
              There shall be a permanent link within the app for users to view
              Privacy Policy, Terms of Services, and Contact Us. For Contact Us,
              you shall leave an email address and/or phone number that can be
              reached in mainland China.
            </p>
          </Col>
        </Row>
      ) : (
        <MultiApk
          data={data}
          attachMultiApks={attachMultiApks}
          disabled={disabled}
          lastSubmition={lastSubmition}
          updateApkValues={updateApkValues}
          getData={getData}
          loadingInfo={loadingInfo}
          compareIcons={compareIcons}
        />
      )}
      {disabled ? null : (
        <Row gutter={16}>
          <Col span={24}>
            <Divider />
          </Col>
          <Col className={classes.newVersion} span={12}>
            <h3>New Version: </h3>
            <p>
              App Icon:{" "}
              {data.apk_icon || appIcon
                ? getFieldDecorator("apk_icon", {
                    initialValue: data.apk_icon,
                  })(
                    <Fragment>
                      <Avatar shape="square" src={appIcon || data.apk_icon} />{" "}
                      {/*iconsMatch === true ? null : (
                        <Tag color="red">Icons don't match</Tag>
                      )*/}
                    </Fragment>
                  )
                : null}
            </p>
            <Item label="App Name">
              {getFieldDecorator("appname_en", {
                initialValue: appName || data.appname_en,
              })(<Input disabled={true} />)}
            </Item>
            <Item label="App Version Number">
              {getFieldDecorator("version", {
                initialValue: data.version,
              })(<Input disabled={true} />)}
            </Item>
            <Item label="App Version Code">
              {getFieldDecorator("version_code", {
                initialValue: data.version_code,
              })(<Input disabled={true} />)}
            </Item>
            <Item label="Package Name">
              {getFieldDecorator("package_name", {
                initialValue: data.package_name,
              })(<Input disabled={true} />)}
            </Item>
          </Col>
          <Col span={12}>
            <h3>Current Live Version: </h3>
            <p>
              App Icon:{" "}
              <Avatar
                shape="square"
                src={
                  lastSubmition.apk_icon ||
                  `https://appinchina.space/uploads/${lastSubmition.icon}`
                }
              />
            </p>
            <p>App Name: {lastSubmition.appname_en}</p>
            <p>App Version Number: {lastSubmition.version}</p>
            <p>App Version Code: {lastSubmition.version_code}</p>
            <p>Package Name: {lastSubmition.package_name}</p>
          </Col>
        </Row>
      )}
    </LineCard>
  );
};
