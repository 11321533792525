import React, { useState, useEffect } from "react";
import classes from "./Chart.module.less";
import MultiLineChart from "../../../../UI/Charts/MultiLineChart";
import {
  Tabs,
  Card,
  DatePicker,
  Row,
  Col,
  Table,
  Button,
  Statistic,
  Select,
} from "antd";
import functions, { getMonthlyDate } from "../../../../../functions";
import {
  dataComparissonPerDayFake,
  dataPerDayTable,
} from "../../../PromotionFakeData";
import { getSummaryPerDay } from "../../../../../services/promotionService";
import moment from "moment";
const { TabPane } = Tabs;
const { Grid } = Card;
const { Option } = Select;
const gridStyle = {
  width: "33.33%",
  textAlign: "center",
};

export default ({ app, useDemoData }) => {
  const [date, setDate] = useState(getMonthlyDate());
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState({});
  let [total, setTotal] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const makeRequest = async (store = null) => {
    setLoading(true);
    let { data: response } = await getSummaryPerDay({
      promotionId: app.analitycs_id,
      app: app.id,
      first: date.start,
      second: date.end,
      store,
    });
    const resume = resumeData(response.dataPerDayTable);
    setTotal(resume);
    setData(response);
    setLoading(false);
  };

  const resumeData = (array) => {
    return array.reduce(
      (prev, curr) => {
        return {
          downloads: prev.downloads + +curr.downloads,
          amountSpent: prev.amountSpent + +curr.amountSpent,
          newActivations: prev.newActivations + +curr.newActivations,
          newRegistrations: prev.newRegistrations + +curr.registrations,
          transactions: prev.transactions + +curr.amountOfPayment,
          totalPaid: prev.totalPaid + +curr.paymentAmount,
        };
      },
      {
        downloads: 0,
        amountSpent: 0,
        newActivations: 0,
        newRegistrations: 0,
        transactions: 0,
        totalPaid: 0,
      }
    );
  };

  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData({
          ...dataComparissonPerDayFake(date.start, date.end),
          dataPerDayTable,
        });
        setLoading(false);
      }, 500);
    }
  };
  useEffect(() => {
    validateRequest();
  }, [app]);
  useEffect(() => {
    validateRequest();
  }, [date]);

  const columns = [
    {
      fixed: "left",
      width: "100px",
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Downloads",
      dataIndex: "downloads",
      key: "downloads",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "Amount Spent",
      dataIndex: "amountSpent",
      key: "amountSpent",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "New Activations",
      dataIndex: "newActivations",
      key: "newActivations",
      render: (val) => functions.styleAsNumber(val),
    },

    {
      title: "New Registrations",
      dataIndex: "registrations",
      key: "registrations",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "Recurring Users",
      dataIndex: "recurringUsers",
      key: "recurringUsers",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "Transactions",
      dataIndex: "amountOfPayment",
      key: "amountOfPayment",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "Total Paid",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "Recurring Users Rate",
      dataIndex: "activationRate",
      key: "activationRate",
      render: (val) => `${functions.styleAsNumber(val || 0)}%`,
    },
    {
      title: "Activations Rate",
      dataIndex: "registrationRate",
      key: "registrationRate",
      render: (val) => `${functions.styleAsNumber(val || 0)}%`,
    },
  ];

  const exportPdf = () => {
    const headers = [
      "Date",
      "Exposure",
      "Downloads",
      "Amount spent",
      "New Activations",
      "New Registrations",
      "Paying Users",
      "Transactions",
      "Total Paid",
    ].join(",");
    const rows = Array(data.dataPerDayTable.length)
      .fill("")
      .map(
        (_, index) =>
          `${data.dataPerDayTable[index].date},${data.dataPerDayTable[index].exposure},${data.dataPerDayTable[index].downloads},${data.dataPerDayTable[index].amountSpent},${data.dataPerDayTable[index].newActivations},${data.dataPerDayTable[index].registrations},${data.dataPerDayTable[index].payingUsers},${data.dataPerDayTable[index].transactions},${data.dataPerDayTable[index].paymentAmount}`
      )
      .join("\n");

    let csvContent = "data:text/csv;charset=utf-8," + `${headers}\n` + rows;
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className={classes.container}>
      <Card title="Data Comparison per Day" style={{ marginTop: 15 }}>
        <Row gutter={16}>
          <Col
            xs={{ span: 12 }}
            md={{ span: 6 }}
            xl={{ span: 5 }}
            style={{ marginBottom: 15 }}
          >
            <DatePicker.MonthPicker
              disabled={loading}
              loading={loading}
              value={moment(date.start)}
              style={{ width: "100%" }}
              onChange={(_, string) =>
                setDate({
                  start: moment(string).format("MMMM YYYY"),
                  end: moment(string)
                    .add(1, "months")
                    .format("MMMM YYYY"),
                })
              }
            />
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 6 }}
            xl={{ span: 5 }}
            style={{ marginBottom: 15 }}
          >
            <DatePicker.MonthPicker
              disabled={loading}
              loading={loading}
              value={moment(date.end)}
              style={{ width: "100%" }}
              onChange={(_, string) =>
                setDate({
                  start: moment(string)
                    .subtract(1, "months")
                    .format("MMMM YYYY"),
                  end: moment(string).format("MMMM YYYY"),
                })
              }
            />
          </Col>
          {activeTab == "0" ||
          activeTab == "1" ||
          activeTab == "2" ||
          activeTab == "3" ||
          activeTab == "6" ? (
            <Col
              xs={{ span: 12 }}
              md={{ span: 6 }}
              xl={{ span: 5 }}
              style={{ marginBottom: 15 }}
            >
              {data.stores ? (
                <Select
                  disabled={loading}
                  loading={loading}
                  defaultValue={null}
                  onChange={(val) => makeRequest(val)}
                  style={{ width: "100%" }}
                  placeholder="App Store"
                >
                  {data.stores.map((it) => (
                    <Option value={it.value}>{it.label}</Option>
                  ))}
                </Select>
              ) : null}
            </Col>
          ) : null}
          <Col
            xs={{ span: 12 }}
            md={{ span: 6 }}
            xl={{ span: 5 }}
            style={{ marginBottom: 15 }}
          >
            <Button onClick={exportPdf} type="primary">
              Export to csv
            </Button>
          </Col>
        </Row>
        <Tabs
          disabled={loading}
          loading={loading}
          onChange={(key) => setActiveTab(key)}
          type="card"
          defaultActiveKey="0"
          style={{ width: "100%", marginTop: 15 }}
        >
          <TabPane disabled={loading} tab="Exposure" key="0">
            <MultiLineChart loading={loading} data={data.exposure} />
          </TabPane>
          <TabPane disabled={loading} tab="Downloads" key="1">
            <MultiLineChart loading={loading} data={data.downloads} />
          </TabPane>
          <TabPane disabled={loading} tab="New Activations" key="3">
            <MultiLineChart loading={loading} data={data.newActivations} />
          </TabPane>
          <TabPane disabled={loading} tab="New Registrations" key="6">
            <MultiLineChart loading={loading} data={data.newRegistrations} />
          </TabPane>
          <TabPane disabled={loading} tab="Paying Users" key="5">
            <MultiLineChart loading={loading} data={data.payingUsers} />
          </TabPane>
          <TabPane disabled={loading} tab="Recurring Users" key="2">
            <MultiLineChart loading={loading} data={data.recurringUsers} />
          </TabPane>
          <TabPane disabled={loading} tab="Transactions" key="7">
            <MultiLineChart loading={loading} data={data.transactions} />
          </TabPane>
          <TabPane disabled={loading} tab="Total Paid" key="4">
            <MultiLineChart loading={loading} data={data.totalPaid} />
          </TabPane>
        </Tabs>
        <Card
          loading={loading}
          title={`Total (${date.start} to ${date.end})`}
          style={{ marginTop: 15, backgroundColor: "white" }}
        >
          <Grid style={gridStyle}>
            <Statistic
              title="Downloads"
              value={total.downloads ? total.downloads : "No Data"}
            />
          </Grid>
          <Grid style={gridStyle}>
            <Statistic
              title="Amount Spent"
              value={total.amountSpent ? total.amountSpent : "No Data"}
            />
          </Grid>
          <Grid style={gridStyle}>
            <Statistic
              title="New Activations"
              value={total.newActivations ? total.newActivations : "No Data"}
            />
          </Grid>
          <Grid style={gridStyle}>
            <Statistic
              title="New Registrations"
              value={
                total.newRegistrations ? total.newRegistrations : "No Data"
              }
            />
          </Grid>
          <Grid style={gridStyle}>
            <Statistic
              title="Transactions"
              value={total.transactions ? total.transactions : "No Data"}
            />
          </Grid>
          <Grid style={gridStyle}>
            <Statistic
              title="Total Paid (RMB)"
              value={total.totalPaid ? total.totalPaid : "No Data"}
            />
          </Grid>
        </Card>
        <Table
          dataSource={data.dataPerDayTable}
          columns={columns}
          size="small"
          scroll={{ x: 700 }}
          style={{ marginTop: 15 }}
        />
      </Card>
    </div>
  );
};
