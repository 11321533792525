import axios from "../axios";

export const getSubscriptionsServer = async (accountId, appId) => {
  let response = null;
  try {
    response = await axios.get(`/payments/subscriptions/${appId}/${accountId}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const addSubscriptionServer = async (accountId, values, appId) => {
  let response = null;
  try {
    response = await axios.post(
      `/payments/subscriptions/${appId}/${accountId}`,
      {
        ...values,
      }
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const editSubscriptionsServer = async (
  subscriptionId,
  values,
  appId
) => {
  let response = null;
  try {
    response = await axios.put(
      `/payments/subscriptions/${appId}/${subscriptionId}`,
      {
        ...values,
      }
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const deleteSubscriptionServer = async (
  subscriptionId,
  accountId,
  appId
) => {
  let response = null;
  try {
    response = await axios.delete(
      `/payments/subscriptions/${appId}/${accountId}/${subscriptionId}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
