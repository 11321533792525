import React, { useState, useEffect } from "react";
import classes from "./AccountBalance.module.less";
import { Alert, Table, Button, Row, Spin, Tag } from "antd";
import { Link } from "react-router-dom";
import functions from "../../../functions";
import { getAccountBalance } from "../../../services/cloudStoreService";
import moment from "moment";
const AccountBalance = ({ appId }) => {
  let [loading, setLoading] = useState(false);
  let [balance, setBalance] = useState(0);
  let [balanceHistory, setBalanceHistory] = useState([]);
  let columns = [
    {
      title: "Deposit Date",
      key: "createdAt",
      dataIndex: "createdAt",
      render: date => moment(date).format("YYYY-MM-DD")
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      align: "right",
      render: amount => <p>USD $ {functions.styleAsNumber(amount)}</p>
    },
    {
      title: "Status",
      key: "verified",
      dataIndex: "verified",
      render: verified =>
        !!verified ? (
          <Tag color="green">VERIFIED</Tag>
        ) : (
          <Tag color="volcano">NOT VERIFIED</Tag>
        )
    },
    { title: "Note", key: "note", dataIndex: "note" }
  ];

  useEffect(() => {
    getBalance();
  }, [appId]);
  useEffect(() => {
    getBalance();
  }, []);
  const getBalance = async () => {
    setLoading(true);
    let { data } = await getAccountBalance(appId);
    setLoading(false);
    if (data && data.total) {
      setBalance(data.total);
      setBalanceHistory(data.account);
    }
  };
  const alert = (
    <Alert
      message={
        <Row type="flex" justify="center">
          <p style={{ margin: 0 }}>
            Account Balance:
            <b> USD ${functions.styleAsNumber(balance)}</b>
          </p>
        </Row>
      }
      type="info"
      showIcon
    />
  );
  return (
    <div className={classes.container}>
      <Spin spinning={loading}>{alert}</Spin>

      <Row style={{ margin: 20 }} type="flex" justify="center">
        <Link to={`/cloud/deposit/${appId}`}>
          <Button icon="credit-card" type="primary">
            Deposit Now
          </Button>
        </Link>
      </Row>
      <p>
        You can top up your account to make check out faster. When a purchase is
        made through your account balance, approval times for pending orders may
        be significantly reduced.
      </p>
      <Table
        rowKey="id"
        pagination={balanceHistory.length > 9 ? { pageSize: 10 } : false}
        columns={columns}
        dataSource={balanceHistory}
        size="small"
        loading={loading}
      />
    </div>
  );
};

export default AccountBalance;
