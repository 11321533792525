import axios from "../axios";

export const changePassword = async values => {
  let response = null;
  try {
    response = await axios.put("/password", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const recoverPassword = async values => {
  let response = null;
  try {
    response = await axios.post("/forgotpassword", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const registerEmail = async values => {
  let response = null;
  try {
    response = await axios.post("/register", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const updateInfo = async values => {
  let response = null;
  try {
    response = await axios.put("/profile", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getUserInfo = async () => {
  let response = null;
  try {
    response = await axios.get("/profile");
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const shareAccess = async values => {
  let response = null;
  try {
    response = await axios.post("/shareaccess", values);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
