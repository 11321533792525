import React, { useState, useEffect } from "react";
import classes from "./Bulletin.module.less";
import { Tabs, List, Button, Spin, Modal } from "antd";
import {
  getAllBulletins,
  getBulletinContent,
  markBulletinAsRead,
} from "../../../services/welcomePage";
import moment from "moment";
const Bulletin = ({ appId }) => {
  const { TabPane } = Tabs;
  const [data, setData] = useState([]);
  const [actualContent, setActualContent] = useState("");
  const [modalLoading, setModalLoading] = useState("");
  const [actualTitle, setActualTitle] = useState("");
  const [openBulletinId, setOpenBulletinId] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);

  const markAsRead = async () => {
    setModalLoading(true);
    let { data } = await markBulletinAsRead(openBulletinId);
    setVisible(false);
    setModalLoading(false);
  };

  const getBulletinDetails = async (id, title) => {
    setOpenBulletinId(id);
    setActualTitle(title);
    setLoadingContent(true);
    setVisible(true);
    let { data } = await getBulletinContent(id);
    setLoadingContent(false);
    if (data && data.bulletin) {
      setActualContent(data.bulletin.content);
    }
  };
  const getBulletins = async () => {
    setLoading(true);
    let { data } = await getAllBulletins(appId);
    setLoading(false);
    if (data && data.bulletins) {
      setData(data.bulletins);
      /*let unreadBulletin = data.bulletins.find((it) => !!it.unread);
      if (unreadBulletin) {
        getBulletinDetails(unreadBulletin.id, unreadBulletin.title);
      }*/
    }
  };
  useEffect(() => {
    getBulletins();
  }, [appId]);

  let LoadingSpin = (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );
  let pageSize = window.outerWidth > 1200 ? 5 : 2;
  return loading ? (
    LoadingSpin
  ) : (
    <div className={classes.Container}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Bulletin" key="1">
          <List
            itemLayout="horizontal"
            dataSource={data}
            pagination={data.length > pageSize ? { pageSize } : false}
            renderItem={(item) => (
              <List.Item>
                <div className={classes.List}>
                  <p>
                    <span>{moment(item.date).format("YYYY-MM-DD")}</span>
                    {item.title}
                  </p>
                  <Button
                    onClick={() => getBulletinDetails(item.id, item.title)}
                    type="link"
                  >
                    View More
                  </Button>
                </div>
                <List.Item.Meta />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
      <Modal
        title={actualTitle}
        onOk={markAsRead}
        onCancel={markAsRead}
        visible={visible}
        className={classes.modal}
        confirmLoading={modalLoading}
      >
        {loadingContent ? (
          LoadingSpin
        ) : (
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: actualContent }}
          />
        )}
      </Modal>
    </div>
  );
};

export default Bulletin;
