import React from "react";
import { MiniArea } from "ant-design-pro/lib/Charts";
const Chart = ({ data }) => {
  const visitData = [];
  let result =
    data && data.length > 0
      ? data.map((it) =>
          visitData.push({
            x: it.date,
            y: it.total,
          })
        )
      : null;
  return <MiniArea line height={45} data={visitData} />;
};

export default Chart;
