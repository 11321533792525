import moment from "moment";

const beginDay = new Date().getTime();
const appDemoFake = {
  details_histories: [
    {
      event: "APP_CREATED",
      date: "2018-09-07T12:20:23Z",
      store: null,
      details: null,
    },
    {
      event: "APP_FORM_SUBMITTED",
      date: "2018-09-07T12:20:23Z",
      store: null,
      details: null,
    },
    {
      event: "COMPANY_FORM_SUBMITTED",
      date: "2018-09-07T12:20:23Z",
      store: null,
      details: null,
    },
    {
      event: "DISTRIBUTION_SUBMITTED",
      date: "2018-09-07T12:20:23Z",
      store: "Tencent MyApp",
      details: null,
    },
    {
      event: "DISTRIBUTION_REJECTED",
      date: "2018-09-07T12:20:23Z",
      store: "Tencent MyApp",
      details: "Icon from APK doesn't match the icon submitted",
    },
    {
      event: "COPYRIGHT_FORM_SUBMITTED",
      date: "2018-09-07T12:20:23Z",
      store: null,
      details: null,
    },
    {
      event: "DISTRIBUTION_PUBLISHED",
      date: "2018-09-07T12:20:23Z",
      store: "Tencent MyApp",
      details: null,
    },
    {
      event: "DISTRIBUTION_PUBLISHED",
      date: "2018-09-07T12:20:23Z",
      store: "Huawei App Market",
      details: null,
    },
    {
      event: "DISTRIBUTION_SUBMITTED",
      date: "2018-09-07T12:20:23Z",
      store: "MIUI App Store",
      details: null,
    },
    {
      event: "DISTRIBUTION_SUSPENDED",
      date: "2018-09-07T12:20:23Z",
      store: "MIUI App Store",
      details: null,
    },
    {
      event: "DISTRIBUTION_REMOVED",
      date: "2018-09-07T12:20:23Z",
      store: "MIUI App Store",
      details: null,
    },
    {
      event: "DISTRIBUTION_PUBLISHED",
      date: "2018-09-07T12:20:23Z",
      store: "Anzhi Market",
      details: null,
    },
  ],
  id: "1234-8475-9439-9494",
  name: "Demo App",
  image_data: "https://appinchina.space/uploads/1539773427695_icon.png",
  information: [],
  authorized: true,
  analitycs_id: "20190307001",
  main_information: {
    completion: "ONGOING",
    company_name: "Demo App",
    country: null,
    industry: "Action",
    stores_to_upload: 10,
    targeted_store: null,
    app_store_accounts: null,
    // app_store_accounts: "CLIENT_CC",
    copyright_form_status: "SUBMITTED",
    app_form_status: "RECEIVED",
    business_form_status: "APPROVED",
    scc_file: "",
    alipay_login_credentials: "n/a",
    wechat_login_credentials: "n/a",
    app_public_name: null,
    amount_updates: 8,
    updates_left: 16,
    account_manager: "JOANNE",
    revenue_model: "ADS,BA",
    package_name: "com.demo.app",
    aicpay_appname: "官方Demo",
    aicpay_package: null,
    aicpay_secret: "123",
    analytics_enabled: true,
    max_logon_num: 3,
  },
  reclaiming_results: [],
  status: true,
};

const appChineseDemoFake = {
  details_histories: [
    {
      event: "APP_CREATED",
      date: "2018-09-07T12:20:23Z",
      store: null,
      details: null,
    },
    {
      event: "APP_FORM_SUBMITTED",
      date: "2018-09-07T12:20:23Z",
      store: null,
      details: null,
    },
    {
      event: "COMPANY_FORM_SUBMITTED",
      date: "2018-09-07T12:20:23Z",
      store: null,
      details: null,
    },
    {
      event: "DISTRIBUTION_SUBMITTED",
      date: "2018-09-07T12:20:23Z",
      store: "Tencent MyApp",
      details: null,
    },
    {
      event: "DISTRIBUTION_REJECTED",
      date: "2018-09-07T12:20:23Z",
      store: "Tencent MyApp",
      details: "Icon from APK doesn't match the icon submitted",
    },
    {
      event: "COPYRIGHT_FORM_SUBMITTED",
      date: "2018-09-07T12:20:23Z",
      store: null,
      details: null,
    },
    {
      event: "DISTRIBUTION_PUBLISHED",
      date: "2018-09-07T12:20:23Z",
      store: "Tencent MyApp",
      details: null,
    },
    {
      event: "DISTRIBUTION_PUBLISHED",
      date: "2018-09-07T12:20:23Z",
      store: "Huawei App Market",
      details: null,
    },
    {
      event: "DISTRIBUTION_SUBMITTED",
      date: "2018-09-07T12:20:23Z",
      store: "MIUI App Store",
      details: null,
    },
    {
      event: "DISTRIBUTION_SUSPENDED",
      date: "2018-09-07T12:20:23Z",
      store: "MIUI App Store",
      details: null,
    },
    {
      event: "DISTRIBUTION_REMOVED",
      date: "2018-09-07T12:20:23Z",
      store: "MIUI App Store",
      details: null,
    },
    {
      event: "DISTRIBUTION_PUBLISHED",
      date: "2018-09-07T12:20:23Z",
      store: "Anzhi Market",
      details: null,
    },
  ],
  id: "1234-8475-9439-9495",
  name: "Chinese Demo App",
  image_data: "https://appinchina.space/uploads/1539773427695_icon.png",
  information: [],
  authorized: true,
  main_information: {
    completion: "ONGOING",
    company_name: "Chinese Demo App",
    country: null,
    industry: "Action",
    stores_to_upload: 10,
    targeted_store: null,
    app_store_accounts: "CLIENT_CC",
    copyright_form_status: "SUBMITTED",
    app_form_status: "RECEIVED",
    business_form_status: "APPROVED",
    scc_file: "",
    alipay_login_credentials: "n/a",
    wechat_login_credentials: "n/a",
    app_public_name: null,
    amount_updates: 8,
    updates_left: 16,
    account_manager: "JOANNE",
    revenue_model: "ADS,BA",
    package_name: "com.demo.app",
  },
  reclaiming_results: [],
  status: true,
};

let actualStores = [
  {
    name: "Tencent My App",
    market_coverage: 26.26,
    downloads: 260069000,
    link: "http://android.myapp.com/",
  },
  {
    name: "Huawei App Market",
    market_coverage: 14.7,
    downloads: 151361000,
    link: "http://app.hicloud.com/",
  },
  {
    name: "Oppo Software Store",
    market_coverage: 12.56,
    downloads: 129361000,
    link: "https://store.oppomobile.com/",
  },
  {
    name: "360 Mobile Assistant",
    market_coverage: 10.23,
    downloads: 105307000,
    link: "http://zhushou.360.cn/",
  },
  {
    name: "Baidu Mobile Assistant",
    market_coverage: 8.48,
    downloads: 87308000,
    link: "http://as.baidu.com/",
  },
  {
    name: "Vivo App Store",
    market_coverage: 8.29,
    downloads: 85350000,
    link: "https://developer.vivo.com.cn/doc/products/pc/index.html#firstPage",
  },
  {
    name: "MIUI App Store",
    market_coverage: 7.55,
    downloads: 77738000,
    link: "http://app.xiaomi.com/",
  },
  {
    name: "PP Assistant",
    market_coverage: 2.46,
    downloads: 23337000,
    link: "https://www.25pp.com/android/",
  },
  {
    name: "Anzhi Market",
    market_coverage: 2.24,
    downloads: 23090000,
    link: "http://www.anzhi.com/applist.html",
  },
  {
    name: "Wandoujia",
    market_coverage: 1.21,
    downloads: 12482000,
    link: "http://www.wandoujia.com/apps",
  },
  {
    name: "HiMarket",
    market_coverage: 0.98,
    downloads: 10074000,
    link: "http://apk.hiapk.com/",
  },
  {
    name: "Lenovo Le Store",
    market_coverage: 0.5,
    downloads: 5150000,
    link: "http://www.lenovomm.com/",
  },
  {
    name: "Samsung App Store",
    market_coverage: 0.49,
    downloads: 5025000,
    link: "https://www.samsung.com/my/apps/galaxy-apps/",
  },
  {
    name: "Coolpad",
    market_coverage: 0.38,
    downloads: 3888000,
    link: "http://www.coolmart.net.cn/",
  },
  {
    name: "China Unicom Wo Store",
    market_coverage: 0.38,
    downloads: 3888000,
    link: "http://store.wo.com.cn/",
  },
  {
    name: "ZTE App Store",
    market_coverage: 0.34,
    downloads: 3466000,
    link: "http://apps.ztems.com/",
  },
  {
    name: "Safe Market",
    downloads: 1463000,
    market_coverage: 0.14,
    link: null,
  },
  {
    name: "China Telecom 189 Store",
    market_coverage: 0.13,
    downloads: 1463000,
    link: "https://www.189store.com/",
  },
  {
    name: "LeTV Store",
    market_coverage: 0.11,
    downloads: 1136000,
    link: "http://leplay.cn/",
  },
];
let comments = [
  {
    english: "Very fun and easy to use",
    chinese: "非常有趣且易于使用",
  },
  {
    english: "It is good!",
    chinese: "很好啊",
  },
  {
    english: "Excellent App",
    chinese: "优秀的应用",
  },
  {
    english: "Easy to use",
    chinese: "使用方便",
  },
  {
    english: "The best App",
    chinese: "最好的应用",
  },
];

actualStores.forEach((store) => {
  appDemoFake.information.push({
    distribution_status: "PUBLISHED",
    downloads: [{ date: "", downloads: store.downloads }],
    link: store.link,
    problem: null,
    problem_details: null,
    rating: Math.floor(Math.random() * 10) + 1,
    rejectionReason: "DEMO APP",
    reviews: [
      {
        createdAt: moment().format("YYYY-MM-DD"),
        date: moment().format("YYYY-MM-DD"),
        english_date: "2019-02-27 16:44:17",
        english_review: comments[Math.floor(Math.random() * 4) + 0].english,
        english_score: store.name,
        english_user: `user`,
        reply: null,
        review: comments[Math.floor(Math.random() * 4) + 0].chinese,
        score: `${Math.floor(Math.random() * 10) + 5}`,
        status: null,
        user: `user`,
      },
    ],
    store: {
      name: store.name,
      market_coverage: store.market_coverage,
      scrapeable: true,
    },
    version: `1.0.${Math.floor(Math.random() * 10) + 1}`,
  });
});
actualStores.forEach((store) => {
  appChineseDemoFake.information.push({
    distribution_status: "PUBLISHED",
    downloads: [{ date: "", downloads: store.downloads }],
    link: store.link,
    problem: null,
    problem_details: null,
    rating: Math.floor(Math.random() * 10) + 1,
    rejectionReason: "DEMO APP",
    reviews: [
      {
        createdAt: moment().format("YYYY-MM-DD"),
        date: moment().format("YYYY-MM-DD"),
        english_date: "2019-02-27 16:44:17",
        english_review: comments[Math.floor(Math.random() * 4) + 0].english,
        english_score: store.name,
        english_user: `user`,
        reply: null,
        review: comments[Math.floor(Math.random() * 4) + 0].chinese,
        score: `${Math.floor(Math.random() * 10) + 5}`,
        status: null,
        user: `user`,
      },
    ],
    store: {
      name: store.name,
      market_coverage: store.market_coverage,
      scrapeable: true,
    },
    version: `1.0.${Math.floor(Math.random() * 10) + 1}`,
  });
});
let downloadsArrayFake = [];

for (let j = 0; j < 20; j += 1) {
  downloadsArrayFake.push({
    date: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * j)).format(
      "YYYY-MM-DD"
    ),
    data: [
      {
        downloads: Math.floor(Math.random() * 10000000) + 1000000,
        currentDown: Math.floor(Math.random() * 10000000) + 1000000,
        currentUpdateDown: Math.floor(Math.random() * 1000000) + 100000,
        store: { fullname: `Tencent My App` },
      },
      {
        downloads: Math.floor(Math.random() * 10000000) + 1000000,
        currentDown: Math.floor(Math.random() * 10000000) + 1000000,
        currentUpdateDown: Math.floor(Math.random() * 1000000) + 100000,
        store: { fullname: `Huawei App Market` },
      },
      {
        downloads: Math.floor(Math.random() * 10000000) + 1000000,
        currentDown: Math.floor(Math.random() * 10000000) + 1000000,
        currentUpdateDown: Math.floor(Math.random() * 1000000) + 100000,
        store: { fullname: `Oppo Software Store` },
      },
    ],
  });
}

let downloadsFormat = {
  chartData: downloadsArrayFake,
  keys: ["Store 1", "Store 2", "Store 3", "Store 4", "Store 5"],
};
let reviewsArrayFake = [];

actualStores.forEach((store) =>
  reviewsArrayFake.push({
    createdAt: moment().format("YYYY-MM-DD"),
    date: moment().format("YYYY-MM-DD"),
    english_date: "2019-02-27 16:44:17",
    english_review: comments[Math.floor(Math.random() * 4) + 0].english,
    english_score: store.name,
    english_user: `user`,
    reply: null,
    review: comments[Math.floor(Math.random() * 4) + 0].chinese,
    score: `${Math.floor(Math.random() * 10) + 5}`,
    status: null,
    store: store.name,
    user: `user`,
  })
);

let appReviewsFake = [];
actualStores.forEach((store) =>
  appReviewsFake.push({
    store: { ...store, scrapeable: true },
    reviews: [
      {
        createdAt: moment().format("YYYY-MM-DD"),
        date: moment().format("YYYY-MM-DD"),
        english_date: "2019-02-27 16:44:17",
        english_review: comments[Math.floor(Math.random() * 4) + 0].english,
        english_score: store.name,
        english_user: `user`,
        reply: null,
        review: comments[Math.floor(Math.random() * 4) + 0].chinese,
        score: `${Math.floor(Math.random() * 10) + 5}`,
        status: null,
        store: store.name,
        user: `user`,
      },
    ],
  })
);

let appFormFake = [
  {
    updatedAt: moment(),
    apk: "1560190095595/Gen_Signature_Android.apk",
    app_category: "Education, Tools, Lifestyle",
    app_cost: "FREEMIUM",
    app_permissions: "Internet, Camera, Geolocalization",
    app_short_name: null,
    app_tags: "Financial Education, Tool",
    appdesc_long: `√服务超过90%的移动互联网用户
    √多人视频、文件多端互传，不断创新满足沟通所需
    √致力于打造欢乐无限的沟通、娱乐与生活体验
    
    -----主要功能-----
    
    ·聊天消息：随时随地收发好友和群消息，一触即达。
    ·语音通话：两人、多人语音通话，高清畅聊。
    ·视频聊天：亲朋好友，想念不如相见。
    ·文件传输：手机、电脑多端互传，方便快捷。
    ·空间动态：更快获知好友动态，分享生活留住感动。
    ·个性装扮：主题、名片、彩铃、气泡、挂件自由选。
    ·游戏中心：天天、全民等最热手游，根本停不下来。
    ·移动支付：话费充值、网购、转账收款，一应俱全。
    
    乐在沟通18年，聊天欢乐9亿人！
    
    -----联系我们-----
    
    如在使用过程中遇到任何问题，请联系我们：
    - 在线帮助：进入QQ设置 -> 关于QQ -> 帮助与反馈
    - 客服热线：0755 -83763333（服务时间：8:00 - 23:00）
    `,
    appdesc_short:
      "Helps you track your financial activity efficiently. Its simple design makes it lightweight, straightforward and very easy to use. It’s done in one click, because you don’t need to fill anything except the amount.",
    apple_store_published: false,
    appname_en: "Demo",
    console_screenshot: "Screenshot_20191215-210542.jpg",
    createdAt: "",
    google_play_link:
      "https://play.google.com/store/apps/details?id=com.mojang.minecraftpe",
    icon: "Screenshot_20191215-210542.jpg",
    icon_2: "Screenshot_20191215-210542.jpg",
    icon_3: "Screenshot_20191215-210542.jpg",
    id: "1234-8475-9439-9494",
    internet: true,
    is_draft: false,
    itunes_link: "https://apps.apple.com/mx/app/clash-royale/id1053012308",
    login_required: false,
    multi_apks: [],
    new_features: "Various bug fixes and optimizations",
    package_name: "demo.com",
    preview_video: null,
    privacy_policy: "na",
    revenue_model: "ADS",
    screenshot1: "Screenshot_20191215-210542.jpg",
    screenshot1_1: "Screenshot_20191215-210542.jpg",
    screenshot2: "Screenshot_20191215-210542.jpg",
    screenshot2_1: "Screenshot_20191215-210542.jpg",
    screenshot3: "Screenshot_20191215-210542.jpg",
    screenshot3_1: "Screenshot_20191215-210542.jpg",
    screenshot4: "Screenshot_20191215-210542.jpg",
    screenshot4_1: "Screenshot_20191215-210542.jpg",
    screenshot5: "Screenshot_20191215-210542.jpg",
    screenshot5_1: "Screenshot_20191215-210542.jpg",
    screenshot6: "Screenshot_20191215-210542.jpg",
    screenshot6_1: "Screenshot_20191215-210542.jpg",
    special_notes: null,
    statement: null,
    status: true,
    support_ipv6: false,
    tablet_devices: true,
    target_audience: "Chinese people",
    test_password: "demopasswd",
    test_user: "demo",
    version: "3.2",
    version_code: "3.133.12",
  },
];
const copyrightFormFake = {
  app_short_name: "Demo App",
  appname_en: "Demo App",
  business_license: "1542319605026_business_license.png",
  company_address: "China, Beijing, Mentougou, 109国道",
  completion_date: "2019-01-01",
  date_publication: moment().format("DD-MM-YYYY"),
  development_statement: null,
  estimated_release_date: "Summer",
  functionality_description:
    "Helps you track your financial activity efficiently. Its simple design makes it lightweight, straightforward and very easy to use. It’s done in one click, because you don’t need to fill anything except the amount.",
  hardware_requirements: "n/a",
  id: "959b6e3c-08d9-4c82-8627-12f405216ab9",
  license_number: "12345678",
  lines_code: "5,345",
  name_company: "Demo Company",
  nationality: "China",
  operation_manual: "1558378365885/Screen Shot 2019-05-16 at 2.04.04 PM.png",
  passport: null,
  phone_number: "7786789978",
  postal_code: "98665",
  programming_language: "Java",
  published: false,
  software_requirements: "Android 4.4 or more",
  source_code: "1542319601686_source_code.png",
  special_notes: "",
  state_city: null,
  version: "3.2",
};
const securityFormFake = {
  complaint_report_system: `The app has a reporting system.\nOnce a report has been received, it is transferred to the content review department for review. An operations employee will determine the outcome of the report.`,
  id: "959b6e3c-08d9-4c82-8627-12f405216ab9",
  mechanism_legal_supervision: `Our servers store user information for the past 100 days.\nThis includes any conversations within our app, as well as user posted content.\nWe are able to locate the correct user based on username, device ID, and IP address.\nWe are able to intercept content and delete it or quarantine it as necessary.`,
  personal_information_protection: `An administrator has access to the database of user submitted information.\nWe have the ability to blacklist users who break the terms of service.\nWe have the ability to block certain keywords and website links.\nWe have a notification function for intercepted content:\nWhen a certain post or link cannot be shown, it will display a notification to users and send them a warning email.\nWe encrypt all user information on a secure database. The passwords are automatically changed on a monthly basis.`,
  safety_management: `Our company has a content operations department that deals specifically with the review of content within our app.\nThis department is in charge of content editing, reviews, approvals and operations.\nThe department reviews all daily posted contents including news and user posted content, and comes to decisions to approve or reject posts.\nThe information and paperwork related to content review is kept in their own department.`,
  special_notes: null,
  user_identity_verification: `During registration, users need to provide a cellphone number.\nWe are able to identify users based on  their phone number. User information is stored on a server which is used to validate the user’s identity when they log in to the app again.\mWe save IP addresses, user device ID, MAC address, and all accounts that the user linked inside the app.`,
  users_record_retention: `We collect daily information regarding the user’s account, user’s activation time (beginning session time), type of action, and IP address, including the source as well as the destination.\nWe also gather the port information for the session.\nWe collect and save this information to quickly find a user’s device, as well as to identify the correct user.`,
  users_retention_prevention: `An administrator has access to the database of user submitted information.\nWe have the ability to blacklist users who break the terms of service.\nWe have the ability to block certain keywords and website links.\nWe have a notification function for intercepted content:When a certain post or link cannot be shown, it will display a notification to users and send them a warning email.`,
};
const companyFormFake = {
  account_email: "test@demo.com",
  account_password: "1234567",
  account_phone: "384573921",
  bank_account: "12037972927",
  bank_permit: "1542318285657_bank_permit.png",
  business_license: "1542318280134_business_license.png",
  company_address: "782 Dekalb Ave, Brooklyn, NY 11221, EU",
  company_branch: "Bank of China",
  company_name: "Demo App",
  company_url: "wwww.appinchina.co",
  contact_name: "你好",
  id: "959b6e3c-08d9-4c82-8627-12f405216ab9",
  id_hand_back: "1542318314453_id_hand_back.png",
  id_hand_front: "1542318306596_id_regular_front.png",
  id_number: "18276537219",
  id_regular_back: "1542318309234_id_regular_back.png",
  id_regular_front: "1542318306596_id_regular_front.png",
  license_number: "2fg936-23812",
  mobile: "17283771298",
  passport_hand_back: "1542318300214_passport_hand_back.png",
  passport_hand_front: "1542318296463_passport_hand_front.png",
  passport_regular_back: "1542318291811_passport_regular_back.png",
  passport_regular_front: "1542318288873_passport_regular_front.png",
  special_notes: "",
  title: "",
};

const distributionStoresFake = [
  {
    id: "57025ee2-fe22-4c88-ab53-a32e745bb53a",
    completion: "Completed",
    version: "1.8",
    distribution_status: "REJECTED",
    rejectionReason: null,
    problem: "NONE",
    problem_details: null,
    link: "https://www.appinchina.co",
    user: "demo@appinchina.co",
    password: "Test123",
    version_being_submitted: null,
    rating: null,
    store: {
      name: "Tencent MyApp",
      market_coverage: 25.99,
      real_ranking: 1,
      scrapeable: true,
    },
    reviews: [],
    downloads: [
      {
        totalDownloads: 5000,
      },
    ],
    distribution_histories: [
      {
        id: "12345",
        user: "demo@appinchina.co",
        description: "Demo information",
        status: "REJECTED",
        date: "2020-05-04T17:20:57.000Z",
        client_notes: null,
        history_attachments: [
          {
            url: "1589519880155/aic.png",
            file_name: "aic.png",
            file_size: 2979,
            createdAt: "2020-05-15T05:18:09.000Z",
            FK_Distribution_History: "demo",
          },
        ],
      },
    ],
  },
  {
    id: "57025ee2-fe22-4c88-ab53-a32e745bb53a",
    completion: "Completed",
    version: "1.8",
    distribution_status: "PUBLISHED",
    rejectionReason: null,
    problem: "NONE",
    problem_details: null,
    link: "https://www.appinchina.co",
    user: "demo@appinchina.co",
    password: "Test123",
    version_being_submitted: null,
    rating: null,
    store: {
      name: "Huawei App Market",
      market_coverage: 25.99,
      real_ranking: 1,
      scrapeable: true,
    },
    reviews: [],
    downloads: [
      {
        totalDownloads: 5000,
      },
    ],
    distribution_histories: [
      {
        id: "12345",
        user: "demo@appinchina.co",
        description: "Demo information",
        status: "PUBLISHED",
        date: "2020-05-04T17:20:57.000Z",
        client_notes: null,
        history_attachments: [
          {
            url: "1589519880155/aic.png",
            file_name: "aic.png",
            file_size: 2979,
            createdAt: "2020-05-15T05:18:09.000Z",
            FK_Distribution_History: "demo",
          },
        ],
      },
      {
        id: "12345",
        user: "demo@appinchina.co",
        description: "Demo information",
        status: "SUBMITTED_TO_STORE",
        date: "2020-05-04T17:20:57.000Z",
        client_notes: null,
        history_attachments: [
          {
            url: "1589519880155/aic.png",
            file_name: "aic.png",
            file_size: 2979,
            createdAt: "2020-05-15T05:18:09.000Z",
            FK_Distribution_History: "demo",
          },
        ],
      },
    ],
  },
  {
    id: "57025ee2-fe22-4c88-ab53-a32e745bb53a",
    completion: "Completed",
    version: "1.8",
    distribution_status: "PUBLISHED",
    rejectionReason: null,
    problem: "NONE",
    problem_details: null,
    link: "https://www.appinchina.co",
    user: "demo@appinchina.co",
    password: "Test123",
    version_being_submitted: null,
    rating: null,
    store: {
      name: "Oppo Software Store",
      market_coverage: 25.99,
      real_ranking: 1,
      scrapeable: true,
    },
    reviews: [],
    downloads: [
      {
        totalDownloads: 5000,
      },
    ],
    distribution_histories: [
      {
        id: "12345",
        user: "demo@appinchina.co",
        description: "Demo information",
        status: "PUBLISHED",
        date: "2020-05-04T17:20:57.000Z",
        client_notes: null,
        history_attachments: [
          {
            url: "1589519880155/aic.png",
            file_name: "aic.png",
            file_size: 2979,
            createdAt: "2020-05-15T05:18:09.000Z",
            FK_Distribution_History: "demo",
          },
        ],
      },
      {
        id: "12345",
        user: "demo@appinchina.co",
        description: "Demo information",
        status: "SUBMITTED_TO_STORE",
        date: "2020-05-04T17:20:57.000Z",
        client_notes: null,
        history_attachments: [
          {
            url: "1589519880155/aic.png",
            file_name: "aic.png",
            file_size: 2979,
            createdAt: "2020-05-15T05:18:09.000Z",
            FK_Distribution_History: "demo",
          },
        ],
      },
    ],
  },
  {
    id: "57025ee2-fe22-4c88-ab53-a32e745bb53a",
    completion: "Completed",
    version: "1.8",
    distribution_status: "PUBLISHED",
    rejectionReason: null,
    problem: "NONE",
    problem_details: null,
    link: "https://www.appinchina.co",
    user: "demo@appinchina.co",
    password: "Test123",
    version_being_submitted: null,
    rating: null,
    store: {
      name: "360 Mobile Assistant",
      market_coverage: 25.99,
      real_ranking: 1,
      scrapeable: true,
    },
    reviews: [],
    downloads: [
      {
        totalDownloads: 5000,
      },
    ],
    distribution_histories: [
      {
        id: "12345",
        user: "demo@appinchina.co",
        description: "Demo information",
        status: "PUBLISHED",
        date: "2020-05-04T17:20:57.000Z",
        client_notes: null,
        history_attachments: [
          {
            url: "1589519880155/aic.png",
            file_name: "aic.png",
            file_size: 2979,
            createdAt: "2020-05-15T05:18:09.000Z",
            FK_Distribution_History: "demo",
          },
        ],
      },
      {
        id: "12345",
        user: "demo@appinchina.co",
        description: "Demo information",
        status: "SUBMITTED_TO_STORE",
        date: "2020-05-04T17:20:57.000Z",
        client_notes: null,
        history_attachments: [
          {
            url: "1589519880155/aic.png",
            file_name: "aic.png",
            file_size: 2979,
            createdAt: "2020-05-15T05:18:09.000Z",
            FK_Distribution_History: "demo",
          },
        ],
      },
    ],
  },
];
const apkStoresFake = [
  {
    name: "Tencent MyApp",
    real_ranking: 1,
    id: "123456",
    id_reclaim: "123456",
    current_state: true,
    note: "Demo information.",
    apk_client_attachments: [
      {
        id: "12344556",
        date: moment(),
        file: "1589519880155/aic.png",
        user: "demo@appinchina.co",
        file_size: 2979,
        file_name: "aic.png",
      },
    ],
    apk_reclaim_attachments: [
      {
        id: "12344556",
        date: moment(),
        file: "1589519880155/aic.png",
        user: "demo@appinchina.co",
        file_size: 2979,
        file_name: "aic.png",
      },
    ],
  },
  {
    name: "Huawei App Market",
    real_ranking: 1,
    id: "123456",
    id_reclaim: "123456",
    current_state: true,
    note: "Demo information.",
    apk_client_attachments: [
      {
        id: "12344556",
        date: moment(),
        file: "1589519880155/aic.png",
        user: "demo@appinchina.co",
        file_size: 2979,
        file_name: "aic.png",
      },
    ],
    apk_reclaim_attachments: [
      {
        id: "12344556",
        date: moment(),
        file: "1589519880155/aic.png",
        user: "demo@appinchina.co",
        file_size: 2979,
        file_name: "aic.png",
      },
    ],
  },
  {
    name: "Oppo Software Store",
    real_ranking: 1,
    id: "123456",
    id_reclaim: "123456",
    current_state: true,
    note: "Demo information.",
    apk_client_attachments: [
      {
        id: "12344556",
        date: moment(),
        file: "1589519880155/aic.png",
        user: "demo@appinchina.co",
        file_size: 2979,
        file_name: "aic.png",
      },
    ],
    apk_reclaim_attachments: [
      {
        id: "12344556",
        date: moment(),
        file: "1589519880155/aic.png",
        user: "demo@appinchina.co",
        file_size: 2979,
        file_name: "aic.png",
      },
    ],
  },
  {
    name: "360 Mobile Assistant",
    real_ranking: 1,
    id: "123456",
    id_reclaim: "123456",
    current_state: true,
    note: "Demo information.",
    apk_client_attachments: [
      {
        id: "12344556",
        date: moment(),
        file: "1589519880155/aic.png",
        user: "demo@appinchina.co",
        file_size: 2979,
        file_name: "aic.png",
      },
    ],
    apk_reclaim_attachments: [
      {
        id: "12344556",
        date: moment(),
        file: "1589519880155/aic.png",
        user: "demo@appinchina.co",
        file_size: 2979,
        file_name: "aic.png",
      },
    ],
  },
];
const docsFake = {
  AUTHORIZATION: [
    {
      PK_App_Doc: "123456789",
      uploader: "demo@appinchina.co",
      type: "AUTHORIZATION",
      description: "Demo information.",
      createdAt: moment(),
      updatedAt: moment(),
      FK_App: "",
      app_documents_attachments: [
        {
          id: "123456789",
          date: moment(),
          file_name: "dummy.pdf",
          file_url: "1588622259893/dummy.pdf",
          file_size: 13264,
          user: "demo@appinchina.co",
        },
      ],
      app_documents_attachments_clients: [
        {
          id: "123456789",
          date: moment(),
          file_name: "dummy.pdf",
          file_url: "1588622259893/dummy.pdf",
          file_size: 13264,
          user: "demo@appinchina.co",
        },
      ],
    },
  ],
  COPYRIGHT: [
    {
      PK_App_Doc: "123456789",
      uploader: "demo@appinchina.co",
      type: "COPYRIGHT",
      description: "Demo information.",
      createdAt: moment(),
      updatedAt: moment(),
      FK_App: "",
      app_documents_attachments: [
        {
          id: "123456789",
          date: moment(),
          file_name: "dummy.pdf",
          file_url: "1588622259893/dummy.pdf",
          file_size: 13264,
          user: "demo@appinchina.co",
        },
      ],
      app_documents_attachments_clients: [
        {
          id: "123456789",
          date: moment(),
          file_name: "dummy.pdf",
          file_url: "1588622259893/dummy.pdf",
          file_size: 13264,
          user: "demo@appinchina.co",
        },
      ],
    },
  ],
  OTHER: [
    {
      PK_App_Doc: "123456789",
      uploader: "demo@appinchina.co",
      type: "OTHER",
      description: "Demo information.",
      createdAt: moment(),
      updatedAt: moment(),
      FK_App: "",
      app_documents_attachments: [
        {
          id: "123456789",
          date: moment(),
          file_name: "dummy.pdf",
          file_url: "1588622259893/dummy.pdf",
          file_size: 13264,
          user: "demo@appinchina.co",
        },
      ],
      app_documents_attachments_clients: [
        {
          id: "123456789",
          date: moment(),
          file_name: "dummy.pdf",
          file_url: "1588622259893/dummy.pdf",
          file_size: 13264,
          user: "demo@appinchina.co",
        },
      ],
    },
  ],
};
export {
  appDemoFake,
  downloadsFormat,
  appFormFake,
  copyrightFormFake,
  securityFormFake,
  companyFormFake,
  reviewsArrayFake,
  downloadsArrayFake,
  appChineseDemoFake,
  distributionStoresFake,
  apkStoresFake,
  docsFake,
  appReviewsFake,
};
