import React, { useState, useEffect } from "react";
import classes from "./SdkManagement.module.less";
import { Table, Avatar, Tag, Card } from "antd";
import { Link } from "react-router-dom";
import defaultImage from "../../assets/default-app.svg";

const SdkManagement = ({ apps, ...props }) => {
  let [appsData, setAppsData] = useState(
    apps.filter((app) => app.id !== "1234-8475-9439-9495")
  );
  let columns = [
    {
      title: "Icon",
      dataIndex: "image_data",
      fixed: "left",
      width: 65,
      render: (image_data) => {
        let imageData = image_data ? image_data : defaultImage;
        imageData = /uploads|static|googleusercontent/.test(imageData)
          ? imageData
          : `data:image/png;base64,${imageData}`;
        return (
          <Avatar
            shape="square"
            src={imageData.replace("appinchina.xyz", "appinchina.space")}
          />
        );
      },
    },
    {
      title: "App Name",
      dataIndex: "main_information.aicpay_appname",
      fixed: "left",
      width: 200,
      render: (name, it) => name || it.name,
    },
    {
      title: "App ID",
      dataIndex: "analitycs_id",
      render: (it) => it || "N/A",
      width: 120,
    },
    {
      title: "App Secret",
      dataIndex: "main_information.aicpay_secret",
      render: (it) => it || "N/A",
    },
    {
      title: "Wechat Pay",
      dataIndex: "wechat_id",
      render: (_, { wechat_id, seccond_wechat_id }) =>
        wechat_id || seccond_wechat_id ? (
          <Tag color="green">Accesible</Tag>
        ) : (
          <Tag color="orange">Not Accesible</Tag>
        ),
    },
    {
      title: "Alipay Pay",
      dataIndex: "alipay_id",
      render: (_, { alipay_id, seccond_alipay_id }) =>
        alipay_id || seccond_alipay_id ? (
          <Tag color="green">Accesible</Tag>
        ) : (
          <Tag color="orange">Not Accesible</Tag>
        ),
    },
    {
      title: "Alipay Auto Renewal",
      dataIndex: "alipay_auto_renewal",
      render: (alipay_auto_renewal) =>
        alipay_auto_renewal ? (
          <Tag color="green">Accesible</Tag>
        ) : (
          <Tag color="orange">Not Accesible</Tag>
        ),
    },
    {
      title: "Analytics",
      dataIndex: "main_information.analytics_enabled",
      render: (analytics_enabled) =>
        analytics_enabled ? (
          <Tag color="green">Accesible</Tag>
        ) : (
          <Tag color="orange">Not Accesible</Tag>
        ),
    },
    {
      title: "Option",
      dataIndex: "id",
      render: (id) => <Link to={`/sdk-management/details/${id}`}>Manage</Link>,
    },
  ];

  let shortcutsColumns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Link",
      dataIndex: "link",
      render: (link) => (
        <a href={link} rel="noopener noreferrer" target="_blank">
          {link}
        </a>
      ),
    },
  ];

  let shortcuts = [
    {
      name: "Payments SDK",
      link: "https://documentation.appinchina.co/payments_sdk.zip",
    },
    {
      name: "Analytics SDK",
      link: "https://documentation.appinchina.co/analytics_sdk.zip",
    },
    {
      name: "Combined SDK (Analytics + Payments)",
      link: "https://documentation.appinchina.co/sdk.zip",
    },
    {
      name: "Developer Documentation",
      link: "https://documentation.appinchina.co/",
    },
  ];

  return (
    <div className={classes.container}>
      <Table
        scroll={{ x: 1050 }}
        columns={columns}
        dataSource={appsData}
        rowKey={(app) => app.id}
        bordered
      />
      <Card title={`By clicking on "Manage" you can:`}>
        <ol className={classes.list}>
          <li>Apply for In-App Payments function (WeChat & Alipay)</li>
          <li>
            Keep track of In-App Purchase items, adding and managing API
            parameters
          </li>
          <li>
            Manage single purchase items and subscriptions items (if the
            function is opened for your app)
          </li>
          <li>View contracts for IAP Function</li>
          <li>Change certain settings for your app</li>
        </ol>
      </Card>
      <Table
        className={classes.shortcuts}
        bordered
        title={() => "Shortcuts"}
        columns={shortcutsColumns}
        dataSource={shortcuts}
        rowKey="name"
      />
    </div>
  );
};

export default SdkManagement;
