import React, { useState, useEffect } from "react";
import history from "../../../history";
import classes from "./AppSelect.module.less";
import { Select, Divider, Icon } from "antd";
import defaultImage from "../../../assets/default-app.svg";

function verifyAppId(id, apps, section) {
  let appFound = apps.find((app) => app.id === id);
  if (
    !appFound &&
    id !== "1234-8475-9439-9494" &&
    id !== "1234-8475-9439-9495" &&
    history.location.pathname.replace(/\//g, "") !== "new"
  ) {
    history.push(`/`);
  }
  let myApps = apps.filter(
    (e) => e.id !== "1234-8475-9439-9494" && e.id !== "1234-8475-9439-9495"
  );

  if (
    (id === "1234-8475-9439-9494" || id === "1234-8475-9439-9495") &&
    section === "cart" &&
    myApps.length > 0
  ) {
    history.push(`/cloud/cart/${myApps[0].id}`);
  }
}
const AppSelect = (props) => {
  const { Option, OptGroup } = Select;
  let [hideDemo, setHideDemo] = useState(false);
  verifyAppId(props.appId, props.apps, props.section, setHideDemo);

  const handleChange = (value) => {
    let newApp = apps.find(it => it.id === value)
    console.log("new app value", value)
    console.log("new app props.appId", props.appId)
    console.log("new app =>", newApp)
    if (newApp && newApp.role === "PAYMENTS") {
      history.push(`/monetization/${value}/summary`)
    }
    else {
      history.push(history.location.pathname.replace(props.appId, value));
    }
  };


  let apps = props.apps.filter(
    ({ id }) => id !== "1234-8475-9439-9494" && id !== "1234-8475-9439-9495"
  );
  let demoApps = props.apps.filter(
    ({ id }) => id === "1234-8475-9439-9494" || id === "1234-8475-9439-9495"
  );

  return (
    <Select
      className={classes.select}
      placeholder="Select an App..."
      value={props.appId ? props.appId : "New App..."}
      onChange={handleChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div
            style={{ padding: "4px 8px", cursor: "pointer" }}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => history.push("/new")}
          >
            <Icon type="plus" /> Add New App
          </div>
        </div>
      )}
    >
      <OptGroup label="My Apps">
        {apps
          ? apps.map((it) => {
            let imageData = it.image_data ? it.image_data : defaultImage;
            imageData = /uploads|static|googleusercontent/.test(imageData)
              ? imageData
              : `data:image/png;base64,${imageData}`;
            return (
              <Option key={it.id} value={it.id} label={it.name}>
                <span role="img" aria-label={it.name}>
                  <img
                    src={imageData.replace(
                      "appinchina.xyz",
                      "appinchina.space"
                    )}
                    alt="App Logo"
                    className={classes.SelectImg}
                  />{" "}
                  {it.name}
                </span>
              </Option>
            );
          })
          : null}
      </OptGroup>
      {!hideDemo ? (
        <OptGroup label="Demo Apps">
          {demoApps
            ? demoApps.map((it) => {
              let imageData = it.image_data ? it.image_data : defaultImage;
              imageData = /uploads|static|googleusercontent/.test(imageData)
                ? imageData
                : `data:image/png;base64,${imageData}`;
              return (
                <Option key={it.id} value={it.id} label={it.name}>
                  <span role="img" aria-label={it.name}>
                    <img
                      src={imageData.replace(
                        "appinchina.xyz",
                        "appinchina.space"
                      )}
                      alt="App Logo"
                      className={classes.SelectImg}
                    />{" "}
                    {it.name}
                  </span>
                </Option>
              );
            })
            : null}
        </OptGroup>
      ) : null}
    </Select>
  );
};

export default AppSelect;
