//home page
import {
  transactionsBalanceFake,
  totalDownloadsFake,
  reviewsFake,
  downloadsFake,
  storesPublished,
  usersArrayFake,
  transactionsArrayFake,
} from "../src/components/WelcomePage/FakeData";
import {
  appFormFake,
  copyrightFormFake,
  securityFormFake,
  companyFormFake,
  downloadsArrayFake,
  reviewsArrayFake,
  distributionStoresFake,
  apkStoresFake,
  docsFake,
  appReviewsFake,
} from "../src/components/Distribution/FakeData";
import { badgeFake } from "../src/components/Distribution/DistributionDetails/AppInChinaBadge/BadgeTrakingDetails/FakeData";
import {
  accountFake,
  ordersFake,
} from "../src/components/CloudStore/CloudFake";
import { dataBadgeFake } from "../src/components/BadgeHome/BadgeAnalytics/BadgeAnalyticsFake";
import {
  sdks_fake,
  translations_fake,
} from "../src/components/Localization/localizationFake";
export default [
  //home page
  {
    url: "/welcome-downloads/1234-8475-9439-9494",
    method: "get",
    response: {
      data: {
        downloads: downloadsFake,
        reviews: reviewsFake,
        total_downloads: totalDownloadsFake,
      },
    },
  },
  {
    url: "/welcome-app-stores/1234-8475-9439-9494",
    method: "get",
    response: {
      data: [
        {
          stores_to_upload: 8,
          uploaded_stores_count: 4,
        },
      ],
    },
  },
  {
    url: "/welcome-distribution/1234-8475-9439-9494",
    method: "get",
    response: {
      data: {
        id: "1234-8475-9439-9494",
        information: storesPublished,
        main_information: {
          copyright_form_status: "WAITING",
          app_form_status: "NOT_RECEIVED",
          business_form_status: "NOT_RECEIVED",
          corporate_client: false,
          security_form_status: "NOT_RECEIVED",
        },
      },
    },
  },
  {
    url: "/welcome-revenue/",
    method: "post",
    data: {
      app: "1234-8475-9439-9494",
    },
    response: {
      data: {
        refunds: [],
        refundsBalance: 0,
        transactions: transactionsArrayFake,
        transactionsBalance: transactionsBalanceFake,
        users: usersArrayFake,
      },
    },
  },
  //home page chinese demo app
  {
    url: "/welcome-downloads/1234-8475-9439-9495",
    method: "get",
    response: {
      data: {
        downloads: downloadsFake,
        reviews: reviewsFake,
        total_downloads: totalDownloadsFake,
      },
    },
  },
  {
    url: "/welcome-app-stores/1234-8475-9439-9495",
    method: "get",
    response: {
      data: [
        {
          stores_to_upload: 8,
          uploaded_stores_count: 4,
        },
      ],
    },
  },
  {
    url: "/welcome-distribution/1234-8475-9439-9495",
    method: "get",
    response: {
      data: {
        id: "1234-8475-9439-9495",
        information: storesPublished,
        main_information: {
          copyright_form_status: "WAITING",
          app_form_status: "NOT_RECEIVED",
          business_form_status: "NOT_RECEIVED",
          corporate_client: false,
          security_form_status: "NOT_RECEIVED",
        },
      },
    },
  },
  {
    url: "/welcome-revenue/",
    method: "post",
    data: {
      app: "1234-8475-9439-9495",
    },
    response: {
      data: {
        refunds: [],
        refundsBalance: 0,
        transactions: transactionsArrayFake,
        transactionsBalance: transactionsBalanceFake,
        users: usersArrayFake,
      },
    },
  },
  //distribution demo app
  {
    url: "/app-form/1234-8475-9439-9494",
    method: "get",
    response: {
      data: appFormFake,
    },
  },
  {
    url: "/app-form",
    method: "put",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/app-form",
    method: "post",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/copyright-form/1234-8475-9439-9494",
    method: "get",
    response: {
      data: copyrightFormFake,
    },
  },
  {
    url: "/copyright-form",
    method: "put",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/copyright-form",
    method: "post",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/security-assessment-form/1234-8475-9439-9494",
    method: "get",
    response: {
      data: securityFormFake,
    },
  },
  {
    url: "/security-assessment-form",
    method: "post",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/security-assessment-form",
    method: "put",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/company-form/1234-8475-9439-9494",
    method: "get",
    response: {
      data: companyFormFake,
    },
  },
  {
    url: "/company-form",
    method: "put",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/company-form",
    method: "post",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/review-reply",
    method: "post",
    data: { app_id: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/downloads",
    method: "post",
    data: { app_id: "1234-8475-9439-9494" },
    response: {
      data: downloadsArrayFake,
    },
  },
  {
    url: "/manual-reviews/1234-8475-9439-9494",
    method: "get",
    response: {
      data: { message: reviewsArrayFake },
    },
  }, //distribution chinese demo app
  {
    url: "/app-form/1234-8475-9439-9495",
    method: "get",
    response: {
      data: appFormFake,
    },
  },
  {
    url: "/app-form",
    method: "put",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/app-form",
    method: "post",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/copyright-form/1234-8475-9439-9495",
    method: "get",
    response: {
      data: copyrightFormFake,
    },
  },
  {
    url: "/copyright-form",
    method: "put",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/copyright-form",
    method: "post",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/security-assessment-form/1234-8475-9439-9495",
    method: "get",
    response: {
      data: securityFormFake,
    },
  },
  {
    url: "/security-assessment-form",
    method: "post",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/security-assessment-form",
    method: "put",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/company-form/1234-8475-9439-9495",
    method: "get",
    response: {
      data: companyFormFake,
    },
  },
  {
    url: "/company-form",
    method: "put",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/company-form",
    method: "post",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/review-reply",
    method: "post",
    data: { app_id: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/downloads",
    method: "post",
    data: { app_id: "1234-8475-9439-9495" },
    response: {
      data: downloadsArrayFake,
    },
  },
  {
    url: "/manual-reviews/1234-8475-9439-9495",
    method: "get",
    response: {
      data: { message: reviewsArrayFake },
    },
  },
  //acquisition
  {
    url: "/acquisition",
    method: "post",
    data: { app_id: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  //acquisiton chinese demo app
  {
    url: "/acquisition",
    method: "post",
    data: { app_id: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  //badge Tracking
  {
    url: "/badge-tracking?app=1234-8475-9439-9494",
    method: "get",
    response: {
      data: {
        ...badgeFake,
      },
    },
  },
  //badge Tracking chinese demo
  {
    url: "/badge-tracking?app=1234-8475-9439-9495",
    method: "get",
    response: {
      data: {
        ...badgeFake,
      },
    },
  },
  //localization demo app
  {
    url: "/localization/apk-files",
    method: "post",
    data: { app_id: "1234-8475-9439-9494" },
    response: {
      data: sdks_fake,
    },
  },
  {
    url: "/apk-file",
    method: "post",
    data: { app_id: "1234-8475-9439-9494" },
    response: {
      data: { PK_Apk: "1234-8475-9439-9494" },
    },
  },
  {
    url: "/sdk",
    method: "post",
    data: { apk_id: "1234-8475-9439-9494" },
    response: {
      data: [{ PK_Apk: "1234-8475-9439-9494" }],
    },
  },
  {
    url: "/icp-web-hosting",
    method: "post",
    data: { FK_App: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/get-icp",
    method: "post",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: {},
    },
  },
  {
    url: "/get-intellectual-property",
    method: "post",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: {},
    },
  },
  {
    url: "/intellectual-property",
    method: "post",
    data: { FK_App: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/company-incorporation",
    method: "post",
    data: { FK_App: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/get-company-incorporation",
    method: "post",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: {},
    },
  },
  {
    url: "/get-translations",
    method: "post",
    data: { app_id: "1234-8475-9439-9494" },
    response: {
      data: translations_fake,
    },
  },
  {
    url: "/translation",
    method: "post",
    data: { FK_App: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  //localization chinese demo app
  {
    url: "/localization/apk-files",
    method: "post",
    data: { app_id: "1234-8475-9439-9495" },
    response: {
      data: sdks_fake,
    },
  },
  {
    url: "/apk-file",
    method: "post",
    data: { app_id: "1234-8475-9439-9495" },
    response: {
      data: { PK_Apk: "1234-8475-9439-9495" },
    },
  },
  {
    url: "/sdk",
    method: "post",
    data: { apk_id: "1234-8475-9439-9495" },
    response: {
      data: [{ PK_Apk: "1234-8475-9439-9495" }],
    },
  },
  {
    url: "/icp-web-hosting",
    method: "post",
    data: { FK_App: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/get-icp",
    method: "post",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: {},
    },
  },
  {
    url: "/get-intellectual-property",
    method: "post",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: {},
    },
  },
  {
    url: "/intellectual-property",
    method: "post",
    data: { FK_App: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/company-incorporation",
    method: "post",
    data: { FK_App: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/get-company-incorporation",
    method: "post",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: {},
    },
  },
  {
    url: "/get-translations",
    method: "post",
    data: { app_id: "1234-8475-9439-9495" },
    response: {
      data: translations_fake,
    },
  },
  {
    url: "/translation",
    method: "post",
    data: { FK_App: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },

  //CLOUD REQUEST

  {
    url: "/account-balance/1234-8475-9439-9495",
    method: "get",
    response: {
      data: {
        ...accountFake,
      },
    },
  },
  {
    url: "/account-balance/1234-8475-9439-9494",
    method: "get",
    response: {
      data: {
        ...accountFake,
      },
    },
  },
  {
    url: "/cloud-orders/1234-8475-9439-9495",
    method: "get",
    response: {
      data: {
        orders: [...ordersFake],
      },
    },
  },
  {
    url: "/cloud-orders/1234-8475-9439-9494",
    method: "get",
    response: {
      data: {
        orders: [...ordersFake],
      },
    },
  },
  {
    url: "/cloud-orders",
    method: "post",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/cloud-orders",
    method: "post",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/account-balance",
    method: "post",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/account-balance",
    method: "post",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/cloud-orders",
    method: "put",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/cloud-orders",
    method: "put",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  //Badge Analytics
  {
    url: "/badge-pages/com.demo.app",
    method: "get",
    response: {
      data: {
        ...dataBadgeFake,
      },
    },
  },
  {
    url: "/cloud-order/1234-8475-9439-9494-1231",
    method: "get",
    response: {
      data: ordersFake.filter(
        (e) => e.PK_Order === "1234-8475-9439-9494-1231"
      )[0],
    },
  },
  {
    url: "/cloud-order/1234-8475-9439-9494-1232",
    method: "get",
    response: {
      data: ordersFake.filter(
        (e) => e.PK_Order === "1234-8475-9439-9494-1232"
      )[0],
    },
  },
  {
    url: "/cloud-order/1234-8475-9439-9494-1233",
    method: "get",
    response: {
      data: ordersFake.filter(
        (e) => e.PK_Order === "1234-8475-9439-9494-1233"
      )[0],
    },
  },
  //renew order
  {
    url: "/renew-order",
    method: "post",
    data: { appId: "1234-8475-9439-9494" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/renew-order",
    method: "post",
    data: { appId: "1234-8475-9439-9495" },
    response: {
      data: { message: "success" },
    },
  },
  {
    url: "/distribution/1234-8475-9439-9494",
    method: "get",
    response: {
      data: { result: distributionStoresFake },
    },
  },
  {
    url: "/distribution/1234-8475-9439-9495",
    method: "get",
    response: {
      data: { result: distributionStoresFake },
    },
  },
  {
    url: "/distribution-results/1234-8475-9439-9494",
    method: "get",
    response: {
      data: {
        averageRating: "8/10",
        marketCoverage: "80",
        numberOfReviews: 12736,
        totalDownloads: "10000",
      },
    },
  },
  {
    url: "/distribution-results/1234-8475-9439-9495",
    method: "get",
    response: {
      data: {
        averageRating: "9/10",
        marketCoverage: "90",
        numberOfReviews: 13736,
        totalDownloads: "20000",
      },
    },
  },
  {
    url: "/distribution-reclaiming/1234-8475-9439-9494",
    method: "get",
    response: {
      data: { result: apkStoresFake },
    },
  },
  {
    url: "/distribution-reclaiming/1234-8475-9439-9495",
    method: "get",
    response: {
      data: { result: apkStoresFake },
    },
  },
  {
    url: "/app-documents/1234-8475-9439-9494",
    method: "get",
    response: {
      data: { docs: docsFake },
    },
  },
  {
    url: "/app-documents/1234-8475-9439-9495",
    method: "get",
    response: {
      data: { docs: docsFake },
    },
  },
  {
    url: "/reviews/1234-8475-9439-9494",
    method: "get",
    response: {
      data: {
        result: appReviewsFake,
      },
    },
  },
  {
    url: "/reviews/1234-8475-9439-9495",
    method: "get",
    response: {
      data: {
        result: appReviewsFake,
      },
    },
  },
];
