import React, { useState, useEffect } from "react";
import classes from "./ApkReclaim.module.less";
import { Table, notification, Button, Modal } from "antd";
import {
  getApkReclaiming,
  newApkAttachment,
} from "../../../../services/apkReclaimService";
import LoadingSpin from "../../../UI/LoadingSpin/LoadingSpin";
import NewFeature from "../../../UI/NewFeature/AsyncNewFeature";
import AttachmentCard from "./AttachmentCard/AttachmentCard";
import _360 from "../../../../assets/blank/360blank.apk";
import baidu from "../../../../assets/blank/baidublank.apk";
import huawei from "../../../../assets/blank/huaweiblank.apk";
import oppo from "../../../../assets/blank/oppoblank.apk";
import vivo from "../../../../assets/blank/vivoblank.apk";
import xiaomi from "../../../../assets/blank/xiaomiblank.apk";
import tencent from "../../../../assets/blank/tencentblank.apk";

const blankApks = {
  "360 Mobile Assistant": _360,
  "Baidu Mobile Assistant": baidu,
  "Huawei App Market": huawei,
  "Oppo Software Store": oppo,
  "VIVO App Store": vivo,
  "MIUI App Store": xiaomi,
  "Tencent MyApp": tencent,
};

export default ({ appId, isOwner }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, [appId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (appId) {
        const { data } = await getApkReclaiming(appId);
        if (data) {
          setData(data.result);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const uploadReclaimingFile = async ({ id, id_reclaim, files }) => {
    try {
      const { data: response } = await newApkAttachment({
        id,
        id_reclaim,
        files,
      });
      if (response && response.attachments) {
        const dataCopy = data;
        const index = dataCopy.findIndex((item) => item.id === id);
        dataCopy[index] = {
          ...dataCopy[index],
          apk_client_attachments: [
            ...dataCopy[index].apk_client_attachments,
            ...response.attachments,
          ],
        };
        setData([...dataCopy]);
        notification.info({ message: "Attachment Saved." });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const removeAttachmentFromState = (id_store, id_remove) => {
    if (isOwner) {
      const dataCopy = data;
      const index = dataCopy.findIndex((item) => item.id === id_store);
      const newAttachments = dataCopy[index].apk_client_attachments.filter(
        (item) => item.id != id_remove
      );
      dataCopy[index] = {
        ...dataCopy[index],
        apk_client_attachments: newAttachments,
      };
      notification.info({
        message: "Attachment removed.",
      });
      setData([...dataCopy]);
    }
  };

  let columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "Store Name",
      render: (name, item) => {
        return (
          <NewFeature
            show={item.viewed === false}
            id={item.id_reclaim}
            category="apk_reclaim"
          >
            {name}
          </NewFeature>
        );
      },
    },
    {
      key: "note",
      dataIndex: "note",
      title: "AppInChina Note",
    },
    {
      key: "blank",
      dataIndex: "blank",
      title: "Blank Apk",
      render: (_, item) =>
        blankApks[item.name] ? (
          <a
            href={blankApks[item.name]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button icon="download">Download</Button>
          </a>
        ) : (
          "N/A"
        ),
    },
  ];
  return (
    <div className={classes.container}>
      <Modal visible={visible} footer={null} onCancel={() => setVisible(false)}>
        The followings are the instructions on how to sign the empty apk
        provided by the app stores in Both English and Chinese. Operations staff
        may provide the information to clients when their developers are not
        sure how to sign the apk. How to sign:
        <br />
        <br />
        jarsigner－verbose-keystorePath]-singnedjar[apkout][apkin][alias]
        <br />
        <br />
        Jarsigner command format: -verbose output details - keystore location -
        alias demo. keystore alias demo. keystore alias demo. keystore keyalg
        RSA uses the RSA algorithm to encrypt the signature validity 40000
        Limited to 4000 days -keystore demo.keystore
        <br />
        <br />
        D:\>jarsigner -verbose -keystore demo.keystore -signedjar
        demo_signed.apk demo.apk demo.keystore /*Description: -verbose output
        signature details */ for example
        <br />
        <br />
        D:\>jarsigner -verbose -keystore demo.keystore -signedjar
      </Modal>
      {loading ? (
        <LoadingSpin />
      ) : !!data && data.length ? (
        <>
          <p className={classes.introText}>
            Your app has been automatically grabbed by the following stores. In
            order to reclaim ownership please download and have your development
            team sign the blank APK provided by the store with the same
            signature as your app. After signing the APK upload it back here by
            expanding the appropriate row on the following table.
            <br />
            <br />
            For more information about app signing please visit:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://developer.android.com/studio/publish/app-signing/"
            >
              https://developer.android.com/studio/publish/app-signing/
            </a>
            or{" "}
            <b
              onClick={() => setVisible(true)}
              style={{ cursor: "pointer", fontWeight: 700 }}
            >
              click here
            </b>
            <br />
          </p>
          <br />
          <Table
            size="small"
            rowKey={(record) => record.id}
            scroll={{ x: 700 }}
            expandedRowRender={(record) => (
              <AttachmentCard
                isOwner={isOwner}
                data={record.apk_reclaim_attachments}
                deleteHandler={(id_remove) =>
                  removeAttachmentFromState(record.id, id_remove)
                }
                clientData={record.apk_client_attachments.filter(
                  (e) => e.file !== null
                )}
                update={({ files }) =>
                  uploadReclaimingFile({
                    id_reclaim: record.id_reclaim,
                    id: record.id,
                    files,
                  })
                }
              />
            )}
            dataSource={data}
            columns={columns}
          />
        </>
      ) : (
        <p>No information to display</p>
      )}
    </div>
  );
};
