import React, { useState, Fragment, useEffect } from "react";
import classes from "./Configuration.module.less";
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Modal,
  notification,
  Alert,
} from "antd";
import {
  getAllTags,
  updateTag,
  createTag,
  deleteTag,
} from "../../../services/pushNotificationsService";
import moment from "moment";
import { DEMO_TAGS } from "../FakeData";
import LineCard from "../../UI/LineCard/LineCard";

const Tags = ({ app, useDemoData, ...props }) => {
  let [loading, setLoading] = useState(false);
  let [modalLoading, setModalLoading] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let [values, setValues] = useState([]);
  let [search, setSearch] = useState("");
  let [tagValue, setTagValue] = useState("");
  let [tagId, setTagId] = useState("");
  let appId = app.id;
  console.log("appId", appId);

  const tableColumns = () => [
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Tag Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "",
      dataIndex: "tagId",
      key: "tagId",
      render: (tagId, tag) => (
        <>
          <Button onClick={() => editTag(tag)}>Edit</Button>
          <Button
            onClick={() => confirmDeleteTag(tag)}
            type="danger"
            ghost
            style={{ marginLeft: "8px" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    fetch();
  }, [app.id, useDemoData]);

  const fetch = async (text = "") => {
    if (!useDemoData) {
      console.log("fetching");
      try {
        setLoading(true);
        let response = await getAllTags(appId, text);
        setLoading(false);
        if (response && response.data.tags) {
          setValues(response.data.tags);
        }
      } catch (error) {
        console.log("error while searching", error);
      }
    } else {
      console.log("demo data search");
    }
  };

  const searchResults = (value) => {
    console.log("searching: ", value);
    fetch(value);
  };

  const resetFilter = () => {
    setSearch("");
    fetch();
  };

  let editTag = (tag) => {
    setTagId(tag.tagId);
    setTagValue(tag.title);
    setShowModal(true);
  };

  let confirmDeleteTag = (tag) => {
    Modal.confirm({
      title: "Do you want to delete the tag?",
      content: "The information will be deleted from the database",
      async onOk() {
        let response = await deleteTag(appId, tag.tagId);
        if (response && response.data && response.data.message === "success") {
          notification.success({
            message: "Tag Deleted",
          });
          setValues(response.data.tags);
          setSearch("");
        } else {
          notification.error({
            message: "Error while deleting",
          });
        }
      },
      onCancel() {},
    });
  };

  let addNewTag = () => {
    setTagId(null);
    setTagValue("");
    setShowModal(true);
    setSearch("");
  };

  let saveTag = async () => {
    setModalLoading(true);
    let response = null;
    if (tagId) {
      response = await updateTag(appId, tagId, tagValue);
    } else {
      response = await createTag(appId, tagValue);
    }
    if (response && response.data && response.data.message === "success") {
      notification.success({
        message: "Saved Tag",
      });
      setValues(response.data.tags);
      setSearch("");
    } else {
      notification.error({
        message: "Error while saving",
      });
    }
    setModalLoading(false);
    setShowModal(false);
  };

  return (
    <Fragment>
      <Modal
        visible={showModal}
        onOk={saveTag}
        confirmLoading={modalLoading}
        title={tagId ? "Edit Tag" : "Add Tag"}
        onCancel={() => setShowModal(false)}
      >
        <Input value={tagValue} onChange={(e) => setTagValue(e.target.value)} />
      </Modal>
      <div className={classes.intro}>
        {useDemoData ? (
          <>
            <Alert
              message="You need to have push notifications enabled to use this feature."
              description="Please contact your account manager for details."
              type="warning"
              showIcon
              style={{ textAlign: "left" }}
            />
            <p></p>
          </>
        ) : null}
        <LineCard shadow>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </LineCard>
      </div>
      <div className={classes.tableContainer}>
        <Row gutter={16}>
          <Col style={{ marginTop: 12 }} xs={24} sm={12} md={8} lg={8} xl={8}>
            <Input.Search
              placeholder="Tag Name"
              enterButton="Search"
              onSearch={searchResults}
              value={search}
              loading={loading}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col style={{ marginTop: 12 }} xs={6} sm={5} md={5} lg={4} xl={4}>
            <Button
              style={{ width: "100%" }}
              onClick={resetFilter}
              disabled={loading}
            >
              Reset Filter
            </Button>
          </Col>
          <Col style={{ marginTop: 12 }} xs={6} sm={5} md={5} lg={4} xl={4}>
            <Button
              style={{ width: "100%" }}
              onClick={addNewTag}
              disabled={loading}
              type="primary"
            >
              Add New
            </Button>
          </Col>
        </Row>
        <div style={{ paddingTop: "16px" }}>
          <Table
            dataSource={useDemoData ? DEMO_TAGS : values}
            rowKey="gmtCreate"
            columns={tableColumns()}
            size="small"
            loading={loading}
            scroll={{ x: 700 }}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default Tags;
