import { Radio } from "antd";
import React from "react";
import DeviceLocation from "./DeviceLocation";
import DeviceLocationMap from "./DeviceLocationMap";

export default class extends React.Component {
  state = {
    type: "chart"
  };
  render() {
    return (
      <div style={{ height: 600 }}>
        {/*<Radio.Group
          value={this.state.type}
          size="small"
          onChange={e => this.setState({ type: e.target.value })}
        >
          <Radio.Button value="chart">Chart</Radio.Button>
          <Radio.Button value="map">Map</Radio.Button>
        </Radio.Group>*/}
        <div
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
            paddingTop: "16px"
          }}
        >
          {this.state.type === "chart" ? (
            <DeviceLocation
              useDemoData={this.props.useDemoData}
              data={this.props.data}
            />
          ) : (
            <DeviceLocationMap
              useDemoData={this.props.useDemoData}
              data={this.props.data}
            />
          )}
        </div>
      </div>
    );
  }
}
