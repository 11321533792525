import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import File from "../../UI/File/File";
import classes from "../ClientForms.module.less";
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Radio,
  Popover,
  Icon,
  notification,
} from "antd";
import moment from "moment";
import LineCard from "../../UI/LineCard/LineCard";
import DemoAppAlert from "../../DemoAppAlert/DemoAppAlert";
import { getForm } from "../../../services/distributionService";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";
import axios from "../../../axios";
import { validateAppName } from "../../../functions";

const { Item } = Form;
const CopyrightForm = ({ form, role, match }) => {
  const disabled = role === "VIEWER";
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { app_id } = match.params;

  useEffect(() => {
    getCopyrightForm();
  }, []);

  const getCopyrightForm = async () => {
    try {
      setLoading(true);
      const { data } = await getForm("copyright-form", app_id);
      setFormData(data);
      setLoading(false);
    } catch (error) {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const newFormSubmition = {
          ...values,
          completion_date:
            !!values.completion_date &&
            values.completion_date.format("YYYY-MM-DD"),
          date_publication:
            !!values.date_publication &&
            values.date_publication.format("YYYY-MM-DD"),
        };
        setSaving(true);
        let result = await axios.post("copyright-form", {
          appId: app_id,
          form_data: newFormSubmition,
        });
        result && result.data
          ? showNotification("success", "Information Sent!")
          : showNotification("error", "An error happened");

        if (window.mixpanel) {
          window.mixpanel.track("Copyright form submitted");
        }
        setSaving(false);
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft",
        });
      }
    });
  };
  const handleSubmitDraft = async () => {
    let values = form.getFieldsValue();
    setSaving(true);
    let result = await axios.put("copyright-form", {
      appId: app_id,
      form_data: values,
    });
    result && result.data
      ? showNotification("success", "Information Saved!")
      : showNotification("error", "An error happened");

    setSaving(false);
  };
  const showNotification = (type, message) => {
    notification[type]({
      message,
      duration: 0,
    });
  };

  const deleteFile = (field) => {
    form.setFieldsValue({ [field]: null });
  };

  const submitFile = ({ name, value }) => {
    form.setFieldsValue({ [name]: value });
  };

  const { getFieldDecorator } = form;
  const completionDateValue = formData.completion_date
    ? { initialValue: moment(new Date(formData.completion_date), "DD/MM/YYYY") }
    : {};
  const publicationDateValue = formData.date_publication
    ? {
        initialValue: moment(new Date(formData.date_publication), "DD/MM/YYYY"),
      }
    : {};

  return loading ? (
    <LoadingSpin />
  ) : (
    <>
      <DemoAppAlert appId={app_id} />
      <div className={classes.Form}>
        <LineCard shadow>
          <p>
            The Software Copyright Certificate is a document that is legally
            required in order to publish an app in China. As per term 7.3 of the
            subscription agreement, AppInChina will apply for this certificate
            on behalf of your company but full legal ownership and control of
            the certificate will belong to and be retained by your company.
          </p>
        </LineCard>
        <Form onSubmit={handleSubmit} colon={false} layout="vertical">
          <LineCard title="App Information" shadow>
            <Row gutter={16} type="flex" justify="space-around">
              <Col xs={24} lg={12}>
                <Item label="App Version Number">
                  {getFieldDecorator(`version`, {
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                    initialValue: formData.version,
                  })(<Input disabled={disabled} />)}
                </Item>
                <Fragment>
                  <Item
                    label={
                      <Popover
                        placement="bottomLeft"
                        content={
                          <p>
                            1) If you want to change the app of your name you
                            must apply for a new copyright certificate.
                            (Example: you originally applied for the English
                            name on the copyright, but you changed your mind and
                            want to use the Chinese name instead, in this case
                            you would need to reapply for a new copyright)
                            <br />
                            <br />
                            2) If you are changing the owner of the copyright.
                            (Example, you have your copyright under your foreign
                            entity, but you want to get more coverage in China
                            so you decide to publish under AppInChina, in this
                            case you would need to reapply for a new copyright)
                          </p>
                        }
                      >
                        App Name <Icon type="question-circle-o" />
                      </Popover>
                    }
                  >
                    {getFieldDecorator(`appname_en`, {
                      rules: [
                        { required: true, message: "Required" },
                        {
                          pattern: /^([\u4E00-\uFA29]*[0-9a-zA-Z- ]*)*$/,
                          message:
                            "Your App Name can only contain Chinese characters, English characters, spaces or hyphens. No other special characters are allowed. Please ensure the App Name within your app meets these requirements.",
                        },
                      ],
                      initialValue: formData.appname_en,
                    })(
                      <Input
                        onChange={(event) =>
                          validateAppName(
                            event.target.value.toLocaleLowerCase()
                          )
                        }
                        disabled={disabled}
                      />
                    )}
                  </Item>
                </Fragment>
                <Fragment>
                  <Item
                    label={
                      <Popover
                        placement="bottomLeft"
                        content={
                          <p>
                            The Software Copyright Certificate can be applied
                            for using two app names. <br /> Please provide an
                            alternative name for your app that you may
                            potentially <br /> want to use in future.
                          </p>
                        }
                      >
                        App Name (Alternative Version){" "}
                        <Icon type="question-circle-o" />
                      </Popover>
                    }
                  >
                    {getFieldDecorator(`app_short_name`, {
                      rules: [
                        {
                          required: true,
                          message: "Required",
                        },
                      ],
                      initialValue: formData.app_short_name,
                    })(
                      <Input
                        onChange={(event) =>
                          validateAppName(
                            event.target.value.toLocaleLowerCase()
                          )
                        }
                        disabled={disabled}
                      />
                    )}
                  </Item>
                  <Item label="Is your app ready to be published immediately ? ">
                    {getFieldDecorator(`published`, {
                      initialValue: formData.published,
                    })(
                      <Radio.Group disabled={disabled}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    )}
                  </Item>
                </Fragment>
              </Col>
              <Col xs={24} lg={12}>
                <Item label="App Completion Date">
                  {getFieldDecorator(`completion_date`, {
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                    ...completionDateValue,
                  })(
                    <DatePicker disabled={disabled} style={{ width: "100%" }} />
                  )}
                </Item>
                <Item label="First Publication Date">
                  {getFieldDecorator(`date_publication`, {
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                    ...publicationDateValue,
                  })(
                    <DatePicker disabled={disabled} style={{ width: "100%" }} />
                  )}
                </Item>
                <Item label="First Publication Location">
                  {getFieldDecorator(`first_publication_location`, {
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                    initialValue: formData.first_publication_location,
                  })(<Input disabled={disabled} style={{ width: "100%" }} />)}
                </Item>
                <Fragment>
                  <Item
                    label={
                      <Popover
                        placement="bottomLeft"
                        content={
                          <p>What is the estimated release date of your app?</p>
                        }
                      >
                        Estimated Release Date <Icon type="question-circle-o" />
                      </Popover>
                    }
                  >
                    {getFieldDecorator(`estimated_release_date`, {
                      initialValue: formData.estimated_release_date,
                    })(
                      <Input.TextArea
                        disabled={disabled}
                        autosize={false}
                        rows={4}
                      />
                    )}
                  </Item>
                </Fragment>
              </Col>
            </Row>
          </LineCard>
          <LineCard title="Technical Information" shadow>
            <Row gutter={16} type="flex" justify="space-around">
              <Col xs={24} lg={12}>
                <Item label="Programming Language">
                  {getFieldDecorator(`programming_language`, {
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                    initialValue: formData.programming_language,
                  })(<Input disabled={disabled} />)}
                </Item>
                <Item label="Estimated Source Code Length (Total Lines)">
                  {getFieldDecorator(`lines_code`, {
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                    initialValue: formData.lines_code,
                  })(<Input disabled={disabled} />)}
                </Item>
                <Fragment>
                  <Item
                    label={
                      <Popover
                        placement="bottomLeft"
                        content={
                          <p>
                            Please write a brief description of the
                            functionality of your app (maximum 5 sentences).
                          </p>
                        }
                      >
                        Functionality Description{" "}
                        <Icon type="question-circle-o" />
                      </Popover>
                    }
                  >
                    {getFieldDecorator(`functionality_description`, {
                      rules: [
                        {
                          required: true,
                          message: "Required",
                        },
                      ],
                      initialValue: formData.functionality_description,
                    })(
                      <Input.TextArea
                        disabled={disabled}
                        autosize={false}
                        rows={5}
                      />
                    )}
                  </Item>
                </Fragment>
              </Col>
              <Col xs={24} lg={12}>
                <Fragment>
                  <Item
                    label={
                      <Popover
                        placement="bottomLeft"
                        content={
                          <p>
                            What are the minimum hardware requirements for your
                            app?
                          </p>
                        }
                      >
                        Minimum Hardware Requirements{" "}
                        <Icon type="question-circle-o" />
                      </Popover>
                    }
                  >
                    {getFieldDecorator(`hardware_requirements`, {
                      rules: [
                        {
                          required: true,
                          message: "Required",
                        },
                      ],
                      initialValue: formData.hardware_requirements,
                    })(
                      <Input.TextArea
                        disabled={disabled}
                        autosize={false}
                        rows={5}
                      />
                    )}
                  </Item>
                </Fragment>
                <Fragment>
                  <Item
                    label={
                      <Popover
                        placement="bottomLeft"
                        content={
                          <p>
                            What are the minimum software requirements for your
                            app (i.e.minimum Android version)?
                          </p>
                        }
                      >
                        Minimum Software Requirements{" "}
                        <Icon type="question-circle-o" />
                      </Popover>
                    }
                  >
                    {getFieldDecorator(`software_requirements`, {
                      rules: [
                        {
                          required: true,
                          message: "Required",
                        },
                      ],
                      initialValue: formData.software_requirements,
                    })(
                      <Input.TextArea
                        disabled={disabled}
                        autosize={false}
                        rows={5}
                      />
                    )}
                  </Item>
                </Fragment>
              </Col>
            </Row>
          </LineCard>
          <LineCard title="Documents" shadow>
            <Row gutter={16} type="flex" justify="space-around">
              <Col xs={24} lg={12}>
                <Fragment>
                  <Item>
                    {getFieldDecorator(`operation_manual`, {
                      rules: [
                        {
                          required: true,
                          message: "Required",
                        },
                      ],
                      initialValue: formData.operation_manual,
                    })(
                      <File
                        name="operation_manual"
                        type="File"
                        validation={{ fileRequired: true }}
                        title="Operation Manual *"
                        onDelete={() => deleteFile("operation_manual")}
                        submitFile={submitFile}
                        format={["docx, doc"]}
                        accept=".doc,.docx,"
                        disabled={disabled}
                      />
                    )}
                  </Item>
                  <p>
                    {" "}
                    Please provide an operation manual in Word format with 15
                    pages of content. If you already have an existing operation
                    manual then please upload this. If not, then please create
                    one based on the template{" "}
                    <a
                      href="https://www.appinchina.co/clients/SampleOperationManual.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      here
                    </a>{" "}
                    If you submit the operation manual in English then we will
                    translate it for you into Chinese.{" "}
                  </p>
                </Fragment>
              </Col>
              <Col xs={24} lg={12}>
                <Fragment>
                  <Item>
                    {getFieldDecorator(`source_code`, {
                      rules: [
                        {
                          required: true,
                          message: "Required",
                        },
                      ],
                      initialValue: formData.source_code,
                    })(
                      <File
                        validation={{ fileRequired: true }}
                        name="source_code"
                        type="File"
                        title="Source Code *"
                        onDelete={() => deleteFile("source_code")}
                        submitFile={submitFile}
                        format={["docx, doc"]}
                        accept=".doc,.docx,"
                        disabled={disabled}
                      />
                    )}
                  </Item>
                  <p>
                    Please prepare and upload a Word document containing the
                    first 40 pages and last 40 pages of your source code, with
                    50 lines per page and at least 3,000 lines in total. This
                    information will be submitted to the{" "}
                    <a
                      href="http://www.ccopyright.com/en/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Copyright Protection Center of China
                    </a>
                    , which is an institution under the
                    <a
                      href="http://en.ncac.gov.cn/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {" "}
                      National Copyright Administration of the People's Republic
                      of China.{" "}
                    </a>
                    This process is similar to that required by the United
                    States Copyright Office when applying for
                    <a
                      href="https://www.copyright.gov/circs/circ61.pdf"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {" "}
                      Copyright Registration of Computer Programs{" "}
                    </a>
                    in the US.
                  </p>
                </Fragment>
              </Col>
            </Row>
          </LineCard>
          <LineCard shadow>
            <Row gutter={16} type="flex" justify="space-around">
              <Col span={24}>
                <Item
                  label={
                    <Popover
                      placement="bottomLeft"
                      content={
                        <p>
                          If you have anything else to add, please leave
                          additional notes
                          <br />
                          for our operations team. (Max. 500 characters)
                        </p>
                      }
                    >
                      Additional notes <Icon type="question-circle-o" />
                    </Popover>
                  }
                >
                  {getFieldDecorator(`special_notes`, {
                    initialValue: formData.special_notes,
                  })(
                    <Input.TextArea
                      disabled={disabled}
                      autosize={false}
                      rows={4}
                    />
                  )}
                </Item>
              </Col>
            </Row>
          </LineCard>
          <div className={classes.Buttons}>
            <Button
              type="primary"
              size="large"
              ghost
              onClick={() => handleSubmitDraft()}
              disabled={disabled || saving}
              icon={saving ? "loading" : null}
            >
              Save as Draft
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={disabled || saving}
              icon={saving ? "loading" : null}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Form.create()(withRouter(CopyrightForm));
