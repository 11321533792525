import classes from "./DetailedHistory.module.less";
import React, { Fragment } from "react";
import { Timeline, Icon, Button, Card } from "antd";
import history from "../../../history";
const DetailedHistory = ({ app, ...props }) => {
  const content = app.details_histories ? app.details_histories : [];
  const getContent = (it, index) => {
    switch (it.event) {
      case "APP_CREATED":
        return (
          <Timeline.Item
            key={index}
            dot={
              <Icon
                type="mobile"
                style={{ fontSize: "16px", color: "#1890ff" }}
              />
            }
          >
            <p>App Created</p>
            <p className={classes.date}>{it.date.split("T")[0]}</p>
          </Timeline.Item>
        );
      case "APP_FORM_SUBMITTED":
        return (
          <Timeline.Item
            key={index}
            dot={
              <Icon
                type="form"
                style={{ fontSize: "16px", color: "#1890ff" }}
              />
            }
          >
            <p>App Form Submitted</p>
            <p>
              <b>Version: {it.details}</b>
            </p>
            <p className={classes.date}>{it.date.split("T")[0]}</p>
          </Timeline.Item>
        );
      case "COMPANY_FORM_SUBMITTED":
        return (
          <Timeline.Item
            key={index}
            dot={
              <Icon
                type="form"
                style={{ fontSize: "16px", color: "#1890ff" }}
              />
            }
          >
            <p>Company Form Submitted</p>
            <p className={classes.date}>{it.date.split("T")[0]}</p>
          </Timeline.Item>
        );
      case "COPYRIGHT_FORM_SUBMITTED":
        return (
          <Timeline.Item
            key={index}
            dot={
              <Icon
                type="form"
                style={{ fontSize: "16px", color: "#1890ff" }}
              />
            }
          >
            <p>Copyright Form Submitted</p>
            <p className={classes.date}>{it.date.split("T")[0]}</p>
          </Timeline.Item>
        );
      case "DISTRIBUTION_REJECTED":
        return (
          <Timeline.Item
            key={index}
            dot={<Icon type="warning" style={{ fontSize: "16px" }} />}
            color="red"
          >
            <p>App distribution rejected on {it.store}</p>
            <p>{it.details}</p>
            <p className={classes.date}>{it.date.split("T")[0]}</p>
          </Timeline.Item>
        );
      case "DISTRIBUTION_PUBLISHED":
        return (
          <Timeline.Item
            key={index}
            dot={<Icon type="check-circle" style={{ fontSize: "16px" }} />}
            color="blue"
          >
            <p>
              App succesfully <b>published</b> on {it.store}
            </p>
            <p className={classes.date}>{it.date.split("T")[0]}</p>
          </Timeline.Item>
        );
      case "DISTRIBUTION_SUBMITTED_TO_STORE":
        return (
          <Timeline.Item
            key={index}
            dot={
              <Icon
                type="info-circle"
                style={{ fontSize: "16px", color: "#1890ff" }}
              />
            }
          >
            <p>App information submitted on {it.store}</p>
            <p className={classes.date}>{it.date.split("T")[0]}</p>
          </Timeline.Item>
        );
      case "DISTRIBUTION_SUSPENDED":
        return (
          <Timeline.Item
            key={index}
            dot={
              <Icon
                type="stop"
                style={{ fontSize: "16px", color: "#1890ff" }}
              />
            }
          >
            <p>App distribution suspended on {it.store}</p>
            <p className={classes.date}>{it.date.split("T")[0]}</p>
          </Timeline.Item>
        );
      case "DISTRIBUTION_REMOVED":
        return (
          <Timeline.Item key={index} color="#1890ff">
            <p>App Distribution Removed on {it.store}</p>
            <p className={classes.date}>{it.date.split("T")[0]}</p>
          </Timeline.Item>
        );
      default:
        break;
    }
  };
  return (
    <Fragment>
      <div className={classes.Button}>
        <Button
          onClick={() =>
            history.push(`/distribution/${app.id}/app-information`)
          }
          width="100px"
        >
          <Icon type="left" />
          Go back
        </Button>
      </div>
      <Card size="small">
        <div className={classes.Timeline}>
          <Timeline mode="alternate">
            <h2 className={classes.Title}>App History</h2>
            {content && content.length > 0
              ? content
                  .sort((a, b) => (a.date > b.date ? -1 : 1))
                  .map((it, index) => getContent(it, index))
              : []}
          </Timeline>
        </div>
        <div style={{ textAlign: "right" }}>
          <b className={classes.Info}>*Data retrieved since 2019-01-24.</b>
        </div>
      </Card>
    </Fragment>
  );
};
export default DetailedHistory;
