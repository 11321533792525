const location = [
  {
    city: "Beijing",
    city_count: 40130
  },
  {
    city: "Shanghai",
    city_count: 30130
  },
  {
    city: "Shenzhen",
    city_count: 25130
  },
  {
    city: "Guangzhou",
    city_count: 18130
  },
  {
    city: "Tianjin",
    city_count: 15130
  }
];
const appStoresDownloads = [
  { app_store: "Tencent", store_count: 219363 },
  { app_store: "Huawei", store_count: 210000 },
  { app_store: "MIUI", store_count: 233839 },
  { app_store: "Baidu", store_count: 21123 },
  { app_store: "Oppo", store_count: 12936 },
  { app_store: "360", store_count: 102738 },
  { app_store: "VIVO", store_count: 70000 }
];
const devices = [
  { store: "Honor", percentage: 23 },
  { store: "Xiaomi", percentage: 11 },
  { store: "Huawei", percentage: 46 },
  { store: "One Plus", percentage: 7 },
  { store: "Samsung", percentage: 5 },
  { store: "LG", percentage: 3 }
];
const network = [
  { network_type: "Not Set", network_count: 23 },
  { network_type: "WiFi", network_count: 123 },
  { network_type: "2G", network_count: 1967 },
  { network_type: "3G", network_count: 1356 }
];
const operating_system = [
  { operating_system: "9", os_count: 1027 },
  { operating_system: "6", os_count: 2027 },
  { operating_system: "4.4", os_count: 1478 },
  { operating_system: "5", os_count: 134 },
  { operating_system: "7", os_count: 600 }
];
const versions = [
  { value: "all", label: "All versions" },
  { value: "1.0", label: "V.1.0" },
  { value: "1.2", label: "V.1.2" },
  { value: "1.3", label: "V.1.3" }
];
const transactions = [
  {
    name: "Transactions",
    Tencent: 1029,
    Huawei: 300,
    Oppo: 120,
    Baidu: 280
  },
  {
    name: "Users",
    Tencent: 2037,
    Huawei: 1362,
    Oppo: 1938,
    Baidu: 457
  }
];
const payment_rate = [
  { name: "Tencent", vote: 50 },
  { name: "Huawei", vote: 20 },
  { name: "Oppo", vote: 11 },
  { name: "Baidu", vote: 19 }
];
let user_visits = [
  { date: "2019-08-01", visits: 1000, type: "Return" },
  { date: "2019-08-02", visits: 2000, type: "Return" },
  { date: "2019-08-03", visits: 2736, type: "Return" },
  { date: "2019-08-04", visits: 2304, type: "Return" },
  { date: "2019-08-05", visits: 2018, type: "Return" },
  { date: "2019-08-06", visits: 1840, type: "Return" },
  { date: "2019-08-07", visits: 1674, type: "Return" },
  { date: "2019-08-08", visits: 4082, type: "Return" },
  { date: "2019-08-09", visits: 1023, type: "Return" },
  { date: "2019-08-10", visits: 3000, type: "Return" },
  { date: "2019-08-11", visits: 1984, type: "Return" },
  { date: "2019-08-12", visits: 2000, type: "Return" },
  { date: "2019-08-13", visits: 1957, type: "Return" },

  { date: "2019-08-01", visits: 193, type: "New" },
  { date: "2019-08-02", visits: 987, type: "New" },
  { date: "2019-08-03", visits: 252, type: "New" },
  { date: "2019-08-04", visits: 113, type: "New" },
  { date: "2019-08-05", visits: 100, type: "New" },
  { date: "2019-08-06", visits: 457, type: "New" },
  { date: "2019-08-07", visits: 241, type: "New" },
  { date: "2019-08-08", visits: 184, type: "New" },
  { date: "2019-08-09", visits: 102, type: "New" },
  { date: "2019-08-10", visits: 300, type: "New" },
  { date: "2019-08-11", visits: 123, type: "New" },
  { date: "2019-08-12", visits: 231, type: "New" },
  { date: "2019-08-13", visits: 157, type: "New" }
];
let visits_time = [
  {
    time: "20",
    total_visits: 1030
  },
  {
    time: "40",
    total_visits: 1340
  },
  { time: "60", total_visits: 1230 },
  { time: "80", total_visits: 1023 },
  { time: "100", total_visits: 1663 }
];
let visits_page = [
  {
    page: "goods/list",
    total_visits: 15
  },
  {
    page: "map/amap",
    total_visits: 14
  },
  {
    page: "order/detail",
    total_visits: 25
  },
  {
    page: "order/confirm",
    total_visits: 28
  },
  {
    page: "me/login",
    total_visits: 19
  },
  {
    page: "me/index",
    total_visits: 18
  },
  {
    page: "other",
    total_visits: 7
  }
];
let app_visits = [
  { date: "2019-08-01", average_visits: 1000 },
  { date: "2019-08-02", average_visits: 2000 },
  { date: "2019-08-03", average_visits: 2736 },
  { date: "2019-08-04", average_visits: 2304 },
  { date: "2019-08-05", average_visits: 2018 },
  { date: "2019-08-06", average_visits: 1840 },
  { date: "2019-08-07", average_visits: 1674 },
  { date: "2019-08-08", average_visits: 4082 },
  { date: "2019-08-09", average_visits: 1023 },
  { date: "2019-08-10", average_visits: 3000 },
  { date: "2019-08-11", average_visits: 1984 },
  { date: "2019-08-12", average_visits: 2000 },
  { date: "2019-08-13", average_visits: 1957 }
];
const area = [
  {
    key: "10105",
    name: "广东",
    value: 11.38
  },
  {
    key: "10125",
    name: "四川",
    value: 8.99
  },
  {
    key: "10102",
    name: "安徽",
    value: 6.95
  },
  {
    key: "10130",
    name: "浙江",
    value: 5.25
  },
  {
    key: "10112",
    name: "湖北",
    value: 5.05
  },
  {
    key: "10124",
    name: "上海",
    value: 4.95
  },
  {
    key: "10103",
    name: "福建",
    value: 4.84
  },
  {
    key: "10131",
    name: "重庆",
    value: 4.19
  },
  {
    key: "10115",
    name: "江苏",
    value: 4.02
  },
  {
    key: "10123",
    name: "陕西",
    value: 3.88
  },
  {
    key: "10121",
    name: "山东",
    value: 3.87
  },
  {
    key: "10109",
    name: "河北",
    value: 3.59
  },
  {
    key: "10116",
    name: "江西",
    value: 3.15
  },
  {
    key: "10113",
    name: "湖南",
    value: 3.04
  },
  {
    key: "10129",
    name: "云南",
    value: 2.94
  },
  {
    key: "10101",
    name: "北京",
    value: 2.46
  },
  {
    key: "10104",
    name: "甘肃",
    value: 2.32
  },
  {
    key: "10114",
    name: "吉林",
    value: 2.29
  },
  {
    key: "10107",
    name: "贵州",
    value: 2.23
  },
  {
    key: "10106",
    name: "广西",
    value: 2.2
  },
  {
    key: "10110",
    name: "河南",
    value: 1.9
  },
  {
    key: "10117",
    name: "辽宁",
    value: 1.52
  },
  {
    key: "10118",
    name: "内蒙古",
    value: 1.42
  },
  {
    key: "10128",
    name: "新疆",
    value: 1.42
  },
  {
    key: "10111",
    name: "黑龙江",
    value: 1.4
  },
  {
    key: "10126",
    name: "天津",
    value: 1.22
  },
  {
    key: "10122",
    name: "山西",
    value: 1.03
  },
  {
    key: "10108",
    name: "海南",
    value: 0.98
  },
  {
    key: "10119",
    name: "宁夏",
    value: 0.8
  },
  {
    key: "10120",
    name: "青海",
    value: 0.52
  },
  {
    key: "10127",
    name: "西藏",
    value: 0.2
  }
];
export {
  location,
  appStoresDownloads,
  devices,
  network,
  operating_system,
  versions,
  transactions,
  payment_rate,
  user_visits,
  visits_time,
  visits_page,
  app_visits,
  area
};
