import React, { useState } from "react";
import { Modal, Form, Input, Button, Alert, Tag, notification } from "antd";
import classes from "./RenewalModalCancel.module.less";
import { cancelRenewal } from "../../../../../services/aicPay";

const RenewalModalCancel = ({ visible, close, renewalId, renewalInfo, useDemoData, form, app, updateRenewal }) => {

    const [loading, setLoading] = useState(false)
    const { getFieldDecorator } = form;

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                setLoading(true)
                const { comments } = values
                const { customer_identity, service_no, app_id } = renewalInfo
                const { code, msg } = await cancelRenewal({ customer_identity, service_no, app_id, app, comments })
                setLoading(false)
                if (code === 0 && msg === "success") {
                    updateRenewal(comments)
                    form.resetFields()
                    close()
                } else {
                    notification.error({                        
                        message: `An error has ocurred`,
                        placement: "bottomRight"
                    })
                }
            }
        });
    };
    return <Modal
        title={<div>Cancel Auto Renewal #: <Tag color="red">
            {renewalId}
        </Tag></div>} footer={null} visible={visible} onCancel={() => {
            form.resetFields()
            close()
        }}
    >
        <Alert
            message={<div>
                <p>Are you sure you want to cancel this Auto renewal subscription for <b className={classes.user}>USER:{renewalInfo.user}</b>.</p>
                <p> Please make sure you have informed the user regarding this cancellation.</p>
            </div>
            }
            type="warning"
            showIcon
        />
        <Form
            hideRequiredMark={true}
            onSubmit={handleSubmit}
        >
            <Form.Item label="Add Note">
                {getFieldDecorator('comments', {
                    rules: [
                        {
                            required: true,
                            message: "Required."
                        },
                    ],
                })(<Input.TextArea rows={4} />)}
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    xs: { span: 24, offset: 0 },
                    sm: { span: 16, offset: 8 },
                }}
            >
                <Button
                    disabled={useDemoData || loading}
                    type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button style={{ marginLeft: 5 }} onClick={() => {
                    form.resetFields()
                    close()
                }}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    </Modal>
}
export default Form.create({ name: 'cancel_renewal' })(RenewalModalCancel);