import React from "react";
import classes from "./PromotionExpenses.module.less";
import UncontractedMessage from "../UncontractedAppPromotion/UncontractedMessage";
import AmountSpent from "./AmountSpent/AmountSpent";
import ExpensesPerDay from "./ExpensesPerDay/ExpensesPerDay";
export default ({ app, useDemoData }) => {
  return (
    <div className={classes.container}>
      <UncontractedMessage app={app} useDemoData={useDemoData} />
      <AmountSpent app={app} useDemoData={useDemoData} />
      <ExpensesPerDay app={app} useDemoData={useDemoData} />
    </div>
  );
};
