import React, { useState } from "react";
import SubmissionTable from "./SubmissionStatusTable/SubmissionStatusTable";
import Preview from "../FormStepper/FormSteps/Preview/Preview";
import { Button, Row } from "antd";
const SubmissionStatus = ({
  data,
  app,
  continueSubmition,
  deleteHandler,
  loading,
  isOwnerOrAdmin,
}) => {
  let [formId, setFormId] = useState(null);

  return (
    <div>
      {formId !== null ? (
        <Row type="flex" justify="start">
          <Button type="ghost" onClick={() => setFormId(null)} icon="left">
            Back
          </Button>
        </Row>
      ) : null}

      {formId === null ? (
        <SubmissionTable
          loading={loading}
          data={data}
          app={app}
          loading={loading}
          setFormId={setFormId}
          deleteHandler={deleteHandler}
          continueSubmition={continueSubmition}
          isOwnerOrAdmin={isOwnerOrAdmin}
        />
      ) : (
        <Preview data={formId} app={app} />
      )}
    </div>
  );
};

export default SubmissionStatus;
