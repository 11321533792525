import React, { useState, useEffect } from "react";
import classes from "./WelcomePage.module.less";
import moment from "moment";
import StatisticCard from "../UI/StatisticCard/StatisticCard";
import { Row, Col } from "antd";
import TransactionsChart from "./Charts/TransactionsChart/TransactionsChart";
import DownloadsChart from "./Charts/Downloads/Downloads";
import UsersChart from "./Charts/Users/Users";
import AppDistributionCard from "../UI/AppDistributionCard/AppDistributionCard";
import Bulletin from "./Bulletin/Bulletin";
import DemoAppAlert from "../DemoAppAlert/DemoAppAlert";
import {
  getAppDistribution,
  getAppStoresDistribution,
  getTotalDownloads,
  getRevenue,
} from "../../services/welcomePage";
import AppInChinaBadge from "../Distribution/DistributionDetails/AppInChinaBadge/AppInChinaBadge";
const WelcomePage = ({ ...props }) => {
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [loadingDownloads, setLoadingDownloads] = useState(false);
  const [loadingAppStores, setLoadingAppStores] = useState(false);
  const [loadingAppDistribution, setLoadingAppDistribution] = useState(false);
  const [appDistribution, setAppDistribution] = useState({});
  const [storesDistribution, setStoresDistribution] = useState({});
  const [downloads, setDownloads] = useState({});
  const [numberReviews, setNumber] = useState(0);
  const [revenue, setRevenue] = useState({});
  const [displayAuthorization, setDisplayAuthorization] = useState(false);

  const paymentsId =
    props.app.wechat_id || props.app.alipay_id
      ? [
          props.app.alipay_id || "",
          props.app.wechat_id || "",
          props.app.seccond_alipay_id || "",
          props.app.seccond_wechat_id || "",
        ].join(",")
      : props.match.params.app_id;
  const defaultDate = {
    start: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };

  useEffect(() => {
    getTransactions();
    getDownloads();
    getAppStorePublished();
    getAppDistributionRequest();
  }, [props.app ? props.app.id : props.match.params.app_id]);

  const getTransactions = async () => {
    setLoadingTransactions(true);
    let response = await getRevenue({
      start: defaultDate.start,
      end: defaultDate.end,
      app: paymentsId,
      id: props.app.id,
    });
    // console.log("AXIOS internal RESPONSE", response);
    let { data } = response;
    if (data && data.refunds) {
      setRevenue(data);
    }
    setLoadingTransactions(false);
  };
  const getDownloads = async () => {
    setLoadingDownloads(true);
    let { data } = await getTotalDownloads({
      app_id: props.app ? props.app.id : props.match.params.app_id,
      start: defaultDate.start,
      end: defaultDate.end,
    });

    if (data && data.downloads) {
      let result = tableData(data.downloads);
      setDownloads(result);
      setNumber(data.numberOfReviews);
    }
    setLoadingDownloads(false);
  };
  const tableData = (downloadsArray) => {
    return downloadsArray.map(({ date, data }) => {
      let downloads = data.reduce(
        (prev, curr) => ({
          ...prev,
          total: prev.total + curr.currentDown,
        }),
        {
          total: 0,
        }
      );
      return {
        date,
        ...downloads,
      };
    });
  };
  const getAppStorePublished = async () => {
    setLoadingAppStores(true);
    let { data } = await getAppStoresDistribution(
      props.app ? props.app.id : props.match.params.app_id
    );
    if (data && data.length > 0) {
      setStoresDistribution(data[0]);
    }
    setLoadingAppStores(false);
  };
  const getAppDistributionRequest = async () => {
    setLoadingAppDistribution(true);
    let { data } = await getAppDistribution(
      props.app ? props.app.id : props.match.params.app_id
    );
    setLoadingAppDistribution(false);
    if (data && data.id) {
      setAppDistribution(data);
    }
  };

  const getAppDistributionPercent = () => {
    if (
      !storesDistribution ||
      (!storesDistribution.stores_to_upload &&
        !storesDistribution.uploaded_stores_count)
    ) {
      return 0;
    } else if (
      storesDistribution &&
      storesDistribution.uploaded_stores_count <
        storesDistribution.stores_to_upload
    ) {
      return (
        10 *
        (((storesDistribution.uploaded_stores_count.toFixed(2) || 0) /
          (storesDistribution.stores_to_upload.toFixed(2) || 0)) *
          10)
      ).toFixed(2);
    } else {
      return 100;
    }
  };
  useEffect(() => {
    if (props && props.apps) {
      let app =
        props && props.apps
          ? props.apps.find((it) => it.id === props.match.params.app_id)
          : null;
      let app_stores_account = app && app.main_information.app_store_accounts;
      const display_authorization =
        app_stores_account === "AIC" || app_stores_account === "ZHENCHUANG";
      setDisplayAuthorization(display_authorization);
    }
  }, [props]);

  return (
    <div className={classes.Container}>
      <DemoAppAlert appId={props.match.params.app_id} />
      <p></p>
      {props.app && props.app.main_information ? (
        <AppInChinaBadge
          app={props.app}
          packageName={props.app.main_information.package_name}
        />
      ) : null}
      <Row gutter={16} style={{ paddingBottom: "16px" }}>
        <Col sm={24} md={24} xl={8}>
          <Bulletin appId={props.match.params.app_id} />
        </Col>
        <Col sm={24} xl={16}>
          <div>
            <AppDistributionCard
              loading={loadingAppDistribution && loadingAppStores}
              title="App Store Distribution"
              data={appDistribution}
              completedStores={storesDistribution.completedStores}
              appId={
                props.app && props.app.id
                  ? props.app.id
                  : props.match.params.app_id
              }
              display_authorization={displayAuthorization}
              percent={getAppDistributionPercent()}
              info={[
                {
                  title: "Stores Paid",
                  data: storesDistribution.stores_to_upload || 0,
                },
                {
                  title: "Stores Completed",
                  data: storesDistribution.uploaded_stores_count || 0,
                },
              ]}
              {...props}
            />
          </div>
        </Col>
      </Row>
      <Row
        justify="space-between"
        gutter={[16, 16]}
        style={{ paddingBottom: "16px" }}
      >
        {" "}
        <Col xs={24} sm={12} md={8}>
          <StatisticCard
            title="New Downloads (Current Month)"
            link={`/distribution/${props.match.params.app_id}/downloads`}
            linkDescription="Open Downloads Page"
            description="The download numbers displayed here may not be entirely accurate due to the information provided by some stores."
            total={
              downloads && downloads.length > 0
                ? downloads.reduce((acc, curr) => acc + curr.total, 0)
                : 0
            }
            chart={<DownloadsChart data={downloads} />}
            loading={loadingDownloads}
            info={[
              {
                title: "Reviews",
                data: numberReviews,
              },
            ]}
          />
        </Col>
        <Col xs={24} sm={12} md={8} className={classes.marginBottom}>
          <StatisticCard
            title="AppInChina Pay Revenue"
            description="If you don't use our in-app purchases solution yet please get in touch with your account manager to get started."
            chart={<TransactionsChart data={revenue} />}
            total={
              revenue.transactionsBalance
                ? revenue.transactionsBalance / 100
                : 0
            }
            revenue
            loading={loadingTransactions}
            info={[
              {
                title: "Transactions (Current Month)",
                data:
                  revenue.transactions && revenue.transactions.length > 0
                    ? revenue.transactions.reduce(
                        (acc, curr) => acc + curr.transactions_by_day,
                        0
                      )
                    : 0,
              },
            ]}
          />
        </Col>
        <Col xs={24} sm={12} md={8} className={classes.marginBottom}>
          <StatisticCard
            title="AppInChina Pay Users"
            chart={<UsersChart data={revenue} />}
            total={
              revenue.users && revenue.users.length > 0
                ? revenue.users.reduce((acc, curr) => acc + curr.count, 0)
                : 0
            }
            loading={loadingTransactions}
            description="If you don't use our in-app purchases solution yet please get in touch with your account manager to get started."
          />
        </Col>
      </Row>
    </div>
  );
};

export default WelcomePage;
