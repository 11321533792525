import React from "react";
import { Empty } from "antd";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import { payment_rate } from "../../fakeData";
export default class extends React.Component {
  render() {
    let data = !this.props.useDemoData
      ? this.props.data.map(item => {
          const [key] = Object.keys(item);
          return {
            name: key,
            vote: item[key]
          };
        })
      : payment_rate;

    const scale = {
      vote: {
        min: 0
      }
    };
    const ds = new DataSet();
    const dv = ds.createView().source(data);
    dv.source(payment_rate).transform({
      type: "sort",
      callback(a, b) {
        return a.vote - b.vote;
      }
    });
    return payment_rate.length > 0 ? (
      <Chart
        height={500}
        data={data}
        padding={[60, 20, 40, 60]}
        scale={scale}
        forceFit
      >
        <Axis
          name="vote"
          labels={null}
          title={null}
          line={null}
          tickLine={null}
          label={{ formatter: val => `${val}%` }}
        />
        <Tooltip
          crosshairs={{
            type: "y"
          }}
        />
        <Geom
          type="interval"
          position="name*vote"
          color={"name"}
          opacity={0.4}
          tooltip={[
            "name*vote",
            (name, vote) => {
              vote = `${vote}%`;
              return {
                name: vote
              };
            }
          ]}
        />
        <Legend />
      </Chart>
    ) : (
      <Empty />
    );
  }
}
