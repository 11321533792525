import React, { useEffect, useState } from "react";
import classes from "./DistributionDetails.module.less";
import AppInChinaBadge from "./AppInChinaBadge/AppInChinaBadge";
import DemoAppAlert from "../../DemoAppAlert/DemoAppAlert";
import DistributionDetailsTable from "./DistributionDetailsTable/DistributionDetailsTable";
import ContractDetails from "./ContractDetails/ContractDetails";
import DistributionResults from "./DistributionResults/DistributionResults";
import moment from "moment";
import { getDistributionDetails } from "../../../services/distributionService";
import { Alert } from "antd";
import AppFormIntro from "../AppFormHome/AppFormIntro/AppFormIntro";

export default ({ app = {} }) => {
  const [loadingApp, setLoadingApp] = useState(true);
  let [data, setData] = useState([]);
  let [corporate, setCorporate] = useState(false);
  let [showAppIntro, setShowAppIntro] = useState(false);
  const isOwner = app.role === "OWNER";

  useEffect(() => {
    fetchData();
  }, [app.name]);

  const fetchData = async () => {
    try {
      const { id } = app;
      if (id) {
        let app_stores_account = app && app.main_information.app_store_accounts;
        const value =
          app_stores_account === "CLIENT_CC" ||
          app_stores_account === "CLIENT_OC";
        setCorporate(value);
        const { data } = await getDistributionDetails(id);
        if (data.result) {
          setData(data.result);
          setLoadingApp(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateHistory = (values) => {
    const dataCopy = data;
    const index = dataCopy.findIndex((item) => item.id === values.store);
    const user = localStorage.getItem("email");
    dataCopy[index] = {
      ...dataCopy[index],
      distribution_histories: [
        ...dataCopy[index].distribution_histories,
        {
          ...values,
          user,
          date: moment().format("YYYY-MM-DD HH:mm"),
        },
      ],
    };
    setData(dataCopy);
  };

  return (
    <div className={classes.container}>
      <DemoAppAlert appId={app.id} />

      <div className={classes.alert}>
        <Alert
          message="The download numbers displayed here may be slightly innacurate since some stores may be unreliable on their calculations."
          type="warning"
          showIcon
          closeText="Dismiss"
        />
      </div>
      <div className={classes.intro}>
        <Alert
          message={
            <p>
              <b onClick={() => setShowAppIntro(true)}>
                View Dashboard Tutorial
              </b>
            </p>
          }
          type="info"
          showIcon
          closeText="Dismiss"
        />
      </div>
      <AppFormIntro
        visible={showAppIntro}
        forceRender
        onOk={() => setShowAppIntro(false)}
      />
      <AppInChinaBadge
        app={app}
        packageName={app.main_information.package_name}
      />
      <div className={classes.TwoCards}>
        <ContractDetails
          storesPublished={data.filter(
            (e) => e.distribution_status === "PUBLISHED"
          )}
          loading={loadingApp}
          app={app}
        />
        <DistributionResults app={app} />
      </div>
      <DistributionDetailsTable
        disabled={!isOwner}
        corporate={corporate}
        data={data}
        loadingApp={loadingApp}
        updateHistory={updateHistory}
      />
    </div>
  );
};
