import React, { useState } from "react";
import { Modal, DatePicker, Row, Button, Col, Select, Form, Alert, Checkbox } from "antd";
import moment from "moment";
import { getCsvTransactions, } from "../../../../services/aicPay";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Item } = Form;
const ExportCsvModal = ({ display, close, paymentsId, app, detailsList, form }) => {
    const [startValue, setStartValue] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState(false);
    const [selectAll, setSelectAll] = useState({
        status: false,
        platform: false,
        details: false
    });
    const paymentsStatus = {
        "21": "Paid",
        "31": "Payment Failed",
        "41": "Order Closed",
        "11": "Pending",
        "51": "Refunded",
    };
    const statusOptions = [
        { value: "21", label: "Paid" },
        { value: "31", label: "Payment Failed" },
        { value: "41", label: "Order Closed" },
        { value: "11", label: "Pending" },
        { value: "51", label: "Refunded" }
    ]
    const onCalendarChange = (dates) => {
        if (dates.length == 1) {
            setStartValue(dates[0])
        }
    }
    const onChange = (dates, _) => {
        if (dates.length === 0) {
            setStartValue(null)
        }
    }
    const disabledDate = (current) => {
        let customDate = startValue ? startValue : null
        return customDate ? current && current < moment(customDate, "YYYY-MM-DD") ||
            current > moment(customDate, "YYYY-MM-DD").add(31, 'day')
            : false;
    }
    const downloadCsvRequest = async (values) => {
        setDownloading(true);
        let response = await getCsvTransactions({
            ...values,
            id: paymentsId,
            app,
        });
        if (response && response.transactions) {
            setError(false)
            const data = response.transactions.map((it) => ({
                user: it.user,
                customer_name: it.customer_name,
                date: it.date,
                amount: it.amount,
                status: paymentsStatus[it.status],
                id: it.transaction_id,
                platform: it.pay_channel,
                platform_fee: it.platform_fee,
                details: it.details,
            }));
            const rows = [
                ["Phone Number", "User Name", "Date", "Amount", "Status", "Transaction ID", "Platform", "Platform Fee", "Details"],
                ...data.map((it) => [...Object.values(it)]),
            ];
            let csvContent =
                "data:text/csv;charset=utf-8," +
                rows.map((e) => e.join(",")).join("\n");
            const link = document.createElement("a");
            link.setAttribute("href", encodeURI(csvContent));
            link.setAttribute("download", "transactions.csv");
            document.body.appendChild(link);
            link.click();
            form.resetFields();
            close()
            setSelectAll({
                details: false,
                platform: false,
                status: false
            })
        } else {
            setError(true)
        }
        setDownloading(false);
    };
    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, fieldsValue) => {
            if (err) {
                return;
            }
            const values = {
                ...fieldsValue,
                'dateRange': { start: fieldsValue['dateRange'][0].format('YYYY-MM-DD'), end: fieldsValue['dateRange'][1].format('YYYY-MM-DD') },
                'detailsOptions': selectAll.details ? encodeURIComponent(detailsList.join(',')) : encodeURIComponent(fieldsValue['detailsOptions'].join(',')),
                'platform': selectAll.platform ? "AliPay,WeChat" : fieldsValue['platform'].join(','),
                'status': selectAll.status ? statusOptions.map(({ value }) => value).join(",") : fieldsValue['status'].join(','),
            };
            downloadCsvRequest(values)
        });
    };
    const { getFieldDecorator } = form;
    return <div><Modal destroyOnClose={true} footer={null} title="Download Transactions as CSV" visible={display}
        onCancel={() => {
            setError(false)
            close()
            setSelectAll({
                status: false,
                platform: false,
                details: false
            })
        }}>

        <Form hideRequiredMark={true} onSubmit={handleSubmit}>
            <Row gutter={[16, 16]} align="middle" justify="center">
                <Col span={24}>
                    <Item label="Date Range">
                        {getFieldDecorator('dateRange', { rules: [{ required: true, message: "Please select." }] })(<RangePicker allowClear={true} disabled={downloading} onChange={onChange} onCalendarChange={onCalendarChange} disabledDate={disabledDate} />
                        )}
                    </Item>
                </Col>
                <Col span={12}>
                    <Item label="Status">
                        <Checkbox disabled={downloading} checked={selectAll.status} onChange={e => { setSelectAll({ ...selectAll, status: e.target.checked }) }}>Select All</Checkbox>
                        {getFieldDecorator('status', { rules: [{ required: !selectAll.status, message: "Please select." }] })(
                            <Select disabled={downloading || selectAll.status} mode="multiple" placeholder="Select Statuses..." style={{ width: "100%" }}>
                                {
                                    statusOptions.map(({ value, label }) => <Option key={value} value={value}>
                                        {label}
                                    </Option>)
                                }
                            </Select>
                        )}
                    </Item>
                </Col>
                <Col span={12}>
                    <Item label="Platform">
                        <Checkbox disabled={downloading} checked={selectAll.platform} onChange={e => { setSelectAll({ ...selectAll, platform: e.target.checked }) }}>Select All</Checkbox>
                        {getFieldDecorator('platform', { rules: [{ required: !selectAll.platform, message: "Please select." }] })(
                            <Select disabled={downloading || selectAll.platform} mode="multiple" placeholder="Select Platform..." style={{ width: "100%" }}>
                                <Option key='WeChat' value="WeChat">
                                    WeChat
                                </Option>
                                <Option key='AliPay' value="AliPay">
                                    Alipay
                                </Option>
                            </Select>
                        )}
                    </Item>
                </Col>
                <Col span={24}>
                    <Item label="Details">
                        <Checkbox disabled={downloading} checked={selectAll.details} onChange={e => { setSelectAll({ ...selectAll, details: e.target.checked }) }}>Select All</Checkbox>
                        {getFieldDecorator('detailsOptions', { rules: [{ required: !selectAll.details, message: "Please select." }] })(
                            <Select disabled={downloading || selectAll.details} mode="multiple" placeholder="Select Details..." style={{ width: "100%" }}>
                                {
                                    detailsList.map((it) => <Option key={it} value={it}>
                                        {it}
                                    </Option>)
                                }
                            </Select>
                        )}
                    </Item>
                </Col>
                {error ? <Col span={24}>
                    <Alert showIcon message="Please select a shorter time range so a maximum of 10,000 results get exported" type="error" />
                </Col> : null}
                <Col span={24}>
                    <Row type="flex" justify="end">
                        <Button loading={downloading} htmlType="submit" icon="download" type="primary">
                            Download
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    </Modal></div>
}

export default Form.create({ name: 'exportTransactions' })(ExportCsvModal);