import React, { useState, useEffect } from "react";
import classes from "../AnalyticsHome.module.css";
import { Spin, Row, Col, DatePicker, Button, Select } from "antd";
import LineCard from "../../UI/LineCard/LineCard";
import { getAnalyticsGeneralInformation } from "../../../services/analyticsService";
import DeviceManufacturer from "./Graphics/DeviceManufacturer";
import DeviceLocationHome from "./Graphics/DeviceLocationHome/DeviceLocationHome";
import AppStoresDownloads from "./Graphics/AppStoresDownloads";
import OperatingSystem from "./Graphics/OperatingSystem";
import NetworkType from "./Graphics/NetworkType";
import moment from "moment";
import { versions } from "../fakeData";
import UncontractedAnalyticsMessage from "../UncontractedAnalyticsMessage/UncontractedAnalyticsMessage";
const { RangePicker } = DatePicker;
const { Option } = Select;

const GeneralInformation = ({ app, useDemoData, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [versionSelected, setVersionSelected] = useState("");
  const [chartData, setChartData] = useState({
    android_version: [],
    city: [],
    manofacturer: [],
    network: [],
    store: []
  });
  const defaultFilters = {
    start: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    version: "all"
  };

  useEffect(() => {
    setStart(defaultFilters.start);
    setEnd(defaultFilters.end);
    setVersionSelected(defaultFilters.version);
    fetch(defaultFilters.start, defaultFilters.end, defaultFilters.version);
  }, [app.id, useDemoData]);

  const onChangeDate = (_, [start, end]) => {
    fetch(start, end, versionSelected);
    setStart(start);
    setEnd(end);
  };
  const onChangeVersion = value => {
    setVersionSelected(value);
    fetch(start, end, value);
  };

  const resetFilter = () => {
    setStart(defaultFilters.start);
    setEnd(defaultFilters.end);
    setVersionSelected(defaultFilters.version);
    fetch(defaultFilters.start, defaultFilters.end, defaultFilters.version);
  };

  const fetch = async (start, end, version) => {
    if (!useDemoData && app)
      try {
        setLoading(true);
        const result = await getAnalyticsGeneralInformation(
          start,
          end,
          version,
          app.analitycs_id
        );
        setChartData(result.data);
        setLoading(false);
      } catch (error) {
        console.log("ERROR", error);
      }
  };

  return loading ? (
    <Spin
      tip="Loading..."
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    />
  ) : (
    <div className={classes.Container}>
      <UncontractedAnalyticsMessage app={app} useDemoData={useDemoData} />
      <div className={classes.Buttons}>
        <Row gutter={16}>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <RangePicker
              value={[moment(start), moment(end)]}
              style={{ width: "100%", marginBottom: 10 }}
              onChange={onChangeDate}
              format={"YYYY-MM-DD"}
            />
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <Select
              onChange={onChangeVersion}
              value={versionSelected}
              style={{ width: "100%", marginBottom: 10 }}
              disabled={!useDemoData}
            >
              {versions.map(it => (
                <Option key={it.value} value={it.value} label={it.label}>
                  {it.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
            <Button
              style={{ width: "100%", marginBottom: 10 }}
              type="primary"
              onClick={resetFilter}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </div>
      <div>
        <LineCard
          title="App Stores - Activations"
          style={{ textAlign: "center" }}
        >
          <AppStoresDownloads
            data={chartData.store}
            useDemoData={useDemoData}
          />
        </LineCard>
      </div>
      <Row gutter={16}>
        <Col xl={12}>
          <LineCard
            title="Operating System"
            style={{ height: "550px", textAlign: "center" }}
          >
            <OperatingSystem
              data={chartData.android_version}
              useDemoData={useDemoData}
            />
          </LineCard>
        </Col>
        <Col xl={12}>
          <LineCard
            title="Device Manufacturer"
            style={{ height: "550px", textAlign: "center" }}
          >
            <DeviceManufacturer
              data={chartData.manofacturer}
              useDemoData={useDemoData}
            />
          </LineCard>
        </Col>
        <Col xl={12}>
          <LineCard
            title="Device Location"
            style={{ height: "600px", textAlign: "center" }}
          >
            <DeviceLocationHome
              data={chartData.city}
              useDemoData={useDemoData}
            />
          </LineCard>
        </Col>
        <Col xl={12}>
          <LineCard
            title="Network Type"
            style={{ height: "600px", textAlign: "center" }}
          >
            <NetworkType data={chartData.network} useDemoData={useDemoData} />
          </LineCard>
        </Col>
      </Row>
    </div>
  );
};

export default GeneralInformation;
