import React from "react";
import { notification, Alert } from "antd";
import classes from "../PaymentsPlatform.module.less";
import { newMonetizationRequest } from "../../../services/paymentsService";

const RequestAccessPayments = ({ title, text, buttonText, app }) => {
  const sendRequest = async () => {
    let email = localStorage.getItem("email");
    notification.info({
      message: "Sending request...",
      placement: "bottomLeft",
      duration: 2
    });
    let { data } = await newMonetizationRequest(title, email, app.id, app.name);
    if (data && data === "success") {
      notification.info({
        message:
          "Thank you for your request. An operations manager will email you soon.",
        placement: "bottomLeft",
        duration: 8
      });
      if (window.mixpanel) {
        window.mixpanel.track("Monetization access requested");
      }
    } else {
      notification.warning({
        message: "An error has ocurred.",
        placement: "bottomLeft"
      });
    }
  };
  const showConfirm = () => {
    sendRequest();
  };
  return (
    <div className={classes.alert}>
      {" "}
      <Alert
        message={
          <p>
            {text} To get started please get in touch with your account manager.
            <a onClick={() => showConfirm()}>{buttonText}</a>.
          </p>
        }
        type="warning"
        showIcon
      />
    </div>
  );
};
export default RequestAccessPayments;
