import React from "react";
import classes from "./BankTransferRenew.module.less";
import { Typography, Col, Row, Button, Modal } from "antd";
import LineCard from "../../../UI/LineCard/LineCard";
const { Paragraph } = Typography;
const { confirm } = Modal;
const BankTransferRenew = ({ total, renewOrder, loadingRenew }) => {
  const submitRequest = () => {
    confirm({
      title: `Are you sure you want to send this order?`,
      onOk() {
        renewOrder({ total });
      },
      onCancel() {}
    });
  };
  return (
    <div className={classes.container}>
      <LineCard title="Account Details">
        <Row>
          <Col span={8}>
            <Row type="flex" justify="start">
              <p> Company Name: </p>{" "}
              <Paragraph copyable>Company Demo</Paragraph>
            </Row>
          </Col>
          <Col span={8}>
            <Row type="flex" justify="start">
              <p> Company Address: </p>{" "}
              <Paragraph copyable>Address Demo</Paragraph>
            </Row>
          </Col>
          <Col span={8}>
            <Row type="flex" justify="start">
              <p> Bank Name:</p> <Paragraph copyable>Bank Demo</Paragraph>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Row type="flex" justify="start">
              <p> Bank Address: </p>{" "}
              <Paragraph copyable>Bank Address Demo</Paragraph>
            </Row>
          </Col>
          <Col span={8}>
            <Row type="flex" justify="start">
              <p> Account Number: </p>
              <Paragraph copyable>123456123456</Paragraph>
            </Row>
          </Col>
          <Col span={8}>
            <Row type="flex" justify="start">
              <p> SWIFT Code: </p> <Paragraph copyable>DEMODEMO123</Paragraph>
            </Row>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <p> Preferred Currency: </p> <Paragraph copyable>USD.</Paragraph>
        </Row>

        <Row type="flex" justify="center">
          <Button
            loading={loadingRenew}
            type="primary"
            onClick={() => submitRequest()}
          >
            Confirm Order
          </Button>
        </Row>
      </LineCard>
    </div>
  );
};

export default BankTransferRenew;
