const AICON_STORES = [
  {
    store: "tencent",
    name: "Tencent",
    url: "http://sj.qq.com/",
    scrapeable: true,
  },
  {
    store: "_360",
    name: "360",
    url: "http://zhushou.360.cn/",
    scrapeable: true,
  },
  {
    store: "oppo",
    name: "Oppo",
    url: "https://store.oppomobile.com/",
    scrapeable: false,
  },
  {
    store: "baidu",
    name: "Baidu",
    url: "http://as.baidu.com",
    scrapeable: true,
  },
  {
    store: "huawei",
    name: "Huawei",
    url: "http://app.hicloud.com/",
    scrapeable: false,
  },
  {
    store: "xiaomi",
    name: "Xiaomi",
    url: "http://app.xiaomi.com",
    scrapeable: true,
  },
  {
    store: "vivo",
    name: "Vivo",
    url: "https://dev.vivo.com.cn/doc/products/pc/index.html",
    scrapeable: false,
  },
  {
    store: "pp",
    name: "PP",
    url: "http://www.25pp.com/",
    scrapeable: true,
  },
  {
    store: "wandoujia",
    name: "Wandoujia",
    url: "http://www.wandoujia.com/",
    scrapeable: true,
  },
  {
    store: "himarket",
    name: "Himarket",
    url: "http://apk.hiapk.com/",
    scrapeable: true,
  },
  {
    store: "anzhi",
    name: "Anzhi",
    url: "http://www.anzhi.com/",
    scrapeable: true,
  },
  {
    store: "_2345",
    name: "2345",
    url: "http://zhushou.2345.com",
    scrapeable: true,
  },
  {
    store: "ct",
    name: "China Telecom",
    url: "https://www.189store.com/index/toMkt?type=1",
    scrapeable: false,
  },
  {
    store: "google",
    name: "Google Play",
    url: "http://play.google.com/store",
    scrapeable: false,
  },
  {
    store: "cheering",
    name: "Cheering",
    url: "http://apps.mycheering.com/WebPage/index.html",
    scrapeable: true,
  },
  {
    store: "mm",
    name: "Mobile Market",
    url: "http://mm.10086.cn/",
    scrapeable: true,
  },
  {
    store: "appchina",
    name: "AppChina",
    url: "http://www.appchina.com/",
    scrapeable: true,
  },
  {
    store: "cu",
    name: "China Unicom",
    url: "http://www.appchina.com/",
    scrapeable: false,
  },
  {
    store: "nicuoo",
    name: "Niucoo Assistant",
    url: "http://www.appchina.com/",
    scrapeable: false,
  },
  {
    store: "coolmarket",
    name: "Cool Market",
    url: "http://www.appchina.com/",
    scrapeable: false,
  },
  {
    store: "sogou",
    name: "Sogou",
    url: "http://zhushou.sogou.com/",
    scrapeable: true,
  },
  {
    store: "lenovo",
    name: "Lenovo",
    url: "http://www.lenovomm.com",
    scrapeable: true,
  },

  {
    store: "zol",
    name: "Zol",
    url: "http://xiazai.zol.com.cn",
    scrapeable: true,
  },
];

const FORM_OPTIONS = {
  published: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  tablet_devices: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  app_cost: [
    { value: "FREE", label: "Free" },
    { value: "FREEMIUM", label: "Freemium" },
    { value: "PAID", label: "Paid" },
  ],
  advertising: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  internet: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  revenue_model: [
    { value: "ADS", label: "Ads" },
    { value: "IAP", label: "IAP" },
    { value: "IOT", label: "IoT / Companion" },
    { value: "B2B", label: "B2B" },
  ],
};

const ACCOUNT_MANAGER = [
  { value: "JOANNE", label: "Joanne", email: "joanne@appinchina.co" },
  { value: "PAUL", label: "Paul", email: "paul@appinchina.co" },
  { value: "COLE", label: "Cole", email: "cole@appinchina.co" },
];

const APP_TESTING_CATEGORIES = [
  { value: "ANALYTICS", label: "Analytics" },
  { value: "MAPS", label: "Maps" },
  { value: "MONETIZATION", label: "Monetization" },
  { value: "PAYMENTS", label: "Payments" },
  { value: "PUSH_NOTIFICATIONS", label: "Push Notifications" },
  { value: "OTHER", label: "Other" },
];

const ROLES = [
  { value: "OWNER", label: "Owner" },
  { value: "ADMIN", label: "Admin" },
  { value: "VIEWER", label: "Viewer" },
  { value: "PAYMENTS", label: "Payments Manager" },
];

const TRADEMARK_CLASSES = [
  {
    number: 1,
    definition:
      "Chemicals used in industry, science and photography, as well as in agriculture, horticulture and forestry; unprocessed artificial resins, unprocessed plastics; manures; fire extinguishing compositions; tempering and soldering preparations; chemical substances for preserving foodstuffs; tanning substances; adhesives used in industry; unprocessed plastics in the form of liquids, chips or granules.",
  },
  {
    number: 2,
    definition:
      "Paints, varnishes, lacquers; preservatives against rust and against deterioration of wood; colorants; mordants; raw natural resins; metals in foil and powder form for painters, decorators, printers and artists.",
  },
  {
    number: 3,
    definition:
      "Bleaching preparations and other substances for laundry use; cleaning, polishing, scouring and abrasive preparations; soaps; perfumery, essential oils, cosmetics, hair lotions; dentifrices.",
  },
  {
    number: 4,
    definition:
      "Industrial oils and greases; lubricants; dust absorbing, wetting and binding compositions; fuels and illuminants; candles and wicks for lighting; combustible fuels, electricity and scented candles.",
  },
  {
    number: 5,
    definition:
      "Pharmaceutical and veterinary preparations; sanitary preparations for medical purposes; dietetic food and substances adapted for medical or veterinary use, food for babies; dietary supplements for humans and animals; plasters, materials for dressings; material for stopping teeth, dental wax; disinfectants; preparations for destroying vermin; fungicides, herbicides.",
  },
  {
    number: 6,
    definition:
      "Common metals and their alloys; metal building materials; transportable buildings of metal; materials of metal for railway tracks; non-electric cables and wires of common metal; ironmongery, small items of metal hardware; pipes and tubes of metal; safes; goods of common metal not included in other classes; ores; unwrought and partly wrought common metals; metallic windows and doors; metallic framed conservatories.",
  },
  {
    number: 7,
    definition:
      "Machines and machine tools; motors and engines (except for land vehicles); machine coupling and transmission components (except for land vehicles); agricultural implements other than hand-operated; incubators for eggs; automatic vending machines.",
  },
  {
    number: 8,
    definition:
      "Hand tools and hand operated implements; cutlery; side arms; razors; electric razors and hair cutters.",
  },
  {
    number: 9,
    definition:
      "Scientific, nautical, surveying, photographic, cinematographic, optical, weighing, measuring, signalling, checking (supervision), life-saving and teaching apparatus and instruments; apparatus and instruments for conducting, switching, transforming, accumulating, regulating or controlling electricity; apparatus for recording, transmission or reproduction of sound or images; magnetic data carriers, recording discs; compact discs, DVDs and other digital recording media; mechanisms for coin-operated apparatus; cash registers, calculating machines, data processing equipment, computers; computer software; fire-extinguishing apparatus.",
  },
  {
    number: 10,
    definition:
      "Surgical, medical, dental and veterinary apparatus and instruments, artificial limbs, eyes and teeth; orthopaedic articles; suture materials; sex aids; massage apparatus; supportive bandages; furniture adapted for medical use.",
  },
  {
    number: 11,
    definition:
      "Apparatus for lighting, heating, steam generating, cooking, refrigerating, drying, ventilating, water supply and sanitary purposes; air conditioning apparatus; electric kettles; gas and electric cookers; vehicle lights and vehicle air conditioning units.",
  },
  {
    number: 12,
    definition:
      "Vehicles; apparatus for locomotion by land, air or water; wheelchairs; motors and engines for land vehicles; vehicle body parts and transmissions.",
  },
  {
    number: 13,
    definition: "Firearms; ammunition and projectiles, explosives; fireworks.",
  },
  {
    number: 14,
    definition:
      "Precious metals and their alloys; jewellery, costume jewellery, precious stones; horological and chronometric instruments, clocks and watches.",
  },
  {
    number: 15,
    definition:
      "Musical instruments; stands and cases adapted for musical instruments.",
  },
  {
    number: 16,
    definition:
      "Paper, cardboard and goods made from these materials, not included in other classes; printed matter; bookbinding material; photographs; stationery; adhesives for stationery or household purposes; artists’ materials; paint brushes; typewriters and office requisites (except furniture); instructional and teaching material (except apparatus); plastic materials for packaging (not included in other classes); printers’ type; printing blocks.",
  },
  {
    number: 17,
    definition:
      "Rubber, gutta-percha, gum, asbestos, mica and goods made from these materials; plastics in extruded form for use in manufacture; semi-finished plastics materials for use in further manufacture; stopping and insulating materials; flexible non-metallic pipes.",
  },
  {
    number: 18,
    definition:
      "Leather and imitations of leather; animal skins, hides; trunks and travelling bags; handbags, rucksacks, purses; umbrellas, parasols and walking sticks; whips, harness and saddlery; clothing for animals.",
  },
  {
    number: 19,
    definition:
      "Non-metallic building materials; non-metallic rigid pipes for building; asphalt, pitch and bitumen; non-metallic transportable buildings; non-metallic monuments; non-metallic framed conservatories, doors and windows.",
  },
  {
    number: 20,
    definition:
      "Furniture, mirrors, picture frames; articles made of wood, cork, reed, cane, wicker, horn, bone, ivory, whalebone, shell, amber, mother-of-pearl, meerschaum or plastic which are not included in other classes; garden furniture; pillows and cushions.",
  },
  {
    number: 21,
    definition:
      "Household or kitchen utensils and containers; combs and sponges; brushes (except paintbrushes); brush-making materials; articles for cleaning purposes; steel wool; articles made of ceramics, glass, porcelain or earthenware which are not included in other classes; electric and non-electric toothbrushes.",
  },
  {
    number: 22,
    definition:
      "Ropes, string, nets, tents, awnings, tarpaulins, sails, sacks for transporting bulk materials; padding and stuffing materials which are not made of rubber or plastics; raw fibrous textile materials.",
  },
  { number: 23, definition: "Yarns and threads, for textile use." },
  {
    number: 24,
    definition:
      "Textiles and textile goods; bed and table covers; travellers’ rugs, textiles for making articles of clothing; duvets; covers for pillows, cushions or duvets.",
  },
  { number: 25, definition: "Clothing, footwear, headgear." },
  {
    number: 26,
    definition:
      "Lace and embroidery, ribbons and braid; buttons, hooks and eyes, pins and needles; artificial flowers.",
  },
  {
    number: 27,
    definition:
      "Carpets, rugs, mats and matting, linoleum and other materials for covering existing floors; wall hangings (non-textile); wallpaper.",
  },
  {
    number: 28,
    definition:
      "Games and playthings; playing cards; gymnastic and sporting articles; decorations for Christmas trees; childrens’ toy bicycles.",
  },
  {
    number: 29,
    definition:
      "Meat, fish, poultry and game; meat extracts; preserved, dried and cooked fruits and vegetables; jellies, jams, compotes; eggs, milk and milk products; edible oils and fats; prepared meals; soups and potato crisps.",
  },
  {
    number: 30,
    definition:
      "Coffee, tea, cocoa, sugar, rice, tapioca, sago, artificial coffee; flour and preparations made from cereals, bread, pastry and confectionery, edible ices; honey, treacle; yeast, baking-powder; salt, mustard; vinegar, sauces (condiments); spices; ice; sandwiches; prepared meals; pizzas, pies and pasta dishes.",
  },
  {
    number: 31,
    definition:
      "Agricultural, horticultural and forestry products; live animals; fresh fruits and vegetables, seeds, natural plants and flowers; foodstuffs for animals; malt; food and beverages for animals.",
  },
  {
    number: 32,
    definition:
      "Beers; mineral and aerated waters; non-alcoholic drinks; fruit drinks and fruit juices; syrups for making beverages; shandy, de-alcoholised drinks, non-alcoholic beers and wines.",
  },
  {
    number: 33,
    definition:
      "Alcoholic beverages (except beers); alcoholic wines; spirits and liqueurs; alcopops; alcoholic cocktails.",
  },
  {
    number: 34,
    definition: "Tobacco; smokers’ articles; matches; lighters for smokers.",
  },
  {
    number: 35,
    definition:
      "Advertising; business management; business administration; office functions; organisation, operation and supervision of loyalty and incentive schemes; advertising services provided via the Internet; production of television and radio advertisements; accountancy; auctioneering; trade fairs; opinion polling; data processing; provision of business information; retail services connected with the sale of [list specific goods].",
  },
  {
    number: 36,
    definition:
      "Insurance; financial services; real estate agency services; building society services; banking; stockbroking; financial services provided via the Internet; issuing of tokens of value in relation to bonus and loyalty schemes; provision of financial information.",
  },
  {
    number: 37,
    definition:
      "Building construction; repair; installation services; installation, maintenance and repair of computer hardware; painting and decorating; cleaning services.",
  },
  {
    number: 38,
    definition:
      "Telecommunications services; chat room services; portal services; e-mail services; providing user access to the Internet; radio and television broadcasting.",
  },
  {
    number: 39,
    definition:
      "Transport; packaging and storage of goods; travel arrangement; distribution of electricity; travel information; provision of car parking facilities.",
  },
  {
    number: 40,
    definition:
      "Treatment of materials; development, duplicating and printing of photographs; generation of electricity.",
  },
  {
    number: 41,
    definition:
      "Education; providing of training; entertainment; sporting and cultural activities.",
  },
  {
    number: 42,
    definition:
      "Scientific and technological services and research and design relating thereto; industrial analysis and research services; design and development of computer hardware and software; computer programming; installation, maintenance and repair of computer software; computer consultancy services; design, drawing and commissioned writing for the compilation of web sites; creating, maintaining and hosting the web sites of others; design services.",
  },
  {
    number: 43,
    definition:
      "Services for providing food and drink; temporary accommodation; restaurant, bar and catering services; provision of holiday accommodation; booking and reservation services for restaurants and holiday accommodation; retirement home services; creche services.",
  },
  {
    number: 44,
    definition:
      "Medical services; veterinary services; hygienic and beauty care for human beings or animals; agriculture, horticulture and forestry services; dentistry services; medical analysis for the diagnosis and treatment of persons; pharmacy advice; garden design services.",
  },
  {
    number: 45,
    definition:
      "Legal services; conveyancing services; security services for the protection of property and individuals; social work services; consultancy services relating to health and safety; consultancy services relating to personal appearance; provision of personal tarot readings; dating services; funeral services and undertaking services; fire-fighting services; detective agency services.",
  },
];

const TRADEMARK_STATUSES = {
  未续展: {
    translation: "Not Renewed",
    color: "gold",
  },
  已无效: {
    translation: "Invalid",
    color: "red",
  },
  待审查: {
    translation: "Pending Review",
    color: "blue",
  },
  已驳回: {
    translation: "Rejected",
    color: "orange",
  },
  已注册: {
    translation: "Registered",
    color: "green",
  },
  未知: {
    translation: "Unknown",
    color: "purple",
  },
  已撤销: {
    translation: "Revoked",
    color: "volcano",
  },
  已撤回: {
    translation: "Withdrawn",
    color: "magenta",
  },
};

export {
  AICON_STORES,
  FORM_OPTIONS,
  ACCOUNT_MANAGER,
  APP_TESTING_CATEGORIES,
  ROLES,
  TRADEMARK_CLASSES,
  TRADEMARK_STATUSES,
};
