import React from "react";
import { Chart, Geom, Axis, Tooltip, Coord } from "bizcharts";

export default ({ data = [], x, y, position }) => {
  return (
    <div>
      <Chart height={350} data={data} forceFit>
        <Coord transpose />
        <Axis
          name={x}
          label={{
            offset: 12,
          }}
        />
        <Axis name={y} />
        <Tooltip />
        <Geom type="interval" position={position} />
      </Chart>
    </div>
  );
};
