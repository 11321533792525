import React from "react";
import { Spin } from "antd";
import { Chart, Geom, Tooltip, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import classes from "./HeatMap.module.less";
const { AMapUI } = window;

const getName = {
  广东省: "Guangdong",
  四川省: "Sichuan",
  安徽省: "Anhui",
  浙江省: "Zheijiang",
  湖北省: "Hubei",
  上海市: "Shanghai",
  福建省: "Fujian",
  重庆市: "Chongqing",
  江苏省: "Jiangsu",
  陕西省: "Shaanxi",
  山东省: "Shandong",
  河北省: "Hebei",
  江西省: "Jiangxi",
  湖南省: "Hunan",
  云南省: "Yunnan",
  北京市: "Beijing",
  甘肃省: "Gansu",
  吉林省: "Jilin",
  贵州省: "Guizhou",
  广西壮族自治区: "Guangxi",
  河南省: "Henan",
  辽宁省: "Liaoning",
  内蒙古自治区: "Inner Mongolia",
  新疆维吾尔自治区: "Xinjiang",
  黑龙江省: "Heilongjiang",
  天津市: "Tianjin",
  山西省: "Shanxi",
  海南省: "Hainan",
  宁夏回族自治区: "Ningxia",
  青海省: "Qinghai",
  西藏自治区: "Tibet",
};

const constructGeoJSON = (features) => {
  if (!features) return false;
  if (Array.isArray(features)) {
    return {
      type: "FeatureCollection",
      features: [...features],
    };
  }
  return features;
};

const getGeojsonByCode = (adcode = 100000, withSub = true) => {
  if (!AMapUI) {
    return Promise.reject();
  }
  return new Promise((resolve, reject) => {
    AMapUI.load("ui/geo/DistrictExplorer", (DistrictExplorer) => {
      const districtExplorer = new DistrictExplorer();
      districtExplorer.loadAreaNode(adcode, (error, areaNode) => {
        if (error) {
          reject();
        }
        let res = null;
        if (withSub) {
          res = areaNode.getSubFeatures();
        } else {
          res = areaNode.getParentFeature();
        }
        resolve(constructGeoJSON(res));
      });
    });
  });
};

class MapChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chinaGeo: null,
    };
  }

  componentDidMount() {
    getGeojsonByCode(100000, true).then((res) => {
      this.setState({ chinaGeo: res });
    });
  }

  processGeoData = (geoData, dataValue) => {
    const { features } = geoData;
    features.forEach((one) => {
      const name = one && one.properties && one.properties.name;
      dataValue.forEach((item) => {
        if (name.includes(item.name)) {
          one.value = item.value;
        }
      });
    });

    const geoDv = new DataSet.View().source(geoData, { type: "GeoJSON" });
    return geoDv;
  };

  render() {
    const area = this.props.data;
    const { chinaGeo } = this.state;
    if (!chinaGeo) {
      return <Spin />;
    }
    const data = this.processGeoData(chinaGeo, area);
    const scale = {
      latitude: {
        sync: true,
        nice: false,
      },
      longitude: {
        sync: true,
        nice: false,
      },
    };
    return [
      <div
        className={classes.container}
        key="1"
        style={{ position: "relative" }}
      >
        <Chart
          height={660}
          width={774}
          scale={scale}
          data={data}
          padding={[0, 0, 0, 90]}
        >
          <Geom
            type="polygon"
            position="longitude*latitude"
            style={{ lineWidth: 1, stroke: "#4e7d00" }}
            color={["value", ["#f3f8eb", "#4e7d00"]]}
            tooltip={[
              "name*value",
              (name, value) => ({
                name: getName[name],
                value,
              }),
            ]}
          >
            <Tooltip showTitle={false} />
            <Legend
              position="bottom-left"
              offsetY={-130}
              offsetX={-60}
              slidable={false}
              width={320}
            />
          </Geom>
        </Chart>
      </div>,
    ];
  }
}

export default MapChart;
