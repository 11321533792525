import React, { useState, useEffect } from "react";
import { Card, Row, Col, Progress } from "antd";
import { Pie } from "ant-design-pro/lib/Charts";
import classes from "./UsersRetention.module.less";
import { usersRetentionFake } from "../PromotionFakeData";
import { getUsersRetentionSummary } from "../../../services/promotionService";
export default ({ app, useDemoData, store }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getUsersRetentionSummary({
      promotionId: app.analitycs_id,
      store,
      app: app.id,
    });
    setData(response);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData({ ...usersRetentionFake });
        setLoading(false);
      }, 500);
    }
  };
  useEffect(() => {
    validateRequest();
  }, [app]);

  return (
    <div className={classes.container}>
      <Card loading={loading} title="Users Retention" style={{ marginTop: 15 }}>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Pie
                  percent={data.sevenDaysProportion}
                  subTitle="1 day / 7 days"
                  total={`${data.sevenDaysProportion || 0}%`}
                  height={100}
                />
              </Col>
              <Col span={12}>
                <Pie
                  percent={data.thirtyDaysProportion}
                  subTitle="1 day / 30 days"
                  total={`${data.thirtyDaysProportion || 0}%`}
                  height={100}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <h3>1 Day</h3>
            <Progress percent={data.oneDayProportion} />
            <h3>7 Days</h3>
            <Progress percent={data.sevenDaysProportion} />
            <h3>14 Days</h3>
            <Progress percent={data.fourteenDaysProportion} />
          </Col>
        </Row>
      </Card>
    </div>
  );
};
