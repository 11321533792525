import axios from "../axios";

export const newFeedback = async values => {
  let response = null;
  try {
    response = await axios.post("/new-feedback", {
      ...values
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
