import React, { Component } from "react";
import { Spin } from "antd";
import AppTesting from "../AppTesting/AppTesting";
import Translation from "../Translation/Translation";
import TrademarkSearch from "../TrademarkSearch/TrademarkSearch";
import history from "../../../history";
import Axios from "axios";

class LocalizationHome extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    if (window.mixpanel) {
      window.mixpanel.track("Localization loaded");
    }
  }

  submitFile = (file, progressCallback) => {
    try {
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      const result = `${timeStamp}/${file.name}`;
      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      Axios.post("https://appinchina.space/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          progressCallback(percentCompleted);
        },
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  getContent = () => {
    let section = this.props.section || "integrations";
    let { apps, appId } = this.props;
    let app = apps.find((app) => app.id === appId);
    switch (section) {
      case "integrations":
        return (
          <AppTesting app={app} submitFile={this.submitFile} {...this.props} />
        );
      case "translation":
        return (
          <Translation app={app} submitFile={this.submitFile} {...this.props} />
        );
      case "trademark-search":
        return <TrademarkSearch app={app} />;
      default:
        return (
          <AppTesting app={app} submitFile={this.submitFile} {...this.props} />
        );
    }
  };

  callback = (key, app) => {
    history.push(`/localization/${app.id}/${key}`);
  };

  render() {
    const { loading } = this.state;
    return loading ? (
      <Spin
        tip="Loading..."
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    ) : (
      this.getContent()
    );
  }
}

export default LocalizationHome;
