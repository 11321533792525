import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Input,
  notification,
  Upload,
  Icon,
} from "antd";
import Axios from "axios";
import { createDistributionEvent } from "../../../../../services/distributionService";
export default Form.create()(
  ({ form, updateHistory, visible, setVisible, infoId }) => {
    const [saving, setSaving] = useState(false);
    const [uploadingImg, setUploadingImg] = useState(false);
    const uploadImage = async ({ file, onSuccess, onProgress, onError }) => {
      const timeStamp = new Date().getTime();
      const formData = new FormData();
      const result = `${timeStamp}/${file.name}`;

      formData.set("uploads[]", file);
      formData.set("name", file.name);
      formData.set("timestamp", timeStamp);
      setUploadingImg(true);
      let { data } = await Axios.post(
        "https://appinchina.space/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({
              percent: percentCompleted,
            });
          },
        }
      );
      setUploadingImg(false);
      if (data) {
        onSuccess(result);
      } else {
        onError("error");
      }
    };

    const uploadProps = {
      listType: "picture",
    };

    const handleSubmit = () => {
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            setSaving(true);
            let history_attachments = values.files
              ? values.files.fileList.map(({ name, size, response }) => ({
                  file_name: name,
                  file_size: size,
                  url: response,
                }))
              : [];
            let format = {
              client_notes: values.client_notes,
              history_attachments,
            };
            const { data: response } = await createDistributionEvent({
              ...format,
              distributionId: infoId,
            });
            if (response && response.message === "success") {
              updateHistory({ ...format });
            }
            setSaving(false);
            setVisible(false);
            form.resetFields();
          } catch (error) {
            console.log(error);
            notification.error({
              message: "An error has ocurred.",
              placement: "bottomLeft",
            });
          }
        }
      });
    };

    const { getFieldDecorator } = form;

    return (
      <div>
        <Modal
          title="Adding Comments/Files"
          visible={visible}
          footer={null}
          onCancel={() => setVisible(false)}
          confirmLoading={saving}
          destroyOnClose={true}
        >
          <Form hideRequiredMark={true}>
            <Form.Item label="Notes">
              {getFieldDecorator(`client_notes`, {
                rules: [{ required: true, message: "Required" }],
              })(<Input.TextArea />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(`files`)(
                <Upload
                  onRemove={(file) => setUploadingImg(false)}
                  disabled={saving}
                  {...uploadProps}
                  customRequest={uploadImage}
                >
                  <Button loading={uploadingImg}>
                    <Icon type={uploadingImg ? "loading" : "upload"} />
                    {uploadingImg
                      ? "Uploading File"
                      : " Click to Upload New File(s)"}
                  </Button>
                </Upload>
              )}
            </Form.Item>
            <Row type="flex" justify="center" align="middle">
              <Button
                loading={saving}
                disabled={uploadingImg}
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 10 }}
              >
                Save History
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
);
