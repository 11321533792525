import React, { useState } from "react";
import classes from "./SdkManagementApp.module.less";
import CredentialsModal from "./CredentialsModal/CredentialsModal";
import { Button, Card, Table, Popover, Icon, Collapse } from "antd";
import history from "../../../history";
import { getSubscriptionsServer } from "../../../services/paymentsSubscriptionsService";
import EventBus from "eventbusjs";
import InAppPurchaseItems from "./InAppPurchaseItems/InAppPurchaseItems";
import AutoRenewalItems from "./AutoRenewalItems/AutoRenewalItems";
import SubscriptionModal from "./SubscriptionModal/SubscriptionModal";
import { Link } from "react-router-dom";
const { Panel } = Collapse;

const SdkManagementApp = ({ apps, appId, ...props }) => {
  let [app, setApp] = useState(apps.find((it) => it.id === appId));
  let [tab, setTab] = useState("tab1");
  let [subscription, setSubscription] = useState({});
  let [subscriptions, setSubscriptions] = useState([]);
  let [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  let [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false);
  let [credentialsModalVisible, setCredentialsModalVisible] = useState(false);

  /*const OLD_ACTIONS = (
    <div className={classes.actions}>
      <Button
        icon="delete"
        type="danger"
        onClick={() => deleteSubscription(item)}
      ></Button>
    </div>
  );*/

  let subscriptionsColumns = [
    {
      title: (
        <Popover
          placement="bottomLeft"
          content={
            <p style={{ maxWidth: 400, textAlign: "left", margin: 0 }}>
              The serviceNo you create here will be the serviceNo you input into
              the API. Each serviceNo also specifications you can modify
              (serviceTypeNo, serviceTypeName, periodType, periodValue), a
              serviceNo will can have multiple serviceTypeNos. Think of the
              serviceNo as a model number for a product. (i.e. iPhone 12, iPhone
              12 pro, iPhone 12 mini etc), each serviceNo (model) can have
              multiple types of specifications, i.e. 128gb, 256gb etc. These
              different specifications are the different serviceTypeNos. When
              you create a serviceNo, the server will automatically create
              several types of commonly used specifications for you. You can
              modify or add new ones as needed. The information you use for
              freeTrialServiceNo is the same as serviceNo.
            </p>
          }
        >
          serviceNo <Icon type="question-circle-o" />
        </Popover>
      ),
      dataIndex: "serviceNo",
    },
    {
      title: (
        <Popover
          placement="bottomLeft"
          content={
            <p style={{ maxWidth: 400, textAlign: "left", margin: 0 }}>
              A descriptor name for your serviceNo
            </p>
          }
        >
          serviceName <Icon type="question-circle-o" />
        </Popover>
      ),
      dataIndex: "serviceName",
    },
    {
      title: "",
      dataIndex: "id",
      render: (_, item) =>
        //this is to prevent the user from editing or modifying the demo subscription which should be available for every client
        item.serviceNo === "VIP" && item.accountId === "20190307001" ? null : (
          <Link to={`/sdk-management/details/${appId}/${item.serviceNo}`}>
            Edit
          </Link>
        ),
    },
  ];

  let addSubscription = async () => {
    setSubscription({});
    setSubscriptionModalVisible(true);
  };

  /*let deleteItem = async ({ id, accountId }) => {
    setLoadingSubscriptions(true);
    let { data } = await deleteSubscriptionServer(id, accountId, app.id);
    if (data && data.message === "success") {
      notification.success({
        message: "Succesfully deleted item",
        placement: "bottomLeft",
      });
      let dataCopy = subscriptions.filter((it) => it.id !== id);
      setSubscriptions(dataCopy);
    } else {
      notification.error({
        message: "An error occurred",
        placement: "bottomLeft",
      });
    }
    setLoadingSubscriptions(false);
  };*/

  /*let deleteSubscription = async (id) => {
    confirm({
      title: "Are you sure you want to delete this item?",
      onOk() {
        deleteItem(id);
      },
      onCancel() {},
    });
  };*/

  let getSubscriptions = async () => {
    setLoadingSubscriptions(true);
    let { data } = await getSubscriptionsServer(app.analitycs_id, app.id);
    if (data && data.length) {
      setSubscriptions(data);
    }
    setLoadingSubscriptions(false);
  };

  let handleCredentialsAdded = (data) => {
    let newAppValues = {
      ...app,
      analitycs_id: data.id,
      main_information: {
        ...app.main_information,
        aicpay_appname: data.companyName,
        aicpay_secret: data.accountSecret,
        aicpay_package: data.aicpay_package,
        max_logon_num: data.maxLogonNum,
      },
    };
    setApp(newAppValues);
    EventBus.dispatch("UPDATE_APP", this, newAppValues);
    setCredentialsModalVisible(false);
  };
  let handleCredentialsEdited = (data) => {
    let newAppValues = {
      ...app,
      main_information: {
        ...app.main_information,
        max_logon_num: data.max_logon_num,
        aicpay_package: data.aicpay_package,
      },
    };
    setApp(newAppValues);
    EventBus.dispatch("UPDATE_APP", this, newAppValues);
    setCredentialsModalVisible(false);
  };

  let handleSubscriptionAdded = (values) => {
    history.push(
      `/sdk-management/details/${appId}/${values.accountService.serviceNo}`
    );
  };

  useState(() => {
    if (app.analitycs_id) getSubscriptions();
  }, [appId]);

  return (
    <div className={classes.container}>
      <CredentialsModal
        visible={credentialsModalVisible}
        app={app}
        onAdd={handleCredentialsAdded}
        onEdit={handleCredentialsEdited}
        onClose={() => setCredentialsModalVisible(false)}
      />
      <SubscriptionModal
        visible={subscriptionModalVisible}
        id={app.analitycs_id}
        appId={app.id}
        {...subscription}
        onAdd={handleSubscriptionAdded}
        onClose={() => setSubscriptionModalVisible(false)}
      />
      <div className={classes.backButton}>
        <Button
          icon="left"
          onClick={() => history.push(`/sdk-management/${appId}/`)}
        >
          Back
        </Button>
      </div>
      <Card
        title="Details"
        className={classes.details}
        extra={
          app.main_information.aicpay_secret ? (
            <Button onClick={() => setCredentialsModalVisible(true)}>
              Edit
            </Button>
          ) : null
        }
      >
        {app.main_information.aicpay_secret ? (
          <ul>
            <li>
              <span>App Name:</span> {app.main_information.aicpay_appname}
            </li>
            <li>
              <span>App ID:</span> {app.analitycs_id}
            </li>
            <li>
              <span>App Secret:</span> {app.main_information.aicpay_secret}
            </li>
            <li>
              <span>No. of Simultaneous Device Logins:</span>{" "}
              {app.main_information.max_logon_num || 0}
            </li>
            <li>
              <span>Package Name:</span>{" "}
              {app.main_information.aicpay_package || "N/A"}
            </li>
          </ul>
        ) : (
          <div className={classes.applyForService}>
            <p>
              Your app currently does not have an App ID and an App Secret yet.
              Please click on the button below to begin.
            </p>
            <p>
              <Button
                onClick={() => setCredentialsModalVisible(true)}
                type="primary"
              >
                Create Credentials
              </Button>
            </p>
          </div>
        )}
      </Card>
      <br />

      <Card
        style={{ width: "100%" }}
        extra={<p></p>}
        tabList={[
          {
            key: "tab1",
            tab: (
              <Popover
                placement="bottomLeft"
                content={
                  <p style={{ maxWidth: 400, textAlign: "left", margin: 0 }}>
                    This is for developer’s reference only, used to keep track
                    of the items you sell in your app. It is not required that
                    you fill this table out)
                  </p>
                }
              >
                In App Purchase Items List (single purchase){" "}
                <Icon type="question-circle-o" />
              </Popover>
            ),
          },
          {
            tab: "Auto Renewal Agreement Title List",
            key: "tab2",
          },
        ]}
        activeTabKey={tab}
        onTabChange={(newKey) => {
          setTab(newKey);
        }}
      >
        {tab === "tab1" ? (
          <InAppPurchaseItems app={app} />
        ) : (
          <AutoRenewalItems app={app} />
        )}
      </Card>

      <Table
        loading={loadingSubscriptions}
        className={classes.shortcuts}
        bordered
        title={() => (
          <div>
            <Popover
              placement="bottomLeft"
              content={
                <p style={{ maxWidth: 400, textAlign: "left", margin: 0 }}>
                  It is required that you create the serviceNo in this table
                  before you send information in the API
                </p>
              }
            >
              <b>Subscriptions List</b> <Icon type="question-circle-o" />
            </Popover>
            <Button
              icon="plus"
              onClick={() => app.analitycs_id && addSubscription()}
              disabled={!app.analitycs_id}
            >
              Add Item
            </Button>
          </div>
        )}
        columns={subscriptionsColumns}
        dataSource={subscriptions}
        rowKey="name"
      />
      <br />
      <Collapse>
        <Panel
          header={
            <p>
              <b>API Information</b>
            </p>
          }
          key="1"
        >
          <h2>In-App Purchase Items</h2>
          <p>
            Single purchase Items (createOrder API):{" "}
            <a
              href="https://documentation.appinchina.co/Order.html"
              target="_blank"
            >
              https://documentation.appinchina.co/Order.html
            </a>
            <br />
            <br />
            Definitions:
            <br />
            <br />
            <b>goodsTitle:</b> This is the item’s name that will appear on the
            order page the user sees in WeChat and Alipay
            <br />
            <b>amount:</b> This is amount in Chinese currency (RMB) that will be
            charged to the user. The amount displayed here in in cents. So for 1
            RMB, you’ll need to use 100 in the api. when adding information in
            the table below, please also total amount in cents.
            <br />
            <b>bizNO:</b> randomly generated order number by the developer for
            record keeping.
            <br />
            <b>payChannel:</b> WeChat or Alipay or Both. Subscriptions are not
            supported for WeChat pay.
            <br />
            <b>customerIdentity:</b> EEither generated by AppInChina’s server if
            you’re using our SMS system, or generated by the developer to
            identity a user. More details on{" "}
            <a
              href="https://documentation.appinchina.co/BasicPrinciples.html"
              target="_blank"
            >
              this link
            </a>
            <br />
            Please note, certain API parameters below do need to be set as they
            are determined by certain factors during order creation. Table below
            only displays required parameters that you must send. You can view
            all supported parameters from the development documentation.
          </p>
          <br />
          <br />

          <h2>
            Auto renewal sign agreement (Only for apps that have been granted
            this function)
          </h2>
          <p>
            Signing auto renewal agreement (Sign Agreement):{" "}
            <a
              href="https://documentation.appinchina.co/SignAgreement.html"
              target="_blank"
            >
              https://documentation.appinchina.co/SignAgreement.html
            </a>
            <br />
            <b>customerIdentity:</b> Either generated by AppInChina’s server if
            you’re using our SMS system, or generated by the developer to
            identity a user.
            <a
              href="https://documentation.appinchina.co/BasicPrinciples.html"
              target="_blank"
            >
              https://documentation.appinchina.co/BasicPrinciples.html
            </a>
            <br />
            <b>scene:</b> required by Alipay for signing agreements. Please use
            the correct scene based on your app’s industry. The official list
            can be found here:
            <a
              href="https://opendocs.alipay.com/open/20190319114403226822/signscene"
              target="_blank"
            >
              https://opendocs.alipay.com/open/20190319114403226822/signscene
            </a>
            <br />
            <b>payChannel:</b> As autorenewal agreements can only be used for
            Alipay, this MUST be ALIPAY
            <br />
            <b>periodType:</b> Submit either DAY or MONTH into the API. You set
            the period type as days or months. If you set as DAY, then renewal
            will happen X amount of days, if set as MONTH, then renewal logic
            will execute by X amount of months.
            <br />
            <b>periodValue:</b> This is how many periods should elapse before
            the next renewal will happen, if you set periodValue as 7 and
            periodType as DAY, then renewal will happen every 7 days. if you set
            periodValue as 1 and periodType as MONTH, then renewal will happen
            according to the a natural month. If renewal starts on the 8th, then
            it will start the next renewal on the 8th of next month.
            <br />
            <b>nextExecDate:</b> When signing an agreement for the very first
            time, developers must evaluate a correct date of when the first
            autorenewal will need to happen and send the timestamp in this
            parameter. The first renewal will start on this date.
            <br />
            *our server has a lastExecDate, we automatically calculate this as
            nextExectDate -2 days. We will attempt to start taking money on the
            lastExecDate. and will try 3 times (if payment fails). In theory,
            money will be taken out of a user’s account 3 days before their
            renewal date is up. If it fails, then auto renewal function will be
            cancelled for that user. For example: if a brand new user starts a
            subscription on January 1st and this is a month subscription. The
            developer will send the timestamp for February 1st as the
            nextExecDate in the agreement. Our server will then attempt to take
            money out of the user’s account beginning January 30th, January
            31st, and February 1st.
            <br />
            <b>serviceNo:</b> links the agreement and lets the server know a
            particular product you’re linking to the order
            <br />
            <b>serviceNoType:</b> sending the correct subscription valid time
            for an order
            <br />
            <b>freeTrialServiceNo:</b> This is is reused from the serviceNo.
            Example: You have 3 types of VIP, one ofr PC users, one for PHONE
            users and one for TV users. the serviceNo PC_VIP with the
            serviceNoType VIP_14Days means that the user will pay for PC version
            of the VIP for 14 days. If you want to give this user a free trial
            of 14 days, then you can just append the serviceNo PC_VIP in this
            parameter to give them 14 days of free trial.
            <br />
            <b>freeTrialserviceNoType:</b> Same as serviceNoType.
            <br />
            <b>totalPayments:</b> The number of times the auto renewal will
            happen. Example: If you want your renewal to happen forever (until
            either party stops) then 0 will be put into here. If you use 12 and
            the user is renewing their monthly subscription, then the auto
            renewal will renew for 12 consecutive months and the agreement will
            be cancelled after this has happened.
            <br />
            <b>agreementTitle:</b> This is the item’s name for the renewal
            agreement (much like the goodsTitle in single purchase API)
            <br />
          </p>
        </Panel>
      </Collapse>
      <br />
    </div>
  );
};

export default SdkManagementApp;
