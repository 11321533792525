import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import functions from "../../../functions";
import { getPaidUsersSummary } from "../../../services/promotionService";
import { paidUsersTimeTable } from "../PromotionFakeData";

export default ({ app, useDemoData }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    validateRequest();
  }, []);

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getPaidUsersSummary({
      promotionId: app.analitycs_id,
      app: app.id
    });
    setData(response.result);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData(paidUsersTimeTable);
        setLoading(false);
      }, 500);
    }
  };

  const firstTableColumns = [
    {
      title: "",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Paying Users",
      dataIndex: "paidUsers",
      key: "paidUsers",
      render: (revenue) => `${functions.styleAsNumber(revenue)}`,
    },
    {
      title: "Payment Amount",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      render: (revenue) => `${functions.styleAsNumber(revenue)}`,
    },
    {
      title: "Order Volume",
      dataIndex: "orderVolume",
      key: "orderVolume",
      render: (revenue) => `${functions.styleAsNumber(revenue)}`,
    },
  ];

  return (
    <Card style={{ marginTop: 15 }}>
      <Table
        size="small"
        columns={firstTableColumns}
        dataSource={data}
        loading={loading}
      />
    </Card>
  );
};
