import React, { useState, Fragment, useEffect } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Spin } from "antd";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import classes from "./Editor.module.less";
import File from "../../UI/File/File";
export default ({ article, loading, actualCategory }) => {
  const [contentState, setContentState] = useState(null);
  const [editable, setEditable] = useState(true);
  const [attachments, setAttachments] = useState([]);
  useEffect(() => {
    if (article && article.id) {
      setAttachments(article.help_articles_attachments);
      setContentState(
        EditorState.createWithContent(convertFromRaw(article.content))
      );
    }
  }, [article]);

  return (
    <div className={classes.container}>
      {contentState ? (
        <Fragment>
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <div>
              <h2>{article.title}</h2>
              <Editor
                toolbarHidden={editable}
                readOnly={editable}
                editorState={contentState}
                onEditorStateChange={(contentState) =>
                  setContentState(contentState)
                }
              />
              <div className={classes.attachments}>
                {attachments
                  ? attachments.map((it) => (
                      <div key={it.id}>
                        <File
                          key={it.id}
                          validation={{}}
                          value={it.file_url}
                          title={it.file_name}
                          disabled
                        />
                      </div>
                    ))
                  : []}
              </div>
            </div>
          )}
        </Fragment>
      ) : null}
    </div>
  );
};
