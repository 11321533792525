import React from "react";
import { MiniArea } from "ant-design-pro/lib/Charts";
const Chart = ({ data }) => {
  const visitData = [];
  let result =
    data.transactions && data.transactions.length > 0
      ? data.transactions.map(it => {
          let sum = 0;
          let values = data.transactions.filter(e => {
            let result = e.date === `${it.date}` ? e.transactions_by_day : 0;
            return result;
          });
          let val =
            values.length > 0
              ? values.map(
                  ({ transactions_by_day }) => (sum += transactions_by_day)
                )
              : null;
          let item = { x: it.date, y: sum };
          visitData.push(item);
        })
      : null;

  return <MiniArea line height={45} data={visitData} />;
};

export default Chart;
