import React from "react";
import classes from "./ECS.module.less";
import { Button, Row, Col } from "antd";
import history from "../../../../history";
import ecs1 from "../../../../assets/ecs1.png";
import ecs2 from "../../../../assets/ecs2.png";
import ecs3 from "../../../../assets/ecs3.png";

let Card = ({ img, title, description }) => {
  return (
    <div className={classes.card}>
      <img src={img} alt="" />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

const ECS = ({ appId }) => {
  return (
    <div className={classes.container}>
      <p>
        Alibaba Cloud Elastic Compute Service (ECS) provides fast memory and the
        latest Intel CPUs to help you to power your cloud applications and
        achieve faster results with low latency. All ECS instances come with
        Anti-DDoS protection to safeguard your data and applications from DDoS
        and Trojan attacks.
      </p>
      <p>
        Alibaba Cloud ECS offers an industry-leading SLA, with a commitment of
        99.975% availability for individual instances and a commitment of
        99.995% availability for multiple zones within the same region.
      </p>
      <Row>
        <h2 className={classes.subtitle}>Features of 6th Gen Instances</h2>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Card
            img={ecs1}
            title="Based on the X-Dragon Chip developed by Alibaba Cloud and Lightweight Hypervisor"
            description="Alibaba Cloud Elastic Compute Service (ECS) provides fast memory and the
            latest Intel CPUs to help you to power your cloud applications and
            achieve faster results with low latency. All ECS instances come with
            Anti-DDoS protection to safeguard your data and applications from DDoS
            and Trojan attacks."
          />
        </Col>
        <Col span={8}>
          <Card
            img={ecs2}
            title="Based on 2nd Generation Intel® Xeon® Scalable Processor"
            description="Alibaba Cloud Elastic Compute Service (ECS) provides fast memory and the
            latest Intel CPUs to help you to power your cloud applications and
            achieve faster results with low latency. All ECS instances come with
            Anti-DDoS protection to safeguard your data and applications from DDoS
            and Trojan attacks."
          />
        </Col>
        <Col span={8}>
          <Card
            img={ecs3}
            title="Consistent and Predictable Performance in Enterprise-level Scenarios"
            description="Alibaba Cloud Elastic Compute Service (ECS) provides fast memory and the
            latest Intel CPUs to help you to power your cloud applications and
            achieve faster results with low latency. All ECS instances come with
            Anti-DDoS protection to safeguard your data and applications from DDoS
            and Trojan attacks."
          />
        </Col>
      </Row>
      <Row type="flex" justify="center" style={{ marginTop: 50 }}>
        <Button
          onClick={() => history.push(`/cloud/shop/ecs/${appId}`)}
          type="primary"
        >
          Select Configuration
        </Button>
      </Row>
    </div>
  );
};
export default ECS;
