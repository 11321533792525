import React from "react";
import { Empty } from "antd";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import functions from "../../../functions";
import LoadingSpin from "../LoadingSpin/LoadingSpin";

export default ({
  data = [],
  color = "event",
  position = "date*event_count",
  x = "date",
  y = "event_count",
  tooltip = [
    "event*event_count",
    (event, event_count) => {
      event_count = functions.styleAsNumber(event_count);
      return {
        name: event,
        value: event_count,
      };
    },
  ],
  loading,
}) => {
  const cols = {
    event: {
      range: [0, 1],
    },
  };
  return (
    <div>
      {loading ? (
        <LoadingSpin />
      ) : data.length > 0 ? (
        <Chart
          height={400}
          padding={{ top: 30, right: 30, bottom: 130, left: 60 }}
          forceFit
          data={data}
          scale={cols}
        >
          <Legend />
          <Axis
            name={x}
            label={{
              offset: 12,
              textStyle: { rotate: 50, textAlign: " left " },
            }}
          />
          <Axis
            name={y}
            label={{
              formatter: (val) => `${functions.styleAsNumber(val)}`,
            }}
          />
          <Tooltip
            crosshairs={{
              type: "y",
            }}
          />
          <Geom
            type="line"
            position={position}
            size={2}
            color={color}
            tooltip={tooltip}
          />
        </Chart>
      ) : (
        <Empty />
      )}
    </div>
  );
};
