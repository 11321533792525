import React, { useState, useEffect } from "react";
import { Alert } from "antd";
import classes from "./AlertRenew.module.less";
import { Link } from "react-router-dom";
import moment from "moment";
export default ({ orders }) => {
  let [display, setDisplay] = useState(false);
  let [orderToRenew, setOrder] = useState("");

  useEffect(() => {
    orders.map(({ expiration_date, PK_Order }) => {
      if (expiration_date) {
        let start = moment(expiration_date).format("YYYY-MM-DD");
        let now = moment().format("YYYY-MM-DD");
        let diff = moment.duration(start.diff(now)).asDays();
        if (diff === -14) {
          setDisplay(true);
          setOrder(PK_Order);
        }
      }
    });
  }, [orders]);
  return display ? (
    <div className={classes.alert}>
      <Alert
        message={
          <p>
            Your order will expire in 14 days{" "}
            <Link to={`/renew-order/${orderToRenew}`}>click here to renew</Link>
            .
          </p>
        }
        type="warning"
        showIcon
      />
    </div>
  ) : null;
};
