import axios from "../axios";

export const getAllEcs = async () => {
  let response = null;
  try {
    response = await axios.get("/cloud-ecs");
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getAllDatabases = async () => {
  let response = null;
  try {
    response = await axios.get("/cloud-databases");
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const newCloudOrder = async values => {
  let response = null;
  try {
    response = await axios.post("/cloud-orders", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const newAccountBalance = async values => {
  let response = null;
  try {
    response = await axios.post("/account-balance", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getAccountBalance = async id => {
  let response = null;
  try {
    response = await axios.get(`/account-balance/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getOrdersByApp = async id => {
  let response = null;
  try {
    response = await axios.get(`/cloud-orders/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const cancelOrder = async (appId, orderId) => {
  let response = null;
  try {
    response = await axios.put(`/cloud-orders`, { appId, orderId });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getOrderDetails = async id => {
  let response = null;
  try {
    response = await axios.get(`/cloud-order/${id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const renewOrder = async values => {
  console.log("renewOrder", values);
  let response = null;
  try {
    response = await axios.post("/renew-order", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const checkDomain = async domain => {
  let response = null;
  try {
    response = await axios.get(
      `/domain-search?domain=${encodeURIComponent(domain)}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
