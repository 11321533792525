import React, { useEffect, useState } from "react";
import classes from "./AccountBalanceRenew.module.less";
import Linecard from "../../../UI/LineCard/LineCard";
import history from "../../../../history";
import {
  Alert,
  Row,
  Button,
  Form,
  InputNumber,
  notification,
  Modal,
  Spin
} from "antd";
import functions from "../../../../functions";
import { getAccountBalance } from "../../../../services/cloudStoreService";
const { confirm } = Modal;
const AccountBalanceRenew = ({
  form,
  total,
  appId,
  renewOrder,
  loadingRenew
}) => {
  const { getFieldDecorator } = form;
  let [balance, setBalance] = useState(0);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getBalance();
  }, [appId]);
  useEffect(() => {
    getBalance();
  }, []);
  const getBalance = async () => {
    setLoading(true);
    let { data } = await getAccountBalance(appId);
    setLoading(false);
    if (data && data.total) {
      setBalance(data.total);
    }
  };
  const handleSubmit = event => {
    event.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        confirm({
          title: `Are you sure you want to pay USD $${functions.styleAsNumber(
            values.total
          )}  and confirm this order?`,
          onOk() {
            renewOrder(values);
            // getBalance();
          },
          onCancel() {}
        });
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft"
        });
      }
    });
  };
  const alert = (
    <Alert
      message={
        <Row type="flex" justify="space-between">
          <p style={{ margin: 0 }}>Account Balance: </p>
          <b> USD ${functions.styleAsNumber(balance)}</b>
          {balance >= total ? (
            ""
          ) : (
            <Button
              type="link"
              onClick={() => history.push(`/cloud/deposit/${appId}`)}
            >
              Your Account Balance doesn't have enough money, click here to make
              a new deposit.
            </Button>
          )}
        </Row>
      }
      type={balance >= total ? "info" : "warning"}
    />
  );
  return (
    <div className={classes.container}>
      <Linecard title="Pay with Account Balance">
        <Spin spinning={loading}>{alert}</Spin>

        <div>
          <Form
            hideRequiredMark
            onSubmit={handleSubmit}
            colon={false}
            layout="vertical"
            style={{ width: "100%" }}
          >
            <Form.Item style={{ width: "100%" }} label="Amount">
              {getFieldDecorator("total", {
                initialValue: total,
                rules: [
                  {
                    required: true,
                    message: "Required!"
                  }
                ]
              })(
                <InputNumber
                  disabled
                  style={{ width: "100%" }}
                  min={0}
                  size="large"
                  formatter={value => `USD $${functions.styleAsNumber(value)}`}
                  parser={value =>
                    parseInt(value.replace("USD $", "").replace(",", ""))
                  }
                />
              )}
            </Form.Item>
            <Form.Item>
              <Row type="flex" justify="end">
                <Button
                  loading={loadingRenew}
                  width="200px"
                  type="primary"
                  disabled={balance >= total ? false : true}
                  htmlType="submit"
                  className="login-form-button"
                >
                  Confirm and pay
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </Linecard>
    </div>
  );
};

export default Form.create()(AccountBalanceRenew);
