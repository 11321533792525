import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Row,
  Modal,
  Tag,
  notification,
  List,
  Alert
} from "antd";
import classes from "../CloudStore.module.less";
import history from "../../../history";
import {
  getOrdersByApp,
  cancelOrder
} from "../../../services/cloudStoreService";
import CredentialModal from "./CredentialsModal/CredentialsModal";
import functions from "../../../functions";
import moment from "moment";
import AlertRenew from "./AlertRenew/AlertRenew";
const { confirm } = Modal;
const Summary = ({ appId, ...props }) => {
  let [loading, setLoading] = useState(false);
  let [orders, setOrders] = useState([]);
  let [credentialModal, setCredentialModal] = useState(false);
  let [orderId, setOrderId] = useState("");
  let [details, setDetails] = useState([]);
  const PAYMENT_STATUS = {
    PAYMENT_PENDING: <Tag color="gold">Payment Pending</Tag>,
    PAYMENT_MISMATCH: <Tag color="cyan">Payment Mismatch</Tag>,
    PAYMENT_SUBMITTED: <Tag color="purple">Payment Submitted</Tag>,
    RENEW: <Tag color="blue">Renewal Payment</Tag>,
    PAID: <Tag color="green">Paid</Tag>,
    ERROR: <Tag color="red">Error</Tag>,
    CANCELLED: <Tag color="magenta">Cancelled</Tag>,
    CANCELLATION_SUBMITTED: <Tag color="volcano">Cancelation Submitted</Tag>
  };
  const columns = [
    {
      title: "Service",
      dataIndex: "title",
      key: "tile",
      width: 150,
      fixed: "left"
    },
    {
      title: "Configuration",
      dataIndex: "product_specifications",
      key: "product_specifications",
      width: 250,
      render: items => {
        let values = Object.entries(JSON.parse(items)).map(([key, value]) => ({
          key,
          value
        }));
        return values.map(it => (
          <p style={{ margin: 0 }}>
            <b>{it.key}: </b>
            {it.value}
          </p>
        ));
      }
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (_, item) =>
        item.cloud_payments[0].cloud_order_payment.payment_status ? (
          <p>
            {functions.splitAndCapitalize(
              item.cloud_payments[0].cloud_order_payment.payment_status
            )}
          </p>
        ) : (
          ""
        )
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      align: "center",
      render: (_, item) =>
        PAYMENT_STATUS[
          item.cloud_payments[0].cloud_order_payment.payment_status
        ]
    },
    {
      title: "Extra One Time Fee",
      dataIndex: "onetime_fee",
      key: "onetime_fee",
      render: price =>
        price ? <p>USD ${functions.styleAsNumber(+price)}</p> : "USD $0",
      align: "rigth"
    },
    {
      title: "Monthly Price",
      dataIndex: "monthly_price",
      key: "monthly_price",
      render: price => `USD $${functions.styleAsNumber(+price)}`,
      align: "rigth"
    },
    {
      title: "Months Paid",
      dataIndex: "months_paid",
      key: "months_paid",
      align: "center"
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (_, item) =>
        item.cloud_payments[0].cloud_order_payment.total
          ? `USD $${functions.styleAsNumber(
              +item.cloud_payments[0].cloud_order_payment.total
            )}`
          : "",
      align: "rigth"
    },
    {
      title: "Next Payment Date",
      dataIndex: "expiration_date",
      key: "expiration_date",
      align: "left",
      render: date => (date ? moment(date).format("YYYY-MM-DD") : "")
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: 300,
      render: (_, values) => {
        console.log(values, "jj");
        return (
          <Row type="flex" justify="center">
            <Button
              onClick={() => {
                setCredentialModal(true);
                setOrderId(values.PK_Order);
                setDetails(values.credentials);
              }}
              icon="setting"
              type="primary"
              ghost
            >
              Details
            </Button>
            {values.cloud_payments[0].cloud_order_payment.payment_status ===
            "PAID" ? (
              <Button
                type="link"
                style={{ marginLeft: 10 }}
                onClick={() =>
                  history.push({
                    pathname: `/renew-order/${values.PK_Order}`,
                    state: {
                      appId
                    }
                  })
                }
              >
                Renew Order
              </Button>
            ) : null}
          </Row>
        );
      }
    }
  ];
  const serverColumns = [
    {
      title: "Service",
      dataIndex: "title",
      key: "tile",
      width: 150,
      fixed: "left"
    },
    {
      title: "Instance",
      dataIndex: "instance",
      key: "instance"
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location"
    },
    {
      title: "IP Address",
      dataIndex: "ip_address",
      key: "ip_address"
    },
    {
      title: "Configuration",
      dataIndex: "product_specifications",
      key: "product_specifications",
      width: 250,
      render: items => {
        let values = Object.entries(JSON.parse(items)).map(([key, value]) => ({
          key,
          value
        }));
        return values.map(it => (
          <p style={{ margin: 0 }}>
            <b>{it.key}: </b>
            {it.value}
          </p>
        ));
      }
    },
    {
      title: "Expiration Date",
      dataIndex: "expiration_date",
      key: "expiration_date",
      align: "left",
      render: date => (date ? moment(date).format("YYYY-MM-DD") : "")
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (_, item) =>
        item.cloud_payments[0].cloud_order_payment.payment_status ? (
          <p>
            {functions.splitAndCapitalize(
              item.cloud_payments[0].cloud_order_payment.payment_status
            )}
          </p>
        ) : (
          ""
        )
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      align: "center",
      render: (_, item) =>
        PAYMENT_STATUS[
          item.cloud_payments[0].cloud_order_payment.payment_status
        ]
    },
    {
      title: "Monthly Price",
      dataIndex: "monthly_price",
      key: "monthly_price",
      render: price => `USD $${functions.styleAsNumber(+price)}`,
      align: "rigth"
    },
    {
      title: "Months Paid",
      dataIndex: "months_paid",
      key: "months_paid",
      align: "center",
      render: (_, item) =>
        item.cloud_payments[0].cloud_order_payment.months_paid
          ? item.cloud_payments[0].cloud_order_payment.months_paid
          : ""
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (_, item) =>
        item.cloud_payments[0].cloud_order_payment.total
          ? `USD $${functions.styleAsNumber(
              +item.cloud_payments[0].cloud_order_payment.total
            )}`
          : "",
      align: "rigth"
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: 250,
      render: (_, values) => {
        return (
          <Row style={{ width: 300 }} type="flex" justify="center">
            <Button
              onClick={() => {
                setCredentialModal(true);
                setOrderId(values.PK_Order);
                setDetails(values.credentials);
              }}
              icon="setting"
              type="primary"
              ghost
            >
              Login Details
            </Button>
            {values.cloud_payments[0].cloud_order_payment.payment_status ===
            "PAID" ? (
              <Button
                type="link"
                style={{ marginLeft: 10 }}
                onClick={() =>
                  history.push({
                    pathname: `/renew-order/${values.PK_Order}`,
                    state: {
                      appId
                    }
                  })
                }
              >
                Renew Order
              </Button>
            ) : null}
          </Row>
        );
      }
    }
  ];
  const getOrders = async () => {
    setLoading(true);
    let { data } = await getOrdersByApp(appId);
    if (data && data.orders) {
      console.log(data);
      setOrders(data.orders);
    }
    setLoading(false);
  };
  useEffect(() => {
    getOrders();
  }, []);
  useEffect(() => {
    getOrders();
  }, [appId]);
  const CancelRecurring = id => {
    confirm({
      title: "Are you sure you want to cancel recurring payment?",
      onOk() {
        cancelRequest(appId, id);
      },
      onCancel() {}
    });
  };

  const cancelRequest = async (appId, orderId) => {
    let { data } = await cancelOrder(appId, orderId);
    if (data && data.message === "success") {
      getOrders();
      notification.info({
        message: "Cancelation Request Received"
      });
    }
  };
  return (
    <div className={classes.container}>
      <AlertRenew orders={orders} />
      <Row type="flex" justify="space-between" align="middle">
        <h3 className={classes.title}>Elastic Compute Service (ECS)</h3>
        <Button
          onClick={() => history.push(`/cloud/shop/ecs/${appId}`)}
          type="primary"
          icon="shopping-cart"
          style={{ marginBottom: 20 }}
          ghost
        >
          Buy New
        </Button>
      </Row>
      <Table
        loading={loading}
        rowKey="id"
        size="small"
        scroll={{ x: 1600 }}
        columns={serverColumns}
        dataSource={orders.filter(e => e.product_type === "ecs")}
        pagination={
          orders.filter(e => e.product_type === "ecs").length > 10
            ? {
                pageSize: 10
              }
            : false
        }
      />
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ paddingTop: "20px" }}
      >
        <h3 className={classes.title}>Databases</h3>
        <Button
          onClick={() => history.push(`/cloud/shop/database/${appId}`)}
          type="primary"
          icon="shopping-cart"
          style={{ marginBottom: 20 }}
          ghost
        >
          Buy New
        </Button>
      </Row>
      <Table
        loading={loading}
        rowKey="id"
        size="small"
        scroll={{ x: 1300 }}
        columns={columns.filter(({ key }) => key !== "onetime_fee")}
        pagination={
          orders.filter(e => e.product_type === "database").length > 10
            ? {
                pageSize: 10
              }
            : false
        }
        dataSource={orders.filter(e => e.product_type === "database")}
      />
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ paddingTop: "20px" }}
      >
        <h3 className={classes.title}>Domain & ICP</h3>
        <Button
          onClick={() => history.push(`/cloud/shop/domains/${appId}`)}
          type="primary"
          icon="shopping-cart"
          style={{ marginBottom: 20 }}
          ghost
        >
          Buy New
        </Button>
      </Row>
      <Table
        loading={loading}
        rowKey="id"
        size="small"
        scroll={{ x: 1300 }}
        columns={columns}
        pagination={
          orders.filter(e => e.product_type === "domain").length > 10
            ? {
                pageSize: 10
              }
            : false
        }
        dataSource={orders.filter(e => e.product_type === "domain")}
      />
      <CredentialModal
        details={details}
        orderId={orderId}
        close={() => setCredentialModal(false)}
        display={credentialModal}
      />
    </div>
  );
};

export default Summary;
