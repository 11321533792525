import React, { useState, useEffect } from "react";
import classes from "../ServicesConfiguration.module.less";
import ECSDetails from "./ECSDetails/ECSDetails";
import {
  Form,
  notification,
  Button,
  Row,
  Icon,
  Collapse,
  Checkbox,
  Modal,
  Alert
} from "antd";
import LoadingSpin from "../../../UI/LoadingSpin/LoadingSpin";
import history from "../../../../history";
import { getAllEcs } from "../../../../services/cloudStoreService";
import functions from "../../../../functions";
const { Panel } = Collapse;
const { confirm } = Modal;
let uuid = require("uuid-random");
const ECSConfiguration = ({ form, appId, ...props }) => {
  const { getFieldDecorator, getFieldValue } = form;
  const customPanelStyle = {
    background: "white",
    borderRadius: 4,
    marginBottom: 8,
    border: "1px solid #e6e6e6",
    overflow: "hidden",
    textAlign: "left"
  };
  let [active, setActive] = useState("");
  let [displayAlert, setDisplayAlert] = useState(false);
  let [checked, setChecked] = useState({});
  let [selectedInfo, setSelectedInfo] = useState({});
  let [total, setTotal] = useState(0);
  let [price, setPrice] = useState(0);
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  let [edit, setEdit] = useState(null);
  const calculatePrice = (type, speed, storage, months) => {
    let info = data.filter(e => e.short_name === active)[0];
    setSelectedInfo(info);
    let price_harddrive =
      type === "HDD" ? info.hard_drive_hdd_price : info.hard_drive_ssd_price;
    let extra_speed = speed == 1 ? 0 : (speed - 1) * 25;
    let extra_storage =
      storage === 40 ? 0 : (price_harddrive / 40) * (storage - 40);
    let price = price_harddrive + extra_speed + extra_storage;
    let total = price * months;
    setPrice(+price.toFixed(2));
    setTotal(+total.toFixed(2));
  };
  const getData = async () => {
    setLoading(true);
    let { data } = await getAllEcs();
    setLoading(false);
    if (data) {
      let format = data.map(it => ({
        ...it,
        operating_systems: it.operating_systems.split(",").map(it => it.trim())
      }));
      setData(format);
      let values = {};
      data.map(
        ({ short_name }) =>
          (values = {
            ...values,
            [short_name]: false
          })
      );
      setChecked(values);
      editItem(values);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const editItem = values => {
    if (props.history.location.state && props.history.location.state.id) {
      let idItem = props.history.location.state.id;
      let localStorageCopy = localStorage.cloud_cart
        ? JSON.parse(localStorage.cloud_cart)
        : [];
      let info = localStorageCopy.orders.filter(e => e.id === idItem)[0];
      let copyChecked = { ...values };
      Object.keys(copyChecked).map(it => (copyChecked[it] = false));
      copyChecked[info.product_subtype] = true;
      setChecked(copyChecked);
      setActive(info.product_subtype);
      setTotal(info.total);
      setPrice(info.price);
      let specifications = {};
      info.description.map(
        ({ key, value }) =>
          (specifications[functions.reverseSplitAndCapitalize(key)] = value)
      );
      specifications["item_id"] = info.id;
      specifications["months_paid"] = info.months_paid;
      setEdit(specifications);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (Object.keys(values).length === 0) {
          setDisplayAlert(true);
        } else {
          setDisplayAlert(false);
          confirm({
            title: "Are you sure you want to request this service?",
            onOk() {
              let data = {
                id: edit && edit.item_id ? edit.item_id : uuid(),
                title: selectedInfo.full_name,
                product_type: "ecs",
                product_subtype: selectedInfo.short_name,
                price: price,
                onetime_fee: 0,
                monthly_price: price,
                total: total,
                months_paid: values.months_paid,
                description: [
                  {
                    key: "Operating System",
                    value: values.operating_systems
                  },
                  {
                    key: "Storage Type",
                    value: values.type_storage
                  },
                  {
                    key: "CPU/RAM",
                    value: selectedInfo.cpu_ram
                  },
                  {
                    key: "Storage GB",
                    value: values.extra_storage
                  },
                  {
                    key: "Intranet Speed",
                    value: selectedInfo.intranet_speed
                  },
                  {
                    key: "Public IPv4 Address Speed(Mpbs)",
                    value: values.speed
                  }
                ]
              };
              history.push(`/cloud/cart/${appId}`);
              saveCart(data);
            },
            onCancel() {}
          });
        }
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft"
        });
      }
    });
  };
  const saveCart = values => {
    let localStorageCopy = localStorage.cloud_cart
      ? JSON.parse(localStorage.cloud_cart)
      : [];
    let localStorageOrders =
      localStorageCopy && localStorageCopy.orders
        ? localStorageCopy.orders
        : [];
    const index = localStorageOrders.findIndex(e => e.id === values.id);
    if (index === -1) {
      localStorageOrders.push(values);
    } else {
      localStorageOrders[index] = values;
    }
    const appFilterSettings = {
      orders: [...localStorageOrders]
    };
    window.localStorage.setItem(
      "cloud_cart",
      JSON.stringify(appFilterSettings)
    );
    form.resetFields();
  };
  const handleCollapse = key => {
    form.resetFields();
    setTotal(0);
    setActive(key);
    let copyChecked = { ...checked };
    Object.keys(copyChecked).map(it => (copyChecked[it] = false));
    copyChecked[key] = true;
    setChecked(copyChecked);
  };
  return loading ? (
    <LoadingSpin />
  ) : (
    <div className={classes.container}>
      <Row type="flex" justify="start">
        <Button
          onClick={() => {
            window.history.back();
          }}
          type="primary"
          width="100px"
          margin="auto"
          ghost
        >
          <Icon type="left" />
          Go back
        </Button>
      </Row>
      {displayAlert ? (
        <Alert
          showIcon
          message="Please select a service to continue."
          type="info"
        />
      ) : null}
      <Form
        onSubmit={handleSubmit}
        colon={false}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Collapse
          bordered={false}
          activeKey={active}
          accordion
          onChange={handleCollapse}
        >
          {data.length > 0
            ? data.map(it => (
                <Panel
                  style={customPanelStyle}
                  header={it.full_name}
                  key={it.short_name}
                  extra={
                    <Checkbox
                      // onChange={() => calculatePrice()}
                      style={{ marginRight: 10 }}
                      checked={checked[it.short_name]}
                    />
                  }
                >
                  <ECSDetails
                    calculatePrice={calculatePrice}
                    getFieldDecorator={getFieldDecorator}
                    getFieldValue={getFieldValue}
                    total={total}
                    price={price}
                    itemToEdit={edit}
                    resetFields={form.resetFields}
                    {...it}
                  />
                </Panel>
              ))
            : null}
        </Collapse>
        <Form.Item>
          <Button
            width="200px"
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Add to Cart
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Form.create()(ECSConfiguration);
