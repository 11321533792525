import React, { useState, useEffect } from "react";
import classes from "../PaymentsPlatform.module.less";
import { Form, Table } from "antd";
import { getAccountDetails } from "../../../services/paymentsService";
import { transfer_history_fake } from "../FakeData";
import moment from "moment";
const AccountDetails = ({ app, form, useDemoData }) => {
  const [transferHistory, setTransferHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const tableColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      fixed: "left",
      width: 100,
      render: date => (date ? moment(date).format("YYYY-MM-DD") : ""),
      sorter: (a, b) => {
        if (a.date > b.date) return -1;
        else if (a.date < b.date) return 1;
        return 0;
      }
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details"
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => {
        if (a.amount > b.amount) return -1;
        else if (a.amount < b.amount) return 1;
        return 0;
      }
    }
  ];

  useEffect(() => {
    getData();
  }, [app.id, useDemoData]);

  const getData = async (params = {}) => {
    if (!useDemoData) {
      try {
        if (!!app) {
          setLoading(true);
          let response = await getAccountDetails(app.id);
          setLoading(false);
          if (response.statusText === "OK") {
            if (response.data.account) {
              const { transfer_histories } = response.data.account;
              setTransferHistory(transfer_histories);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className={classes.tableContainer}>
      <Table
        dataSource={useDemoData ? transfer_history_fake : transferHistory}
        columns={tableColumns}
        size="small"
        pagination={{ pageSize: 10 }}
        loading={loading}
        scroll={{ x: 550 }}
      />
    </div>
  );
};
export default Form.create()(AccountDetails);
