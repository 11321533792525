import moment from "moment";
const storesOptions = ["Tencent", "Huawei", "Xiaomi", "Oppo"];
const phones = ["Vivo X2", "Iphone X", "Huawei P30", "Samsung"];

const generateRandom = () => Math.floor(Math.random() * 200000) + 1000;
const summaryCard = {
  exposure: generateRandom(),
  activations: generateRandom(),
  new_registrations: generateRandom(),
  transactions: generateRandom(),
  total_paid: generateRandom(),
  downloads: generateRandom(),
};
const chartsFake = (events) => {
  return events.reduce((prev, event) => {
    const daysAgo = moment().subtract(30, "days");
    const fakeData = Array(30)
      .fill(null)
      .map(() => ({
        date: daysAgo.add(1, "day").format("YYYY-MM-DD"),
        event,
        event_count: Math.floor(Math.random() * 200000) + 1000,
      }));
    return [...prev, ...fakeData];
  }, []);
};

const chartPerHour = (events) => {
  return events.reduce((prev, event) => {
    const fakeData = Array(24)
      .fill(null)
      .map((_, index) => ({
        date: index + 1,
        event: `${event} `,
        event_count: Math.floor(Math.random() * 200000) + 1000,
      }));
    return [...prev, ...fakeData];
  }, []);
};

const dataPerStoreTable = storesOptions.map((store) => ({
  marketName: store,
  exposure: Math.floor(Math.random() * 200000) + 1000,
  downloads: Math.floor(Math.random() * 200000) + 1000,
  spend: Math.floor(Math.random() * 200000) + 1000,
  activations: Math.floor(Math.random() * 200000) + 1000,
  newRegistrations: Math.floor(Math.random() * 200000) + 1000,
  amountOfTransactions: Math.floor(Math.random() * 200000) + 1000,
  transactions: Math.floor(Math.random() * 200000) + 1000,
}));

const paidUsersTable = storesOptions.map((store) => ({
  marketName: store,
  paidUsers: Math.floor(Math.random() * 200000) + 1000,
  totalRevenue: Math.floor(Math.random() * 200000) + 1000,
  noOrders: Math.floor(Math.random() * 200000) + 1000,
}));

const usersSourceTable = phones.map((phone) => ({
  phone,
  newUsers: Math.floor(Math.random() * 200000) + 1000,
  proportion: (Math.random() * 100).toFixed(2),
}));

const trendPerVersionsTable = Array(30)
  .fill(null)
  .map((_, index) => ({
    date: moment()
      .subtract(index, "days")
      .format("YYYY-MM-DD"),
    activations: Math.floor(Math.random() * 200000) + 1000,
    registrations: Math.floor(Math.random() * 200000) + 1000,
    totalPaid: Math.floor(Math.random() * 200000) + 1000,
    transactions: Math.floor(Math.random() * 200000) + 1000,
  }));

const dataPerDayTable = Array(30)
  .fill(null)
  .map((_, index) => ({
    date: moment()
      .subtract(index, "days")
      .format("YYYY-MM-DD"),
    downloads: Math.floor(Math.random() * 200000) + 1000,
    newActivations: Math.floor(Math.random() * 200000) + 1000,    
    newRegistrations: Math.floor(Math.random() * 200000) + 1000,
    amountOfPayment: Math.floor(Math.random() * 200000) + 1000,
    paymentAmount: Math.floor(Math.random() * 200000) + 1000,
    activationRate: (Math.random() * 100).toFixed(2),
    registrationRate: (Math.random() * 100).toFixed(2),
  }));

const daysAgo = moment().subtract(30, "days");
const usersPerDayTable = Array(30)
  .fill(null)
  .map(() => ({
    date: daysAgo.add(1, "day").format("YYYY-MM-DD"),
    activeUsers: Math.floor(Math.random() * 200000) + 1000,
    usersComposition: Math.floor(Math.random() * 200000) + 1000,
    dau7Days: Math.floor(Math.random() * 100),
    dau30Days: Math.floor(Math.random() * 100)
  }));
const histogramData = (events, name) => {
  return events.map((event) => ({
    [name]: event,
    Total: Math.floor(Math.random() * 200000) + 1000,
  }));
};
const amontSpentFake = {
  current_day: generateRandom(),
  recent_7_days: generateRandom(),
  recent_30_days: generateRandom(),
  overall: generateRandom(),
};
const generateRandomPercent = () => Math.floor(Math.random() * (100 - 10)) + 10;
const versionsFake = ["1.1", "1.1.12"];
const usersSourceFake = {
  phone: histogramData(["Vivo X2", "Iphone X", "Huawei P30", "Samsung"], "phone"),
  country: histogramData(["China", "E.U", "England"], "country"),
  region: histogramData(["Wuhan", "Beijing", "Guangxi", "Hebei"], "province"),
  paying: histogramData(["Vivo X2", "Iphone X", "Huawei P30", "Samsung"], "phone"),
};
const usersRetentionFake = {
  oneDayProportion: generateRandomPercent(),
  sevenDaysProportion: generateRandomPercent(),
  fourteenDaysProportion: generateRandomPercent(),
  thirtyDaysProportion: generateRandomPercent(),
};
const dataComparissonPerDayFake = (start, end) => {
  return {
    exposure: chartsFake([start, end]),
    downloads: chartsFake([start, end]),
    newActivations: chartsFake([start, end]),
    newRegistrations: chartsFake([start, end]),
    newTransactions: chartsFake([start, end]),
    totalPaid: chartsFake([start, end]),
    payingUsers: chartsFake([start, end]),
  };
};
const dataComparissonPerHourFake = (start, end) => {
  console.log(start, end)
  return {
    exposure: chartPerHour([start, end]),
    downloads: chartPerHour([start, end]),
    newActivations: chartPerHour([start, end]),
    newRegistrations: chartPerHour([start, end]),
    newTransactions: chartPerHour([start, end]),
    totalPaid: chartPerHour([start, end]),
    payingUsers: chartPerHour([start, end]),
  };
};

const paidUsersTimeTable = ["Overall", "Last 30 days", "Last 7 days"].map(
  time => ({
    time,
    paidUsers: Math.floor(Math.random() * 200000) + 1000,
    paymentAmount: Math.floor(Math.random() * 200000) + 1000,
    orderVolume: Math.floor(Math.random() * 200000) + 1000
  })
);

let random = () => {
  return Math.random() * (100 - 0) + 0;
};
const usersRetentionMapFake = [
  [0, 0, 59],
  [0, 1, 46],
  [0, 2, 31],
  [0, 3, 51],
  [0, 4, 59],
  [0, 5, 51],
  [0, 6, 70],
  [1, 0, random().toFixed(2)],
  [1, 1, random().toFixed(2)],
  [1, 2, random().toFixed(2)],
  [1, 3, random().toFixed(2)],
  [1, 4, random().toFixed(2)],
  [1, 5, random().toFixed(2)],
  [1, 6, random().toFixed(2)],

  [2, 1, random().toFixed(2)],
  [2, 2, random().toFixed(2)],
  [2, 3, random().toFixed(2)],
  [2, 4, random().toFixed(2)],
  [2, 5, random().toFixed(2)],
  [2, 6, random().toFixed(2)],

  [3, 2, random().toFixed(2)],
  [3, 3, random().toFixed(2)],
  [3, 4, random().toFixed(2)],
  [3, 5, random().toFixed(2)],
  [3, 6, random().toFixed(2)],

  [4, 3, random().toFixed(2)],
  [4, 4, random().toFixed(2)],
  [4, 5, random().toFixed(2)],
  [4, 6, random().toFixed(2)],

  [5, 4, random().toFixed(2)],
  [5, 5, random().toFixed(2)],
  [5, 6, random().toFixed(2)],

  [6, 5, random().toFixed(2)],
  [6, 6, random().toFixed(2)],

  [7, 6, random().toFixed(2)],

  [8, 6, random().toFixed(2)],

  [9, 6, random().toFixed(2)],
];
export {
  summaryCard,
  chartsFake,
  dataPerStoreTable,
  usersPerDayTable,
  histogramData,
  generateRandomPercent,
  generateRandom,
  storesOptions,
  amontSpentFake,
  usersSourceFake,
  usersRetentionFake,
  versionsFake,
  dataComparissonPerDayFake,
  usersRetentionMapFake,
  dataComparissonPerHourFake,
  paidUsersTable,
  trendPerVersionsTable,
  usersSourceTable,
  dataPerDayTable,
  paidUsersTimeTable
};
