import React, { useState, useEffect, version } from "react";
import classes from "./Deposit.module.less";
import { Alert, Row, Form, notification, InputNumber, Spin } from "antd";
import functions from "../../../../functions";
import {
  newAccountBalance,
  getAccountBalance
} from "../../../../services/cloudStoreService";
import PayPalButton from "./PayPalButtonDeposit/PayPalButton";
export default Form.create()(({ form, appId }) => {
  const { getFieldDecorator } = form;
  let [balance, setBalance] = useState(0);
  let [loading, setLoading] = useState(false);
  let [balanceToAdd, setBalanceToAdd] = useState(0);
  let [loadingNew, setLoadingNew] = useState(false);
  let [disablePaypal, setDisablePaypal] = useState(false);

  const getBalance = async () => {
    setLoading(true);
    let { data } = await getAccountBalance(appId);
    setLoading(false);
    if (data && data.total) {
      setBalance(data.total);
    }
  };
  const addBalance = async (amount, idTransaction, details) => {
    setLoadingNew(true);
    let { data } = await newAccountBalance({
      amount: amount,
      FK_App: appId,
      transaction_id: idTransaction,
      paypal_callback_string: details
    });
    setLoadingNew(false);
    if (data && data.message === "success") {
      notification.info({ message: "Your payment has been saved!" });
    }
    getBalance();
  };
  useEffect(() => {
    getBalance();
    if (appId === "1234-8475-9439-9495" || appId === "1234-8475-9439-9494") {
      setDisablePaypal(true);
    } else {
      setDisablePaypal(false);
    }
  }, [appId]);
  useEffect(() => {
    getBalance();
  }, []);
  const alert = (
    <Alert
      message={
        <Row type="flex" justify="center">
          <p style={{ margin: 0 }}>
            Account Balance:
            <b> USD ${functions.styleAsNumber(balance)}</b>
          </p>
        </Row>
      }
      type="info"
      showIcon
    />
  );
  return (
    <div className={classes.container}>
      <Spin spinning={loading}>{alert}</Spin>
      <Form
        hideRequiredMark
        colon={false}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Form.Item style={{ width: "100%" }} label="Amount:">
          {getFieldDecorator("amount", {
            initialValue: 0,
            rules: [
              {
                required: true,
                message: "Required!"
              }
            ]
          })(
            <InputNumber
              onChange={val => setBalanceToAdd(val)}
              disabled={loadingNew}
              style={{ width: 250 }}
              min={0}
              formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={value => value.replace(/\$\s?|(,*)/g, "")}
            />
          )}
        </Form.Item>
      </Form>
      {balanceToAdd > 0 && !disablePaypal ? (
        <PayPalButton
          addBalance={addBalance}
          amount={balanceToAdd}
          appId={appId}
        />
      ) : null}
    </div>
  );
});
