import React, { useState, useEffect } from "react";
import { Typography, List } from "antd";
import classes from "./CredentialsModal.module.less";
import { Modal } from "antd";
const { Paragraph } = Typography;
export default ({ display, close, orderId, details }) => {
  return (
    <Modal
      width={500}
      title="Credentials"
      visible={display}
      onCancel={close}
      footer={null}
    >
      <Paragraph copyable>{details}</Paragraph>
      {/*  <List
        bordered
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <div>
              <h3 className={classes.title}>{item.key}</h3>
              <p>
                {item.value}
                {/* <Paragraph copyable>{item.value}</Paragraph> */}
      {/* </p>
            </div>
          </List.Item> */}
    </Modal>
  );
};
