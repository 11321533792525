import React, { useState, useEffect } from "react";
import classes from "./TrendPerStore.module.less";
import { getDate } from "../../../../functions";
import MultiLineChart from "../../../UI/Charts/MultiLineChart";
import { Tabs, Card, DatePicker, Row, Col, Select, Button } from "antd";
import {
  chartsFake,
  storesOptions,
  versionsFake,
} from "../../PromotionFakeData";
import { getTrendPerStore } from "../../../../services/promotionService";
import moment from "moment";
const { TabPane } = Tabs;
const { Option } = Select;

export default ({ app, useDemoData }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [date, setDate] = useState(getDate());
  const [versionSelected, setVersion] = useState(null);
  const [versions, setVersions] = useState([]);
  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getTrendPerStore({
      promotionId: app.analitycs_id,
      app: app.id,
      version: versionSelected,
      start: date[0].format("YYYY-MM-DD"),
      end: date[1].format("YYYY-MM-DD"),
    });
    setVersions(response.versions);
    setData(response.data);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setVersions([...versionsFake, "All Versions"]);
        setData({
          newActivations: chartsFake(storesOptions),
          newRegistrations: chartsFake(storesOptions),
          amountPayment: chartsFake(storesOptions),
        });
        setVersions([
          {
            value: null,
            label: "All Versions",
          },
        ]);
        setLoading(false);
      }, 500);
    }
  };
  useEffect(() => {
    validateRequest();
  }, [app]);
  useEffect(() => {
    validateRequest();
  }, [date, versionSelected]);

  const dateRange = (days) => {
    const start = moment().subtract(days, "days");
    const end = moment();
    setDate([start, end]);
  };

  return (
    <div className={classes.container}>
      <Card title="Trend Chart of Store Data" style={{ marginTop: 15 }}>
        <Row gutter={16}>
          <Col xs={{ span: 12 }} lg={{ span: 10 }} style={{ marginBottom: 15 }}>
            <DatePicker.RangePicker
              disabled={loading}
              value={date}
              onChange={(moment) => {
                setDate(moment);
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={{ span: 6 }} lg={{ span: 4 }}>
            <Button
              style={{ width: "100%" }}
              onClick={() => dateRange(7)}
              type="primary"
            >
              7 days
            </Button>
          </Col>
          <Col xs={{ span: 6 }} lg={{ span: 4 }}>
            <Button
              style={{ width: "100%" }}
              onClick={() => dateRange(30)}
              type="primary"
            >
              30 days
            </Button>
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 5 }}>
            <Select
              loading={loading}
              disabled={loading}
              value={versionSelected}
              onChange={(value) => setVersion(value)}
              style={{ width: "100%" }}
              placeholder="Version"
            >
              {versions.map((it) => (
                <Option value={it.value}>{it.label}</Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Tabs
          type="card"
          defaultActiveKey="0"
          style={{ width: "100%", marginTop: 15 }}
        >
          <TabPane disabled={loading} tab="Active Users" key="0">
            <MultiLineChart loading={loading} data={data.activeUsers} />
          </TabPane>
          <TabPane disabled={loading} tab="New Activations" key="1">
            <MultiLineChart loading={loading} data={data.newActivations} />
          </TabPane>
          <TabPane disabled={loading} tab="New Registrations" key="2">
            <MultiLineChart loading={loading} data={data.newRegistrations} />
          </TabPane>
          <TabPane disabled={loading} tab="Transactions" key="3">
            <MultiLineChart loading={loading} data={data.transactions} />
          </TabPane>
          <TabPane disabled={loading} tab="Paying Users" key="4">
            <MultiLineChart loading={loading} data={data.payingUsers} />
          </TabPane>
          <TabPane disabled={loading} tab="Total Paid" key="5">
            <MultiLineChart loading={loading} data={data.totalPaid} />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};
