import React, { useEffect, useState } from "react";
import { Table, Card, Button, Select } from "antd";
import functions, { getDate } from "../../../functions";
import { getDataPerVersion } from "../../../services/promotionService";
import { versionsFake, trendPerVersionsTable } from "../PromotionFakeData";

import moment from "moment";
const { Option } = Select;
export default ({ useDemoData, app }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(getDate());
  const [versionSelected, setVersion] = useState("null");
  const [versions, setVersions] = useState([]);

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getDataPerVersion({
      promotionId: app.analitycs_id,
      app: app.id,
      start: moment(date[0]).format("YYYY-MM-DD"),
      end: moment(date[1]).format("YYYY-MM-DD"),
      version: versionSelected,
    });
    setData(response.data.tableData);
    setVersions(response.versions);
    setLoading(false);
  };

  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setVersions([
          ...versionsFake.map((item) => ({
            value: item,
            label: item,
          })),
          { value: "null", label: "All Versions" },
        ]);
        setData([...trendPerVersionsTable]);
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    validateRequest();
  }, [app]);

  useEffect(() => {
    validateRequest();
  }, [date, versionSelected]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Recurring Users",
      dataIndex: "recurringUsers",
      key: "recurringUsers",
      align: "right",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "New Activations",
      dataIndex: "activations",
      key: "activations",
      align: "right",
      render: (val) => functions.styleAsNumber(val),
    },    
    {
      title: "New Registrations",
      dataIndex: "newRegistrations",
      key: "newRegistrations",
      align: "right",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "Total Paid",
      dataIndex: "totalPaid",
      key: "totalPaid",
      align: "right",
      render: (val) => functions.styleAsNumber(val),
    },
    {
      title: "Transactions",
      dataIndex: "transactions",
      key: "transactions",
      align: "right",
      render: (val) => functions.styleAsNumber(val),
    },
  ];

  const exportToCSV = () => {
    const headers = [
      "Date",
      "Recurring Users",
      "New Activations",
      "Total Paid",
      "Transactions",
    ].join(",");
    const rows = data.map((it) => [...Object.values(it)].join(",")).join("\n");
    let csvContent = "data:text/csv;charset=utf-8," + `${headers}\n` + rows;
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Card
      loading={loading}
      title={
        <>
          Data Per Version
          {versions ? (
            <Select
              value={versionSelected}
              onChange={setVersion}
              style={{ marginLeft: 10, width: 130 }}
            >
              {versions.map(({ value, label }) => (
                <Option value={value}>{label}</Option>
              ))}
            </Select>
          ) : null}
        </>
      }
      extra={
        <Button onClick={exportToCSV} type="primary" icon="file">
          Export as csv
        </Button>
      }
      style={{ marginTop: 15 }}
    >
      <Table
        loading={loading}
        dataSource={data || []}
        columns={columns}
        size="small"
        scroll={{ x: 500 }}
        style={{ marginTop: 15 }}
      />
    </Card>
  );
};
