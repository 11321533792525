import axios from "../axios";

export const getAccountDetails = async app_id => {
  let response = null;
  try {
    response = await axios.get(`/account-details/${app_id}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const updateAccountDetails = async values => {
  let response = null;
  try {
    response = await axios.put("/account-details", {
      ...values
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getUserDetails = async (value, paymentsId) => {
  let response = null;
  try {
    response = await axios.get(`/user-details/${value}/${paymentsId}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getUser = async (value, paymentsId) => {
  let response = null;
  try {
    response = await axios.get(`/users/${value}/${paymentsId}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getUsers = async values => {
  let response = null;
  try {
    response = await axios.post("/users", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getTransactionById = async (value, paymentsId) => {
  let response = null;
  try {
    response = await axios.get(`/transactions/${value}/${paymentsId}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getTransactions = async values => {
  let response = null;
  try {
    response = await axios.post("/transactions", { ...values });
  } catch (e) {
    console.log(e);
  }
  return response;
};

export const getStatementsReports = async app_id => {
  let response = null;
  try {
    response = await axios.get(`/statements-reports/${app_id}`);
  } catch (e) {
    console.log(e);
  }
  return response;
};
export const newMonetizationRequest = async (
  title,
  client_email,
  appId,
  appName
) => {
  let response = null;
  try {
    response = await axios.post("/monetization-request", {
      title,
      client_email,
      appId,
      appName
    });
  } catch (e) {
    console.log(e);
  }
  return response;
};
