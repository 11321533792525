import React, { useState, useEffect } from "react";
import classes from "./Translation.module.less";
import {
  Radio,
  Form,
  Input,
  notification,
  Button,
  Select,
  Table,
  Modal,
  Tag,
} from "antd";
import {
  getTranslations,
  newTranslation,
} from "../../../services/localizationService";
import moment from "moment";
import File from "../../UI/File/File";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Option = Select.Option;

const columns = [
  {
    title: "Translation Quote",
    dataIndex: "quote",
    key: "quote",
    width: 130,
    fixed: "left",
  },
  {
    title: "Original Language",
    dataIndex: "original",
    key: "original",
  },
  {
    title: "Words",
    dataIndex: "words",
    key: "words",
  },
  {
    title: "Translated Language",
    dataIndex: "translated",
    key: "translated",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
];

export default Form.create()(({ form, app, ...props }) => {
  const [mode, changeMode] = useState("file");
  const [translation, setTranslationRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentTranslation, setCurrentTranslation] = useState("");
  const [translationModalVisible, setTranslationModalVisible] = useState(false);
  const [loadingTranslations, setLoadingTranslations] = useState(false);
  const [translationRequest, setTranslationRequest] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const { getFieldDecorator } = form;

  const getData = async (app_id) => {
    try {
      setLoadingTranslations(true);
      const { data } = await getTranslations(app_id);
      if (!data.error) {
        setTranslationRequest(data);
        setLoadingTranslations(false);
      } else {
        throw new Error(data.error);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getData(props.appId);
  }, [props.appId]);
  const translationColumns = [
    {
      title: "Date Submitted",
      dataIndex: "date_added",
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (a.date_added > b.date_added) return -1;
        else if (a.date_added < b.date_added) return 1;
        return 0;
      },
      fixed: "left",
      width: 180,
      render: (date_added) => moment(date_added).format("YYYY-MM-DD"),
    },
    {
      title: "Original Language",
      dataIndex: "original_language",
    },
    {
      title: "Text to Translate",
      dataIndex: "file_url",
      align: "center",
      render: (translation_file, element) =>
        translation_file ? (
          <a
            rel="noopener noreferrer"
            href={`https://appinchina.space/download/${translation_file}`}
            target="_blank"
          >
            <Button icon="cloud-download">Download</Button>
          </a>
        ) : (
          <Button icon="eye" onClick={() => showTranslationContent(element)}>
            View Text
          </Button>
        ),
    },
    {
      title: "Report",
      dataIndex: "report",
      align: "center",
      render: (report) =>
        report ? (
          <a
            rel="noopener noreferrer"
            href={`https://appinchina.space/download/${report}`}
            target="_blank"
          >
            <Button icon="cloud-download">Download</Button>
          </a>
        ) : (
          <Tag color="blue">Pending</Tag>
        ),
    },
  ];

  const showTranslationContent = (element) => {
    setCurrentTranslation(element);
    setTranslationModalVisible(true);
  };
  const translationModal = () => (
    <Modal
      title="Translation Text Submitted"
      visible={translationModalVisible}
      onOk={() => setTranslationModalVisible(false)}
      onCancel={() => setTranslationModalVisible(false)}
    >
      {<p>"{!!currentTranslation && currentTranslation.text}"</p>}
    </Modal>
  );

  const parseXML = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      calculateQuote(text);
    };
    reader.readAsText(file);
  };

  const calculateQuote = (text) => {
    const div = document.createElement("div");
    div.innerHTML = text;
    let count = (div.textContent || div.innerText || "")
      .replace(/\n/g, "")
      .split(" ")
      .filter((it) => it !== "").length;
    setTranslationRow({
      quote: `$ ${count * 0.1}`,
      words: count,
      original: form.getFieldValue("language"),
      translated: "Chinese Simplified",
      price: "0.1USD",
    });
  };

  const handleTextArea = (text) => {
    if (text.includes(" ")) {
      const wordCount = text.trim().split(" ").length;
      setTranslationRow({
        quote: `$ ${(wordCount * 0.1).toFixed(2)}`,
        words: wordCount,
        original: form.getFieldValue("language"),
        translated: "Chinese Simplified",
        price: "0.1USD",
      });
    } else if (text.length == 0) {
      setTranslationRow({
        quote: "",
        words: "",
        original: form.getFieldValue("language"),
        translated: "Chinese Simplified",
        price: "0.1USD",
      });
    }
  };

  const deleteFile = () => {
    setCurrentFile(null);
    form.setFieldsValue({ ["file"]: null });
    setTranslationRow(null);
  };
  const submitTranslation = (fieldToUpdate, event, file) => {
    console.log("EVENT 3", event.target.files, file);
    if (file) {
      form.setFieldsValue({ ["file"]: fieldToUpdate.value });
      if (fieldToUpdate.value.includes(".xml")) {
        const [xml] = event.target.files;
        parseXML(xml);
      } else {
        setTranslationRow({
          quote: "Pending Under Review",
          words: "Pending Under Review",
          original: form.getFieldValue("language"),
          translated: "Chinese Simplified",
          price: "0.1USD",
        });
      }
      setCurrentFile(fieldToUpdate.value);
    }
  };

  const submitRequest = (event) => {
    event.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        let fileName = null;
        if (mode == "file") {
          fileName = currentFile;
        }
        if (!loading) {
          try {
            const { data } = await newTranslation({
              FK_App: props.appId,
              file_url: fileName,
              text: values.text || null,
              original_language: form.getFieldValue("language"),
            });
            if (data.error) {
              throw new Error(data.error);
            }
            getData(props.appId);
            setTranslationRow(null);
            form.resetFields();
            setLoading(false);
            notification["success"]({
              message:
                "Your request has been sent to an Operations manager. Someone will contact you soon",
              placement: "bottomLeft",
            });
            if (window.mixpanel) {
              window.mixpanel.track("Localization requested");
            }
          } catch (error) {
            notification["error"]({
              message: error.message,
              placement: "bottomLeft",
            });
          }
        }
      }
    });
  };
  console.log("currentfile", currentFile);
  return (
    <div className={classes.Container}>
      <p>
        We recommend that your app is translated into Simplified Chinese before
        publication in the Chinese market. If you haven’t yet translated your
        app then click below to upload the language file and we’ll provide you
        with an instant quotation.
      </p>
      <RadioGroup defaultValue={mode}>
        <RadioButton
          value="file"
          onChange={(event) => changeMode(event.target.value)}
        >
          Upload File
        </RadioButton>
        <RadioButton
          value="write"
          onChange={(event) => changeMode(event.target.value)}
        >
          Write Area
        </RadioButton>
      </RadioGroup>
      <Form hideRequiredMark={true} onSubmit={submitRequest}>
        <Form.Item label="Language">
          {getFieldDecorator("language", {
            rules: [{ required: true, message: "Required" }],
            initialValue: "English",
            onChange: (value) =>
              setTranslationRow({
                ...translation,
                original: value,
              }),
          })(
            <Select>
              <Option value="English">English</Option>
            </Select>
          )}
        </Form.Item>
        {mode === "file" ? (
          <Form.Item>
            {getFieldDecorator("file", {
              rules: [{ required: true, message: "Required" }],
            })(
              <File
                name="file"
                type="File"
                title="Translation File*"
                validation={{ fileRequired: true }}
                onDelete={() => deleteFile()}
                submitFile={submitTranslation}
                source={currentFile}
              />
            )}
          </Form.Item>
        ) : (
          <Form.Item>
            {getFieldDecorator("text", {
              rules: [{ required: true, message: "Required" }],
              onChange: (event) => handleTextArea(event.target.value),
            })(<Input.TextArea rows={10} />)}
          </Form.Item>
        )}
        {!translation ? null : (
          <Table
            columns={columns}
            dataSource={[
              {
                key: "1",
                ...translation,
              },
            ]}
            pagination={false}
            size="small"
            scroll={{ x: 800 }}
          />
        )}
        <Form.Item>
          <div style={{ padding: "20px" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Send Translation Request
            </Button>
          </div>
        </Form.Item>
      </Form>
      {translationModal()}
      <Table
        loading={loadingTranslations}
        dataSource={translationRequest}
        columns={translationColumns}
        size="small"
        pagination={{ pageSize: 10 }}
        scroll={{ x: 450 }}
      />
    </div>
  );
});
