import React, { useState } from "react";
import { Button, Card, Table, Popover, Icon, Modal, notification } from "antd";
import classes from "./InAppPurchaseItems.module.less";
import {
  getInAppPurchases,
  deleteInAppPurchase,
} from "../../../../services/inAppPurchaseItemService";
import PurchaseItemModal from "../PurchaseItemModal/PurchaseItemModal";
const { confirm } = Modal;

export default ({ app }) => {
  let [item, setItem] = useState({});
  let [items, setItems] = useState([]);
  let [loadingItems, setLoadingItems] = useState(false);
  let [itemModalVisible, setItemModalVisible] = useState(false);

  let editItem = async ({ itemName, amount, PK_Purchase_Item }) => {
    setItem({ itemName, amount, itemId: PK_Purchase_Item });
    setItemModalVisible(true);
  };

  let confirmDeletion = async ({ PK_Purchase_Item }) => {
    setLoadingItems(true);
    let { data } = await deleteInAppPurchase(PK_Purchase_Item);
    if (data && data.message === "success") {
      notification.success({
        message: "Succesfully deleted item",
        placement: "bottomLeft",
      });
      let dataCopy = items.filter(
        (it) => it.PK_Purchase_Item !== PK_Purchase_Item
      );
      setItems(dataCopy);
    } else {
      notification.error({
        message: "An error occurred",
        placement: "bottomLeft",
      });
    }
    setLoadingItems(false);
  };

  let deleteItem = async (id) => {
    confirm({
      title: "Are you sure you want to delete this item?",
      onOk() {
        confirmDeletion(id);
      },
      onCancel() {},
    });
  };

  let addItem = (item) => {
    setItem({});
    setItemModalVisible(true);
  };

  let getItems = async () => {
    setLoadingItems(true);
    let { data } = await getInAppPurchases(app.id);
    if (data && data.items && data.items.length) {
      setItems(data.items);
    }
    setLoadingItems(false);
  };

  let handleItemAdded = (values) => {
    let dataCopy = [...items, values];
    setItems(dataCopy);
    setItemModalVisible(false);
  };

  let handleItemEdited = (values) => {
    let dataCopy = items.map((it) =>
      it.PK_Purchase_Item === values.id ? { ...it, ...values } : it
    );
    setItems(dataCopy);
    setItemModalVisible(false);
  };

  useState(() => {
    getItems();
  }, [app.id]);

  let columns = [
    {
      title: "Item Name (goodsTitle)",
      dataIndex: "itemName",
    },
    {
      title: "Amount (in cents)",
      dataIndex: "amount",
    },
    {
      title: "Options",
      render: (_, it) => (
        <div className={classes.actions}>
          <Button
            icon="edit"
            type="primary"
            onClick={() => editItem(it)}
          ></Button>
          {"  "}
          <Button
            icon="delete"
            type="danger"
            onClick={() => deleteItem(it)}
          ></Button>
        </div>
      ),
    },
  ];
  return (
    <div className={classes.container}>
      <PurchaseItemModal
        visible={itemModalVisible}
        id={app.id}
        {...item}
        onAdd={handleItemAdded}
        onEdit={handleItemEdited}
        onClose={() => setItemModalVisible(false)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "20px",
        }}
      >
        <Button
          icon="plus"
          onClick={() => app.analitycs_id && addItem()}
          disabled={!app.analitycs_id}
        >
          Add Item
        </Button>
      </div>
      <Table
        loading={loadingItems}
        className={classes.shortcuts}
        bordered
        pagination={{ pageSize: 5 }}
        columns={columns}
        dataSource={items}
        rowKey="PK_Purchase_Item"
      />
    </div>
  );
};
