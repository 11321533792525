import axios from "../axios";

export const getInAppPurchases = async (appId) => {
  let response = null;
  try {
    response = await axios.get(`/payments/items/${appId}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const addInAppPurchase = async (itemId, values) => {
  let response = null;
  try {
    response = await axios.post(`/payments/items/${itemId}`, {
      ...values,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const editInAppPurchase = async (itemId, values) => {
  let response = null;
  try {
    response = await axios.put(`/payments/items/${itemId}`, {
      ...values,
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const deleteInAppPurchase = async (itemId) => {
  let response = null;
  try {
    response = await axios.delete(`/payments/items/${itemId}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
