const ordersFake = [
  {
    payment_method: "account_balance",
    payment_status: "PAID",
    product_type: "ecs",
    product_subtype: "c62xlarge",
    price: 1210,
    monthly_price: 1210,
    total: 14520,
    onetime_fee: 0,
    instance: "2hf74nf8n9sdn9snwj",
    title: "ECS 2X Large (C6)",
    months_paid: 12,
    location: "Beijing China",
    ip_address: "36.1.1.1",
    credentials: "User: demoUser Password: 129423200",
    next_payment_date: "2020-06-24T21:08:46.000Z",
    PK_Order: "1234-8475-9439-9494-1231",
    product_specifications: `{"Operating System":"Ubuntu","Storage Type":"HDD","CPU/RAM":"8Core/16GB","Storage GB":40,"Intranet Speed":2.5,"Public IPv4 Address Speed(Mpbs)":18}`,
    date: "2020-02-24T23:02:38.000Z"
  },
  {
    payment_method: "paypal",
    payment_status: "PAID",
    product_type: "domain",
    product_subtype: "hello.com",
    price: 100,
    onetime_fee: 999,
    monthly_price: 100,
    total: 1999,
    credentials: "User: demoUser Password: 129423200",
    next_payment_date: "2020-06-24T21:08:46.000Z",
    title: "Domain: hello.com",
    months_paid: 12,
    PK_Order: "1234-8475-9439-9494-1232",
    product_specifications: `{"Domain":"hello.com","Available":"Available","Type":"Premium"}`,
    date: "2020-02-24T23:02:38.000Z"
  },
  {
    payment_method: "bank_transfer",
    payment_status: "PAYMENT_PENDING",
    product_type: "database",
    product_subtype: "mysql",
    price: 290,
    credentials: "User: demoUser Password: 129423200",
    monthly_price: 290,
    next_payment_date: "2020-06-24T21:08:46.000Z",
    total: 1740,
    title: "Database",
    onetime_fee: 0,
    months_paid: 6,
    PK_Order: "1234-8475-9439-9494-1233",
    product_specifications: `{"Type":"mysql","Specifications":"2Core CPU / 8GB Ram","Storage GB":100,"Extra Storage GB":0}`,
    date: "2020-02-24T23:02:38.000Z"
  }
];
const accounts = [
  {
    verified: null,
    transaction_id: "0L463285X1139830Y",
    amount: 0.01,
    status: null,
    notes: null,
    paypal_callback_string: `{"create_time":"2020-02-24T21:08:09Z","update_time":"2020-02-24T21:08:44Z","id":"0L463285X1139830Y","intent":"CAPTURE","status":"COMPLETED","payer":{"email_address":"iedc-buyer@outlook.com","payer_id":"S4GGDKHU74QYY","address":{"country_code":"MX"},"name":{"given_name":"test","surname":"buyer"}},"purchase_units":[{"reference_id":"default","soft_descriptor":"PAYPAL *TESTFACILIT","amount":{"value":"0.01","currency_code":"USD"},"payee":{"email_address":"iedc-facilitator@outlook.com","merchant_id":"FXG43VYCNWPJW"},"shipping":{"name":{"full_name":"test buyer"},"address":{"address_line_1":"Calle Juarez 1","admin_area_2":"Miguel Hidalgo","admin_area_1":"Ciudad de Mexico","postal_code":"11580","country_code":"MX"}},"payments":{"captures":[{"status":"COMPLETED","id":"32H34766C0087135V","final_capture":true,"create_time":"2020-02-24T21:08:44Z","update_time":"2020-02-24T21:08:44Z","amount":{"value":"0.01","currency_code":"USD"},"seller_protection":{"status":"ELIGIBLE","dispute_categories":["ITEM_NOT_RECEIVED","UNAUTHORIZED_TRANSACTION"]},"links":[{"href":"https://api.sandbox.paypal.com/v2/payments/captures/32H34766C0087135V","rel":"self","method":"GET","title":"GET"},{"href":"https://api.sandbox.paypal.com/v2/payments/captures/32H34766C0087135V/refund","rel":"refund","method":"POST","title":"POST"},{"href":"https://api.sandbox.paypal.com/v2/checkout/orders/0L463285X1139830Y","rel":"up","method":"GET","title":"GET"}]}]}}],"links":[{"href":"https://api.sandbox.paypal.com/v2/checkout/orders/0L463285X1139830Y","rel":"self","method":"GET","title":"GET"}]}`,
    createdAt: "2020-02-24T21:08:46.000Z",
    updatedAt: "2020-02-24T21:08:46.000Z"
  },
  {
    verified: 0,
    transaction_id: "71P59014V3323840Y",
    amount: 10000,
    status: null,
    notes: null,
    paypal_callback_string: `{"create_time":"2020-02-24T21:47:16Z","update_time":"2020-02-24T21:47:51Z","id":"71P59014V3323840Y","intent":"CAPTURE","status":"COMPLETED","payer":{"email_address":"iedc-buyer@outlook.com","payer_id":"S4GGDKHU74QYY","address":{"country_code":"MX"},"name":{"given_name":"test","surname":"buyer"}},"purchase_units":[{"reference_id":"default","soft_descriptor":"PAYPAL *TESTFACILIT","amount":{"value":"0.01","currency_code":"USD"},"payee":{"email_address":"iedc-facilitator@outlook.com","merchant_id":"FXG43VYCNWPJW"},"shipping":{"name":{"full_name":"test buyer"},"address":{"address_line_1":"Calle Juarez 1","admin_area_2":"Miguel Hidalgo","admin_area_1":"Ciudad de Mexico","postal_code":"11580","country_code":"MX"}},"payments":{"captures":[{"status":"COMPLETED","id":"7YB17757KY190060L","final_capture":true,"create_time":"2020-02-24T21:47:51Z","update_time":"2020-02-24T21:47:51Z","amount":{"value":"0.01","currency_code":"USD"},"seller_protection":{"status":"ELIGIBLE","dispute_categories":["ITEM_NOT_RECEIVED","UNAUTHORIZED_TRANSACTION"]},"links":[{"href":"https://api.sandbox.paypal.com/v2/payments/captures/7YB17757KY190060L","rel":"self","method":"GET","title":"GET"},{"href":"https://api.sandbox.paypal.com/v2/payments/captures/7YB17757KY190060L/refund","rel":"refund","method":"POST","title":"POST"},{"href":"https://api.sandbox.paypal.com/v2/checkout/orders/71P59014V3323840Y","rel":"up","method":"GET","title":"GET"}]}]}}],"links":[{"href":"https://api.sandbox.paypal.com/v2/checkout/orders/71P59014V3323840Y","rel":"self","method":"GET","title":"GET"}]}`,
    createdAt: "2020-02-24T21:47:53.000Z",
    updatedAt: "2020-02-24T21:47:53.000Z"
  }
];

let accountFake = {
  account: accounts,
  total: 10000
};

export { accountFake, ordersFake };
