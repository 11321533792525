import React, { useState, useEffect } from "react";
import classes from "./Summary.module.less";
import { Card, Select, Statistic } from "antd";
import { summaryCard } from "../../../PromotionFakeData";
import { getOverralSummary } from "../../../../../services/promotionService";
const { Option } = Select;
const gridStyle = {
  width: "25%",
  textAlign: "center",
};

export default ({ app, useDemoData }) => {
  let [loading, setLoading] = useState(false);
  let [days, setDays] = useState("overall");
  let [data, setData] = useState({});

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getOverralSummary({
      promotionId: app.analitycs_id,
      app: app.id,
      days,
    });
    setData(response);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData({ ...summaryCard });
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    validateRequest();
  }, [app]);

  useEffect(() => {
    validateRequest();
  }, [days]);

  return (
    <div className={classes.container}>
      <Card
        loading={loading}
        title={
          <>Summary {days == "overall" ? " Overall" : ` (${days} Days)`}</>
        }
        extra={
          <Select
            loading={loading}
            style={{ width: "200px" }}
            onChange={(val) => setDays(val)}
            placeholder="Time"
            defaultValue={days}
            disabled={loading}
          >
            <Option value="overall">Overall</Option>
            <Option value={7}>Recent 7 Days</Option>
            <Option value={30}>Recent 30 Days</Option>
          </Select>
        }
      >
        <Card.Grid style={gridStyle}>
          <Statistic
            title="Exposure"
            value={data.exposure ? data.exposure : "No Data"}
          />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="Downloads"
            value={data.downloads ? data.downloads : "No Data"}
          />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="Amount Spent"
            value={data.account_spent ? data.account_spent : "No Data"}
          />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="New Activations"
            value={data.new_activations ? data.new_activations : "No Data"}
          />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="New Registrations"
            value={data.new_registrations ? data.new_registrations : "No Data"}
          />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="Paying Users"
            value={data.paying_users ? data.paying_users : "No Data"}
          />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="Transactions"
            value={data.transactions ? data.transactions : "No Data"}
          />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="Total Paid (RMB)"
            value={data.total_paid ? data.total_paid : "No Data"}
          />
        </Card.Grid>
      </Card>
    </div>
  );
};
