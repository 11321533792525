import React from "react";
import { Chart, Geom, Axis, Tooltip, Coord, Label, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import { network } from "../../fakeData";
export default class Innerlabel extends React.Component {
  render() {
    const { DataView } = DataSet;
    let data = this.props.useDemoData ? network : this.props.data;

    const dv = new DataView();
    dv.source(data).transform({
      type: "percent",
      field: "network_count",
      dimension: "network_type",
      as: "percent"
    });
    const cols = {
      percent: {
        formatter: val => {
          val = (val * 100).toFixed(2) + "%";
          return val;
        }
      }
    };
    return (
      <div>
        <Chart data={dv} scale={cols} height={400} width={300} forceFit>
          <Coord type="theta" radius={0.75} />
          <Axis name="percent" />
          <Legend position="left" offsetY={10} offsetX={20} />
          <Tooltip
            showTitle={false}
            itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
          />
          <Geom
            type="intervalStack"
            position="percent"
            color="network_type"
            tooltip={[
              "network_tipe*percent",
              (item, percent) => {
                percent = (percent * 100).toFixed(2) + "%";
                return {
                  name: item,
                  value: percent
                };
              }
            ]}
            style={{
              lineWidth: 1,
              stroke: "#fff"
            }}
          >
            <Label
              content="percent"
              textStyle={{
                rotate: 0,
                textAlign: "center",
                shadowBlur: 2,
                shadowColor: "rgba(0, 0, 0, .45)"
              }}
            />
          </Geom>
        </Chart>
      </div>
    );
  }
}
