import history from "./history";
import auth0 from "auth0-js";
import EventBus from "eventbusjs";
import { AUTH_CONFIG } from "./auth0-variables";
import { notification } from "antd";
import jwtDecode from "jwt-decode";

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: "token id_token",
    scope: "openid",
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };

  crossOriginVerification() {
    this.auth0.crossOriginVerification();
  }

  login(email, password) {
    return new Promise((resolve) => {
      this.auth0.client.login(
        {
          realm: "Username-Password-Authentication",
          username: email,
          password,
        },
        (err, result) => {
          resolve({ err, result });
        }
      );
    });
  }

  handleAuthentication(authResult, err) {
    console.log("handleAuthentication", authResult, err);
    if (authResult && authResult.accessToken && authResult.idToken) {
      this.setSession(authResult, true);
      setTimeout(() => {
        EventBus.dispatch("GET_DATA", this);
      }, 1000);
    } else if (err) {
      history.replace("/login");
      this.openNotificationWithIcon("warning", err.description);
    }
  }

  async setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(86400 * 1000 + new Date().getTime());
    let idTokenPayload = await jwtDecode(authResult.idToken);
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem(
      "imageLocation",
      idTokenPayload["https://www.appinchina.co/picture"]
    );
    localStorage.setItem(
      "email",
      idTokenPayload["https://www.appinchina.co/email"]
    );
    history.replace("/");
  }

  logout() {
    let i = localStorage.length;
    while (i--) {
      let key = localStorage.key(i);
      if (!/_newFeature/.test(key)) {
        localStorage.removeItem(key);
      }
    }
    history.replace("/login");
  }

  isAuthenticated() {
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return window.localStorage.id_token && new Date().getTime() < expiresAt;
  }

  refreshToken() {
    let path = history.location.pathname;
    if (
      !this.isAuthenticated() &&
      !path.includes("login") &&
      !path.includes("register")
    ) {
      return new Promise((resolve) => {
        this.auth0.checkSession({}, (err, result) => {
          if (!err) {
            this.setSession(result);
          } else {
            console.log("ERROR while refreshing token", err);
          }
          resolve();
        });
      });
    }
  }
}
