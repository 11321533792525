import React from "react";
import { Empty } from "antd";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import { user_visits } from "../../fakeData";
export default ({ data, useDemoData }) => {
  let chartData = useDemoData
    ? user_visits
    : data.reduce((prev, curr) => {
        return prev.concat([
          {
            date: curr.date,
            visits: curr.return_user_visits,
            type: "Return"
          },
          {
            date: curr.date,
            visits: curr.new_user_visits,
            type: "New"
          }
        ]);
      }, []);

  const scale = {
    date: {
      type: "time",
      tickCount: 10,
      nice: false
    },
    visits: {
      min: 0
    },
    type: {
      type: "cat"
    }
  };

  return (
    <div>
      {chartData.length > 0 ? (
        <Chart height={500} data={chartData} scale={scale} forceFit>
          <Tooltip />
          <Axis />
          <Legend itemFormatter={val => `${val} User Visits`} />
          <Geom
            type="line"
            position="date*visits"
            color={["type", ["#ff7f0e", "#2ca02c"]]}
            shape="smooth"
            size={2}
          />
          <Geom
            type="point"
            position="date*visits"
            color={["type", ["#ff7f0e", "#2ca02c"]]}
            shape="smooth"
            size={2}
          />
          <Geom
            type="area"
            position="date*visits"
            color={["type", ["#ff7f0e", "#2ca02c"]]}
            shape="smooth"
            opacity={0.2}
            size={2}
          />
        </Chart>
      ) : (
        <Empty />
      )}
    </div>
  );
};
