import React from "react";
import { Table } from "antd";
import classes from "./UsersTable.module.less";

export default ({ data, loading }) => {
  const columns = [
    {
      key: "date",
      dataIndex: "date",
      title: "Date",
      fixed: "left",
      width: 120,
      render: (date) => <div className={classes.cells}>{date} </div>,
    },
    {
      key: "new_users",
      dataIndex: "new_users",
      title: "New Users",
      align: "right",
      render: (users) => <div className={classes.cells}>{users}</div>,
    },
    {
      key: "1_day",
      dataIndex: "1_day",
      title: "1 day",
      render: (percentage) => blueComponent(percentage),
    },
    {
      key: "2_day",
      dataIndex: "2_day",
      title: "2 days",
      render: (percentage) => blueComponent(percentage),
    },
    {
      key: "3_day",
      dataIndex: "3_day",
      title: "3 days",
      render: (percentage) => blueComponent(percentage),
    },
    {
      key: "4_day",
      dataIndex: "4_day",
      title: "4 days",
      render: (percentage) => blueComponent(percentage),
    },
    {
      key: "5_day",
      dataIndex: "5_day",
      title: "5 days",
      render: (percentage) => blueComponent(percentage),
    },
    {
      key: "6_day",
      dataIndex: "6_day",
      title: "6 days",
      render: (percentage) => blueComponent(percentage),
    },
    {
      key: "7_day",
      dataIndex: "7_day",
      title: "7 days",
      render: (percentage) => blueComponent(percentage),
    },
    {
      key: "14_day",
      dataIndex: "14_day",
      title: "14 days",
      render: (percentage) => blueComponent(percentage),
    },
    {
      key: "30_day",
      dataIndex: "30_day",
      title: "30 days",
      render: (percentage) => blueComponent(percentage),
    },
  ];

  let blueComponent = (percentage) => {
    let opacity = `0.${Math.floor(percentage / 10).toFixed(0)}5`;
    return percentage ? (
      <div style={{ position: "relative", padding: 12 }}>
        <span
          style={{
            background: "#90b6ff",
            opacity: opacity,
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
          }}
        ></span>
        <span
          style={{
            position: "relative",
          }}
        >
          {percentage ? `${percentage}%` : ""}
        </span>
      </div>
    ) : null;
  };

  return (
    <div className={classes.container}>
      <Table
        scroll={{ x: 900 }}
        loading={loading}
        size="small"
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};
