import axios from "../axios";

export const getApkFiles = async app_id => {
  let response = null;
  try {
    response = await axios.post("/localization/apk-files", {
      app_id: app_id
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getCompanyIncorporation = async app_id => {
  let response = null;
  try {
    response = await axios.post("/get-company-incorporation", {
      appId: app_id
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getHosting = async app_id => {
  let response = null;
  try {
    response = await axios.post("/get-icp", {
      appId: app_id
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getIntellectualProperty = async app_id => {
  let response = null;
  try {
    response = await axios.post("/get-intellectual-property", {
      appId: app_id
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getTranslations = async app_id => {
  let response = null;
  try {
    response = await axios.post("/get-translations", { app_id });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const newApkFile = async (app_id, values) => {
  let response = null;
  try {
    response = await axios.post("/apk-file", {
      app_id: app_id,
      values: values
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const newCompanyIncorporation = async (values, app_id) => {
  let response = null;
  try {
    response = await axios.post("/company-incorporation", {
      ...values,
      FK_App: app_id
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const newSdk = async (apk_id, values) => {
  let response = null;
  try {
    response = await axios.post("/sdk", {
      apk_id: apk_id,
      values: values
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const newHosting = async (values, app_id) => {
  let response = null;
  try {
    response = await axios.post("/icp-web-hosting", {
      ...values,
      FK_App: app_id
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const newIntellectualProperty = async values => {
  let response = null;
  try {
    response = await axios.post("/intellectual-property", {
      ...values
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const newTranslation = async values => {
  let response = null;
  try {
    response = await axios.post("/translation", {
      ...values
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getMightySignalInfo = async package_name => {
  let response = null;
  try {
    response = await axios.post("/scan-sdks", {
      package_name
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
