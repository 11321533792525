import React, { useEffect, useState } from "react";
import moment from "moment";
import Axios from "axios";
import classes from "./AuthorizationLetter.module.less";
import { Card, Button, Upload, Icon, notification, Table, Row } from "antd";
import {
  newAuthorizationLetter,
  getAuthorizationLetter,
} from "../../../services/distributionService";
import JiayouFormat from "./AuthorizationLetterFormats/App_Authorization_Statement-Jiayou.docx";
import ZhenchuangFormat from "./AuthorizationLetterFormats/App_Authorization_Statement-zhenchuang.docx";
import PreviewButton from "../../UI/PreviewButton/PreviewButton";

export default ({ appId, app }) => {
  const [loading, setLoading] = useState(false);
  const [uploadingImg, setUploadingImg] = useState(false);
  const [saving, setSaving] = useState(false);
  const [signedFiles, setSignedFiles] = useState([]);

  let columns = [
    {
      key: "date",
      dataIndex: "date",
      title: "Submission Date",
      width: 150,
      fixed: true,
      render: (date) =>
        date ? <p>{moment(date).format("YYYY-MM-DD HH:mm")}</p> : "",
    },
    {
      key: "file_name",
      dataIndex: "file_name",
      title: "File Name",
    },
    {
      key: "id",
      dataIndex: "id",
      title: "Actions",
      width: 150,
      render: (id, item) => {
        return (
          <Row type="flex" justify="space-between">
            <a
              rel="noopener noreferrer"
              href={`https://appinchina.space/download/${item.file_url}`}
              target="_blank"
              className={classes.downloadButton}
            >
              <Button shape="circle" icon="cloud-download" />
            </a>
            <div className={classes.previewButton}>
              <PreviewButton fileName={item.file_url} />
            </div>
          </Row>
        );
      },
    },
  ];
  const type_authorization =
    (app && app.main_information.app_store_accounts) || "AIC";
  const uploadImage = async ({ file, onSuccess, onProgress, onError }) => {
    const timeStamp = new Date().getTime();
    const formData = new FormData();
    const result = `${timeStamp}/${file.name}`;
    formData.set("uploads[]", file);
    formData.set("name", file.name);
    formData.set("timestamp", timeStamp);
    setUploadingImg(true);
    let { data } = await Axios.post(
      "https://appinchina.space/upload",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress({
            percent: percentCompleted,
          });
        },
      }
    );
    setUploadingImg(false);
    if (data) {
      onSuccess(result);
      saveFile({
        file_url: result,
        file_name: file.name,
        file_size: file.size,
      });
    } else {
      onError("error");
    }
  };
  const getAuthorization = async () => {
    setLoading(true);
    let { data } = await getAuthorizationLetter(appId);
    if (data && data.docs) {
      setSignedFiles(data.docs);
    }
    setLoading(false);
  };
  const saveFile = async (data) => {
    setSaving(true);
    let app_documents_attachments_clients = [
      {
        ...data,
      },
    ];
    let values = {
      type: "AUTHORIZATION",
      app_documents_attachments_clients,
      FK_App: appId,
    };
    let { data: response } = await newAuthorizationLetter(values);
    if (response && response.result) {
      getAuthorization();
      notification.info({ message: "Authorization Letter received." });
    }
    setSaving(false);
  };
  useEffect(() => {
    getAuthorization();
  }, []);

  return (
    <div className={classes.Container}>
      <Card
        actions={[
          <div className={classes.actionsButtons}>
            <a
              rel="noopener noreferrer"
              href={
                type_authorization === "AIC" ? JiayouFormat : ZhenchuangFormat
              }
              target="_blank"
              className={classes.downloadButton}
            >
              <Button icon="cloud-download">Download Template File</Button>
            </a>
            <Upload
              onRemove={(file) => setUploadingImg(false)}
              disabled={saving}
              customRequest={uploadImage}
              fileList={[]}
            >
              <Button type="primary" loading={uploadingImg}>
                <Icon type={uploadingImg ? "loading" : "upload"} />
                {uploadingImg ? "Uploading Signed File" : " Upload Signed File"}
              </Button>
            </Upload>
          </div>,
        ]}
      >
        <p>
          As your app is going to be distributed by AppInChina, the Chinese
          Android app stores will need an official statement which shall
          demonstrate the relationship between you and AppInChina.
        </p>
        <p>
          Please download the Application Authorization Statement and read
          carefully. The Application Authorization Statement will give
          AppInChina the authority to distribute your app. If you agree to
          authorize AppInChina to distribute the app on your behalf, you need to
          fill out the Statement, sign and stamp it.
        </p>
        <p>
          Once you complete the Statement, please scan it back to us with a PDF
          format.
        </p>
      </Card>
      <Table
        className={classes.table}
        columns={columns}
        dataSource={signedFiles}
        size="small"
        bordered
        scroll={{ x: true }}
        loading={loading}
        rowKey={({ id }) => id}
        title={() => "Submitted Files"}
        pagination={signedFiles.length > 10 ? { pageSize: 10 } : false}
      />
    </div>
  );
};
