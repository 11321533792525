import React, { useState, useEffect } from "react";
import classes from "./TrendPerVersion.module.less";
import MultiLineChart from "../../../UI/Charts/MultiLineChart";
import { Tabs, Card, DatePicker, Row, Col, Select } from "antd";
import { getDate } from "../../../../functions";
import {
  chartsFake,
  versionsFake,
  storesOptions,
  trendPerVersionsTable,
} from "../../PromotionFakeData";
import moment from "moment";
import { getTrendVersion } from "../../../../services/promotionService";
const { TabPane } = Tabs;
const { Option } = Select;

export default ({ app, useDemoData }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [date, setDate] = useState(getDate());
  const [storeSelected, setStore] = useState(null);
  const [stores, setStores] = useState([]);
  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getTrendVersion({
      promotionId: app.analitycs_id,
      app: app.id,
      start: moment(date[0]).format("YYYY-MM-DD"),
      end: moment(date[1]).format("YYYY-MM-DD"),
      store: storeSelected,
    });
    setData(response.result.data);
    setStores(response.result.stores);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setStores([...storesOptions, "All Stores"]);
        setData({
          newActivations: chartsFake(versionsFake),
          newRegistrations: chartsFake(versionsFake),
          newTransactions: chartsFake(versionsFake),
          totalPaid: chartsFake(versionsFake),
          tableData: trendPerVersionsTable,
        });
        setStores([
          {
            value: null,
            label: "All Stores",
          },
        ]);        
        setLoading(false);
      }, 500);
    }
  };
  useEffect(() => {
    validateRequest();
  }, [app]);
  useEffect(() => {
    validateRequest();
  }, [date, storeSelected]);
  return (
    <div className={classes.container}>
      <Card title="Trend Per Version" style={{ marginTop: 15 }}>
        <Row gutter={16}>
          <Col xs={{ span: 12 }} lg={{ span: 10 }} style={{ marginBottom: 15 }}>
            <DatePicker.RangePicker
              disabled={loading}
              value={date}
              onChange={(moment, [start, end]) => {
                setDate(moment);
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={{ span: 12 }} lg={{ span: 4 }}>
            <Select
              disabled={loading}
              loading={loading}
              value={storeSelected}
              onChange={(val) => setStore(val)}
              style={{ width: "100%" }}
              placeholder="App Store"
            >
              {stores.map((it) => (
                <Option value={it.value}>{it.label}</Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Tabs
          type="card"
          defaultActiveKey="1"
          style={{ width: "100%", marginTop: 15 }}
        >
          <TabPane disabled={loading} tab="Recurring Users" key="1">
            <MultiLineChart loading={loading} data={data.recurringUsers} />
          </TabPane>
          <TabPane disabled={loading} tab="New Activations" key="2">
            <MultiLineChart loading={loading} data={data.newActivations} />
          </TabPane>
          <TabPane disabled={loading} tab="New Registrations" key="3">
            <MultiLineChart loading={loading} data={data.newRegistrations} />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};
