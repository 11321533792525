import React, { useState, useEffect } from "react";
import {
  Row,
  Button,
  Card,
  Avatar,
  Alert,
  Spin,
  Modal,
  notification,
  Tag
} from "antd";
import UserCard from "../UserCard/UserCard";
import classes from "../Settings.module.less";
import defaultImage from "../../../assets/default-app.svg";
import ShareAccessDialog from "../../Dialogs/ShareAccessWithRol/ShareAccessWithRol";
import {
  removeAccess,
  updateRole,
  getAppsRoles
} from "../../../services/rolesAndPermissionsService";
const { confirm } = Modal;

const RolesAndPermissions = ({ ...props }) => {
  const [roles, setUsersRoles] = useState(null);
  const [displayAccess, setDisplayAccess] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [ownApps, setOwnApps] = useState(null);
  const handleAddUsers = () => {
    if (roles.length > 0) {
      setDisplayAccess(true);
    } else {
      setDisplayAlert(true);
    }
  };

  useEffect(() => {
    fetch();
    let own_apps = props.apps.filter(e => e.role && e.role === "OWNER");
    setOwnApps(own_apps);
  }, []);

  const fetch = async () => {
    try {
      const { data } = await getAppsRoles();
      setUsersRoles(data.apps);
    } catch (error) {
      console.log(error);
    }
  };

  const showConfirmUpdateRol = (val, item, appname, appId) => {
    confirm({
      title: `Are you sure you want to set the role ${val} for the app ${appname}?`,
      async onOk() {
        await updateRole({
          app: appId,
          user: item.id,
          role: val
        });
        let result = roles.reduce((prev, curr) => {
          if (curr.name === appname) {
            const users = curr.users.filter(
              ({ email }) => email !== item.email
            );
            return [
              ...prev,
              {
                ...curr,
                users: [
                  ...users,
                  {
                    ...item,
                    role: val
                  }
                ]
              }
            ];
          } else {
            return [
              ...prev,
              {
                ...curr
              }
            ];
          }
        }, []);
        setUsersRoles(result);
      }
    });
  };

  const showConfirmDeleteAccess = (val, appname, data) => {
    confirm({
      title: `Are you sure you want to remove access to ${val} ?`,
      async onOk() {
        await removeAccess(data);
        let result = roles.reduce((prev, curr) => {
          if (curr.name === appname) {
            const users = curr.users.filter(({ email }) => email !== val);
            return [
              ...prev,
              {
                ...curr,
                users
              }
            ];
          } else {
            return [
              ...prev,
              {
                ...curr
              }
            ];
          }
        }, []);
        setUsersRoles(result);
        notification.info({
          message: "Access removed"
        });
      }
    });
  };
  return roles ? (
    <div className={classes.container}>
      <div className={classes.roles}>
        <h2>ROLES:</h2>
        <p>
          <Tag color="blue">Owner</Tag> Provides full access to the account.
          Owners can invite new users, edit information and submit updates.
        </p>
        <p>
          <Tag color="cyan">Admin</Tag> Provides full access to the account.
          Admins can edit information and submit updates but they can not invite
          new users.
        </p>
        <p>
          <Tag color="green">Viewer</Tag> The user can view all data in the
          account but can not make any changes.
        </p>
        <p>
          <Tag color="purple">Payments Manager</Tag> The user can interact
          exclusively with the AppInChina Pay data.
        </p>
      </div>
      <Row type="flex" justify="end">
        <Button
          disabled={ownApps.length <= 0 ? true : false}
          onClick={() => handleAddUsers()}
          icon="usergroup-add"
        >
          Invite User
        </Button>
      </Row>
      {displayAlert ? (
        <Alert
          style={{ marginTop: 20, textAlign: "left" }}
          message="Please add at least one app to share access."
          type="info"
          showIcon
        />
      ) : null}
      {roles.map(({ id, name, image_data, users }) => {
        let imageData = image_data ? image_data : defaultImage;
        imageData = /uploads|static|googleusercontent/.test(imageData)
          ? imageData
          : `data:image/png;base64,${imageData}`;
        return (
          <Card
            title={
              <Row type="flex" justify="space-between">
                {name} <Avatar shape="square" src={imageData.replace("appinchina.xyz", "appinchina.space")} />
              </Row>
            }
            style={{ marginTop: 30, textAlign: "left" }}
          >
            <UserCard
              isOwner={ownApps}
              appname={name}
              appId={id}
              users={users}
              showConfirmUpdateRol={showConfirmUpdateRol}
              showConfirmDeleteAccess={showConfirmDeleteAccess}
            />
          </Card>
        );
      })}
      <ShareAccessDialog
        display={displayAccess}
        apps={ownApps}
        close={() => setDisplayAccess(false)}
        updateInfo={setUsersRoles}
        allInfo={roles}
      />
    </div>
  ) : (
    <Spin />
  );
};

export default RolesAndPermissions;
