import React, { useState } from "react";
import classes from "./TrademarkSearch.module.less";
import {
  Table,
  Input,
  Alert,
  notification,
  Icon,
  Card,
  Tooltip,
  Tag,
} from "antd";
import { searchTrademarks } from "../../../services/trademarkService";
import { TRADEMARK_CLASSES, TRADEMARK_STATUSES } from "../../../constants";
const { Search } = Input;

const TrademarkSearch = () => {
  let [totalResults, setTotalResults] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [currentSearch, setCurrentSearch] = useState(1);
  let [loading, setLoading] = useState(false);
  let [results, setResults] = useState(null);

  let getData = async (pageSelected, search) => {
    setLoading(true);
    setCurrentPage(pageSelected);
    let { data, total } = await searchTrademarks(
      pageSelected,
      search || currentSearch
    );
    setResults(data);
    setTotalResults(total);
    setLoading(false);
  };

  let search = (search) => {
    if (search.length > 1) {
      setCurrentPage(0);
      setCurrentSearch(search);
      getData(1, search);
    } else {
      notification.info({
        message: "Please type something to run the search",
        placement: "bottomLeft",
      });
    }
  };

  let columns = [
    {
      title: "Image",
      dataIndex: "tmImg",
      width: "120px",
      fixed: "left",
      render: (img) => (
        <img
          height={50}
          className={classes.img}
          src={`http://tmpic.tmkoo.com/${img}-m`}
          alt=""
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "tmName",
    },
    {
      title: "Application Date",
      dataIndex: "appDate",
    },
    {
      title: "Registration Date",
      dataIndex: "regDate",
    },
    {
      title: "Status",
      dataIndex: "currentStatus",
      render: (currentStatus) => {
        let { color, translation } = TRADEMARK_STATUSES[currentStatus] || {};
        return (
          <Tag color={color || "cyan"}>
            {translation || currentStatus || "N/A"}
          </Tag>
        );
      },
    },
    {
      title: "Number",
      dataIndex: "regNo",
    },
    {
      title: "Agent",
      dataIndex: "agent",
    },
    {
      title: "Class",
      dataIndex: "intCls",
      render: (val) => {
        let classValues = TRADEMARK_CLASSES.find((e) => e.number === +val);
        return (
          <Tooltip
            placement="rightTop"
            title={(classValues && classValues.definition) || ""}
          >
            <p>
              {val} <Icon type="info-circle" />
            </p>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div className={classes.container}>
      <Card className={classes.Introduction}>
        <p>
          It's essential to apply for a Chinese trademark on both the name and
          logo of your software, in English and Chinese, before publishing it in
          China.
        </p>
        <p>
          Search for your brand name in the field below to confirm its current
          trademark status.
        </p>
      </Card>
      <Alert
        showIcon
        className={classes.Alert}
        type="info"
        message={
          <div>
            <p>
              The key laws and regulations relating to trademarks in China are
              the
              <a href="https://www.appinchina.co/government-documents/trademark-law-of-the-peoples-republic-of-china-revised-in-2019/">
                {" "}
                Trademark Law of the People’s Republic of China (Revised in
                2019){" "}
              </a>
              and the
              <a href="https://www.appinchina.co/government-documents/implementation-regulations-for-the-trademark-law-of-the-peoples-republic-of-china/">
                {" "}
                Implementation Regulations for the Trademark Law of the People’s
                Republic of China (Amended in 2014)
              </a>
              .
            </p>
          </div>
        }
      />
      <Search
        placeholder="Search..."
        onSearch={search}
        enterButton
        className={classes.search}
      />
      <>
        {results && !loading ? (
          <Alert
            className={classes.results}
            type={totalResults ? "success" : "warning"}
            showIcon
            message={
              <p>
                Found {totalResults} results that match <b>{currentSearch}</b>
              </p>
            }
          />
        ) : null}
        <Table
          size="small"
          columns={columns}
          scroll={{ x: "700px" }}
          loading={loading}
          rowKey="nameUrl"
          pagination={{
            onChange: (page) => {
              setCurrentPage(page);
              getData(page);
            },
            pageSize: 10,
            currentPage,
            total: totalResults,
          }}
          dataSource={results}
        />
      </>
    </div>
  );
};

export default TrademarkSearch;
