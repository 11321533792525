import React from "react";
import { MiniBar } from "ant-design-pro/lib/Charts";
const Chart = ({ data }) => {
  const visitData = [];
  let result =
    data.users && data.users.length > 0
      ? data.users.map(it =>
          visitData.push({
            x: it.date,
            y: it.count
          })
        )
      : null;

  return <MiniBar height={45} data={visitData} />;
};

export default Chart;
