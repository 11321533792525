import axios from "../axios";
export const getAllNotifications = async (appId, title) => {
  let response = null;
  try {
    response = await axios.get("/push-notifications/history", {
      params: {
        appId,
        title,
      },
    });
  } catch (e) {
    console.log(e);
  } finally {
    return response;
  }
};

export const createNewPushNotification = async (
  analyticsId,
  appId,
  content
) => {
  let response = null;
  try {
    response = await axios.post("/push-notification", {
      analyticsId,
      appId,
      content,
    });
  } catch (e) {
    console.log(e);
  } finally {
    return response;
  }
};

export const updateNewPushNotification = async (pushId, appId, content) => {
  let response = null;
  try {
    response = await axios.put(`/push-notification/${pushId}`, {
      appId,
      content,
    });
  } catch (e) {
    console.log(e);
  } finally {
    return response;
  }
};

export const getPushDetails = async (analyticsId, appId, pushId) => {
  let response = null;
  try {
    response = await axios.get(`/push-notification/${pushId}`, {
      params: { analyticsId, appId },
    });
  } catch (e) {
    console.log(e);
  } finally {
    return response;
  }
};

export const deletePushNotification = async (pushId) => {
  let response = null;
  try {
    response = await axios.delete(`/push-notification/${pushId}`);
  } catch (e) {
    console.log(e);
  } finally {
    return response;
  }
};

export const getAllTags = async (appId, title) => {
  let response = null;
  try {
    response = await axios.get("/push-notifications/tags", {
      params: {
        appId,
        title,
      },
    });
  } catch (e) {
    console.log(e);
  } finally {
    return response;
  }
};

export const updateTag = async (appId, tagId, title) => {
  let response = null;
  try {
    response = await axios.put(`/push-notifications/tag/${tagId}`, {
      title,
      appId,
    });
  } catch (e) {
    console.log(e);
  } finally {
    return response;
  }
};

export const createTag = async (appId, title) => {
  let response = null;
  try {
    response = await axios.post("/push-notifications/tag", {
      appId,
      title,
    });
  } catch (e) {
    console.log(e);
  } finally {
    return response;
  }
};

export const deleteTag = async (appId, tagId) => {
  let response = null;
  try {
    response = await axios.delete(`/push-notifications/tag/${tagId}`, {
      params: { appId },
    });
  } catch (e) {
    console.log(e);
  } finally {
    return response;
  }
};
