import React from "react";
import { Chart, Geom, Axis, Tooltip, View, Legend } from "bizcharts";

export default class Lineofdashed extends React.Component {
  render() {
    const data = this.props.data;
    function pick(data, field) {
      return data.map(function(item) {
        var result = {};
        for (var key in item) {
          if (item.hasOwnProperty(key) && field.indexOf(key) !== -1) {
            result[key] = item[key];
          }
        }
        return result;
      });
    }

    var scale = {
      date: {
        alias: "Date",
        mask: "YYYY-MM-DD"
      },
      revenue_by_day: {
        alias: "Revenue",
        formatter: function(value) {
          return `¥ ${value.toFixed(2)}`;
        },
        range: [0, 1]
      },
      transactions_by_day: {
        alias: "Transactions",
        range: [0, 1]
      }
    };

    class SliderChart extends React.Component {
      render() {
        return (
          <div style={{ width: "100%" }}>
            <Chart
              height={400}
              padding={[20, 80, 80, 80]}
              forceFit
              scale={{
                date: {
                  sync: true
                }
              }}
            >
              <Tooltip />
              <View data={pick(data, ["date", "revenue_by_day"])} scale={scale}>
                <Geom
                  type="line"
                  position="date*revenue_by_day"
                  color="#e2861e"
                  size={2}
                />
                <Geom
                  type="point"
                  position="date*revenue_by_day"
                  size={4}
                  shape={"circle"}
                  color="#f14747"
                  style={{
                    stroke: "#fff",
                    lineWidth: 1
                  }}
                />
                <Legend />
              </View>
              <View
                data={pick(data, ["date", "transactions_by_day"])}
                scale={scale}
              >
                <Axis name="kk" visible={true} />
                <Geom
                  type="line"
                  position="date*transactions_by_day"
                  color="orange"
                  size={3}
                />
                <Geom
                  type="point"
                  position="date*transactions_by_day"
                  size={4}
                  shape={"circle"}
                  color="orange"
                  style={{
                    stroke: "#fff",
                    lineWidth: 1
                  }}
                />
                <Legend />
              </View>
            </Chart>
          </div>
        );
      }
    }
    return <SliderChart />;
  }
}
