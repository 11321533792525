import React from "react";
import LineCard from "../../../../../../UI/LineCard/LineCard";
import File from "../../../../../../UI/File/File";
import classes from "./HorizontalScreenshots.module.less";
import { Row, Col, Form } from "antd";
const { Item } = Form;
export default ({
  getFieldDecorator,
  data = {},
  deleteFile,
  submitFileHandler,
  disabled,
}) => {
  return (
    <div>
      <LineCard
        title="1920x1080 PIxel Screenshots. 2MB or less."
        style={{ marginTop: 15 }}
        shadow
      >
        <Row className={classes.description} style={{ marginBottom: 15 }}>
          <b>
            All screenshots must be in JPG or PNG format; taken on an Android
            device but not showing any recognizable phone brand; contents are in
            Chinese and match the APK that was submitted.
          </b>
        </Row>
        <Row gutter={[16, 16]} type="flex" justify="space-around">
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot1_1`, {
                // rules: [{ required: true, message: "Required" }],
                initialValue: data.horizontal_screenshot1_1,
              })(
                <File
                  name="horizontal_screenshot1_1"
                  type="Image"
                  title="Horizontal Screenshot 1 *"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "1920", height: "1080" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot1_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1920}
                  previewHeight={1080}
                />
              )}
            </Item>
          </Col>
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot2_1`, {
                // rules: [{ required: true, message: "Required" }],
                initialValue: data.horizontal_screenshot2_1,
              })(
                <File
                  name="horizontal_screenshot2_1"
                  type="Image"
                  title="Horizontal Screenshot 2 *"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "1920", height: "1080" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot2_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1920}
                  previewHeight={1080}
                />
              )}
            </Item>
          </Col>
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot3_1`, {
                // rules: [{ required: true, message: "Required" }],
                initialValue: data.horizontal_screenshot3_1,
              })(
                <File
                  name="horizontal_screenshot3_1"
                  title="Horizontal Screenshot 3 *"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "1920", height: "1080" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  type="Image"
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot3_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1920}
                  previewHeight={1080}
                />
              )}
            </Item>
          </Col>
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot4_1`, {
                // rules: [{ required: true, message: "Required" }],
                initialValue: data.horizontal_screenshot4_1,
              })(
                <File
                  name="horizontal_screenshot4_1"
                  title="Horizontal Screenshot 4 *"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "1920", height: "1080" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  type="Image"
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot4_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1920}
                  previewHeight={1080}
                />
              )}
            </Item>
          </Col>
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot5_1`, {
                // rules: [{ required: true, message: "Required" }],
                initialValue: data.horizontal_screenshot5_1,
              })(
                <File
                  name="horizontal_screenshot5_1"
                  title="Horizontal Screenshot 5 *"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "1920", height: "1080" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  type="Image"
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot5_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1920}
                  previewHeight={1080}
                />
              )}
            </Item>
          </Col>
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot6_1`, {
                initialValue: data.horizontal_screenshot6_1,
              })(
                <File
                  name="horizontal_screenshot6_1"
                  title="Horizontal Screenshot 6"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "1920", height: "1080" },
                    size: 2097152,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  type="Image"
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot6_1")}
                  submitFile={submitFileHandler}
                  previewWidth={1920}
                  previewHeight={1080}
                />
              )}
            </Item>
          </Col>
        </Row>
      </LineCard>
      <LineCard title="800x480 Pixel Screenshots." shadow>
        <Row gutter={[16, 16]} type="flex" justify="space-around">
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot1`, {
                // rules: [{ required: true, message: "Required" }],
                initialValue: data.horizontal_screenshot1,
              })(
                <File
                  name="horizontal_screenshot1"
                  type="Image"
                  title="Horizontal Screenshot 1 *"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "800", height: "480" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot1")}
                  submitFile={submitFileHandler}
                  previewWidth={800}
                  previewHeight={480}
                />
              )}
            </Item>
          </Col>
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot2`, {
                // rules: [{ required: true, message: "Required" }],
                initialValue: data.horizontal_screenshot2,
              })(
                <File
                  type="Image"
                  name="horizontal_screenshot2"
                  title="Horizontal Screenshot 2 *"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "800", height: "480" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot2")}
                  submitFile={submitFileHandler}
                  previewWidth={800}
                  previewHeight={480}
                />
              )}
            </Item>
          </Col>
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot3`, {
                // rules: [{ required: true, message: "Required" }],
                initialValue: data.horizontal_screenshot3,
              })(
                <File
                  type="Image"
                  name="horizontal_screenshot3"
                  title="Horizontal Screenshot 3 *"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "800", height: "480" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot3")}
                  submitFile={submitFileHandler}
                  previewWidth={800}
                  previewHeight={480}
                />
              )}
            </Item>
          </Col>
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot4`, {
                // rules: [{ required: true, message: "Required" }],
                initialValue: data.horizontal_screenshot4,
              })(
                <File
                  type="Image"
                  name="horizontal_screenshot4"
                  title="Horizontal Screenshot 4 *"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "800", height: "480" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot4")}
                  submitFile={submitFileHandler}
                  previewWidth={800}
                  previewHeight={480}
                />
              )}
            </Item>
          </Col>
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot5`, {
                // rules: [{ required: true, message: "Required" }],
                initialValue: data.horizontal_screenshot5,
              })(
                <File
                  type="Image"
                  name="horizontal_screenshot5"
                  title="Horizontal Screenshot 5 *"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "800", height: "480" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot5")}
                  submitFile={submitFileHandler}
                  previewWidth={800}
                  previewHeight={480}
                />
              )}
            </Item>
          </Col>
          <Col xs={12} sm={16} md={16} lg={8} xl={8}>
            <Item>
              {getFieldDecorator(`horizontal_screenshot6`, {
                initialValue: data.horizontal_screenshot6,
              })(
                <File
                  name="horizontal_screenshot6"
                  type="Image"
                  title="Horizontal Screenshot 6"
                  validation={{
                    // fileRequired: true,
                    resolution: { width: "800", height: "480" },
                    size: 1048576,
                  }}
                  format={["png", "jpg", "jpeg"]}
                  disabled={disabled}
                  onDelete={() => deleteFile("horizontal_screenshot6")}
                  submitFile={submitFileHandler}
                  previewWidth={800}
                  previewHeight={480}
                />
              )}
            </Item>
          </Col>
        </Row>
      </LineCard>
    </div>
  );
};
