import React, { useState, useEffect } from "react";
import classes from "../../PaymentsPlatform.module.less";
import { Button, Table, DatePicker, Input, Icon, Tag, Row, Col, Select, Typography } from "antd";
import RefundDialog from "../../RefundsHistory/RefundDialog/RefundDialog";
import moment from "moment";
import { getUserDetails, getUnregisteredUserDetails } from "../../../../services/aicPay";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import { user_details_fake } from "../../FakeData";
import { Link } from "react-router-dom";
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { Option } = Select;
const { Title } = Typography;
const getTag = {
  "11": <Tag color="volcano">PENDING</Tag>,
  "21": <Tag color="green">PAID</Tag>,
  "31": <Tag color="red">PAYMENT FAILED</Tag>,
  "41": <Tag color="blue">ORDER CLOSED</Tag>,
  "51": <Tag color="purple">REFUNDED</Tag>
};
const getRenewalTag = {
  "error": <Tag color="purple">ERROR</Tag>,
  "active": <Tag color="green">ACTIVE</Tag>,
  "cancelled": <Tag color="volcano">CANCELLED</Tag>,
};

const UserDetails = ({ app, ...props }) => {
  const requestType = props.match.path.split("/")[3] || null;
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [userInfo, setInfo] = useState([]);
  const [showRefund, setShowRefund] = useState(false);
  const [visible, setVisible] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [userToSearch, setUserToSearch] = useState(props.match.params.id);
  const [refunds, setRefunds] = useState([]);
  const [renewals, setRenewals] = useState([]);
  const [searchFilter, setSearch] = useState({ filter: undefined, value: null })
  const searchOptions = [
    { value: "transaction_id", label: "Transaction ID" },]
  const useDemoData = app
    ? app.alipay_id != null || app.wechat_id != null
    : props.appId === "1234-8475-9439-9494" ||
    props.appId === "1234-8475-9439-9495";
  const tableInfoColumns = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      fixed: "left",
      width: 150,
      render: transaction_id => (
        <Ellipsis length={15} tooltip>
          {transaction_id}
        </Ellipsis>
      )
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => {
        if (a.date > b.date) return -1;
        else if (a.date < b.date) return 1;
        return 0;
      }
    },
    {
      title: "Amount",
      dataIndex: "amount",
      className: classes.alignRight,
      key: "amount",
      sorter: true,
      render: total => <span>¥{total / 100}</span>
    },
    {
      title: "Details",
      dataIndex: "goods_title",
      key: "goods_title"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: status => getTag[status]
    },
    {
      title: "Biz No",
      dataIndex: "biz_no",
      key: "biz_no",
      align: "center",
      render: (biz_no) =>
        biz_no ? (
          <Ellipsis length={15} tooltip>
            {biz_no}
          </Ellipsis>
        ) : (
          "-"
        ),
    },
    {
      title: "Platform ID",
      dataIndex: "refund_no",
      key: "refund_no",
      align: "center",
      render: refund_no => (refund_no ? refund_no : "-")
    },
    {
      title: "",
      dataIndex: "refund",
      key: "refund",
      render: (refund, item) =>
        item.status == "21" && item.biz_no ? (
          <a onClick={() => showRefundInfo(item)}>Refund</a>
        ) : (
          <Tag color="grey">Not refundable</Tag>
        )
    }
  ];

  const refundsTableColumns = [
    {
      title: "Transaction ID",
      dataIndex: "pay_order_id",
      key: "pay_order_id",
      fixed: "left",
      width: 150
    },
    {
      title: "Renewal Agreement #",
      dataIndex: "renewal_agreement",
      key: "renewal_agreement",
      align: "center",
      render: (agreement) =>
        agreement ? (
          <Link to={`/monetization/${app ? app.id : props.appId}/renewals/${agreement}`}>
            {agreement}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      title: "Refund Date",
      dataIndex: "refund_date",
      key: "refund_date",
      sorter: true,
      sorter: (a, b) => {
        if (a.refund_date > b.refund_date) return -1;
        else if (a.refund_date < b.refund_date) return 1;
        return 0;
      }
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details"
    },
    {
      title: "Amount",
      dataIndex: "refund_amount",
      className: classes.alignRight,
      key: "refund_amount",
      sorter: true,
      render: total => <span>¥{total / 100}</span>
    }
  ];
  const renewalsTableColumns = [
    {
      title: "Auto Renewal",
      dataIndex: "renewal_id",
      key: "renewal_id",
      fixed: "left",
      width: 150
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: status => getRenewalTag[status]
    },
    {
      title: "Cancelled Date",
      dataIndex: "cancelled_date",
      key: "cancelled_date",
      sorter: (a, b) => {
        if (a.cancelled_date > b.cancelled_date) return -1;
        else if (a.cancelled_date < b.cancelled_date) return 1;
        return 0;
      }
    },
    {
      title: "Agreement Sign Date",
      dataIndex: "agreement_sign_date",
      key: "agreement_sign_date",
      sorter: (a, b) => {
        if (a.agreement_sign_date > b.agreement_sign_date) return -1;
        else if (a.agreement_sign_date < b.agreement_sign_date) return 1;
        return 0;
      }
    },
    {
      title: "Renewal Date",
      dataIndex: "renewal_date",
      key: "renewal_date",
      sorter: (a, b) => {
        if (a.renewal_date > b.renewal_date) return -1;
        else if (a.renewal_date < b.renewal_date) return 1;
        return 0;
      }
    },
    {
      title: "Renewal Period (Days)",
      dataIndex: "renewal_period",
      key: "renewal_period",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (_, item) =>
        item && item.renewal_id ? (
          <Link to={`/monetization/${app ? app.id : props.appId}/renewals/${item.renewal_id}`}>
            More
          </Link>
        ) : (
          "-"
        ),
    },
  ];

  useEffect(() => {
    searchUser();
  }, [app, app.id]);

  const searchUser = async () => {
    if (useDemoData) {
      setLoadingTransactions(true);
      if (app) {
        const paymentsId = [
          app.alipay_id || "",
          app.wechat_id || "",
          app.seccond_alipay_id || "",
          app.seccond_wechat_id || ""
        ].join(",");
        let results;
        if (requestType === "user") {
          results = await getUserDetails(userToSearch, paymentsId, app);
        } else {
          results = await getUnregisteredUserDetails(userToSearch, paymentsId, app);
        }
        setLoadingTransactions(false);
        if (results) {
          setTransactions(results.user.transactions);
          setRefunds(results.user.refunds);
          setRenewals(results.user.renewals || []);
        }
      }
    }
  };

  const onChangeDate = (date, [start, end]) => {
    setStartDate(start);
    setEndDate(end);
    setDisabled(false);
    const from = new Date(start).getTime();
    const to = new Date(end).getTime();
    const newTransactions = transactions.filter(({ date }) => {
      const timeStamp = new Date(date).getTime();
      return timeStamp >= from && timeStamp <= to;
    });
    const newRefunds = refunds.filter(({ refund_date }) => {
      const timeStamp = new Date(refund_date).getTime();
      return timeStamp >= from && timeStamp <= to;
    });
    const newRenewals = renewals.filter(({ renewal_date }) => {
      const timeStamp = new Date(renewal_date).getTime();
      return timeStamp >= from && timeStamp <= to;
    });
    setTransactions(newTransactions);
    setRefunds(newRefunds);
    setRenewals(newRenewals);
  };

  const resetFilter = () => {
    setDisabled(true);
    setStartDate(null);
    setEndDate(null);
    searchUser();
    setSearch({ filter: undefined, value: null });
  };

  const showRefundInfo = info => {
    setVisible(true);
    setInfo(info);
    setShowRefund(true);
  };

  const handleSearchTransaction = value => {
    if (value.trim() != "") {
      searchTransactionsId(value);
    }
  };

  const handleRequest = () => {
    const { filter, value } = searchFilter;
    if (filter && value && value.trim() !== "") {
      if (filter === "transaction_id") {
        handleSearchTransaction(value);
      }
    }
  }

  const searchTransactionsId = value => {
    setDisabled(false);
    const newTransactions = transactions.filter(({ transaction_id }) =>
      transaction_id.includes(value)
    );
    const newRefunds = refunds.filter(({ pay_order_id }) =>
      pay_order_id.includes(value)
    );
    const newRenewals = renewals.filter(({ renewal_id }) =>
      renewal_id.includes(value)
    );
    setTransactions(newTransactions);
    setRefunds(newRefunds);
    setRenewals(newRenewals);
  };

  const updateAfterRefund = id => {
    const newTransactions = transactions.map(
      ({ transaction_id, status, ...rest }) => ({
        ...rest,
        transaction_id,
        status: transaction_id == id ? "51" : status
      })
    );
    setTransactions(newTransactions);
    searchUser();
  };

  return (
    <div className={classes.tableContainer}>
      <Row gutter={16}>
        <Col style={{ marginTop: 12 }} xs={8} sm={5} md={5} lg={4} xl={3}>
          <Button
            onClick={() => {
              window.history.go(-2);
            }}
            type="primary"
            style={{ width: "100%" }}
            margin="auto"
            ghost
          >
            <Icon type="left" />
            Go back
          </Button>
        </Col>
        <Col style={{ marginTop: 12 }} xs={24} sm={24} md={18} lg={12} xl={10}>
          <Row gutter={8}> <Col span={8}>
            <Select disabled={loadingTransactions} loading={loadingTransactions} value={searchFilter.filter} placeholder="Search By" style={{ width: "100%" }} onChange={val => setSearch({ ...searchFilter, filter: val })}>
              {searchOptions.map(({ label, value }) => (
                <Option value={value}>{label}</Option>
              ))}</Select></Col>
            <Col span={16}>
              <Search
                style={{ width: "100%" }}
                disabled={loadingTransactions || !searchFilter.filter}
                onChange={e => setSearch({ ...searchFilter, value: e.target.value })}
                onSearch={handleRequest}
                placeholder="Type here..."
                value={searchFilter.value}
              />
            </Col>
          </Row>
        </Col>
        <Col style={{ marginTop: 12 }} xs={17} sm={19} md={19} lg={7} xl={7}>
          <RangePicker
            style={{ width: "100%" }}
            value={start_date && end_date ? [moment(start_date), moment(end_date)] : null}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
            disabled={loadingTransactions}
          />
        </Col>
        <Col style={{ marginTop: 12 }} xs={7} sm={5} md={5} lg={3} xl={3}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={() => resetFilter()}
            disabled={disabled}
            loading={loadingTransactions}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <div>
        <p className={classes.DetailsTitle}>USER: {userToSearch}</p>
        <Title type="secondary">Single Purchases</Title>
        <Table
          rowKey={record => record.transaction_id}
          dataSource={
            !useDemoData ? user_details_fake.transactions : transactions
          }
          columns={tableInfoColumns}
          size="small"
          loading={loadingTransactions}
          scroll={{ x: 1000 }}
        />
        <Title type="secondary">Renewal Agreements</Title>
        <Table
          dataSource={!useDemoData ? user_details_fake.renewals : renewals}
          rowKey={record => record.renewal_id}
          columns={renewalsTableColumns}
          size="small"
          loading={loadingTransactions}
          scroll={{ x: 1000 }}
        />
        <Title type="secondary">Refund History</Title>
        <Table
          dataSource={!useDemoData ? user_details_fake.refunds : refunds}
          rowKey={record => record.pay_order_id}
          columns={refundsTableColumns}
          size="small"
          loading={loadingTransactions}
          scroll={{ x: 1000 }}
        />
      </div>
      {!showRefund ? null : (
        <RefundDialog
          info={userInfo}
          show={visible}
          setVisible={setVisible}
          updateAfterRefund={updateAfterRefund}
          app={app}
        />
      )}
    </div>
  );
};

export default UserDetails;
