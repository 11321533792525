import React, { useState } from "react";
import {
  Modal,
  Button,
  Select,
  Form,
  notification,
  Checkbox,
  Row,
  Input,
  Tag
} from "antd";
import { ROLES } from "../../../constants";
import { shareAccess } from "../../../services/acccountService";
const { Option } = Select;
export default Form.create()(
  ({ form, apps, updateInfo, allInfo, ...props }) => {
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator } = form;
    const makeRequest = async values => {
      setLoading(true);
      const { email, ...apps } = values;
      const transformFormValues = Object.entries(apps).reduce(
        (prev, [key, value]) => {
          const app = key
            .split("role_")
            .filter(item => item !== "" && item !== "role_");
          const val = value === true ? { include: value } : { role: value };
          return {
            ...prev,
            [app]: {
              ...prev[app],
              ...val
            }
          };
        },
        {}
      );
      const appsArray = Object.entries(transformFormValues).reduce(
        (prev, [app, values]) => {
          if (values.include) {
            return [
              ...prev,
              {
                id: app,
                role: values.role
              }
            ];
          }
          return prev;
        },
        []
      );
      if (appsArray.length > 0) {
        let { data } = await shareAccess({
          email,
          apps: appsArray
        });

        if (data && data.message === "success") {
          let updateUsers = allInfo.map(({ id, users, ...it }) => {
            let app = appsArray.filter(e => e.id === id);
            let result;
            if (app.length > 0) {
              result = [...users, { ...app[0], email: email }];
            } else {
              result = users;
            }
            return { ...it, users: result, id: id };
          });
          form.resetFields();
          updateInfo(updateUsers);
          notification.info({
            placement: "bottomLeft",
            message: "Invitation sent"
          });
        }
      } else {
        notification.info({
          placement: "bottomLeft",
          message: "Please select at least one App to continue."
        });
      }
      setLoading(false);
      props.close();
    };
    const handleSubmit = event => {
      event.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          makeRequest(values);
        }
      });
    };

    return (
      <Modal
        width={500}
        title="Share Access"
        visible={props.display}
        onCancel={props.close}
        footer={null}
      >
        <Form hideRequiredMark={true} onSubmit={handleSubmit}>
          <Form.Item label="Email">
            {getFieldDecorator(`email`, {
              rules: [
                {
                  type: "email",
                  message: "Please input a valid email."
                },
                { required: true, message: "Required" }
              ]
            })(<Input />)}
          </Form.Item>
          {apps.length > 0
            ? apps.map(it => (
                <Row type="flex" justify="space-between">
                  <Form.Item label="">
                    {getFieldDecorator(`${it.id}`, {
                      initialValue: true,
                      valuePropName: "checked"
                    })(<Checkbox>{it.name} </Checkbox>)}
                  </Form.Item>
                  <Form.Item label="">
                    {getFieldDecorator(`role_${it.id}`, {
                      initialValue: "ADMIN"
                    })(
                      <Select style={{ width: 200 }}>
                        {ROLES.map(({ value, label }) => (
                          <Option key={value} value={value} label={label}>
                            {label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Row>
              ))
            : null}
          <Row type="flex" justify="center" align="middle">
            <Button
              loading={loading}
              disabled={loading}
              type="primary"
              style={{ marginTop: 10 }}
              htmlType="submit"
            >
              Share Access
            </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
);
