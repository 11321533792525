import React, { useState, useEffect } from "react";
import classes from "./FeedbackBar.module.less";
import { Button, Form, notification, Input, Rate, Icon, Checkbox } from "antd";
import { newFeedback } from "../../services/FeedBackService";
const FeedbackBar = ({ form, appId, ...props }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState(false);
  const { getFieldDecorator } = form;
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        notification.success({
          message: "Thank you for your feedback!",
          placement: "bottomLeft",
          duration: 8
        });
        await createNewFeedback({
          ...values,
          rating: values.rating * 2,
          comments: values.comments ? values.comments : "",
          appId
        });
        form.resetFields();
        setOpen(false);
      }
    });
  };
  const createNewFeedback = async values => {
    setLoading(true);
    await newFeedback(values);
    setLoading(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 3000);
  }, []);
  return (
    <div className={[classes.container, open ? classes.open : ""].join(" ")}>
      <Icon
        type="close"
        className={classes.close}
        onClick={() => {
          setOpen(false);
        }}
      />
      <h4>
        How likely are you to recommend our service to a friend or colleague?
      </h4>
      <Form hideRequiredMark={true} layout="vertical" onSubmit={handleSubmit}>
        <div className={classes.rate}>
          <Form.Item label="">
            {getFieldDecorator("rating", {
              rules: [
                {
                  required: true,
                  message: "Please select a number."
                }
              ]
            })(<Rate allowHalf />)}
          </Form.Item>{" "}
        </div>
        <div className={classes.flex}>
          <Checkbox onChange={e => setComment(e.target.checked)}>
            Leave a comment.
          </Checkbox>
        </div>
        {comment ? (
          <Form.Item label="">
            {getFieldDecorator("comments")(
              <Input.TextArea
                placeholder="Tell us what you think..."
                rows={4}
              />
            )}
          </Form.Item>
        ) : null}
        <Form.Item>
          <div className={classes.buttonSend}>
            <Button
              style={{ marginBottom: 0 }}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              icon={loading ? "loading" : null}
              disabled={loading}
            >
              SEND
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create()(FeedbackBar);
