import React from "react";
import { Chart, Geom, Axis, Tooltip, Coord } from "bizcharts";
import DataSet from "@antv/data-set";
import { visits_page } from "../../fakeData";
export default ({ useDemoData, data }) => {
  let chartData = useDemoData ? visits_page : data;

  const total = chartData.reduce((a, b) => a + (b["total_visits"] || 0), 0);
  const ds = new DataSet();
  const dv = ds.createView().source(chartData);

  dv.source(chartData).transform({
    type: "sort",
    callback(a, b) {
      return a.total_visits - b.total_visits;
    }
  });
  return (
    <div>
      <Chart height={400} data={dv} forceFit>
        <Coord transpose />
        <Axis
          name="page"
          label={{
            offset: 12
          }}
        />
        <Axis
          name="total_visits"
          label={{ formatter: val => `${((val / total) * 100).toFixed(2)}%` }}
        />
        <Tooltip
          crosshairs={{
            type: "y"
          }}
        />
        <Geom
          type="interval"
          position="page*total_visits"
          color={"page"}
          tooltip={[
            "page*total_visits",
            (page, total_visits) => {
              total_visits = `${((total_visits / total) * 100).toFixed(2)}%`;
              return {
                value: total_visits
              };
            }
          ]}
        />
      </Chart>
    </div>
  );
};
