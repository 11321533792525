import axios, { publicInstance } from "../axios";

export const getGoogleResults = async value => {
  let response = null;
  try {
    response = await publicInstance.get(`google-search/${value}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getGoogleDetails = async value => {
  let response = null;
  try {
    response = await publicInstance.get(`google-details/${value}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getAppDetails = async values => {
  let response = null;
  try {
    response = await axios.get("https://www.appinchina.co/aicon/scraper.php", {
      ...values
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
