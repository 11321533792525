import React, { Component, Fragment } from "react";
import EventBus from "eventbusjs";
import { notification } from "antd";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import Login from "../Login/Login";
import Register from "../Register/Register";
import Distribution from "../Distribution/Distribution";
import AppFormIntro from "../Distribution/AppFormHome/AppFormIntro/AppFormIntro";
import Localization from "../Localization/LocalizationHome/LocalizationHome";
import SetupApp from "../Setup/SetupApp/SetupApp";
import Content from "../Content/Content";
import Reviews from "../Distribution/Reviews/Reviews";
import PaymentsPlatform from "../PaymentsPlatform/PaymentsPlatform";
import Unsuscribe from "../Unsuscribe/Unsuscribe";
import DetailedHistory from "../Distribution/DetailedHistory/DetailedHistory";
import { getApps } from "../../services/appsService";
import Auth from "../../auth";
import history from "../../history";
import UserDetails from "../PaymentsPlatform/Users/UserDetails/UserDetails";
import Analytics from "../Analytics/AnalyticsHome";
import PushNotifications from "../PushNotifications/PushNotificationsHome";
import PushNotificationsDetails from "../PushNotifications/PushNotificationsDetails/PushNotificationsDetails";
import Downloads from "../Distribution/Downloads/Downloads";
import BigLoader from "../UI/BigLoader/BigLoader";
import WelcomePage from "../WelcomePage/WelcomePage";
import AppPromotionHome from "../AppPromotion/AppPromotionHome";
import { appDemoFake, appChineseDemoFake } from "../Distribution/FakeData";
import FeedbackBar from "../FeedbackBar/FeedBackBar";
import Settings from "../Settings/Settings";
import BadgeDetails from "../Distribution/DistributionDetails/AppInChinaBadge/BadgeTrakingDetails/BadgeTrackingDetails";
import Summary from "../CloudStore/Summary/Summary";
import Shop from "../CloudStore/Shop/Shop";
import Products from "../CloudStore/Products/Products";
import Cart from "../CloudStore/Cart/Cart";
import AccountBalance from "../CloudStore/AccountBalance/AccountBalance";
import ECSConfiguration from "../CloudStore/ServicesConfiguration/ECSConfiguration/ECSConfiguration";
import DatabaseConfiguration from "../CloudStore/ServicesConfiguration/DatabaseConfiguration/DatabaseConfiguration";
import Deposit from "../CloudStore/AccountBalance/Deposit/Deposit";
import SingleDataStoreView from "../AppPromotion/UserActivity/UserBehavior";
import {
  getDistributionTitle,
  getLocalizationTitle,
  getMonetizationTitle,
  getAnalyticsTitle,
  getPushTitle,
} from "../../functions";
import DomainSearch from "../CloudStore/Shop/Domains/Domains";
import BadgeHome from "../BadgeHome/BadgeHome";
import RenewCloudOrder from "../CloudStore/RenewOrder/RenewOrder";
import SdkManagement from "../SdkManagement/SdkManagement";
import SdkManagementApp from "../SdkManagement/SdkManagementApp/SdkManagementApp";
import SdkManagementService from "../SdkManagement/SdkManagementService/SdkManagementService";
import Help from "../Help/help";
import RenewalsDetails from "../PaymentsPlatform/Renewals/RenewalsDetails/RenewalsDetails";
const auth = new Auth();
class Home extends Component {
  state = {
    preventRedirect: false,
    loading: true,
    zeroApps: false,
    apps: null,
    chat_enabled: null,
  };
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };
  getData = () => {
    setTimeout(this.getApps, 500);
    if (localStorage.redirect) {
      if (!localStorage.redirect.includes("login")) {
        history.push(localStorage.redirect);
      }
      localStorage.removeItem("redirect");
    }
    this.openNotificationWithIcon("info", "Login Successful");
  };

  handleLoginError = () => {
    //trying again
    let email = localStorage.e;
    let password = localStorage.p;

    localStorage.removeItem("e");
    localStorage.removeItem("p");

    if (email && password) {
      auth.login(email, password).then((err, result) => {
        this.setState({
          loading: false,
        });
      });
    } else {
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "warning",
        "Connection error, please try again"
      );
    }
  };
  logoutBlockedUser() {
    let i = localStorage.length;
    while (i--) {
      let key = localStorage.key(i);
      if (!/_newFeature/.test(key)) {
        localStorage.removeItem(key);
      }
    }
    history.replace("/login");
    this.openNotificationWithIcon(
      "info",
      "Your account is limited and you can not access this platform. For more details please contact your account manager."
    );
  }

  handleAuthentication = () => {
    let pathname = window.location.pathname;
    if (/jwt/.test(window.location.search)) {
      console.log("setting session from url");
      let url_params = new URLSearchParams(window.location.search);
      localStorage.original_id_token = localStorage.id_token;
      localStorage.id_token = url_params.get("jwt");
      localStorage.email_to_impersonate = decodeURIComponent(
        url_params.get("email_to_impersonate")
      );
      localStorage.email_website = decodeURIComponent(
        url_params.get("email-website")
      );
      localStorage.expires_at =
        url_params.get("expires_at") ||
        new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).getTime();
      setTimeout(this.getApps, 500);
    } else if (!auth.isAuthenticated() && !pathname.includes("unsuscribe")) {
      if (
        pathname.includes("user-unregistered") ||
        (!pathname.includes("register") && !pathname.includes("login"))
      ) {
        localStorage.redirect = pathname;
        history.replace("/login");
      }
      this.setState({ loading: false });
    } else if (
      (!pathname.includes("login") &&
        !pathname.includes("register") &&
        !pathname.includes("unsuscribe")) ||
      pathname.includes("user-unregistered")
    ) {
      this.getApps();
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    let { apps, feedback_needed, app_intro_viewed } = this.state;
    return (
      <Fragment>
        {/*feedback_needed ? <FeedbackBar /> : null*/}
        <Router history={history}>
          <Switch>
            <Route
              key="login"
              path="/login"
              exact
              render={(props) => <Login {...props} />}
            />
            <Route
              key="new"
              path="/new"
              render={(props) => (
                <Content
                  apps={apps}
                  hideMenu
                  menuOption="new"
                  section="new"
                  hideAppSelector
                  title="Add New App"
                >
                  <SetupApp {...props} apps={apps} />{" "}
                </Content>
              )}
            />
            <Route
              key="register"
              path="/register"
              exact
              render={(props) => <Register {...props} />}
            />
            <Route
              key="unsuscribe"
              path="/unsuscribe"
              exact
              component={Unsuscribe}
            />
            <Route
              key="home"
              path="/home/:app_id"
              exact
              render={(props) => (
                <Content
                  apps={apps}
                  menuOption="home"
                  section="home"
                  title=""
                  appId={props.match.params.app_id}
                >
                  <WelcomePage
                    {...props}
                    apps={apps}
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                  />
                </Content>
              )}
            />
            <Route
              key="badge"
              path="/badge-tracking/:id"
              exact
              render={(props) => (
                <Content
                  apps={apps}
                  appId={props.match.params.id}
                  title="Badge Statistics"
                >
                  <BadgeDetails {...props} />
                </Content>
              )}
            />
            <Route
              key="downloads"
              path="/distribution/:app_id/downloads"
              exact
              render={(props) => (
                <Content
                  apps={apps}
                  menuOption="distribution"
                  section="status"
                  appId={props.match.params.app_id}
                  title="Downloads"
                >
                  <Downloads
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                    {...props}
                  />
                </Content>
              )}
            />
            <Route
              key="app-reviews"
              path="/distribution/:app_id/app-reviews"
              exact
              render={(props) => (
                <Content
                  apps={apps}
                  menuOption="distribution"
                  section="status"
                  title="Reviews"
                  appId={props.match.params.app_id}
                >
                  <Reviews
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                    {...props}
                  />
                </Content>
              )}
            />
            <Route
              key="history"
              path="/distribution/:app_id/history"
              exact
              render={(props) => (
                <Content
                  apps={apps}
                  menuOption="distribution"
                  section="status"
                  title="App History"
                  appId={props.match.params.app_id}
                >
                  <DetailedHistory
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                    {...props}
                  />
                </Content>
              )}
            />
            <Route
              key="distribution-redirect"
              path="/distribution/:app_id/"
              exact
              render={(props) => (
                <Redirect
                  to={`/distribution/${props.match.params.app_id}/app-information/submission_history`}
                />
              )}
            />
            <Route
              key="distribution-redirect"
              path="/distribution/:app_id/app-information/"
              exact
              render={(props) => (
                <Redirect
                  to={`/distribution/${props.match.params.app_id}/app-information/submission_history`}
                />
              )}
            />
            <Route
              key="distribution"
              path="/distribution/:app_id/:section/:tab?"
              render={(props) => (
                <Content
                  title={getDistributionTitle(props.match.params.section)}
                  apps={apps}
                  appId={props.match.params.app_id}
                  menuOption="distribution"
                  section={props.match.params.section}
                  hasTabs={props.match.params.section === "app-information"}
                >
                  {props.match.params.section === "app-information" ? (
                    <AppFormIntro
                      visible={!app_intro_viewed}
                      onOk={() => this.setState({ app_intro_viewed: true })}
                    />
                  ) : null}
                  <Distribution apps={this.state.apps} {...props} />
                </Content>
              )}
            />
            <Route
              key="localization-redirect"
              path="/localization/:app_id/"
              exact
              render={(props) => (
                <Redirect
                  to={`/localization/${props.match.params.app_id}/integrations`}
                />
              )}
            />
            <Route
              key="localization"
              path="/localization/:app_id/:section"
              render={(props) => (
                <Content
                  apps={apps}
                  title={getLocalizationTitle(props.match.params.section)}
                  appId={props.match.params.app_id}
                  menuOption="localization"
                  section={props.match.params.section}
                >
                  <Localization
                    apps={this.state.apps}
                    appId={props.match.params.app_id}
                    section={props.match.params.section}
                  />
                </Content>
              )}
            />
            <Route
              key="user-details"
              path="/monetization/:app_id/user/:id"
              exact
              render={(props) => (
                <Content
                  title="User Details"
                  apps={apps}
                  appId={props.match.params.app_id}
                  menuOption="monetization"
                  section="users"
                >
                  <UserDetails
                    {...props}
                    apps={apps}
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                  />
                </Content>
              )}
            />
            <Route
              key="user-details-unregistered"
              path="/monetization/:app_id/user-unregistered/:id"
              exact
              render={(props) => (
                <Content
                  title="User Details (By Username)"
                  apps={apps}
                  appId={props.match.params.app_id}
                  menuOption="monetization"
                  section="users"
                >
                  <UserDetails
                    {...props}
                    apps={apps}
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                  />
                </Content>
              )}
            />
            <Route
              key="renewals-details"
              path="/monetization/:app_id/renewals/:id"
              exact
              render={(props) => (
                <Content
                  title="Renewals Details"
                  apps={apps}
                  appId={props.match.params.app_id}
                  menuOption="monetization"
                  section="renewals"
                >
                  <RenewalsDetails
                    {...props}
                    apps={apps}
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                  />
                </Content>
              )}
            />
            <Route
              key="monetization-redirect"
              path="/monetization/:app_id"
              exact
              render={(props) => (
                <Content apps={apps} appId={props.match.params.app_id}>
                  <Redirect
                    to={`/monetization/${props.match.params.app_id}/summary/`}
                  />
                </Content>
              )}
            />
            <Route
              key="monetization"
              path="/monetization/:app_id/:section"
              render={(props) => (
                <Content
                  apps={apps}
                  appId={props.match.params.app_id}
                  menuOption="monetization"
                  section={props.match.params.section}
                  title={getMonetizationTitle(props.match.params.section)}
                >
                  <PaymentsPlatform
                    apps={this.state.apps}
                    appId={props.match.params.app_id}
                    section={props.match.params.section}
                  />
                </Content>
              )}
            />
            <Route
              key="analytics-redirect"
              path="/analytics/:app_id/"
              exact
              render={(props) => (
                <Redirect
                  to={`/analytics/${props.match.params.app_id}/general-information`}
                />
              )}
            />
            <Route
              key="analytics"
              path="/analytics/:app_id/:section"
              render={(props) => (
                <Content
                  apps={apps}
                  title="Analytics"
                  menuOption="analytics"
                  section={props.match.params.section}
                  appId={props.match.params.app_id}
                  title={getAnalyticsTitle(props.match.params.section)}
                >
                  <Analytics
                    apps={this.state.apps}
                    appId={props.match.params.app_id}
                    section={props.match.params.section}
                  />
                </Content>
              )}
            />
            <Route
              key="app-promotion-redirect"
              path="/app-promotion/:app_id/"
              exact
              render={(props) => (
                <Redirect
                  to={`/app-promotion/${props.match.params.app_id}/realtime-statistics`}
                />
              )}
            />

            <Route
              key="app-promotion"
              path="/app-promotion/:app_id/:section"
              render={(props) => (
                <Content
                  feedback_needed={feedback_needed}
                  apps={apps}
                  menuOption="app-promotion"
                  section={props.match.params.section}
                  appId={props.match.params.app_id}
                >
                  <AppPromotionHome
                    apps={this.state.apps}
                    appId={props.match.params.app_id}
                    section={props.match.params.section}
                  />
                </Content>
              )}
            />
            <Route
              key="app-promotion"
              path="/promotion/:app_id/data-per-store/:store"
              render={(props) => (
                <Content
                  feedback_needed={feedback_needed}
                  apps={apps}
                  menuOption="app-promotion"
                  section={props.match.params.section}
                  appId={props.match.params.app_id}
                >
                  <SingleDataStoreView
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                    useDemoData={
                      apps
                        ? !!apps.find(
                            (it) => it.id === props.match.params.app_id
                          ).analytics_id
                        : true
                    }
                    store={props.match.params.store}
                  />
                </Content>
              )}
            />
            <Route
              key="cloud-summary"
              path="/cloud/summary/:app_id"
              render={(props) => (
                <Content
                  title="My Orders"
                  section="summary"
                  menuOption="cloud"
                  apps={apps}
                  appId={props.match.params.app_id}
                >
                  <Summary
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                  />
                </Content>
              )}
            />

            <Route
              key="settings"
              path="/settings/aic-badge/:app_id"
              exact
              render={(props) => (
                <Content
                  appId={props.match.params.app_id}
                  apps={apps}
                  title="Settings"
                >
                  <Settings
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                    section="aic-badge"
                  />
                </Content>
              )}
            />
            <Route
              key="settings"
              path="/settings/:section/:app_id"
              exact
              render={(props) => (
                <Content
                  appId={props.match.params.app_id}
                  apps={apps}
                  hideAppSelector
                  title="Settings"
                  section={props.match.params.section}
                >
                  <Settings
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                    section={props.match.params.section}
                  />
                </Content>
              )}
            />
            <Route
              key="cloud-shop-domain"
              path="/cloud/shop/domains/:app_id"
              render={(props) => (
                <Content
                  title="Custom Domain"
                  section="shop"
                  menuOption="cloud"
                  apps={apps}
                  appId={props.match.params.app_id}
                >
                  <DomainSearch
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="cloud-shop-ecs"
              path="/cloud/shop/ecs/:app_id"
              render={(props) => (
                <Content
                  title="Elastic Compute Service"
                  section="shop"
                  menuOption="cloud"
                  apps={apps}
                  appId={props.match.params.app_id}
                >
                  <ECSConfiguration
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="cloud-shop-database"
              path="/cloud/shop/database/:app_id"
              render={(props) => (
                <Content
                  title="Dedicated Databases"
                  section="shop"
                  menuOption="cloud"
                  apps={apps}
                  appId={props.match.params.app_id}
                >
                  <DatabaseConfiguration
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="cloud-shop"
              path="/cloud/shop/:app_id"
              render={(props) => (
                <Content
                  title="Cloud Store"
                  section="shop"
                  menuOption="cloud"
                  apps={apps}
                  appId={props.match.params.app_id}
                >
                  <Shop
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="cloud-products"
              path="/cloud/products/:app_id"
              render={(props) => (
                <Content
                  title="Cloud Store"
                  section="shop"
                  menuOption="cloud"
                  apps={apps}
                  appId={props.match.params.app_id}
                >
                  <Products
                    {...props}
                    apps={apps}
                    appId={props.match.params.app_id}
                  />
                </Content>
              )}
            />
            <Route
              key="cloud-cart"
              path="/cloud/cart/:app_id"
              render={(props) => (
                <Content
                  title="Shopping Cart"
                  section="cart"
                  menuOption="cloud"
                  apps={apps}
                  appId={props.match.params.app_id}
                >
                  <Cart
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="cloud-account-balance"
              path="/cloud/account-balance/:app_id"
              render={(props) => (
                <Content
                  title="Account Balance"
                  section="account-balance"
                  menuOption="cloud"
                  apps={apps}
                  appId={props.match.params.app_id}
                >
                  <AccountBalance
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="cloud-deposit"
              path="/cloud/deposit/:app_id"
              render={(props) => (
                <Content
                  title="Deposit"
                  section="deposit"
                  menuOption="cloud"
                  apps={apps}
                  appId={props.match.params.app_id}
                >
                  <Deposit
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="renew-order"
              path="/renew-order/:order_id"
              render={(props) => (
                <Content
                  title="Renew Order"
                  apps={apps}
                  section="cloud"
                  menuOption="cloud"
                  hideAppSelector
                  // appId={props.match.params.app_id}
                >
                  <RenewCloudOrder
                    {...props}
                    orderId={props.match.params.order_id}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="aic-badge"
              path="/aic-badge/:app_id/:section"
              render={(props) => (
                <Content
                  title="Badge Page"
                  apps={apps}
                  appId={props.match.params.app_id}
                >
                  <BadgeHome
                    {...props}
                    appId={props.match.params.app_id}
                    section={props.match.params.section}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="/aic-badge/"
              path="/aic-badge/:app_id/"
              exact
              render={(props) => (
                <Redirect
                  to={`/aic-badge/${props.match.params.app_id}/analytics`}
                />
              )}
            />
            <Route
              key="sdk-management"
              path="/sdk-management/:app_id"
              exact
              render={(props) => (
                <Content
                  apps={apps}
                  hideAppSelector
                  title="SDK & API Management"
                  section={props.match.params.section}
                >
                  <SdkManagement
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                    section={props.match.params.section}
                  />
                </Content>
              )}
            />
            <Route
              key="sdk-management"
              path="/sdk-management/details/:app_id"
              exact
              render={(props) => (
                <Content
                  appId={props.match.params.app_id}
                  apps={apps}
                  hideAppSelector
                  title="SDK & API Management - App"
                  section={props.match.params.section}
                >
                  <SdkManagementApp
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                    section={props.match.params.section}
                  />
                </Content>
              )}
            />
            <Route
              key="sdk-management"
              path="/sdk-management/details/:app_id/:service_no"
              exact
              render={(props) => (
                <Content
                  appId={props.match.params.app_id}
                  apps={apps}
                  hideAppSelector
                  title="SDK & API Management - Service Details"
                >
                  <SdkManagementService
                    {...props}
                    appId={props.match.params.app_id}
                    serviceNo={props.match.params.service_no}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="help"
              path="/help/:app_id"
              exact
              render={(props) => (
                <Content
                  appId={props.match.params.app_id}
                  apps={apps}
                  hideAppSelector
                  title="Help"
                  fullSize
                >
                  <Help
                    appId={props.match.params.app_id}
                    {...props}
                    apps={apps}
                  />
                </Content>
              )}
            />
            <Route
              key="push-notifications"
              path="/push-notifications/:app_id/details/:pushId"
              render={(props) => (
                <Content
                  apps={apps}
                  title="Push Notifications"
                  menuOption="push-notifications"
                  appId={props.match.params.app_id}
                  title="Push Notifications Details"
                >
                  <PushNotificationsDetails
                    apps={this.state.apps}
                    appId={props.match.params.app_id}
                    pushId={props.match.params.pushId}
                  />
                </Content>
              )}
            />
            <Route
              key="push-notifications"
              path="/push-notifications/:app_id/:section"
              render={(props) => (
                <Content
                  apps={apps}
                  title="Push Notifications"
                  menuOption="push-notifications"
                  section={props.match.params.section}
                  appId={props.match.params.app_id}
                  title={getPushTitle(props.match.params.section)}
                >
                  <PushNotifications
                    apps={this.state.apps}
                    appId={props.match.params.app_id}
                    section={props.match.params.section}
                  />
                </Content>
              )}
            />
            <Route
              key="app-promotion-redirect"
              path="/app-promotion/:app_id/"
              exact
              render={(props) => (
                <Redirect
                  to={`/app-promotion/${props.match.params.app_id}/realtime-statistics`}
                />
              )}
            />
            <Route
              key="other"
              path="/"
              render={() =>
                apps ? (
                  apps.length ? (
                    <Redirect to={`/home/${apps[0].id}`} />
                  ) : (
                    <Redirect to="/new" />
                  )
                ) : (
                  <BigLoader loading />
                )
              }
            />
          </Switch>
        </Router>
      </Fragment>
    );
  }

  handleUpdateSubmission = () => {
    this.openNotificationWithIcon("info", "New version submitted successfully");
    window.history.back();
  };

  handleCopyrightSubmission = (event) => {
    let app = {
      ...this.state.apps.find((it) => it.selected),
      copyright_form_submitted: true,
      copyright_form_skipped: true,
    };
    let apps = [...this.state.apps];
    apps[apps.findIndex((it) => app.id === it.id)] = app;
    this.setState({
      apps: apps,
    });
  };

  updateApps = (event, apps, preventReload = false) => {
    console.log("updating apps...");
    let filterApps = apps.filter(
      ({ id }) => id !== "1234-8475-9439-9494" && id !== "1234-8475-9439-9495"
    );
    this.setState({
      apps: [...filterApps, appDemoFake, appChineseDemoFake],
      appsDownloaded: true,
      loading: false,
    });
    if (!preventReload) history.push("/");
  };

  updateApp = (event, app) => {
    console.log("updating app", app);
    let apps = [...this.state.apps];
    if (apps && apps.length) {
      let index = apps.findIndex(
        (it) => app.FK_Apps === it.FK_Apps || app.id === it.id
      );
      console.log("ap index", index);
      apps[index] = { ...app };
    }
    console.log("apps now", apps);
    this.setState({
      apps: apps,
      loading: false,
    });
  };

  selectApp = (event, index) => {
    let apps = [...this.state.apps];
    apps.forEach((it, i, arr) => (arr[i].selected = i === index));
    this.setState({ apps: apps, addingApp: false });
  };

  getApps = async () => {
    this.setState({ loading: true });
    let response = await getApps();
    if (response.data && response.data.apps) {
      this.setState({
        feedback_needed: !!response.data.feedback_needed,
        app_intro_viewed: !!response.data.app_intro_viewed,
      });
      this.updateApps(null, response.data.apps, true);
    } else if (response.data.error === "blocked") {
      this.logoutBlockedUser();
    }
  };

  componentWillMount() {
    this.handleAuthentication();
  }

  componentDidMount() {
    EventBus.addEventListener("UPDATE_APPS", this.updateApps, this);
    EventBus.addEventListener("UPDATE_APP", this.updateApp, this);
    EventBus.addEventListener("SELECT_APP", this.selectApp, this);
    EventBus.addEventListener("GET_APPS", this.getApps, this);
    EventBus.addEventListener("GET_DATA", this.getData, this);
    EventBus.addEventListener("LOGIN_ERROR", this.handleLoginError, this);
  }

  componentWillUnmount() {
    EventBus.removeEventListener("UPDATE_APPS", this.updateApps, this);
    EventBus.removeEventListener("UPDATE_APP", this.updateApp, this);
    EventBus.removeEventListener("SELECT_APP", this.selectApp, this);
    EventBus.removeEventListener("GET_APPS", this.getApps, this);
    EventBus.removeEventListener("GET_DATA", this.getData, this);
    EventBus.removeEventListener("LOGIN_ERROR", this.handleLoginError, this);
  }
}

export default Home;
