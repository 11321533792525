import React from "react";
import { Empty } from "antd";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import { transactions } from "../../fakeData";
export default class extends React.Component {
  render() {
    let data = !this.props.useDemoData
      ? this.props.data.reduce((prev, curr) => {
          const [key] = Object.keys(curr);
          return [
            {
              ...prev[0],
              name: "Transactions",
              [key]: curr[key].transactions_count
            },
            {
              ...prev[1],
              name: "Users",
              [key]: curr[key].users_count
            }
          ];
        }, [])
      : transactions;
    let keys =
      this.props.data.length > 0
        ? this.props.data.map(item => {
            const [key] = Object.keys(item);
            return key;
          })
        : ["Tencent", "Huawei", "Baidu", "Oppo"];

    const ds = new DataSet();
    const dv = ds.createView().source(data);
    dv.transform({
      type: "fold",
      fields: keys,
      key: "store",
      value: "valor"
    });

    return data.length > 0 ? (
      <Chart height={500} data={dv} forceFit>
        <Axis name="store" />
        <Axis name="valor" />
        <Legend />
        <Tooltip
          crosshairs={{
            type: "y"
          }}
        />
        <Geom
          type="interval"
          position="store*valor"
          color={"name"}
          adjust={[
            {
              type: "dodge",
              marginRatio: 1 / 32
            }
          ]}
        />
      </Chart>
    ) : (
      <Empty />
    );
  }
}
