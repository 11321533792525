import React, { useState } from "react";
import classes from "./Domains.module.less";
import { Form, Input, Button, Row, notification, Select, Col } from "antd";
import DomainCard from "./DomainCard/DomainCard";
import { checkDomain } from "../../../../services/cloudStoreService";

const Domains = ({ form, appId, ...props }) => {
  const { getFieldDecorator } = form;
  const { Item } = Form;
  const { Option } = Select;
  let [domains, setDomains] = useState([]);
  let [loading, setLoading] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        let domain = `${values.domain_name}${values.top_level}`;
        try {
          setLoading(true);
          const { data } = await checkDomain(domain);
          setLoading(false);
          if (data && data.name) {
            setDomains([{ ...data }]);
          } else {
            setDomains([]);
          }

          form.resetFields();
        } catch (error) {
          console.log(error);
        }
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft"
        });
      }
    });
  };
  const selectAfter = (
    <Item>
      {getFieldDecorator(`top_level`, {
        initialValue: ".com",
        rules: [
          {
            required: true,
            message: "Required!",
            whitespace: true
          }
        ]
      })(
        <Select disabled={loading} style={{ width: 80 }}>
          <Option value=".cn">.cn</Option>
          <Option value=".com.cn">.com.cn</Option>
          <Option value=".com">.com</Option>
        </Select>
      )}
    </Item>
  );
  return (
    <div className={classes.container}>
      <p>
        Purchase, setup and manage your Chinese domains right from the
        AppInChina dashboard. Purchases through the dashboard are made via
        Alibaba Cloud and AppInChina will be the domain's custodian. Should you
        choose to transfer this domain at a later time, AppInChina can assist
        with its transfer.
      </p>
      <p>
        It is required by law in China to apply for an ICP Recordal license
        after a domain purchase. Additional fees apply.{" "}
      </p>
      <Form onSubmit={handleSubmit} colon={false} layout="vertical">
        <Row className={classes.search}>
          <Item>
            {getFieldDecorator(`domain_name`, {
              rules: [
                {
                  required: true,
                  message: "Required!",
                  whitespace: true
                }
              ]
            })(<Input disabled={loading} addonAfter={selectAfter} />)}
          </Item>
          <Item>
            <Button
              loading={loading}
              icon="search"
              width="200px"
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Search
            </Button>
          </Item>
        </Row>
      </Form>
      <DomainCard appId={appId} domains={domains} />
    </div>
  );
};
export default Form.create()(Domains);
