import React, { useState, useEffect } from "react";
import { Modal, Popover, Icon, Input, Form, notification } from "antd";
import classes from "./CredentialsModal.module.less";
import {
  createCredentials,
  editCredentials,
} from "../../../../services/sdkManagementService";

const CredentialsModal = ({ form, visible, app, onAdd, onEdit, onClose }) => {
  let [loading, setLoading] = useState(false);

  const { getFieldDecorator } = form;

  useEffect(() => {
    form.setFields({
      aicpay_appname: {
        value: app.main_information.aicpay_appname || app.name,
      },
      aicpay_package: {
        value:
          app.main_information.aicpay_package ||
          app.main_information.package_name,
      },
      max_logon_num: {
        value: app.main_information.max_logon_num || 0,
      },
    });
  }, [visible]);

  let saveData = (event) => {
    event.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        if (!app.main_information.aicpay_secret) {
          let { data } = await createCredentials(app.id, values);
          if (!data.error) {
            notification.success({
              message: "Data Saved.",
              placement: "bottomLeft",
            });
            onAdd(data);
          } else {
            notification.error({
              message: "An error happened",
              placement: "bottomLeft",
            });
          }
        } else {
          let { data } = await editCredentials(app.id, {
            ...values,
            analitycs_id: app.analitycs_id,
          });
          if (!data.error) {
            notification.success({
              message: "Data Saved.",
              placement: "bottomLeft",
            });
            onEdit(values);
          } else {
            notification.error({
              message: "An error happened",
              placement: "bottomLeft",
            });
          }
        }
        setLoading(false);
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft",
        });
      }
    });
  };

  return (
    <div className={classes.container}>
      <Modal
        visible={visible}
        confirmLoading={loading}
        onOk={saveData}
        onCancel={onClose}
      >
        <Form.Item label="App Name">
          {getFieldDecorator("aicpay_appname", {
            rules: [
              {
                required: true,
                message: "Required",
              },
            ],
          })(<Input disabled={app.main_information.aicpay_secret} />)}
        </Form.Item>

        <Form.Item label="Package Name">
          {getFieldDecorator("aicpay_package", {
            rules: [
              {
                required: true,
                message: "Required",
              },
            ],
          })(<Input disabled={app.analitycs_id === "20190307001"} />)}
        </Form.Item>

        <Form.Item label="No. of Simultaneous Device Logins">
          {getFieldDecorator("max_logon_num", {
            rules: [
              {
                required: true,
                message: "Required",
              },
            ],
          })(
            <Input type="number" disabled={app.analitycs_id == "20190307001"} />
          )}
        </Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(CredentialsModal);
