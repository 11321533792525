import axios from "axios";
import { notification } from "antd";
import demoUrls from "./urlDemo";
import Auth from "./auth";
import history from "./history";

const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
    placement: "bottomLeft",
  });
};
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
instance.interceptors.request.use(async (request) => {
  let demo = request.url.includes("/badge-tracking")
    ? demoUrls.find(
        (e) => request.url.includes(e.url) && e.method === request.method
      )
    : request.url.includes("/badge-pages")
    ? demoUrls.find(
        (e) => request.url.includes(e.url) && e.method === request.method
      )
    : request.data && request.data.app
    ? demoUrls.find(
        (e) =>
          request.url === e.url &&
          e.method === request.method &&
          (e.data && e.data.app ? e.data.app === request.data.app : null)
      )
    : request.data && request.data.app_id
    ? demoUrls.find(
        (e) =>
          request.url === e.url &&
          e.method === request.method &&
          (e.data && e.data.app_id
            ? e.data.app_id === request.data.app_id
            : null)
      )
    : request.data && request.data.apk_id
    ? demoUrls.find(
        (e) =>
          request.url === e.url &&
          e.method === request.method &&
          (e.data && e.data.apk_id
            ? e.data.apk_id === request.data.apk_id
            : null)
      )
    : request.data && request.data.FK_App
    ? demoUrls.find(
        (e) =>
          request.url === e.url &&
          e.method === request.method &&
          (e.data && e.data.FK_App
            ? e.data.FK_App === request.data.FK_App
            : null)
      )
    : request.data && request.data.appId
    ? demoUrls.find(
        (e) =>
          request.url === e.url &&
          e.method === request.method &&
          (e.data && e.data.appId ? e.data.appId === request.data.appId : null)
      )
    : demoUrls.find(
        (e) => e.url === request.url && e.method === request.method
      );
  if (demo && demo.url) {
    let response = demo.response;
    throw new axios.Cancel(response);
  } else {
    try {
      const auth = new Auth();
      await auth.refreshToken();
      request.headers.Authorization = `Bearer ${localStorage.id_token}`;
      if (
        localStorage.email_to_impersonate &&
        localStorage.email_to_impersonate !== "null"
      ) {
        request.headers["X-Email-To-Impersonate"] =
          localStorage.email_to_impersonate;
      }
    } catch (err) {
      const error = new Error(err);
      return error.message;
    }
    return request;
  }
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("AXIOS ERROR", error);
    if (error.message) {
      console.log("AXIOS ERROR has message");
      if (
        !!error.response &&
        !!error.response.status &&
        error.response.status === 401
      ) {
        console.log("AXIOS ERROR 401");
        openNotificationWithIcon(
          "warning",
          "Invalid token, please login again"
        );
        localStorage.clear();
        history.replace("/login");
      } else {
        return Promise.resolve(error.message);
      }
    }
  }
);

export default instance;

export const publicInstance = axios.create({
  baseURL: process.env.REACT_APP_OPEN_URL,
});

export const appInChinaPay = axios.create();

appInChinaPay.interceptors.request.use(async request => {
  try {
    const auth = new Auth();
    await auth.refreshToken();
    request.headers.authorization = `Bearer ${localStorage.id_token}`;
  } catch (err) {
    const error = new Error(err);
    return error.message;
  }
  return request;
});
