import React from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import functions from "../../../functions";
const cols = {
  store: {
    range: [0, 1]
  }
};

export default ({ dataView }) => {
  return (
    <Chart
      height={500}
      data={dataView}
      scale={cols}
      forceFit
      padding={60}
      style={{ marginTop: "40px" }}
    >
      <Axis name="date" />
      <Axis
        name="transactions_by_day"
        label={{ formatter: val => functions.styleAsNumber(val) }}
      />
      <Legend
        position="top"
        useHtml={true}
        g2-legend={{
          marginLeft: "100px",
          marginTop: "-107px"
        }}
        g2-legend-list={{
          border: "none",
          height: "auto",
          marginBottom: "30px"
        }}
      />
      <Tooltip
        crosshairs={{
          type: "y"
        }}
      />
      <Geom
        type="line"
        position="date*transactions_by_day"
        size={2}
        color={"store"}
        tooltip={[
          "store*transactions_by_day",
          (store, transactions_by_day) => {
            transactions_by_day = functions.styleAsNumber(transactions_by_day);
            return {
              name: store,
              value: transactions_by_day
            };
          }
        ]}
      />
      <Geom
        type="point"
        position="date*transactions_by_day"
        size={4}
        shape={"circle"}
        color={"store"}
        style={{
          stroke: "#fff",
          lineWidth: 1
        }}
      />
    </Chart>
  );
};
