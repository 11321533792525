import React, { useEffect, useState } from "react";
import { Row, Col, Card, DatePicker, Select, Tabs, Button } from "antd";
import MultiLineChart from "../../UI/Charts/MultiLineChart";
import { chartsFake } from "../PromotionFakeData";
import { getPaidUsersChart } from "../../../services/promotionService";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";
import { getDate } from "../../../functions";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
export default ({ app, useDemoData }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  let [store, setStore] = useState(null);
  let [allStores, setAllStores] = useState([]);
  let [date, setDate] = useState(getDate());

  useEffect(() => {
    validateRequest();
  }, [app, store, date]);

  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getPaidUsersChart({
      promotionId: app.analitycs_id,
      app: app.id,
      store,
      version: null,
      startDate: date[0].format("YYYY-MM-DD"),
      endDate: date[1].format("YYYY-MM-DD"),
    });
    setData(response.data);
    setAllStores(response.stores);
    setLoading(false);
  };
  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData({
          paidUsers: chartsFake(["Vivo", "Oppo", "Huawei", "Tencent My App"]),
          orderVolume: chartsFake(["Vivo", "Oppo", "Huawei", "Tencent My App"]),
          paymentAmount: chartsFake([
            "Vivo",
            "Oppo",
            "Huawei",
            "Tencent My App",
          ]),
        });
        setAllStores([
          {
            value: null,
            label: "All Stores",
          },
        ]);
        setLoading(false);
      }, 500);
    }
  };

  const exportToCsv = () => {
    const headers = ["Date", "Paying Users", "Transactions", "Total Paid"].join(
      ","
    );
    const rows = Array(data.paidUsers.length)
      .fill("")
      .map(
        (_, index) =>
          `${data.paidUsers[index].date},${data.paidUsers[index].event_count},${data.orderVolume[index].event_count},${data.paymentAmount[index].event_count}`
      )
      .join("\n");

    let csvContent = "data:text/csv;charset=utf-8," + `${headers}\n` + rows;
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Card style={{ marginTop: 15 }}>
      <Row gutter={16}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          lg={{ span: 10 }}
          style={{ marginBottom: 15 }}
        >
          <RangePicker
            value={date}
            style={{ width: "100%" }}
            onChange={setDate}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 6 }}
          lg={{ span: 4 }}
          style={{ marginBottom: 15 }}
        >
          <Select
            defaultValue={null}
            style={{ width: "100%" }}
            onChange={setStore}
          >
            {allStores.map(({ value, label }) => (
              <Select.Option value={value}>{label}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 6 }}
          lg={{ offset: 6, span: 4 }}
          style={{ marginBottom: 15 }}
        >
          <Button
            type="primary"
            icon="file"
            style={{ width: "100%" }}
            onClick={exportToCsv}
          >
            Export
          </Button>
        </Col>
      </Row>
      {loading ? (
        <LoadingSpin />
      ) : (
        <Tabs
          type="card"
          defaultActiveKey="1"
          style={{ width: "100%", marginTop: 30 }}
        >
          <TabPane tab="Paying Users" key="1">
            <MultiLineChart loading={loading} data={data.paidUsers} />
          </TabPane>
          <TabPane tab="Transactions" key="2">
            <MultiLineChart loading={loading} data={data.orderVolume} />
          </TabPane>
          <TabPane tab="Total Paid" key="3">
            <MultiLineChart loading={loading} data={data.paymentAmount} />
          </TabPane>
        </Tabs>
      )}
    </Card>
  );
};
