import React, { useState, Fragment, useEffect } from "react";
import classes from "../PaymentsPlatform.module.less";
import { Button, Table, DatePicker, Input, Col, Row, List, Select } from "antd";
import {
  getUser,
  getAllUsers,
  getUsersByCustomerName,
  getAllUnregistered,
} from "../../../services/aicPay";
import moment from "moment";
import { Link } from "react-router-dom";
import { users_table_fake } from "../FakeData";
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { Option } = Select;
const Users = ({ app, useDemoData, ...props }) => {
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [pagination, setPagination] = useState({});
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [sorters, setSorters] = useState("date");
  const [sortOrder, setSortOrder] = useState("descend");
  const [unregistered, setUnregistered] = useState([]);
  const [unregisteredPagination, setUnregisteredPagination] = useState({});
  const [unregisteredLoading, setUnregisteredLoading] = useState(false);
  const [unregisteredFilters, setUnregisteredFilters] = useState(null);
  const [unregisteredSorters, setUnregisteredSorters] = useState("date");
  const [unregisteredSortOrder, setUnregisteredSortOrder] = useState("descend");
  const [useAsync, setUseAsync] = useState(true);
  const [searchFilter, setSearch] = useState({
    filter: undefined,
    value: null,
  });
  const searchOptions = [
    { value: "user", label: "Phone Number" },
    { value: "customer_name", label: "User Name" },
  ];
  const tableColumns = () => [
    {
      title: "Phone Number",
      dataIndex: "mobile_no",
      key: "mobile_no",
      sorter: false,
      fixed: "left",
      width: 150,
    },
    {
      title: "Date Created",
      dataIndex: "date",
      key: "date",
      align: "center",
      sorter: true,
    },
    {
      title: "",
      dataIndex: "more",
      key: "more",
      render: (more, item) =>
        item && item.mobile_no ? (
          <Link
            to={`/monetization/${app ? app.id : props.appId}/user/${item.mobile_no
              }`}
          >
            More
          </Link>
        ) : (
          ""
        ),
    },
  ];

  const unregisteredTableColumns = () => [
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: false,
      fixed: "left",
      width: 150,
    },
    {
      title: "Date Created",
      dataIndex: "date",
      key: "date",
      align: "center",
      sorter: true,
    },
    {
      title: "",
      dataIndex: "more",
      key: "more",
      render: (more, item) =>
        item && item.customer_name ? (
          <Link
            to={`/monetization/${app ? app.id : props.appId
              }/user-unregistered/${item.customer_name}`}
          >
            More
          </Link>
        ) : (
          ""
        ),
    },
  ];

  useEffect(() => {
    fetch({
      results: 10,
      page: 1,
      sortField: "date",
      sortOrder: "descend",      
    });
    fetchUnregistered({
      results: 10,
      page: 1,
      sortField: "date",
      sortOrder: "descend",      
    });
  }, [app.id, useDemoData]);

  const fetch = async (params = {}) => {
    if (!useDemoData)
      try {
        if (!!app) {
          setLoading(true);
          const paymentsId = [
            app.alipay_id || "",
            app.wechat_id || "",
            app.seccond_alipay_id || "",
            app.seccond_wechat_id || "",
          ].join(",");
          let response = await getAllUsers({
            ...params,
            id: paymentsId,
            app,
          });
          setLoading(false);
          if (response) {
            setPagination({
              total: response.total_results,
            });
            setValues(response.users);
          }
        }
      } catch (error) {
        console.log(error);
      }
  };

  const fetchUnregistered = async (params = {}) => {
    if (!useDemoData)
      try {
        if (!!app) {
          setUnregisteredLoading(true);
          const paymentsId = [
            app.alipay_id || "",
            app.wechat_id || "",
            app.seccond_alipay_id || "",
            app.seccond_wechat_id || "",
          ].join(",");
          let response = await getAllUnregistered({
            ...params,
            id: paymentsId,
            app,
          });
          setUnregisteredLoading(false);
          if (response) {
            setUnregisteredPagination({
              total: response.total_results,
            });
            setUnregistered(response.users);
          }
        }
      } catch (error) {
        console.log(error);
      }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setFilters(filters);
    setSorters(sorter.field);
    setSortOrder(sorter.order);
    setPagination(pager);
    fetch({
      results: pagination.pageSize,
      page: pagination.current,
      rangeDate: { start: start_date, end: end_date },
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  const handleUnregisteredTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setUnregisteredFilters(filters);
    setUnregisteredSorters(sorter.field);
    setUnregisteredSortOrder(sorter.order);
    setUnregisteredPagination(pager);
    fetchUnregistered({
      results: pagination.pageSize,
      page: pagination.current,
      rangeDate: { start: start_date, end: end_date },
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };
  const handleSearchUser = async (value) => {
    if (value.trim() != "") {
      setUseAsync(false);
      setDisabled(false);
      setLoading(true);
      const paymentsId = [
        app.alipay_id || "",
        app.wechat_id || "",
        app.seccond_alipay_id || "",
        app.seccond_wechat_id || "",
      ].join(",");
      let result = await getUser(value, paymentsId, app);
      setLoading(false);
      if (result) {
        setValues(result.user);
        setPagination({
          total: result.user.length,
        });
      }
    }
  };
  const handleSearchCustomerName = async (value) => {
    if (value.trim() != "") {
      setUseAsync(false);
      setDisabled(false);
      setUnregisteredLoading(true);
      const paymentsId = [
        app.alipay_id || "",
        app.wechat_id || "",
        app.seccond_alipay_id || "",
        app.seccond_wechat_id || "",
      ].join(",");
      let result = await getUsersByCustomerName(value, paymentsId, app);
      setUnregisteredLoading(false);
      if (result) {
        setUnregistered(result.user);
        setUnregisteredPagination({
          total: result.user.length,
        });
      }
    }
  };
  const onChangeDate = (date, [start, end]) => {
    setStartDate(start);
    setEndDate(end);
    setDisabled(false);
    fetch({
      results: 10,
      page: 1,
      rangeDate: { start, end },
      sortField: sorters,
      sortOrder: sortOrder,
    });
    fetchUnregistered({
      results: 10,
      page: 1,
      rangeDate: { start, end },
      sortField: unregisteredSorters,
      sortOrder: unregisteredSortOrder,
    });
  };
  const handleRequest = () => {
    const { filter, value } = searchFilter;
    if (filter) {
      if (filter === "user") {
        handleSearchUser(value);
      } else if (filter === "customer_name") {
        handleSearchCustomerName(value);
      }
    }
  };

  const resetFilter = () => {
    setSearch({ filter: undefined, value: null });
    setStartDate(null);
    setEndDate(null);
    setDisabled(true);
    setUseAsync(true);
    fetch({
      results: 10,
      page: 1,
      sortField: sorters,
      sortOrder: sortOrder,
      rangeDate: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
    });
    fetchUnregistered({
      results: 10,
      page: 1,
      sortField: unregisteredSorters,
      sortOrder: unregisteredSortOrder,
      rangeDate: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
    });
  };

  return (
    <Fragment>
      <div className={classes.tableContainer}>
        <div />
        <Row gutter={16}>
          <Col
            style={{ marginTop: 12 }}
            xs={24}
            sm={24}
            md={18}
            lg={12}
            xl={10}
          >
            <Row gutter={8}>
              {" "}
              <Col span={8}>
                <Select
                  disabled={loading}
                  loading={loading}
                  value={searchFilter.filter}
                  placeholder="Search By"
                  style={{ width: "100%" }}
                  onChange={(val) =>
                    setSearch({ ...searchFilter, filter: val })
                  }
                >
                  {searchOptions.map(({ label, value }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={16}>
                <Search
                  disabled={loading || !searchFilter.filter}
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setSearch({ ...searchFilter, value: e.target.value })
                  }
                  onSearch={handleRequest}
                  placeholder="Type here..."
                  value={searchFilter.value}
                />
              </Col>
            </Row>
          </Col>
          <Col
            style={{ marginTop: 12 }}
            xs={18}
            sm={11}
            md={11}
            lg={10}
            xl={10}
          >
            <RangePicker
              style={{ width: "100%" }}
              value={start_date && end_date ? [moment(start_date), moment(end_date)] : null}
              onChange={onChangeDate}
              format={"YYYY-MM-DD"}
              disabled={loading}
              allowClear={false}
            />
          </Col>
          <Col style={{ marginTop: 12 }} xs={6} sm={5} md={5} lg={4} xl={4}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={() => resetFilter()}
              disabled={disabled}
              loading={loading}
            >
              Reset
            </Button>
          </Col>
        </Row>

        <div>
          {(values && values.length && values.length > 0) || useDemoData ? (
            <Table
              title={() => "Users By Phone Number"}
              dataSource={useDemoData ? users_table_fake : values}
              rowKey={(record) => record.mobile_no}
              columns={tableColumns()}
              size="small"
              pagination={pagination}
              onChange={useAsync ? handleTableChange : () => { }}
              loading={loading}
              scroll={{ x: 700 }}
            />
          ) : (
            <List loading={loading} header={<div>Users By Phone Number</div>} dataSource={[]} />
          )}
        </div>
        <div>
          {(unregistered && unregistered.length && unregistered.length > 0) ||
            useDemoData ? (
            <Table
              title={() => "Users By User Name"}
              dataSource={useDemoData ? users_table_fake : unregistered}
              rowKey={(record) => record.customer_name}
              columns={unregisteredTableColumns()}
              size="small"
              pagination={unregisteredPagination}
              onChange={useAsync ? handleUnregisteredTableChange : () => { }}
              loading={unregisteredLoading}
            />
          ) : (
            <List loading={unregisteredLoading} header={<div>Users By User Name</div>} dataSource={[]} />
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default Users;
