import settings from "./assets/settings.svg";
import companyLogo from "./assets/logo.png";
import companyLogoWhite from "./assets/logo-white.png";
import preloader from "./assets/infinite-loader.gif";
import imgIcon from "./assets/image.svg";
import fileIcon from "./assets/file.svg";
import videoIcon from "./assets/video.svg";
import lockIcon from "./assets/lock.svg";
import warningIcon from "./assets/warning.svg";
import infoIcon from "./assets/info.svg";
import closeIcon from "./assets/close.svg";
import addUsers from "./assets/add-user.svg";
import back from "./assets/back.svg";
import companyLogoSmallWhite from "./assets/logo-w.png";
import logoGreen from "./assets/logo-green.png";
import call from "./assets/call.png";
import documentHand from "./assets/documentHand.png";
import documentHand1 from "./assets/documentHand1.png";
import documentHand2 from "./assets/documentHand2.png";
import bankPermit from "./assets/bankPermit.jpg";
import badgeCn from "./assets/badge-cn.png";
import badgeEn from "./assets/badge-en.png";
import badgeGp from "./assets/badge-gp.png";
import badgeIos from "./assets/badge-ios.png";
import allBadges from "./assets/all-badges.png";

const allImages = {
  preloader,
  settings,
  companyLogo,
  companyLogoWhite,
  fileIcon,
  imgIcon,
  lockIcon,
  warningIcon,
  closeIcon,
  addUsers,
  back,
  companyLogoSmallWhite,
  call,
  infoIcon,
  bankPermit,
  documentHand,
  documentHand1,
  documentHand2,
  videoIcon,
  logoGreen,
};

export {
  preloader,
  settings,
  companyLogo,
  companyLogoWhite,
  fileIcon,
  imgIcon,
  lockIcon,
  warningIcon,
  closeIcon,
  addUsers,
  back,
  companyLogoSmallWhite,
  call,
  infoIcon,
  bankPermit,
  documentHand,
  videoIcon,
  badgeCn,
  badgeEn,
  badgeGp,
  badgeIos,
  allBadges,
  logoGreen,
  documentHand1,
  documentHand2,
};

export default allImages;
