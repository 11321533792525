import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import FormStepper from "../FormStepper/FormStepper";
import classes from "./EditAppInformation.module.less";
import { getOrCreateDraft } from "../../../../services/distributionService";
import LoadingSpin from "../../../UI/LoadingSpin/LoadingSpin";

const EditAppInformation = ({ app, haveDrafts = false, tab }) => {
  let [draftInfo, setDraftInfo] = useState(null);
  let [loading, setLoading] = useState(false);
  let [lastVersion, setLastVersion] = useState({});
  const isOwner = app.role === "OWNER" || app.role === "ADMIN";

  useEffect(() => {
    getData();
  }, [app]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await getOrCreateDraft(app.id, false);
      if (data.response) {
        setDraftInfo(data.response.draft);
        setLastVersion(data.response.latest_version);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getHighestCompletedStep = (data) => {
    for (let i = 6; i >= 0; i--) {
      if (data[`step${i}_finished`]) return i + 1;
    }
    return 0;
  };

  return isOwner ? (
    <div className={classes.container}>
      {loading ? (
        <LoadingSpin />
      ) : draftInfo ? (
        <FormStepper
          app={app}
          lastSubmition={lastVersion}
          draft={draftInfo}
          initialStep={getHighestCompletedStep(draftInfo)}
          is_apk_update={false}
          getData={getData}
          loadingInfo={loading}
        />
      ) : null}
    </div>
  ) : (
    <Redirect to={`/distribution/${app.id}/app-information`} />
  );
};

export default EditAppInformation;
