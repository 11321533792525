import React, { useState, Fragment } from "react";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import { Icon, Row, Button } from "antd";
import functions, { downloadCSV } from "../../../../functions";
import classes from "./DownloadsCard.module.less";
import LoadingSpin from "../../../UI/LoadingSpin/LoadingSpin";
export default ({ data, rawData, description, title, loading }) => {
  let [loadingCsv, setLoadingCsv] = useState(false);

  const downloadAsCSV = () => {
    let csvData = [
      ["Date", "New Downloads", "Update Downloads", "Total Downloads"]
    ];
    rawData.forEach(row => {
      csvData.push([row.date, row.new, row.update, row.total]);
    });
    let totals = [
      rawData.reduce(
        (acc, curr) => [
          acc[0] + curr.new,
          acc[1] + curr.update,
          acc[2] + curr.total
        ],
        [0, 0, 0]
      )
    ];
    csvData.push(["TOTAL", ...totals]);
    try {
      setLoadingCsv(true);
      downloadCSV(csvData, "downloads_per_day.csv");
      setTimeout(() => setLoadingCsv(false), 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.Container}>
      <div className={classes.Header}>
        <p className={classes.Title}>{title}</p>
        {description ? (
          <Tooltip placement="bottomLeft" title={description}>
            <Icon type="question-circle" />
          </Tooltip>
        ) : null}
      </div>
      {loading ? (
        <div style={{ height: 400 }}>
          <LoadingSpin />
        </div>
      ) : (
        <Fragment>
          <Chart
            padding={80}
            height={400}
            data={data}
            forceFit
            style={{
              position: "relative",
              top: "-30px",
              left: "30px"
            }}
          >
            <Axis name="date" />
            <Axis
              name="downloads"
              label={{ formatter: val => functions.styleAsNumber(val) }}
            />
            <Legend
              useHtml={true}
              g2-legend-list={{
                border: "none",
                height: "auto",
                marginBottom: "30px"
              }}
            />
            <Tooltip
              crosshairs={{
                type: "y"
              }}
            />
            <Geom
              type="line"
              position="date*downloads"
              size={2}
              color={"title"}
              shape={"smooth"}
              tooltip={[
                "title*downloads",
                (title, downloads, something) => {
                  downloads = functions.styleAsNumber(downloads);
                  return {
                    name: title,
                    value: downloads
                  };
                }
              ]}
            />
          </Chart>
          <Row type="flex" justify="end">
            <Button
              icon="download"
              onClick={() => downloadAsCSV()}
              type="primary"
              width="150px"
              disabled={loadingCsv}
            >
              Download as CSV
            </Button>
          </Row>
        </Fragment>
      )}
    </div>
  );
};
