import React from "react";
import classes from "./Database.module.less";
import { Button, Row, Col } from "antd";
import history from "../../../../history";
import database from "../../../../assets/database.png";

const Card = ({ img, title, description }) => {
  return (
    <div className={classes.card}>
      <img src={img} alt="" />
      <div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Database = ({ appId, ...props }) => {
  return (
    <div className={classes.container}>
      <p>
        Alibaba Cloud offers a set of fully managed, less trouble, and optimized
        database services that fully support open-source database engines.
      </p>
      <p>
        AppInChina currently offers MySQL and PostgresSQL options through
        Alibaba Cloud China
      </p>
      <Row>
        <h2 className={classes.subtitle}>Features</h2>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Card
            img={database}
            title="Scale Up Read Capacity On-Demand"
            description="Alibaba Cloud Elastic Compute Service (ECS) provides fast memory and the
            latest Intel CPUs to help you to power your cloud applications and
            achieve faster results with low latency."
          />
        </Col>
        <Col span={12}>
          <Card
            img={database}
            title="Support Database Engines for Different Application Needs"
            description="Alibaba Cloud Elastic Compute Service (ECS) provides fast memory and the
            latest Intel CPUs to help you to power your cloud applications and
            achieve faster results with low latency."
          />
        </Col>
        <Col span={12}>
          <Card
            img={database}
            title="Commercial-Grade Audit and Recovery"
            description="Alibaba Cloud Elastic Compute Service (ECS) provides fast memory and the
            latest Intel CPUs to help you to power your cloud applications and
            achieve faster results with low latency."
          />
        </Col>
        <Col span={12}>
          <Card
            img={database}
            title="Intelligent Analysis and Optimization Report"
            description="Alibaba Cloud Elastic Compute Service (ECS) provides fast memory and the
            latest Intel CPUs to help you to power your cloud applications and
            achieve faster results with low latency."
          />
        </Col>
      </Row>
      <Row type="flex" justify="center" style={{ marginTop: 20 }}>
        <Button
          onClick={() => history.push(`/cloud/shop/database/${appId}`)}
          type="primary"
        >
          Select Configuration
        </Button>
      </Row>
    </div>
  );
};
export default Database;
