import React, { useState, useEffect } from "react";
import { Card, Row, Col, Select, DatePicker } from "antd";
import MultilineChart from "../../UI/Charts/MultiLineChart";
import UsersTable from "../UsersRetention/UsersTable/UsersTable";
import moment from "moment";
import UncontractedMessage from "../UncontractedAppPromotion/UncontractedMessage";
import { getUsersRetention } from "../../../services/promotionService";
import { getDate } from "../../../functions";

const { Option } = Select;
export default ({ app, useDemoData }) => {
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState({});
  let [store, setStore] = useState(null);
  let [version, setVersion] = useState(null);
  let [date, setDate] = useState(getDate());
  let [allVersions, setAllVersions] = useState([]);
  let [allStores, setAllStores] = useState([]);
  let [day, setDay] = useState(1);
  const makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getUsersRetention({
      promotionId: app.analitycs_id,
      version,
      store,
      startDate: date[0].format("YYYY-MM-DD"),
      endDate: date[1].format("YYYY-MM-DD"),
      app: app.id,
    });
    setAllVersions(response.versions);
    setAllStores(response.stores);
    const chartData = response.data.tableData.map(({ date, ...data }) => ({
      date,
      event_count: data["1_day"] || 0,
      event: "1",
    }));
    setData({ ...response.data, chartData });
    setLoading(false);
  };
  const days = [1, 2, 3, 4, 5, 6, 7, 14, 30];

  const fakeData = (events = ["New Users"]) => {
    return events.reduce((prev, event) => {
      const daysAgo = moment().subtract(7, "days");
      const fakeData = Array(7)
        .fill(null)
        .map(() => ({
          date: daysAgo.add(1, "day").format("YYYY-MM-DD"),
          event,
          event_count: Math.floor(Math.random() * 100),
        }));
      return [...prev, ...fakeData];
    }, []);
  };

  let random = () => {
    return Math.random() * (100 - 0) + 0;
  };

  let tableData = Array(7)
    .fill(null)
    .map((_, index) => {
      return {
        date: moment()
          .subtract(index, "days")
          .format("YYYY-MM-DD"),
        new_users: random().toFixed(0),
        "1_day": random().toFixed(2),
        "2_day": random().toFixed(2),
        "3_day": random().toFixed(2),
        "4_day": random().toFixed(2),
        "5_day": random().toFixed(2),
        "6_day": random().toFixed(2),
        "7_day": random().toFixed(2),
        "14_day": random().toFixed(2),
        "30_day": random().toFixed(2),
      };
    });

  const validateRequest = () => {
    if (!useDemoData) {
      makeRequest();
    } else {
      setLoading(true);
      setTimeout(() => {
        setData({ chartData: fakeData(), tableData: tableData });
        setLoading(false);
        setAllVersions([
          {
            value: null,
            label: "All Versions",
          },
        ]);
        setAllStores([
          {
            value: null,
            label: "All Stores",
          },
        ]);
      }, 500);
    }
  };

  const handleDayChange = (day) => {
    setDay(day);
    const chartData = data.tableData
      ? data.tableData.map(({ date, ...data }) => ({
          date,
          event_count: data[`${day}_day`],
          event: day,
        }))
      : [];
    setData({
      ...data,
      chartData,
    });
  };

  useEffect(() => {
    validateRequest();
  }, [app, date, version, store]);

  return (
    <>
      <UncontractedMessage app={app} useDemoData={useDemoData} />
      <Card title="Retained Users">
        <Row gutter={16}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            lg={{ span: 10 }}
            style={{ marginBottom: 15 }}
          >
            <DatePicker.RangePicker
              onChange={setDate}
              value={date}
              style={{ width: "100%" }}
            />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 6 }}
            lg={{ span: 4 }}
            style={{ marginBottom: 15 }}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Version"
              defaultValue={null}
              onChange={setVersion}
            >
              {allVersions.map(({ value, label }) => (
                <Select.Option value={value}>{label}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 6 }}
            lg={{ span: 4 }}
            style={{ marginBottom: 15 }}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="App Store"
              defaultValue={null}
              onChange={setStore}
            >
              {allStores.map(({ value, label }) => (
                <Select.Option value={value}>{label}</Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <UsersTable loading={loading} data={data.tableData} />
        <Row gutter={16} style={{ marginTop: 15 }}>
          <Col offset={20} span={4}>
            <Select
              style={{ width: "100%" }}
              placeholder="Range"
              value={day}
              onChange={handleDayChange}
            >
              {days.map((day) => (
                <Option value={day}>
                  {day}
                  {+day === 1 ? " day" : " days"}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <MultilineChart loading={loading} data={data.chartData} />
      </Card>
    </>
  );
};
