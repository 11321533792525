import React from "react";
import { Chart, Geom, Axis, Tooltip, Coord } from "bizcharts";
import DataSet from "@antv/data-set";
import { Radio } from "antd";
import { devices } from "../../fakeData";
const total = data =>
  data.reduce((prev, curr) => prev + curr.manofacturer_count, 0);

export default class extends React.Component {
  state = {
    order: "percentage"
  };
  render() {
    const totalPercentaje = total(this.props.data);
    let data = this.props.useDemoData
      ? devices
      : this.props.data.map(item => ({
          store: item.device_manofacturer,
          percentage: (item.manofacturer_count / totalPercentaje) * 100
        }));

    const ds = new DataSet();
    const dv = ds.createView().source(data);
    let order = this.state.order;

    dv.source(data).transform({
      type: "sort",
      callback(a, b) {
        if (a[order] > b[order]) return order === "percentage" ? 1 : -1;
        else if (a[order] < b[order]) return order === "percentage" ? -1 : 1;
        return 0;
      }
    });

    return (
      <div>
        <div>
          <b>Sort by: </b>
          <Radio.Group
            value={this.state.order}
            size="small"
            onChange={e => this.setState({ order: e.target.value })}
          >
            <Radio.Button value="percentage">Percentage</Radio.Button>
            <Radio.Button value="store">Store name</Radio.Button>
          </Radio.Group>
        </div>
        <Chart height={400} data={dv} forceFit style={{ marginTop: 20 }}>
          <Coord transpose />
          <Axis
            name="store"
            label={{
              offset: 12
            }}
          />
          <Axis name="percentage" label={{ formatter: val => `${val}%` }} />
          <Tooltip
            crosshairs={{
              type: "y"
            }}
          />
          <Geom
            type="interval"
            position="store*percentage"
            color={"store"}
            tooltip={[
              "store*percentage",
              (store, percentage) => {
                percentage = `${percentage}%`;
                return {
                  value: percentage
                };
              }
            ]}
          />
        </Chart>
      </div>
    );
  }
}
