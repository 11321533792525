import React, { useState, useEffect } from "react";
import { Tabs, Button, Modal } from "antd";
import SubmissionStatus from "./SubmissionStatus/SubmissionStatus";
import SubmitNewVersion from "./SubmitNewVersion/SubmitNewVersion";
import EditAppInformation from "./EditAppInformation/EditAppInformation";
import AppDocuments from "./AppDocuments/AppDocuments";
import ApkReclaim from "./ApkReclaim/ApkReclaim";
import classes from "./AppFormHome.module.less";
import history from "../../../history";
import { deleteFormSubmission } from "../../../services/distributionService";
import { getForm, getDotTabs } from "../../../services/distributionService";
import NewFeature from "../../UI/NewFeature/AsyncNewFeature";
import LineCard from "../../UI/LineCard/LineCard";
import LoadingSpin from "../../UI/LoadingSpin/LoadingSpin";
const { TabPane } = Tabs;
const { confirm } = Modal;
const AppFormHome = ({ app, tab, appId }) => {
  let [key, setKey] = useState("distribution_status");
  let [form, setForm] = useState({});
  let [isOpen, openStepper] = useState(false);
  let [showIntro, setShowIntro] = useState(false);
  let [section, setSection] = useState(null);
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [tabsDots, setTabsDots] = useState({});
  let [isOwnerOrAdmin, setIsOwner] = useState(false);

  const deleteHandler = async (id) => {
    try {
      const { data: response } = await deleteFormSubmission(id);
      if (response.message === "success") {
        getData();
      }
    } catch (error) {}
  };

  const continueSubmition = (item) => {
    if (item.is_apk_update) {
      setForm(item);
      setSection("new");
    } else {
      setForm(item);
      setSection("edit");
    }
    history.push(`/distribution/${app.id}/app-information/submit_new_version/`);
    openStepper(true);
  };

  const showConfirmEditInformation = () => {
    confirm({
      title:
        "Your new version will no be distributed until you submit the entire form",
      onOk() {
        console.log("OK");
        setSection("edit");
      },
      onCancel() {},
    });
  };
  const getData = async () => {
    setLoading(true);
    let { data: app_forms } = await getForm("app-form", appId);
    getTabs();
    setLoading(false);
    if (app_forms) {
      setData(app_forms);
    }
  };

  useEffect(() => {
    setKey(tab || "distribution_status");
    if (tab === "submission_history") {
      setData(data.filter((it) => it.is_draft === false));
      getData();
    }
    if (tab !== "submit_new_version") {
      setSection(null);
    }
    autoOpenDraft();
  }, [tab]);

  useEffect(() => {
    autoOpenDraft();
  }, [data]);

  let autoOpenDraft = () => {
    if (tab === "submit_new_version") {
      let tmpDraft = data.find((it) => !!it.is_draft);
      if (tmpDraft) {
        continueSubmition(tmpDraft);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("new.app.form.modal")) {
      setShowIntro(true);
    }
    getData();
  }, []);

  useEffect(() => {
    let val = app.role == "OWNER" || app.role == "ADMIN" ? true : false;
    setIsOwner(val);
  }, [app.id]);

  const haveDrafts = () => {
    const draft = data ? data.find((it) => it.is_draft === true) : null;
    return !!draft;
  };

  const handleTabChange = (e) => {
    history.push(`/distribution/${app.id}/app-information/${e}`);
  };

  const getTabs = async () => {
    const { data } = await getDotTabs(appId);
    setTabsDots(data);
  };

  return (
    <div className={classes.container}>
      <Tabs
        activeKey={key}
        defaultActiveKey="submission_history"
        onChange={handleTabChange}
      >
        <TabPane tab="Submission History" key="submission_history">
          <SubmissionStatus
            loading={loading}
            data={data || []}
            app={app}
            continueSubmition={continueSubmition}
            deleteHandler={deleteHandler}
            isOwnerOrAdmin={isOwnerOrAdmin}
          />
        </TabPane>
        {isOwnerOrAdmin ? (
          <TabPane tab="New Submission" key="submit_new_version">
            {section == null ? (
              !loading ? (
                <div className={classes.options}>
                  <LineCard title="New Version">
                    <p>
                      Please submit your new apk version in this section. The
                      information you submit here will be published across each
                      of the Chinese Android app stores covered by your package.
                      You can either begin a new submission or continue an
                      unfinished submission by pressing the button below.
                    </p>
                    <div className={classes.button}>
                      <Button
                        onClick={() => setSection("new")}
                        type="primary"
                        icon="arrow-right"
                      >
                        {haveDrafts()
                          ? "Continue Submition"
                          : "Submit New Version"}
                      </Button>
                    </div>
                  </LineCard>
                  <LineCard
                    title="Edit Information"
                    disabled={!data.filter((it) => !it.is_draft).length}
                  >
                    <p>
                      You are able to edit your app information without
                      submitting a new apk in this section. Changes will be
                      applied without counting towards your annual update limit.
                      Click Edit App Information to begin.
                    </p>
                    <div className={classes.button}>
                      <Button
                        onClick={() => showConfirmEditInformation()}
                        type="primary"
                        icon="arrow-right"
                        disabled={!data.filter((it) => !it.is_draft).length}
                      >
                        Edit App Information
                      </Button>
                    </div>
                    {!data.filter((it) => !it.is_draft).length ? (
                      <i className={classes.disabledLabel}>
                        You need to submit a new version at least once to edit
                        your app information.
                      </i>
                    ) : null}
                  </LineCard>
                </div>
              ) : (
                <LoadingSpin />
              )
            ) : null}
            {section === "new" ? (
              <SubmitNewVersion app={app} showStepper={isOpen} />
            ) : null}
            {section === "edit" ? (
              <EditAppInformation
                app={app}
                showStepper={isOpen}
                haveDrafts={haveDrafts()}
                tab={key}
              />
            ) : null}
          </TabPane>
        ) : null}
        <TabPane
          tab={
            <NewFeature
              style={{ right: -10, top: 0 }}
              show={tabsDots.apk_reclaim}
            >
              APK Reclaim
            </NewFeature>
          }
          key="apk_reclaim"
        >
          <ApkReclaim appId={app.id} isOwner={isOwnerOrAdmin} />
        </TabPane>
        <TabPane
          tab={
            <NewFeature
              style={{ right: -10, top: 0 }}
              show={tabsDots.documents}
            >
              Documents
            </NewFeature>
          }
          key="documents"
        >
          <AppDocuments appId={app.id} isOwner={isOwnerOrAdmin} />
        </TabPane>
      </Tabs>
    </div>
  );
};
export default AppFormHome;
