import moment from "moment";
import axios from "../axios";

export const getSummary = async ({ promotionId, app }) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/summary?id=${promotionId}&app=${app}&date=${moment().format(
        "YYYY-MM-DD"
      )}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getOverralSummary = async ({ promotionId, days, app }) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/overral-summary?promotionId=${promotionId}&app=${app}&days=${days}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getSummaryPerDay = async ({
  promotionId,
  first,
  second,
  app,
  store,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/data-comparisson-day?promotionId=${promotionId}&app=${app}&start=${first}&end=${second}&store=${store}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getSummaryPerHour = async ({
  promotionId,
  first,
  second,
  app,
  store,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/data-comparisson-hour?promotionId=${promotionId}&app=${app}&first_day=${first}&seccond_day=${second}&store=${store}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getUsersSource = async ({
  promotionId,
  start,
  end,
  app,
  store,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/users-source?promotionId=${promotionId}&app=${app}&start=${start}&end=${end}&store=${store}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getUsersPerDay = async ({
  start,
  end,
  version,
  promotionId,
  store,
  app,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/users-per-day?promotionId=${promotionId}&app=${app}&start=${start}&end=${end}&version=${version}&store=${store}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getAmountSpent = async (app) => {
  let response = null;
  try {
    response = await axios.get(`/app-promotion/amount-spent?app=${app}`);
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getExpensesPerDay = async ({ start, end, app }) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/expenses-per-day?app=${app}&start=${start}&end=${end}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getDataPerStore = async ({
  promotionId,
  start,
  end,
  version,
  app,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/data-per-store?promotionId=${promotionId}&app=${app}&start=${start}&end=${end}&version=${version}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getDataPerVersion = async ({
  promotionId,
  start,
  end,
  version,
  app,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/data-per-version?promotionId=${promotionId}&app=${app}&start=${start}&end=${end}&version=${version}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getTrendVersion = async ({
  promotionId,
  start,
  end,
  store,
  app,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/trend-per-version?promotionId=${promotionId}&app=${app}&start=${start}&end=${end}&store=${store}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getDataVersion = async ({
  promotionId,
  start,
  end,
  app,
  version,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/data-per-version?promotionId=${promotionId}&app=${app}&start=${start}&end=${end}&version=${version}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getTrendPerStore = async ({
  promotionId,
  start,
  end,
  version,
  app,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/trend-per-store?promotionId=${promotionId}&app=${app}&start=${start}&end=${end}&version=${version}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getUsersRetention = async ({
  promotionId,
  version,
  store,
  startDate,
  endDate,
  app,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/users-retention?promotionId=${promotionId}&version=${version}&store=${store}&startDate=${startDate}&endDate=${endDate}&app=${app}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
//&app=${app}
export const getVersionsTableService = async ({ promotionId, app }) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/versions-table?promotionId=${promotionId}&app=${app}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getPaidUsersSummary = async ({ promotionId, app }) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/paid-users-summary?promotionId=${promotionId}&app=${app}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getPaidUsersTable = async ({
  promotionId,
  store,
  startDate,
  endDate,
  app
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/paid-users-table?promotionId=${promotionId}&store=${store}&startDate=${startDate}&endDate=${endDate}&app=${app}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getPaidUsersChart = async ({
  promotionId,
  version,
  store,
  startDate,
  endDate,
  app,
}) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/paid-users-chart?promotionId=${promotionId}&version=${version}&store=${store}&startDate=${startDate}&endDate=${endDate}&app=${app}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getUsersRetentionSummary = async ({ promotionId, store, app }) => {
  let response = null;
  try {
    response = await axios.get(
      `/app-promotion/users-retention-summary?promotionId=${promotionId}&store=${store}&app=${app}`
    );
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
