import React from "react";
import classes from "./AppTesting.module.less";
import ApkInspector from "./ApkInspector/ApkInspector";
//import SDKSearch from "./SDKSearch/SDKSearch";
//import AddendumFile from "./AddendumFile/AddendumFile";
const AppTesting = ({ ...props }) => {
  return (
    <div className={classes.Container}>
      <p>
        Many commonly used SDKs are blocked in China. The first step of
        localization is therefore to test your app and understand which SDKs
        need to be replaced with local alternatives.
      </p>
      <ApkInspector {...props} />
    </div>
  );
};

export default AppTesting;
