import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import classes from "./PayPalButton.module.less";
import { notification } from "antd";
import history from "../../../../history";
const PayPal = ({ appId, total, createOrder }) => {
  return (
    <div className={classes.container}>
      <PayPalButton
        amount="0.01"
        style={{
          color: "blue"
        }}
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        options={{
          clientId:
            "ASgF7wh67NOiYkU2zItpCgc5AhsyzI2imgvHPzBRvWTsREOFLCdnR_4BeeWIXsfrmaXsXVaC3oA20ORW"
        }}
        onError={error => {
          console.log("error", error);

          notification.error({
            message: `An error has ocurred ${error}`,
            placement: "bottomLeft"
          });
        }}
        onSuccess={(details, data) => {
          let paypal_amount = details.purchase_units[0].amount.value;
          let localStorageCopy = localStorage.cloud_cart
            ? JSON.parse(localStorage.cloud_cart)
            : [];
          let orders =
            localStorageCopy && localStorageCopy.orders
              ? localStorageCopy.orders
              : [];
          orders.length > 0
            ? (orders = orders.map(it => {
                let values = {};
                it.description.map(({ key, value }) => {
                  values[key] = value;
                });
                return {
                  ...it,
                  paypal_callback_string: JSON.stringify(details),
                  product_specifications: JSON.stringify(values)
                };
              }))
            : (orders = orders);
          createOrder(orders);
          notification.success({
            title: "Successful Payment",
            message:
              "You will be able to configure your order once our team has approved it.",
            placement: "bottomLeft"
          });
          //history.push(`/cloud/summary/${appId}`);
        }}
      />
    </div>
  );
};

export default PayPal;
