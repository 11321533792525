import axios from "../axios";
export const getAllBadge = async (start = null, end = null, app = null) => {
  try {
    const queryString =
      app !== null
        ? `/badge-tracking?app=${app}&start=${start}&end=${end}`
        : `/badge-tracking?start=${start}&end=${end}`;

    return await axios.get(queryString);
  } catch (error) {
    return null;
  }
};
export const getBadgePagesByApp = async (start, end, app) => {
  try {
    const [_, timezone] = new Date().toString().match(/([-\+][0-9]+)\s/);
    return await axios.get(
      `/badge-pages/${app}?start=${start}&end=${end}&tz=${encodeURIComponent(
        timezone
      )}`
    );
  } catch (error) {
    return null;
  }
};
