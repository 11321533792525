import React, { useState } from "react";
import { Modal, Button, Input, Form, notification } from "antd";
import { changePassword } from "../../../services/acccountService";

export default Form.create({ name: "change_password" })(
  ({ form, ...props }) => {
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator } = form;
    const openNotificationWithIcon = (type, message) => {
      notification[type]({
        message: message,
        placement: "bottomLeft"
      });
    };
    const makeRequest = async values => {
      let data = {
        password: values.password,
        newPassword: values.newPassword
      };
      setLoading(true);
      let response = await changePassword(data);
      setLoading(false);
      if (response.data && response.data.message === "success") {
        openNotificationWithIcon("info", "Password changed successfully");
        props.close();
      } else if (response.data && response.data.message === "incorrect") {
        openNotificationWithIcon("warning", "Incorrect information");
      } else {
        openNotificationWithIcon(
          "warning",
          "There was an error while connecting to the server"
        );
        throw response.data;
      }
    };
    const handleSubmit = event => {
      event.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          makeRequest(values);
        }
      });
    };
    const compareToFirstPassword = (rule, value, callback) => {
      if (value && value !== form.getFieldValue("newPassword")) {
        callback("Two passwords that you enter is inconsistent!");
      } else {
        callback();
      }
    };
    return (
      <Modal
        title="Change Password"
        visible={props.display}
        onCancel={props.close}
        footer={null}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Password">
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Required" },
                {
                  pattern: /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
                  message:
                    "Must be at least 10 characters long and contain at least one lower case letter, one upper case letter, a number and an special character"
                }
              ],
              validateTrigger: "onSubmit"
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="New Password">
            {getFieldDecorator("newPassword", {
              rules: [
                { required: true, message: "Required" },
                {
                  pattern: /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
                  message:
                    "Must be at least 10 characters long and contain at least one lower case letter, one upper case letter, a number and an special character"
                }
              ],
              validateTrigger: "onSubmit"
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Repeat Password">
            {getFieldDecorator("repeatPassword", {
              rules: [
                { required: true, message: "Required" },
                { validator: compareToFirstPassword }
              ],
              validateTrigger: "onSubmit"
            })(<Input.Password />)}
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              loading={loading}
              disable={loading}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
);
