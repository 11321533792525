import React from "react";
import { Table } from "antd";

const pages = [
  "goods/list",
  "map/amap",
  "order/detail",
  "order/confirm",
  "me/login",
  "me/index",
  "order/list",
  "me/change"
];

export default ({ useDemoData, data }) => {
  const fakeData = pages.map((page, index) => ({
    key: index,
    page,
    total_visits: Math.floor(Math.random() * 5000) + 1,
    total_users: Math.floor(Math.random() * 5000) + 1,
    visit_time: (Math.random() * 100 + 1).toFixed(2),
    page_exists: Math.floor(Math.random() * 5000) + 1,
    exit_percent: (Math.random() * 100 + 1).toFixed(2)
  }));
  let tableData = useDemoData ? fakeData : data;
  const columns = [
    {
      title: "Page Name",
      dataIndex: "page",
      key: "page",
      fixed: "left",
      width: 200
    },
    {
      title: "Total Visits",
      dataIndex: "total_visits",
      key: "total_visits"
    },
    {
      title: "Total Users",
      dataIndex: "total_users",
      key: "total_users"
    }
  ];

  return (
    <div>
      <Table
        size="small"
        style={{ marginTop: 20 }}
        columns={columns}
        dataSource={tableData}
        scroll={{ x: 500 }}
      />
    </div>
  );
};
