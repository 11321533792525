import React, { useState, useEffect } from "react";
import {
  Steps,
  Button,
  Row,
  Form,
  Modal,
  notification,
  message,
  Tooltip,
} from "antd";
import UploadApk from "./FormSteps/UploadApk/UploadApk";
import Screenshots from "./FormSteps/Screenshots/Screenshots";
import AppInformation from "./FormSteps/AppInformation/AppInformation";
import DistributionDetails from "./FormSteps/DistributionDetails/DistributionDetails";
import PrivacyPolicy from "./FormSteps/PrivacyPolicy/PrivacyPolicy";
import Preview from "./FormSteps/Preview/Preview";
import classes from "./FormStepper.module.less";
import Submit from "./FormSteps/Submit/Submit";
import {
  updateForm,
  submitForm,
} from "../../../../services/distributionService";
import { compareImages } from "../../../../functions";
const { Step } = Steps;

var timeout = 0;

const FormStepper = ({
  form,
  app,
  initialStep,
  draft,
  is_apk_update,
  lastSubmition,
  getData,
  loadingInfo,
}) => {
  const [showingmessage, setShowingMessage] = useState(false);
  const [status, setStatus] = useState("process");
  const [step, setStep] = useState(initialStep);
  const [formId, setFormId] = useState(null);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({ ...draft });
  const [width, setWidth] = useState(window.innerWidth);
  const [disableStep1, setDisable] = useState(false);
  const [incompleteSteps, setIncompleteSteps] = useState(false);
  const { getFieldDecorator } = form;
  const [mode, changeMode] = useState("single");
  const validIndividualStep = () => {
    const uploadApkFields =
      mode === "single"
        ? [
            "apk",
            "version",
            "version_code",
            "package_name",
            "apk_icon",
            "appname_en",
          ]
        : [];

    const stepFields = {
      0: uploadApkFields,
      1: [
        "appname_en",
        "app_category",
        "app_tags",
        "appdesc_short",
        "appdesc_long",
        "new_features",
        "app_permissions",
        "test_user",
        "test_password",
        "login_required",
        "console_screenshot",
        "tablet_devices",
        "app_cost",
        "advertising",
        "internet",
        "support_ipv6",
        "itunes_link",
        "apple_store_published",
        "google_play_link",
        "console_screenshot",
        "target_audience",
        "revenue_model",
      ],
      2: [
        "screenshot1",
        "screenshot2",
        "screenshot3",
        "screenshot4",
        "screenshot5",
        "screenshot6",
        "screenshot1_1",
        "screenshot2_1",
        "screenshot3_1",
        "screenshot4_1",
        "screenshot5_1",
        "screenshot6_1",
        "icon",
        "icon_2",
        "icon_3",
        "preview_video",
      ],
      3: [
        "privacy_policy",
        "privacy_screenshot_1",
        "privacy_screenshot_2",
        "long_privacy_screenshot",
      ],
    };
    form.validateFields(stepFields[step], async (err, values) => {
      if (!err) {
        setStatus("process");
        try {
          const revenue_model = values.revenue_model
            ? { revenue_model: values.revenue_model.join(",") }
            : {};
          setFormData({
            ...formData,
            ...values,
            ...revenue_model,
            [`step${step}_finished`]: true,
          });
          setSaving(true);
          let form_data = {
            ...formData,
            ...values,
            ...revenue_model,
            [`step${step}_finished`]: true,
            is_apk_update,
            is_draft: true,
          };
          if (formId) {
            form_data.Form_id = formId;
          }
          const { data } = await updateForm("app-form", {
            appId: app.id,
            form_data,
          });
          setSaving(false);
          if (data.message === "success") {
            setStep(step + 1);
            if (!!data.data && data.data.PK_App_Form) {
              setFormId(data.data.PK_App_Form);
            }
          } else {
            Modal.error({
              title: "An error has ocurred",
              centered: true,
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setStatus("error");
        notification.warning({
          placement: "bottomLeft",
          message: "Please fill all the required fields",
        });
      }
    });
  };

  const validCompletionSteps = () => {
    let completed =
      formData[`step${0}_finished`] &&
      formData[`step${1}_finished`] &&
      formData[`step${2}_finished`] &&
      formData[`step${3}_finished`];
    if (completed) {
      setStatus("process");
      setIncompleteSteps(false);
    } else {
      setStatus("error");
      setIncompleteSteps(true);
    }
  };

  const showSaved = () => {
    if (!showingmessage) message.info("Information Saved!", 3);
    setShowingMessage(true);
    setTimeout(() => setShowingMessage(false), 3000);
  };

  const autoSave = (data) => {
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      let response = await updateForm("app-form", {
        appId: app.id,
        form_data: {
          ...data,
          Form_id: formData.Form_id,
          is_draft: true,
        },
      });
      if (
        !!response &&
        !!response.data &&
        response.data.message === "success"
      ) {
        showSaved();
      }
    }, 500);
  };

  const submitNewForm = async () => {
    try {
      if (!incompleteSteps) {
        let form_data = {
          ...formData,
          is_apk_update,
          is_draft: false,
          [`step${4}_finished`]: true,
        };
        setFormData({
          ...formData,
          ...form_data,
        });
        if (formId) {
          form_data.Form_id = formId;
        }
        setSaving(true);
        await submitForm("app-form", {
          appId: app.id,
          form_data,
        });
        setSaving(false);
        setStep(5);
      } else {
        notification.error({
          placement: "bottomLeft",
          message:
            "You need to complete the previous steps in order to continue.",
        });
      }
    } catch (error) {}
  };

  const submitFileHandler = ({ name, value }, save = true) => {
    if (save) {
      autoSave({ [name]: value });
    }
    form.setFieldsValue({ [name]: value });
  };

  const deleteFile = (field) => {
    form.setFieldsValue({ [field]: null });
  };

  const handleStepperClick = (clickedStep) => {
    setStatus("process");
    if (clickedStep === 0 && !is_apk_update) {
      notification.info({
        placement: "bottomLeft",
        message: `You can not submit a new APK during an App Information Update.`,
      });
      return;
    }
    if (clickedStep === 0 || !!formData[`step${clickedStep - 1}_finished`]) {
      setStep(clickedStep);
    } else {
      setStep(clickedStep);
      if (clickedStep === 5) {
        if (!incompleteSteps && formData[`step${4}_finished`] === true) {
          setIncompleteSteps(false);
        } else {
          setIncompleteSteps(true);
        }
      } else {
        validCompletionSteps();
      }
    }
  };

  const reportWindowSize = () => {
    setWidth(window.innerWidth);
  };
  window.onresize = reportWindowSize;

  const getStepStatus = (s) => {
    if (s === step) {
      return status;
    } else if (formData[`step${s}_finished`]) {
      return "finish";
    } else {
      return "wait";
    }
  };

  const compareIcons = async (icon1, icon2, info = {}) => {
    let isSameImage = await compareImages(icon1, icon2, formData);
    if (isSameImage == false) {
      await autoSave({
        icon: null,
        icon_2: null,
        icon_3: null,
        step2_finished: false,
        step3_finished: false,
        step4_finished: false,
        step5_finished: false,
        step6_finished: false,
        ...info,
      });
    }
  };
  const validateMode = () => {
    if (formData && formData.multi_apks && formData.multi_apks.length > 0) {
      changeMode("multi");
    } else {
      changeMode("single");
    }
  };
  useEffect(() => {
    validateMode();
  }, []);

  const validateIsApkUpdate = () => {
    if (!is_apk_update && step === 1) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  useEffect(() => {
    validateIsApkUpdate();
    validCompletionSteps(step - 1);
  }, [step, is_apk_update]);

  useEffect(() => {
    validateMode();
  }, []);

  let setApkInformation = (receivedData) => {
    setFormData(receivedData);
  };
  return (
    <>
      <div
        className={[
          classes.container,
          width > 960 ? "" : classes.horizontal,
        ].join(" ")}
      >
        <Steps
          direction={width > 960 ? "vertical" : "horizontal"}
          labelPlacement={width < 960 ? "vertical" : "horizontal"}
          size="small"
          current={step}
        >
          <Step
            status={getStepStatus(0)}
            title="Upload APK"
            onClick={() => handleStepperClick(0)}
          />
          <Step
            status={getStepStatus(1)}
            title="App Details"
            onClick={() => handleStepperClick(1)}
          />
          <Step
            status={getStepStatus(2)}
            title="Screenshots"
            onClick={() => handleStepperClick(2)}
          />
          <Step
            status={getStepStatus(3)}
            title="Privacy Policy"
            onClick={() => handleStepperClick(3)}
          />
          <Step
            status={getStepStatus(4)}
            title="Preview"
            onClick={() => handleStepperClick(4)}
          />
          <Step
            status={getStepStatus(5)}
            title="Submit"
            onClick={() => handleStepperClick(5)}
          />
        </Steps>
        <div>
          <Form>
            {step === 0 ? (
              <UploadApk
                data={formData}
                lastSubmition={lastSubmition}
                getFieldDecorator={getFieldDecorator}
                submitFileHandler={submitFileHandler}
                compareIcons={compareIcons}
                deleteFile={deleteFile}
                setApkInformation={setApkInformation}
                mode={mode}
                changeMode={changeMode}
                is_apk_update={is_apk_update}
                autoSave={autoSave}
                getData={getData}
                loadingInfo={loadingInfo}
              />
            ) : null}
            {step === 1 ? (
              <>
                <AppInformation
                  data={formData}
                  getFieldDecorator={getFieldDecorator}
                  autoSave={autoSave}
                />
                <DistributionDetails
                  data={formData}
                  isCorporateClient={
                    app.main_information.app_store_accounts === "CLIENT_CC"
                  }
                  getFieldDecorator={getFieldDecorator}
                  submitFileHandler={submitFileHandler}
                  deleteFile={deleteFile}
                  autoSave={autoSave}
                />
              </>
            ) : null}
            {step === 2 ? (
              <Screenshots
                data={formData}
                lastSubmition={lastSubmition}
                getFieldDecorator={getFieldDecorator}
                submitFileHandler={submitFileHandler}
                deleteFile={deleteFile}
                autoSave={autoSave}
              />
            ) : null}
            {step === 3 ? (
              <PrivacyPolicy
                app={app}
                data={formData}
                getFieldDecorator={getFieldDecorator}
                submitFileHandler={submitFileHandler}
                deleteFile={deleteFile}
                autoSave={autoSave}
              />
            ) : null}
            {step === 4 ? (
              <Preview
                getFieldDecorator={getFieldDecorator}
                data={formData}
                app={app}
              />
            ) : null}
            {step === 5 ? (
              <Submit
                warning={incompleteSteps}
                version={formData.version}
                id={app.id}
              />
            ) : null}
          </Form>
          <Row className={classes.buttons} type="flex" justify="center">
            {step > 0 && step <= 4 ? (
              disableStep1 ? (
                <Tooltip title="You can not submit a new APK during an App Information Update.">
                  <Button
                    className={classes.previousButton}
                    disabled
                    icon="step-backward"
                  >
                    Previous
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  disabled={saving}
                  loading={saving}
                  className={classes.previousButton}
                  onClick={() => setStep(step - 1)}
                  icon="step-backward"
                >
                  Previous
                </Button>
              )
            ) : null}

            {step < 4 ? (
              <Button
                type="primary"
                icon="step-forward"
                disabled={saving}
                loading={saving}
                onClick={validIndividualStep}
              >
                Save and Next
              </Button>
            ) : step === 5 ? null : (
              <Button
                type="primary"
                icon="save"
                disabled={saving}
                loading={saving}
                onClick={submitNewForm}
              >
                Submit
              </Button>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Form.create()(FormStepper);
