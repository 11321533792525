import React from "react";
import classes from "./AttachmentCard.module.less";
import { Table, Button } from "antd";
import NewFeature from "../../../../UI/NewFeature/AsyncNewFeature";
import moment from "moment";

export default ({ data }) => {
  let columns = [
    {
      dataIndex: "date",
      key: "date",
      title: "Date",
      render: date => moment(date).format("YYYY-MM-DD")
    },
    { dataIndex: "file_name", key: "file_name", title: "File Name" },
    { dataIndex: "file_size", key: "file_size", title: "File Size (Bytes)" },
    {
      dataIndex: "file_url",
      key: "file_url",
      title: "",
      render: (file_url, item) =>
        file_url ? (
          <a
            rel="noopener noreferrer"
            href={`https://appinchina.space/download/${file_url}`}
            target="_blank"
          >
            <NewFeature
              show={item.viewed === false}
              category="app_documents_attachments"
              id={item.id}
            >
              <Button icon="cloud-download">Download</Button>
            </NewFeature>
          </a>
        ) : (
          "No file attached"
        ),
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <h3>Acount Manager Attachments</h3>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          pagination={
            data && data.manager_attachments.length > 5
              ? { pageSize: 5 }
              : false
          }
          size="small"
          dataSource={data.manager_attachments}
        />
      </div>
      <div className={classes.container}>
        <h3>My Attachments</h3>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          pagination={
            data && data.client_attachments.length > 5 ? { pageSize: 5 } : false
          }
          size="small"
          dataSource={data.client_attachments}
        />
      </div>
    </>
  );
};
