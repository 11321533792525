import axios from "../axios";

export const newMultiApk = async data => {
  let response = null;
  try {
    response = await axios.post("/new-multiApk", { ...data });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const deleteMultiApk = async id => {
  let response = null;
  try {
    response = await axios.delete("/multiApk", {
      data: {
        id: id
      }
    });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
