import React, { Fragment, useEffect, useState } from "react";
import { Spin } from "antd";
import Users from "./Users/Users";
import GeneralInformation from "./GeneralInformation/GeneralInformation";
import Payments from "./PaymentsAnalytics/PaymentsAnalytics";

export default props => {
  let [loading, setLoading] = useState(false);
  let [useDemoData, setUseDemoData] = useState(false);
  let { apps, appId } = props;

  useEffect(() => {
    let app = apps.find(app => app.id === appId);
    let usingDemoApp =
      appId === "1234-8475-9439-9494" || appId === "1234-8475-9439-9495";
    if (app || usingDemoApp) {
      setUseDemoData((!!app && !app.analitycs_id) || usingDemoApp);
      setLoading(false);
    }
    if (window.mixpanel) {
      window.mixpanel.track("Analytics loaded");
    }
  }, [appId]);

  let loader = () => (
    <div>
      <Spin
        tip="Loading..."
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      />
    </div>
  );
  let getContentSection = () => {
    let { apps, appId } = props;
    let app = apps.find(app => app.id === appId);
    switch (props.section || "general-information") {
      case "general-information":
        return <GeneralInformation app={app} useDemoData={useDemoData} />;
      case "users":
        return <Users app={app} useDemoData={useDemoData} />;
      case "payments":
        return <Payments app={app} useDemoData={useDemoData} />;
      default:
        return <GeneralInformation app={app} useDemoData={useDemoData} />;
    }
  };

  let getContent = () => {
    return getContentSection();
  };

  return <Fragment>{loading ? loader() : getContent()}</Fragment>;
};
