import React, { useState, useEffect } from "react";
import classes from "./NewFeature.module.css";

const NewFeature = ({ name, children, style }) => {
  const [value, setValue] = useState(false);
  const confirm = () => {
    localStorage.setItem(`${name}_newFeature`, false);
    setValue(false);
  };

  useEffect(() => {
    let value = localStorage.getItem(`${name}_newFeature`);
    if (value === "true") {
      setValue(true);
    } else {
      if (!value) {
        localStorage.setItem(`${name}_newFeature`, true);
        setValue(true);
      }
    }
  }, []);

  return value ? (
    <div onClick={confirm}>
      <div className={classes.container}>
        <span className={classes.animated} style={style} />
        {children}
      </div>
    </div>
  ) : (
    children
  );
};
export default NewFeature;
