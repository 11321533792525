import React, { useState, Fragment, useEffect } from "react";
import { Button, Form, Input, Spin, Tree, Row } from "antd";
import Editor from "./Editor/Editor";
import classes from "./help.module.less";
import {
  getHelpArticles,
  getHelpArticleById,
} from "../../services/helpService";
import AdvancedSearchModal from "./AdvancedSearchModal/AdvancedSearchModal";
const { TreeNode } = Tree;
const { Search } = Input;

export default Form.create()(({ form }) => {
  const [currentMenu, setCurrentMenu] = useState([]);
  const [selectedVersion, setVersion] = useState(null);
  const [content, setContent] = useState(null);
  const [categories, setCategories] = useState(null);
  const [loadingContent, setLoadingContent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    getCategories(true);
  }, []);

  const getSubcategoriesFormat = (arr) => {
    const findChildren = (parents, referenceArray) =>
      parents.map(({ id, parent_category, ...it }) => ({
        ...it,
        id,
        parent_category,
        children: findChildren(
          referenceArray.filter((i) => i.parent_category === id),
          arr
        ),
      }));
    let result = findChildren(
      arr.filter((i) => i.parent_category === null),
      arr
    );
    setCategories(result);
  };
  const getCategories = async (updateMenu = false) => {
    try {
      const { data } = await getHelpArticles();
      setLoading(false);
      if (data && data.categories) {
        getSubcategoriesFormat(data.categories);
        setAllCategories(data.categories);
        if (updateMenu && data.categories.length > 0) {
          const menu = data.categories
            .filter((e) => e.parent_category == null)
            .map(({ category }) => category);

          setCurrentMenu([menu[0]]);
        }
      }
    } catch (error) {}
  };

  const selectVersion = async (id) => {
    setLoadingContent(true);
    const { data } = await getHelpArticleById(id);
    let parseVersions = null;
    if (data.versions && data.versions.id) {
      parseVersions = {
        ...data.versions,
        content:
          data.versions.content !== null
            ? JSON.parse(data.versions.content)
            : null,
      };
      setVersion([id]);
      setContent(parseVersions);
    }

    setLoadingContent(false);
  };

  const loop = (data) =>
    data.map((item) => {
      let filterBy = item.category || item.title || "";
      const index = filterBy.toLowerCase().indexOf(searchValue.toLowerCase());
      const beforeStr = filterBy.substr(0, index);
      const afterStr = filterBy.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <Row
            className={classes.actionsHover}
            type="flex"
            justify="space-between"
          >
            <span>
              {beforeStr}
              <span style={{ background: "#FFFF00" }}>{searchValue}</span>
              {afterStr}
            </span>
          </Row>
        ) : (
          <Row
            className={classes.actionsHover}
            type="flex"
            justify="space-between"
          >
            {filterBy}
          </Row>
        );
      if (item.children && item.children.length > 0) {
        if (item.help_articles && item.help_articles.length > 0) {
          return (
            <TreeNode key={item.id} title={title}>
              {loop(item.help_articles)}
              {loop(item.children)}
            </TreeNode>
          );
        } else
          return (
            <TreeNode key={item.id} title={title}>
              {loop(item.children)}
            </TreeNode>
          );
      } else {
        if (item.help_articles && item.help_articles.length > 0) {
          return (
            <TreeNode key={item.id} title={title}>
              {loop(item.help_articles)}
            </TreeNode>
          );
        } else return <TreeNode key={item.id} title={title} />;
      }
    });

  const getCategoryTitle = (versionId) => {
    return allCategories
      .map(({ id, category, help_articles }) => {
        let results = help_articles.filter((version) => {
          if (version.id === versionId) {
            return true;
          }
          return null;
        });

        return results && results.length > 0 ? category : null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)[0];
  };
  const isCategory = (categoryId) => {
    let result = allCategories
      .filter(({ id, category, versions }) => {
        return id == categoryId ? true : null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    return result && result.length > 0 ? true : false;
  };
  const onSelect = (id) => {
    let isIdCategory = isCategory(id);
    if (isIdCategory) {
      let index = expandedKeys.indexOf(id);
      if (index === -1) {
        setExpandedKeys([...expandedKeys, id]);
      } else {
        setExpandedKeys([...expandedKeys.filter((e) => e !== id)]);
      }
    } else {
      let categoryTitle = getCategoryTitle(id);
      let idCategory = allCategories.filter(
        (e) => e.category === categoryTitle
      )[0].id;
      setCategory(idCategory);
      setExpandedKeys([...expandedKeys, idCategory]);
      selectVersion(id);
    }
  };

  const filterContent = (e) => {
    const { value } = e.target;
    const expandedKeys = allCategories
      .map(({ id, category, help_articles }) => {
        let results = help_articles.filter(({ title }) => {
          if (title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
            return true;
          }
          return null;
        });

        return results && results.length > 0 ? id : null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setExpandedKeys(expandedKeys);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const menu = () =>
    currentMenu.length > 0 ? (
      categories ? (
        <div className={classes.menuContainer}>
          <div className={classes.menu}>
            <Search placeholder="Search" onChange={(e) => filterContent(e)} />
            <Button
              onClick={() => setAdvancedSearch(true)}
              icon="search"
              className={classes.advancedSearch}
              type="primary"
              ghost
            >
              Advanced Search
            </Button>
            <Tree
              // className="draggable-tree"
              // draggable
              blockNode
              onSelect={(key, info) => {
                if (key.length > 0) {
                  onSelect(key[0]);
                }
              }}
              selectedKeys={selectedVersion}
              onExpand={(values) => {
                setExpandedKeys(values);
                setAutoExpandParent(false);
              }}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
            >
              {loop(categories)}
            </Tree>
          </div>
          <AdvancedSearchModal
            visible={advancedSearch}
            close={() => setAdvancedSearch(false)}
            onSelect={onSelect}
          />
        </div>
      ) : null
    ) : (
      <div className={classes.menu}>
        <p style={{ marginTop: 10 }}>No categories</p>
      </div>
    );

  const updateContent = async () => {
    try {
      await getCategories();
      setVersion(null);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCategories = async () => await getCategories();

  return (
    <Fragment>
      {!loading ? (
        <div className={classes.container}>
          {menu()}
          <div className={classes.content}>
            {selectedVersion ? (
              content ? (
                <Editor
                  article={content}
                  loading={loadingContent}
                  updateVersions={updateContent}
                  updateCategories={updateCategories}
                  categories={allCategories}
                  actualCategory={category}
                  updateActualTitle={() => updateCategories()}
                />
              ) : (
                <p>Error</p>
              )
            ) : (
              <div className={classes.flex}>
                <p>Please select an article</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <Spin />
        </div>
      )}
    </Fragment>
  );
});
