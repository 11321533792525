import axios from "../axios";

export async function getHelpArticles() {
  let response = null;
  try {
    response = await axios.get("/help");
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
export async function getHelpArticleById(id) {
  let response = null;
  try {
    response = await axios.get(`/help/${id}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}

export async function helpAdvancedSearch({ search }) {
  let response = null;
  try {
    response = await axios.get(`/help-search?search=${search}`);
  } catch (error) {
    console.log("error", error);
  }
  return response;
}
