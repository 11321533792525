import React, { useEffect, useState } from "react";
import classes from "./NewPushNotification.module.less";
import {
  Form,
  Row,
  Col,
  Input,
  Radio,
  Select,
  notification,
  DatePicker,
  Button,
  Alert,
} from "antd";
import LineCard from "../../UI/LineCard/LineCard";
import {
  createNewPushNotification,
  getAllTags,
} from "../../../services/pushNotificationsService";
import PushNotificationDemo from "../PushNotificationDemo/PushNotificationDemo";
import moment from "moment-timezone";
const { Item } = Form;

const NewPushNotification = ({ form, match, app, useDemoData, ...props }) => {
  let [loading, setLoading] = useState(false);
  let [tags, setTags] = useState([]);
  const { getFieldDecorator } = form;

  let getTags = async () => {
    let response = await getAllTags(app.id);
    if (response && response.data.tags) {
      setTags(response.data.tags);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  const showNotification = (type, message) => {
    notification[type]({
      message,
    });
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      let send_time =
        values.send_option === "NOW"
          ? moment()
              .tz("Asia/Shanghai")
              .format("YYYY-MM-DDTHH:mm")
          : moment(values.send_time).format("YYYY-MM-DDTHH:mm");
      if (!err) {
        setLoading(true);
        let result = await createNewPushNotification(app.analitycs_id, app.id, {
          ...values,
          send_time,
        });
        if (result && result.data && result.data.message === "success") {
          showNotification("success", "Information Sent!");
          form.resetFields();
        } else {
          showNotification("error", "An error happened");
        }
        setLoading(false);
      } else {
        notification.error({
          message: "Fill all the required fields.",
          placement: "bottomLeft",
        });
      }
    });
  };

  let getAppIcon = () => {
    let imageData = app.image_data ? app.image_data : "";
    return (imageData = /uploads|static|googleusercontent/.test(imageData)
      ? imageData
      : `data:image/png;base64,${imageData}`);
  };

  return (
    <div className={classes.container}>
      {useDemoData ? (
        <>
          <Alert
            message="You need to have push notifications enabled to use this feature."
            description="Please contact your account manager for details."
            type="warning"
            showIcon
            style={{ textAlign: "left" }}
          />
          <p></p>
        </>
      ) : null}
      <div className={classes.intro}>
        <LineCard shadow>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </LineCard>
      </div>
      <div className={classes.content}>
        <Form
          onSubmit={handleSubmit}
          colon={false}
          layout="vertical"
          className={classes.form}
        >
          <div>
            <LineCard title="Push Notification Details" shadow>
              <Row gutter={16} type="flex" justify="space-around">
                <Col span={24}>
                  <Item label="Title">
                    {getFieldDecorator(`title`, {
                      rules: [
                        {
                          required: true,
                          message: "Required",
                        },
                      ],
                    })(<Input disabled={loading} />)}
                  </Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Item label="Body">
                    {getFieldDecorator(`body`, {
                      rules: [{ required: true, message: "Required" }],
                    })(<Input.TextArea disabled={loading} />)}
                  </Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Item label="Target Audience">
                    {getFieldDecorator("pushTarget", {
                      rules: [{ required: true, message: "Required" }],
                    })(
                      <Radio.Group>
                        <Radio value="ALL">All Users</Radio>
                        <Radio value="TAG">By Tag</Radio>
                      </Radio.Group>
                    )}
                  </Item>
                </Col>
              </Row>
              {form.getFieldValue("pushTarget") === "TAG" ? (
                <Row>
                  <Col span={24}>
                    <Item label="Select Tag">
                      {getFieldDecorator("targetValue", {
                        rules: [{ required: true, message: "Required" }],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Please select"
                        >
                          {tags.map((it) => (
                            <Select.Option value={it.title}>
                              {it.title}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>
                </Row>
              ) : null}

              <Row>
                <Col span={24}>
                  <Item label="Time to be sent">
                    {getFieldDecorator("send_option", {
                      rules: [{ required: true, message: "Required" }],
                    })(
                      <Radio.Group>
                        <Radio value="NOW">Now</Radio>
                        <Radio value="SCHEDULED">Scheduled</Radio>
                      </Radio.Group>
                    )}
                  </Item>
                </Col>
              </Row>

              {form.getFieldValue("send_option") === "SCHEDULED" ? (
                <Row>
                  <Col span={24}>
                    {" "}
                    <Alert
                      message="Warning"
                      description="The time you select must be in China Standard Time (GMT+8)."
                      type="warning"
                      showIcon
                    />
                    <p></p>
                    <Item label="Select Time">
                      {getFieldDecorator("send_time", {
                        rules: [
                          {
                            required: true,
                            validator: (rule, value, callback) => {
                              if (
                                moment(value).isAfter(
                                  moment(
                                    moment()
                                      .tz("Asia/Shanghai")
                                      .format("YYYY-MM-DDTHH:mm:00")
                                  )
                                )
                              ) {
                                callback();
                              } else {
                                callback(
                                  "The time to be sent must be higher than right now in China"
                                );
                              }
                            },
                          },
                        ],
                      })(
                        <DatePicker
                          showTime={{ format: "HH:mm" }}
                          format="YYYY-MM-DD HH:mm"
                          placeholder="Select Time"
                        />
                      )}
                    </Item>
                  </Col>
                </Row>
              ) : null}
            </LineCard>
          </div>
          <div>
            <LineCard shadow>
              <div className={classes.send}>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  Send
                </Button>
              </div>
            </LineCard>
          </div>
        </Form>
        <PushNotificationDemo
          appName={app.name}
          title={form.getFieldValue("title")}
          body={form.getFieldValue("body")}
          send_option={form.getFieldValue("send_option")}
          time={form.getFieldValue("send_time")}
          icon={getAppIcon()}
        />
      </div>
    </div>
  );
};

export default Form.create()(NewPushNotification);
