import React, { Component, Fragment } from "react";
import classes from "./Reviews.module.less";
import {
  Icon,
  Button,
  Table,
  Popover,
  Tag,
  Modal,
  DatePicker,
  notification,
  Spin,
} from "antd";
import Input from "../../UI/Input/Input";
import { newReply, searchReviews } from "../../../services/distributionService";
import history from "../../../history";
import moment from "moment";
import { getReviews } from "../../../services/distributionService";

class Reviews extends Component {
  state = {
    loading: false,
    display: false,
    reply: {
      value: "",
    },
    id_review: null,
    actual_store: "",
    actual_user: "",
    store_filter: "",
    start_date: "",
    end_date: "",
    date_filter: true,
    loading_new_reviews: false,
    new_reviews: [],
    scrapeableStores: [],
  };

  render() {
    let start = this.state.start_date;
    let end = this.state.end_date;
    let scrapeableStores = this.state.scrapeableStores
      .filter((it) => it.store.scrapeable && it.store.scrapeable === true)
      .filter((it) => it.reviews && it.reviews.length > 0)
      .reduce((prev, curr) => {
        const store = curr.reviews.map((it) => ({
          ...it,
          date: this.getDate(it.english_date, it.createdAt),
          store: curr.store.name,
        }));
        return [...prev, ...store];
      }, [])
      .sort((a, b) => {
        const first = new Date(a.date);
        const seccond = new Date(b.date);
        if (first > seccond) return -1;
        else if (first < seccond) return 1;
        return 0;
      });
    let scrapeableStores_filter =
      start !== "" && end !== "" && scrapeableStores.length > 0
        ? scrapeableStores.filter((it) => it.date >= start && it.date <= end)
        : scrapeableStores;
    if (this.state.new_reviews.length > 0) {
      scrapeableStores_filter = this.state.new_reviews.sort((a, b) => {
        const first = new Date(a.date);
        const seccond = new Date(b.date);
        if (first > seccond) return -1;
        else if (first < seccond) return 1;
        return 0;
      });
    }

    let stores = scrapeableStores.map((it, index) => it.store);

    let columns = [
      {
        title: "Store",
        dataIndex: "store",
        width: 160,
        // align: "left",
        key: "store",
        fixed: "left",
        filters: this.getStores(stores),
        filterMultiple: false,
        onFilter: (value, record) => {
          return record.store.indexOf(value) === 0;
        },
        sorter: (a, b) => {
          if (a.store > b.store) return -1;
          else if (a.store < b.store) return 1;
          return 0;
        },
        sortDirections: ["descend", "ascend"],
        render: (_, it) => this.getTag(it.store),
      },
      {
        title: "Review",
        dataIndex: "review",
        align: "left",
        key: "review",
        render: (_, review) =>
          this.getPopover(
            review.english_review,
            decodeURIComponent(review.review)
          ),
      },
      {
        title: "User",
        dataIndex: "user",
        // width: "15%",
        align: "left",
        key: "user",
      },
      {
        title: "Score",
        dataIndex: "score",
        // width: "10%",
        align: "left",
        key: "score",
      },
      {
        title: "Date",
        dataIndex: "date",
        // width: "10%",
        align: "left",
        key: "date",
        sortDirections: ["descend", "ascend"],
        defaultSortOrder: "ascend",
        sorter: (a, b) => {
          const first = new Date(a.date);
          const seccond = new Date(b.date);
          if (first > seccond) return -1;
          else if (first < seccond) return 1;
          return 0;
        },
      },
      {
        title: "Reply",
        dataIndex: "reply",
        // width: "10%",
        align: "center",
        key: "reply",
        render: (_, review) =>
          this.getReply(
            review.id,
            review.reply,
            review.status,
            review.store,
            review.user
          ),
      },
    ];
    const { RangePicker } = DatePicker;

    return (
      <div className={classes.Container}>
        <Fragment>
          <div className={classes.Buttons}>
            <div>
              {" "}
              <Button
                onClick={() =>
                  history.push(
                    `/distribution/${this.props.app.id}/app-information`
                  )
                }
                type="primary"
                width="100px"
                margin="auto"
                ghost
                icon="left"
              >
                Go back
              </Button>
            </div>
            <div>
              <RangePicker
                disabled={
                  !!this.state.loading_new_reviews || this.state.loading
                }
                width="100%"
                onChange={this.onChangeDate}
              />
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => this.resetFilter()}
                disabled={this.state.date_filter}
                width="100px"
              >
                Reset
              </Button>
            </div>
            <div>
              <Button
                icon="download"
                onClick={() => this.downloadCsv()}
                type="primary"
                width="150px"
                disabled={
                  !!this.state.loading_new_reviews || this.state.loading
                }
              >
                Download as CSV
              </Button>
            </div>
            <div>
              <Button
                onClick={() => this.searchReviews()}
                icon={this.state.loading_new_reviews ? "loading" : "sync"}
                type="primary"
                width="200px"
                disabled={
                  !!this.state.loading_new_reviews || this.state.loading
                }
              >
                {this.state.loading_new_reviews
                  ? "Searching..."
                  : "Search for new reviews"}
              </Button>
            </div>
          </div>
          <div className={classes.Information}>
            <h2 className={classes.StoresTitle}>Reviews</h2>
            <p className={classes.MainDescription}>
              This page shows the user reviews of your app on each app store on
              which your app is published. Hover over the review to see the
              original text in Chinese.*
            </p>
          </div>
          {!this.state.loading_new_reviews ? (
            <Table
              dataSource={scrapeableStores_filter}
              columns={columns}
              loading={this.state.loading}
              size="small"
              pagination={{ pageSize: 12 }}
              onChange={this.onChange}
              scroll={{ x: 800 }}
            />
          ) : (
            <Spin
              tip="Looking for new reviews..."
              style={{
                display: "flex",
                height: "400px",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
          <Modal
            visible={this.state.display}
            title="Write a reply for this review:"
            onCancel={this.desactiveModalReply}
            okButtonProps={{ disabled: true }}
            footer={[
              <Button
                onClick={() => this.sendReply()}
                type="primary"
                width="170px"
              >
                Send
              </Button>,
            ]}
          >
            <Input
              name="reply"
              placeholder="Reply"
              value={this.state.reply.value}
              type="textarea"
              onChange={(event) => this.handleFieldChange(event, "reply")}
            />
          </Modal>
        </Fragment>
      </div>
    );
  }
  getStores(stores) {
    let result = [];
    stores.forEach((s1) => {
      if (!result.find((s2) => s2.text === s1))
        result.push({ text: s1, value: s1 });
    });
    return result;
  }

  getTag = (storeName) => {
    switch (storeName) {
      case "Tencent MyApp":
        return <Tag color="lime">{storeName}</Tag>;
      case "360 Mobile Assistant":
        return <Tag color="red">{storeName}</Tag>;
      case "Baidu Mobile Assistant":
        return <Tag color="volcano">{storeName}</Tag>;
      case "MIUI App Store":
        return <Tag color="orange">{storeName}</Tag>;
      case "PP Assistant":
        return <Tag color="gold">{storeName}</Tag>;
      case "China Mobile MM Store":
        return <Tag color="purple">{storeName}</Tag>;
      case "Anzhi Market":
        return <Tag color="green">{storeName}</Tag>;
      case "Sogou Mobile Assistant":
        return <Tag color="cyan">{storeName}</Tag>;
      case "Wandoujia":
        return <Tag color="blue">{storeName}</Tag>;
      case "Meizu App Center":
        return <Tag color="geekblue">{storeName}</Tag>;
      case "Lenovo Store":
        return <Tag color="lime">{storeName}</Tag>;
      case "AppChina":
        return <Tag color="magenta">{storeName}</Tag>;
      case "2345 Mobile Assistant":
        return <Tag color="orange">{storeName}</Tag>;
      default:
        return <Tag color="lime">{storeName}</Tag>;
    }
  };
  getPopover = (value, content) => {
    return (
      <Popover style={{ maxWidth: "100px" }} placement="top" content={content}>
        {value}
      </Popover>
    );
  };

  getDate = (date, createdAt) => {
    let result;
    if (!date) {
      return createdAt.split("T")[0];
    }
    const dateRegex = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])( (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9])?/;
    if (date && dateRegex.test(date)) {
      result = date.split(" ")[0];
    } else {
      let splitDate = date.split(" ");
      const unitsOfTime = /month|months|year|years|day|days/;
      const monthsRegex = /January|February|March|April|May|June|July|August|September|October|November|December/;
      const monthsJson = {
        January: "01",
        February: "02",
        March: "03",
        April: "04",
        May: "05",
        June: "06",
        July: "07",
        August: "08",
        September: "09",
        October: "10",
        November: "11",
        December: "12",
      };
      if (unitsOfTime.test(date)) {
        let number = +splitDate[0];
        let value = splitDate[1];
        let newDate = new Date();
        let fullDate;
        if (value === "day" || value === "days") {
          let dateDays = 1000 * 60 * 60 * 24 * number;
          fullDate = newDate.getTime() - dateDays;
          result = moment(fullDate).format("YYYY-MM-DD");
        } else if (value === "months" || value === "month") {
          let dateMonths = 1000 * 60 * 60 * 24 * 30 * number;
          fullDate = newDate.getTime() - dateMonths;
          result = moment(fullDate).format("YYYY-MM-DD");
        } else if (value === "year" || value === "years") {
          let dateYears = 1000 * 60 * 60 * 24 * 30 * 12 * number;
          fullDate = newDate.getTime() - dateYears;
          result = moment(fullDate).format("YYYY-MM-DD");
        }
      } else if (monthsRegex.test(date)) {
        const [month, day, year] = splitDate;
        result = `${year}-${monthsJson[month]}-${day.substring(0, 2)}`;
      }
    }
    return result;
  };
  onChange = (pagination, filters, sorter) => {
    let [value] = filters.store ? filters.store : "";
    this.setState({
      store_filter: value,
    });
  };
  onChangeDate = (date, dateString) => {
    let start = dateString[0];
    let end = dateString[1];
    this.setState({
      start_date: start,
      end_date: end,
      date_filter: false,
    });
  };
  resetFilter = () => {
    this.setState({
      start_date: "",
      end_date: "",
      date_filter: true,
    });
  };
  downloadCsv = (filename = "reviews.csv") => {
    let filter = this.state.store_filter;
    let start = this.state.start_date;
    let end = this.state.end_date;
    let headers = ["Store", "Review", "User", "Score", "Date"];
    let info = this.props.app
      ? this.props.app.information
      : this.state.new_reviews;
    let data = info
      .filter((it) => it.store.scrapeable && it.store.scrapeable === true)
      .map((it) => {
        return {
          reviews: it.reviews.map((review) => [
            `${it.store.name}`,
            `"${review.english_review.replace(/"/g, " ")}"`,
            `"${review.user}"`,
            `"${review.english_score}"`,
            this.getDate(review.english_date, review.createdAt),
          ]),
        };
      })
      .reduce((prev, curr) => {
        return [...prev, ...curr.reviews];
      }, [])
      .sort((a, b) => new Date(b.date) - new Date(a.date));

    start !== "" && end !== ""
      ? (data = data.filter((it) => it[4] >= start && it[4] <= end))
      : (data = data);
    filter ? (data = data.filter((it) => it[0] == filter)) : (data = data);

    let result_headers = [headers].concat(data);
    let csvContent =
      "data:text/csv;charset=utf-8," +
      result_headers.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  };
  getReply = (id, reply, status, store, user) => {
    let result;
    !reply && !status
      ? (result = (
          <a
            type="primary"
            onClick={() => this.activeModalReply(id, store, user)}
          >
            Send reply
          </a>
        ))
      : (result = <Fragment>{this.getStatus(reply, status)}</Fragment>);
    return result;
  };
  getStatus = (reply, status) => {
    switch (status) {
      case "NOT_STARTED":
        return (
          <Popover
            placement="top"
            content={
              <p>
                <b>Text Submitted:</b> "{reply}"
              </p>
            }
          >
            <Icon
              type="info-circle"
              theme="filled"
              style={{ fontSize: "1.5em", color: "#ffd83e" }}
            />
          </Popover>
        );
      case "COMPLETED":
        return (
          <Popover
            placement="top"
            content={
              <p>
                <b>Text Submitted:</b> "{reply}"
              </p>
            }
          >
            <Icon
              type="check-circle"
              theme="filled"
              style={{ fontSize: "1.5em", color: "#7cb305" }}
            />
          </Popover>
        );
    }
  };
  activeModalReply = (id, store, user) => {
    this.setState({
      id_review: id,
      display: true,
      actual_store: store,
      actual_user: user,
    });
  };
  desactiveModalReply = () => {
    this.setState({
      id_review: null,
      display: false,
      actual_store: "",
      actual_user: "",
    });
  };
  handleFieldChange = (event, name) => {
    let field = {
      ...this.state[name],
      validated: false,
      value: event.target.value,
    };
    this.setState({ [name]: field });
  };
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };
  sendReply = async () => {
    let appId = this.props.match.params.app_id;
    let data = {
      id: this.state.id_review,
      reply: this.state.reply.value,
      app_id: appId,
      store: this.state.actual_store,
      user: this.state.actual_user,
    };
    let response = await newReply(data);
    this.setState({ display: false });
    if (response.data && response.data.message === "success") {
      this.openNotificationWithIcon("info", "Your reply has been received.");
      if (window.mixpanel) window.mixpanel.track("Reply sent");
    } else {
      this.openNotificationWithIcon(
        "warning",
        "error while connecting to server"
      );
    }
  };
  searchReviews = async () => {
    this.setState({ loading_new_reviews: true });
    notification.info({
      message: "Searching for new reviews",
      description:
        "This may take a couple minutes, please do not close this page",
      placement: "bottomLeft",
      duration: 10,
    });
    let appId = this.props.match.params.app_id;
    let response = await searchReviews(appId);
    if (response && response.data.message) {
      this.setState({ new_reviews: response.data.message });
    } else {
      notification.warn({
        message: "Error while getting the new reviews. Please reload the page.",
        placement: "bottomLeft",
        duration: 3,
      });
    }
    this.setState({ loading_new_reviews: false });
  };
  getData = async () => {
    this.setState({ loading: true });
    let { data } = await getReviews(this.props.app.id);
    this.setState({ loading: false });
    if (data && data.result) {
      this.setState({
        scrapeableStores: data.result,
      });
    }
  };
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.app.id !== prevProps.app.id) {
      this.getData();
    }
  }
}
export default Reviews;
