import React, { useState } from "react";
import { Form, Row, Col, Input, Checkbox, Radio, Select, Modal } from "antd";
import LineCard from "../../../../../UI/LineCard/LineCard";
import File from "../../../../../UI/File/File";
import DeveloperConsoleScreenShot from "../../../../../../assets/screenshots_app_form/developer_console.jpeg";
import { FORM_OPTIONS } from "../../../../../../constants";
import classes from "./DistributionDetails.module.less";
const { Item } = Form;
const { Option } = Select;

export default ({
  getFieldDecorator,
  autoSave,
  disabled = false,
  data = {},
  submitFileHandler,
  deleteFile,
}) => {
  const [display, setDisplay] = useState(false);
  return (
    <LineCard className={classes.container} title="Distribution Details">
      <Modal
        visible={display}
        onCancel={() => setDisplay(false)}
        footer={null}
        width={700}
        centered={true}
      >
        <img
          src={DeveloperConsoleScreenShot}
          width={600}
          alt="Developer Console Screenshot"
        />
      </Modal>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <>
            <Item label="Apple App Store China Download Link">
              {getFieldDecorator(`itunes_link`, {
                initialValue: data.itunes_link,
                rules: [
                  {
                    required: !data.apple_store_published,
                    message: "Required",
                  },
                ],
                onChange: (event) =>
                  autoSave({ itunes_link: event.target.value }),
              })(<Input disabled={disabled} />)}
            </Item>{" "}
            <Item>
              {getFieldDecorator(`apple_store_published`, {
                initialValue: data.apple_store_published,
                valuePropName: "checked",
                onChange: (event) =>
                  autoSave({
                    apple_store_published: event.target.value,
                  }),
              })(
                <Checkbox disabled={disabled}>
                  {" "}
                  Please note that this must be the download link for China
                  (e.g. https://itunes.apple.com/cn/app/example). If you don't
                  have an iOS app or it's not yet published in China then please
                  click here.{" "}
                </Checkbox>
              )}
            </Item>
          </>

          <Item label="Google Play Link">
            {getFieldDecorator(`google_play_link`, {
              initialValue: data.google_play_link,
              onChange: (event) =>
                autoSave({
                  google_play_link: event.target.value,
                }),
            })(<Input disabled={disabled} />)}
          </Item>
          <Item>
            {getFieldDecorator(`console_screenshot`, {
              initialValue: data.console_screenshot,
              rules: [{ required: true, message: "Required" }],
            })(
              <File
                name="console_screenshot"
                type="Image"
                disabled={disabled}
                title="Google Play Developer Console/Dashboard Screenshot"
                validation={{ fileRequired: false }}
                submitFile={submitFileHandler}
                onDelete={() => deleteFile("console_screenshot")}
                format={["png", "jpg", "jpeg"]}
              />
            )}
            <b onClick={() => setDisplay(true)}>Example</b>
          </Item>
          <Item label="Who is the target audience of your app?">
            {getFieldDecorator(`target_audience`, {
              initialValue: data.target_audience,
              onChange: (event) =>
                autoSave({
                  target_audience: event.target.value,
                }),
            })(<Input disabled={disabled} />)}
          </Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Item label="Please select your Revenue Model">
            {getFieldDecorator(`revenue_model`, {
              initialValue:
                data && data.revenue_model ? data.revenue_model.split(",") : [],
              onChange: (event) => autoSave({ revenue_model: event.join(",") }),
            })(
              <Select
                disabled={disabled}
                style={{ width: "100%" }}
                mode="multiple"
              >
                {FORM_OPTIONS.revenue_model.map((it) => (
                  <Option key={it.value} value={it.value} label={it.label}>
                    {it.label}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <Item label="Is this APK also usable on tablet devices?">
            {getFieldDecorator(`tablet_devices`, {
              initialValue: data.tablet_devices,
              rules: [{ required: true, message: "Required" }],
              onChange: (event) =>
                autoSave({
                  tablet_devices: event.target.value,
                }),
            })(
              <Radio.Group disabled={disabled} name="tablet_devices">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            )}
          </Item>
          <Item label="Is your app free, freemium, or paid?">
            {getFieldDecorator(`app_cost`, {
              initialValue: data.app_cost,
              rules: [{ required: true, message: "Required" }],
              onChange: (event) => autoSave({ app_cost: event.target.value }),
            })(
              <Radio.Group disabled={disabled} name="app_cost">
                <Radio value={"FREE"}>Free</Radio>
                <Radio value={"FREEMIUM"}>Freemium</Radio>
                <Radio value={"PAID"}>Paid</Radio>
              </Radio.Group>
            )}
          </Item>
          <Item label="Does your app include advertising?">
            {getFieldDecorator(`advertising`, {
              initialValue: data.advertising,
              rules: [{ required: true, message: "Required" }],
              onChange: (event) =>
                autoSave({ advertising: event.target.value }),
            })(
              <Radio.Group disabled={disabled} name="advertising">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            )}
          </Item>
          <Item label="Does your app require an internet connection in order to function?">
            {getFieldDecorator(`internet`, {
              initialValue: data.internet,
              rules: [{ required: true, message: "Required" }],
              onChange: (event) => autoSave({ internet: event.target.value }),
            })(
              <Radio.Group disabled={disabled} name="internet">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            )}
          </Item>
          <Item label="Does the app support IPv6?">
            {getFieldDecorator(`support_ipv6`, {
              initialValue: data.support_ipv6,
              rules: [{ required: true, message: "Required" }],
              onChange: (event) =>
                autoSave({ support_ipv6: event.target.value }),
            })(
              <Radio.Group disabled={disabled} name="support_ipv6">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            )}
          </Item>
        </Col>
      </Row>
    </LineCard>
  );
};
