import React, { useEffect, useState } from "react";
import classes from "./page.module.less";
import moment from "moment";
import Chart from "./Chart";
import { getBadgePagesByApp } from "../../../services/badgeTrackingService";
import HeatMap from "./HeatMap";
import RegionsMap from "./RegionsMap";
import TrendsChart from "./TrendsChart";
import functions from "../../../functions";
import { Table, DatePicker, Row, Col, Button, Spin } from "antd";
import LineCard from "../../UI/LineCard/LineCard";

const { RangePicker } = DatePicker;

const PageBadge = ({ appId, apps }) => {
  let [data, setData] = useState({});
  let [start_date, setStartDate] = useState("");
  let [end_date, setEndDate] = useState("");
  let [loading, setLoading] = useState(false);
  let [pkg, setPkg] = useState("");
  const getData = async (start, end, package_name) => {
    setLoading(true);
    let { data } = await getBadgePagesByApp(start, end, package_name);
    if (data) {
      setData({
        ...data,
        traficByReferrer: traficByReferrer(data.traficByReferrer),
      });
      setLoading(false);
    }
  };

  const traficByReferrer = (data) => {
    return Object.entries(data).map(([referrer, values]) => ({
      referrer,
      ...values,
    }));
  };

  const onChangeDate = (_, [start, end]) => {
    setStartDate(start);
    setEndDate(end);
    getData(start, end, pkg);
  };
  const makeRequest = async () => {
    let act = apps.filter((app) => app.id === appId)[0];
    setPkg(act.main_information.package_name);
    getData(
      moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
      act.main_information.package_name
    );
    setStartDate(
      moment()
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    setEndDate(moment().format("YYYY-MM-DD"));
  };

  useEffect(() => {
    makeRequest();
  }, []);

  useEffect(() => {
    makeRequest();
  }, [appId]);

  let columns = [
    {
      title: "Referrer",
      dataIndex: "referrer",
      key: "referrer",
    },
    {
      title: "Visitors",
      dataIndex: "sessions",
      key: "sessions",
      render: (sessions) => functions.styleAsNumber(sessions),
    },
  ];
  let regionColumns = [
    {
      title: "Region",
      dataIndex: "regionName",
      key: "regionName",
    },
    {
      title: "Page Views",
      dataIndex: "value",
      key: "value",
    },
  ];

  let countryColumns = [
    {
      title: "Country Name",
      dataIndex: "countryName",
      key: "countryName",
      render: (countryName) => (countryName ? countryName : "N/A"),
    },
    {
      title: "Page Views",
      dataIndex: "page_views",
      key: "page_views",
      render: (views) => (views ? functions.styleAsNumber(+views) : ""),
    },
  ];

  let clicksColumns = [
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      render: (clicks) => (clicks ? functions.styleAsNumber(+clicks) : ""),
    },
  ];

  return (
    <div className={classes.container}>
      <Row type="flex" gutter={16} style={{ marginBottom: 20 }}>
        <Col>
          <RangePicker
            value={
              start_date !== "" && end_date !== ""
                ? [moment(start_date), moment(end_date)]
                : [moment().startOf("month"), moment()]
            }
            style={{ width: "100%" }}
            onChange={onChangeDate}
            format={"YYYY-MM-DD"}
            disabled={loading}
          />
        </Col>
        <Col>
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            style={{ paddingBottom: "12px" }}
          >
            <a
              href={`https://apps.appinchina.com.cn/details/${pkg}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <Button icon="link">Open Badge Page</Button>
            </a>
          </Row>
        </Col>
      </Row>
      {loading ? (
        <Spin />
      ) : (
        <>
          <table className={classes.summaryTable}>
            <tbody>
              <tr>
                <td>Traffic Today</td>
                <td>
                  {functions.styleAsNumber(
                    data.todayTraffic ? data.todayTraffic : 0
                  )}
                </td>
              </tr>
              <tr>
                <td>Page Views (Pv)</td>
                <td>
                  {functions.styleAsNumber(
                    data.summary ? data.summary.page_views : 0
                  )}
                </td>
              </tr>
              <tr>
                <td>Visitors (Uv)</td>
                <td style={{ color: "#2db72d" }}>
                  {functions.styleAsNumber(
                    data.summary ? data.summary.visitors : 0
                  )}
                </td>
              </tr>
              <tr>
                <td>Avg. Session Duration</td>
                <td>
                  {functions.styleAsTime(
                    data.summary ? data.summary.avg_session_duration / 1000 : 0
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <Table
            style={{ marginTop: 15 }}
            dataSource={data.traficByReferrer}
            loading={loading}
            size="small"
            columns={columns}
          />
          <LineCard title="Visits per Day" style={{ marginBottom: "24px" }}>
            <Chart data={data.table} />
          </LineCard>
          <TrendsChart data={data.trendChart} />
          <LineCard
            title="Visitors Per Region (China)"
            style={{ textAlign: "center" }}
          >
            {data.visitorsByRegion ? (
              <HeatMap data={data.visitorsByRegion} />
            ) : null}
          </LineCard>
          <Table
            style={{ marginTop: 15 }}
            dataSource={data.visitorsByRegion}
            loading={loading}
            size="small"
            columns={regionColumns}
          />
          <RegionsMap countries={data.visitorsByCountry} loading={loading} />
          <Table
            style={{ marginTop: 15 }}
            dataSource={data.visitorsByCountry}
            loading={loading}
            size="small"
            columns={countryColumns}
          />
          <Table
            style={{ marginTop: 15 }}
            dataSource={data.clicks}
            loading={loading}
            size="small"
            columns={clicksColumns}
          />
        </>
      )}
    </div>
  );
};
export default PageBadge;
