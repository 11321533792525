import React, { useState, useEffect } from "react";
import { Row, Result, Button, Modal, Select } from "antd";
import classes from "./DomainCard.module.less";
import history from "../../../../../history";
import functions from "../../../../../functions";
const { confirm } = Modal;
const { Option } = Select;
let uuid = require("uuid-random");
const DomainCard = ({ appId, domains }) => {
  let [plan, setPlan] = useState(12);
  let [total, setTotal] = useState(0);
  const addDomain = (name, price) => {
    let monthly_price = price / 12;
    confirm({
      title: "Are you sure you want to add this service?",
      onOk() {
        let data = {
          id: uuid(),
          title: `Domain: ${name}`,
          product_type: "domain",
          product_subtype: name,
          months_paid: plan,
          total: total,
          price: price,
          onetime_fee: 0,
          monthly_price: monthly_price,
          total: total,
          description: [
            {
              key: "Domain",
              value: name
            },
            {
              key: "Available",
              value: "Available"
            }
          ]
        };
        saveCart(data);
        history.push(`/cloud/cart/${appId}`);
      },
      onCancel() {}
    });
  };

  useEffect(() => {
    if (domains.length > 0) {
      setTotal(domains[0].price);
    }
  }, [domains]);
  const saveCart = values => {
    let localStorageCopy = localStorage.cloud_cart
      ? JSON.parse(localStorage.cloud_cart)
      : [];
    let localStorageOrders =
      localStorageCopy && localStorageCopy.orders
        ? localStorageCopy.orders
        : [];
    const appFilterSettings = {
      orders: [...localStorageOrders, values]
    };
    window.localStorage.setItem(
      "cloud_cart",
      JSON.stringify(appFilterSettings)
    );
  };

  let months = [12, 24, 36];
  return domains && domains.length > 0 ? (
    <Row key="container" className={classes.container}>
      {domains.map(({ name, available, price }) => (
        <Result
          key={name}
          className={classes.result}
          status={available ? "success" : "warning"}
          title={
            available ? (
              <p className={classes.title}>
                <b>{name}</b> is available for{" "}
                <b>USD $ {functions.styleAsNumber(price)} the first year.</b>
              </p>
            ) : (
              <p className={classes.title}>
                {" "}
                <b>{name}</b> is actually registered.
              </p>
            )
          }
          // subTitle="An extra one time fee of $999.00 is required for ICP Recordal Service"
          extra={[
            <Row key="total" type="flex" justify="center">
              {available ? (
                <h1 className={classes.total}>
                  Total: USD ${functions.styleAsNumber(total)}
                </h1>
              ) : null}
            </Row>,
            <Row key="select-month">
              <Select
                disabled={!available}
                key="month"
                onChange={val => (setPlan(val), setTotal((val / 12) * price))}
                placeholder="Select a Plan..."
                value={plan}
                style={{ width: 150, marginRight: 20 }}
              >
                {months.map(it => (
                  <Option key={it} value={it}>
                    {it} Months
                  </Option>
                ))}
              </Select>
              <Button
                disabled={!available}
                key="buy"
                onClick={() => addDomain(name, price)}
                type="primary"
              >
                Add to Cart
              </Button>
            </Row>
          ]}
        />
      ))}
    </Row>
  ) : null;
};

export default DomainCard;
