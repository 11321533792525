import React from "react";
import { Chart, Geom, Axis, Tooltip, Coord } from "bizcharts";
import DataSet from "@antv/data-set";
import { location } from "./../../../fakeData";
export default class extends React.Component {
  render() {
    let data = this.props.useDemoData ? location : this.props.data;
    const ds = new DataSet();
    const dv = ds.createView().source(data || []);
    dv.source(data || []).transform({
      type: "sort",
      callback(a, b) {
        return a.city_count - b.city_count;
      }
    });

    return (
      <div>
        <Chart height={400} data={dv} forceFit>
          <Coord transpose />
          <Axis
            name="city"
            label={{
              offset: 12
            }}
          />
          <Axis name="city_count" label={{ formatter: val => `${val}` }} />
          <Tooltip
            crosshairs={{
              type: "y"
            }}
          />
          <Geom
            type="interval"
            position="city*city_count"
            color={"city_count"}
            tooltip={[
              "city*city_count",
              (city, city_count) => {
                city_count = `${city_count}`;
                return {
                  value: city_count
                };
              }
            ]}
          />
        </Chart>
      </div>
    );
  }
}
