import React, { Fragment, useState } from "react";
import classes from "./Login.module.less";
import LoginForm from "./LoginForm/LoginForm";
import { Input, Form, Button, Icon } from "antd";
import ForgotPasswordDialog from "../Dialogs/ForgotPasswordDialog/ForgotPasswordDialog";
import Auth from "../../auth";
const auth = new Auth();

export default Form.create({ name: "login_form" })(({ form }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { getFieldDecorator } = form;

  const handleSubmit = (event) => {
    event.preventDefault();
    form.validateFields(async (err, { email, password }) => {
      if (!err) {
        setLoading(true);
        let { err, result } = await auth.login(email, password);
        setLoading(false);
        auth.handleAuthentication(result, err);
      }
    });
  };

  return (
    <Fragment>
      <LoginForm>
        <h2 className={classes.Title}>Welcome</h2>
        <Form onSubmit={handleSubmit} className={classes.form}>
          <Form.Item label="Email">
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Required" },
                {
                  pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                  message: "Input a valid email",
                },
              ],
              validateTrigger: "onSubmit",
            })(
              <Input
                prefix={
                  <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                }
              />
            )}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Required" }],
              validateTrigger: "onSubmit",
            })(
              <Input.Password
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
              />
            )}
          </Form.Item>
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            htmlType="submit"
          >
            Log in
          </Button>
        </Form>
        <div className={classes.BottomOptions}>
          <div></div>
          <span onClick={() => setVisible(true)}>Forgot password?</span>
        </div>
        <ForgotPasswordDialog
          close={() => setVisible(false)}
          display={visible}
        />
      </LoginForm>
    </Fragment>
  );
});
